import React from "react";
import styled from "styled-components";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import { PageHeading } from "../components/PageHeading";
import { NewsMessageAction } from "./NewsMessageContainer";
import {OverlayLoader} from "../components/Loader";

const Text = styled.p`
  color: black;
  padding: 0;
  margin: 0;
`;

class NewsReceipt extends React.Component {
  componentWillUnmount() {
    this.props.cleanUpOnUnmount();
  }
  render() {
    const { t } = this.props;
    const props = this.props;

    var header = "";

    if (
      props.isTestMessage &&
      (props.isTestMessage === "true" || props.isTestMessage === true)
    ) {
      header = t("test_message_is_published");
    } else if (props.newsMessageAction === NewsMessageAction.EDIT) {
      header = t("message_updated");
    } else if (props.newsMessageAction === NewsMessageAction.CORRECTION) {
      header = t("corrected_message_created");
    } else if (props.newsMessageAction === NewsMessageAction.NEW) {
      if (props.isDraft) {
        header = t("saved_as_draft");
      } else if (props.futureMessage) {
        header = t("message_prepared_for_publish");
      } else {
        header = t("message_is_published");
      }
    }

    return (
      <FlexContainer column greyBackground padding={[16, 16, 16, 16]}>
        {this.props.loading ? <OverlayLoader /> : undefined}
        <PageHeading>{header}</PageHeading>

        <FlexContainer margin={[16, 0, 0, 0]}>
          <Text>{t("news_receipt_comment")} </Text>
        </FlexContainer>

        <FlexContainer row flexEnd>
          <Button.Standard onClick={props.onMessageListClick}>
            {" "}
            {t("goto_message")}{" "}
          </Button.Standard>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default NewsReceipt;
