import React from "react";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import { Field, reduxForm, reset } from "redux-form";
import DayPicker from "../components/DayPicker";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Table, { TD, TR } from "../components/Table";
import Button from "../components/Button";
import Input from "../components/Input";
import Label from "../components/Label";
import { ActionText } from "../components/ActionText";
import {fetchAuditlog, fetchAuditlogDetails, fetchAuditlogEvents} from "./AuditlogActions";
import Loader, {OverlayLoader} from "../components/Loader";
import moment from "moment/moment";
import {getOBNTDate, getOBNTTimeWithSecAndMillis, hasValue} from "../util/common";
import styled from "styled-components";
import { ConfirmModal } from "../components/ConfirmModal";
import SearchableSelectNew from "../components/SearchableSelectNew";
import { fetchCompanyList } from "../actions/CommonActions";
import SearchableTextDropdown from "../components/SearchableTextDropdown";

const dateFormat = "YYYY-MM-DD";

const SearchBox = (props) => {
    return (
        <FlexContainer>
            <FlexContainer column maxWidth={773} marginBottom={16}>
                <FlexContainer row vAlignEnd marginBottom={10}>
                    <FlexContainer margin={[0, 15, 0, 0]} minWidth={300}>
                        <Label htmlFor={"company"}>{props.t("company")}</Label>
                        <Field
                            options={props.companies}
                            t={props.t}
                            id="company"
                            name={"company"}
                            placeholder={props.t("select")}
                            component={SearchableSelectNew}
                        />
                    </FlexContainer>
                    <FlexContainer margin={[0, 15, 0, 0]} minWidth={300}>
                        <Field
                            options={props.events.map((opt) => {
                                return {label: opt, value: opt}
                            }) || []}
                            t={props.t}
                            id="operation"
                            label={props.t("event")}
                            name={"operation"}
                            placeholder={props.t("search_input")}
                            component={SearchableTextDropdown}
                        />
                    </FlexContainer>
                    <FlexContainer row vAlignEnd minWidth={300}>
                        <FlexContainer >
                            <Label htmlFor={props.input.name}>{props.label}</Label>
                            <Input
                                {...props}
                                {...props.input}
                                border
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        props.handleSubmit();
                                    }
                                }}
                            />
                        </FlexContainer>
                        <Button.Standard notRounded onClick={props.handleSubmit}>
                            {props.buttonLabel}
                        </Button.Standard>
                    </FlexContainer>
                </FlexContainer>

            </FlexContainer>
            <FlexContainer row spaceBetween marginBottom={24} width={"1100px"}>
                <FlexContainer row fitToContent>
                    <Field component={DateBox} name={"fromDate"} label={props.t("fromDate")} defaultValue={new Date()}/>
                    <Field component={DateBox} name={"toDate"} label={props.t("toDate")}/>
                </FlexContainer>

                <ActionText
                    margin={[30, 10, 0, 0]}
                    onClick={props.reset}
                >
                    {props.t("reset_search")}
                </ActionText>
            </FlexContainer>
        </FlexContainer>
    );
}

const DateBox = (props) => (
  <FlexContainer column maxWidth={300} marginRight={19}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    <DayPicker {...props} border={true} />
  </FlexContainer>
);

const Link = styled.a`
  text-align: left;
  color: #336699;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const P = styled.p`
  white-space: pre-line;
  margin: 0;
  word-break: break-all;
  ${(props) => (props.bold ? "font-weight: bold;" : "")};
`;
const ChangeEntry = (props) => {
  return (
    <FlexContainer marginBottom={5}>
      <P bold>{props.field}</P>
      <P>{props.oldValue}</P>
      <P>{props.newValue}</P>
    </FlexContainer>
  );
};

class AuditlogForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showChanges: false,
      changes: undefined,
      isJSON: false,
      loading: false
    };
    props.dispatch(fetchAuditlogEvents());
  }

    getChangeEntry = (key, field, oldValue, newValue) => {
        return (
            <ChangeEntry
                key={key}
                field={this.props.t("audit_field") + field}
                oldValue={this.props.t("audit_old_value") + oldValue}
                newValue={this.props.t("audit_new_value") + newValue}
            />
        );
    };

  resetSearch = () => {
      this.props.dispatch(reset("auditlogform"));
      this.props
          .dispatch(
              fetchAuditlog({
                  fromDate: moment().format(dateFormat),
              })
          )
          .then(this.setLoadingFalse)
          .catch(this.setLoadingFalse);
  }

    showEntities = () => {
        let toDisplay = this.state.entities;
        try {
            toDisplay = JSON.stringify(toDisplay, null, 2);
        } catch (e) {
          console.log("Could not parse entities")
        }
        return (
            <FlexContainer key="0" marginBottom={5}>
                <P bold> {this.props.t("entity") + ": " + toDisplay} </P>
            </FlexContainer>
        );
    }

    getChangesList = (changes, rowId) => {
        let changeEntries = [];
        let counter = 0;
        changes.forEach((change) => {
            if (
                Array.isArray(change.newValue) ||
                typeof change.newValue === "string"
            ) {
                if (change.newValue !== change.oldValue) {
                    changeEntries.push(
                        this.getChangeEntry(
                            rowId + counter++,
                            change.field,
                            change.oldValue,
                            change.newValue
                        )
                    );
                }
            } else {
                if (change.newValue) {
                    Object.keys(change.newValue).forEach((key) => {
                        if (change.oldValue) {
                            if (change.newValue[key] + "" !== change.oldValue[key] + "") {
                                changeEntries.push(
                                    this.getChangeEntry(
                                        rowId + counter++,
                                        key,
                                        change.oldValue[key],
                                        change.newValue[key]
                                    )
                                );
                            }
                        } else {
                            changeEntries.push(
                                this.getChangeEntry(
                                    rowId + counter++,
                                    key,
                                    "",
                                    change.newValue[key]
                                )
                            );
                        }
                    });
                } else if (!change.newValue && change.oldValue) {
                    Object.keys(change.oldValue).forEach((key) => {
                        changeEntries.push(
                            this.getChangeEntry(rowId + counter++, key, change.oldValue[key], "")
                        );
                    });
                }
            }
        });
        return changeEntries;
    };

  showDetails = (id) => {
      const getEntities = (res) => {
        let entities = {};
        if (res["entityUsername"]) {
            entities["username"] = res["entityUsername"];
        }
        if (res["entityOrganisationName"]) {
            entities["organisation"] = res["entityOrganisationName"];
        }
        if (res["entityCompany"]) {
            entities["company"] = res["entityCompany"];
        }
        if (res["entityRefId"]) {
            entities["refId"] = res["entityRefId"];
        }
        if (Object.keys(entities).length === 0 && hasValue(res["entity"])) {
            entities = res["entity"]
        }
        return entities;
      }

    this.setState({loading: true})
    this.props.dispatch(fetchAuditlogDetails(id))
        .then((res) => {
            let details = undefined;
            let entities = undefined;
            let parsedSuccessfully = false;
            let row = res.data.entity;
            try {
                entities = getEntities(row)
                if (row.details) {
                    let jsonRow = JSON.parse(row.details);
                    if (jsonRow.message) {
                        details = jsonRow.message;
                        details = details.replaceAll(",", ",\n");
                        details = details.replaceAll("{", "{\n");
                    }
                    if (jsonRow.data) {
                        try {
                            JSON.parse(row.details);
                            parsedSuccessfully = true;
                        } catch (error) {
                            console.log(error);
                        }
                        details = JSON.stringify(jsonRow.data, null, 2);
                    }
                    if (jsonRow.changes) {
                        details = this.getChangesList(jsonRow.changes, row.id);
                    }
                }
            } catch (e) {
              console.log(e);
            }

          this.setState({
            showChanges: true,
            entities: entities,
            changes: details,
            operation: row.operation,
            isJSON: parsedSuccessfully,
            loading: false
          });
        }).catch((e) => console.log(e))
  }

  render() {
    const { t } = this.props;

    const thLabels = [
      t("date"),
      t("time"),
      t("user"),
      t("event"),
      t("text"),
      t("ID"),
    ];
    const tableRows = this.props.auditlog
      ? this.props.auditlog.map((row, index) => {
          return (
            <TR key={index}>
              <TD width={88}>
                {row.timestamp ? getOBNTDate(row.timestamp) : ""}
              </TD>
              <TD width={101}>
                {row.timestamp
                  ? getOBNTTimeWithSecAndMillis(row.timestamp)
                  : ""}
              </TD>
              <TD width={166}>{row.user}</TD>
              <TD width={200}>{row.operation}</TD>
              <TD width={250}>
                <Link
                  onClick={() => {
                    this.showDetails(row.id)
                  }}
                >
                  {t("show_details")}
                </Link>
              </TD>
              <TD width={87}>{row.id}</TD>
            </TR>
          );
        })
      : [];

    return (
      <form autoComplete="off">
          {this.state.loading && <OverlayLoader/>}
        <PageHeading>{t("auditlog")}</PageHeading>
        <FlexContainer column>
          <Field
            component={SearchBox}
            label={t("user")}
            name={"user"}
            buttonLabel={t("search")}
            companies={this.props.companyList}
            events={this.props.auditLogEvents}
            reset={this.resetSearch}
            {...this.props}
          />

          {!this.props.state.loading ? (
            <Table
              tableRows={tableRows}
              tableHeaderLabels={thLabels}
              forceUpdate={true}
            />
          ) : (
            <Loader />
          )}
        </FlexContainer>
        <ConfirmModal
          isOpen={this.state.showChanges}
          onClose={() =>
            this.setState({ showChanges: false, changes: undefined })
          }
          header={
            t("changes") +
            (this.state.operation ? " - " + this.state.operation : "")
          }
          onConfirm={() =>
            this.setState({ showChanges: false, changes: undefined })
          }
          confirmText={t("okay")}
          isJSON={this.state.isJSON}
        >
          <FlexContainer>
              {(this.state.entities !== undefined && Object.keys(this.state.entities).length !== 0) &&
                  this.showEntities()}
              {this.state.changes}
          </FlexContainer>
        </ConfirmModal>
      </form>
    );
  }
}

AuditlogForm = reduxForm({
  form: "auditlogform",
})(AuditlogForm);

class Auditlog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  setLoadingFalse = () => {
    this.setState({ loading: false });
  };

  componentDidMount() {
    this.props
      .dispatch(fetchCompanyList())
      .then(() =>
        this.props.dispatch(
          fetchAuditlog({
            fromDate: moment().format(dateFormat),
          })
        )
      )
      .then(this.setLoadingFalse)
      .catch(this.setLoadingFalse);
  }

  render() {
    const handleSubmit = (values) => {
      let searchObject = {};

      if (values.user) {
        searchObject.user = values.user;
      }
      if (!values.fromDate && !values.toDate) {
        searchObject.fromDate = moment().format(dateFormat);
      } else {
        if (values.fromDate) {
            searchObject.fromDate = moment(values.fromDate, "DD.MM.YYYY").format(dateFormat);
        }
        if (values.toDate) {
          searchObject.toDate = moment(values.toDate, "DD.MM.YYYY").format(dateFormat);
        }
      }
      if (values.company) {
        searchObject.cid = values.company.value;
      }
      if (values.operation) {
          searchObject.operation = values.operation.value;
      }

      this.setState({ loading: true });
      this.props
        .dispatch(fetchAuditlog(searchObject))
        .then(this.setLoadingFalse)
        .catch(this.setLoadingFalse);
    };

    return (
      <AuditlogForm
        {...this.props}
        onSubmit={(values) => handleSubmit(values)}
        state={this.state}
      />
    );
  }
}

function mapStateToProps(state) {
  const { auditlogReducer, companyReducer } = state;
  return {
    auditlog: auditlogReducer.auditlogListReducer.auditlog,
    auditlogIsFetched: auditlogReducer.auditlogListReducer.isFetched,
    auditLogDetails: auditlogReducer.auditlogDetailsReducer.details,
    auditLogEvents: auditlogReducer.auditlogEventsReducer.events,
    companyList: companyReducer.list
      ? companyReducer.list.map((company) => {
          return { label: company.longName, value: company.cid };
        })
      : [],
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(Auditlog)
);
