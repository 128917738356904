import React from "react";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import { destroy, Field, reduxForm, reset, SubmissionError } from "redux-form";
import { withTranslation } from "react-i18next";
import Button from "../components/Button";
import Input from "../components/Input";
import Label from "../components/Label";
import { ActionText } from "../components/ActionText";
import { Modal } from "../components/Modal";
import SearchableSelect from "../components/SearchableSelect";
import { connect } from "react-redux";
import {
  fetchUser,
  resetUserData,
  updateCredentials
} from "../actions/MFAActions";

import Loader, { OverlayLoader } from "../components/Loader";
import { getGlobalSubject } from "../obsvcClient";
import { withRouter } from "react-router";
import { ErrorText } from "../components/ErrorText";
import {
  fetchCompanyList,
  fetchParameters
} from "../companyInformation/CompanyInformationActions";
import i18n from "../i18n";
import { browserIsIE, getOBNTDateTime } from "../util/common";
import { updateUser, fetchUserParams } from "../admin/AdminActions";
import { NotificationManager } from "react-notifications";
import MFABoxContainer from "./MFABoxContainer";
import PasswordBox from "./PasswordBox";
import { emailRequirementValidator } from "../util/emailValidator";
import { setLocalLanguage } from "../actions/CommonActions";

const InformationBox = props => (
  <FlexContainer row>
    <Label
      htmlFor={props.name}
      maxWidth={185}
      margin={[0, 16, 16, 0]}
      bold
      {...props}
    >
      {props.label}:
    </Label>
    <FlexContainer maxWidth={browserIsIE() ? "185px;" : ""} id={props.name}>
      {props.children}
    </FlexContainer>
  </FlexContainer>
);

export const InputBox = props => {
  const { error } = props.meta;
  return (
    <FlexContainer column {...props}>
      <Label margin={[0, 0, 8, 0]} htmlFor={props.input.name}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input border {...props.input} {...props} />
    </FlexContainer>
  );
};

const conpanySort = (a, b) => {
  if (!a.longName && !b.longName) return 0;
  if (!a.longName) return -1;
  if (!b.longName) return 1;

  return a.longName
    .toLocaleLowerCase()
    .localeCompare(b.longName.toLocaleLowerCase(), "nb-NO");
};

class AuthorizedBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companyList:
        props.companyList != null ? props.companyList.sort(conpanySort) : []
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companyList.length !== this.state.companyList.length) {
      this.setState({ companyList: nextProps.companyList.sort(conpanySort) });
    }
  }
  render() {
    const props = this.props;
    return (
      <FlexContainer column>
        <Label margin={[0, 0, 8, 0]} htmlFor={props.name}>
          {props.label}:
        </Label>
        <FlexContainer
          pre
          border
          scrollbarsOnOverflow
          whiteBackground
          height={"425px"}
        >
          {this.state.companyList.map(company => company.longName + "\n", "")}
        </FlexContainer>
      </FlexContainer>
    );
  }
}

class MyProfileForm extends React.Component {
  render() {
    const { t, configLanguages } = this.props;
    const languageOptions = [];
    configLanguages.map(x => {
      languageOptions.push({ label: t(`language_${x}`), value: x });
    });
    return (
      <FlexContainer column>
        {this.props.saving ? <OverlayLoader /> : undefined}
        <PageHeading>{t("my_profile")}</PageHeading>
        <form>
          <FlexContainer row>
            <FlexContainer
              greyBackground
              column
              padding={[16, 16, 16, 16]}
              margin={[0, 20, 0, 0]}
            >
              <InformationBox name="username" label={t("username")}>
                {this.props.user.username}
              </InformationBox>
              <InformationBox
                name="last_password_update"
                label={t("last_password_update")}
              >
                {this.props.lastPasswordChange}
              </InformationBox>
              <InformationBox name="last_login" label={t("last_login")}>
                {this.props.lastLogin}
              </InformationBox>
              <InformationBox name="organisation" label={t("organisation")}>
                {this.props.user.organisationName}
              </InformationBox>
              {this.props.systemWideTwoFactorEnabled ? (
                <InformationBox
                  name={"twoFactorNumber"}
                  label={t("two_factor_phone_number")}
                >
                  {this.props.user.otherProperties.twoFactorPhone}
                </InformationBox>
              ) : (
                ""
              )}
            </FlexContainer>
            <FlexContainer column margin={[0, 20, 0, 0]}>
              <Field
                component={InputBox}
                margin={[0, 0, 8, 0]}
                name="firstName"
                label={t("first_name") + "*"}
              />
              <Field
                component={InputBox}
                margin={[0, 0, 8, 0]}
                name="lastName"
                label={t("last_name") + "*"}
              />
              <Field
                component={InputBox}
                margin={[0, 0, 8, 0]}
                name="mobileNumber"
                label={t("mobile_number") + "*"}
              />
              <Field
                component={InputBox}
                margin={[0, 0, 8, 0]}
                name="directNumber"
                label={t("direct_number")}
              />

              <Label margin={[0, 0, 8, 0]} htmlFor={"language"}>
                {t("preferred_language")}
              </Label>
              <Field
                component={SearchableSelect}
                name="preferedLang"
                t={t}
                label={t("language")}
                options={languageOptions}
                margin={[0, 0, 16, 0]}
                placeholder={this.props.t("pick_preferred_language")}
                searchable={true}
              />
              <Field
                component={InputBox}
                name="email"
                label={t("email") + "*"}
              />
            </FlexContainer>
            <AuthorizedBox
              name="authorized"
              label={t("authorized_for")}
              companyList={this.props.companyList}
            />
          </FlexContainer>
          <FlexContainer row spaceBetween margin={[40, 0, 0, 0]} vAlignCenter>
            {this.props.enableTwoFactor ? (
              <ActionText
                onClick={() => {
                  if (this.props.areaCodeLabel) {
                    this.props.setState({ showMFABox: true });
                  } else {
                    NotificationManager.error(
                      t("cannot_update_two_factor_information_at_this_time"),
                      "",
                      10000
                    );
                  }
                }}
              >
                {t("register_mfa_phone")}
              </ActionText>
            ) : (
              undefined
            )}
            <FlexContainer row flexEnd vAlignCenter>
              <Button.Standard
                onClick={() => this.props.setState({ showPasswordBox: true })}
                inverted
                margin={[0, 24, 0, 0]}
              >
                {t("change_password")}
              </Button.Standard>
              <Button.Standard
                margin={[0, 24, 0, 0]}
                onClick={this.props.handleSubmit}
              >
                {t("save")}
              </Button.Standard>
              <Button.Text onClick={() => this.props.onCancel()}>
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

MyProfileForm = reduxForm({
  form: "myProfileForm"
})(MyProfileForm);

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordBox: false,
      showMFABox: false,
      saving: false,
      twoFactorCodeRequested: false
    };
  }

  onSuccess = () => {
    this.setState({ otherProperties: this.props.user.otherProperties });
  };

  componentDidMount() {
    const globalSubject = getGlobalSubject();
    this.props
      .dispatch(fetchUser(globalSubject))
      .then(() => this.props.dispatch(fetchUserParams()))
      .then(this.onSuccess)
      .catch(err => {});
    this.props.dispatch(fetchParameters()).catch(err => {});
    this.props.dispatch(fetchCompanyList()).catch(err => {});
  }

  render() {
    const { t, user } = this.props;

    const setThisState = state => {
      this.setState(state);
    };

    const hasValue = value => {
      return value != null && value.length > 0;
    };

    const invalidPhone = phone => {
      return !/^\+[0-9]+|[0-9]+/i.test(phone);
    };

    const validateFields = values => {
      let errors = {};

      if (hasValue(values.mobileNumber)) {
        if (invalidPhone(values.mobileNumber)) {
          errors.mobileNumber = t("invalid_phonenumber");
        }
      } else {
        errors.mobileNumber = t("required_field");
      }
      if (!hasValue(values.firstName)) {
        errors.firstName = t("required_field");
      }
      if (!hasValue(values.lastName)) {
        errors.lastName = t("required_field");
      }
      if (!hasValue(values.email) || emailRequirementValidator(values.email)) {
        errors.email = t("invalid_email");
      }

      return Object.keys(errors).length > 0 ? errors : undefined;
    };

    const onUpdateUserSuccess = user => {
      if (user.entity.preferedLang !== this.props.user.preferedLang) {
        setLocalLanguage(user.entity.preferedLang === "en" ? "en" : "nb");
      }
      if (user.entity.preferedLang === "en") {
        i18n.changeLanguage("en");
      } else {
        i18n.changeLanguage("nb");
      }
      this.props
        .dispatch(fetchUser(user.entity.globalSubject))
        .then(() => {
          NotificationManager.success(t("user_updated"), "", 10000);
        })
        .finally(() => this.setState({ saving: false }));
    };

    const submit = values => {
      this.setState({ saving: true });
      let valueObj = values;
      if (valueObj.preferedLang && valueObj.preferedLang.value) {
        valueObj.preferedLang = valueObj.preferedLang.value;
      } else {
        valueObj.preferedLang = this.props.user.preferedLang;
      }
      if (this.state.otherProperties) {
        valueObj.otherProperties = this.state.otherProperties;
      }
      valueObj.phoneNumbers.forEach(phone => {
        if (phone.phoneType === "MOBILE") {
          phone.phone = valueObj.mobileNumber;
        }
      });
      valueObj.phoneNumbers.forEach(phone => {
        if (phone.phoneType === "OFFICE") {
          phone.phone = valueObj.directNumber;
        }
      });
      this.props
        .dispatch(updateUser(valueObj))
        .then(onUpdateUserSuccess)
        .catch(() => {
          NotificationManager.error(t("an_error_occured"), "", 0);
          this.setState({ saving: false });
        });
    };

    const getPhoneNumber = phoneType => {
      let number = "";
      user.phoneNumbers.forEach(phone => {
        if (phone.phoneType === phoneType) {
          number = phone.phone;
        }
      });
      return number;
    };

    const onUpdatePasswordSuccess = () => {
      NotificationManager.success(t("password_updated"), "", 10000);
      this.setState({ showPasswordBox: false });
      this.props.dispatch(reset("passwordBoxForm"));
    };

    const handlePasswordChange = (currentPassword, newPassword) => {
      /*
  globalSubject,
  code,
  country,
  phone,
  protocol,
  resetSecret,
  changeType,
  password,
  oldPassword
    */
      this.props
        .dispatch(
          updateCredentials(
            null,
            null,
            null,
            null,
            null,
            null,
            1,
            currentPassword,
            newPassword
          )
        )
        .then(onUpdatePasswordSuccess)
        .catch(err => {});
    };

    const countryOptions = this.props.parameterList
      ? this.props.parameterList.countries.map(country => {
          return {
            label: i18n.language === "nb" ? country.nameNo : country.nameEn,
            value: country.id
          };
        })
      : [];

    const areaCodeLabel = this.props.initialAreaCode
      ? i18n.language === "nb"
        ? this.props.initialAreaCode.nameNo
        : this.props.initialAreaCode.nameEn
      : "";

    const getPreferredLanguageObject = () => {
      return {
        label: t(
          "language_" +
            (user.preferedLang ? user.preferedLang.toLowerCase() : "en")
        ),
        value: user.preferedLang ? user.preferedLang.toLowerCase() : "en"
      };
    };

    return this.props.userIsFetched ? (
      [
        <MyProfileForm
          {...user}
          {...this.props}
          systemWideTwoFactorEnabled={this.props.systemWideTwoFactorEnabled}
          configLanguages={this.props.configLanguages}
          initialValues={{
            ...user,
            preferedLang: getPreferredLanguageObject(),
            mobileNumber: getPhoneNumber("MOBILE"),
            directNumber: getPhoneNumber("OFFICE")
          }}
          lastPasswordChange={
            user.lastPasswordChange
              ? getOBNTDateTime(user.lastPasswordChange)
              : ""
          }
          lastLogin={user.lastLogin ? getOBNTDateTime(user.lastLogin) : ""}
          t={t}
          setState={state => setThisState(state)}
          onSubmit={(values, dispatch) => {
            return new Promise((resolve, reject) => {
              const errors = validateFields(values);
              if (errors) {
                reject(new SubmissionError(errors));
              } else {
                submit(values);
                resolve();
              }
            });
          }}
          state={this.state}
          onCancel={() => {
            this.props.dispatch(destroy("myProfileForm"));
            this.props.dispatch(resetUserData());
            this.props.history.length > 0
              ? this.props.history.goBack()
              : this.props.history.push(`${this.props.base}`);
          }}
          handlePasswordChange={(currentPassword, newPassword) => {
            handlePasswordChange(currentPassword, newPassword);
          }}
          saving={this.state.saving}
          key="MyProfileForm"
          areaCodeLabel={areaCodeLabel}
        />,
        <Modal
          key="MFABox"
          header={t("register_mfa_phone")}
          isOpen={this.state.showMFABox}
          onClose={() => {
            this.setState({ showMFABox: false });
            this.props.dispatch(reset("MFABoxForm"));
          }}
        >
          <MFABoxContainer
            t={t}
            countryOptions={countryOptions}
            closeModal={() => {
              this.setState({
                showMFABox: false,
                twoFactorCodeRequested: false
              });
              this.props.dispatch(reset("MFABoxForm"));
            }}
            initialValues={{
              twoFactorProtocol:
                user && user.otherProperties.twoFactorMessageProtocol
                  ? user.otherProperties.twoFactorMessageProtocol
                  : "SMS",
              areaCode: {
                label: areaCodeLabel,
                value: user.otherProperties.twoFactorCountryCode
                  ? user.otherProperties.twoFactorCountryCode
                  : "NO"
              }
            }}
            setMFADetails={otherProperties => {
              this.setState({
                showMFABox: false,
                otherProperties: otherProperties
              });
            }}
            {...this.state.otherProperties}
            setTwoFactorCodeRequested={() =>
              this.setState({ twoFactorCodeRequested: true })
            }
            twoFactorCodeRequested={this.state.twoFactorCodeRequested}
          />
        </Modal>,
        <Modal
          key="PasswordBox"
          center
          header={t("change_password")}
          isOpen={this.state.showPasswordBox}
          onClose={() => {
            this.setState({ showPasswordBox: false });
            this.props.dispatch(reset("passwordBoxForm"));
          }}
        >
          <PasswordBox
            {...this.props}
            closePasswordModal={() => {
              this.setState({ showPasswordBox: false });
              this.props.dispatch(reset("passwordBoxForm"));
            }}
          />
        </Modal>
      ]
    ) : (
      <Loader />
    );
  }
}

function mapStateToProps(state) {
  const {
    profileReducer,
    companyInformationReducer,
    config,
    adminReducer
  } = state;
  let initialAreaCode = {
    id: "NO",
    nameNo: "Norge",
    nameEn: "Norway",
    active: true
  };
  if (
    profileReducer.user.userIsFetched &&
    companyInformationReducer.parameters.isFetched
  ) {
    const countryCode =
      profileReducer.user.user.otherProperties.twoFactorCountryCode;

    if (countryCode) {
      initialAreaCode = companyInformationReducer.parameters.list.countries.find(
        element => {
          return element.id === countryCode;
        }
      );
    }
  }
  return {
    user: profileReducer.user.user,
    userIsFetched:
      profileReducer.user.userIsFetched &&
      companyInformationReducer.parameters.isFetched,
    parameterList: companyInformationReducer.parameters.list,
    companyList: companyInformationReducer.companyList.list,
    initialAreaCode: initialAreaCode,
    configLanguages:
        config.config && config.config.languages && config.config.languages.length > 0
        ? config.config.languages
        : [],
    systemWideTwoFactorEnabled:
      adminReducer.userParams.params &&
      adminReducer.userParams.params.authenticationParams !== null &&
      adminReducer.userParams.params.authenticationParams
        .systemWideTwoFactorEnabled !== null &&
      adminReducer.userParams.params.authenticationParams
        .systemWideTwoFactorEnabled === "true"
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(MyProfile))
);
