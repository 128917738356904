import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import CheckBox from "./CheckBox";
import { FlexContainer } from "./FlexContainer";
import Button from "./Button";
import styled from "styled-components";

const ButtonRow = ({ onCancel, onSubmit, t }) => {
  return (
    <FlexContainer flexEnd vAlignCenter row>
      <Button.Standard margin={[0, 20, 0, 0]} onClick={onSubmit}>
        {t("logout")}
      </Button.Standard>
      <Button.Text onClick={onCancel}>{t("cancel")}</Button.Text>
    </FlexContainer>
  );
};

const Text = styled.p`
  font-weight: 100;
  font-size: 16px;
`;

const DoNotShowText = styled.span`
  font-weight: 100;
  font-size: 16px;
  opacity: 70%;
  margin: -1px 0 0 7px;
`;

class LogoutModal extends React.Component {
  render() {
    const { t, handleSubmit, onSubmit, onClose } = this.props;

    return (
      <FlexContainer>
        <FlexContainer>
          <Text margin={[0, 0, 20, 0]}>{t("logout_information_text")}</Text>
          <Field
            component={CheckBox}
            name="doNotShowAgain"
            label={
              <DoNotShowText>{t("logout_do_not_show_again")}</DoNotShowText>
            }
            fitToContent
          />
        </FlexContainer>
        <FlexContainer margin={[8, 0, 8, 0]}>
          <ButtonRow
            t={t}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={onClose}
          />
        </FlexContainer>
      </FlexContainer>
    );
  }
}
LogoutModal = reduxForm({
  form: "updateDateTime"
})(LogoutModal);

function mapStateToProps(state) {
  const { form } = state;

  return {
    updateDateTimeValues: form.updateDateTime
      ? form.updateDateTime.values
      : undefined
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(LogoutModal)
);
