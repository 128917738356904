import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FlexContainer } from "../components/FlexContainer";
import {PageHeading} from "../components/PageHeading";
import { reduxForm} from "redux-form";
import Button from "../components/Button";
import {getLanguage, hasValue} from "../util/common";
import {ConfirmModal} from "../components/ConfirmModal";
import {Icon} from "../insiderTool/components";
import ChatBox from "./ChatBox";
import Table, {TD, TR} from "../components/Table";
import {mapStatus} from "./ShareIssuanceContainer";
import {fetchIssuanceInstruments} from "./ShareIssuanceActions";

class ShareIssuancePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelModal: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.data?.isin &&
            this.props.data?.issuerId &&
            !this.props.data?.issuerId?.value &&
            this.props.data?.issuerId !== prevProps?.data?.issuerId &&
            this.props.instruments?.length < 1) {
                this.props.dispatch(fetchIssuanceInstruments(this.props.data.issuerId));
        }
    }

    render() {
        const { t, base, data, parameters } = this.props;

        const getValueToShow = (parName, val) => {
            if (hasValue(parameters) && hasValue(data)) {
                if (hasValue(data[val]) && data[val].label ) {
                    return data[val].label
                } else if (hasValue(data[val])) {
                    let returnVal = data[val]
                    if (hasValue(parName)) {
                        const toShow = parameters[parName];
                        if (hasValue(toShow) && toShow.length > 0) {
                            toShow.map((p => {
                                if (returnVal === p.value) {
                                    returnVal = p["label" + getLanguage().toUpperCase()];
                                }
                            }))
                        }
                    }
                    return "" + returnVal;
                }
            }
            return ""
        }

        const tableHeaderLabels = ["", ""];
        const tableHeaderProps = [{colSpan: 2}];

        const mapProspectusReasonYes = () => {
            const reason = this.props.data?.prospectusYesReason
            switch (reason) {
                case 1:
                    return t("share_prospectus_reason0");
                case 2:
                    return t("share_prospectus_reason1")
                case 3:
                    return t("share_prospectus_reason2")
                default:
                    return ""
            }
        }

        const getInstrumentName = () => {
            let label = this.props.data?.instrument?.label;
            if (!label && this.props.instruments) {
                const instrument = this.props.instruments.find((instrument) => instrument.value === this.props.data?.isin);
                if (instrument) {
                    label = instrument[`label${getLanguage().toUpperCase()}`]
                }
            }
            return label;
        }

        const getRows = () => {
            const rows = [
                <TR key="1">
                    <TD bold>{t("issuer")}:</TD>
                    <TD>{getValueToShow("issuers", "issuerId")}</TD>
                </TR>,
                <TR key="2">
                    <TD bold>{t("instrument")}:</TD>
                    <TD>{getInstrumentName()}</TD>
                </TR>,
                <TR key="3">
                    <TD bold>{t("share_market")}:</TD>
                    <TD>{getValueToShow("markets", "mic")}</TD>
                </TR>,
                <TR key="4">
                    <TD bold>{t("share_contact_name")}:</TD>
                    <TD>{getValueToShow(null, "contactName")}</TD>
                </TR>,
                <TR key="5">
                    <TD bold>{t("share_contact_phone")}:</TD>
                    <TD>{getValueToShow(null, "contactPhone")}</TD>
                </TR>,
                <TR key="6">
                    <TD bold>{t("share_contact_mail")}:</TD>
                    <TD>{getValueToShow(null, "contactMail")}</TD>
                </TR>,
                <TR key="7">
                    <TD bold>{t("share_reason")}:</TD>
                    <TD>{getValueToShow("share_reason", "reason")}</TD>
                </TR>,
                <TR key="8">
                    <TD bold>{t("share_reference")}:</TD>
                    <TD>{getValueToShow(null, "reference")}</TD>
                </TR>,
                <TR key="9">
                    <TD bold>{t("share_current")}:</TD>
                    <TD>{Number(getValueToShow(null, "currentNumberOfShares")).toLocaleString()}</TD>
                </TR>,
                <TR key="10">
                    <TD bold>{t("share_change")}:</TD>
                    <TD>{getValueToShow(null, "changeNumberOfShares")}</TD>
                </TR>,
                <TR key="11">
                    <TD bold>{t("share_percentage")}:</TD>
                    <TD>{`${Number(getValueToShow(null, "percentageNumberOfShares")).toLocaleString()} %`}</TD>
                </TR>,
                <TR key="12">
                    <TD bold>{t("share_new")}:</TD>
                    <TD>{getValueToShow(null, "newNumberOfShares")}</TD>
                </TR>,
                <TR key="13">
                    <TD bold>{t("share_effective_date")}:</TD>
                    <TD>{getValueToShow(null, "effectiveDate")}</TD>
                </TR>,
                <TR key="14">
                    <TD bold>{t("share_prospectus")}:</TD>
                    <TD>{this.props.data?.prospectusRequired  === true || this.props.data?.prospectusRequired === "true" ||
                    this.props.data?.prospectusRequired?.value === "true"
                        ? t("yes") : t("no")}</TD>
                </TR>
            ]
            if (this.props.data?.prospectusRequired  === true || this.props.data?.prospectusRequired  === "true" ||
                this.props.data?.prospectusRequired?.value === "true") {
                rows.push(<TR key="15">
                    <TD bold>{t("share_prospectus_reason")}:</TD>
                    <TD>{mapProspectusReasonYes()}</TD>
                </TR>)
                rows.push(<TR key="16">
                    <TD bold>{t("share_prospectus_yes_desc")}:</TD>
                    <TD>{getValueToShow(null, "prospectusYesMechanisms")}</TD>
                </TR>)
            } else {
                rows.push(<TR key="15">
                    <TD bold>{t("share_prospectus_no_desc")}:</TD>
                    <TD>{getValueToShow(null, "prospectusNoReason")}</TD>
                </TR>)
                rows.push(<TR key="17">
                    <TD bold>{t("share_prospectus_no_cumulative")}:</TD>
                    <TD>{getValueToShow(null, "prospectusNoCumulative")}</TD>
                </TR>)
            }
            rows.push(<TR key="18">
                <TD bold>{t("comments_optional")}:</TD>
                <TD>{getValueToShow(null, "comment")}</TD>
            </TR>)
            if (this.props.isSuperUser) {
                rows.splice(0, 0, (<TR key="0">
                    <TD bold>{t("organisation")}:</TD>
                    <TD>{getValueToShow("organisations", "organisationId")}</TD>
                </TR>));
            }
            return rows;
        }

        return (
            <form>
                <PageHeading margin={[0, 0, 16, 0]}>
                    {this.props.data?.id ? t("share_view") : t("share_preview") }
                </PageHeading>

                <Table
                    key={"issuance_preview"}
                    tableRows={getRows()}
                    tableHeaderLabels={tableHeaderLabels}
                    tableHeaderProps={tableHeaderProps}
                    noHeader
                />

                {(this.props.isSuperUser && this.props.data?.id) &&
                    <FlexContainer>
                        <FlexContainer  margin={[20, 0, 0, 0]}>
                            <PageHeading margin={[10, 0, 16, 0]} fontSize={"20"}>
                                {t("share_internal_fields")}
                            </PageHeading>
                            <Table
                                key={"issuance_preview_super"}
                                tableRows={[
                                    <TR key="1">
                                        <TD bold>{t("status")}:</TD>
                                        <TD>{mapStatus(parameters?.statusTypes, this.props.data.status, t)}</TD>
                                    </TR>,
                                    <TR key="2">
                                        <TD bold>{t("share_closed")}:</TD>
                                        <TD>{this.props.data.closed || this.props.data.status === 5 ?
                                        t("yes") : t("no")}</TD>
                                    </TR>,
                                    <TR key="3">
                                        <TD bold>{t("share_comment_internal")}:</TD>
                                        <TD>{getValueToShow(null, "internalComment")}</TD>
                                    </TR>
                                ]}
                                tableHeaderLabels={tableHeaderLabels}
                                tableHeaderProps={tableHeaderProps}
                                noHeader
                            />
                        </FlexContainer>
                        {this.props.data.commentToIssuer && <FlexContainer  margin={[20, 0, 0, 0]}>
                            <PageHeading margin={[10, 0, 16, 0]} fontSize={"20"}>
                                {t("share_comment_to_issuer")}
                            </PageHeading>
                            <Table
                                key={"share_comment_to_issuer"}
                                tableRows={[
                                    <TR key="1">
                                        <TD bold>{t("share_comment_to_issuer_sub", {communication: t("share_communication")})}:</TD>
                                        <TD>{this.props.data.commentToIssuer}</TD>
                                    </TR>
                                ]}
                                tableHeaderLabels={tableHeaderLabels}
                                tableHeaderProps={tableHeaderProps}
                                noHeader
                            />
                        </FlexContainer> }
                    </FlexContainer>
                }

                {this.props.data && hasValue(this.props.data.status) && !this.props.data.update ? //er allerede sendt inn
                    <FlexContainer row flexEnd vAlignCenter margin={[10, 0, 0, 0]}>
                        <FlexContainer row vAlignCenter>
                            <Icon src="/icons/back-arrow-24-px.svg" />
                            <Button.Text margin={[0, 0, 0, 8]}
                                         onClick={this.props.onCancel}>
                                {t("back")}
                            </Button.Text>
                        </FlexContainer>
                        <FlexContainer row flexEnd vAlignCenter>
                            {
                                this.props.isSuperUser && (this.props.data.status === 0 || this.props.data.status === 4) &&
                                <Button.Standard margin={[0, 10, 0, 0]} onClick={this.props.startProcessing}>
                                    {t("share_start_processing")}
                                </Button.Standard>
                            }
                            {(this.props.data.status === 0 || this.props.data.status === 3 || this.props.isSuperUser) &&
                                <Button.Standard margin={[0, 10, 0, 0]}
                                                 onClick={() => this.props.history.push(`${base}/form${this.props.id && "/" + this.props.id}`)}>
                                    {t("share_edit")}
                                </Button.Standard>
                            }
                        </FlexContainer>
                    </FlexContainer>
                    :
                    <FlexContainer row spaceBetween vAlignCenter margin={[20, 0, 0, 0]}>
                        <FlexContainer row vAlignCenter>
                            <Icon src="/icons/back-arrow-24-px.svg" />
                            <Button.Text margin={[0, 0, 0, 8]}
                                         onClick={() => this.props.history.push(`${base}/form`)}>
                                {t("back")}
                            </Button.Text>
                        </FlexContainer>
                        <FlexContainer row flexEnd vAlignCenter>
                            <Button.Text margin={[0, 10, 0, 0]}
                                         onClick={() => this.setState({cancelModal: true})}>
                                {t("cancel")}
                            </Button.Text>
                            <Button.Standard margin={[0, 10, 0, 0]}
                                             onClick={this.props.handleSubmit(this.props.onSubmit)}>
                                {t("submit")}
                            </Button.Standard>
                        </FlexContainer>
                    </FlexContainer>
                }

                <ConfirmModal
                    isOpen={this.state.cancelModal}
                    onClose={() => this.setState({cancelModal: false})}
                    width={"40%"}
                    header={t("share_cancel_headline")}
                    onConfirm={this.props.onCancel}
                    cancelText={t("cancel")}
                    confirmText={t("okay")}
                >
                    <FlexContainer center vAlignCenter>
                        {t("share_cancel_text")}
                    </FlexContainer>
                </ConfirmModal>

                {hasValue(this.props.data?.id) && !this.props.data?.update &&
                    <FlexContainer trueCenter>
                        <ChatBox {...this.props} comments={this.props.issuanceDetails.comments}
                                 id={this.props.data && hasValue(this.props.data.id)
                                     ? this.props.data.id : undefined}
                                 fetchIssuanceDetails={this.props.fetchIssuanceDetails}
                        />
                    </FlexContainer>
                }
            </form>
        );
    }
}

ShareIssuancePreview = reduxForm({
    form: "ShareIssuancePreview",
    destroyOnUnmount: true
})(ShareIssuancePreview);

ShareIssuancePreview.propTypes = {
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    fetchIssuanceDetails: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const { issuanceReducer } = state;

    return {
        instruments: issuanceReducer.issuanceInstruments.list
    };
}
export default connect(mapStateToProps)(
    withTranslation("translations")(ShareIssuancePreview)
);
