export const validateNewInsiderLogDetails = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );
  const requiredFieldsCheck = () => {
    if (values.company == null) {
      errors.company = props.t("required_field");
    }
    if (values.client == null) {
      errors.client = props.t("required_field");
    }
  };
  requiredFieldsCheck();
  return Object.keys(errors).length > 0 ? errors : undefined;
};
