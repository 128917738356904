import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { NotificationManager } from "react-notifications";
import { FileButton, WarningText } from "./FileUploader";
import Button from "./Button";

const SimpleFileUploader = props => {
  const { t, fileValidator, uploadFile } = props;
  var uploadInput = undefined;

  return [
    <FileButton
      key="uploadFileInput"
      accept={props.fileType}
      ref={element => {
        uploadInput = element;
        if (element) {
          element.onchange = e => {
            const newFile = element.files[0];
            if (newFile == null) return;

            const error = fileValidator(newFile);
            if (!error) {
              uploadFile(newFile);
              e.target.value = ""; // So that it is possible to select the same file twice if user deletes it first.
            } else {
              NotificationManager.warning(
                <WarningText>{error}</WarningText>,
                "",
                10000
              );
            }
          };
        }
      }}
    />,
    <Button.Standard
      key="uploadFileButton"
      alt={t("add_attachment")}
      title={t("add_attachment")}
      onClick={() => uploadInput.click()}
    >
      {props.children}
    </Button.Standard>
  ];
};

SimpleFileUploader.propTypes = {
  uploadFile: PropTypes.func.isRequired,
  fileValidator: PropTypes.func.isRequired
};

export default withTranslation("translations")(SimpleFileUploader);
