import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import RadioButton from "../components/RadioButton";

export const InsiderStateRadioButtons = props => {
  return (
    <FlexContainer row center>
      <RadioButton
        {...props}
        margin={[0, 30, 0, 0]}
        radioValue={0}
        label={props.t("show_active")}
      />
      <RadioButton
        {...props}
        margin={[0, 30, 0, 0]}
        radioValue={1}
        label={props.t("show_inactive")}
      />
      <RadioButton
        {...props}
        margin={[0, 0, 0, 0]}
        radioValue={2}
        label={props.t("show_both")}
      />
    </FlexContainer>
  );
};
