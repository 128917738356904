import {hasValue} from "../util/common";

export const validate = (values, props) => {
    const errors = {};
    const { t } = props;

    const validateFieldLength = (field, maximumCharacters) => {
        if (values[field] !== undefined && values[field].length > maximumCharacters) {
            errors[field] = t("field_length_exceeded", {length: maximumCharacters});
        }
    }

    const validateRequiredField = (field) => {
        if (values[field] == null || values[field].length === 0 || values[field].value === null) {
            errors[field] = t("required_field");
        }
    };

    if (props.isSuperUser) {
        validateRequiredField("organisationId");
    }

    validateRequiredField("issuerId");
    validateRequiredField("instrument");
    validateRequiredField("mic");
    validateRequiredField("reason");
    validateRequiredField("reference");
    validateRequiredField("currentNumberOfShares");
    validateRequiredField("changeNumberOfShares");
    validateRequiredField("percentageNumberOfShares");
    validateRequiredField("newNumberOfShares");
    validateRequiredField("effectiveDate");
    validateRequiredField("prospectusRequired");

    if (values["prospectusRequired"]?.value === "true" || values["prospectusRequired"] === true) {
        validateRequiredField("prospectusYesReason");
        validateRequiredField("prospectusYesMechanisms");
    } else {
        validateRequiredField("prospectusNoReason");
        validateRequiredField("prospectusNoCumulative");
    }
    validateRequiredField("contactName");
    validateRequiredField("contactPhone");
    validateRequiredField("contactMail");

    validateFieldLength("effectiveDate", 255);
    validateFieldLength("reason", 300);
    validateFieldLength("reference", 1000);
    validateFieldLength("comment", 1500);
    validateFieldLength("prospectusYesMechanisms", 1500);
    validateFieldLength("prospectusNoReason", 1500);
    validateFieldLength("prospectusNoAuthority", 1500);
    validateFieldLength("contactName", 255);
    validateFieldLength("contactPhone", 20);
    validateFieldLength("contactMail", 255);

    return errors;
}
