import React from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import Input from "../components/Input";
import Label from "../components/Label";

const ListInfoBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]} maxWidth>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : (
          undefined
        )}
      </Label>
      <Input {...props} {...props.input} border />
    </FlexContainer>
  );
};

class EditList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <FlexContainer>
            <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
              <FlexContainer>
                <Field
                  border
                  name="name"
                  component={ListInfoBox}
                  label={t("edist_list_name")}
                  placeholder={t("list_name")}
                  greyPlaceholder
                />

                <Field
                  border
                  name="description"
                  component={ListInfoBox}
                  label={t("edist_list_description")}
                  placeholder={t("edist_list_description")}
                  greyPlaceholder
                />

                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={this.props.handleSubmit(this.props.updateList)}
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text onClick={() => this.props.onClose()}>
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}
EditList = reduxForm({
  form: "editDistributionListForm",
  enableReinitialize: true
})(EditList);

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(EditList);
