import React from "react";
import "../css/react-select.css";
import {StyledSelect} from "./SearchableSelect";
import PropTypes from "prop-types";
import {FlexContainer} from "./FlexContainer";
import Label from "./Label";

class SearchableTextDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedValue: "",
            searchValue: "",
            options: {},
            originalOptions: {}
        }
        this.onSelectChange = this.onSelectChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.input.value.value && this.props.input.value==="") {
            this.setState({selectedValue: "", searchValue: ""})
        }
    }

    onSelectChange(val) {
        this.setState({ selectedValue: val, searchValue: "" });
        this.updateFieldValue(val)
    }

    updateFieldValue(val) {
        const { onChange } = this.props.input || this.props;
        if (onChange != null) onChange(val);
    }

    handleSearch(val) {
        if (val.length === 1) {
            this.deleteOldSearch()
        }
        if (val !== "") {
            this.setState({ searchValue: val, selectedValue: ""});
            this.updateFieldValue(val)
        }
    }

    handleBlur() {
        if (this.state.selectedValue === "" && this.state.searchValue !== "") {
            const options = this.state.options
            options.push({label: this.state.searchValue, value: this.state.searchValue})
            this.setState({selectedValue: {label: this.state.searchValue, value: this.state.searchValue}})
            this.updateFieldValue({label: this.state.searchValue, value: this.state.searchValue})
        }
    }

    deleteOldSearch() {
        if (this.state.searchValue!=="" && this.state.originalOptions.length > 0) {
            const searchInOriginal = this.state.originalOptions.some(v => (v.value === this.state.searchValue))
            if (!searchInOriginal) {
                const toDelete = this.props.options.filter(opt => {
                    return opt.value === this.state.searchValue;
                })[0]
                const index = this.props.options.indexOf(toDelete)
                if (index !== -1) {
                    this.props.options.splice(index, 1);
                }
            }
        }
        this.setState({searchValue: "", selectedValue: ""})
    }

    handleFocus() {
        if (this.props.options.length !== undefined && this.state.options.length === undefined) {
            const originalOptions = this.props.options.map(object=>({...object}))
            const options = this.props.options
            this.setState({options: options, originalOptions: originalOptions})
        }
    }

    render() {
        return (
            <FlexContainer>
                <Label htmlFor={"event"}>{this.props.label}</Label>
                <StyledSelect
                    border
                    greyPlaceholder
                    placeholder={this.props.placeholder}
                    value={this.state.selectedValue}
                    onChange={(e) => this.onSelectChange(e)}
                    onInputChange={(e) => this.handleSearch(e)}
                    onBlur={() => this.handleBlur()}
                    onFocus={() => this.handleFocus()}
                    {...this.props}
                />

            </FlexContainer>
        )
    }
}

SearchableTextDropdown.propTypes = {
    options: PropTypes.arrayOf(Object).isRequired,
    t: PropTypes.func.isRequired,
    input: PropTypes.object,
    onSelect: PropTypes.func,
}

SearchableTextDropdown.defaultProps = {
    searchable: true,
    clearable: true,
};

export default SearchableTextDropdown;