import React from "react";
import styled from "styled-components";
import { FlexContainer } from "./FlexContainer";
import FileUpload from "./FileUploader";
import { ErrorText } from "./ErrorText";

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #336699;
  width: 100%;
  height: 38px;
  color: #ffffff;
  padding-left: 16px;
  box-sizing: border-box;
  padding: 0 0 0 16px;
`;

const Title = styled.input`
  min-width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 14px;
  box-shadow: none;
  border: 0;
  height: 38px;
  font-family: inherit;
  font-size: inherit;
`;

const Message = styled.textarea`
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  height: 224px;
  resize: none;
  width: 100%;
  border: 8px;
  font-size: inherit;
  font-family: "Courier New", Courier, "Lucida Sans Typewriter",
    "Lucida Typewriter", monospace;
`;

export const MessageBox = props => {
  const {
    names,
    header,
    titlePlaceholder,
    messagePlaceholder,
    maxTextLength,
    maxTitleLength,
    maxColumns,
    maxRows,
    getAttachment,
    withFileUpload,
    showHelp,
    maxNumFiles,
    maxFileSize,
    fileType,
    validExtensions,
      fileValidation
  } = props;

  const titleError = props[names[0]].meta.error;
  const bodyError = props[names[1]].meta.error;

  const flexProps = {
    padding: !withFileUpload ? [0, 0, 10, 0] : [0, 0, 0, 0],
    backgroundColor: "rgba(178, 207, 174, 0.2)"
  };

  return (
    <div>
      <FlexContainer {...flexProps}>
        <Header>{header}</Header>
        <FlexContainer padding={[14, 8, 0, 8]} column>
          {titleError ? <ErrorText>{titleError}</ErrorText> : undefined}
          <FlexContainer row>
            <Title
              placeholder={titlePlaceholder}
              maxLength={maxTitleLength}
              {...props[names[0]].input}
              showHelp={showHelp}
            />
          </FlexContainer>
          {bodyError ? <ErrorText>{bodyError}</ErrorText> : undefined}
          <Message
            placeholder={messagePlaceholder}
            maxLength={maxTextLength}
            {...props[names[1]].input}
            cols={maxColumns}
            rows={maxRows}
          />
          {withFileUpload ? (
            <FileUpload
              getServerFile={getAttachment}
              {...props[names[2]]}
              maxFileSize={maxFileSize}
              fileType={fileType}
              maxNumFiles={maxNumFiles}
              validExtensions={validExtensions}
              fileValidation={fileValidation}
            />
          ) : (
            ""
          )}
        </FlexContainer>
      </FlexContainer>
    </div>
  );
};

MessageBox.defaultProps = {
  withFileUpload: true
};
