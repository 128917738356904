import React from "react";
import { Header } from "../insiderTool/components";
import { connect } from "react-redux";
import { FlexContainer } from "../components/FlexContainer";

import Label from "../components/Label";
import Table, { TD, TR } from "../components/Table";
import { getDateTimeInOBNTFormat } from "../insiderTool/UTCUtils";
import styled from "styled-components";
import { getNotificationStatusTextPostfix } from "./NotificationUtils";

const ListFlexContainer = styled(FlexContainer)`
    &:nth-child(odd) {
      background: rgba(178, 207, 174, 0.2);";
    }
    
    &:nth-child(even) {
      background: white;";
    }
  ;
`;

const Italic = styled.i`
  ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

export class NotificationViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { t, isSuperUser } = this.props;

    const thLabels = [
      t("first_name"),
      t("last_name"),
      t("position_within_issuer"),
      t("email"),
      t("phone"),
    ];

    const headerProps = [, , , ,];

    const tableRows =
      this.props.notificationObject.decisionmakers != null
        ? this.props.notificationObject.decisionmakers.map((entity, index) => (
            <TR key={index}>
              <TD>{entity.firstName}</TD>
              <TD>{entity.lastName}</TD>
              <TD>{entity.title}</TD>
              <TD>{entity.email}</TD>
              <TD>{entity.phone}</TD>
            </TR>
          ))
        : [];

    const getOrganisationName = (organisationId) => {
      if (this.props.organisationList) {
        const organisation = this.props.organisationList.find(
          (organisation) => organisation.id === organisationId
        );
        if (organisation) return organisation.name;
      }
      return "";
    };

    const getUTCText = (timestamp) => {
      return (
        <Italic margin={[0, 0, 0, 5]}>
          {" (UTC: " + getDateTimeInOBNTFormat(timestamp, true) + ")"}
        </Italic>
      );
    };

    return (
      <FlexContainer margin={[10, 0, 10, 0]} padding={[10, 10, 10, 10]}>
        <FlexContainer>
          <Header margin={[0, 0, 20, 0]} fontSize={18} bold>
            {t("notification_preview")}
          </Header>
          <FlexContainer row>
            <FlexContainer>
              {isSuperUser ? (
                <ListFlexContainer row>
                  <FlexContainer>
                    <Label bold>{t("organisation")}</Label>
                  </FlexContainer>
                  <FlexContainer>
                    <Label>
                      {getOrganisationName(
                        this.props.notificationObject.organisationId
                      )}
                    </Label>
                  </FlexContainer>
                </ListFlexContainer>
              ) : undefined}
              <ListFlexContainer row>
                <FlexContainer>
                  <Label bold>{t("issuer")}</Label>
                </FlexContainer>
                <FlexContainer>
                  <Label>
                    {this.props.notificationObject.issuer ||
                      this.props.notificationObject.issuerId}
                  </Label>
                </FlexContainer>
              </ListFlexContainer>
              <ListFlexContainer row>
                <FlexContainer>
                  <Label bold>{t("it_reference_to_announcement_en")}</Label>
                </FlexContainer>
                <FlexContainer breakWord>
                  <Label breakWord>{this.props.notificationObject.ref}</Label>
                </FlexContainer>
              </ListFlexContainer>
              <ListFlexContainer row>
                <FlexContainer>
                  <Label bold>{t("it_reference_to_announcement_no")}</Label>
                </FlexContainer>
                <FlexContainer breakWord>
                  <Label>{this.props.notificationObject.refNO}</Label>
                </FlexContainer>
              </ListFlexContainer>
              {this.props.notificationObject.createdTime ? (
                <ListFlexContainer row>
                  <FlexContainer>
                    <Label bold>{t("created")}</Label>
                  </FlexContainer>
                  <FlexContainer>
                    <Label>
                      {getDateTimeInOBNTFormat(
                        this.props.notificationObject.createdTime
                      )}
                      {getUTCText(this.props.notificationObject.createdTime)}
                    </Label>
                  </FlexContainer>
                </ListFlexContainer>
              ) : undefined}
              <ListFlexContainer row>
                <FlexContainer preWrap>
                  <Label bold>{t("it_date_time_responsible")}</Label>
                </FlexContainer>
                <FlexContainer>
                  <Label>
                    {getDateTimeInOBNTFormat(
                      this.props.notificationObject.decisionTime
                    )}
                    {getUTCText(this.props.notificationObject.decisionTime)}
                  </Label>
                </FlexContainer>
              </ListFlexContainer>
              {this.props.notificationObject.createdUsername ? (
                <ListFlexContainer row>
                  <FlexContainer>
                    <Label bold>{t("created_by")}</Label>
                  </FlexContainer>
                  <FlexContainer>
                    <Label>
                      {this.props.notificationObject.createdUsername}
                    </Label>
                  </FlexContainer>
                </ListFlexContainer>
              ) : undefined}
              <ListFlexContainer>
                <FlexContainer row>
                  <FlexContainer>
                    <Label bold>{t("status")}</Label>
                  </FlexContainer>
                  <FlexContainer>
                    <Label>
                      {t(
                        "notification_status_" +
                          getNotificationStatusTextPostfix(
                            this.props.notificationObject.status
                          )
                      )}
                    </Label>
                  </FlexContainer>
                </FlexContainer>
              </ListFlexContainer>
              <ListFlexContainer row>
                <FlexContainer>
                  <Label bold>{t("comments")}</Label>
                </FlexContainer>
                <FlexContainer preWrap breakWord>
                  <Label>{this.props.notificationObject.text}</Label>
                </FlexContainer>
              </ListFlexContainer>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer>
          <Header margin={[20, 0, 20, 0]} fontSize={18} bold>
            {t("it_person_nca")}
          </Header>

          <FlexContainer>
            <ListFlexContainer row>
              <FlexContainer>
                <Label bold>{t("first_name")}</Label>
              </FlexContainer>
              <FlexContainer>
                <Label>
                  {this.props.notificationObject.submitter.firstName}
                </Label>
              </FlexContainer>
            </ListFlexContainer>
            <ListFlexContainer row>
              <FlexContainer>
                <Label bold>{t("last_name")}</Label>
              </FlexContainer>
              <FlexContainer>
                <Label>
                  {this.props.notificationObject.submitter.lastName}
                </Label>
              </FlexContainer>
            </ListFlexContainer>
            <ListFlexContainer row>
              <FlexContainer>
                <Label bold>{t("position_within_issuer")}</Label>
              </FlexContainer>
              <FlexContainer>
                <Label>{this.props.notificationObject.submitter.title}</Label>
              </FlexContainer>
            </ListFlexContainer>
            <ListFlexContainer row>
              <FlexContainer>
                <Label bold>{t("email_work")}</Label>
              </FlexContainer>
              <FlexContainer>
                <Label>{this.props.notificationObject.submitter.email}</Label>
              </FlexContainer>
            </ListFlexContainer>
            <ListFlexContainer row>
              <FlexContainer>
                <Label bold>{t("phone_work")}</Label>
              </FlexContainer>
              <FlexContainer>
                <Label>{this.props.notificationObject.submitter.phone}</Label>
              </FlexContainer>
            </ListFlexContainer>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer />

        <Header margin={[20, 0, 10, 0]} fontSize={18} bold>
          {t("it_responsible_decision_header_list")}
        </Header>

        <Table
          tableRows={tableRows}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
          forceUpdate={true}
          t={t}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { notificationReducer } = state;

  return {
    notificationObject: notificationReducer.notification.data,
    organisationList: notificationReducer.organisationList.list,
  };
}

export default connect(mapStateToProps)(NotificationViewer);
