import React from "react";
import styled from "styled-components";

const DropDownContainer = styled.div`
  display: block;
  ${props => (props.containerNoMarginTop ? "" : "margin-top: 10px")};

  background-color: rgb(238, 242, 247);
  padding: 10px 10px 10px 0;
  ${props => (props.withLeftPadding ? "padding: 10px" : "")};
`;

const DropDownHeaderContainer = styled.div`
  display: block;
  background-color: rgb(238, 242, 247);
  ${props => (props.parentPadding ? "padding: 10px 10px 10px 0" : "")};
  //margin-top: 10px;
`;

const DropDownIcon = styled.img`
  margin-left: 16px;
  cursor: pointer;
  align: center;

  padding-right: 25px;
`;

const DropDownHeader = styled.h3`
  cursor: pointer;
  text-align: left;
  color: black;
  margin: 0px;

  font-size: 16px;

  ${props => (props.boldHeader ? "font-weight: 700;" : " font-weight: 600;")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const DropDownBody = styled.div`
  background-color: rgb(238, 242, 247);

  ${props => (props.noBodyPadding ? "" : "padding: 16px")};
  //just for test
  min-height: 30px;
`;

export class GreyDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = {
      isOpen: false
    };
  }

  onClick() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { header, children, ...rest } = this.props;

    return (
      <DropDownContainer
        withLeftPadding={this.props.withLeftPadding}
        containerNoMarginTop={this.props.containerNoMarginTop}
      >
        <DropDownHeaderContainer
          id={"dropDownHeaderContainer"}
          onClick={this.onClick}
          parentPadding={this.props.parentPadding}
        >
          <DropDownHeader boldHeader={this.props.boldHeader}>
            {header}
            <DropDownIcon
              key={"icon"}
              src={
                this.state.isOpen
                  ? "/icons/ic-keyboard-arrow-up-black-24-px.svg"
                  : "/icons/ic-keyboard-arrow-down-black-24-px.svg"
              }
            />
          </DropDownHeader>
        </DropDownHeaderContainer>
        {this.state.isOpen ? (
          <DropDownBody
            id={"dropDownBody"}
            {...rest}
            isOpen={this.state.isOpen}
          >
            {children}
          </DropDownBody>
        ) : (
          ""
        )}
      </DropDownContainer>
    );
  }
}
