import React from "react";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {casLogin} from "./LoginActions";
import {parse} from "query-string";
import {setupLoginData} from "../actions/CommonActions";
import Loader from "../components/Loader";
import Button from "../components/Button";
import {browserIsIE} from "../util/common";
import styled from "styled-components";
import {Header} from "../insiderTool/components";

const Text = styled.p`
  font-weight: 100;
  font-size: 16px;
`;

class LoginSSO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      redirectUrl: undefined,
      twoFactorError: undefined,
      ticketError: undefined,
      redirect: false
    };
  }

  componentDidMount() {
    const search = window.location.search;
    const pathname = window.location.pathname;

    if (
      this.props.ssoEnabled &&
      search &&
      search.length > 0 &&
      !pathname.includes("loginPWD")
    ) {
      const parsedParams = parse(window.location.search, {
        decode: false
      });
      if (parsedParams && parsedParams.ticket) {
        this.props
          .dispatch(casLogin(parsedParams.ticket))
          .then(res => {
            if (res?.data?.status === 401) {
              const redirectUrl = res.data.details.find(detail => {
                return detail.code === 1400;
              }).message;
              const twoFactorError = res.data.details.find(detail => {
                return detail.code === 1401;
              });
              const ticketError = res.data.details.find(detail => {
                return detail.code === 1402;
              });
              this.setState({
                redirectUrl: redirectUrl,
                twoFactorError: twoFactorError !== undefined,
                ticketError: ticketError !== undefined,
                loading: false
              });
            } else {
              if (browserIsIE()) {
                window.location.assign("/?token=" + res.token);
              } else
                return this.props
                  .dispatch(setupLoginData(true))
                  .then(() => this.props.setTimer());
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          });
      }
    } else {
      this.props
        .dispatch(casLogin())
        .then(res => {
          if (res?.data?.status === 401) {
            const details = res.data.details;
            const redirectObject = details.find(detail => {
              return detail.code === 1400;
            });
            const redirectUrl =
              redirectObject && redirectObject.message
                ? redirectObject.message
                : undefined;
            const redirect = details.find(detail => {
              return detail.code === 1404;
            });
            if (redirect && redirectUrl) {
              this.setState({
                redirectUrl: redirectUrl,
                redirect: redirect,
                loading: false
              });
            } else {
              this.setState({ loading: false });
            }
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { t } = this.props;
    if (this.state.loading) {
      return <Loader />;
    }

    const getText = () => {
      if (this.state.twoFactorError) {
        return t("sso_missing_2fa");
      } else if (this.state.ticketError) {
        return t("sso_invalid_ticket");
      } else if (this.state.redirect) {
        return t("sso_redirect");
      } else {
        return (
          <FlexContainer>
            <FlexContainer vAlignCenter>
              <Header fontSize={20}>{t("sso_no_redirect_info")}</Header>
            </FlexContainer>
            <Text>
              {t("contact_ma_during_opening_hours_sso")} <br />
              {t("phone")}: + 47 22 34 19 45<br />
            </Text>
            <Text>
              {t("contact_after_close_sso")} <br />
              {t("phone")}: + 47 22 34 19 90
            </Text>
          </FlexContainer>
        );
      }
    };

    return (
      <FlexContainer maxWidth={840}>
        <FlexContainer center vAlignCenter margin={[50, 0, 0, 0]}>
          {getText()}
          {this.state.redirectUrl ? (
            <Button.Standard
              onClick={() => (window.location = this.state.redirectUrl)}
              margin={[30, 0, 0, 0]}
            >
              {t("sso_go_to_connect")}
            </Button.Standard>
          ) : (
            undefined
          )}
        </FlexContainer>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { ssoEnabled } = state;
  return {
    ssoEnabled: ssoEnabled.ssoEnabled
  };
}

LoginSSO = connect(mapStateToProps)(LoginSSO);

export default LoginSSO;
