import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import Label from "../components/Label";
import Table, { TR, TD } from "../components/Table";
import { InputBox, DateBox } from "../insiderTool/components";
import Button from "../components/Button";
import { getOBNTDateTime } from "../util/common";
import SearchableSelectNew from "../components/SearchableSelectNew";
import { ActionText } from "../components/ActionText";

export const CompanyDropDown = props => {
  const { t } = props;
  return (
    <FlexContainer column margin={[0, 8, 10, 0]}>
      <Label
        bold
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>

      <SearchableSelectNew
        {...props}
        clearable={true}
        searchable={true}
        onSelect={props.onCompanySelect}
        selectIfOnlyOneOption
      />
    </FlexContainer>
  );
};

class EmailDistributionStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    const { t } = this.props;
    const thLabels = [
      t("organisation"),
      t("edist_message_id"),
      t("edist_news_id"),
      t("edist_issuer_id"),
      t("issuer"),
      t("edist_list_id"),
      t("email"),
      t("edist_distribution_date"),
      t("status")
    ];

    const tableRows =
      this.props.distributionStatusList != null
        ? this.props.distributionStatusList.map((entity, index) => (
            <TR key={index}>
              <TD>{entity.orgName}</TD>
              <TD>{entity.messageId}</TD>
              <TD>{entity.newsId}</TD>
              <TD>{entity.issuerId}</TD>
              <TD>{entity.issuerName}</TD>
              <TD>{entity.listId}</TD>
              <TD>{entity.email}</TD>
              <TD>{getOBNTDateTime(entity.distributionDate)}</TD>
              <TD>
                {t("edist_distribution_status_" + entity.distributionStatus)}
              </TD>
            </TR>
          ))
        : [];

    return (
      <React.Fragment>
        <form autoComplete="off">
          <FlexContainer margin={[0, 0, 30, 0]}>
            <FlexContainer row>
              <Field
                component={CompanyDropDown}
                name={"companySelect"}
                label={t("company")}
                options={this.props.companyList}
                onStatusSearch={this.props.onStatusSearch}
                place
                placeholder={t("select")}
                t={t}
              />
              <FlexContainer margin={[0, 20, 0, 0]}>
                <Field
                  component={InputBox}
                  name={"messageId"}
                  margin={[0, 0, 0, 20]}
                  bold
                  label={t("edist_message_id")}
                />
              </FlexContainer>

              <FlexContainer margin={[0, 20, 0, 0]}>
                <Field
                  component={InputBox}
                  name={"email"}
                  margin={[0, 0, 0, 20]}
                  bold
                  label={t("email")}
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer row>
              <FlexContainer margin={[0, 20, 0, 0]}>
                <Field
                  border
                  name="fromDate"
                  component={DateBox}
                  label={t("fromDate")}
                />
              </FlexContainer>
              <FlexContainer>
                <Field
                  border
                  name="toDate"
                  component={DateBox}
                  label={t("toDate")}
                />
              </FlexContainer>

              <FlexContainer margin={[0, 0, 0, 0]} row flexEnd vAlignEnd>
                <ActionText
                  margin={[0, 20, 5, 0]}
                  onClick={() => {
                    this.props.resetSearch();
                  }}
                >
                  {t("reset_search")}
                </ActionText>

                <Button.Standard
                  onClick={this.props.handleSubmit(this.props.onStatusSearch)}
                >
                  {t("search")}
                </Button.Standard>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <Table
            tableRows={tableRows}
            tableHeaderLabels={thLabels}
            forceUpdate={true}
            t={t}
          />
        </form>
      </React.Fragment>
    );
  }
}
EmailDistributionStatus = reduxForm({
  form: "distributionStatusForm",
  enableReinitialize: true
})(EmailDistributionStatus);

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(EmailDistributionStatus);
