import {deleteData, fetchData, postData, putData} from "../actions/CommonActionsNoObsvc";

const prefix = "v1/company/issuance";

export const GetIssuanceList = {
    request: "GET_ISSUANCE_LIST",
    receive: "GET_ISSUANCE_LIST_RECEIVE",
    receiveFail: "GET_ISSUANCE_LIST_FAIL",
};

export const fetchIssuanceList = (params = undefined) =>
    fetchData(GetIssuanceList, params, prefix + "/list");

export const GetIssuanceDetails = {
    request: "GET_ISSUANCE_DETAILS",
    receive: "GET_ISSUANCE_DETAILS_RECEIVE",
    receiveFail: "GET_ISSUANCE_DETAILS_FAIL",
    clear: "CLEAR_ISSUANCE_DETAILS_KEYS"
};

export const fetchIssuanceDetails = (id) =>
    fetchData(GetIssuanceDetails, undefined, prefix + "/get/" + id);

export const CreateIssuance = {
    request: "CREATE_ISSUANCE",
    postFail: "CREATE_ISSUANCE",
    postSuccess: "CREATE_ISSUANCE_SUCCESS",
};

export const clearIssuanceDetails = () => ({ type: GetIssuanceDetails.clear });

export const createIssuance = (form) =>
    postData(CreateIssuance, undefined, { ...form }, prefix + "/add", true);

export const UpdateIssuance = {
    request: "UPDATE_ISSUANCE",
    putSuccess: "UPDATE_ISSUANCE_SUCCESS",
    putFail: "UPDATE_ISSUANCE_FAIL",
};

export const updateIssuance = (form, id) =>
    putData(UpdateIssuance, undefined, { ...form }, prefix + "/update/" + id);

export const UpdateIssuanceStatus = {
    request: "UPDATE_ISSUANCE_STATUS",
    putSuccess: "UPDATE_ISSUANCE_STATUS_SUCCESS",
    putFail: "UPDATE_ISSUANCE_STATUS_FAIL",
};

export const updateIssuanceStatus = (id, status) =>
    putData(UpdateIssuanceStatus, undefined, status, prefix + "/updateStatus/" + id);

export const DeleteIssuance = {
    request: "DELETE_ISSUANCE",
    deleteSuccess: "DELETE_ISSUANCE_SUCCESS",
    deleteFail: "DELETE_ISSUANCE_FAIL",
};

export const deleteIssuance = (id) =>
    deleteData(DeleteIssuance, prefix + "/delete/" + id);

export const CreateComment = {
    request: "CREATE_COMMENT",
    postFail: "CREATE_COMMENT",
    postSuccess: "CREATE_COMMENT_SUCCESS",
};

export const postComment = (form, id) =>
    postData(CreateComment, undefined, form, prefix + "/comment/" + id, false);

export const GetIssuanceParameters = {
    request: "GET_ISSUANCE_PARAMETERS",
    receive: "GET_ISSUANCE_PARAMETERS_RECEIVE",
    receiveFail: "GET_ISSUANCE_PARAMETERS_FAIL",
};

export const fetchIssuanceParameters = () =>
    fetchData(GetIssuanceParameters, undefined, prefix + "/parameters");

export const GetIssuanceInstruments = {
    request: "GET_ISSUANCE_INSTRUMENTS",
    receive: "GET_ISSUANCE_INSTRUMENTS_RECEIVE",
    receiveFail: "GET_ISSUANCE_INSTRUMENTS_FAIL",
    clear: "CLEAR_ISSUANCE_INSTRUMENTS_KEYS"
};

export const fetchIssuanceInstruments = (cid) =>
    fetchData(GetIssuanceInstruments, undefined, prefix + "/instruments/" + cid);

export const clearIssuanceInstruments = () => ({ type: GetIssuanceInstruments.clear });

export const GetIssuanceIssuers = {
    request: "GET_ISSUANCE_ISSUERS",
    receive: "GET_ISSUANCE_ISSUERS_RECEIVE",
    receiveFail: "GET_ISSUANCE_ISSUERS_FAIL",
    clear: "CLEAR_ISSUANCE_ISSUERS_KEYS"
};

export const fetchIssuanceIssuers = (id) =>
    fetchData(GetIssuanceIssuers, undefined, prefix + "/issuers/" + id);

export const clearIssuanceIssuers = () => ({ type: GetIssuanceIssuers.clear });
