import {
  createData,
  deleteData,
  fetchData,
  updateData,
  uploadData
} from "../actions/CommonActions";

export const InsiderType = {
  LIST: "list",
  CONFIDENTIALITY_LIST: "confidentialityList",
  PERMANENT_INSIDERS: "permanentInsiders",
  PERSON: "insider",
  STATE: "insider.state",
  USER: "user",
  ATTACHMENT: "attachment",
  DOCUMENT: "document",
  MANDATORY_KEY_INFO: "keyInfo",
  MANDATORY_NCA_INFO: "ncaKeyInfo"
};

export const PERMANENT_ID = "global";

export const InsiderToolActions = {
  create: "CREATE_INSIDERLIST",
  createFail: "CREATE_INSIDERLIST_FAILED",
  createSuccess: "CREATE_INSIDERLIST_SUCCESS",

  update: "UPDATE_INSIDERLIST",
  updateFail: "UPDATE_INSIDERLIST_FAILED",
  updateSuccess: "UPDATE_INSIDERLIST_SUCCESS",

  request: "REQUEST_INSIDERLIST",
  receive: "RECEIVE_INSIDERLIST",
  receiveFail: "RECEIVE_INSIDERLIST_FAILED",

  updateUTC: "UPDATE_UTC_TIME"
};
export const createInsiderListData = (listId, data) =>
  createData({
    ...InsiderToolActions,
    params: {
      listId,
      data: {
        type: InsiderType.LIST,
        ...data
      }
    },
    obsvcFilename: "it/createInsiderToolList.obsvc"
  });

export const createListBasedOnOtherList = (listId, type) =>
  createData({
    ...InsiderToolActions,
    params: {
      listId,
      type
    },
    obsvcFilename: "it/createListFromOtherList.obsvc"
  });

export const updateUTCTime = value => ({
  type: InsiderToolActions.updateUTC,
  value: value
});

export const createConfidentialListData = (listId, data) =>
  createData({
    ...InsiderToolActions,
    params: {
      listId,
      data: {
        type: InsiderType.CONFIDENTIALITY_LIST,
        ...data
      }
    },
    obsvcFilename: "it/createInsiderToolList.obsvc"
  });

export const updateInsiderListData = (type, data, listId) =>
  updateData({
    ...InsiderToolActions,
    params: {
      listId,
      data,
      type
    },
    obsvcFilename: "it/updateInsiderListData.obsvc"
  });

export const updateInsiderListExtraData = (type, data, listId) =>
  updateData({
    ...InsiderToolActions,
    params: {
      listId,
      data,
      type
    },
    obsvcFilename: "it/updateInsiderListExtraData.obsvc"
  });

export const PermanentListActions = {
  request: "REQUEST_PERMANENTLIST",
  receive: "RECEIVE_PERMANENTLIST",
  receiveFail: "RECEIVE_PERMANENTLIST_FAILED"
};

export const fetchInsiderList = (listId, when = null) =>
  listId === PERMANENT_ID
    ? fetchData({
        ...PermanentListActions,
        params: { when },
        obsvcFilename: "it/getPermanentList.obsvc"
      })
    : fetchData({
        ...InsiderToolActions,
        params: {
          listId,
          when
        },
        obsvcFilename: "it/getInsiderToolList.obsvc"
      });

export const InsiderListListActions = {
  request: "REQUEST_INSIDERLIST_LIST",
  receive: "RECEIVE_INSIDERLIST_LIST",
  receiveFail: "RECEIVE_INSIDERLIST_LIST_FAILED"
};

export const fetchInsiderListList = _ =>
  fetchData({
    ...InsiderListListActions,
    obsvcFilename: "it/getInsiderToolListList.obsvc"
  });

export const InsiderActions = {
  create: "CREATE_INSIDER",
  createFail: "CREATE_INSIDER_FAILED",
  createSuccess: "CREATE_INSIDER_SUCCESS",

  request: "REQUEST_INSIDERS",
  receive: "RECEIVE_INSIDERS",
  receiveFail: "RECEIVE_INSIDERS_FAILED",

  update: "UPDATE_INSIDER",
  updateFail: "UPDATED_INSIDER_FAILED",
  updateSuccess: "UPDATE_INSIDER_SUCCESS",

  delete: "DELETE_INSIDER",
  deleteFailed: "DELETE_INSIDER_FAILED",
  deleteSuccess: "DELETE_INSIDER_SUCCESS"
};
export const fetchAllInsiders = _ =>
  fetchData({
    ...InsiderActions,
    obsvcFilename: "it/getAllInsiders.obsvc"
  });

export const createInsider = (id, listId, data) =>
  createData({
    ...InsiderActions,
    params: {
      listId,
      id,
      type: InsiderType.PERSON,
      data: { ...data }
    },
    obsvcFilename: "it/createInsiderToolInsider.obsvc"
  });

export const updateInsider = (id, listId, data, type) =>
  updateData({
    ...InsiderActions,
    params: {
      listId,
      id,
      type: type,
      data: { ...data }
    },
    obsvcFilename: "it/updateInsiderToolInsider.obsvc"
  });

export const deleteInsider = (id, listId) => {
  return deleteData({
    ...InsiderActions,
    params: {
      id,
      listId,
      type: InsiderType.PERSON,
      data: {}
    },
    obsvcFilename: "it/deleteInsiderToolInsider.obsvc"
  });
};

export const InsiderMultiActions = {
  create: "CREATE_MULTI_INSIDER",
  createFail: "CREATE_MULTI_INSIDER_FAILED",
  createSuccess: "CREATE_MULTI_INSIDER_SUCCESS"
};

export const createMultipleInsiders = (listId, items) =>
  createData({
    ...InsiderMultiActions,
    params: {
      listId,
      //type: InsiderType.PERSON,
      items
    },
    obsvcFilename: "it/addMultipleInsiders.obsvc"
  });

export const UserList = {
  request: "REQUEST_INSIDERTOOL_USERLIST",
  receive: "RECEIVE_INSIDERTOOL_USERLIST",
  receiveFail: "RECEIVE_INSIDERTOOL_USERLIST_FAILED",

  clear: "CLEAR_INSIDERTOOL_USERLIST"
};

export const fetchUserList = (organisationId, filter) =>
  fetchData({
    ...UserList,
    obsvcFilename: "it/getUserList.obsvc",
    params: { organisationId, filter }
  });

export const TraceLogActions = {
  request: "REQUEST_INSIDERTOOL_TRACELOG",
  receive: "RECEIVE_INSIDERTOOL_TRACELOG",
  receiveFail: "RECEIVE_INSIDERTOOL_TRACELOG_FAILED"
};

export const fetchTraceLog = listId =>
  fetchData({
    ...TraceLogActions,
    obsvcFilename: "it/getInsiderToolTracelog.obsvc",
    params: {
      listId
    }
  });

export const AddUserActions = {
  create: "CREATE_USER",
  createFail: "CREATE_USER_FAILED",
  createSuccess: "CREATE_USER_SUCCESS"
};

export const addUser = (user, listId, level) =>
  createData({
    ...AddUserActions,
    params: {
      listId,
      user,
      level
    },
    obsvcFilename: "it/createInsiderToolUser.obsvc"
  });

export const JsonExcelActions = {
  request: "REQUEST_JSONEXCEL_LIST",
  receive: "RECEIVE_JSONEXCEL_LIST",
  receiveFail: "RECEIVE_JSONEXCEL_LIST_FAILED",

  upload: "UPLOAD_EXCEL_LIST",
  uploadFailed: "UPLOAD_EXCEL_LIST",
  uploadSuccess: "UPLOAD_EXCEL_LIST"
};

export const uploadExcelAttachment = attachment =>
  uploadData({
    ...JsonExcelActions,
    obsvcFilename: "it/uploadExcel.obsvc",
    params: attachment
  });

export const fetchJsonExcel = () => {
  return { type: JsonExcelActions.receive };
};

export const ITAttachmentActions = {
  request: "REQUEST_IT_ATTACHMENT",
  receive: "RECEIVE_IT_ATTACHMENT",
  receiveFail: "RECEIVE_IT_ATTACHMENT_FAILED",

  upload: "UPLOAD_IT_ATTACHMENT",
  uploadFailed: "UPLOAD_IT_ATTACHMENT_FAILED",
  uploadSuccess: "UPLOAD_IT_ATTACHMENT_SUCCESS",

  delete: "DELETE_IT_ATTACHMENT",
  deleteFailed: "DELETE_IT_ATTACHMENT_FAILED",
  deleteSuccess: "DELETE_IT_ATTACHMENT_SUCCESS"
};

export const uploadAttachment = (attachment, type) =>
  type === InsiderType.PERMANENT_INSIDERS || type === InsiderType.DOCUMENT
    ? uploadData({
        ...ITAttachmentActions,
        obsvcFilename: "it/uploadITGlobalAttachment.obsvc",
        params: attachment
      })
    : uploadData({
        ...ITAttachmentActions,
        obsvcFilename: "it/uploadITAttachment.obsvc",
        params: attachment
      });

export const fetchAttachment = (id, parentId, type) =>
  type === InsiderType.PERMANENT_INSIDERS || type === InsiderType.DOCUMENT
    ? fetchData({
        ...ITAttachmentActions,
        obsvcFilename: "it/getITGlobalAttachment.obsvc?obsvc.item=1",
        params: { id, parentId }
      })
    : fetchData({
        ...ITAttachmentActions,
        obsvcFilename: "it/getITAttachment.obsvc?obsvc.item=1",
        params: { id, listId: parentId }
      });

export const deleteAttachment = (id, parentId, type) =>
  type === InsiderType.PERMANENT_INSIDERS || type === InsiderType.DOCUMENT
    ? deleteData({
        ...ITAttachmentActions,
        obsvcFilename: "it/removeITGlobalAttachment.obsvc",
        params: { id, parentId }
      })
    : deleteData({
        ...ITAttachmentActions,
        obsvcFilename: "it/removeITAttachment.obsvc",
        params: { id, listId: parentId }
      });

const NotifyMultipleInsidersActions = {
  update: "NOTIFY_MULTIPLE_INSIDER",
  updateFail: "NOTIFY_MULTIPLE_INSIDER_FAILED",
  updateSuccess: "NOTIFY_MULTIPLE_INSIDER_SUCCESS"
};

export const notifyMultipleInsiders = (ids, listId, body, lang, templateName) =>
  updateData({
    ...NotifyMultipleInsidersActions,
    obsvcFilename: "it/notifyMultipleInsiders.obsvc",
    params: {
      listId,
      ids,
      ...body,
      lang,
      templateName
    }
  });

const InactivatePersonActions = {
  update: "INACTIVATE_PERSON",
  updateFail: "INACTIVATE_PERSON_FAILED",
  updateSuccess: "INACTIVATE_PERSON_SUCCESS"
};

export const inactivatePerson = (
  id,
  listId,
  body,
  lang,
  templateName,
  sendMail
) =>
  updateData({
    ...InactivatePersonActions,
    obsvcFilename: "it/inactivatePerson.obsvc",
    params: {
      listId,
      id,
      ...body,
      lang,
      templateName,
      sendMail
    }
  });

const ConfirmInsiderActions = {
  update: "CONFIRM_INSIDER",
  updateFailed: "CONFIRM_INSIDER_FAILED",
  updateSuccess: "CONFIRM_INSIDER_SUCCESS"
};

export const confirmInsider = (id, listId) =>
  updateData({
    ...ConfirmInsiderActions,
    obsvcFilename: "it/confirmInsider.obsvc",
    params: {
      listId,
      id
    }
  });

export const TemplateActions = {
  request: "REQUEST_TEMPLATE_LIST",
  receive: "RECEIVE_TEMPLATE_LIST",
  receiveFail: "RECEIVE_TEMPLATE_LIST_FAILED"
};

export const fetchTemplate = (lang, name) =>
  fetchData({
    ...TemplateActions,
    obsvcFilename: "it/getMailTemplate.obsvc",
    params: {
      lang,
      name
    }
  });

export const ExternalInsiderActions = {
  request: "REQUEST_EXTERNAL_INSIDER",
  receive: "RECEIVE_EXTERNAL_INSIDER",
  receiveFail: "RECEIVE_EXTERNAL_INSIDER_FAILED",
  update: "UPDATE_EXTERNAL_INSIDER",
  updateFail: "UPDATE_EXTERNAL_INSIDER_FAILED",
  updateSuccess: "UPDATE_EXTERNAL_INSIDER_SUCCESS"
};

export const fetchInsiderExternal = token =>
  fetchData(
    {
      ...ExternalInsiderActions,
      params: { token },
      obsvcFilename: "it/getExternalInsider.obsvc"
    },
    undefined,
    undefined,
    false,
    true
  );

export const fetchInsiderActions = {
  request: "REQUEST_INSIDERS",
  receive: "RECEIVE_INSIDERS",
  receiveFail: "RECEIVE_INSIDERS_FAILED"
};

export const fetchInsider = id => (dispatch, getState) => {
  const { insiderToolReducer } = getState();

  return dispatch({
    type: fetchInsiderActions.receive,
    data: insiderToolReducer.document.map.get(id),
    attachments: insiderToolReducer.document.fullAttachmentList.filter(
      a => a.parentId === id
    )
  });
};

export const fetchInsiderExternalWithMfaCode = (token, code) =>
  fetchData(
    {
      ...ExternalInsiderActions,
      params: { token, code },
      obsvcFilename: "it/getExternalInsider.obsvc"
    },
    undefined,
    undefined,
    false,
    true
  );

export const updateInsiderExternal = (id, listId, data, type, token, code) =>
  updateData({
    ...InsiderActions,
    params: {
      listId,
      id,
      type: type,
      data: { ...data },
      token,
      code
    },
    obsvcFilename: "it/updateExternalInsider.obsvc"
  });

const CloseListActions = {
  update: "CLOSE_LIST",
  updateFail: "CLOSE_LIST_FAILED",
  updateSuccess: "CLOSE_LIST_SUCCESS"
};
export const closeList = listId =>
  updateData({
    ...CloseListActions,
    params: {
      listId
    },
    obsvcFilename: "it/closeList.obsvc"
  });

const ReopenListActions = {
  update: "REOPEN_LIST",
  updateFail: "REOPEN_LIST_FAILED",
  updateSuccess: "REOPEN_LIST_SUCCESS"
};
export const reopenList = listId =>
  updateData({
    ...ReopenListActions,
    params: {
      listId
    },
    obsvcFilename: "it/reopenList.obsvc"
  });

const SendMailToInsidersActions = {
  update: "SEND_MAIL_TO_INSIDERS",
  updateFail: "SEND_MAIL_TO_INSIDERS_FAILED",
  updateSuccess: "SEND_MAIL_TO_INSIDERS_SUCCESS"
};
export const sendMailToInsiders = (listId, body, templateName) =>
  updateData({
    ...SendMailToInsidersActions,
    params: {
      listId,
      body,
      templateName
    },
    obsvcFilename: "it/sendMailToInsiders.obsvc"
  });

export const KnownPersonsAction = {
  request: "REQUEST_INSIDERTOOL_KNOWN_PERSONS",
  receive: "RECEIVE_INSIDERTOOL_KNOWN_PERSONS",
  receiveFail: "RECEIVE_INSIDERTOOL_KNOWN_PERSONS_FAILED",

  clear: "CLEAR_INSIDERTOOL_KNOWN_PERSONS"
};

export const fetchKnownPersons = () =>
  fetchData({
    ...KnownPersonsAction,
    obsvcFilename: "it/getKnownPersons.obsvc"
  });

export const DocumentAction = {
  request: "REQUEST_INSIDERTOOL_DOCUMENT",
  receive: "RECEIVE_INSIDERTOOL_DOCUMENT",
  receiveFail: "RECEIVE_INSIDERTOOL_DOCUMENT_FAILED",

  update: "UPADATE_INSIDERTOOL_DOCUMENT",
  updateSuccess: "UPDATE_INSIDERTOOL_DOCUMENT_SUCCESS",
  updateFail: "UPDATE_INSIDERTOOL_DOCUMENT_FAILED",

  create: "CREATE_INSIDERLIST_DOCUMENT",
  createFail: "CREATE_INSIDERLIST_DOCUMENT_FAILED",
  createSuccess: "CREATE_INSIDERLIST_DOCUMENT_SUCCESS",

  delete: "DELETE_IT_DOCUMENT",
  deleteFailed: "DELETE_IT_DOCUMENT_FAILED",
  deleteSuccess: "DELETE_IT_DOCUMENT_SUCCESS"
};

export const createInsiderListDocument = data =>
  createData({
    ...DocumentAction,
    params: {
      type: InsiderType.DOCUMENT,
      data
    },
    obsvcFilename: "it/createDocument.obsvc"
  });

export const updateInsiderListDocument = (id, data) =>
  updateData({
    ...DocumentAction,
    params: {
      type: InsiderType.DOCUMENT,
      id,
      data
    },
    obsvcFilename: "it/createDocument.obsvc"
  });

export const deleteInsiderListDocument = id =>
  deleteData({
    ...DocumentAction,
    params: {
      type: InsiderType.DOCUMENT,
      id,
      data: null
    },
    obsvcFilename: "it/createDocument.obsvc"
  });

export const fetchInsiderListDocument = id => (dispatch, getState) => {
  const { insiderToolReducer } = getState();

  return dispatch({
    type: DocumentAction.receive,
    data: insiderToolReducer.document.map.get(id),
    attachments: insiderToolReducer.document.fullAttachmentList.filter(
      a => a.parentId === id
    )
  });
};

export const DocumentListAction = {
  request: "REQUEST_INSIDERTOOL_DOCUMENT_LIST",
  receive: "RECEIVE_INSIDERTOOL_DOCUMENT_LIST",
  receiveFail: "RECEIVE_INSIDERTOOL_DOCUMENT_LIST_FAILED"
};

export const fetchInsiderListDocumentList = () =>
  fetchData({
    ...DocumentListAction,
    obsvcFilename: "it/getDocumentList.obsvc"
  });

const ExportListActions = {
  request: "REQUEST_EXPORT_LIST",
  receive: "RECEIVE_EXPORT_LIST",
  receiveFail: "RECEIVE_EXPORT_LIST_FAILED"
};

export const exportList = (listId, when, lang) =>
  fetchData({
    ...ExportListActions,
    params: {
      listId,
      when,
      lang
    },
    obsvcFilename: "it/exportList.obsvc?obsvc.item=1"
  });
const ExportCSVListActions = {
  request: "REQUEST_EXPORT_CSV_LIST",
  receive: "RECEIVE_EXPORT_CSV_LIST",
  receiveFail: "RECEIVE_EXPORT_CSV_LIST_FAILED"
};

export const exportCSVList = (listId, when) =>
  fetchData({
    ...ExportCSVListActions,
    params: {
      listId,
      when
    },
    obsvcFilename: "it/exportCSVList.obsvc?obsvc.item=1"
  });

export const ENTER_HISTORIC_MODE = "ENTER_HISTORIC_MODE";
export const LEAVE_HISTORIC_MODE = "LEAVE_HISTORIC_MODE";
export const enterHistoricMode = timestamp => ({
  type: ENTER_HISTORIC_MODE,
  data: timestamp
});

export const leaveHistoricMode = () => ({
  type: LEAVE_HISTORIC_MODE
});

export const FetchCountriesActions = {
  request: "IT_REQUEST_COUNTRIES",
  receive: "IT_RECEIVE_COUNTRIES",
  receiveFail: "IT_RECEIVE_COUNTRIES_FAILED"
};

export const getCountries = () =>
  fetchData({
    ...FetchCountriesActions,
    obsvcFilename: "it/fetchCountries.obsvc"
  });
