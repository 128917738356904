import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getLanguage } from "../util/common";
import Button from "../components/Button";
import { FlexContainer } from "../components/FlexContainer";
import { REGISTER } from "./FinanskalenderContainer";

const InfoBox = styled.div`
  background: rgba(199, 211, 227, 0.3);
  padding: 16px;
  color: black;
  width: 100%;
`;

const Heading = styled.h2`
  margin: 0 0 16px 0;
  font-size: 24px;
  font-weight: 600;
`;
const Link = styled.a`
  text-decoration: none;
  color: #336699;
  &:hover {
    text-decoration: underline;
  }
`;
const Text = styled.p`
  color: black;
  padding: 0;
  margin: 0;
`;

class Receipt extends React.Component {
  componentWillUnmount() {
    this.props.cleanUpOnUnmount();
  }

  render() {
    const { t, financeCalendarObj, base } = this.props;

    return (
      <InfoBox>
        <Heading>{t("financial_calender_saved")}</Heading>
        {financeCalendarObj &&
        (financeCalendarObj.publishNewsEN ||
          financeCalendarObj.publishNewsNO) ? (
          <Text>
            {t("financial_calendar_published")}{" "}
            <Link
              href={
                (this.props && this.props.newsWebWithLanguageIdLink
                  ? this.props.newsWebWithLanguageIdLink + "/search?languageId="
                  : "https://newsweb.oslobors.no/search?languageId=") +
                getLanguage().toLowerCase()
              }
              target="_blank"
            >
              NewsWeb
            </Link>
          </Text>
        ) : (
          <Text>{t("financial_calendar_published_without_message")}</Text>
        )}
        <FlexContainer row flexEnd>
          <Button.Standard
            onClick={() => {
              this.props.history.push(`${base}/${REGISTER}`);
            }}
          >
            {t("goto_financial_calendar")}{" "}
          </Button.Standard>
        </FlexContainer>
      </InfoBox>
    );
  }
}

Receipt.propTypes = {
  t: PropTypes.func.isRequired
};

export default Receipt;
