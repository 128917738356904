import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import RequestEmailForm from "./RequestEmailForm";
import { sendForgotPasswordLink } from "./ResetPasswordActions";
import EmailSent from "./EmailSent";
import { getLanguage } from "../util/common";

class RequestEmailFormContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailSent: false
    };
  }

  submit = ({ email, username }) => {
    this.props.dispatch(sendForgotPasswordLink(email, username, getLanguage()));
    this.setState({ emailSent: true });
  };

  render() {
    if (this.state.emailSent) {
      return <EmailSent />;
    }
    return <RequestEmailForm t={this.props.t} onSubmit={this.submit} />;
  }
}

function mapStateToProps(state) {
  return {};
}

RequestEmailFormContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(
  withTranslation("translations")(RequestEmailFormContainer)
);
