import React from "react";
import { reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import Label from "../components/Label";
import RadioButton from "../components/RadioButton";
import Field from "redux-form/es/Field";
import SearchableSelect from "../components/SearchableSelect";
import Input from "../components/Input";
import Button from "../components/Button";
import { ErrorText } from "../components/ErrorText";

const RadioButtonTwoFactorProtocolGroup = props => {
  return (
    <FlexContainer row margin={[12, 0, 20, 0]} vAlignCenter>
      <RadioButton
        {...props}
        margin={[0, 0, 8, 0]}
        radioValue={"SMS"}
        label={props.t("sms")}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"VOICE"}
        label={props.t("speech_message")}
      />
    </FlexContainer>
  );
};

const InputBox = props => {
  return (
    <FlexContainer column maxWidth={"240"}>
      <Input {...props} />
      {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
    </FlexContainer>
  );
};

class MFABoxForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;

    return (
      <FlexContainer column>
        <form>
          <Label>{props.t("receive_one_time_code")}</Label>
          <Field
            name={"twoFactorProtocol"}
            t={props.t}
            component={RadioButtonTwoFactorProtocolGroup}
          />

          <Label>{props.t("phonenumber_for_receival_mfa")}:</Label>
          <FlexContainer row margin={[8, 0, 30, 0]}>
            <Field
              component={SearchableSelect}
              searchable={true}
              name="areaCode"
              maxWidth={300}
              {...props}
              margin={[0, 24, 0, 0]}
              placeholder={props.t("choose_area_code")}
              options={this.props.countryOptions}
              clearable={false}
            />

            <Field
              component={InputBox}
              name={"twoFactorPhone"}
              border
              maxWidth={208}
              margin={[0, 24, 0, 0]}
              placeholder={props.t("telephonenumber")}
            />

            <Button.Standard
                loading={this.props.loading ? 1 : 0}
              margin={[0, 0, 0, 15]}
              onClick={props.handleSubmit(props.validatePhoneNumber)}
            >
              {props.t("verify")}
            </Button.Standard>
          </FlexContainer>
          <Label>{props.t("register_receival_of_mfacode")}:</Label>
          <FlexContainer row margin={[8, 0, 20, 0]}>
            <Field
              name={"receivedCode"}
              component={InputBox}
              maxWidth="276"
              border
              margin={[0, 24, 0, 0]}
              disabled={!props.twoFactorCodeRequested}
            />
          </FlexContainer>

          <FlexContainer row flexEnd vAlignCenter>
            {props.twoFactorCodeRequested ? (
              <Button.Standard
                onClick={props.handleSubmit(props.updateTwoFactorNumber)}
                margin={[0, 24, 0, 0]}
              >
                {props.t("save")}
              </Button.Standard>
            ) : (
              undefined
            )}
            <Button.Text
              onClick={() => {
                props.closeModal();
              }}
            >
              {props.t("cancel")}
            </Button.Text>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

MFABoxForm = reduxForm({
  form: "MFABoxForm",
  destroyOnUnmount: true
})(MFABoxForm);

export default MFABoxForm;
