import {deleteToken, setCASLogin, setToken} from "../obsvcClient";
import {deleteLocalLanguage} from "../actions/CommonActions";
import {doPostRequest} from "../noObsvcClient";

export const PASSWORDS_DO_NOT_MATCH_ERROR_CODE = 1000;
export const MISSING_TWO_FACTOR_DATA_ERROR_CODE = 1240;
export const LOGIN_CREDENTIALS_INCORRECT_ERROR_CODE = 401;

export const REQUEST_LOGIN_PHASE_ONE = "REQUEST_LOGIN_PHASE_ONE";
export function requestLoginPhaseOne() {
  return { type: REQUEST_LOGIN_PHASE_ONE };
}

export const REQUEST_LOGIN_PHASE_TWO = "REQUEST_LOGIN_PHASE_TWO";
export function requestLoginPhaseTwo() {
  return { type: REQUEST_LOGIN_PHASE_TWO };
}

export const RECEIVE_LOGIN_PHASE_ONE = "RECEIVE_LOGIN_PHASE_ONE";
export function receiveLoginPhaseOne(data) {
  return { type: RECEIVE_LOGIN_PHASE_ONE, data: data.entity };
}

export const RECEIVE_LOGOUT = "RECEIVE_LOGOUT";
export function receiveLogout(data) {
  return { type: RECEIVE_LOGOUT, data: data };
}

export const RECEIVE_LOGIN_PHASE_TWO = "RECEIVE_LOGIN_PHASE_TWO";
export function receiveLoginPhaseTwo(data) {
  return { type: RECEIVE_LOGIN_PHASE_TWO, data: data.entity };
}

export const REQUEST_LOGIN_FAILURE = "REQUEST_LOGIN_FAILURE";
export function requestLoginFailure(error) {
  return { type: REQUEST_LOGIN_FAILURE, error: error };
}

export const LOGIN_WITH_TOKEN = "LOGIN_WITH_TOKEN";
export function loginWithToken() {
  return { type: LOGIN_WITH_TOKEN };
}

export const LOG_OUT = "LOG_OUT";
export function logOut() {
  deleteToken();
  deleteLocalLanguage();
  return { type: LOG_OUT };
}

export function loginPhaseOne({ username, password }) {
  return (dispatch) => {
    dispatch(requestLoginPhaseOne());

    return doLogin({
      username: username,
      credentials: {
        type: "twoFactor",
        phase: "1",
        password: password,
      },
    }).then(
      (data) => {
        dispatch(receiveLoginPhaseOne(data));
        return data;
      },
      (error) => {
        dispatch(requestLoginFailure(error));
        throw error;
      }
    );
  };
}

export function loginPhaseTwo({ username, code }) {
  return (dispatch) => {
    dispatch(requestLoginPhaseTwo());

    return doLogin({
      username: username,
      credentials: {  type: "twoFactor", code: code, phase: "2" },
    }).then(
      (data) => {
        dispatch(receiveLoginPhaseTwo(data));
        return data;
      },
      (error) => {
        dispatch(requestLoginFailure(error));
        throw error;
      }
    );
  };
}

const doLogin = (data) => {
  return doPostRequest(
    "v1/authn/twoFactor/login",
    undefined,
    data,
    false,
    true,
    true
  ).then((data) => {
    const { token } = data.data.entity;
    if (token) {
      setToken(token);
    }
    return data.data;
  });
};

export function casLogin(ticket) {
  return (dispatch) => {
    return doPostRequest(
      "v1/authn/cas/login",
      null,
      {       type: "twoFactor",
        credentials: { password: ticket } },
      false,
      true
    )
      .then((data) => {
        if (data?.data?.entity?.token) {
          setToken(data.data.entity.token);
          setCASLogin();
          dispatch(receiveLoginPhaseOne({ entity: data.data.entity }));
        }
        return data.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

export function maintenanceLogin() {
  return () => {
    return doPostRequest(
        "v1/authn/cas/login",
        null,
        {credentials: {type: "maintenance"}},
        false,
        true
    );
  };
}

export function casLogout(doNotShowAgain) {
  return (dispatch) => {
    return doPostRequest(
      "v1/authn/cas/logout",
      null,
      { doNotShowAgain },
      false,
      true
    )
      .then((data) => {
        if (data && data.token) {
          setToken(data.token);
          setCASLogin();
          dispatch(receiveLogout({ entity: data }));
        }
        return data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}
