import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import { InsiderType } from "../../InsiderToolActions";
import styled from "styled-components";
import { StyledLink } from "../../../components/StyledLink";
import Button from "../../../components/Button";

const Para = styled.p`
  color: black;
  font-size: 16px;
  margin: 0 0 15px 0;
  ${props => (props.indentation ? "margin-left: 20px;" : "")};
  text-align: left;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const NewInsiderList = props => {
  const { t } = props;
  return (
    <FlexContainer>
      <Para>{t("new_insider_list_text_1")}</Para>
      <Para>
        {t("new_insider_list_text_2")}
        <Underline>{t("new_insider_list_not_inside_information")}</Underline>
        {props.isBasedOnOtherList
          ? t("new_insider_list_text_2_2_based_on_other_list")
          : t("new_insider_list_text_2_2")}
        <Underline>{t("new_list_text_not")}</Underline>
        {t("new_insider_list_text_2_3")}
      </Para>
      <Para>{t("new_insider_list_text_3")}</Para>
      <Para indentation>
        {t("new_insider_list_text_4")}
        <StyledLink
          href={
            "https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32014R0596&from=EN"
          }
          target="_blank"
        >
          https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32014R0596&from=EN
        </StyledLink>
        {t("new_insider_list_text_5")}
      </Para>
    </FlexContainer>
  );
};

const NewConfidentialityList = props => {
  const { t } = props;

  return (
    <FlexContainer>
      <Para>
        {t("new_confidentiality_list_text_1")}
        <Underline>{t("new_list_text_not")}</Underline>
        {props.isBasedOnOtherList
          ? t("new_confidentiality_list_text_2_based_on_other_list")
          : t("new_confidentiality_list_text_2")}
      </Para>
      <Para>{t("new_confidentiality_list_text_3")}</Para>
      <Para>
        <Bold>
          {t("new_confidentiality_list_text_4")}
          <Underline>{t("new_list_text_not")}</Underline>
          {t("new_confidentiality_list_text_5")}
        </Bold>
      </Para>
    </FlexContainer>
  );
};

export class CreateNewListModal extends React.Component {
  render() {
    const { listType, t, onSubmit, onSubmitLabel, onCancel } = this.props;

    return (
      <FlexContainer>
        {listType === InsiderType.LIST ? (
          <NewInsiderList {...this.props} />
        ) : (
          <NewConfidentialityList {...this.props} />
        )}
        <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
          <Button.Standard onClick={onSubmit} margin={[0, 24, 0, 0]}>
            {t(onSubmitLabel)}
          </Button.Standard>

          <Button.Text onClick={onCancel}>{t("cancel")}</Button.Text>
        </FlexContainer>
      </FlexContainer>
    );
  }
}
