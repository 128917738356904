import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import Button from "../../../components/Button";
import RadioButton from "../../../components/RadioButton";

const AuthorityRadioButton = props => {
  return (
    <FlexContainer row margin={[24, 0, 10, 0]} center vAlignCenter>
      <RadioButton
        {...props}
        checked
        margin={[0, 0, 8, 0]}
        radioValue={"finanstilsynet"}
        label={props.t("send_to_finanstilsynet")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onSendMail();
            props.input.onChange(value);
          }
        }}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"oslo_bors"}
        label={props.t("send_to_oslo_bors")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onDoNotSendMail();
            props.input.onChange(value);
          }
        }}
      />
    </FlexContainer>
  );
};

export class CloseAndNotifyAuthority extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailSelected: false
    };
  }

  render() {
    const { t } = this.props;
    const onSendMail = () => {
      this.setState({ sendMailSelected: true });
    };
    const onDoNotSendMail = () => {
      this.setState({ sendMailSelected: false });
    };

    return (
      <FlexContainer>
        {t("")}
        <FlexContainer center>
          <Field
            name={"authority"}
            t={this.props.t}
            component={AuthorityRadioButton}
            onSendMail={onSendMail}
            onDoNotSendMail={onDoNotSendMail}
          />
        </FlexContainer>

        <FlexContainer row vAlignCenter flexEnd>
          <Button.Standard
            onClick={this.props.handleSubmit}
            margin={[0, 24, 0, 0]}
          >
            {t("send_list")}
          </Button.Standard>
          <Button.Text onClick={this.props.onCancel}>{t("cancel")}</Button.Text>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

class CloseAndNotifyAuthorityForm extends React.Component {
  render() {
    return (
      <FlexContainer>
        <form>
          <CloseAndNotifyAuthority {...this.props} />
        </form>
      </FlexContainer>
    );
  }
}

CloseAndNotifyAuthorityForm = reduxForm({
  form: "closeAndNotifyAuthorityForm"
})(CloseAndNotifyAuthorityForm);

export default CloseAndNotifyAuthorityForm;
