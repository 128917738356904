import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { DateBox, TimePickerBox } from "../../components";
import Button from "../../../components/Button";
import { FlexContainer } from "../../../components/FlexContainer";
import { getUtcDateTimeLabelFromFormValues } from "../../UTCUtils";
import { connect } from "react-redux";

const ButtonRow = ({ onCancel, onSubmit, t }) => {
  return (
    <FlexContainer flexEnd vAlignCenter row>
      <Button.Standard margin={[0, 20, 0, 0]} onClick={onSubmit}>
        {t("save")}
      </Button.Standard>
      <Button.Text onClick={onCancel}>{t("cancel")}</Button.Text>
    </FlexContainer>
  );
};
class UpdateDateTime extends React.Component {
  render() {
    const { t, handleSubmit, onSubmit, onClose } = this.props;

    return (
      <FlexContainer column>
        <FlexContainer row>
          <Field
            component={DateBox}
            name="update_date"
            label={
              t("date") +
              " " +
              getUtcDateTimeLabelFromFormValues(
                this.props.updateDateTimeValues,
                "update_date",
                "update_time"
              )
            }
            border
          />
          <Field
            component={TimePickerBox}
            name="update_time"
            label={t("time")}
          />
        </FlexContainer>
        <FlexContainer margin={[8, 0, 8, 0]}>
          <ButtonRow
            t={t}
            onSubmit={handleSubmit(onSubmit)}
            onCancel={onClose}
          />
        </FlexContainer>
      </FlexContainer>
    );
  }
}
UpdateDateTime = reduxForm({
  form: "updateDateTime"
})(UpdateDateTime);

function mapStateToProps(state) {
  const { form } = state;

  return {
    updateDateTimeValues: form.updateDateTime
      ? form.updateDateTime.values
      : undefined
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(UpdateDateTime)
);
