import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Edit from "./Edit";
import Confirm from "./Confirm";
import { reset } from "redux-form";
import Receipt from "./Receipt";

import { Redirect, Route, Switch } from "react-router-dom";
import { fetchCompanyList } from "../companyInformation/CompanyInformationActions";
import {
  clearPreviewMessages,
  createFinancialCalendar,
  fetchCalendarMessagePreview,
  fetchFinancialCalendar,
  fetchOptionalEvents
} from "./FinanceCalendarActions";
import moment from "moment";
import {
  noCalendarEventDates,
  noFutureEvents,
  someDatesAreUndefined
} from "./FinanceCalendarValidator";
import { isSuperUser } from "../util/common";

export const REGISTER = "register";
const CONFIRM = "confirm";
const RECEIPT = "reciept";

export const isSameOrBefore = date => {
  return moment()
    .startOf("day")
    .isSameOrBefore(date);
};

export const PAST = "past";
export const CURRENT = "current";
export const FUTURE = "future";

class FinanskalenderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      financialCalendar: undefined,
      saveInProgress: false,
      engCharsLength: 0,
      noCharsLength: 0,
      pastIsOpen: false,
      currentIsOpen: false,
      futureIsOpen: false,
      errorMissing: undefined,
      errorInvalid: undefined,
      errorNoMessage: undefined
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchCompanyList()).catch(err => {});
    this.props.dispatch(fetchOptionalEvents()).catch(err => {});
  }
  componentWillUnmount() {
    this.props.dispatch(reset("finanskalender"));
  }

  render() {
    const { t, base } = this.props;

    const addOptionalEvent = period => {
      let financialObj = this.state.financialCalendar;
      if (period === PAST) {
        if (!financialObj.pastPeriod.optionalEvents) {
          financialObj.pastPeriod.optionalEvents = [];
        }
        financialObj.pastPeriod.optionalEvents.push({});
      } else if (period === CURRENT) {
        if (!financialObj.currentPeriod.optionalEvents) {
          financialObj.currentPeriod.optionalEvents = [];
        }
        financialObj.currentPeriod.optionalEvents.push({});
      } else {
        if (!financialObj.futurePeriod.optionalEvents) {
          financialObj.futurePeriod.optionalEvents = [];
        }
        financialObj.futurePeriod.optionalEvents.push({});
      }
      this.setState({ financialCalendar: financialObj });
    };

    const updateOptionalEvent = (eventId, period, index) => {
      let financialObj = this.state.financialCalendar;
      this.props.optionalEvents.forEach(event => {
        if (event.id === eventId) {
          if (period === PAST) {
            financialObj.pastPeriod.optionalEvents[index].eventType = event;
          } else if (period === CURRENT) {
            financialObj.currentPeriod.optionalEvents[index].eventType = event;
          } else {
            financialObj.futurePeriod.optionalEvents[index].eventType = event;
          }
        }
      });
      this.setState({ financialCalendar: financialObj });
    };

    const removeOptionalEvent = (period, index) => {
      let financialObj = this.state.financialCalendar;
      if (period === PAST) {
        if (financialObj.pastPeriod.optionalEvents) {
          financialObj.pastPeriod.optionalEvents.splice(index, 1);
        }
      } else if (period === CURRENT) {
        if (financialObj.currentPeriod.optionalEvents) {
          financialObj.currentPeriod.optionalEvents.splice(index, 1);
        }
      } else {
        if (financialObj.futurePeriod.optionalEvents) {
          financialObj.futurePeriod.optionalEvents.splice(index, 1);
        }
      }
      this.setState({ financialCalendar: financialObj });
    };

    const companyOptions = this.props.companyList
      ? this.props.companyList.map(company => {
          return {
            label: company.longName,
            value: company.cid,
            helpText: company.symbol
          };
        })
      : [];

    const getPastOptionalEventOfId = id => {
      let optionalEvent = undefined;
      if (this.props.financialCalendar.pastPeriod.optionalEvents) {
        this.props.financialCalendar.pastPeriod.optionalEvents.forEach(
          event => {
            if (event.eventType.id === id) {
              optionalEvent = event;
            }
          }
        );
      }
      return optionalEvent;
    };

    const getCurrentOptionalEventOfId = id => {
      let optionalEvent = undefined;
      if (this.props.financialCalendar.currentPeriod.optionalEvents) {
        this.props.financialCalendar.currentPeriod.optionalEvents.forEach(
          event => {
            if (event.eventType.id === id) {
              optionalEvent = event;
            }
          }
        );
      }
      return optionalEvent;
    };

    const getFutureOptionalEventOfId = id => {
      let optionalEvent = undefined;
      if (id && this.props.financialCalendar.futurePeriod.optionalEvents) {
        this.props.financialCalendar.futurePeriod.optionalEvents.forEach(
          event => {
            if (event.eventType.id === id) {
              optionalEvent = event;
            }
          }
        );
      }
      return optionalEvent;
    };

    const insertEventAtCorrectIndex = (optionalEvents, event) => {
      let hasBeenInserted = false;
      if (optionalEvents.length === 0) {
        optionalEvents.push({ eventType: event });
      } else {
        optionalEvents.forEach((currEvent, index) => {
          if (
            currEvent.eventType.sortOrder > event.sortOrder &&
            !hasBeenInserted
          ) {
            optionalEvents.splice(index, 0, { eventType: event });
            hasBeenInserted = true;
          }
        });
        if (!hasBeenInserted) {
          optionalEvents.push({ eventType: event });
        }
      }
    };

    const onFetchCalendarSuccess = () => {
      let financialCalendar = this.props.financialCalendar;
      if (this.props.optionalEvents) {
        for (let i = 0; i < this.props.optionalEvents.length; i++) {
          const event = this.props.optionalEvents[i];
          if (event.eventType === 2) {
            const pastOptionalEvent = getPastOptionalEventOfId(event.id);
            const currentOptionalEvent = getCurrentOptionalEventOfId(event.id);
            const futureOptionalEvent = getFutureOptionalEventOfId(event.id);
            if (!pastOptionalEvent) {
              if (!financialCalendar.pastPeriod.optionalEvents) {
                financialCalendar.pastPeriod.optionalEvents = [];
              }
              insertEventAtCorrectIndex(
                financialCalendar.pastPeriod.optionalEvents,
                event
              );
            }
            if (!currentOptionalEvent) {
              if (!financialCalendar.currentPeriod.optionalEvents) {
                financialCalendar.currentPeriod.optionalEvents = [];
              }
              insertEventAtCorrectIndex(
                financialCalendar.currentPeriod.optionalEvents,
                event
              );
            }
            if (!futureOptionalEvent) {
              if (!financialCalendar.futurePeriod.optionalEvents) {
                financialCalendar.futurePeriod.optionalEvents = [];
              }
              insertEventAtCorrectIndex(
                financialCalendar.futurePeriod.optionalEvents,
                event
              );
            }
          }
        }
      }
      this.setState({ financialCalendar: financialCalendar });
    };

    const getFinancialCalendar = cid => {
      if (cid) {
        this.setState({
          financialCalendar: undefined,
          pastIsOpen: false,
          currentIsOpen: false,
          futureIsOpen: false,
          errorMissing: undefined,
          errorInvalid: undefined,
          errorNoMessage: undefined
        });
        this.props
          .dispatch(fetchFinancialCalendar(cid))
          .then(onFetchCalendarSuccess)
          .catch(err => {});
      }
    };

    const updateDateForRequiredEvent = (index, period, date) => {
      let financialObj = this.state.financialCalendar;
      if (index > -1) {
        const serverDate = date
          ? moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")
          : "";

        if (period === PAST) {
          if (date === "") {
            financialObj.pastPeriod.requiredEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.pastPeriod.requiredEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.pastPeriod.requiredEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        } else if (period === CURRENT) {
          if (date === "") {
            financialObj.currentPeriod.requiredEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.currentPeriod.requiredEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.currentPeriod.requiredEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        } else {
          if (date === "") {
            financialObj.futurePeriod.requiredEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.futurePeriod.requiredEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.futurePeriod.requiredEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        }
        this.setState({ financialCalendar: financialObj });
      }
    };

    const updateDateForOptionalEvent = (index, period, date) => {
      let financialObj = this.state.financialCalendar;
      if (index > -1) {
        const serverDate = date
          ? moment(date, "DD.MM.YYYY").format("YYYY-MM-DD")
          : "";

        if (period === PAST) {
          if (serverDate === "") {
            financialObj.pastPeriod.optionalEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.pastPeriod.optionalEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.pastPeriod.optionalEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        } else if (period === CURRENT) {
          if (serverDate === "") {
            financialObj.currentPeriod.optionalEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.currentPeriod.optionalEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.currentPeriod.optionalEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        } else {
          if (serverDate === "") {
            financialObj.futurePeriod.optionalEvents[index].eventDate = "";
          } else if (
            moment(serverDate, "YYYY-MM-DD", true).isValid() &&
            isSameOrBefore(serverDate)
          ) {
            financialObj.futurePeriod.optionalEvents[
              index
            ].eventDate = serverDate;
          } else {
            financialObj.futurePeriod.optionalEvents[index].eventDate = t(
              "invalid_date"
            );
          }
        }
        this.setState({ financialCalendar: financialObj });
      }
    };

    const norwegianMessageEnabled = () => {
      let financialObj = this.state.financialCalendar;
      financialObj.publishNewsNO = !financialObj.publishNewsNO;
      this.setState({ financialCalendar: financialObj });
    };

    const englishMessageEnabled = () => {
      let financialObj = this.state.financialCalendar;
      financialObj.publishNewsEN = !financialObj.publishNewsEN;
      this.setState({ financialCalendar: financialObj });
    };

    const onAddSuccess = () => {
      this.props.history.push(`${base}/${RECEIPT}`);
      this.setState({
        saveInProgress: false
      });
    };

    const onAddFailure = () => {
      this.setState({ saveInProgress: false });
    };

    const setEventDateNullIfInvalid = eventList => {
      if (eventList && eventList.length > 0) {
        eventList.forEach(event => {
          if (
            event.eventDate !== null &&
            !moment(event.eventDate, "YYYY-MM-DD", true).isValid()
          ) {
            event.eventDate = null;
          }
        });
      }
    };

    const removeEventsFromOptionalsIfNoEventSet = calendarObj => {
      for (let i = 0; i < calendarObj.pastPeriod.optionalEvents.length; i++) {
        const event = calendarObj.pastPeriod.optionalEvents[i];
        if (!event.eventType) {
          calendarObj.pastPeriod.optionalEvents.splice(i--, 1);
        }
      }
      for (
        let i = 0;
        i < calendarObj.currentPeriod.optionalEvents.length;
        i++
      ) {
        const event = calendarObj.currentPeriod.optionalEvents[i];
        if (!event.eventType) {
          calendarObj.currentPeriod.optionalEvents.splice(i--, 1);
        }
      }
      for (let i = 0; i < calendarObj.futurePeriod.optionalEvents.length; i++) {
        const event = calendarObj.futurePeriod.optionalEvents[i];
        if (!event.eventType) {
          calendarObj.futurePeriod.optionalEvents.splice(i--, 1);
        }
      }
    };

    const getFinancialCalendarObject = () => {
      let calendarObj = this.state.financialCalendar;

      removeEventsFromOptionalsIfNoEventSet(calendarObj);

      setEventDateNullIfInvalid(calendarObj.pastPeriod.requiredEvents);
      setEventDateNullIfInvalid(calendarObj.currentPeriod.requiredEvents);
      setEventDateNullIfInvalid(calendarObj.futurePeriod.requiredEvents);
      setEventDateNullIfInvalid(calendarObj.pastPeriod.optionalEvents);
      setEventDateNullIfInvalid(calendarObj.currentPeriod.optionalEvents);
      setEventDateNullIfInvalid(calendarObj.futurePeriod.optionalEvents);

      if (calendarObj.publishNewsEN && !calendarObj.commentary_EN) {
        calendarObj.commentary_EN = "";
      }
      if (calendarObj.publishNewsNO && !calendarObj.commentary_NO) {
        calendarObj.commentary_NO = "";
      }

      return calendarObj;
    };

    const saveFinancialCalendar = () => {
      this.setState({ saveInProgress: true });
      this.props
        .dispatch(createFinancialCalendar(getFinancialCalendarObject()))
        .then(onAddSuccess)
        .catch(onAddFailure);
    };

    const handleNorwegianMessageChange = value => {
      let financialObj = this.state.financialCalendar;
      financialObj.commentary_NO = value;
      this.setState({
        noCharsLength: value != null ? value.length : 0,
        financialCalendar: financialObj
      });
    };

    const handleEnglishMessageChange = value => {
      let financialObj = this.state.financialCalendar;
      financialObj.commentary_EN = value;
      this.setState({
        engCharsLength: value != null ? value.length : 0,
        financialCalendar: financialObj
      });
    };

    const notSuperUserAndMissingMessage = () => {
      if (!this.props.isSuperUser) {
        return (
          !this.state.financialCalendar.publishNewsEN &&
          !this.state.financialCalendar.publishNewsNO
        );
      }
      return false;
    };

    const handleSubmit = () => {
      let hasErrors = false;
      if (
          (noCalendarEventDates(this.state.financialCalendar) ||
        noFutureEvents(this.state.financialCalendar)) && !this.props.isSuperUser
      ) {
        this.setState({ errorMissing: "future_events_are_missing" });
        hasErrors = true;
      } else {
        this.setState({ errorMissing: undefined });
      }

      if (someDatesAreUndefined(this.state.financialCalendar)) {
        this.setState({ errorInvalid: "some_dates_are_invalid" });
        hasErrors = true;
      } else {
        this.setState({ errorInvalid: undefined });
      }

      if (notSuperUserAndMissingMessage()) {
        this.setState({ errorNoMessage: "no_message_language_selected" });
        hasErrors = true;
      } else {
        this.setState({ errorNoMessage: undefined });
      }

      if (!hasErrors) {
        this.setState({
          errorMissing: undefined,
          errorInvalid: undefined,
          errorNoMessage: undefined
        });
        if (
          this.state.financialCalendar.publishNewsEN ||
          this.state.financialCalendar.publishNewsNO
        ) {
          this.props
            .dispatch(fetchCalendarMessagePreview(getFinancialCalendarObject()))
            .then(() => {
              this.props.history.push(`${base}/${CONFIRM}`);
            })
            .catch(error => {});
        } else {
          this.props.dispatch(clearPreviewMessages());
          this.props.history.push(`${base}/${CONFIRM}`);
        }
      }
    };

    const openClosePeriod = period => {
      if (period === PAST) {
        this.setState({
          pastIsOpen: !this.state.pastIsOpen
        });
      } else if (period === CURRENT) {
        this.setState({
          currentIsOpen: !this.state.currentIsOpen
        });
      } else if (period === FUTURE) {
        this.setState({
          futureIsOpen: !this.state.futureIsOpen
        });
      }
    };

    return (
      <Switch>
        <Route
          path={`${base}/${REGISTER}`}
          render={props => (
            <Edit
              onSubmit={handleSubmit}
              onCancel={() => {
                this.props.history.push("/");
                this.props.dispatch(reset("finanskalender"));
              }}
              t={this.props.t}
              addOptionalEvent={addOptionalEvent}
              removeOptionalEvent={removeOptionalEvent}
              state={this.state}
              companyOptions={companyOptions}
              fetchFinancialCalendar={getFinancialCalendar}
              optionalEvents={this.props.optionalEvents}
              updateDateForRequiredEvent={updateDateForRequiredEvent}
              updateOptionalEvent={updateOptionalEvent}
              updateDateForOptionalEvent={updateDateForOptionalEvent}
              norwegianMessageEnabled={norwegianMessageEnabled}
              englishMessageEnabled={englishMessageEnabled}
              handleNorwegianMessageChange={handleNorwegianMessageChange}
              handleEnglishMessageChange={handleEnglishMessageChange}
              engCharsLength={this.state.engCharsLength}
              noCharsLength={this.state.noCharsLength}
              openClosePeriod={openClosePeriod}
              isSuperUser={this.props.isSuperUser}
              newsWebWithLanguageIdLink={
                this.props.config.newsWebWithLanguageIdLink
              }
            />
          )}
        />

        <Route
          path={`${base}/${CONFIRM}`}
          render={props => (
            <Confirm
              onBack={() => this.props.history.push(`${base}/${REGISTER}`)}
              onCancel={() => {
                this.props.history.push("/");
                this.props.dispatch(reset("finanskalender"));
              }}
              onSubmit={saveFinancialCalendar}
              t={this.props.t}
              previewMessageList={this.props.previewMessageList}
              saveInProgress={this.state.saveInProgress}
            />
          )}
        />

        <Route
          path={`${base}/${RECEIPT}`}
          render={props => (
            <Receipt
              t={t}
              base={base}
              history={this.props.history}
              financeCalendarObj={this.state.financialCalendar}
              cleanUpOnUnmount={() => {
                window.location.reload();
              }}
              newsWebWithLanguageIdLink={
                this.props.config.newsWebWithLanguageIdLink
              }
            />
          )}
        />

        <Route
          path={base}
          render={() => <Redirect to={`${base}/${REGISTER}`} />}
        />
      </Switch>
    );
  }
}

FinanskalenderContainer.propTypes = {
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const {
    companyInformationReducer,
    financialReducer,
    loginUser,
    config
  } = state;

  return {
    companyList: companyInformationReducer.companyList.list,
    financialCalendar: financialReducer.calendar.financialCalendar,
    optionalEvents: financialReducer.optionalEvents.optionalEvents,
    optionalEventsIsFetched:
      financialReducer.optionalEvents.optionalEventsIsFetched,
    isSuperUser: isSuperUser(loginUser.user),
    previewMessageList: financialReducer.previewMessage.previewMessageList
      ? financialReducer.previewMessage.previewMessageList
      : undefined,
    config: config.config
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(FinanskalenderContainer)
);
