import React from "react";
import styled, { css } from "styled-components";
import FocusElement from "./FocusElement";
import { FlexContainer } from "./FlexContainer";
import { browserIsIE } from "../util/common";

const Label = styled.label`
  /*select -> user cannot highlight text*/
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.fitToContent
      ? css`
          ${browserIsIE() ? "width: 20px;" : "width: fit-content;"};
        `
      : ""};
  display: flex;
  align-items: flex-start;
  color: black;
  cursor: pointer;
  ${(props) => (props.disabled ? "" : "cursor: pointer;")} ${(props) =>
    props.color ? "color: " + props.color + ";" : ""};
  ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

const Icon = styled.img`
  ${(props) =>
    props.inactive
      ? css`
          opacity: 0.5;
        `
      : ""}
  display: ${(props) => (props.displayIcon ? "inline;" : "none;")};
  cursor: pointer;
  ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  padding-right: 8px;
  padding-top: 2px;
`;

class RadioButton extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.checked)
      if (this.props.input != null) {
        this.props.input.onChange(this.props.radioValue);
      }
  }

  render() {

    const updateRadioButton = () => {
      this.props.input.onChange(this.props.radioValue);
    };

    const onEnterKeyPress = (e) => {
      if (e.keycode === 13 && !this.props.disabled) {
        updateRadioButton();
        e.preventDefault();
      }
    };

    return (
      <Label
        {...this.props}
        onClick={!this.props.disabled ? updateRadioButton : undefined}
      >
        <Icon
          tabIndex={this.props.disabled ? "-1" : "0"}
          onKeyDown={onEnterKeyPress}
          displayIcon={this.props.input?.value === this.props.radioValue}
          aria-hidden="true"
          alt={this.props.altText}
          inactive={this.props.inactive}
          src="/icons/ic-radio-button-checked-black-24-px-1.svg"
        />
        <Icon
          tabIndex={this.props.disabled ? "-1" : "0"}
          onKeyDown={onEnterKeyPress}
          displayIcon={!(this.props.input?.value === this.props.radioValue)}
          aria-hidden="true"
          alt={this.props.altText}
          inactive={this.props.inactive}
          src="/icons/ic-radio-button-unchecked-black-24-px-1.svg"
        />
        {this.props.label}
      </Label>
    );
  }
}

export default RadioButton;
