import React from "react";

export const prefix = "/../help/";
export const extraPrefix = "it/";
export const enPostfix = "_en.html";
export const noPostfix = "_no.html";
export const newBrowserName = "newsPoint_help";

export const HelpIcon = (props) => {
  const {
    language,
    fileName,
    insiderToolHelp,
    margin,
    whiteIcon,
    height,
    width,
    incomingPrefix,
  } = props;

  return (
    <img
      id={fileName + "help"}
      src={
        whiteIcon ? "/icons/help-white-16px.svg" : "/icons/help-green-16px.svg"
      }
      alt={"Info"}
      style={{
        cursor: "pointer",
        margin: margin
          ? margin.reduce((acc, val) => (acc += val + "px "), "")
          : "5px 0px 0px 5px",
      }}
      onClick={() => {
        if (language && language === "en") {
          window.open(
            prefix +
              (incomingPrefix
                ? `${props.incomingPrefix}/`
                : insiderToolHelp
                ? extraPrefix
                : "") +
              fileName +
              enPostfix,
            newBrowserName,
            "location,status,scrollbars,resizable,width=600, height=600"
          );
        } else {
          window.open(
            prefix +
              (incomingPrefix
                ? `${props.incomingPrefix}/`
                : insiderToolHelp
                ? extraPrefix
                : "") +
              fileName +
              noPostfix,
            newBrowserName,
            "location,status,scrollbars,resizable,width=600, height=600"
          );
        }
      }}
      height={height}
      width={width}
    />
  );
};
