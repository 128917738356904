import React from "react";
import { Icon } from "../components";
import styled from "styled-components";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
`;

const DropdownContent = styled.div`
  position: absolute;
  background-color: white;
  width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: block;
  margin: 0 50px 10px -270px;
`;

export class MenuBox extends React.Component {
  constructor() {
    super();
    this.state = {
      showMenu: false
    };
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  handleClickEvent(event) {
    if (!this.state.showMenu && event.target.id === "menu" + this.props.id) {
      this.setState({ showMenu: true });
    } else if (
      this.state.showMenu &&
      event.target.id !== "sendNotificationToAuthority"
    ) {
      this.setState({ showMenu: false });
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClickEvent);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClickEvent);
  }

  render() {
    return (
      <Dropdown>
        <Icon
          height={30}
          width={30}
          src={this.state.showMenu ? this.props.selectedIcon : this.props.icon}
          alt="menu"
          id={"menu" + this.props.id}
        />

        {this.state.showMenu ? (
          <DropdownContent>{this.props.children}</DropdownContent>
        ) : (
          undefined
        )}
      </Dropdown>
    );
  }
}
