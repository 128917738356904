import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { StyledLink } from "../components/StyledLink";
import { withTranslation } from "react-i18next";

class InactiveLogout extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <FlexContainer maxWidth={767}>
        <FlexContainer center vAlignCenter height={"200px"}>
          <span>{t("logged_out_due_to_inactivity_login")}</span>
          <StyledLink href={"/login"}>{t("login")}</StyledLink>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default withTranslation("translations")(InactiveLogout);
