import React from "react";
import {change, destroy, Field, reduxForm, SubmissionError} from "redux-form";
import {FlexContainer} from "../components/FlexContainer";
import {HeaderPanel} from "../components/HeaderPanel";
import Input from "../components/Input";
import Label from "../components/Label";
import Button from "../components/Button";
import CheckBox from "../components/CheckBox";
import SearchableSelect from "../components/SearchableSelect";
import {userAction} from "./AdminContainer";
import {ErrorText} from "../components/ErrorText";
import {getOBNTDateTime} from "../util/common";
import {Modal} from "../components/Modal";
import styled from "styled-components";
import SearchableSelectNew from "../components/SearchableSelectNew";
import {
    clearValidKeys,
    getApiKeys,
    getPublicKeys,
    getUserprofileData,
    setCredentials,
    setPublicKey,
    updatePublicKey
} from "./AdminActions";
import {NotificationManager} from "react-notifications";
import {validatePassword} from "../util/Validators";
import ApiKeyManagement from "./ApiKeyManagement";
import moment from "moment/moment";
import UserEditRoleManager from "./UserEditRoleManager";
import NewsfeedManagement from "./NewsfeedManagement";
export const NewSelectDropDown = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer maxWidth={295} margin={[0, 8, 0, 0]}>
      <Label htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:
        {error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <SearchableSelectNew
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        component={SearchableSelectNew}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};
class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPasswordReset: false,
      isTwoFactorActive: true,
      openSetPasswordModal: false,
      lastPasswordChange: undefined,
      keyManagement: undefined,
      selectedUserType: undefined,
      newsfeedManagement: false,
    };
  }

  componentDidMount() {
      if (this.props.user && this.props.user.lastPasswordChange !== undefined) {
          this.setState({lastPasswordChange: this.props.user.lastPasswordChange})
      }
  }

  onPasswordChange = (password, dispatch, props) => {
        const sendPasswordValues = () => {
            return dispatch(
                setCredentials(
                    this.props.user.globalSubject,
                    null,
                    null,
                    null,
                    null,
                    null,
                    1,
                    null,
                    password.repeatPassword
                )
            );
        };
        const handleServerErrors = (error, reject) => {
            if (error.data.details != null) {
                const details = error.data.details;
                const hasDetailWithCode = (details, code) => details.some((detail) => detail.code === code);
                const submissionErrorWithTranslationText = (translationKey) => new SubmissionError({
                    newPassword: this.props.t(translationKey), repeatPassword: this.props.t(translationKey),
                })

                switch (true) {
                    case hasDetailWithCode(details, 1221):
                        return reject(submissionErrorWithTranslationText("password_missing_char"))
                    case hasDetailWithCode(details, 1222):
                        return reject(submissionErrorWithTranslationText("password_too_short"))
                    case hasDetailWithCode(details, 1223):
                        return reject(submissionErrorWithTranslationText("password_reused"))
                    case hasDetailWithCode(details, 1224):
                        return reject(submissionErrorWithTranslationText("password_username"))
                    case hasDetailWithCode(details, 1225):
                        return reject(submissionErrorWithTranslationText("password_missing_char"))
                    case hasDetailWithCode(details, 1226):
                        return reject(submissionErrorWithTranslationText("password_consecutive_char"))
                    default:
                        return reject()
                }
            } else
                NotificationManager.error(
                    this.props.t("an_error_occured"),
                    "",
                    0
                );
            return reject();
        };

        const requiredFieldsList = [
            "newPassword",
            "repeatPassword",
        ];

        const errors = validatePassword(
            password,
            props,
            requiredFieldsList
        );

        return new Promise((resolve, reject) => {
            if (errors) {
                return reject(new SubmissionError(errors));
            }
            sendPasswordValues()
                .then(() =>
                    NotificationManager.success(this.props.t("password_updated"))
                )
                .then(() =>
                    this.setState({ openSetPasswordModal: false, lastPasswordChange: new Date() })
                )
                .then(resolve)
                .catch((error) => handleServerErrors(error, reject));
        });
    }

    onPublicKeySubmit = (values) => {
        const sendKeyValues = () => {
          if (values.isUpdate) {
              return this.props.dispatch(
                  updatePublicKey(
                      this.props.user.globalSubject,
                      values
                  )
              );
          } else {
              return this.props.dispatch(
                  setPublicKey(
                      this.props.user.globalSubject,
                      values
                  )
              );
          }
        };
        const handleServerErrors = (error, reject) => {
            NotificationManager.error(
                this.props.t("an_error_occured"),
                "",
                0
            );
            return reject();
        };

        let valueObj = values;
        valueObj.expires = moment(valueObj.expires, "DD.MM.YYYY", true).isValid()
            ? moment(valueObj.expires, "DD.MM.YYYY").toISOString()
            : moment(valueObj.expires, "YYYY-MM-DD", true).isValid()
                ? moment(valueObj.expires, "YYYY-MM-DD", true).toISOString() : undefined;

        return new Promise((resolve, reject) => {
            sendKeyValues()
                .then(() =>
                    NotificationManager.success(this.props.t("key_updated"))
                )
                .then(() =>
                    this.setState({ keyManagement: undefined })
                )
                .then(resolve)
                .then(this.props.dispatch(destroy("publicKeyForm")))
                .catch((error) => handleServerErrors(error, reject));
        });
    }

    onUserTypeChange = (type) => {
        if (type === this.state.selectedUserType) {
            // TO OVERRIDE RADIO-BUTTON BEHAVIOUR AND BE ABLE TO DESELECT
            this.setState({selectedUserType: undefined}, () => {
                this.props.dispatch(change("userForm", "userType", "unknown"));
                this.props.dispatch(change("userForm", "user", {}));
                this.props.dispatch(change("userForm", "admin", {}));
                this.props.dispatch(change("userForm", "roles", []));
            });
        } else {
            this.setState({selectedUserType: type});
        }
    }

    handleOpenKeyManagementModal = (type) => {
        if (type === "public") {
            this.props.dispatch(getPublicKeys(this.props.user.globalSubject));
        }
        else if (type === "api") {
            this.props.dispatch(getApiKeys(this.props.user.globalSubject));
        }
        this.setState({ keyManagement: type });
    }

    handleOpenNewsfeedManagementModal = () => {
        this.props.dispatch(getUserprofileData(this.props.user.globalSubject))
        this.setState({ newsfeedManagement: true });
    }
  render() {
    const { t, user } = this.props;
    const props = this.props;

    return (
      <FlexContainer column>
        <HeaderPanel>
          {user != null
            ? user.username +
              (!this.props.userEnabled ? " [" + props.t("deactive") + "]" : "")
            : props.t("new_user")}
        </HeaderPanel>

        <UserForm
          {...user}
          {...props}
          enableReinitialize={false}
          initialValues={{
            ...user,
            enableTwoFactor:
              user != null
                ? user.enableTwoFactor && this.props.systemWideTwoFactorEnabled
                : this.props.systemWideTwoFactorEnabled,
            organisation: props.preSelectOrganisation
              ? {
                  label: props.organisation.name,
                  value: {
                    id: props.organisation.id,
                    isInternal: props.organisation.internal,
                  },
                }
              : undefined,
            userType: props.userType,
            organisationId: this.props.organisationId,
            areaCode: {
              label: props.initialAreaCode
                ? props.initialAreaCode.label
                : undefined,
              value: props.initialAreaCode
                ? props.initialAreaCode.value
                : undefined,
            },
            twoFactorProtocol:
              this.props.user &&
              this.props.user.otherProperties.twoFactorMessageProtocol
                ? this.props.user.otherProperties.twoFactorMessageProtocol
                : "SMS",
            twoFactorPhone:
              this.props.user && this.props.user.otherProperties.twoFactorPhone,
          }}
          languageOptions={props.languageOptions}
          toggleTwoFactorInfo={() =>
            this.setState({ isTwoFactorActive: !this.state.isTwoFactorActive })
          }
          isTwoFactorActive={this.state.isTwoFactorActive}
          onResetPassword={() =>
            this.props.handlePasswordReset(this.props.user, this.props)
          }
          onOpenSetPasswordModal={() => {this.setState({ openSetPasswordModal: true })}}
          onCloseSetPasswordModal={() => {
              this.setState({ openSetPasswordModal: false });
          }}
          lastPasswordChange={this.state.lastPasswordChange}
          onOpenKeyManagementModal={(role) => this.handleOpenKeyManagementModal(role)}
          onOpenNewsfeedManagement={() => this.handleOpenNewsfeedManagementModal()}
          onUserTypeChange={this.onUserTypeChange}
          t={t}
        />

        <PasswordForm {...props} onPasswordSubmit={this.onPasswordChange}
                      openSetPasswordModal={this.state.openSetPasswordModal}
                      onCloseSetPasswordModal={() => {
                          this.setState({ openSetPasswordModal: false });
                      }}
        />

          {this.state.keyManagement !== undefined &&
              <ApiKeyManagement
              onSubmitPublicKey={this.onPublicKeySubmit}
              onCloseKeyManagementModal={() => {
                  this.props.dispatch(clearValidKeys())
                  this.setState({ keyManagement: undefined })}}
              validKeys={this.props.validKeys}
              apiKeys={this.props.apiKeys}
              type={this.state.keyManagement}
              {...props}
          /> }

          {this.state.newsfeedManagement &&
          <NewsfeedManagement
            newsfeedManagement={this.state.newsfeedManagement}
            onClose={() => this.setState({ newsfeedManagement: false })}
            {...props}
            initialValues={{
                ...this.props.userprofileData
            }}
          />
          }
      </FlexContainer>
    );
  }
}

class UserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            advanced: false
        };
    }

    componentDidMount() {
        this.props.userParams?.userTypes && Object.values(this.props.userParams.userTypes).map((type) => {
            if (this.props.user?.roles.includes(type.id) && type.advanced === true) {
                this.setState({advanced: true})
            }
        })
    }

    render() {
    const { t } = this.props;
    return (
      <form autoComplete="off">
        <FlexContainer greyBackground row padding={[16, 16, 16, 16]}>
          <LeftContentBox t={t} {...this.props}/>

          <RightContentBox t={t} {...this.props}
                           onAdvancedChange={() => this.setState({ advanced: !this.state.advanced })}
                           advanced={this.state.advanced}
          />
        </FlexContainer>
        <BottomActionMenu
          t={t}
          userActivityButtonLabel={
            this.props.userEnabled ? t("deactivate") : t("activate")
          }
          {...this.props}
        />
      </form>
    );
  }
}

UserForm = reduxForm({
  form: "userForm",
})(UserForm);

class PasswordForm extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <form autoComplete="off">
        <Modal
          isOpen={this.props.openSetPasswordModal}
          header={t("set_password", {
            user: this.props.user != null ? this.props.user.username : "",
          })}
          onClose={() => this.props.onCloseSetPasswordModal()}
        >
          <FlexContainer>
            <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
              <FlexContainer>
                <Field
                  border
                  name="newPassword"
                  component={InputBox}
                  label={t("password") + ":"}
                  placeholder={t("password")}
                  type="password"
                  greyPlaceholder
                />

                <Field
                  border
                  name="repeatPassword"
                  component={InputBox}
                  label={t("repeat_password") + ":"}
                  placeholder={t("repeat_password")}
                  onKeyDown={(e) => {
                    switch (e.keyCode) {
                      case 13: //Enter
                      //props.onClickAction(loginValues);
                    }
                  }}
                  type="password"
                  greyPlaceholder
                />

                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={this.props.handleSubmit(
                        this.props.onPasswordSubmit
                    )}
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text
                    onClick={() => this.props.onCloseSetPasswordModal()}
                  >
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Modal>
      </form>
    );
  }
}

PasswordForm = reduxForm({
  form: "passwordForm",
})(PasswordForm);
//const selector = formValue;

const LeftContentBox = (props) => (
  <FlexContainer column>
    <Field
      name="firstName"
      component={InputBox}
      label={props.t("first_name") + "*"}
    />
    <Field
      name="lastName"
      component={InputBox}
      label={props.t("last_name") + "*"}
    />
    <Field
      name="mobile"
      component={InputBox}
      label={props.t("mobile_number") + "*"}
    />
    <Field
      name="office"
      component={InputBox}
      label={props.t("direct_number")}
    />
    <Field name="email" component={InputBox} label={props.t("email") + "*"} />
    <Field
      name="organisation"
      component={SelectBox}
      t={props.t}
      onSelect={props.onSelectOrganisation}
      label={props.t("organisation") + "*"}
      placeholder={props.t("select")}
      options={props.organisationList}
      disabled={
        props.disableOrganisationList || props.action === userAction.EDIT
      }
    />

    {props.action === userAction.EDIT ? (
      <InformationBox
        label={props.t("username") + "*"}
        name="username"
        margin={[0, 26, 16, 0]}
        maxWidth={0}
      >
        {props.user.username}
      </InformationBox>
    ) : (
      <FlexContainer row>
        <Field
          name="username"
          loginNamePrefix={props.loginNamePrefix}
          component={UserNameBox}
          label={props.t("username") + "*"}
        />
      </FlexContainer>
    )}

    <Field
      name="language"
      t={props.t}
      component={NewSelectDropDown}
      options={props.languageOptions}
      placeholder={props.t("select")}
      margin={[0, 0, 24, 0]}
      label={props.t("language") + "*"}
      selectIfOnlyOneOption
      forceUpdate={true}
    />

    {props.action === userAction.EDIT ? (
      <FlexContainer>
        <InformationBox
          label={props.t("last_password_update")}
          name="passwordUpdate"
        >
          {props.lastPasswordChange != null
            ? getOBNTDateTime(props.lastPasswordChange)
            : ""}
        </InformationBox>
        <InformationBox label={props.t("last_login")} name="lastLogin">
          {props.lastLogin != null ? getOBNTDateTime(props.lastLogin) : ""}
        </InformationBox>
        <InformationBox label={props.t("invalid_logins")} name="invalidLogins">
          {props.invalidLogons}
        </InformationBox>
      </FlexContainer>
    ) : undefined}
  </FlexContainer>
);

export const InputBox = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

const SelectBox = (props) => {
  const { margin, ...restProps } = props;
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16, 0]} {...props}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...restProps}
        id={props.input.name}
        noBorder
        searchable={true}
      />
    </FlexContainer>
  );
};

const InformationBox = (props) => (
  <FlexContainer row>
    <Label
      htmlFor={props.name}
      maxWidth={295}
      margin={[0, 16, 16, 0]}
      bold
      {...props}
    >
      {props.label}:
    </Label>
    <span id={props.name}>{props.children}</span>
  </FlexContainer>
);

const RightContentBox = (props) => {
    const userTypes = props.getUserTypes();

    const getAuthOptions = () => {
        return props.otherProperties
            ? Object.keys(props.otherProperties)
                .filter((property) => property.startsWith("type_"))
                .map((property) => (
                    <Field
                        key={property}
                        fitToContent
                        component={CheckBox}
                        margin={[0, 0, 8, 0]}
                        label={props.t(property)}
                        name={property}
                        checked={props.otherProperties[property] === "true"}
                    />
                ))
            : props.userParams && props.userParams.userprofileTypes
                ? Object.keys(props.userParams.userprofileTypes)
                    .filter((property) => property.startsWith("type_"))
                    .map((property) => (
                        <Field
                            key={property}
                            fitToContent
                            component={CheckBox}
                            margin={[0, 0, 8, 0]}
                            label={props.t(property)}
                            name={property}
                            checked={props.userParams.userprofileTypes[property] === "true"}
                        />
                    ))
                : undefined;
    };

    return (
        <FlexContainer>
            <UserEditRoleManager
                getAuthOptions={getAuthOptions()}
                userTypes={userTypes}
                parameterList={props.parameterList}
                companyList={props.companyList}
                {...props}
            />
        </FlexContainer>
);
};

const UserNameBox = (props) => {
  const { touched, error } = props.meta;
  return (
    <FlexContainer column maxWidth={295} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {touched && error ? <ErrorText>{error}</ErrorText> : undefined}
      <FlexContainer row vAlignCenter>
        <Label margin={[0, 2, 0, 0]}>
          {props.loginNamePrefix && props.loginNamePrefix.length > 0
            ? props.loginNamePrefix + "-"
            : ""}
        </Label>
        <Input margin={[0, 4, 0, 0]} id={props.input.name} {...props.input} />
      </FlexContainer>
    </FlexContainer>
  );
};


const Icon = styled.img`
  cursor: pointer;
  padding: 0 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;
const BottomActionMenu = (props) => {
  return (
    <FlexContainer row spaceBetween margin={[16, 0, 0, 0]}>
      <FlexContainer row vAlignCenter onClick={props.onCancel}>
        <Icon src="/icons/back-arrow-24-px.svg" />
        <Button.Text>{props.t("back")}</Button.Text>
      </FlexContainer>
      <FlexContainer row flexEnd vAlignCenter>
        {props.showDeactivateButton ? (
          <Button.Standard
            onClick={() => props.onToggleUserActivity(props.organisation.id)}
            inverted
            margin={[0, 24, 0, 0]}
          >
            {props.userActivityButtonLabel}
          </Button.Standard>
        ) : undefined}
        <Button.Standard
          inactive={props.submitting || props.pristine}
          onClick={
            !(props.submtting || props.pristine) ? props.onSave : undefined
          }
          margin={[0, 24, 0, 0]}
        >
          {props.t("save")}
        </Button.Standard>
        <Button.Text
          onClick={props.dirty ? props.onCancel : undefined}
          inactive={props.pristine}
        >
          {props.t("cancel")}
        </Button.Text>
      </FlexContainer>
    </FlexContainer>
  );
};

export default UserEdit;
