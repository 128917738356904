import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { reset, SubmissionError } from "redux-form";

import { Redirect, Route, Switch } from "react-router-dom";
import InfoList from "./InfoList";
import InfoMessage from "./InfoMessage";
import {
  createInfoboardMessage,
  deleteInfoboardMessageById, fetchAllInfoboardMessages,
  fetchInfoboardMessageById,
  updateInfoboardMessageById
} from "./InfoboardActions";
import moment from "moment/moment";
import Loader from "../components/Loader";
import i18n from "../i18n";
import { ConfirmModal } from "../components/ConfirmModal";
import { FlexContainer } from "../components/FlexContainer";
import { getOBNTDate } from "../util/common";

const LIST = "list";
const MESSAGE = "message";

class InfoboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: {},
      messageToDelete: {},
      showDeleteModal: false
    };
  }

  componentDidMount() {
    const urlIdRegEx = new RegExp(`${this.props.base}/${MESSAGE}/(\\d+)`);
    const messageId = urlIdRegEx.exec(this.props.location.pathname);
    if (messageId) {
      this.props
        .dispatch(fetchInfoboardMessageById(messageId[1]))
        .then(() => this.setState({ message: this.props.message }))
        .catch(err => {});
    }
    if (!this.props.messageListIsFetched) {
      this.props.dispatch(fetchAllInfoboardMessages()).catch(err => {});
    }
  }

  render() {
    const { t, base } = this.props;

    const fetchMessageSuccess = () => {
      this.setState({ message: this.props.message });
      this.props.history.push(
        `${base}/${MESSAGE}/${this.props.message.identity}`
      );
    };

    const getMessage = id => {
      this.props
        .dispatch(fetchInfoboardMessageById(id))
        .then(fetchMessageSuccess)
        .catch(err => {});
    };

    const openDeleteMessageModal = message => {
      this.setState({ showDeleteModal: true, messageToDelete: message });
    };

    const deleteMessage = id => {
      const onDeleteMessageSuccess = () => {
        this.props.dispatch(fetchAllInfoboardMessages()).catch(err => {});
      };
      this.props
        .dispatch(deleteInfoboardMessageById(id))
        .then(onDeleteMessageSuccess)
        .catch(err => {});
    };

    const validateInfoMessages = (values, publishTime) => {
      const errors = {};

      const enValuesIsMissing =
        values.titleEn.length === 0 || values.bodyEn.length === 0;
      const noValuesIsMissing =
        values.titleNo.length === 0 || values.bodyNo.length === 0;

      if (!publishTime || !moment().subtract(1, "days").isBefore(publishTime)) {
        errors.publishDate = t("invalid_date");
      }

      if (enValuesIsMissing && noValuesIsMissing) {
        errors.errorMessage = t("fill_inn_nb_or_en_message");
      }
      return Object.keys(errors).length > 0 ? errors : undefined;
    };

    const onSubmit = (values, id) => {
      const onSaveSuccess = () => {
        this.props.dispatch(reset("ma_message"));
        this.setState({ message: {} });
        this.props.history.push(`${base}/${LIST}`);
        this.props.dispatch(fetchAllInfoboardMessages()).catch(err => {});
      };

      return new Promise((resolve, reject) => {
        let publishTime = moment(
          values.publishDate,
          "DD-MM-YYYY",
        ).isValid()
          ? moment
              .tz(values.publishDate, "DD-MM-YYYY", "UTC")
              .toDate()
              .toISOString()
          : undefined;
        const errors = validateInfoMessages(values, publishTime);
        const newValues = { ...values, publishTime };

        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          id != null
            ? this.props
                .dispatch(updateInfoboardMessageById(newValues))
                .then(onSaveSuccess)
                .catch(err => {})
            : this.props
                .dispatch(createInfoboardMessage(newValues))
                .then(onSaveSuccess)
                .catch(err => {});
        }
        resolve();
      });
    };

    return (
      <FlexContainer>
        <Switch>
          <Route
            path={`${base}/${LIST}`}
            render={props =>
              this.props.messageListIsFetched ? (
                <InfoList
                  messageList={this.props.messageList}
                  onNewMessage={() =>
                    this.props.history.push(`${base}/${MESSAGE}/`)
                  }
                  onMessageClick={getMessage}
                  onDeleteMessage={openDeleteMessageModal}
                  title={message => {
                    if (i18n.language === "nb")
                      return message.titleNo.length > 0
                        ? message.titleNo
                        : message.titleEn;
                    else
                      return message.titleEn.length > 0
                        ? message.titleEn
                        : message.titleNo;
                  }}
                  t={t}
                  {...props}
                />
              ) : (
                <Loader />
              )
            }
          />
          <Route
            path={`${base}/${MESSAGE}/:id?`}
            render={props => {
              const { id } = props.match.params;

              return (
                <InfoMessage
                  t={t}
                  initialValues={{
                    //Sets default values as db wont accept null values when sending to server
                    titleNo: "",
                    titleEn: "",
                    bodyNo: "",
                    bodyEn: "",
                    publishTime: "00:00",
                    ...this.state.message,
                    publishDate: this.state.message.publishTime
                      ? getOBNTDate(this.state.message.publishTime)
                      : getOBNTDate(moment())
                  }}
                  enableReinitialize={true}
                  onCancel={() => {
                    this.setState({ message: {} });
                    this.props.history.length > 0
                      ? this.props.history.goBack()
                      : this.props.history.push(`${this.props.base}`);
                  }}
                  onSubmit={values => onSubmit(values, id)}
                  messageId={id}
                />
              );
            }}
          />
          <Route
            path={`${base}/`}
            render={props => <Redirect to={`${base}/${LIST}`} />}
          />
        </Switch>
        <ConfirmModal
          center
          header={t("delete_ma_message_header")}
          isOpen={this.state.showDeleteModal}
          onClose={() => this.setState({ showDeleteModal: false })}
          confirmText={t("delete")}
          cancelText={t("cancel")}
          onConfirm={() => {
            this.setState({ showDeleteModal: false });
            deleteMessage(this.state.messageToDelete.identity);
          }}
        >
          <p>
            {t("delete_ma_message_info", {
              title:
                i18n.language === "nb"
                  ? this.state.messageToDelete.titleNo
                  : this.state.messageToDelete.titleEn
            })}
          </p>
        </ConfirmModal>
      </FlexContainer>
    );
  }
}

InfoboardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { infoboardReducer } = state;

  return {
    messageList: infoboardReducer.messagesAll,
    messageListIsFetched: infoboardReducer.messagesAllIsFetched,
    message: infoboardReducer.message,
    messageIsFetched: infoboardReducer.messageIsFetched
  };
}
export default connect(mapStateToProps)(
  withTranslation("translations")(InfoboardContainer)
);
