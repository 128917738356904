import React from "react";
import styled, { css } from "styled-components";
import { sizes } from "../util/common";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
const tiltedPhone = (...args) => css`
  @media (max-height: 580px) {
    ${css(...args)};
  }
`;

const tiltedTablet = (...args) => css`
  @media (max-height: 768px) and (max-width: 1024px) {
    ${css(...args)};
  }
`;

const ModalWrapper = styled.div`
  ${(props) => (props.isOpen ? "display: flex;" : "display: none;")};

  position: fixed;
  left: 20px;
  top: 120px;
  z-index: 10;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  border: 1px solid black;
  padding: 7px;
  width: 300px;
`;

const ModalBox = styled.div`
  display: grid;
  display: -ms-grid;
  -ms-grid-rows: 100px 1fr;
  -ms-grid-columns: 16px 1fr 16px;

  grid-template-columns: 16px auto 16px;
  grid-template-row: 100px 1fr;

  ${(props) => (props.width ? "width:" + props.width + ";" : "width: 50%;")}
  background: white;
  color: black;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 10%;
  max-height: 80%;

  ${media.phone`

-ms-grid-columns: 14px 1fr 14px;
max width: 90%;

grid-template-columns: 28px auto 28px;
`}

  ${media.tablet`

-ms-grid-columns: 14px 1fr 14px;
max width: 90%;

grid-template-columns: 28px auto 28px;

`}

${tiltedPhone`
max-height: 90%;
max-width: 80%;
`}

${tiltedTablet`
height: 90%;
width: 80%;
`}
`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-top: 16px;
  float: right;
  padding-right: 16px;

  ${media.phone`
padding-right: 10px;
`} ${media.tablet`

padding-right: 10px;

`};
`;

const HeaderModal = styled.h4`
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  grid-column: 2;
  grid-row: 1 / 2;
`;

const IconContainer = styled.div`
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  grid-column: 3;
  grid-row: 1 / 2;
`;

const ContentModal = styled.div`
  color: black;
  white-space: pre-line;
  overflow-wrap: break-word;
  display: block;
  margin-bottom: 10px;

  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;

  grid-column: 2;
  grid-row: 2 / 3;
`;

export const HelpModal = ({ isOpen, header, onClose, children, width }) => (
  <ModalWrapper isOpen={isOpen}>
    <ModalBox width={width}>
      <IconContainer>
        <CloseIcon onClick={onClose} src="/icons/red-cross-24.svg" />
      </IconContainer>

      <HeaderModal>{header}</HeaderModal>

      <ContentModal>{children}</ContentModal>
    </ModalBox>
  </ModalWrapper>
);
