import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import { connect } from "react-redux";
import { DropDown } from "../../components/DropDown";
import { reduxForm } from "redux-form";
import {
  deleteAttachment,
  fetchAttachment,
  fetchInsiderList,
  fetchTraceLog,
  InsiderType,
  updateInsiderListExtraData,
  uploadAttachment
} from "../InsiderToolActions";
import { NotificationManager } from "react-notifications";
import { OverlayLoader } from "../../components/Loader";
import Tracelog from "../modals/insiderList/Tracelog";
import { showFileFromServer } from "../../util/common";
import { ListComponentTemplate } from "./ListComponentTemplate";
import { getISOTimeFromDateAndTime } from "../UTCUtils";
import { FlexContainer } from "../../components/FlexContainer";

export const MANDATORY_FORM = {
  VIEW: "mandatoryKeyInformationView",
  EDIT: "mandatoryKeyInformationEdit"
};

export class MandatoryKeyInformationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showOverlayLoader: false,
      showTraceLogModal: false
    };
  }

  toggleMandatoryKeyEditModal = () =>
    this.setState({ showEdit: !this.state.showEdit });

  isVisibleInView = field =>
    [
      "section_related_to",
      "attachments",
      "date_time_responsible",
      "date_time_insider_creation",
      "tracelog",
      "responsible_decision_persons",
      "optional"
    ]
      .concat(
        this.props.listType !== InsiderType.CONFIDENTIALITY_LIST
          ? [
              "date_time_insider_existence",
              "likely_disclosure",
              "responsible_monitoring_persons",
              "responsible_notification_persons",
              "assessment_and_follow_up_of_conditions",
              "assessment_and_follow_up_of_conditions_attachments",
              "date_time_transmission"
            ]
          : undefined
      )
      .includes(field);

  isVisibleInEdit = field =>
    this.props.insiderListInfo.data.listState === "closed"
      ? ["optional", "attachments"].includes(field) ||
        (this.props.listType === InsiderType.LIST &&
          field === "date_time_transmission")
      : this.isVisibleInView(field) && !["tracelog"].includes(field);

  onMandatoryKeySubmit = values => {
    const { listId, dispatch } = this.props;
    const nonNullValues = key => values[key] != null;
    const toObject = (acc, key) => ({ ...acc, [key]: values[key] });

    const formattedValues = Object.keys(values)
      .filter(nonNullValues)
      .reduce(toObject, {});

    if (formattedValues.existence_date && formattedValues.existence_time) {
      formattedValues.date_time_insider_existence = getISOTimeFromDateAndTime(
        formattedValues.existence_date,
        formattedValues.existence_time
      );
    }
    if (formattedValues.created_date && formattedValues.created_time) {
      formattedValues.date_time_insider_creation = getISOTimeFromDateAndTime(
        formattedValues.created_date,
        formattedValues.created_time
      );
    }
    if (formattedValues.responsible_date && formattedValues.responsible_time) {
      formattedValues.date_time_responsible = getISOTimeFromDateAndTime(
        formattedValues.responsible_date,
        formattedValues.responsible_time
      );
    }

    if (
      formattedValues.likely_disclosure_date &&
      formattedValues.likely_disclosure_time
    ) {
      formattedValues.likely_disclosure = getISOTimeFromDateAndTime(
        formattedValues.likely_disclosure_date,
        formattedValues.likely_disclosure_time
      );
    }

    delete formattedValues.existence_date;
    delete formattedValues.existence_time;
    delete formattedValues.created_date;
    delete formattedValues.created_time;
    delete formattedValues.responsible_date;
    delete formattedValues.responsible_time;
    delete formattedValues.likely_disclosure_date;
    delete formattedValues.likely_disclosure_time;

    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() =>
        dispatch(
          updateInsiderListExtraData(
            InsiderType.MANDATORY_KEY_INFO,
            formattedValues,
            listId
          )
        )
      )
      .then(() => dispatch(fetchInsiderList(listId)))
      .then(this.toggleMandatoryKeyEditModal)
      .then(() => this.setState({ showOverlayLoader: false }))
      .catch(() => this.setState({ showOverlayLoader: false }));
  };

  uploadAttachment = (file, parentId) => {
    const { t, listId, listType, dispatch } = this.props;

    var formData = new FormData();

    formData.append("attachment", file);
    formData.append("listId", listId);
    formData.append("parentId", parentId);

    dispatch(uploadAttachment(formData, listType))
      .then(() => dispatch(fetchInsiderList(listId)))
      .then(() => NotificationManager.success(t("attachment_uploaded")));
  };

  openAttachment = (id, fileName) => {
    const { dispatch, listId, listType } = this.props;
    dispatch(fetchAttachment(id, listId, listType)).then(data =>
      showFileFromServer(data, fileName)
    );
  };

  removeAttachment = id => {
    const { dispatch, listId, t, listType } = this.props;

    dispatch(deleteAttachment(id, listId, listType))
      .then(() => dispatch(fetchInsiderList(listId)))
      .then(() => NotificationManager.success(t("it_attachment_removed")));
  };

  toggleTraceLog = _ => {
    const { listId, dispatch } = this.props;

    if (this.state.showTraceLogModal)
      this.setState({ showTraceLogModal: false });
    else {
      Promise.resolve(this.setState({ showOverlayLoader: true }))
        .then(() => dispatch(fetchTraceLog(listId)))
        .then(() =>
          this.setState({ showOverlayLoader: false, showTraceLogModal: true })
        );
    }
  };

  render() {
    const {
      t,
      mandatoryKeyInfo,
      listType,
      attachments,
      tracelog,
      hasEditAccess
    } = this.props;

    return (
      <div>
        {this.state.showOverlayLoader ? <OverlayLoader /> : ""}

        <DropDown
          defaultOpen={Object.keys(mandatoryKeyInfo.data).length === 0}
          key={"dropDownKey"}
          onEdit={this.toggleMandatoryKeyEditModal}
          boldHeader
          editIcon={hasEditAccess}
          header={t("it_mandatory_key_info_" + listType)}
          helpFileName={
            listType === InsiderType.LIST ? "mandatory_key_information" : ""
          }
        >
          <MandatoryKeyInformationView
            initialValues={mandatoryKeyInfo.data}
            listType={listType}
            openAttachment={this.openAttachment}
            viewTracelog={this.toggleTraceLog}
            isVisible={this.isVisibleInView}
            attachments={attachments}
            showEdit={this.state.showEdit}
          />
        </DropDown>
        <Modal
          width={"65%"}
          key={"modalKey"}
          xScroll
          isOpen={this.state.showEdit}
          onClose={this.toggleMandatoryKeyEditModal}
          header={t("it_mandatory_key_info_" + listType)}
        >
          <MandatoryKeyInformationEdit
            insiderListInfo={this.props.insiderListInfo}
            listType={listType}
            onSubmit={this.onMandatoryKeySubmit}
            initialValues={mandatoryKeyInfo.data}
            uploadAttachment={this.uploadAttachment}
            openAttachment={this.openAttachment}
            attachments={attachments}
            removeAttachment={this.removeAttachment}
            isVisible={this.isVisibleInEdit}
            inEditMode={true}
            onClose={this.toggleMandatoryKeyEditModal}
            mandatoryKeyInfoFormValues={this.props.mandatoryKeyInfoFormValues}
          />
        </Modal>
        {this.state.showTraceLogModal ? (
          <Tracelog
            onClose={this.toggleTraceLog}
            data={tracelog}
            helpTextFileName={"mandatory_tracelog"}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { insiderToolReducer, form } = state;

  const inHistoricMode = insiderToolReducer.insiderList.historicMode;

  return {
    mandatoryKeyInfo: insiderToolReducer.insiderList.mandatoryKeyData,
    attachments: insiderToolReducer.insiderList.attachments,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    tracelog: insiderToolReducer.tracelog.mandatoryKeyData,
    mandatoryKeyInfoFormValues: form.mandatoryKeyInformationEdit
      ? form.mandatoryKeyInformationEdit.values
      : {},
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    hasEditAccess:
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(MandatoryKeyInformationContainer)
);

class MandatoryKeyInformationView extends React.Component {
  render() {
    return <ListComponentTemplate {...this.props} />;
  }
}
MandatoryKeyInformationView = reduxForm({
  form: MANDATORY_FORM.VIEW,
  enableReinitialize: true
})(withTranslation("translations")(MandatoryKeyInformationView));

class MandatoryKeyInformationEdit extends React.Component {
  render() {
    return (
      <FlexContainer>
        <form autoComplete={"off"}>
          <ListComponentTemplate
            {...this.props}
            onSubmit={this.props.handleSubmit(this.props.onSubmit)}
          />
        </form>
      </FlexContainer>
    );
  }
}
MandatoryKeyInformationEdit = reduxForm({
  form: MANDATORY_FORM.EDIT,
  enableReinitialize: true
})(withTranslation("translations")(MandatoryKeyInformationEdit));
