import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { withTranslation } from "react-i18next";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { deleteToken } from "../obsvcClient";

class IncompatibleUserContainer extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <FlexContainer center vAlignCenter margin={[100, 0, 0, 0]}>
        <ErrorText fontSize={"26px"}>
          {t("your_user_is_not_applicable")}
        </ErrorText>
        <FlexContainer row center margin={[50, 0, 0, 0]}>
          <Button.Form
            type="submit"
            onClick={() => {
              deleteToken();
              window.location.assign("/");
            }}
          >
            {t("ok")}
          </Button.Form>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default withRouter(
  connect()(withTranslation("translations")(IncompatibleUserContainer))
);
