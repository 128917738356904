import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import { change } from "redux-form";
import InsiderToolPaginationContainer from "../../../components/InsiderToolPaginationContainer";
import InsiderTable from "./InsiderTable";
import { FlexContainer } from "../../../components/FlexContainer";
import { browserIsIE } from "../../../util/common";
import { SelectOptionsContainer } from "./SelectOptionsContainer";
import ImportFromExcel from "./handleInsiders/ImportFromExcel";
import { getLanguage } from "../../../util/common";
import AddNewInsider from "./handleInsiders/AddNewInsider";
import UpdateInsider from "./handleInsiders/UpdateInsider";
import { InsiderType, updateInsiderListData } from "../../InsiderToolActions";
import { getISOTimeFromDateAndTime } from "../../UTCUtils";
import WithFilterAndSort from "./Filter";

const ITTable = InsiderToolPaginationContainer(WithFilterAndSort(InsiderTable));

export class InsiderTableContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      menuTop: undefined,
      menuLeft: undefined,
      isEditInsiderFormVisible: false,
      selectedInsider: undefined
    };
  }

  showEditInsiderForm = id => {
    this.setState({
      isEditInsiderFormVisible: true,
      selectedInsider: this.props.insiderMap.get(id)
    });
  };

  hideEditInsiderForm = () => {
    this.setState({ isEditInsiderFormVisible: false });
  };

  getState = insiderId => {
    let state = "";
    this.props.insiderStateData.find(insider => {
      if (insiderId === insider.id) {
        state = insider.short_data;
      }
    });

    return state;
  };

  changeValueByState = (state, newValue) => {
    const { dispatch } = this.props;

    this.props.insiders.forEach(ins => {
      if (state === "noState" || this.getState(ins.id) === state) {
        dispatch(change("insiderTableForm", ins.id, newValue));
      } else {
        dispatch(change("insiderTableForm", ins.id, !newValue));
      }
    });
  };

  toggleMenu = event => {
    if (!this.state.showMenu && event.target.id === "selectOptionsMenu") {
      const top = browserIsIE()
        ? event.clientY - event.offsetY
        : event.target.y + event.target.height / 2;
      const left = browserIsIE()
        ? event.clientX - event.offsetX
        : event.target.x;

      this.setState({
        showMenu: true,
        menuTop: top,
        menuLeft: left
      });
    } else if (this.state.showMenu) {
      this.setState({ showMenu: false });
    }
  };

  toggleMenuOnScroll = _ => {
    if (this.state.showMenu) this.setState({ showMenu: false });
  };

  componentDidMount() {
    document.addEventListener("click", this.toggleMenu);
    document.addEventListener("scroll", this.toggleMenuOnScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.toggleMenu);
    document.removeEventListener("scroll", this.toggleMenuOnScroll);
  }

  updateUpdateTime = () => {
    const { dispatch, insiderListInfo, listId } = this.props;
    const now = moment();
    insiderListInfo.data.updated = now.toISOString();

    const {
      update_time,
      update_date,
      updatedTime,
      ...rest
    } = insiderListInfo.data;
    dispatch(
      updateInsiderListData(InsiderType.LIST, { ...rest }, listId, listId)
    );
  };

  getUpdatedDatesForInsider = values => {
    let copiedValues = JSON.parse(JSON.stringify(values));
    if (copiedValues.obtained_date && copiedValues.obtained_time) {
      copiedValues.obtained = getISOTimeFromDateAndTime(
        copiedValues.obtained_date,
        copiedValues.obtained_time
      );
    }

    if (copiedValues.ceased_date && copiedValues.ceased_time) {
      copiedValues.ceased = getISOTimeFromDateAndTime(
        copiedValues.ceased_date,
        copiedValues.ceased_time
      );
    }

    delete copiedValues.obtained_date;
    delete copiedValues.obtained_time;
    delete copiedValues.obtainedTime;
    delete copiedValues.ceased_date;
    delete copiedValues.ceased_time;
    delete copiedValues.ceasedTime;
    return copiedValues;
  };

  updateCountriesForPerson = data => {
    if (data.company_country && data.company_country.value) {
      data.company_country = data.company_country.value;
    }
    if (data.country && data.country.value) {
      data.country = data.country.value;
    }
    if (data.twoFactorCountryCode && data.twoFactorCountryCode.value) {
      data.twoFactorCountryCode = data.twoFactorCountryCode.value;
    }
  };

  replaceKeysInString = (string, language) => {
    const { t } = this.props;
    if (string) {
      let replacedString = string.replace(
        /_url_/g,
        t("it_url_replaced_here", { lng: language })
      );
      replacedString = replacedString.replace(
        /_name_ _surname_/g,
        t("it_name_replaced_here", { lng: language })
      );
      replacedString = replacedString.replace(
        /_projectName_/g,
        this.props.insiderListInfo.data.code_name
      );

      return replacedString;
    }
    return string;
  };

  render() {
    const { insiders, t } = this.props;

    return (
      <React.Fragment>
        {this.state.showMenu ? (
          <SelectOptionsContainer
            top={this.state.menuTop}
            left={this.state.menuLeft}
            t={this.props.t}
            markAllNotNotified={() => this.changeValueByState("active", true)}
            markAllNotConfirmed={() =>
              this.changeValueByState("notified", true)
            }
            markAll={() => this.changeValueByState("noState", true)}
            markAllActive={() => this.changeValueByState("active", true)}
            markAllInactive={() => this.changeValueByState("inactive", true)}
            deselectAll={() => this.changeValueByState("noState", false)}
          />
        ) : (
          ""
        )}

        <ITTable
          getState={this.getState}
          changeValueByState={this.changeValueByState}
          hasEditAccess={this.props.hasEditAccess}
          showEditInsiderForm={this.showEditInsiderForm}
          data={insiders}
          t={t}
          insiderStateData={this.props.insiderStateData}
        />

        <FlexContainer row flexEnd margin={[20, 0, 0, 0]}>
          <ImportFromExcel
            countryOptions={this.props.countryOptions}
            hasEditAccess={this.props.hasEditAccess}
          />
          <AddNewInsider
            countryOptions={this.props.countryOptions}
            hasEditAccess={this.props.hasEditAccess}
            updateUpdateTime={this.updateUpdateTime}
            getUpdatedDatesForInsider={this.getUpdatedDatesForInsider}
            updateCountriesForPerson={this.updateCountriesForPerson}
            replaceKeysInString={this.replaceKeysInString}
          />
        </FlexContainer>
        <UpdateInsider
          countryOptions={this.props.countryOptions}
          isEditInsiderFormVisible={this.state.isEditInsiderFormVisible}
          selectedInsider={this.state.selectedInsider}
          hideEditInsiderForm={this.hideEditInsiderForm}
          getState={this.getState}
          showEditInsiderForm={this.showEditInsiderForm}
          updateUpdateTime={this.updateUpdateTime}
          getUpdatedDatesForInsider={this.getUpdatedDatesForInsider}
          updateCountriesForPerson={this.updateCountriesForPerson}
          replaceKeysInString={this.replaceKeysInString}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;
  const inHistoricMode = insiderToolReducer.insiderList.historicMode;
  const listActive =
    insiderToolReducer.insiderList.insiderListInfo.data.listState === "active"
      ? true
      : false;
  const countries = insiderToolReducer.countries.countries
    ? insiderToolReducer.countries.countries
    : [];
  const countryOptions = countries.map(country => {
    return {
      label: getLanguage() === "No" ? country.nameNo : country.nameEn,
      value: country.id
    };
  });

  return {
    hasEditAccess:
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode &&
      listActive,
    insiderStateData: insiderToolReducer.insiderList.insiderStateData,
    insiders: insiderToolReducer.insiderList.insiders,
    insiderMap: insiderToolReducer.insiderList.insidersMap,
    countryOptions,
    insiderListInfo: insiderToolReducer.insiderList.insiderListInfo,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderTableContainer)
);
