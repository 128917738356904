import React from "react";
import { Modal } from "../../../components/Modal";
import {
  fetchInsiderList,
  InsiderType,
  notifyMultipleInsiders
} from "../../InsiderToolActions";
import i18n from "../../../i18n";
import { FlexContainer } from "../../../components/FlexContainer";
import { HelpIcon } from "../../../components/HelpIcon";
import { withTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { Field, reduxForm, reset } from "redux-form";
import { NoLabelTextAreaBox, NoLabelTextBox, TextBox } from "../../components";
import RadioButton from "../../../components/RadioButton";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import Label from "../../../components/Label";

const LanguageRadioButtons = props => {
  return (
    <FlexContainer row margin={[0, 0, 10, 0]} vAlignCenter>
      <RadioButton
        {...props}
        checked
        margin={[0, 0, 8, 0]}
        radioValue={"no"}
        label={props.t("norwegian")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.selectNorwegian();
            props.input.onChange(value);
          }
        }}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"en"}
        label={props.t("english")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.selectEnglish();
            props.input.onChange(value);
          }
        }}
      />
    </FlexContainer>
  );
};

class SendReminderModal extends React.Component {
  notifyInsiders = () => {
    const {
      dispatch,
      listId,
      sendReminderModalFormValues,
      insidersToApprove,
      templateName
    } = this.props;

    const mailContentObject = this.getMailContentObject(
      sendReminderModalFormValues
    );
    const lang = sendReminderModalFormValues.languageRadioButtons;

    if (insidersToApprove.length > 0) {
      dispatch(
        notifyMultipleInsiders(
          insidersToApprove,
          listId,
          mailContentObject,
          lang,
          templateName
        )
      )
        .then(this.fetchListAndResetTable)
        .then(this.props.onClose)
        .catch(e => {
          console.log(e);
          this.fetchListAndResetTable();
        });
    } else {
      NotificationManager.error(
        this.props.t("it_select_one_or_more_insiders"),
        "",
        0
      );
      return Promise.resolve();
    }
  };

  fetchListAndResetTable = () => {
    const { dispatch, listId } = this.props;
    dispatch(fetchInsiderList(listId)).then(() => {
      dispatch(reset("insiderTableForm"));
    });
  };

  getMailContentObject = values => {
    const { sendReminderModalFormValues } = this.props;
    let bodyObject;

    if (sendReminderModalFormValues.languageRadioButtons === "no") {
      bodyObject = {
        body: [values.body_0, values.body_1, values.body_2]
      };
    } else {
      bodyObject = {
        body: [values.body_en_0, values.body_en_1, values.body_en_2]
      };
    }
    return bodyObject;
  };

  render() {
    const {
      t,
      onClose,
      listType,
      emailTemplateNo,
      emailTemplateEn,
      notifyReminder,
      selectNorwegian,
      selectEnglish,
      norwegianSelected
    } = this.props;

    const header = (
      <FlexContainer row center>
        {t(
          notifyReminder === "notify"
            ? "send_notification_insider"
            : "it_send_reminder_insider"
        )}
        <HelpIcon
          margin={[8, 0, -3, 5]}
          fileName={
            listType === InsiderType.LIST
              ? notifyReminder === "notify"
                ? "send_notification_insider"
                : "send_reminder"
              : notifyReminder === "notify"
                ? "send_notification_person"
                : "send_reminder"
          }
          language={i18n.language}
          insiderToolHelp
          height={"18"}
          width={"18"}
        />
      </FlexContainer>
    );

    const fields = norwegianSelected
      ? emailTemplateNo.body.map((element, index) => (
          <Field
            component={element ? NoLabelTextBox : NoLabelTextAreaBox}
            name={"body_" + index}
            margin={[8, 0, 0, 0]}
            key={"it_body_" + index}
          />
        ))
      : emailTemplateEn.body.map((element, index) => (
          <Field
            component={element ? NoLabelTextBox : NoLabelTextAreaBox}
            name={"body_en_" + index}
            margin={[8, 0, 0, 0]}
            key={"it_body_en_" + index}
          />
        ));

    const subject = (
      <FlexContainer margin={[0, 0, 20, 0]}>
        {norwegianSelected ? (
          <TextBox
            label={t("subject", { lng: "nb" })}
            input={{ value: emailTemplateNo.subject }}
          />
        ) : (
          <TextBox
            label={t("subject", { lng: "en" })}
            input={{ value: emailTemplateEn.subject }}
          />
        )}
      </FlexContainer>
    );

    return (
      <Modal isOpen onClose={onClose} header={header}>
        <FlexContainer>
          <FlexContainer>
            <Field
              component={LanguageRadioButtons}
              t={t}
              name={"languageRadioButtons"}
              selectNorwegian={selectNorwegian}
              selectEnglish={selectEnglish}
            />
            {subject}
            <Label>
              {t("it_tracelog_email_body", {
                lng: norwegianSelected ? "nb" : "en"
              })}
              :
            </Label>
            {fields}
          </FlexContainer>
          <FlexContainer vAlignCenter row flexEnd margin={[20, 0, 20, 0]}>
            <Button.Standard
              margin={[0, 16, 0, 0]}
              onClick={() => this.props.onSend(this.notifyInsiders)}
            >
              {t("it_send_mail")}
            </Button.Standard>
            <Button.Text onClick={onClose}>{t("cancel")}</Button.Text>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
}

SendReminderModal = reduxForm({
  form: "sendReminderModalForm"
})(SendReminderModal);

function mapStateToProps(state) {
  const { form, insiderToolReducer } = state;

  return {
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    sendReminderModalFormValues: form.sendReminderModalForm
      ? form.sendReminderModalForm.values
      : undefined
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(SendReminderModal)
);
