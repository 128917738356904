import React from "react";
import styled from "styled-components";
import FocusElement from "./FocusElement";

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #336699;
  box-sizing: border-box;
  height: 38px;

  color: #ffffff;

  padding: 0 16px 0 16px;
  font-family: inherit;
  font-size: 16px;
  ${props => (props.noTextTransform ? "" : "text-transform: uppercase")};

  ${props => (props.marginTop ? "margin-top: " + props.marginTop + "px;" : "")};
  ${props =>
    props.marginBottom ? "margin-bottom: " + props.marginBottom + "px;" : ""};
  ${props => (props.height ? "height: " + props.height + "px;" : "")};
`;
const Icon = styled.img``;

export const HeaderPanel = props => (
  <HeaderDiv {...props}>
    {props.children}
    {props.withCloseButton ? (
      <FocusElement onClick={props.onCloseClick}>
        <Icon src="/icons/ic-close-black-24-px.svg" />
      </FocusElement>
    ) : (
      ""
    )}
  </HeaderDiv>
);
