import React from "react";
import {
  DateBox,
  Header,
  Icon,
  InputBox,
  TextAreaBox,
  TextBox,
  TimePickerBox,
  Attachment
} from "../components";
import Button from "../../components/Button";
import { FlexContainer } from "../../components/FlexContainer";
import { Field } from "redux-form";
import SimpleFileUploader from "../../components/SimpleFileUploader";
import { OBNTInsiderToolDateFormat } from "../../util/common";
import { getUtcDateTimeLabelFromFormValues } from "../UTCUtils";
import { InsiderType } from "../InsiderToolActions";
import { HelpIcon } from "../../components/HelpIcon";
import i18n from "../../i18n";

export const ACTION = {
  EDIT: "edit",
  VIEW: "view"
};

export class ListComponentTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      mapResponsiblePersons: new Map([[0, 0]])
    };

    this.component = this.props.inEditMode ? InputBox : TextBox;
    this.componentTextArea = this.props.inEditMode ? TextAreaBox : TextBox;
    this.componentTime = this.props.inEditMode ? TimePickerBox : TextBox;
    this.componentDateBox = this.props.inEditMode ? DateBox : TextBox;
  }

  isActive = listState => {
    return listState === "active" ? true : false;
  };

  createResponsiblePersonsMap = initialValues => {
    const responsiblePersonsKeys = key =>
      key.match(/responsible_(name|surname|position)/) != null;
    const toPersonIndex = key =>
      key.match(/responsible_(name|surname|position)_([0-9]+)/)[2];
    const zeroIndex = i => i > 0;
    const toArrayTuple = i => [i, 0];
    const initialValue = [[0, 0]];

    return new Map(
      Object.keys(initialValues || {})
        .filter(responsiblePersonsKeys)
        .map(toPersonIndex)
        .filter(zeroIndex)
        .distinctValues()
        .map(toArrayTuple)
        .concat(initialValue)
        .sort()
    );
  };

  componentDidMount() {
    const newMap = this.createResponsiblePersonsMap(this.props.initialValues);
    this.setState({ mapResponsiblePersons: newMap });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.initialValues && this.props.initialValues) {
      if (!Object.compare(nextProps.initialValues, this.props.initialValues)) {
        const newMap = this.createResponsiblePersonsMap(
          nextProps.initialValues
        );
        this.setState({ mapResponsiblePersons: newMap });
      }
    }
  }

  addResponsiblePersonRow = () => {
    const maxKeyValue = Array.from(
      this.state.mapResponsiblePersons.keys()
    ).reduce(
      (acc, val) => (Number(val) > Number(acc) ? Number(val) : Number(acc)),
      0
    );

    const newMap = new Map(this.state.mapResponsiblePersons.entries()).set(
      maxKeyValue + 1,
      0
    );

    this.setState({ mapResponsiblePersons: newMap });
  };

  removeResponsiblePersonRow = i => {
    const newMap = new Map(this.state.mapResponsiblePersons.entries());
    newMap.delete(i);
    this.setState({ mapResponsiblePersons: newMap });
  };

  responsiblePersonsList() {
    const { t, change } = this.props;

    const isFirstRow = i => i === 0;
    const remove = i => {
      change(`responsible_name_${i}`, null);
      change(`responsible_surname_${i}`, null);
      change(`responsible_position_${i}`, null);

      this.removeResponsiblePersonRow(i);
    };

    return Array.from(this.state.mapResponsiblePersons.keys()).map(
      (i, index) => (
        <FlexContainer row key={"personRow_" + index + this.props.action}>
          <Field
            component={this.component}
            name={`responsible_name_${i}`}
            label={t("name")}
            noLabel={!isFirstRow(index)}
            margin={[0, 0, 10, 0]}
          />

          <Field
            component={this.component}
            name={`responsible_surname_${i}`}
            label={t("surname")}
            noLabel={!isFirstRow(index)}
            margin={[0, 0, 10, 5]}
          />
          <Field
            component={this.component}
            noWrap
            name={`responsible_position_${i}`}
            label={t("position_within_issuer")}
            noLabel={!isFirstRow(index)}
            margin={[0, 0, 10, 5]}
          />
          {this.props.inEditMode ? (
            <FlexContainer flexEnd width="auto" margin={[0, 0, 22, 10]}>
              <Icon
                id={"removeResponsiblePerson" + index}
                onClick={() => remove(i)}
                src="/icons/red-cross-24.svg"
              />
            </FlexContainer>
          ) : (
            ""
          )}
        </FlexContainer>
      )
    );
  }

  leftColumnFields = [
    "insiderlist_metadata",
    "person_nca",
    "section_related_to",
    "responsible_decision_persons",
    "date_time_responsible",
    "responsible_monitoring_persons",
    "assessment_and_follow_up_of_conditions",
    "assessment_and_follow_up_of_conditions_attachments"
  ];

  isLeftColumnVisible =
    this.leftColumnFields.find(column => this.props.isVisible(column)) !==
    undefined;

  rightColumnFields = [
    "date_time_insider_existene",
    "date_time_insider_existence",
    "likely_disclosure",
    "responsible_notification_persons",
    "date_time_transmission",
    "reference_to_announcement",
    "attachments",
    "tracelog",
    "optional"
  ];

  isRightColumnVisible =
    this.rightColumnFields.find(column => this.props.isVisible(column)) !==
    undefined;

  render() {
    const {
      t,
      listType,
      isVisible,
      inEditMode,
      disableEditMode,
      viewTracelog,
      uploadAttachment,
      openAttachment,
      removeAttachment,
      attachments,
      onEdit,
      isNCADropDown,
      mandatoryKeyInfoFormValues,
      listIsClosed,
      insiderListInfo
    } = this.props;

    return (
      <FlexContainer row>
        {/*leftColumn*/}
        <FlexContainer
          key={"leftColumn"}
          width={this.isLeftColumnVisible ? "100%" : "auto"}
          margin={[0, 16, 0, 0]}
          column
        >
          {isVisible("insiderlist_metadata") ? (
            <FlexContainer row margin={[20, 16, 0, 0]}>
              <Field
                fontSize={inEditMode ? "14" : "16"}
                component={this.component}
                margin={inEditMode ? [0, 20, 0, 0] : undefined}
                name="insiderlist_for"
                bold
                label={t("it_insiderlist_for_" + listType)}
                helpTextFile={
                  listType === InsiderType.LIST
                    ? "insiderlist_for"
                    : "insiderlist_for_conf"
                }
              />
              <Field
                fontSize={inEditMode ? "14" : "16"}
                component={this.component}
                name="code_name"
                bold
                label={t("it_project_name")}
                extraLabel={"(" + t("code_name") + ")"}
                minWidth={250}
                helpTextFile={
                  listType === InsiderType.LIST
                    ? "project_name"
                    : "project_name_conf"
                }
              />
              {!inEditMode && !disableEditMode ? (
                <FlexContainer
                  center
                  vAlignCenter
                  width={"auto"}
                  margin={[0, 50, 0, 0]}
                >
                  <Icon
                    onClick={onEdit}
                    id={this.props.id + "edit"}
                    key={"iconEdit"}
                    src={"/icons/ic-edit-black-24-px.svg"}
                  />
                </FlexContainer>
              ) : (
                ""
              )}
            </FlexContainer>
          ) : (
            ""
          )}

          {isVisible("person_nca") ? (
            <React.Fragment>
              <Header>
                {t("it_person_nca")}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={"person_nca"}
                  language={i18n.language}
                  insiderToolHelp
                  height={"16"}
                  width={"16"}
                />
              </Header>
              <FlexContainer row>
                <Field
                  component={this.component}
                  name="person_nca_name"
                  label={t("name")}
                />
                <Field
                  component={this.component}
                  name="person_nca_surname"
                  label={t("surname")}
                />
                <Field
                  component={this.component}
                  name="person_nca_phone"
                  label={t("it_phone_work")}
                />
              </FlexContainer>
              <FlexContainer row>
                <Field
                  component={this.component}
                  name="person_nca_position_within_issuer"
                  label={t("position_within_issuer")}
                />
                <Field
                  component={this.component}
                  name="person_nca_email"
                  label={t("it_email_prof")}
                />
                {!inEditMode ? <FlexContainer /> : undefined}
              </FlexContainer>
            </React.Fragment>
          ) : (
            ""
          )}
          {isVisible("section_related_to") ? (
            <React.Fragment>
              <Header>
                {t("it_section_related_to_" + listType)}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={
                    listType === InsiderType.LIST
                      ? "section_related_to"
                      : "section_related_to_conf"
                  }
                  language={i18n.language}
                  insiderToolHelp
                  height={"16"}
                  width={"16"}
                />
              </Header>
              <Field
                component={this.component}
                name="inside_name_deal"
                label={t("it_inside_name_deal_" + listType)}
              />
            </React.Fragment>
          ) : (
            ""
          )}
          {isVisible("responsible_decision_persons") ? (
            <React.Fragment>
              <Header>
                {t("it_responsible_decision_header_" + listType)}
                {listType === InsiderType.LIST ? (
                  <HelpIcon
                    margin={[0, 0, -3, 5]}
                    fileName={
                      isNCADropDown
                        ? "responsible_decision_nca"
                        : "responsible_decision"
                    }
                    language={i18n.language}
                    insiderToolHelp
                    height={"16"}
                    width={"16"}
                  />
                ) : (
                  undefined
                )}
              </Header>
              <FlexContainer
                column
                id="responsiblePersonList"
                key="responsiblePersonList"
                grey={isNCADropDown}
              >
                {this.responsiblePersonsList()}
              </FlexContainer>

              {inEditMode ? (
                <Button.Standard
                  margin={[10, 0, 0, 0]}
                  id="addResponsiblePersonBtn"
                  onClick={this.addResponsiblePersonRow}
                >
                  {t("add_new_person")}
                </Button.Standard>
              ) : (
                ""
              )}
            </React.Fragment>
          ) : (
            ""
          )}
          {isVisible("date_time_responsible") &&
          listType === InsiderType.LIST ? (
            <React.Fragment>
              <Header>
                {t("it_date_time_responsible")}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={
                    isNCADropDown
                      ? "date_time_responsible_nca"
                      : "date_time_responsible"
                  }
                  language={i18n.language}
                  insiderToolHelp
                  height={"16"}
                  width={"16"}
                />
              </Header>
              <FlexContainer row grey={isNCADropDown}>
                <Field
                  component={this.componentDateBox}
                  name="responsible_date"
                  border
                  label={
                    t("date") +
                    " " +
                    getUtcDateTimeLabelFromFormValues(
                      mandatoryKeyInfoFormValues,
                      "responsible_date",
                      "responsible_time"
                    )
                  }
                />
                <Field
                  component={this.componentTime}
                  name="responsible_time"
                  border
                  label={t("time")}
                />
                {!inEditMode ? <FlexContainer /> : undefined}
              </FlexContainer>
            </React.Fragment>
          ) : (
            ""
          )}

          {isVisible("responsible_monitoring_persons") ? (
            <React.Fragment>
              <Header>
                {t("it_responsible_monitoring_persons")}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={"responsible_monitoring_persons"}
                  language={i18n.language}
                  insiderToolHelp
                  height={"16"}
                  width={"16"}
                />
              </Header>
              <Field
                component={this.componentTextArea}
                border
                noResize
                name="responsible_monitoring_persons"
                label={t("name")}
              />
            </React.Fragment>
          ) : (
            ""
          )}

          {isVisible("assessment_and_follow_up_of_conditions") ? (
            <React.Fragment>
              <Header>
                {t("it_assessment_and_follow_up_of_conditions")}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={"assessment_and_follow_up_of_conditions"}
                  language={i18n.language}
                  insiderToolHelp
                  height={"16"}
                  width={"16"}
                />
              </Header>
              <Field
                component={this.componentTextArea}
                border
                noResize
                name="assessment_and_follow_up_of_conditions"
                noLabel
              />
            </React.Fragment>
          ) : (
            ""
          )}
          {isVisible("assessment_and_follow_up_of_conditions_attachments") ? (
            <FlexContainer margin={[8, 0, 0, 0]} column>
              <FlexContainer column margin={[0, 0, 8, 0]}>
                <Header margin={[0]}>{t("attachments")}</Header>
                {attachments != null
                  ? attachments
                      .filter(file => {
                        return file.parentId === "ASSESSMENT";
                      })
                      .map((file, index) => (
                        <Attachment
                          key={index}
                          noIcon
                          onClick={() => openAttachment(file.id, file.name)}
                          onClickDelete={() =>
                            removeAttachment
                              ? removeAttachment(file.id)
                              : undefined
                          }
                          showDelete={inEditMode}
                        >
                          {file.name}
                        </Attachment>
                      ))
                  : ""}
              </FlexContainer>
              {inEditMode ? (
                <SimpleFileUploader
                  uploadFile={file => uploadAttachment(file, "ASSESSMENT")}
                  fileValidator={file => undefined}
                >
                  {t("upload_files")}
                </SimpleFileUploader>
              ) : (
                ""
              )}
            </FlexContainer>
          ) : (
            ""
          )}
          {!this.isRightColumnVisible && inEditMode ? (
            <FlexContainer vAlignCenter row flexEnd>
              <Button.Standard
                margin={[16, 16, 0, 0]}
                onClick={this.props.onSubmit}
              >
                {t("save")}
              </Button.Standard>
              <Button.Text onClick={this.props.onClose} margin={[16, 10, 0, 0]}>
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          ) : (
            ""
          )}
        </FlexContainer>

        {/* COLUMN TWO */}
        {this.isRightColumnVisible ? (
          <FlexContainer
            key={"rightColumn"}
            width={this.isRightColumnVisible ? "100%" : "auto"}
            column
            spaceBetween
          >
            <FlexContainer>
              {isVisible("date_time_insider_existence") ? (
                <React.Fragment>
                  <Header>
                    {t("it_date_time_insider_existence")}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={"date_time_insider_existence"}
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <FlexContainer minWidth={500} row>
                    <Field
                      component={this.componentDateBox}
                      name="existence_date"
                      label={
                        t("date") +
                        " " +
                        getUtcDateTimeLabelFromFormValues(
                          mandatoryKeyInfoFormValues,
                          "existence_date",
                          "existence_time"
                        )
                      }
                      border
                    />
                    <Field
                      component={this.componentTime}
                      name="existence_time"
                      label={t("time")}
                    />
                  </FlexContainer>
                </React.Fragment>
              ) : (
                ""
              )}

              {isVisible("date_time_insider_creation") ? (
                <React.Fragment>
                  <Header>
                    {t("it_date_time_insider_creation_" + listType)}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={
                        listType === InsiderType.LIST
                          ? "date_time_insider_creation"
                          : "date_time_insider_creation_conf"
                      }
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <FlexContainer row>
                    <Field
                      component={this.componentDateBox}
                      name="created_date"
                      label={
                        t("date") +
                        " " +
                        getUtcDateTimeLabelFromFormValues(
                          mandatoryKeyInfoFormValues,
                          "created_date",
                          "created_time"
                        )
                      }
                      border
                    />
                    <Field
                      component={this.componentTime}
                      name="created_time"
                      label={t("time")}
                    />
                  </FlexContainer>
                </React.Fragment>
              ) : (
                ""
              )}

              {isVisible("likely_disclosure") ? (
                <React.Fragment>
                  <Header key="transmissionHeader">
                    {t("it_likely_disclosure")}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={"likely_disclosure"}
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <FlexContainer row>
                    <Field
                      component={this.componentDateBox}
                      border
                      name="likely_disclosure_date"
                      label={
                        t("date") +
                        " " +
                        getUtcDateTimeLabelFromFormValues(
                          mandatoryKeyInfoFormValues,
                          "likely_disclosure_date",
                          "likely_disclosure_time"
                        )
                      }
                    />
                    <Field
                      component={this.componentTime}
                      name="likely_disclosure_time"
                      label={t("time")}
                    />
                  </FlexContainer>
                </React.Fragment>
              ) : (
                ""
              )}

              {isVisible("responsible_notification_persons") ? (
                <React.Fragment>
                  <Header>
                    {t("it_responsible_notification_persons")}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={"responsible_notification_persons"}
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <Field
                    component={this.componentTextArea}
                    border
                    noResize
                    name="responsible_notification_persons"
                    label={t("name")}
                  />
                </React.Fragment>
              ) : (
                ""
              )}

              {isVisible("date_time_transmission") ? (
                <React.Fragment>
                  <Header key="transmissionHeader">
                    {t("it_date_transmission")}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={"date_transmission"}
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <FlexContainer width={"50%"}>
                    <Field
                      key={"transmissionInput"}
                      component={this.componentDateBox}
                      name="date_transmission"
                      label={t("date")}
                      border
                      dateFormat={OBNTInsiderToolDateFormat}
                    />
                  </FlexContainer>
                </React.Fragment>
              ) : (
                ""
              )}
              {isVisible("reference_to_announcement") ? (
                <React.Fragment>
                  {inEditMode ? (
                    listIsClosed ? (
                      <React.Fragment>
                        <Header>
                          {t("reference_to_announcement")}
                          <HelpIcon
                            margin={[0, 0, -3, 5]}
                            fileName={"reference_to_announcement"}
                            language={i18n.language}
                            insiderToolHelp
                            height={"16"}
                            width={"16"}
                          />
                        </Header>
                        <Field
                          height="60px"
                          border
                          noResize
                          fontSize={"14"}
                          component={TextAreaBox}
                          name="reference_to_announcement_en"
                          label={t("language_en")}
                        />
                        <Field
                          height="60px"
                          noResize
                          fontSize={"14"}
                          component={TextAreaBox}
                          name="reference_to_announcement_no"
                          border
                          label={t("language_no")}
                          minWidth={250}
                        />
                      </React.Fragment>
                    ) : (
                      undefined
                    )
                  ) : (
                    <React.Fragment>
                      <Header grey={!listIsClosed}>
                        {t("reference_to_announcement")}
                        <HelpIcon
                          margin={[0, 0, -3, 5]}
                          fileName={"reference_to_announcement"}
                          language={i18n.language}
                          insiderToolHelp
                          height={"16"}
                          width={"16"}
                        />
                      </Header>
                      <Field
                        component={TextBox}
                        name="reference_to_announcement_en"
                        label={t("language_en")}
                        grey={!listIsClosed}
                      />
                      <Field
                        component={TextBox}
                        name="reference_to_announcement_no"
                        label={t("language_no")}
                        grey={!listIsClosed}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                ""
              )}

              {isVisible("optional") ? (
                <React.Fragment>
                  <Header>
                    {t("optional")}
                    <HelpIcon
                      margin={[0, 0, -3, 5]}
                      fileName={
                        isNCADropDown ? "optional_text_nca" : "optional_text"
                      }
                      language={i18n.language}
                      insiderToolHelp
                      height={"16"}
                      width={"16"}
                    />
                  </Header>
                  <Field
                    component={this.componentTextArea}
                    border
                    noResize
                    name="optional"
                    label={t("optional_textfield")}
                  />
                </React.Fragment>
              ) : (
                ""
              )}
              {isVisible("attachments") ? (
                <FlexContainer margin={[8, 0, 0, 0]}>
                  <FlexContainer column margin={[0, 0, 8, 0]}>
                    <Header margin={[0]}>{t("attachments")}</Header>
                    {attachments != null
                      ? attachments
                          .filter(file => file.parentId === "OPTIONAL")
                          .map((file, index) => (
                            <Attachment
                              key={index}
                              noIcon
                              onClick={() => openAttachment(file.id, file.name)}
                              onClickDelete={() =>
                                removeAttachment
                                  ? removeAttachment(file.id)
                                  : undefined
                              }
                              showDelete={inEditMode}
                            >
                              {file.name}
                            </Attachment>
                          ))
                      : ""}
                  </FlexContainer>
                  {inEditMode ? (
                    <SimpleFileUploader
                      uploadFile={file => uploadAttachment(file, "OPTIONAL")}
                      fileValidator={file => undefined}
                    >
                      {t("upload_files")}
                    </SimpleFileUploader>
                  ) : (
                    ""
                  )}
                </FlexContainer>
              ) : (
                ""
              )}
            </FlexContainer>

            <FlexContainer row vAlignEnd flexEnd margin={[20, 0, 0, 0]}>
              {isVisible("tracelog") ? (
                <Button.Standard inverted onClick={viewTracelog}>
                  {t("tracelog")}
                </Button.Standard>
              ) : (
                ""
              )}

              {inEditMode ? (
                <FlexContainer margin={[0, 0, 8, 0]} vAlignCenter row flexEnd>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={this.props.onSubmit}
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text onClick={this.props.onClose}>
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
              ) : (
                ""
              )}
            </FlexContainer>
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
    );
  }
}

ListComponentTemplate.defaultProps = {
  inEditMode: false,
  isVisible: field => false,
  initialValues: {}
};
export default ListComponentTemplate;
