import React from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "../../components/Modal";
import { connect } from "react-redux";
import { DropDown } from "../../components/DropDown";
import { reduxForm, SubmissionError } from "redux-form";
import {
  fetchInsiderList,
  fetchTraceLog,
  InsiderType,
  updateInsiderListExtraData
} from "../InsiderToolActions";
import Tracelog from "../modals/insiderList/Tracelog";
import { OverlayLoader } from "../../components/Loader";
import { ListComponentTemplate } from "./ListComponentTemplate";
import { FlexContainer } from "../../components/FlexContainer";
import { emailRequirementValidator } from "../../util/emailValidator";

export const MANDATORY_NCA_ACTION = {
  EDIT: "edit",
  VIEW: "view"
};

export const MANDATORY_NCA_FORM = {
  VIEW: "mandatoryNCAInfoView",
  EDIT: "mandatoryNCAInfoEdit"
};

export class MandatoryNCAInfoContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showOverlayLoader: false,
      showTraceLogModal: false
    };
  }

  toggleEditModal = () => this.setState({ showEdit: !this.state.showEdit });

  isVisibleInView = field =>
    [
      "person_nca",
      "responsible_decision_persons",
      "optional",
      "tracelog",
      "date_time_responsible",
      "reference_to_announcement"
    ].includes(field);

  isVisibleInEdit = field =>
    this.props.insiderListInfo !== undefined &&
    this.props.insiderListInfo.data.listState === "active"
      ? [
          "person_nca",
          "optional",
          "saveButtonLeft",
          "reference_to_announcement"
        ].includes(field)
      : field === "optional";

  validateEmailInNCAInformation = email => {
    const errors = {};
    if (emailRequirementValidator(email)) {
      errors.person_nca_email = this.props.t("invalid_email");
    }

    return Object.keys(errors).length > 0 ? errors : undefined;
  };

  onSubmit = values => {
    const { listId, dispatch } = this.props;

    return new Promise((resolve, reject) => {
      const errors = this.validateEmailInNCAInformation(
        values.person_nca_email
      );
      if (errors) {
        this.setState({ loading: false });
        reject(new SubmissionError(errors));
      } else {
        Promise.resolve(this.setState({ showOverlayLoader: true }))
          .then(() =>
            dispatch(
              updateInsiderListExtraData(
                InsiderType.MANDATORY_NCA_INFO,
                values,
                listId
              )
            )
          )
          .then(resolve)
          .then(() => dispatch(fetchInsiderList(listId)))
          .then(this.toggleEditModal)
          .then(() => this.setState({ showOverlayLoader: false }));
      }
    });
  };

  toggleTraceLog = _ => {
    const { listId, dispatch } = this.props;

    if (this.state.showTraceLogModal)
      this.setState({ showTraceLogModal: false });
    else {
      Promise.resolve(this.setState({ showOverlayLoader: true }))
        .then(() => dispatch(fetchTraceLog(listId)))
        .then(() =>
          this.setState({ showOverlayLoader: false, showTraceLogModal: true })
        );
    }
  };

  render() {
    const {
      t,
      mandatoryNcaInfo,
      mandatoryKeyInfoData,
      listType,
      tracelog,
      hasEditAccess,
      isClosed
    } = this.props;

    const { optional, ...keyInfoRest } = mandatoryKeyInfoData.data;

    return (
      <div>
        {this.state.showOverlayLoader ? <OverlayLoader /> : ""}
        <DropDown
          onEdit={this.toggleEditModal}
          editIcon={hasEditAccess}
          key="dropDownNCA"
          boldHeader
          defaultOpen={!mandatoryNcaInfo.data}
          header={t("mandatory_nca")}
          helpFileName={"mandatory_nca_information"}
        >
          <MandatoryNCAInfoView
            initialValues={{
              ...keyInfoRest,
              ...mandatoryNcaInfo.data,
              reference_to_announcement:
                mandatoryNcaInfo &&
                mandatoryNcaInfo.data &&
                mandatoryNcaInfo.data.reference_to_announcement
                  ? mandatoryNcaInfo.data.reference_to_announcement
                  : "[Link]"
            }}
            /* we have to send in a dummy parameter (that isn't used so that the 
            component will update if values in the mandatory key information is updated)*/
            dummyVal={mandatoryKeyInfoData.data}
            viewTracelog={this.toggleTraceLog}
            listType={listType}
            isVisible={this.isVisibleInView}
            isNCADropDown
            listIsClosed={isClosed}
          />
        </DropDown>
        <Modal
          width="65%"
          key={"modal"}
          isOpen={this.state.showEdit}
          onClose={this.toggleEditModal}
          header={t("mandatory_nca")}
        >
          <MandatoryNCAInfoEdit
            onSubmit={this.onSubmit}
            initialValues={{
              ...mandatoryNcaInfo.data
            }}
            listType={listType}
            isVisible={this.isVisibleInEdit}
            inEditMode={true}
            onClose={this.toggleEditModal}
            isNCADropDown
            listIsClosed={isClosed}
          />
        </Modal>
        {this.state.showTraceLogModal ? (
          <Tracelog
            onClose={this.toggleTraceLog}
            data={tracelog}
            helpTextFileName={"mandatory_tracelog"}
          />
        ) : (
          undefined
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { insiderToolReducer } = state;
  const inHistoricMode = insiderToolReducer.insiderList.historicMode;

  return {
    mandatoryNcaInfo: insiderToolReducer.insiderList.mandatoryNCAData,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    isClosed:
      insiderToolReducer.insiderList.insiderListInfo.data &&
      insiderToolReducer.insiderList.insiderListInfo.data
        ? insiderToolReducer.insiderList.insiderListInfo.data.listState ===
          "closed"
        : false,

    tracelog: insiderToolReducer.tracelog.mandatoryNCAData,
    mandatoryKeyInfoData: insiderToolReducer.insiderList.mandatoryKeyData,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    hasEditAccess:
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode
  };
}
export default connect(mapStateToProps)(
  withTranslation("translations")(MandatoryNCAInfoContainer)
);

class MandatoryNCAInfoView extends React.Component {
  render() {
    return <ListComponentTemplate {...this.props} />;
  }
}
MandatoryNCAInfoView = reduxForm({
  form: MANDATORY_NCA_FORM.VIEW,
  enableReinitialize: true
})(withTranslation("translations")(MandatoryNCAInfoView));

class MandatoryNCAInfoEdit extends React.Component {
  render() {
    return (
      <FlexContainer>
        <form autoComplete={"off"}>
          <ListComponentTemplate
            {...this.props}
            onSubmit={this.props.handleSubmit(this.props.onSubmit)}
          />
        </form>
      </FlexContainer>
    );
  }
}
MandatoryNCAInfoEdit = reduxForm({
  form: MANDATORY_NCA_FORM.EDIT,
  enableReinitialize: true
})(withTranslation("translations")(MandatoryNCAInfoEdit));
