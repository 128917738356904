import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { APP_WIDTH } from "../util/common";

const TableHeader = styled.thead`
  background-color: #336699;
`;

const StyledTable = styled.table`
  border-spacing: 0px;
  width: 100%;
  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
  ${props =>
    props.spaceUnderEachRow
      ? css`
          border-collapse: separate;
          border-spacing: 0 2px;
        `
      : ""};
  ${props =>
    props.stickyColumns
      ? css`
          position: absolute;
          top: 0;
          left: 0;
          visibility: hidden;
        `
      : ""};
`;

const TH = styled.th`
  background-color: #336699;
  box-sizing: border-box;
  height: 60px;
  color: white;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  padding: 8px 8px 8px 12px;

  ${props => (props.sticky ? "visibility:visible;" : "")};
  ${props => (props.rightBorder ? "border-right:1px dotted #000;" : "")};

  ${props =>
    props.fontWeight ? "font-weight: " + props.fontWeight + ";" : ""};
  ${props => (props.width !== undefined ? "width: " + props.width + ";" : "")};
  ${props =>
    props.center !== undefined ? "text-align: center;" : "text-align:left;"};
  ${props => (props.pointer ? "cursor: pointer" : "")};
  ${props =>
    props.ascending
      ? `&:after {
  content: '\\25B2';
  font-size: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  }`
      : ""} ${props =>
    props.descending
      ? `&:after {
  content: '\\25BC';
  font-size: 10px;
  padding-left: 5px;
  padding-bottom: 5px;
  }`
      : ""};
`;

export const TD = styled.td`
  ${props =>
    props.printBorder ? "@media print {border: 1px black solid;}" : ""}

  ${props => (props.sticky ? "visibility:visible; background: inherit;" : "")};
  ${props => (props.rightBorder ? "border-right:1px dotted #000;" : "")};

  ${props => (props.pointer ? "cursor: pointer;" : "")}
  ${props => (props.bold ? "font-weight: 600;" : "")}
    ${props => (props.boldFirstLine ? "&::first-line{font-weight: 600;}" : "")}
    ${props =>
      props.width !== undefined ? "max-width: " + props.width + "px;" : ""};
  ${props => (props.minWidth ? "min-width: " + props.minWidth + "px;" : "")};
  ${props => (props.pre !== undefined ? "white-space: pre" : "")}
  ${props => (props.pre_wrap !== undefined ? "white-space: pre-wrap" : "")}
    ${props => (props.alignTop !== undefined ? "vertical-align: top;" : "")}
    ${props =>
      props.monospace !== undefined
        ? "font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;"
        : ""}
    
    ${props => (props.capitalize ? "text-transform: capitalize;" : "")};
  ${props =>
    props.center !== undefined ? "text-align: center;" : "text-align:left;"}
      ${props =>
    props.right !== undefined ? "text-align: right;" : ""}
  ${props =>
    props.breakWordsWithHyphen
      ? css`
          -ms-word-break: break-word;
          word-break: break-word;

          -webkit-hyphens: auto;
          -moz-hyphens: auto;
          hyphens: auto;
        `
      : ""}
        ${props =>
          props.breakWords
            ? css`
                -ms-word-break: break-word;
                word-break: break-word;
              `
            : ""}
    ${props =>
      props.ellipsis
        ? css`
            text-overflow: ellipsis;
            /* Required for text-overflow to do anything */
            white-space: nowrap;
            overflow: hidden;
          `
        : ""}

    box-sizing: border-box;
  padding: 8px 8px 8px 12px;

  ${props => (props.deleted ? "text-decoration: line-through;" : "")}
   ${props => (props.underline ? "text-decoration: underline;" : "")}
  ${props => (props.fontSize ? "font-size: " + props.fontSize + "px;" : "")}
    ${props => (props.greyText ? "color: #a9a9a9;" : "")};
  ${props => (props.color ? "color: " + props.color + ";" : "")};

`;

export const TR = styled.tr`
  ${props =>
    props.disableAlternation
      ? "background: white;"
      : css`
    &:nth-child(odd) {
      background: rgba(178, 207, 174, 0.2);";
    }
    &:nth-child(even) {
      background: white;";
    }
  `};

  ${props => (props.greenBackground ? "background: rgb(224, 236, 223);" : "")};
  ${props => (props.yellowBackground ? "background: rgb(255, 251, 229);" : "")};
  ${props => (props.blueBackground ? "background: rgb(214, 224, 235);" : "")};
  ${props =>
    props.redBackgroundImportant
      ? "background: rgb(265, 133, 133) !important;"
      : ""};

  height: ${props => (props.height ? props.height + "px;" : "46px")};
  width: 100%;
  ${props => (props.lineThrough ? "text-decoration: line-through;" : "")};
`;

const FixedContainer = styled.div`
  display: none;
  position: fixed;
  top: 0;

  overflow-x: hidden;
  z-index: 10;

  max-width: inherit;
  ${props => (props.maxWidth ? "max-width: " + props.maxWidth : "")};
  ${props => (props.stickyTable ? "display: block;" : "")};
  ${props => (props.rightBorder ? "border-right:1px dotted #000;" : "")};
`;

const HeaderColumnsContainer = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  background-color: #336699;
  width: ${props => props.containerWidth + "px;"};

  height: ${props => (props.height ? props.height + "px;" : "38px;")};
`;

const THDiv = styled.div`
  box-sizing: border-box;
  font-weight: 600;

  width: 100%;
  ${props =>
    props.center !== undefined ? "text-align: center;" : "text-align:left;"};
  ${props =>
    props.maxWidth !== undefined
      ? "max-width: " + props.maxWidth + "px;"
      : ""} ${props =>
    props.minWidth !== undefined
      ? "min-width: " + props.minWidth + "px;"
      : ""}
    padding-left: 12px;
  padding-right: 8px;
  color: white;
  ${props => (props.pointer ? "cursor: pointer" : "")};
  ${props =>
    props.ascending
      ? `
    &:after {
    content: '\\25B2';
    font-size: 10px;
    padding-left: 5px;
    padding-bottom: 5px;
    }`
      : ""} ${props =>
    props.descending
      ? `
    &:after {
    content: '\\25BC';
    font-size: 10px;
    padding-left: 5px;
    padding-bottom: 5px;
    }`
      : ""};
`;

class HContainer extends React.Component {
  render() {
    const props = this.props;

    return (
      <FixedContainer ref={x => (this.container = x)} {...props}>
        <HeaderColumnsContainer {...props}>
          {props.children}
        </HeaderColumnsContainer>
      </FixedContainer>
    );
  }
}

/**
 * A presentational component that outputs a table element with sticky header
 * @param {*Object} props.
 */
export class _Table extends React.Component {
  constructor(props) {
    super(props);
    this.thRefs = new Array(this.props.tableHeaderLabels.length);
    this.sticky =
      this.props.stickyOption != null ? this.props.stickyOption : true;
    this.state = {
      stickyTable: false,
      tableWidth: undefined,
      thWidths: new Array(this.props.tableHeaderLabels.length),
      thHeight: undefined
    };

    this.handleScrollEvent = this.handleScrollEvent.bind(this);
  }

  handleScrollEvent() {
    if (!this.table) return;

    const tableBox = this.table.getBoundingClientRect();
    const tableTopOffset = tableBox.top;
    const topOffset = 0;
    const tableIsAtTopOfWindow = () => tableTopOffset <= topOffset;

    if (tableIsAtTopOfWindow() && this.state.stickyTable === false) {
      this.setState({
        stickyTable: true,
        tableWidth: this.table.getBoundingClientRect().width,
        thWidths: this.thRefs.map(th => th.getBoundingClientRect().width),
        thHeight: !this.props.noHeader ? this.thHeaderRef.getBoundingClientRect().height : undefined
      });
    } else if (!tableIsAtTopOfWindow() && this.state.stickyTable === true) {
      this.setState({
        stickyTable: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScrollEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScrollEvent);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState !== this.state || nextProps.forceUpdate;
  }

  render() {
    const { t } = this.props;

    const headerProps = this.props.tableHeaderProps || [];
    const arrTH = this.props.tableHeaderLabels.map((label, index) => (
      <TH
        scope="col"
        {...headerProps[index]}
        ref={x => (this.thRefs[index] = x)}
        key={label + index}
      >
        {label}
      </TH>
    ));

    const arrTHDiv = this.props.tableHeaderLabels.map((label, index) => (
      <THDiv
        {...headerProps[index]}
        maxWidth={this.state.thWidths[index]}
        key={label + index}
      >
        {label}
      </THDiv>
    ));

    const stickyColumnsWidth = this.state.thWidths
      .filter((w, i) => i < this.props.numStickyColumns)
      .reduce((val, acc) => acc + val, -1);

    const maxHeaderWidth = this.props.stickyColumns
      ? stickyColumnsWidth + "px"
      : this.props.stickyScrollerTable
        ? this.props.maxHeaderWidth + "px"
        : undefined;

    return this.props.tableRows != null && this.props.tableRows.length > 0
      ? [
          <HContainer
            key={"header"}
            stickyTable={this.state.stickyTable && this.sticky}
            aria-hidden="true"
            height={this.state.thHeight}
            containerWidth={this.state.tableWidth}
            rightBorder={this.props.stickyColumns}
            stickyColumnsTable={this.props.stickyColumnsTable}
            maxWidth={maxHeaderWidth}
            ref={x => (this.containerScroll = x ? x.container : undefined)}
          >
            {arrTHDiv}
          </HContainer>,
          <StyledTable
            {...this.props}
            key={"table"}
            ref={x => (this.table = x)}
          >
            {!this.props.noHeader &&
                <TableHeader ref={x => (this.thHeaderRef = x)}>
                  <tr>{arrTH}</tr>
                </TableHeader>
            }
            <tbody>{this.props.tableRows}</tbody>
          </StyledTable>
        ]
      : "";
  }
}

const TableScrollerContainer = styled.div`
  position: relative;
  display: block;
  overflow-x: hidden;
  max-width: inherit;
`;
const TableScroller = styled.div`
  width: 100%;
  overflow: auto;
`;
class Table extends React.Component {
  scrollHandler = event => {
    this.tableRef.scrollLeft = this.tableScroller.scrollLeft;
  };

  componentDidMount() {
    if (this.props.stickyColumns) {
      this.tableScroller.addEventListener("scroll", this.scrollHandler);
    }
  }
  componentWillUnmount() {
    if (this.props.stickyColumns) {
      this.tableScroller.removeEventListener("scroll", this.scrollHandler);
    }
  }

  render() {
    const {
      stickyColumns,
      numberOfStickyColumns,
      tableHeaderLabels,
      tableRows,
      data
    } = this.props;

    var tableData = undefined;
    tableData = tableRows === undefined ? data : tableRows;

    if (stickyColumns) {
      const headerProps = this.props.tableHeaderProps || [];
      const arrStickyTHProps = this.props.tableHeaderLabels.map(
        (label, index) => ({
          scope: "col",
          ...headerProps[index],
          key: label + index,
          sticky: index < numberOfStickyColumns,
          rightBorder: index === numberOfStickyColumns - 1,
          center: true
        })
      );

      return (
        <TableScrollerContainer>
          <TableScroller ref={x => (this.tableScroller = x)}>
            <_Table
              stickyOption={this.props.stickyOption}
              forceUpdate={this.props.forceUpdate}
              stickyScrollerTable
              maxHeaderWidth={this.props.contentWidth}
              tableRows={tableData}
              tableHeaderLabels={tableHeaderLabels}
              tableHeaderProps={arrStickyTHProps}
              spaceUnderEachRow
              ref={x => (this.tableRef = x ? x.containerScroll : undefined)}
            />

            <_Table
              stickyOption={this.props.stickyOption}
              forceUpdate={this.props.forceUpdate}
              stickyColumns
              numStickyColumns={numberOfStickyColumns}
              tableRows={tableData}
              tableHeaderLabels={tableHeaderLabels}
              tableHeaderProps={arrStickyTHProps}
              spaceUnderEachRow
            />
          </TableScroller>
        </TableScrollerContainer>
      );
    }

    return <_Table {...this.props} tableRows={tableData} />;
  }
}

Table.propTypes = {
  tableRows: PropTypes.array,
  data: PropTypes.array, // same as tableRows, but exist to support paginated table
  tableHeaderLabels: PropTypes.array,
  tableHeaderProps: PropTypes.array,
  stickyOption: PropTypes.bool,
  forceUpdate: PropTypes.bool
};

Table.defaultProps = {
  forceUpdate: true,
  stickyOption: true,
  contentWidth: APP_WIDTH
};

export default Table;
