import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/reducers";
import { unregister } from "./registerServiceWorker";
import Root from "./Root";
import { createGlobalStyle } from "styled-components";
const GlobalStyle = createGlobalStyle`

html {
  height: 100%;
}

  body {
    height: 100%;
    margin: 0;
    font-family: 'Open sans', sans-serif;
    font-size: 16px;
    overflow-y:scroll;
    *::-ms-clear {
      display: none;
  }

#root {
    min-height: 100%;
    position:relative;
    box-sizing: border-box;
}`;
createLogger();
let store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

ReactDOM.render(
  <>
    <Root store={store} />
    <GlobalStyle />
  </>,
  document.getElementById("root")
);
unregister();
