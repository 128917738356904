import { emailRequirementValidator } from "../util/emailValidator";
import { getResponsiblePersonsFromValues } from "./ResponsiblePersonUtils";

const hasValue = value => {
  return value != null && value.length > 0;
};

const invalidPhone = phone => {
  return !/^\+[0-9]+|[0-9]+/i.test(phone);
};


export const validateFields = (values, t) => {
  let errors = {};

  const validateFieldLength = (field, maximumCharacters) => {
    if (values[field] !== undefined && values[field].length > maximumCharacters) {
      errors[field] = t("field_length_exceeded", {length: maximumCharacters});
    }
  }
  const validateRequiredField = (field, maximumCharacters) => {
    if (values[field] == null ||
            values[field].length === 0 ||
            values[field].value === null) {
      errors[field] = t("required_field");
    } else if (maximumCharacters !== undefined){
      validateFieldLength(field, maximumCharacters)
    }
  };

  if (values.unknownIssuer && values.unknownIssuer === true) {
    validateRequiredField("issuer");
  } else {
    validateRequiredField("issuerId");
  }
  validateRequiredField("fromDate");
  validateRequiredField("responsible_time");
  if (
    !hasValue(values.newsMessageReference) &&
    !hasValue(values.newsMessageReferenceNo)
  ) {
    errors.newsMessageReference = t("required_en_or_no_reference");
  }

  validateFieldLength("newsMessageReference", 250)
  validateFieldLength("newsMessageReferenceNo", 250)

  validateRequiredField("responsibleFirstName", 50);
  validateRequiredField("responsibleLastName", 50);
  validateRequiredField("responsiblePosition", 50);
  if (hasValue(values.responsibleEmail)) {
    if (emailRequirementValidator(values.responsibleEmail)) {
      errors.responsibleEmail = t("invalid_email");
    } else {
      validateFieldLength("responsibleEmail", 50)
    }
  } else {
    errors.responsibleEmail = t("required_field");
  }
  if (hasValue(values.responsiblePhoneNumber)) {
    if (invalidPhone(values.responsiblePhoneNumber)) {
      errors.responsiblePhoneNumber = t("invalid_phonenumber");
    } else {
      validateFieldLength("responsiblePhoneNumber", 50)
    }
  } else {
    errors.responsiblePhoneNumber = t("required_field");
  }

  let responsiblePersonArray = getResponsiblePersonsFromValues(values);

  if (responsiblePersonArray.length > 0) {
    responsiblePersonArray.forEach((person, index) => {
      validateRequiredField("responsible_firstName_" + index, 50);
      validateRequiredField("responsible_lastName_" + index, 50);
      validateRequiredField("responsible_title_" + index, 50);

      if (hasValue(person.email)) {
        if (emailRequirementValidator(person.email)) {
          errors["responsible_email_" + index] = t("invalid_email");
        }
        else {
          validateFieldLength("responsible_email_" + index, 50)
        }
      } else {
        errors["responsible_email_" + index] = t("required_field");
      }

      if (hasValue(person.phone)) {
        if (invalidPhone(person.phone)) {
          errors["responsible_phone_" + index] = t("invalid_phonenumber");
        } else {
          validateFieldLength("responsible_phone_" + index, 50)
        }
      } else {
        errors["responsible_phone_" + index] = t("required_field");
      }
    });
  } else if (responsiblePersonArray.length < 1) {
    errors["missingResponsiblePersonsError"] = t(
      "required_at_least_one_responsible_person"
    );
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};
