import { doRequest } from "../obsvcClient";
import { fetchData } from "../actions/CommonActions";
import { postData } from "../actions/CommonActionsNoObsvc";

export const RESET_RESET_PASSWORD = "RESET_RESET_PASSWORD";
export function resetResetPassword() {
  return { type: RESET_RESET_PASSWORD };
}

export const REQUEST_RESET_PASSWORD = "REQUEST_RESET_PASSWORD";
export function requestResetPassword() {
  return { type: REQUEST_RESET_PASSWORD };
}

export const RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD";
export function receiveResetPassword(data) {
  return { type: RECEIVE_RESET_PASSWORD, data: data };
}

export const FAILED_RESET_PASSWORD = "FAILED_RESET_PASSWORD";
export function failedResetPassword(error) {
  return { type: FAILED_RESET_PASSWORD, error: error };
}

export function resetPassword({ globalSubject, resetSecret, password }) {
  return (dispatch) => {
    dispatch(requestResetPassword());

    return doRequest("auth/resetPasswordWithSecret.obsvc", {
      globalSubject,
      resetSecret,
      newCredentials: { password: password },
    }).then(
      (data) => {
        dispatch(receiveResetPassword(data));
      },
      (error) => {
        dispatch(failedResetPassword(error));
      }
    );
  };
}

export const ForgotPasswordLink = {
  request: "REQUEST_LINK",
  postSuccess: "RECEIVE_LINK",
  postFail: "RECEIVE_LINK_FAILED",
};

export const sendForgotPasswordLink = (email, username) =>
  postData(
    ForgotPasswordLink,
    undefined,
    { email, username },
    "v1/authn/twoFactor/forgotPassword"
  );

export const GetAuthenticateParams = {
  request: "REQUEST_AUTHENTICATE_GET",
  postSuccess: "RECEIVE_AUTHENTICATE_GET",
  postFail: "RECEIVE_AUTHENTICATE_GET_FAILED",
};

export const fetchAuthParams = (globalSubject) =>
  postData(
    GetAuthenticateParams,
    undefined,
    { globalSubject },
    "v1/authn/twoFactor/parameters"
  );

export const REQUEST_RESET_SECRET = "REQUEST_RESET_SECRET";
export function requestResetSecret() {
  return { type: REQUEST_RESET_SECRET };
}

export const RECEIVE_RESET_SECRET = "RECEIVE_RESET_SECRET";
export function receiveResetSecret(data) {
  return { type: RECEIVE_RESET_SECRET, data: data };
}

export const FAILED_RESET_SECRET = "FAILED_RESET_SECRET";
export function failedResetSecret(error) {
  return { type: FAILED_RESET_SECRET, error: error };
}
