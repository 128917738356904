import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import Input from "../components/Input";
import Label from "../components/Label";
import { ErrorText } from "../components/ErrorText";

import TimePicker from "../components/TimePicker";
import Textarea from "../components/Textarea";
import SearchableSelect from "../components/SearchableSelect";
import DayPicker from "../components/DayPicker";
import styled, { css } from "styled-components";
import { OBNTInsiderToolDateFormat } from "../util/common";
import { Tooltip } from "../components/Tooltip";
import i18n from "../i18n";
import { HelpIcon } from "../components/HelpIcon";

export const TooltipWrapper = styled.div`
  position: relative;
  ${props => (props.helpCursor ? "cursor:help;" : "")};
  &:hover ${Tooltip} {
    visibility: visible;
    transition-delay: 0.8s;
  }
`;

const LabelBox = props => {
  const { error } = props.meta ? props.meta : {};
  const margin = props.margin ? { margin: props.margin } : {};
  const labelProps = {
    bold: !!props.bold,
    noWrap: !!props.noWrap,
    fontSize: props.fontSize ? props.fontSize : undefined
  };

  return (
    <FlexContainer
      fitToContent={!!props.showError}
      column
      margin={[0, 8, 0, 0]}
      {...margin}
      minWidth={props.minWidth}
      grey={props.grey}
    >
      {props.noLabel ? (
        props.showError && error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined
      ) : (
        <TooltipWrapper helpCursor={props.tooltip}>
          <FlexContainer>
            <Label
              maxWidth
              htmlFor={props.name}
              margin={[8, 0, 3, 0]}
              {...labelProps}
              tooltip={props.tooltip}
            >
              {props.label}
              {props.helpTextFile ? (
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={props.helpTextFile}
                  language={i18n.language}
                  insiderToolHelp={props.incomingPrefix === undefined}
                  incomingPrefix={props.incomingPrefix}
                  height={"16"}
                  width={"16"}
                />
              ) : (
                undefined
              )}
              {error ? (
                <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
              ) : (
                undefined
              )}
            </Label>
            {props.extraLabel ? (
              <Label
                maxWidth
                htmlFor={props.name}
                margin={[-3, 0, 3, 0]}
                fontWeight={400}
              >
                {props.extraLabel}:
              </Label>
            ) : undefined}
          </FlexContainer>
          {props.tooltip ? <Tooltip>{props.tooltip}</Tooltip> : undefined}
        </TooltipWrapper>
      )}
      {props.component}
    </FlexContainer>
  );
};

const Text = styled.span`
  font-size: ${props => (props.fontSize ? props.fontSize : "14px")};
  ${props =>
    props.textMargin
      ? "margin: " +
        props.textMargin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;
export const TextBox = props => {
  const { textMargin, ...restProps } = props;
  return (
    <LabelBox
      {...restProps}
      component={
        <Text {...restProps} textMargin={textMargin}>
          {props.input.value}
        </Text>
      }
    />
  );
};

export const NoLabelTextBox = props => <Text>{props.input.value}</Text>;

export const InputBox = props => {
  const { margin, ...restProps } = props;
  return <LabelBox {...props} component={<Input {...restProps} border />} />;
};

export const NoLabelInputBox = props => <Input {...props} border />;

export const DropDownBox = props => (
  <LabelBox {...props} bold component={<SearchableSelect {...props} />} />
);

export const DateBox = props => (
  <LabelBox
    {...props}
    component={
      <DayPicker
        numFutureYears={1}
        {...props}
        dateFormat={OBNTInsiderToolDateFormat}
      />
    }
  />
);

export const TimePickerBox = props => (
  <LabelBox {...props} component={<TimePicker {...props} />} />
);

export const TextAreaBox = props => {
  const { margin, ...restProps } = props;
  return <LabelBox {...props} component={<Textarea {...restProps} />} />;
};

export const NoLabelTextAreaBox = props => <Textarea {...props} border />;

export const Header = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  ${props => (props.grey ? "opacity: 0.5; " : "")};
  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
  ${props => (props.color ? "color: " + props.color + ";" : "")};
  ${props => (props.fontSize ? "font-size: " + props.fontSize + "px;" : "")};
`;

export const Icon = styled.img`
  cursor: pointer;
  ${props => (props.isHidden ? "visibility: hidden" : "")};
  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
  ${props =>
    props.inactive
      ? css`
          opacity: 0.5;
          cursor: auto;
        `
      : ""};
`;

export const MenuItem = styled.div`
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: rgba(178, 207, 174, 0.2);
  }
  //padding: 0px 7px 0px 7px;
  padding: 8px;
  //margin-top: 10px;
`;

const Item = styled.a`
  width: 100%;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  margin-right: 8px;

  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  font-size: 14px;
`;
export const Attachment = ({
  showDelete,
  onClickDelete,
  onClick,
  children
}) => (
  <FlexContainer row>
    {showDelete ? (
      <Icon
        margin={[0, 10, 0, 0]}
        pointer
        src="/icons/red-cross-24.svg"
        alt={"DELETE"}
        onClick={onClickDelete}
      />
    ) : (
      ""
    )}

    <Item onClick={onClick}>{children}</Item>
  </FlexContainer>
);
