import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "./FlexContainer";

import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";
import { showFileFromServer } from "../util/common";

export const FileUploadObjectType = {
  LOCAL: "local",
  SERVER: "server",
  DELETED_SERVER_FILE: "server_deleted",
};

export const FileButton = styled.input.attrs(() => ({
  type: "file",
}))`
  display: none;
`;
export const WarningText = styled.div`
  white-space: pre-wrap;
`;

const AttachIcon = styled.img`
  cursor: pointer;
  padding: 0px 10px 0 0;
`;

const FileContainer = styled(FlexContainer)`
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
`;

const File = styled.a`
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
`;
const Icon = styled.img`
  cursor: pointer;
  padding: 3px 3px 1px 1px;
`;

const RemovableFile = (props) => (
  <FlexContainer row vAlignCenter>
    <Icon
      onClick={props.onRemoveClick}
      src="/icons/red-cross-24.svg"
      width="13px"
      height="13px"
      alt={props.t("delete_attachment")}
      title={props.t("delete_attachment")}
    />
    <File
      onClick={props.onLinkClick}
      href={props.href}
      download={props.download}
    >
      {props.children}
    </File>
  </FlexContainer>
);

const maximumFilenameLength = 183;

export const fileValidator = (
  uploadedFiles,
  file,
  fileType,
  maxFileSize,
  validExtensions,
  fileValidation
) => {
  if (
    uploadedFiles.some(
      (file2) =>
        file2.fileName === file.name &&
        file2.type !== FileUploadObjectType.DELETED_SERVER_FILE
    )
  )
    return false;

  if (fileValidation !== "false" && fileValidation !== false) {
    const fileNameExtension = file.name.substring(
        file.name.lastIndexOf(".") + 1
    );
    if (
        validExtensions.length > 0 &&
        !validExtensions.includes(fileNameExtension.toLowerCase())
    ) {
      return false;

    }
    if (file.name.length > maximumFilenameLength) {
      return false;
    }
  }
  return file.size <= maxFileSize;
};

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
    };
    this.addFiles = this.addFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
  }

  componentDidMount() {
    // Set the onchange method of the input button to our custom addfiles method
    this.uploadInput.onchange = (e) => this.addFiles(e);

    // If files are already present on server or from redux-store
    if (this.props.input != null && this.props.input.value.map != null) {
      const files = this.props.input.value.map((obj) => {
        return {
          fileId: obj.fileId || -1,
          fileName: obj.fileName,
          file: obj.file,
          type: obj.type || FileUploadObjectType.SERVER,
        };
      });

      this.setState({ files: files });
      this.props.input.onChange(files);
    }
  }

  addFiles = (e) => {
    e.preventDefault();
    if (this.uploadInput.files.length > 0) {
      var files =
        this.uploadInput.files.length > 1
          ? Array.from(this.uploadInput.files)
          : Array(this.uploadInput.files[0]);

      var nFiles =
        this.state.files.filter(
          (file) => file.type !== FileUploadObjectType.DELETED_SERVER_FILE
        ).length || 0;

      const newFileObjects = files.filter(
        (file, index) =>
          fileValidator(
            this.state.files,
            file,
            this.props.fileType,
            this.props.maxFileSize,
            this.props.validExtensions,
              this.props.fileValidation
          ) && nFiles + (index + 1) <= this.props.maxNumFiles
      );
      const rejectedFiles = files.filter(
        (file, index) =>
          !fileValidator(
            this.state.files,
            file,
            this.props.fileType,
            this.props.maxFileSize,
            this.props.validExtensions,
              this.props.fileValidation
          ) || nFiles + (index + 1) > this.props.maxNumFiles
      );

      if (rejectedFiles.length > 0) {
        var errorText = "";
        rejectedFiles.forEach(
          (file) => (errorText = errorText.concat(file.name + "\n"))
        );

        NotificationManager.warning(
          <WarningText>
            {this.props.t("rejected_attachments", {
              fileSize: this.props.maxFileSize / 1000000,
              numFiles: this.props.maxNumFiles,
              length: maximumFilenameLength,
            })}
          </WarningText>,
          "",
          10000
        );
        NotificationManager.warning(
          <WarningText>{errorText}</WarningText>,
          "",
          10000
        );
      }

      var newFiles = newFileObjects.map((file) => {
        return {
          fileId: -1,
          fileName: file.name,
          file,
          type: FileUploadObjectType.LOCAL,
        };
      });

      if (this.state.files.length > 0) {
        newFiles.push(...this.state.files);
      }

      this.setState({
        files: newFiles,
      });

      this.props.input.onChange(newFiles);
      e.target.value = "";
    }
  };

  removeFile = (rmFileObj) => {
    const updatedFiles = [];
    this.state.files.forEach((fileObj) => {
      switch (fileObj.type) {
        case FileUploadObjectType.SERVER:
          fileObj.fileId === rmFileObj.fileId
            ? updatedFiles.push({
                ...fileObj,
                type: FileUploadObjectType.DELETED_SERVER_FILE,
              })
            : updatedFiles.push({ ...fileObj });
          break;
        case FileUploadObjectType.LOCAL:
          // Don't add the file to the new state if the file equals the file the user tries to remove.
          if (fileObj.fileName !== rmFileObj.fileName) {
            updatedFiles.push({ ...fileObj });
          }
          break;
        default:
          updatedFiles.push({ ...fileObj });
      }
    });

    this.setState({ files: updatedFiles });
    this.props.input.onChange(updatedFiles);
    this.forceUpdate();
  };

  render() {
    const { t } = this.props;
    return (
      <FlexContainer row spaceBetween padding={[16, 8, 16, 8]}>
        <FileButton
          multiple
          accept={"application/" + this.props.fileType}
          ref={(ref) => {
            this.uploadInput = ref;
          }}
        />
        <div>
          <AttachIcon
            src="/icons/attach-green.svg"
            alt={t("add_attachment")}
            title={t("add_attachment")}
            onClick={() => this.uploadInput.click()}
          />
          {t("accepted_file_types")}
        </div>

        <FileContainer width="auto">
          {this.state.files.length > 0
            ? this.state.files
                .filter(
                  (fileObj) =>
                    fileObj.type !== FileUploadObjectType.DELETED_SERVER_FILE
                )
                .map((fileObj, index) => {
                  return (
                    <RemovableFile
                      key={fileObj.fileName + "_" + index}
                      t={t}
                      href="download"
                      onLinkClick={(e) => {
                        e.preventDefault();
                        if (
                          fileObj.type !== FileUploadObjectType.LOCAL &&
                          fileObj.type === FileUploadObjectType.SERVER
                        ) {
                          this.props.getServerFile(fileObj.fileId);
                        } else {
                          showFileFromServer(fileObj.file, fileObj.fileName);
                        }
                      }}
                      onRemoveClick={() => this.removeFile(fileObj)}
                      download={fileObj.fileName}
                    >
                      {fileObj.fileName}
                    </RemovableFile>
                  );
                })
            : this.props.noFilesSelectedLabel}
        </FileContainer>
      </FlexContainer>
    );
  }
}
FileUpload.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      fileId: PropTypes.number,
      fileName: PropTypes.string,
    })
  ),
};

export default withTranslation("translations")(FileUpload);
