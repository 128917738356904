import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader, { OverlayLoader } from "../components/Loader";
import { PageHeading } from "../components/PageHeading";
import { Route } from "react-router";
import EmailDistributionListContainer from "./EmailDistributionListContainer";
import EmailDristributionListDetailsContainer from "./EmailDristributionListDetailsContainer";
import { fetchOrganisation } from "../admin/AdminActions";
import { fetchCompany } from "../companyInformation/CompanyInformationActions";
import { isSuperUser } from "../util/common";
import EmailDistributionStatusContainer from "./EmailDistributionStatusContainer";

class EmailDistributionContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      test: true,
      userHasAccessToCompanyWithNewsAgreement: false,
      loading: true,
    };
  }

  componentWillMount() {
    const loggedInUser = this.props.userLoggedIn;
    if (isSuperUser(this.props.userLoggedIn)) {
      this.setState({
        userHasAccessToCompanyWithNewsAgreement: true,
        loading: false,
      });
    } else {
      this.props
        .dispatch(fetchOrganisation(loggedInUser.organisationId))
        .then((res) => {
          const companies = res.data.entity.companies;
          if (companies && companies.length > 0) {
            for (let i = 0; i < companies.length; i++) {
              const companyId = companies[i];
              this.props
                .dispatch(fetchCompany(companyId))
                .then((res) => {
                  if (
                    res &&
                    res.data &&
                    res.data.entity &&
                    res.data.entity.newsAgreement !== undefined &&
                    res.data.entity.newsAgreement !== null &&
                    res.data.entity.newsAgreement >= 0
                  ) {
                    this.setState({
                      userHasAccessToCompanyWithNewsAgreement: true,
                    });
                  }
                  if (i === companies.length - 1) {
                    this.setState({ loading: false });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.setState({ loading: false });
                });
            }
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ loading: false });
        });
    }
  }

  render() {
    const { t, base } = this.props;
    const LIST = `${base}`;
    const STATUS = `${base}/status`;

    if (this.state.loading) {
      return <Loader />;
    }

    if (!this.state.userHasAccessToCompanyWithNewsAgreement) {
      return (
        <FlexContainer center vAlignCenter margin={[200, 0, 0, 0]}>
          <PageHeading>{t("edist_no_customer_text")}</PageHeading>
        </FlexContainer>
      );
    }

    return (
      <FlexContainer column margin={[0, 0, 20, 0]}>
        {isSuperUser(this.props.userLoggedIn) ? (
          <FlexContainer center row>
            <PageHeading
              fontSize={20}
              isFocusable
              onClick={() => {
                this.props.history.push(LIST);
              }}
              opacity={this.props.location.pathname !== STATUS ? "1.0" : "0.5"}
              underline={this.props.location.pathname !== STATUS}
              pointer={this.props.location.pathname === STATUS}
              margin={[0, 25, 25, 0]}
              color={"#1c315b"}
            >
              {t("edist_email_distribution_lists")}
            </PageHeading>

            <PageHeading
              fontSize={20}
              isFocusable
              opacity={this.props.location.pathname === STATUS ? "1.0" : "0.5"}
              underline={this.props.location.pathname === STATUS}
              pointer={this.props.location.pathname !== STATUS}
              onClick={() => this.props.history.push(STATUS)}
              margin={[0, 25, 25, 0]}
              color={"#1c315b"}
            >
              {t("edist_email_distribution_status")}
            </PageHeading>
          </FlexContainer>
        ) : (
          ""
        )}

        {this.state.saving ? <OverlayLoader /> : undefined}

        <FlexContainer>
          <Route
            exact
            path={`${base}`}
            render={() => {
              return <EmailDistributionListContainer {...this.props} />;
            }}
          />
        </FlexContainer>
        <FlexContainer>
          <Route
            exact
            path={`${base}/list/:page`}
            render={() => {
              return <EmailDristributionListDetailsContainer {...this.props} />;
            }}
          />
        </FlexContainer>
        {isSuperUser(this.props.userLoggedIn) ? (
          <FlexContainer>
            <Route
              exact
              path={STATUS}
              render={() => {
                return <EmailDistributionStatusContainer {...this.props} />;
              }}
            />
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { config, loginUser } = state;

  return {
    config: config.config,
    userLoggedIn: loginUser.user,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(EmailDistributionContainer)
);
