import styled, { css } from "styled-components";
import { media } from "../util/common";

export const Tooltip = styled.span`
  position: absolute;
  background: #ffffff;
  padding: 8px;

  white-space: pre-wrap;
  width: ${(props) => (props.width ? props.width + ";" : "240px;")};
  bottom: 130%;
  left: 50%;
  margin-left: -120px; /* Use half of the width to center the tooltip */

  ${(props) =>
    props.left
      ? css`
          bottom: auto;
          left: auto;
          margin-left: 0px;
          right: 105%;
          top: -5px;
        `
      : ""} z-index: 100;
  visibility: hidden;

  &:after {
    content: " ";
    position: absolute;

    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;

    ${(props) =>
      props.left
        ? css`
            top: 50%;
            left: 100%;
            margin-left: 0px;
            margin-top: -5px;
          `
        : ""} border-width: 5px;
    border-style: solid;
    border-color: white transparent transparent transparent;
    ${(props) =>
      props.left
        ? "border-color: transparent transparent transparent white;"
        : ""};
  }
  box-shadow: 0 0px 15px rgba(0, 0, 0, 0.15);
  ${media.phone`
    display: none;
   `};
`;
