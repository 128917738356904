import React from "react";
import styled, { css } from "styled-components";
import FocusElement from "./FocusElement";
import { FlexContainer } from "./FlexContainer";
import { browserIsIE } from "../util/common";

const commonStyles = (props) => {
  const backgroundColor = props.inverted ? "#3e6a4c;" : "#477857;";

  return css`
    /*select -> user cannot highlight text*/
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
      
    display:  ${browserIsIE() ? "table" : "inline-flex"};
    box-sizing : border-box;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    width: ${(props) => (props.width ? props.width + "px;" : "fit-content;")}
    height: 35px;
    color: ${(props) => (props.loading ? "#477857;" : "white;")}
    border: 0;
    font-size: inherit;
    font-family: inherit;
    border-radius: 3px;
    cursor: pointer;
    text-transform: uppercase;
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor + ";" : "#477857;")}
      
    padding: 8px 20px 8px 20px;
    &:hover{background-color: ${(props) =>
      props.loading ? "#477857};" : "#3E6A4C;}"}
     
    font-weight: 600;

    ${(props) =>
      props.margin
        ? "margin: " +
          props.margin.reduce((acc, val) => (acc += val + "px "), "") +
          ";"
        : ""}
    ${(props) =>
      props.padding
        ? "padding: " +
          props.padding.reduce((acc, val) => (acc += val + "px "), "") +
          ";"
        : ""}
    ${(props) => (props.width ? "width: " + props.width + "px;" : "")}
    ${(props) => (props.notRounded ? "border-radius: 0;" : "")}
    ${(props) =>
      props.inactive
        ? css`
            opacity: 0.5;
            &:hover {
              background-color: ${backgroundColor};
            }
            cursor: default;
          `
        : ""}

    ${(props) =>
      props.inverted
        ? css`
        background: white;
        color: ${(props) => (props.loading ? "white;" : "#477857;")}
        border: 1px solid #477857;
        &:hover {
          color: white; 
          background-color: ${backgroundColor}
        }

        &:hover {
          color: white; 
          background-color: ${(props) =>
            props.loading ? "white};" : "#3E6A4C;}"}
        `
        : ""}

      ${(props) => (props.helpCursor ? "cursor: help" : "")};
      ${(props) => (props.hidden ? "display: none" : "")};
    `;
};

const MenuButton = styled.span`
  ${(props) => commonStyles};
  display: inline-flex;
  height: 50px;
  width: 100%;
  border-radius: 0px;
  ${(props) => (props.breakLine ? "white-space: pre-line;" : "")};
`;

const Spinner = (props) => {
  return (
    <img
      src="/icons/loadingspinner.gif"
      width="25px"
      height="25px"
      alt="loading"
      style={{
        position: "absolute",
      }}
    />
  );
};

const Menu = (props) => {
  const { onClick, ...rest } = props;
  return (
    <MenuButton
      tabIndex="0"
      onClick={onClick}
      onKeyDown={(e) => {
        switch (e.keyCode) {
          case 13:
            onClick(e);
            break;
          default:
            break;
        }
      }}
      {...rest}
    >
      {props.children}
    </MenuButton>
  );
};

const StandardSpan = styled.span`
  ${(props) => commonStyles};
`;

const StandardButton = styled.button`
  ${(props) => commonStyles};
`;

const Standard = (props) => {
  const { onClick, ...rest } = props;
  return (
    <FocusElement onClick={props.loading ? undefined : onClick}>
      <FlexContainer>
        <StandardSpan {...rest}>
          {props.children}
          {props.loading ? <Spinner /> : ""}
        </StandardSpan>
      </FlexContainer>
    </FocusElement>
  );
};
const Form = (props) => {
  return <StandardButton {...props}>{props.children}</StandardButton>;
};

const AddButton = styled(StandardSpan)`
  padding: 8px 8px 8px 8px;
  height: 27px;
`;
const Add = (props) => {
  const { onClick, ...rest } = props;

  return (
    <FocusElement onClick={onClick}>
      <AddButton {...rest}>+</AddButton>
    </FocusElement>
  );
};

const Text = styled.span`
  /*select -> user cannot highlight text*/
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;

  ${(props) =>
    props.inactive
      ? css`
          opacity: 0.5;

          cursor: default;
        `
      : ""}

  ${(props) => (props.black ? "color: black;" : "")} 
  ${(props) =>
    !props.inactive
      ? css`
          &:hover {
            ${(props) => (props.black ? "color: white;" : "color: #df4747;")};
          }
        `
      : ""}
  &:hover {
      ${(props) => (props.success ? "color: #477857;" : "")}
  }
      ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
`;

const Button = {
  Standard,
  Menu,
  Form,
  Text,
  Add,
};

export default Button;
