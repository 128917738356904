import {
  deleteData,
  fetchData,
  postData,
  putData
} from "../../actions/CommonActionsNoObsvc";

export const INSIDER_SYNC_DETAILS = "v1/insidersyncdetails/";

export const ListInsiderSyncAction = {
  request: "REQUEST_INSIDER_LOG_DETAILS",
  receive: "RECEIVE_INSIDER_LOG_DETAILS_SUCCESS",
  receiveFail: "RECEIVE_INSIDER_LOG_DETAILS_FAILED"
};

export const fetchInsiderSyncDetails = (id) => {
  return fetchData(
    ListInsiderSyncAction,
    undefined,
      INSIDER_SYNC_DETAILS + "list/" + id
  );
};

export const ListInsiderSyncClients = {
  request: "REQUEST_INSIDER_SYNC_CLIENTS",
  receive: "RECEIVE_INSIDER_SYNC_CLIENTS_SUCCESS",
  receiveFail: "RECEIVE_INSIDER_SYNC_CLIENTS_FAILED"
};

export const fetchInsiderSyncClients = () => {
  return fetchData(
      ListInsiderSyncClients,
      undefined,
      INSIDER_SYNC_DETAILS + "clients"
  );
};

export const GetInsiderLogSyncDetailsByUserAction = {
  request: "REQUEST_INSIDER_LOG_DETAIL_BY_USER",
  receive: "RECEIVE_INSIDER_LOG_DETAIL_BY_USER_SUCCESS",
  receiveFail: "RECEIVE_INSIDER_LOG_DETAIL_BY_USER_FAILED"
};

export const fetchInsiderLogUrlByUser = _ => {
  return fetchData(
    GetInsiderLogSyncDetailsByUserAction,
    undefined,
      INSIDER_SYNC_DETAILS + "findUrlByUser"
  );
};

export const CreateInsiderLogDetials = {
  request: "CREATE_INSIDER_LOG_DETAILS",
  postFail: "CREATE_INSIDER_LOG_DETAILS_FAILED",
  postSuccess: "CREATE_INSIDER_LOG_DETAILS_SUCCESS"
};

export const addInsiderLogDetails = insiderLogDetails =>
  postData(
    CreateInsiderLogDetials,
    undefined,
    insiderLogDetails,
      INSIDER_SYNC_DETAILS + "create"
  );

export const DeleteInsiderLogDetails = {
  request: "DELETE_INSIDER_LOG_DETAILS",
  deleteFail: "DELETE_INSIDER_LOG_DETAILS_FAILED",
  deleteSuccess: "DELETE_INSIDER_LOG_DETAILS_SUCCESS"
};

export const deleteInsiderLogDetails = id =>
  deleteData(DeleteInsiderLogDetails, INSIDER_SYNC_DETAILS + "delete/" + id);

export const UpdateInsiderLogSyncDetails = {
  request: "UPDATE_INSIDER_LOG_SYNC_DETAILS",
  putFail: "UPDATE_INSIDER_LOG_SYNC_DETAILS_FAILED",
  putSuccess: "UPDATE_INSIDER_LOG_SYNC_DETAILS_SUCCESS"
};

export const updateInsiderLogSyncDetails = (insiderSyncDetails, id) =>
  putData(
    UpdateInsiderLogSyncDetails,
    undefined,
    insiderSyncDetails,
      INSIDER_SYNC_DETAILS + "update/" + id
  );
