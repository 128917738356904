import { emailRequirementValidator } from "../util/emailValidator";

export const emailDistributionListFormValidator = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredListFieldsCheck = () => {
    if (values.name == null) {
      errors.name = props.t("required_field");
    }
  };

  requiredListFieldsCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const emailDistributionContactFormValidator = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredListFieldsCheck = () => {
    if (values.email == null) {
      errors.email = props.t("required_field");
    }
  };

  const valueFormatCheck = () => {
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredListFieldsCheck();
  valueFormatCheck();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const emailDistributionSearchFormValidator = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const valueFormatCheck = () => {
    //This regex = Numbers only
    if (
      values.messageId && !/^\d+$/.test(values.messageId) &&
      values.messageId.length > 0
    ) {
      errors.messageId = props.t("edist_digits_only");
    }
  };

  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};
