import React from "react";
import styled from "styled-components";

const DropDownContainer = styled.div`
  display: block;
  ${(props) => (props.containerNoMarginTop ? "" : "margin-top: 10px;")};
  width: 100%;
  background-color: #ffffff;
`;

const DropDownHeaderContainer = styled.div`
  display: block;
  background-color: #336699;
  //justify-self:left; //x-aksen;
  //align-self:start; //y-aksen;
  margin-top: 10px;

  //just for test
  height: 60px;
`;

const DropDownIcon = styled.img`
  cursor: pointer;
  float: right;
  align: center;
  padding-top: 25px;
  padding-right: 25px;
`;

const DropDownHeader = styled.h3`
  cursor: pointer;
  text-align: left;
  color: #ffffff;
  margin: 0px;
  padding-top: 20px;
  padding-left: 16px;

  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const DropDownBody = styled.div`
  ${(props) =>
    props.isOpen ? "" : "display: none; "} background-color: #ffffff;
  ${(props) =>
    props.greenBackground
      ? "background: rgba(178, 207, 174, 0.2);"
      : ""} text-align: left;
  white-space: pre-wrap;
  ${(props) => (props.noBodyPadding ? "" : "padding: 16px")};
  //just for test
  min-height: 186px;
`;

export class WhiteDropDown extends React.Component {
  constructor(props) {
    super(props);
    if (props.onClick) {
      this.onClick = props.onClick;
    } else {
      this.onClick = this.onClick.bind(this);
    }
  }

  onClick() {
    this.setState({ isOpenLocal: !this.state.isOpen });
  }

  render() {
    const { onClick, header, isOpen, children, ...rest } = this.props;

    return (
      <DropDownContainer containerNoMarginTop={this.props.containerNoMarginTop}>
        <DropDownHeaderContainer onClick={onClick}>
          <DropDownIcon
            key={"icon"}
            src={
              this.props.isOpen
                ? "/icons/ic-keyboard-arrow-up-white-24-px.svg"
                : "/icons/ic-keyboard-arrow-down-white-24-px.svg"
            }
          />
          <DropDownHeader>{header}</DropDownHeader>
        </DropDownHeaderContainer>
        <DropDownBody {...rest} isOpen={isOpen}>
          {children}
        </DropDownBody>
      </DropDownContainer>
    );
  }
}
