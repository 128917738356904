import { combineReducers } from "redux";
import { MFAActions, MFACode, User } from "../actions/MFAActions";

const initalState = {
  verificationSuccess: false,
  verificationFailed: false,
};

const mfa = (
  state = {
    ...initalState,
  },
  action
) => {
  switch (action.type) {
    case MFAActions.verifySuccess:
      return Object.assign({}, state, {
        verificationSuccess: true,
      });
    case MFAActions.verifyFail:
      return Object.assign({}, state, {
        verificationFailed: true,
      });
    default:
      return state;
  }
};

const initialUserState = {
  user: {},
  userIsFetched: false,
};

const user = (
  state = {
    ...initialUserState,
  },
  action
) => {
  switch (action.type) {
    case User.postSuccess:
      return Object.assign({}, state, {
        user: action.data.data.entity,
        userIsFetched: true,
      });
    case User.clear:
      return Object.assign({}, state, {
        ...initialUserState,
      });
    default:
      return state;
  }
};

const profileReducer = combineReducers({
  user,
});

export default profileReducer;
