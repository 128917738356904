import {FlexContainer} from "../../components/FlexContainer";
import Button from "../../components/Button";
import {Modal} from "../../components/Modal";
import React from "react";
import {Field, reduxForm} from "redux-form";
import CheckBox from "../../components/CheckBox";
import {InputBox} from "../../insiderTool/components";

class EditInsiderLogUrlModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Modal
        width={"70%"}
        key={"showEditURLModal"}
        header={t("system_edit_insider_sync")}
        isOpen={true}
        onClose={this.props.onClose}
      >
        <FlexContainer column>
          <FlexContainer width={"32%"} >
            <Field component={InputBox} bold disabled name={"client"} label={t("Kunde")} border />
          </FlexContainer>

          <FlexContainer row margin={[20, 15, 0, 0]}>
            <Field component={InputBox} bold disabled name={"issuerName"} label={t("company")} border />
            <FlexContainer margin={[0, 0, 0, 20]}>
            <Field component={InputBox} bold name={"insiderLogURL"} label={t("system_insider_log_link")} border />
            </FlexContainer>


            <FlexContainer margin={[35, 0, 0, 30]}>
              <Field
                component={CheckBox}
                name={"insiderLogSync"}
                border
                label={t("system_allow_insidersync")}
              />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer
            row
            center
            flexEnd
            vAlignCenter
            margin={[35, 0, 16, 0]}
          >
            <Button.Standard
              margin={[0, 15, 0, 0]}
              onClick={this.props.handleSubmit}
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={this.props.onClose}>{t("close")}</Button.Text>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
}

EditInsiderLogUrlModal = reduxForm({
  form: "editInsiderLogUrlModalForm"
})(EditInsiderLogUrlModal);

export default EditInsiderLogUrlModal;
