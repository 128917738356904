import React from "react";
import { FlexContainer } from "./FlexContainer";

const Spinner = props => {
  return (
    <img
      src="/icons/loadingspinner.gif"
      width="50px"
      height="50px"
      alt="loading"
    />
  );
};

function Loader(props) {
  return (
    <FlexContainer
      margin={props.margin ? props.margin : [200, 0, 0, 0]}
      center
      vAlignCenter
    >
      <Spinner />
    </FlexContainer>
  );
}

export function OverlayLoader(props) {
  return (
    <FlexContainer overlay center vAlignCenter>
      <Spinner />
    </FlexContainer>
  );
}

export default Loader;
