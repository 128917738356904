import { postData, fetchData } from "../actions/CommonActionsNoObsvc";

export const AuditlogList = {
  request: "REQUEST_AUDITLOG",
  postSuccess: "RECEIVE_AUDITLOG",
  postFail: "RECEIVE_AUDITLOG_FAILED",
};

export const fetchAuditlog = (searchParams) =>
  postData(
    AuditlogList,
    undefined,
    { ...searchParams },
    "v1/sysadmin/auditlog/list"
  );

export const AuditlogDetails = {
  request: "REQUEST_AUDITLOG_DETAILS",
  receive: "RECEIVE_AUDITLOG_DETAILS",
  receiveFail: "RECEIVE_AUDITLOG_DETAILS_FAILED",
};

export const fetchAuditlogDetails = (id) =>
  fetchData(
      AuditlogDetails,
      { id: id },
      "v1/sysadmin/auditlog/getDetails"
  );

export const AuditlogEvents = {
  request: "REQUEST_AUDITLOG_EVENTS",
  receive: "RECEIVE_AUDITLOG_EVENTS",
  receiveFail: "RECEIVE_AUDITLOG_EVENTS_FAILED",
};

export const fetchAuditlogEvents = () =>
    fetchData(
        AuditlogEvents,
        undefined,
        "v1/sysadmin/auditlog/operations"
    );

