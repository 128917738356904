import React from "react";
import {Modal} from "../components/Modal";
import {FlexContainer} from "../components/FlexContainer";
import {Field, reduxForm} from "redux-form";
import Textarea from "../components/Textarea";
import Label from "../components/Label";
import Button from "../components/Button";
import {InputLabel} from "../components/Input";
import {setUserprofileData} from "./AdminActions";
import {NotificationManager} from "react-notifications";

class NewsfeedManagement extends React.Component {
    constructor(props) {
        super(props);
    }

    onSubmit = (params) => {
        const entityRequest = {
            params: params
        };
        this.props.dispatch(setUserprofileData(this.props.user.globalSubject, (entityRequest)))
            .then(() => this.props.onClose())
            .then(() => NotificationManager.success(this.props.t("success")))
            .catch(() => NotificationManager.error(this.props.t("error")))
    }

    render() {
        return (
            <Modal
                isOpen={this.props.newsfeedManagement}
                onClose={this.props.onClose}
                header={"Newsfeed"}
            >
                <FlexContainer scrollbarsOnOverflow margin={[0, 0, 0, 0]} >
                    <form>
                        <Field
                            name={"NEWSFEED_MAX_LOGINS"}
                            component={InputLabel}
                            label={"Max Logins"}
                            type={"number"}
                            bold border
                        />

                        <FlexContainer backgroundColor={"rgba(199, 211, 227, 0.3)"} margin={[10, 0, 0, 0]}>
                            <FlexContainer row margin={[10, 0, 10, 0]}>
                                <FlexContainer trueCenter>
                                    <Label bold fontSize={16}>Tag filter</Label>
                                    <Field
                                        width={"90%"}
                                        margin={[10, 0, 10, 0]}
                                        name={"NEWSFEED_TAG_FILTER"}
                                        component={Textarea}
                                        label={"Tag Filter"}
                                    />
                                    <Label fontSize={"13"}>Additional filtered messages that should be allowed by this
                                        user.</Label>
                                </FlexContainer>
                                <FlexContainer trueCenter>
                                    <Label bold fontSize={16}>Message filter</Label>
                                    <Field
                                        width={"90%"}
                                        margin={[10, 0, 10, 0]}
                                        name={"NEWSFEED_MSG_FILTER"}
                                        component={Textarea}
                                        label={"Message Filter"}
                                    />
                                    <Label fontSize={"13"}>All message tags allowed for user must be defined here.</Label>
                                </FlexContainer>
                            </FlexContainer>
                        </FlexContainer>

                        <FlexContainer flexEnd vAlignCenter row margin={[50, 0, 0, 0]}>
                            <Button.Standard
                                margin={[0, 16, 0, 0]}
                                onClick={this.props.handleSubmit(this.onSubmit)}
                            >
                                {this.props.t("save")}
                            </Button.Standard>
                            <Button.Text
                                onClick={this.props.onClose}
                            >
                                {this.props.t("cancel")}
                            </Button.Text>
                        </FlexContainer>
                    </form>
                </FlexContainer>
            </Modal>
        );
    }
}

NewsfeedManagement = reduxForm({
    form: "newsfeedManagement",
    enableReinitialize: true,
})(NewsfeedManagement);


export default NewsfeedManagement;