import React from "react";
import { NotificationManager } from "react-notifications";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Modal } from "../components/Modal";
import SearchableSelect from "../components/SearchableSelect";
import CreatableSelect from "../components/CreatableSelect";
import { WhiteDropDown } from "../components/WhiteDropDown";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import Label from "../components/Label";
import Input from "../components/Input";
import Table, { TD, TR } from "../components/Table";
import { ErrorText } from "../components/ErrorText";
import {
  validateEditContactPerson,
  validateNewContactPerson,
} from "./Validators";
import {browserIsIE, hasValue} from "../util/common";
import { ConfirmModal } from "../components/ConfirmModal";

export const SelectBox = (props) => {
  return <SearchableSelect {...props} />;
};

export const CreatableDropDown = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      {error != null ? <ErrorText>{error}</ErrorText> : ""}
      <CreatableSelect
        placeholder={props.placeholder}
        searchable={true}
        {...props}
        onSelect={props.onChangedContact}
        options={props.options}
      />
    </FlexContainer>
  );
};

export const SearchDropDownWlabel = (props) => {
  const { error } = props.meta;
  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
        onSelect={props.getContact}
      />
    </FlexContainer>
  );
};

export const InputFormWLabel = (props) => (
  <FlexContainer column margin={[0, 8, 0, 0]}>
    <Label htmlFor={props.name} maxWidth={185} margin={[8, 8, 3, 0]} {...props}>
      {props.label}:
    </Label>

    <Field
      component={Input}
      name={props.name}
      placeholder={props.placeholder}
      greyPlaceholder
      {...props}
      value={props.value}
    />
  </FlexContainer>
);

const InputBox = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={350} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

class NewContactModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { t } = this.props;

    const handleServerErrors = (error, reject) => {
      if (error.data.details != null) {
        if (error.data.details.some((e) => e.code === 1011)) {
          return reject(
            new SubmissionError({
              email: this.props.t("invalid_email"),
            })
          );
        }
        if (error.data.details.some((e) => e.code === 1024)) {
          return reject(
            new SubmissionError({
              email: this.props.t("contact_person_already_exists"),
            })
          );
        } else {
          error.data.details.forEach((e) =>
            NotificationManager.error(e.message, "", 0)
          );
        }
      } else NotificationManager.error(this.props.t("an_error_occured"), "", 0);
    };

    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validateNewContactPerson(values, this.props);
        if (errors) {
          this.setState({ loading: false });
          reject(new SubmissionError(errors));
        } else {
          Promise.resolve(this.setState({ loading: true }))
            .then(() => this.props.onSubmit())
            .then(() => this.setState({ loading: false }))

            .then(resolve)
            .catch((error) => handleServerErrors(error, reject))
            .then(() => this.setState({ loading: false }));
        }
      });
    };
    return (
      <FlexContainer>
        <form>
          <FlexContainer vAlignCenter>
            <Field
              border
              name="firstName"
              component={InputBox}
              label={this.props.t("first_name") + "*"}
            />
            <Field
              border
              name="lastName"
              component={InputBox}
              label={t("last_name") + "*"}
            />
            <Field
              border
              name="mobile"
              component={InputBox}
              label={t("mobile_number") + "*"}
            />
            <Field
              border
              name="office"
              component={InputBox}
              label={t("direct_number")}
            />
            <Field
              border
              name="email"
              component={InputBox}
              label={t("email") + "*"}
            />
            <Field
              border
              name="title"
              component={InputBox}
              label={t("company_title")}
            />
          </FlexContainer>

          <FlexContainer margin={[24, 0, 0, 0]} row>
            <FlexContainer row>
              <Label bold>{t("required_fields")}</Label>
            </FlexContainer>
            <FlexContainer flexEnd vAlignCenter row>
              <Button.Standard
                margin={[0, 16, 0, 0]}
                inactive={this.state.loading}
                onClick={
                  !this.state.loading
                    ? this.props.handleSubmit(onSubmit)
                    : undefined
                }
                //onClick={this.props.handleSubmit(onSubmit)}
              >
                {t("save")}
              </Button.Standard>
              <Button.Text onClick={() => this.props.onCancel()}>
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

NewContactModal = reduxForm({
  form: "newContactModalForm",
})(NewContactModal);

class ContactEditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { t } = this.props;
    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validateEditContactPerson(values, this.props);
        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          this.setState({ loading: true }, () =>
            this.props.onSubmit().then(() => this.setState({ loading: false }))
          );
          resolve();
        }
      });
    };

    return (
      <form>
        <FlexContainer minWidth={300}>
          <Field
            component={SearchDropDownWlabel}
            name={"editContactPerson"}
            getContact={this.props.getContact}
            options={this.props.contactOptions}
            isSorted={true}
            placeholder={t("select")}
            label={t("name") + " *"}
            t={this.props.t}
            clearable={false}
          />
        </FlexContainer>
        {this.props.showDeleteButton ? (
          <FlexContainer>
            <Field
              border
              component={InputBox}
              name="mobile"
              label={t("mobile_number") + "*"}
            />
            <Field
              border
              component={InputBox}
              name="office"
              label={t("direct_number")}
            />
            <Field
              border
              component={InputBox}
              name="email"
              label={t("email") + " *"}
            />
            <Field
              border
              component={InputBox}
              name="title"
              label={t("company_title")}
            />
            <Label bold>{t("required_fields")}</Label>
          </FlexContainer>
        ) : undefined}
        <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
          {this.props.showDeleteButton ? (
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={
                !this.state.loading
                  ? this.props.handleSubmit(onSubmit)
                  : undefined
              }
              //onClick={this.props.handleSubmit(onSubmit)}
            >
              {t("save")}
            </Button.Standard>
          ) : undefined}
          {this.props.showDeleteButton ? (
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={() => {
                if (!this.state.loading) {
                  this.props.onConfirmDeleteContact();
                }
              }}
              //onClick={() => this.props.onConfirmDeleteContact()}
            >
              {t("delete")}
            </Button.Standard>
          ) : undefined}
          <Button.Text onClick={() => this.props.onCancel()}>
            {t("cancel")}
          </Button.Text>
        </FlexContainer>
      </form>
    );
  }
}

ContactEditModal = reduxForm({
  enableReinitialize: true,
  form: "contactEditModalForm",
})(ContactEditModal);

class ContactTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tv: this.props.initialValues,
      ny: [],
      contacts: this.props.contactPersonList
    };
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.contactPersonList !== this.state.contacts) {
      this.setState({contacts: nextProps.contactPersonList})
      let newObj = { ...this.state.tv };
      for (let roleIndex in this.props.roleList) {
        const role = this.props.roleList[roleIndex];
        const isId = this.state.tv[role + "id"];
        if (isId !== undefined) {
          const correspondingContact = nextProps.contactPersonList.filter((c) => c.id === isId)[0];
          if (correspondingContact === undefined || this.state.tv[role + "email"] !== correspondingContact.email) {
            newObj[role + "email"] = hasValue(correspondingContact) ? correspondingContact.email : undefined;
          }
          if (correspondingContact === undefined || this.state.tv[role + "office"] !== correspondingContact.phoneNumbers[1].phone) {
            newObj[role + "office"] = hasValue(correspondingContact) ? correspondingContact.phoneNumbers[1].phone : undefined;
          }
          if (correspondingContact === undefined || this.state.tv[role + "mobile"] !== correspondingContact.phoneNumbers[0].phone) {
            newObj[role + "mobile"] = hasValue(correspondingContact) ? correspondingContact.phoneNumbers[0].phone : undefined;
          }
          if (correspondingContact === undefined || this.state.tv[role + "title"] !== correspondingContact.title) {
            newObj[role + "title"] = hasValue(correspondingContact) ? correspondingContact.title : undefined;
          }
        }
      }
      this.setState({ tv: newObj });
    }
  }

  render() {
    const { t } = this.props;
    const thLabels = [
      t("role"),
      t("contact_person"),
      t("email"),
      t("telephone"),
      t("mobile_number"),
      t("company_title"),
    ];
    const arrWidth = [300, 300, 200, 200, 200, 180];
    const headerProps = [
      { fontWeight: 500 },
      { fontWeight: 500 },
      { fontWeight: 500 },
      { fontWeight: 500 },
      { fontWeight: 500 },
      { fontWeight: 500 },
    ];

    const tableRows = this.props.roleList
      .filter((role) => this.props.isVisible(role))
      .map((role, index) => (
        <TR key={index}>
          <TD width={arrWidth[0]} fontSize={14}>
            {this.props.initialValues[role + "role"]}
            {this.props.isRequired(role) ? "*" : ""}
          </TD>

          <TD width={arrWidth[1]} fontSize={14}>
            <Field
              onChangedContact={(value) => {
                this.props.onContactChange();
                const selected = this.props.contactPersonList.find(
                  (contact) => value != null && contact.id === value.value
                );
                var newObj = { ...this.state.tv };
                newObj[role + "email"] =
                  selected !== undefined && selected !== null ? selected.email : undefined;
                newObj[role + "office"] =
                  selected !== undefined && selected !== null ? selected.phoneNumbers[1].phone : undefined;
                newObj[role + "mobile"] =
                  selected !== undefined && selected !== null ? selected.phoneNumbers[0].phone : undefined;
                newObj[role + "title"] =
                  selected !== undefined && selected !== null ? selected.title : undefined;
                newObj[role + "id"] =
                    selected !== undefined && selected !== null ? selected.id : undefined;
                this.setState({ tv: newObj, ny: this.state.ny++ });
              }}
              component={CreatableDropDown}
              isSorted={true}
              options={this.props.contactOptions}
              onNewOptionClick={this.props.onNewContact}
              placeholder={t("select")}
              name={role + "selectedContact"}
              t={this.props.t}
            />
          </TD>
          <TD ellipsis width={arrWidth[2]} fontSize={14}>
            {this.state.tv[role + "email"]}
          </TD>

          <TD width={arrWidth[3]} fontSize={14}>
            {this.state.tv[role + "office"]}
          </TD>

          <TD key={"mobile"} width={arrWidth[4]} fontSize={14}>
            {this.state.tv[role + "mobile"]}
          </TD>
          <TD width={arrWidth[5]} ellipsis fontSize={14}>
            {this.state.tv[role + "title"]}
          </TD>
        </TR>
      ));

    return (
      <form>
        <Table
          forceUpdate={true}
          tableRows={tableRows}
          stickyOption={false}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
        />
      </form>
    );
  }
}

ContactTable = reduxForm({
  form: "contactTableForm",
  enableReinitialize: true,
})(ContactTable);

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contactSelectionChanged: false,
      showConfirmEditContactModal: false,
    };

    this.submit = this.submit.bind(this);
  }

  submit() {
    this.setState({ contactSelectionChanged: false });
    return this.contactTable.submit();
  }

  render() {
    const { t } = this.props; //

    return [
      <WhiteDropDown
        onClick={() => {
          this.props.onToggleContactPersons();
        }}
        isOpen={this.props.isContactPersonOpen}
        key="contactTable"
        whiteBackground
        header={t("contact")}
        greenBackground
      >
        <FlexContainer>
          <ContactTable
            {...this.props}
            ref={(x) => (this.contactTable = x)}
            onContactChange={() =>
              this.setState({ contactSelectionChanged: true })
            }
          />
          <FlexContainer spaceBetween column>
            <FlexContainer row>
              <Button.Standard
                onClick={() => {
                  this.props.onNewContact();
                }}
                margin={[16, 16, 0, 0]}
              >
                {t("add_contact")}
              </Button.Standard>
              <Button.Standard
                onClick={() => {
                  if (this.state.contactSelectionChanged) {
                    this.setState({ showConfirmEditContactModal: true });
                  } else this.props.onEditContact();
                }}
                margin={[16, 0, 16, 0]}
              >
                {t("edit_or_delete_contactperson")}
              </Button.Standard>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
        <ConfirmModal
          key={"ConfirmEditContactModal"}
          minHeight={200}
          confirmText={"OK"}
          cancelText={t("cancel")}
          center
          header={t("edit_contact_confirm")}
          isOpen={this.state.showConfirmEditContactModal}
          onClose={() => this.setState({ showConfirmEditContactModal: false })}
          onConfirm={() =>
            this.setState(
              { showConfirmEditContactModal: false },
              this.props.onEditContact
            )
          }
        >
          <p>{t("work_will_be_lost")}</p>
        </ConfirmModal>
      </WhiteDropDown>,
      <Modal
        key={"ModalEditContact"}
        minHeight={200}
        center
        header={t("edit_or_delete_contactperson")}
        isOpen={this.props.showEditContactBox}
        onClose={() => this.props.onCloseEditContactModal()}
      >
        <FlexContainer padding={[16, 16, 16, 16]} column vAlignCenter>
          {/*<FlexContainer hidden={!this.props.contactPerson}>*/}
          <ContactEditModal
            enableReinitialize={true}
            {...this.props}
            initialValues={{ ...this.props.initialContactPerson }}
            onSubmit={this.props.onSubmitEditContact}
            onCancel={this.props.onCloseEditContactModal}
            onDelete={this.props.deleteContact}
            onConfirmDeleteContact={this.props.onConfirmDeleteContact}
            showDeleteButton={this.props.showDeleteButton}
          />
        </FlexContainer>

        {/*</FlexContainer>*/}
      </Modal>,
      <Modal
        key={"ModalNewContact"}
        center
        header={t("add_contact")}
        isOpen={this.props.showNewContactBox}
        onClose={() => this.props.onCloseNewContactModal()}
      >
        <NewContactModal
          t={this.props.t}
          onSubmit={this.props.onSubmitNewContact}
          onCancel={this.props.onCloseNewContactModal}
        />
      </Modal>,
      <Modal
        key={"confirmDeleteContactPerson"}
        isOpen={this.props.openConfirmModalContact}
        header={t("delete_contact_person")}
        onClose={() => this.props.onCloseConfirmModal()}
      >
        <FlexContainer>
          <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
            <FlexContainer column width={browserIsIE() ? "80%" : "100%"}>
              <Label bold>
                {t("delete_contactperson_info", {
                  contact: this.props.contactInfo,
                })}
              </Label>
            </FlexContainer>
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={() => {
                if (!this.state.loading)
                  this.setState({ loading: true }, () =>
                    this.props
                      .deleteContact()
                      .then(() => this.setState({ loading: false }))
                  );
              }}
              //onClick={() => this.props.deleteContact()}
            >
              <span>{t("delete")}</span>
            </Button.Standard>
            <Button.Text onClick={() => this.props.onCloseConfirmModal()}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </FlexContainer>
      </Modal>,
    ];
  }
}

export default Contact;
