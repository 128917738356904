import React from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../components/FlexContainer";
import Table, { TD, TR } from "../components/Table";
import { Icon } from "../insiderTool/components";
import { MAX_RESULT_ON_PAGE } from "../components/PaginationContainer";
import { Field, reduxForm } from "redux-form";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import Input from "../components/Input";
import Label from "../components/Label";
import { Modal } from "../components/Modal";
import EditList from "./EditList";
import { HeaderPanel } from "../components/HeaderPanel";
import { PageHeading } from "../components/PageHeading";
import styled from "styled-components";

const PreviousPage = styled.div``;

const TextButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 0 0 0 5px;
`;

const InputBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]} maxWidth>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : (
          undefined
        )}
      </Label>
      <Input {...props} {...props.input} border />
    </FlexContainer>
  );
};

class AddContactToListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <FlexContainer>
            <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
              <FlexContainer>
                <Field
                  border
                  name="email"
                  component={InputBox}
                  label={t("edist_contact_email")}
                  placeholder={t("edist_contact_email")}
                  greyPlaceholder
                  onKeyDown={e => {
                    switch (e.keyCode) {
                      case 13: //Enter
                        this.props.isNew
                          ? this.props.handleSubmit(
                              this.props.addNewContactToList
                            )
                          : this.props.handleSubmit(
                              this.props.updateContactPerson
                            );
                    }
                  }}
                />

                <Field
                  border
                  name="description"
                  component={InputBox}
                  label={t("edist_new_contact_description")}
                  placeholder={t("edist_new_contact_description")}
                  onKeyDown={e => {
                    switch (e.keyCode) {
                      case 13: //Enter
                        this.props.isNew
                          ? this.props.handleSubmit(
                              this.props.addNewContactToList
                            )
                          : this.props.handleSubmit(
                              this.props.updateContactPerson
                            );
                    }
                  }}
                  greyPlaceholder
                />

                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                  <Button.Standard
                    margin={[0, 16, 0, 0]}
                    onClick={
                      this.props.isNew
                        ? this.props.handleSubmit(
                            this.props.addNewContactToList
                          )
                        : this.props.handleSubmit(
                            this.props.updateContactPerson
                          )
                    }
                  >
                    {t("save")}
                  </Button.Standard>
                  <Button.Text onClick={() => this.props.onClose()}>
                    {t("cancel")}
                  </Button.Text>
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}
AddContactToListComponent = reduxForm({
  form: "distributionContactForm",
  enableReinitialize: true
})(AddContactToListComponent);

class EmailDistributionListDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isNew: false,
      isDeleteListSelected: false
    };
  }

  render() {
    const { t, getListInfo } = this.props;

    const thLabels = [
      <FlexContainer row>{t("email")}</FlexContainer>,
      <FlexContainer row>{t("edist_contact_description")}</FlexContainer>,
      <FlexContainer row>{t("edit")}</FlexContainer>,
      <FlexContainer row>{t("delete")}</FlexContainer>
    ];

    const tableRows =
      this.props.data != null
        ? this.props.data.map((entity, index) => (
            <TR key={index}>
              <TD>{entity.email}</TD>
              <TD>{entity.description}</TD>

              <TD>
                <Icon
                  onClick={() => {
                    this.setState({ isNew: false });
                    this.props.onOpenUpdateContact(entity.id);
                  }}
                  src="/icons/ic-edit-black-24-px.svg"
                  alt={t("edist_edit_contact_alt")}
                  title={t("edist_edit_contact_title")}
                  margin={[10, 0, 0, 20]}
                  key={"edit_icon_" + entity.id}
                />
              </TD>
              <TD>
                <Icon
                  onClick={() => {
                    this.setState({ isDeleteListSelected: false });
                    this.props.onOpenDeleteContact(entity.id);
                  }}
                  src="/icons/red-cross-24.svg"
                  width="13px"
                  height="13px"
                  alt={t("edist_delete_contact_alt")}
                  title={t("edist_delete_contact_title")}
                  margin={[5, 0, 0, 15]}
                  key={"delete_icon_" + entity.id}
                />
              </TD>
            </TR>
          ))
        : [];
    return (
      <React.Fragment>
        <HeaderPanel>{t("edist_list_details")}</HeaderPanel>
        <FlexContainer margin={[0, 0, 20, 0]} border greyBackground>
          <FlexContainer margin={[10, 0, 0, 15]}>
            <FlexContainer row>
              <Label bold margin={[0, 10, 0, 0]}>
                {t("edist_list_name") + ": "}
              </Label>
              <Label>{getListInfo() ? getListInfo().name : ""}</Label>
            </FlexContainer>
            <FlexContainer row>
              <Label bold margin={[0, 10, 0, 0]}>
                {t("edist_list_description") + ": "}
              </Label>
              <Label>{getListInfo() ? getListInfo().description : ""}</Label>
            </FlexContainer>

            <FlexContainer row>
              <Label bold margin={[0, 10, 0, 0]}>
                {t("status") + ": "}
              </Label>
              <Label>
                {getListInfo() ? t("edist_status_" + getListInfo().status) : ""}
              </Label>
            </FlexContainer>
          </FlexContainer>

          <FlexContainer flexEnd vAlignCenter row>
            <Button.Standard
              margin={[0, 0, 10, 0]}
              onClick={() => {
                this.props.onOpenEditList();
              }}
            >
              {t("edist_edit_list")}
            </Button.Standard>

            <Button.Standard
              margin={[0, 10, 10, 16]}
              onClick={() => {
                this.setState({ isDeleteListSelected: true });
                this.props.onOpenDeleteContact();
              }}
            >
              {t("edist_delete_list")}
            </Button.Standard>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer margin={[16, 0, 0, 0]}>
          <PageHeading>{t("edist_contact_details")}</PageHeading>
        </FlexContainer>

        <FlexContainer margin={[0, 0, 20, 0]} row spaceBetween>
          <FlexContainer margin={[0, 0, 0, 0]} vAlignEnd>
            <Button.Standard
              onClick={() => {
                this.setState({ isNew: true });
                this.props.onOpenContactModal();
              }}
            >
              {t("edist_add_new_contact")}
            </Button.Standard>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          column
          padding={
            tableRows && tableRows.length < MAX_RESULT_ON_PAGE
              ? [0, 0, 0, 0, 0, 0, 0]
              : []
          }
          margin={[0, 0, 15, 0]}
        >
          <FlexContainer row />
          <Table
            tableRows={tableRows}
            tableHeaderLabels={thLabels}
            forceUpdate={true}
            t={t}
          />
        </FlexContainer>

        <FlexContainer width="auto" margin={[20, 0, 0, 0]}>
          <PreviousPage
            onClick={() =>
              this.props.history.length > 0
                ? this.props.history.goBack()
                : this.props.history.push(`${this.props.base}`)
            }
          >
            <Icon src="/icons/back-arrow-24-px.svg" />
            <TextButton>{t("back")}</TextButton>
          </PreviousPage>
        </FlexContainer>
        {
          //ADD CONTACT MODAL:
        }
        <Modal
          isOpen={this.props.isContactModalOpen}
          onClose={() => this.props.onCloseContactModal()}
          header={
            this.state.isNew
              ? t("edist_add_distribution_contact")
              : t("edist_edit_distribution_contact")
          }
          onConfirm={() => this.props.onCloseContactModal()}
        >
          <AddContactToListComponent
            {...this.props}
            isNew={this.state.isNew}
            initialValues={
              this.state.isNew ? undefined : this.props.getContactDetails()
            }
            onClose={() => this.props.onCloseContactModal()}
            addNewContactToList={this.props.addNewContactToList}
            updateContactPerson={this.props.updateContactPerson}
          />
        </Modal>

        {
          //REMOVE CONTACT MODAL:
        }

        <Modal
          isOpen={this.props.isDeleteWarningOpen}
          onClose={() => this.props.onCloseWarningModal()}
          header={t("edist_are_you_sure")}
          onConfirm={() => this.props.onCloseWarningModal()}
        >
          <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
            <FlexContainer>
              {this.state.isDeleteListSelected ? (
                <FlexContainer>
                  {t("edist_delete_list_info", {
                    list: this.props.getListInfo()
                      ? this.props.getListInfo().name
                      : ""
                  })}
                </FlexContainer>
              ) : (
                <FlexContainer>
                  {t("edist_delete_contact_info", {
                    contact: this.props.getContactDetails()
                      ? this.props.getContactDetails().email
                      : ""
                  })}
                </FlexContainer>
              )}
              <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                <Button.Standard
                  margin={[0, 16, 0, 0]}
                  onClick={() => {
                    this.state.isDeleteListSelected
                      ? this.props.deleteDistributionList()
                      : this.props.deleteContactFromList();
                  }}
                >
                  {t("ok")}
                </Button.Standard>
                <Button.Text onClick={() => this.props.onCloseWarningModal()}>
                  {t("cancel")}
                </Button.Text>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </Modal>

        {
          //EDIT LIST MODAL:
        }

        <Modal
          isOpen={this.props.isEditListOpen}
          onClose={() => this.props.onCloseEditList()}
          header={t("edist_edit_distribution_list")}
          //TODO: onConfirm close??
          onConfirm={() => this.props.onCloseEditList()}
        >
          <EditList
            {...this.props}
            initialValues={this.props.getListInfo()}
            onClose={() => this.props.onCloseEditList()}
            updateList={this.props.updateList}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(EmailDistributionListDetails);
