import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexContainer } from "../components/FlexContainer";
import { ButtonGroup } from "./Edit";
import { PageHeading } from "../components/PageHeading";
import Table, { TD, TR } from "../components/Table";
import Button from "../components/Button";
import { OverlayLoader } from "../components/Loader";

const PreviousPage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Icon = styled.img`
  cursor: pointer;
  padding: 0px 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;

const createTable = (t, left, message) => {
  const language = message.language.toLowerCase();
  const tableHeaderLabels = [t(language + "_message", { lng: language })];
  const tableHeaderProps = [{ colSpan: 2 }];

  const tableRows = [
    <TR key="1">
      <TD bold width={150}>
        {t("issuer", { lng: language })}:
      </TD>
      <TD>{message.issuerName}</TD>
    </TR>,
    <TR key="2">
      <TD bold>{t("category", { lng: language })}:</TD>
      <TD>{t("financial_calendar_category", { lng: language })}</TD>
    </TR>,
    <TR key="3">
      <TD bold>{t("messageTitle", { lng: language })}:</TD>
      <TD>{t("financial_calendar_title", { lng: language })}</TD>
    </TR>,
    <TR key="4">
      <TD bold alignTop>
        {t("message", { lng: language })}:
      </TD>
      <TD breakWordsWithHyphen pre_wrap width={725}>
        {message.body}
      </TD>
    </TR>
  ];

  return (
    <FlexContainer margin={[0, 15, 0, 0]} row key={language}>
      <Table
        margin={left ? [0, 0, 20, 0] : []}
        key={"confirmTable_" + language}
        tableRows={tableRows}
        tableHeaderLabels={tableHeaderLabels}
        tableHeaderProps={tableHeaderProps}
      />
    </FlexContainer>
  );
};

function Confirm(props) {
  const { t } = props;

  let tables = [];
  let left = true;

  if (props.previewMessageList) {
    props.previewMessageList.forEach(message => {
      tables.push(createTable(t, left, message));
      left = !left;
    });
  }

  const noMessage = (
    <FlexContainer
      margin={[20, 0, 20, 0]}
      row
      center
      greyBackground
      key={"no_message"}
    >
      {t("no_message_will_be_created_for_this_change")}
    </FlexContainer>
  );

  return (
    <FlexContainer column>
      {props.saveInProgress ? <OverlayLoader /> : undefined}
      <PageHeading>{props.t("heading")}</PageHeading>

      <FlexContainer column>
        {tables.length > 0 ? tables : noMessage}
      </FlexContainer>

      <FlexContainer row spaceBetween>
        <PreviousPage onClick={props.onBack}>
          <Icon src="/icons/back-arrow-24-px.svg" />
          <Button.Text>{t("previous_step")}</Button.Text>
        </PreviousPage>
        <ButtonGroup>
          <Button.Standard
            onClick={() => {
              if (!props.saveInProgress) {
                props.onSubmit();
              }
            }}
          >
            {t("done")}
          </Button.Standard>
          <Button.Text
            onClick={() => {
              if (!props.saveInProgress) {
                props.onCancel();
              }
            }}
          >
            {t("cancel")}
          </Button.Text>
        </ButtonGroup>
      </FlexContainer>
    </FlexContainer>
  );
}

Confirm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};

export default Confirm;
