import { combineReducers } from "redux";
import {
  CountriesList,
  InsiderClearData,
  InsiderList,
  PrimaryInsiderList,
} from "./InsiderActions";

const initalInsiderListState = {
  list: [],
  isFetched: false,
};

export const insiderList = (
  state = {
    ...initalInsiderListState,
  },
  action
) => {
  switch (action.type) {
    case InsiderList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case InsiderClearData.clear:
      return Object.assign({}, state, {
        ...initalInsiderListState,
      });
    default:
      return state;
  }
};

const initialPrimaryInsiderListState = {
  list: [],
  isFetched: false,
};

export const primaryInsiderList = (
  state = {
    ...initialPrimaryInsiderListState,
  },
  action
) => {
  switch (action.type) {
    case PrimaryInsiderList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case InsiderClearData.clear:
      return Object.assign({}, state, {
        ...initialPrimaryInsiderListState,
      });
    default:
      return state;
  }
};

const initialCountriesList = {
  list: [],
  isFetched: false,
};

export const countries = (
  state = {
    ...initialCountriesList,
  },
  action
) => {
  switch (action.type) {
    case CountriesList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const insiderReducer = combineReducers({
  insiderList,
  primaryInsiderList,
  countries,
});

export default insiderReducer;
