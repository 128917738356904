import React from "react";
import PropTypes from "prop-types";
import { FlexContainer } from "../components/FlexContainer";

import styled from "styled-components";

const Select = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 472px;
  padding: 4px 8px 0 8px;
  width: 100%;
  height: 239px;
  background-color: white;
  color: black;
  border: solid 1px #d0d0d0;
  overflow: auto;

  &:focus {
    outline: none;
  }
`;
const StyledOption = styled.a`
  padding: 4px 8px 4px 8px;
  margin: 1px 0 1px 0;
  cursor: pointer;
  ${(props) =>
    props.selected ? "background: rgba(178, 207, 174, 0.5);" : ""} &: hover {
    outline: none;
    background: rgba(178, 207, 174, 0.2);
  }
  &: focus {
    outline: none;
    ${(props) =>
      props.hasFocus ? "background: rgba(178, 207, 174, 0.2);" : ""};
  }
`;

const TransferButton = styled.span`
  display: flex;
  border-radius: 3px;
  background-color: #477857;
  max-width: 177px;
  width: 100%;
  height: 35px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #3e6a4c;
  }

  ${(props) => (props.inactive ? "opacity: 0.5;" : "")};
`;
const Icon = styled.img``;

class Option extends React.Component {
  componentDidUpdate() {
    if (this.props.hasFocus) {
      this.option.focus({ preventScroll: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.selected !== this.props.selected ||
      nextProps.tabIndex !== this.props.tabIndex ||
      nextProps.hasFocus !== this.props.hasFocus
    );
  }

  render() {
    return (
      <StyledOption
        {...this.props}
        ref={(x) => (this.option = x)}
        onClick={(e) => {
          if (this.props.hasFocus) {
            this.option.blur();
          }
          this.props.onClick(e);
          e.preventDefault();
        }}
        onFocus={this.props.onTabFocus}
      >
        {this.props.children}
      </StyledOption>
    );
  }
}
class SelectionBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.setupDatastructures = this.setupDatastructures.bind(this);
    this.updateStateAfterTransfer = this.updateStateAfterTransfer.bind(this);
    this.transferToSource = this.transferToSource.bind(this);
    this.transferToTarget = this.transferToTarget.bind(this);
  }

  getInitialState() {
    const initialState = {
      sourceMap: new Map(),
      targetMap: new Map(),
      sourceSelectionMap: new Map(),
      targetSelectionMap: new Map(),
      nextSourceIdMap: new Map(),
      prevSourceIdMap: new Map(),
      nextTargetIdMap: new Map(),
      prevTargetIdMap: new Map(),
      selectedId: -1,
    };

    return initialState;
  }
  createId2NextIdMap = (array, sortFnc) => {
    var idMap = new Map();
    array.sort(sortFnc);
    var orgArray = array.slice();

    array.shift();
    array.forEach((elm, index) => {
      if (typeof orgArray[index] === "object")
        idMap.set(orgArray[index][0], elm[0]);
      else idMap.set(orgArray[index], elm);
    });

    return idMap;
  };

  setupDatastructures(nextProps, first10Entries = false) {
    var selectionArray =
      first10Entries && nextProps.selectionArray != null
        ? nextProps.selectionArray.slice(0, 1)
        : nextProps.selectionArray;
    var targetArray =
      first10Entries && nextProps.targetArray != null
        ? nextProps.targetArray.slice(0, 1)
        : nextProps.targetArray;
    var nextSourceIdMap = new Map();
    var prevSourceIdMap = new Map();
    var nextTargetIdMap = new Map();
    var prevTargetIdMap = new Map();
    var sourceMap = new Map();
    var targetMap = new Map();

    const descendingSort = (a, b) => {
      if (b[1] && a[1]) {
        return b[1].toLowerCase().localeCompare(a[1].toLowerCase());
      } else if (b[1] && !a[1]) {
        return -1;
      } else if (!b[1] && a[1]) {
        return 1;
      } else {
        return 0;
      }
    };
    const ascendingSort = (a, b) => descendingSort(a, b) * -1;

    if (targetArray != null) {
      targetArray.sort(ascendingSort);
      targetMap = new Map(targetArray);

      nextTargetIdMap = this.createId2NextIdMap(
        Array.from(targetMap.entries()),
        ascendingSort
      );
      prevTargetIdMap = this.createId2NextIdMap(
        Array.from(targetMap.entries()),
        descendingSort
      );
    }

    if (selectionArray != null) {
      selectionArray.sort(ascendingSort);
      sourceMap = new Map(selectionArray);

      // Remove the id that is in the target from the source map
      targetMap.forEach((targetName, targetId) => sourceMap.delete(targetId));

      nextSourceIdMap = this.createId2NextIdMap(
        Array.from(sourceMap.entries()),
        ascendingSort
      );
      prevSourceIdMap = this.createId2NextIdMap(
        Array.from(sourceMap.entries()),
        descendingSort
      );
    }

    this.setState({
      sourceMap: sourceMap,
      targetMap: targetMap,
      nextSourceIdMap: nextSourceIdMap,
      prevSourceIdMap: prevSourceIdMap,
      nextTargetIdMap: nextTargetIdMap,
      prevTargetIdMap: prevTargetIdMap,
    });
  }

  updateStateAfterTransfer = (newSourceMap, newTargetMap) => {
    var newNextSourceIdMap = new Map();
    var newPrevSourceIdMap = new Map();
    var newNextTargetIdMap = new Map();
    var newPrevTargetIdMap = new Map();
    const descendingSort = (a, b) => Number(b) - Number(a);
    const ascendingSort = (a, b) => Number(a) - Number(b);
    const onChangeTarget = () => {
      var idArray = [];
      this.state.targetMap.forEach((label, id) => idArray.push(id));
      this.props.input.onChange(idArray);
    };

    newNextSourceIdMap = this.createId2NextIdMap(
      Array.from(newSourceMap.keys()),
      ascendingSort
    );
    newPrevSourceIdMap = this.createId2NextIdMap(
      Array.from(newSourceMap.keys()),
      descendingSort
    );
    newNextTargetIdMap = this.createId2NextIdMap(
      Array.from(newTargetMap.keys()),
      ascendingSort
    );
    newPrevTargetIdMap = this.createId2NextIdMap(
      Array.from(newTargetMap.keys()),
      descendingSort
    );

    this.setState(
      {
        sourceMap: newSourceMap,
        targetMap: newTargetMap,
        nextSourceIdMap: newNextSourceIdMap,
        prevSourceIdMap: newPrevSourceIdMap,
        nextTargetIdMap: newNextTargetIdMap,
        prevTargetIdMap: newPrevTargetIdMap,
        sourceSelectionMap: new Map(),
        targetSelectionMap: new Map(),
      },
      onChangeTarget()
    );
  };

  transferToTarget = () => {
    if (this.state.sourceSelectionMap.size > 0) {
      var newSourceMap = this.state.sourceMap;
      var newTargetMap = this.state.targetMap;

      this.state.sourceSelectionMap.forEach((label, id) => {
        newSourceMap.delete(id);
        newTargetMap.set(id, label);
      });

      this.updateStateAfterTransfer(newSourceMap, newTargetMap);
    }
  };
  transferToSource = () => {
    if (this.state.targetSelectionMap.size > 0) {
      var newTargetMap = this.state.targetMap;
      var newSourceMap = this.state.sourceMap;

      this.state.targetSelectionMap.forEach((label, id) => {
        newTargetMap.delete(id);
        newSourceMap.set(id, label);
      });
      this.updateStateAfterTransfer(newSourceMap, newTargetMap);
    }
  };

  populateOptions = (action, selectionMap, sourceMap) => {
    var optionsArray = [];
    var firstVal = true;

    sourceMap.forEach((label, id) => {
      optionsArray.push(
        <Option
          key={id}
          onTabFocus={() => {
            this.setState({ selectedId: id });
          }}
          hasFocus={this.state.selectedId == id}
          tabIndex={firstVal ? "0" : "-1"}
          onClick={(e) => action(e, label, id)}
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 13:
                action(e, label, id, true);
                e.preventDefault();
                break;
              default:
                break;
            }
          }}
          selected={selectionMap.has(id)}
        >
          {label}
        </Option>
      );

      firstVal = false;
    });

    return optionsArray;
  };

  componentWillMount() {
    this.setupDatastructures(this.props, true);
  }

  componentDidMount() {
    // We do this again to load all the data. This is because IE had a bug when rendering all at once.
    this.setupDatastructures(this.props);
  }

  componentWillReceiveProps(nextProps, nextState) {
    const targetLengthNow =
      this.props.targetArray != null ? this.props.targetArray.length : 0;
    const targetLengthNext =
      nextProps.targetArray != null ? nextProps.targetArray.length : 0;

    if (this.state.sourceMap.size === 0) {
      return;
    }
    if (
      this.state.sourceMap.size > 0 &&
      targetLengthNow === targetLengthNext &&
      this.props.sourceId === nextProps.sourceId
    )
      return;

    this.setupDatastructures(nextProps);
  }

  render() {
    const sourceOptions = this.populateOptions(
      (e, label, id, fromKeyboard = false) => {
        var newSelection = this.state.sourceSelectionMap;

        if (this.props.singleSelect) {
          newSelection.clear();
          newSelection.set(id, label);
        } else {
          !newSelection.has(id)
            ? newSelection.set(id, label)
            : newSelection.delete(id);
        }

        if (!fromKeyboard)
          this.setState({
            sourceSelectionMap: newSelection,
            selectedId: id == this.state.selectedId ? -1 : id,
          });
        else
          this.setState({
            sourceSelectionMap: newSelection,
          });
      },
      this.state.sourceSelectionMap,
      this.state.sourceMap,
      true
    );

    const targetOptions = this.populateOptions(
      (e, label, id, fromKeyboard = false) => {
        var newSelection = this.state.targetSelectionMap;

        if (this.props.singleSelect) {
          newSelection.clear();
          newSelection.set(id, label);
        } else {
          !newSelection.has(id)
            ? newSelection.set(id, label)
            : newSelection.delete(id);
        }

        if (!fromKeyboard)
          this.setState({
            targetSelectionMap: newSelection,
            selectedId: id == this.state.selectedId ? -1 : id,
          });
        else
          this.setState({
            targetSelectionMap: newSelection,
          });
      },
      this.state.targetSelectionMap,
      this.state.targetMap
    );

    return (
      <FlexContainer row spaceBetween id={this.props.input.name}>
        <Select
          tabIndex="0"
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 38:
                this.setState({
                  selectedId: this.state.prevSourceIdMap.get(
                    this.state.selectedId
                  ),
                });
                e.preventDefault();
                break;
              case 40:
                this.setState({
                  selectedId: this.state.nextSourceIdMap.get(
                    this.state.selectedId
                  ),
                });
                e.preventDefault();
                break;
              default:
                break;
            }
          }}
        >
          {sourceOptions}
        </Select>
        <FlexContainer column maxWidth={140} margin={[0, 20, 0, 20]} center>
          <TransferButton
            tabIndex="0"
            inactive={this.state.sourceMap.size === 0}
            onClick={() => this.transferToTarget()}
            onKeyDown={(e) => {
              switch (e.keyCode) {
                case 13:
                  e.preventDefault();
                  this.transferToTarget();
                  break;
                default:
                  break;
              }
            }}
          >
            <Icon src="/icons/button_arrow_right.svg" />
          </TransferButton>

          <TransferButton
            tabIndex="0"
            inactive={this.state.targetMap.size === 0}
            onClick={() => this.transferToSource()}
            onKeyDown={(e) => {
              switch (e.keyCode) {
                case 13:
                  e.preventDefault();
                  this.transferToSource();
                  break;
                default:
                  break;
              }
            }}
          >
            <Icon src="/icons/button_arrow_left.svg" />
          </TransferButton>
        </FlexContainer>
        <Select
          tabIndex="0"
          onKeyDown={(e) => {
            switch (e.keyCode) {
              case 38:
                this.setState({
                  selectedId: this.state.prevTargetIdMap.get(
                    this.state.selectedId
                  ),
                });
                e.preventDefault();
                break;
              case 40:
                this.setState({
                  selectedId: this.state.nextTargetIdMap.get(
                    this.state.selectedId
                  ),
                });
                e.preventDefault();
                break;
              default:
                break;
            }
          }}
        >
          {targetOptions}
        </Select>
      </FlexContainer>
    );
  }
}

SelectionBoxes.propTypes = {
  selectionArray: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.any, PropTypes.any)
  ),
  targetArray: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.any, PropTypes.any)
  ),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
  }),
  sourceId: PropTypes.number.isRequired, // For identifying the source of whom is populating the boxes so that we can easily
  // Update with new values when that is needed in "componentWillReceiveProps"
};

export default SelectionBoxes;
