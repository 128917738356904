import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../../components/FlexContainer";
import InternalDocumentationTable from "./InternalDocumentationTable";
import moment from "moment";
import { getOBNTDateTime, showFileFromServer } from "../../util/common";
import Button from "../../components/Button";
import { Route, Switch } from "react-router";
import Document from "./Document";
import {
  fetchInsiderListDocumentList,
  createInsiderListDocument,
  fetchInsiderListDocument,
  updateInsiderListDocument,
  deleteInsiderListDocument,
  fetchAttachment,
  uploadAttachment,
  InsiderType,
  deleteAttachment
} from "../InsiderToolActions";
import { NotificationManager } from "react-notifications";
import Loader, { OverlayLoader } from "../../components/Loader";
import { getGlobalSubject } from "../../obsvcClient";

const VIEW = "view";
const EDIT = "edit";

class InternalDocumentationHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = { showLoader: false, showOverlayLoader: false };
  }
  componentWillMount() {
    Promise.resolve(this.setState({ showLoader: true }))
      .then(() => this.props.dispatch(fetchInsiderListDocumentList()))
      .then(() => this.setState({ showLoader: false }));
  }

  onNewDocument = _ => {
    const date = moment().toISOString();

    this.props
      .dispatch(
        createInsiderListDocument({
          created: date,
          updated: date,
          title: "DOC_" + date,
          createdBy: getGlobalSubject()
        })
      )
      .then(doc =>
        this.props.history.push(`${this.props.base}/${EDIT}/${doc.id}`)
      );
  };

  onViewDocument = id => {
    this.props.history.push(`${this.props.base}/${VIEW}/${id}`);
  };

  onEditDocument = id => {
    this.props.history.push(`${this.props.base}/${EDIT}/${id}`);
  };

  fetchDocument = id => {
    this.props
      .dispatch(fetchInsiderListDocumentList())
      .then(() => this.props.dispatch(fetchInsiderListDocument(id)));
  };

  onDeleteDocument = id => {
    Promise.resolve(this.setState({ showOverlayLoader: true }));
    this.props
      .dispatch(deleteInsiderListDocument(id))
      .then(() => this.props.dispatch(fetchInsiderListDocumentList()))
      .then(() => this.setState({ showOverlayLoader: false }));
  };

  uploadAttachment = file => {
    const { t, document, dispatch } = this.props;

    var formData = new FormData();

    formData.append("attachment", file);
    formData.append("parentId", document.id);

    dispatch(uploadAttachment(formData, InsiderType.DOCUMENT))
      .then(() => NotificationManager.success(t("attachment_uploaded")))
      .then(() => this.props.dispatch(fetchInsiderListDocumentList()))
      .then(() => this.props.dispatch(fetchInsiderListDocument(document.id)));
  };

  openAttachment = (id, fileName) => {
    const { dispatch, document } = this.props;
    dispatch(fetchAttachment(id, document.id, InsiderType.DOCUMENT)).then(
      data => showFileFromServer(data, fileName)
    );
  };

  removeAttachment = id => {
    const { dispatch, document, t } = this.props;

    Promise.resolve(this.setState({ showOverlayLoader: true }));
    dispatch(deleteAttachment(id, document.id, InsiderType.DOCUMENT))
      .then(() => dispatch(fetchInsiderListDocumentList()))
      .then(() => dispatch(fetchInsiderListDocument(document.id)))
      .then(() => this.setState({ showOverlayLoader: false }))
      .then(() => NotificationManager.success(t("it_attachment_removed")));
  };

  onSubmit = values => {
    const { dispatch, history, base, document } = this.props;
    const { title, type, message } = values;

    const data = {
      created: document.data.created,
      updated: moment().toISOString(),
      title,
      message,
      createdBy: values.createdBy,
      type: type ? (type.value ? type.value : type) : ""
    };

    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() => dispatch(updateInsiderListDocument(document.id, data)))
      .then(() => dispatch(fetchInsiderListDocumentList()))
      .then(() => this.setState({ showOverlayLoader: false }))
      .then(
        () => (history.length > 0 ? history.goBack() : history.push(`${base}`))
      );
  };

  onCancel = () => {
    this.props.history.length > 0
      ? this.props.history.goBack()
      : this.props.history.push(`${this.props.base}`);
  };

  render() {
    const {
      base,
      document,
      documentAttachments,
      documentList,
      showUTCTime
    } = this.props;

    if (this.state.showLoader) return <Loader />;

    return (
      <FlexContainer column margin={[30, 0, 0, 0]}>
        {this.state.showOverlayLoader ? <OverlayLoader /> : undefined}
        <Switch>
          <Route
            exact
            path={base}
            render={_ => (
              <InternalDocumentationTable
                userMap={this.props.userMap}
                showUTCTime={showUTCTime}
                docList={documentList}
                onNewDocument={this.onNewDocument}
                onDeleteDocument={this.onDeleteDocument}
                onClickDocument={this.onViewDocument}
                onEditDocument={this.onEditDocument}
                hasEditAccess={this.props.hasEditAccess}
              />
            )}
          />
          <Route
            path={`${base}/:routeAction/:guid`}
            render={props => {
              const routeAction = props.match.params.routeAction;
              const guid = props.match.params.guid;

              return (
                <Document
                  fetchInsiderListDocument={() => this.fetchDocument(guid)}
                  typeList={this.props.types}
                  initialValues={document ? document.data : {}}
                  onUploadAttachment={this.uploadAttachment}
                  onCancel={this.onCancel}
                  onSubmit={values => this.onSubmit(values)}
                  inEditMode={routeAction === EDIT}
                  openAttachment={this.openAttachment}
                  removeAttachment={this.removeAttachment}
                  attachments={documentAttachments.map(a => ({
                    name: a.short_data,
                    id: a.id
                  }))}
                />
              );
            }}
          />
        </Switch>
      </FlexContainer>
    );
  }
}

const mapStateToProps = state => {
  const { insiderToolReducer, permissionReducer } = state;
  const userMap = insiderToolReducer.userList.map;
  var hasEditAccess = who => {
    if (
      userMap.get(getGlobalSubject()) &&
      userMap.get(getGlobalSubject()).isAdmin === true
    ) {
      return true;
    } else if (who == getGlobalSubject()) {
      return true;
    }
    return false;
  };

  return {
    documentList: insiderToolReducer.document.list,
    document: insiderToolReducer.document.document,
    documentAttachments: insiderToolReducer.document.attachments,
    types: insiderToolReducer.document.types,
    showUTCTime: insiderToolReducer.UTC.showUTC,
    userMap,
    hasEditAccess,

    permissionList: permissionReducer.permissions || []
  };
};
export default connect(mapStateToProps)(
  withTranslation("translations")(InternalDocumentationHome)
);
