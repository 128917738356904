const atLeastOneKeyHasValue = person => {
  let hasValue = false;
  if (person != null) {
    Object.keys(person).forEach(key => {
      if (person[key] != null) {
        hasValue = true;
      }
    });
  }
  return hasValue;
};

export const RESPONSIBLE_PERSON_REG_EX =
  "responsible_(firstName|lastName|title|email|phone)";

export const getResponsiblePersonsFromValues = values => {
  let persons = [];
  Object.keys(values).forEach(key => {
    const matchArr = key.match(`${RESPONSIBLE_PERSON_REG_EX}_([0-9]+)`);

    if (matchArr != null) {
      const keyIndex = matchArr[2];
      const personKey = matchArr[1];
      if (!persons[keyIndex]) {
        persons[keyIndex] = {};
      }

      if (atLeastOneKeyHasValue(values[key])) {
        persons[keyIndex][personKey] = values[key];
      }
    }
  });

  //As we are using the indexes from the generic list of persons we need to remove the ones that are empty
  let nonEmptyPersons = [];
  for (let i = 0; i < persons.length; i++) {
    if (persons[i]) {
      nonEmptyPersons.push(persons[i]);
    }
  }
  return nonEmptyPersons;
};
