import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import Button from "../../components/Button";
import { FlexContainer } from "../../components/FlexContainer";
import { DateBox, TimePickerBox } from "../components";
import MenuBoxContainer from "../MenuBoxContainer";
import Label from "../../components/Label";
import { Modal } from "../../components/Modal";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";
import { HelpIcon } from "../../components/HelpIcon";
import moment from "moment/moment";
import { OBNTInsiderToolDateTimeFormat } from "../../util/common";
import { ErrorText } from "../../components/ErrorText";

export class InsiderListHeaderActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEarlierVersionsModal: false
    };
  }

  toggleShowEarlierVersions = () =>
    this.setState({
      showEarlierVersionsModal: !this.state.showEarlierVersionsModal
    });
  onShowEarlierVersion = values => {
    const { getInsiderListAt, insiderListData } = this.props;

    const dateTimeString = values.date + " " + values.time;
    const timestamp = moment(dateTimeString, OBNTInsiderToolDateTimeFormat).add(
      1,
      "seconds"
    );

    return new Promise((resolve, reject) => {
      if (moment(insiderListData.data.created).isAfter(timestamp))
        reject(new SubmissionError({ beforeCreatedError: true }));
      else {
        getInsiderListAt(timestamp.toISOString());
        resolve(this.toggleShowEarlierVersions());
      }
    });
  };

  render() {
    const { t, dispatch, listId, viewTracelog, insiderListData } = this.props;

    const earlierVersionsHeader = (
      <FlexContainer row center>
        {t("chose_time_and_date")}
        <HelpIcon
          margin={[8, 0, -3, 5]}
          fileName={"earlier_versions"}
          language={i18n.language}
          insiderToolHelp
          height={"18"}
          width={"18"}
        />
      </FlexContainer>
    );

    return (
      <FlexContainer column>
        <FlexContainer row flexEnd margin={[10, 0, 20, 0]} width="auto">
          <Label margin={[3, 10, 0, 0]} fontSize={16} bold>
            {t("alternatives")}
          </Label>

          <MenuBoxContainer
            insiderListSubType={this.props.insiderListData}
            dispatch={dispatch}
            listOpened
            listId={listId}
            icon={"/icons/more-horiz-24px.svg"}
            selectedIcon={"/icons/more-horiz-selected-24px.svg"}
          />
        </FlexContainer>

        <FlexContainer width="auto" row flexEnd>
          <Button.Standard
            onClick={this.toggleShowEarlierVersions}
            margin={[0, 10, 0, 0]}
            inverted
          >
            {t("show_earlier_versions")}
          </Button.Standard>
          <Button.Standard onClick={viewTracelog}>
            {t("show_history")}
          </Button.Standard>
        </FlexContainer>
        {this.state.showEarlierVersionsModal ? (
          <Modal
            width={"30%"}
            key={"earlier_versions_modal"}
            header={earlierVersionsHeader}
            isOpen={this.state.showEarlierVersionsModal}
            onClose={this.toggleShowEarlierVersions}
          >
            <EarlierVersions
              disableDaysBefore={moment(insiderListData.data.created).toDate()}
              onSubmit={this.onShowEarlierVersion}
              onClose={this.toggleShowEarlierVersions}
            />
          </Modal>
        ) : (
          ""
        )}
      </FlexContainer>
    );
  }
}

InsiderListHeaderActions = withTranslation("translations")(
  InsiderListHeaderActions
);

class EarlierVersions extends React.Component {
  render() {
    const {
      handleSubmit,
      onSubmit,
      t,
      onClose,
      disableDaysBefore
    } = this.props;

    return (
      <FlexContainer minHeight={300} column>
        <form autoComplete="off">
          <Field
            name="beforeCreatedError"
            component={({ meta }) =>
              meta.error ? (
                <FlexContainer
                  name="beforeCreatedDate"
                  row
                  margin={[0, 0, 20, 0]}
                >
                  <ErrorText>{t("it_no_versions_exists")}</ErrorText>
                </FlexContainer>
              ) : (
                ""
              )
            }
          />
          <FlexContainer column margin={[0, 0, 100, 0]}>
            <Field
              component={DateBox}
              disableDaysBefore={disableDaysBefore}
              name="date"
              label={t("date")}
              border
            />
            <Field
              component={TimePickerBox}
              name="time"
              label={t("time")}
              border
            />
          </FlexContainer>
          <FlexContainer flexEnd row vAlignCenter>
            <Button.Standard
              onClick={handleSubmit(onSubmit)}
              margin={[0, 16, 0, 0]}
            >
              {t("show")}
            </Button.Standard>
            <Button.Text onClick={onClose}>{t("cancel")}</Button.Text>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

EarlierVersions = reduxForm({
  form: "earlierVersions",
  initialValues: { time: "00:00" }
})(withTranslation("translations")(EarlierVersions));
