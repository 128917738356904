import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { Field, reduxForm } from "redux-form";

import Table, { TR, TD } from "../components/Table";
import SearchableSelect from "../components/SearchableSelect";
import styled from "styled-components";
import Label from "../components/Label";

import Button from "../components/Button";
import CheckBox from "../components/CheckBox";

import Input from "../components/Input";
import { ActionText } from "../components/ActionText";
import WithPagination from "../components/PaginationContainer";
import Loader from "../components/Loader";
import { RoleTipContiner, RoleTip } from "./AdminContainer";
import {ErrorText} from "../components/ErrorText";

const OrganisationDropdownBox = props => (
  <FlexContainer column margin={[0, 19, 0, 0]}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    <FlexContainer id={props.input.name} row>
      <SearchableSelect {...props} onSelect={props.onSelectOrganisation} />
    </FlexContainer>
  </FlexContainer>
);

const InputBox = props => {
  return (
    <FlexContainer column margin={[0, 20, 0, 0]}>
      <Label htmlFor={props.input.name}>{props.label}</Label>
      <Input border {...props.input} {...props} id={props.input.name} />
    </FlexContainer>
  );
};

const IsActiveBox = props => <CheckBox noWrap checked {...props} />;

class SearchForm extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
    const { t } = this.props;

    return (
      <form onSubmit={this.props.handleSubmit}>
        <FlexContainer row margin={[0, 0, 5, 0]}>
          <FlexContainer row>
            {this.props.showOrgBox ? (
              <Field
                name={"organisation"}
                label={t("organisation")}
                component={OrganisationDropdownBox}
                options={this.props.organisationList}
                onSelectOrganisation={this.props.onSelectOrganisation}
                searchable={true}
                placeholder={t("select")}
                t={t}
              />
            ) : (
              ""
            )}

            <Field
              component={InputBox}
              onChange={(e, newValue) =>
                newValue == null || newValue.length === 0
                  ? this.props.onClearSearch(true)
                  : undefined
              }
              name={"username"}
              label={t("user")}
            />
          </FlexContainer>
          <FlexContainer row padding={[21, 0, 0, 0]} vAlignCenter spaceBetween>
            <IsActiveBox
              label={t("show_only_active")}
              onChange={this.props.onIsActiveChange}
            />
            <FlexContainer row vAlignCenter flexEnd>
              <Button.Form
                type="submit"
                margin={[0, 24, 0, 0]}
                inactive={!this.props.searchButtonIsActive}
              >
                {t("search")}
              </Button.Form>
              <Button.Standard
                onClick={this.props.newUserAction}
                inverted
                margin={[0, 24, 0, 0]}
              >
                {t("new_user")}
              </Button.Standard>
              <ActionText
                onClick={() => {
                  this.props.reset();
                  this.props.onClearSearch();
                }}
              >
                {t("reset_search")}
              </ActionText>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
          {this.props.errorMessage ? <ErrorText>{t("invalid_search")}</ErrorText> : undefined}
      </form>
    );
  }
}

SearchForm = reduxForm({
  form: "userAdminSearch",
  destroyOnUnmount: false,
  enableReinitialize: true
})(SearchForm);

var PaginatedUserList = props => <Table tableRows={props.data} {...props} />;
PaginatedUserList = WithPagination(PaginatedUserList);

const ClickAbleTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlyActiveUsers: true,
      username: undefined,
      organisationIsSelected: !this.props.showOrgBox,
      loading: false,
        errorMessage: false,
    };
  }

  componentDidMount() {
    if (this.props.selectedOrganisation != null)
      this.setState({ organisationIsSelected: true });
  }

  render() {
    const props = this.props;
    const { t } = props;

    const thLabels = [
      t("username"),
      t("name"),
      t("telephone"),
      t("email"),
      t("organisation"),
      t("active"),
      t("roles"),
      t("modify")
    ];
    const arrWidth = [126, 206, 148, 260, 193, 78, 100, 66];
    const headerProps = [undefined, undefined, undefined, undefined, undefined, { center: true }, undefined, { center: true }];

    const userRows =
      props.data != null
        ? props.data
            .filter(
              user =>
                this.state.username != null &&
                (!this.props.useServerSearch ||
                  this.state.organisationIsSelected)
                  ? user.username != null &&
                    (user.username.includes(this.state.username) ||
                      user.firstName
                        .toLowerCase()
                        .includes(this.state.username.toLowerCase()) ||
                      user.lastName
                        .toLowerCase()
                        .includes(this.state.username.toLowerCase()))
                  : true
            )
            .filter(
              user =>
                this.state.showOnlyActiveUsers ? user.enabled === true : true
            )
            .map((row) => (
              <TR key={row.id + "_user"}>
                <TD
                  ellipsis
                  pointer
                  width={arrWidth[0]}
                  onClick={() => props.onUserClick(row.id)}
                >
                  <ClickAbleTitle title={row.username}>
                    {row.username}
                  </ClickAbleTitle>
                </TD>

                <TD ellipsis width={arrWidth[1]}>
                  {row.firstName + " " + row.lastName}
                </TD>
                <TD ellipsis width={arrWidth[2]}>
                  {row.phoneNumbers[0].phone}
                </TD>
                <TD ellipsis width={arrWidth[3]}>
                  {row.email}
                </TD>
                <TD ellipsis width={arrWidth[4]}>
                  {row.organisationName}
                </TD>
                <TD
                  key={row.id + "_checkbox"}
                  ellipsis
                  width={arrWidth[5]}
                  center
                >
                  <CheckBox disabled center checked={row.enabled} />
                </TD>

                <TD ellipsis width={arrWidth[6]}>
                  <RoleTipContiner>
                    {this.props.roles(row.rolesAbbr)}

                    <RoleTip>
                      {this.props.roleMouseOverText(row.rolesAbbr)}
                    </RoleTip>
                  </RoleTipContiner>
                </TD>

                <TD pointer width={arrWidth[7]} center>
                  <img
                    src="/icons/ic-edit-black-24-px.svg"
                    onClick={() => props.onUserClick(row.id)}
                    alt={"edit"}
                  />
                </TD>
              </TR>
            ))
        : [];

    const hideUserList = !(props.data != null && props.data.length > 0);

    return (
      <FlexContainer column margin={[0, 0, 16, 0]}>
        <SearchForm
          {...props}
          errorMessage={this.state.errorMessage}
          organisationList={props.organisationList}
          t={t}
          searchButtonIsActive={
            this.state.organisationIsSelected || this.props.useServerSearch
          }
          onSelectOrganisation={values => {
            this.setState({ loading: true }, () => {
              this.props.onSelectOrganisation(values).finally(() =>
                this.setState({
                  organisationIsSelected: !!values,
                  loading: false
                })
              );
            });
          }}
          onIsActiveChange={() =>
            this.setState({
              showOnlyActiveUsers: !this.state.showOnlyActiveUsers
            })
          }
          initialValues={{
            organisation:
              props.selectedOrganisation != null &&
              this.state.organisationIsSelected
                ? {
                    label: props.selectedOrganisation.name,
                    value: props.selectedOrganisation.id
                  }
                : undefined
          }}
          onSubmit={values => {
              if (values.organisation === undefined && values.username === undefined) {
                  this.setState({errorMessage: true})
              }
            else if (this.props.useServerSearch && !this.state.organisationIsSelected) {
                  this.setState({errorMessage: false})
              this.props.userSearchOnServer(values.username);
            } else if ((this.props.showOrgBox && values.organisation != null) || !this.props.showOrgBox) {
                  this.setState({
                      username: values.username,
                      errorMessage: false
                  });
              }
          }}
          onClearSearch={(onlyResetUserName = false) => {
            if (this.props.showOrgBox && !onlyResetUserName) {
              this.setState({
                username: undefined,
                showOnlyActiveUsers: true,
                organisationIsSelected: false
              });
              this.props.clearUserList();
            } else
              this.setState({ username: undefined, showOnlyActiveUsers: true });
          }}
        />
        {hideUserList ? (
          this.state.loading ? (
            <Loader />
          ) : (
            ""
          )
        ) : (
          <PaginatedUserList
            data={userRows}
            tableHeaderLabels={thLabels}
            forceUpdate={true}
            tableHeaderProps={headerProps}
            t={t}
          />
        )}
      </FlexContainer>
    );
  }
}

export default UserAdmin;
