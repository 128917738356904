import connect from "react-redux/es/connect/connect";
import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { NotificationViewer } from "./NotificationViewer";
import Button from "../components/Button";
import styled from "styled-components";
import { getISOTimeFromDateAndTime } from "../insiderTool/UTCUtils";
import { getResponsiblePersonsFromValues } from "./ResponsiblePersonUtils";

const PreviousPage = styled.div``;

const Icon = styled.img`
  cursor: pointer;
  padding: 0px 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;

const TextButton = styled.span`
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export class ConfirmNotification extends React.Component {
  render() {
    const { t, organisationId, isSuperUser } = this.props;

    const createNotificationObject = () => {
      const formattedValues = {};
      let values = this.props.formValues;

      const nonNullValues = key => values[key] != null;
      const toObject = (acc, key) => ({ ...acc, [key]: values[key] });

      values = Object.keys(values)
        .filter(nonNullValues)
        .reduce(toObject, {});

      const responsiblePersons = getResponsiblePersonsFromValues(values);

      formattedValues["organisationId"] = isSuperUser
        ? values.organisationId && values.organisationId.value
          ? values.organisationId.value
          : values.organisationId
        : organisationId;
      formattedValues["code_name"] = values.code_name;
      formattedValues["decisionmakers"] = responsiblePersons;

      if (values.unknownIssuer && values.unknownIssuer === true) {
        formattedValues["issuer"] = values.issuer;
      } else {
        if (values.issuerId && values.issuerId.value) {
          formattedValues["issuerId"] = values.issuerId.value;
          formattedValues["issuer"] = values.issuerId.label;
        }
      }
      formattedValues["text"] = values.text;
      formattedValues["submitter"] = {
        firstName: values.responsibleFirstName
          ? values.responsibleFirstName
          : null,
        lastName: values.responsibleLastName
          ? values.responsibleLastName
          : null,
        email: values.responsibleEmail ? values.responsibleEmail : null,
        phone: values.responsiblePhoneNumber
          ? values.responsiblePhoneNumber
          : null,
        title: values.responsiblePosition ? values.responsiblePosition : null
      };
      formattedValues["ref"] = values.newsMessageReference;
      formattedValues["refNO"] = values.newsMessageReferenceNo;
      formattedValues["decisionTime"] = getISOTimeFromDateAndTime(
        values.fromDate,
        values.responsible_time
      );
      formattedValues["source"] = 1;
      return formattedValues;
    };

    return (
      <FlexContainer margin={[0, 0, 20, 0]}>
        <NotificationViewer
          {...this.props}
          notificationObject={createNotificationObject()}
        />
        <FlexContainer row vAlignCenter spaceBetween>
          <FlexContainer width={"auto"}>
            <PreviousPage onClick={this.props.onBack}>
              <Icon src="/icons/back-arrow-24-px.svg" />
              <TextButton>{t("edit_message")}</TextButton>
            </PreviousPage>
          </FlexContainer>
          <FlexContainer width={"auto"} row vAlignCenter>
            <FlexContainer row flexEnd vAlignCenter margin={[0, 24, 0, 0]}>
              <Button.Standard
                onClick={() =>
                  this.props.saveNotification(createNotificationObject())
                }
              >
                {this.props.t("send_notification_to_oslo_bors")}
              </Button.Standard>
            </FlexContainer>
            <Button.Text onClick={this.props.onCancel}>
              {this.props.t("cancel")}
            </Button.Text>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { form } = state;

  return {
    formValues: form.notificationForm ? form.notificationForm.values : {}
  };
}

export default connect(mapStateToProps)(ConfirmNotification);
