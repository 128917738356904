export const validatePassword = (values, props, twoFactorEnabled) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );
  const requiredFieldsCheck = () => {
    if (values.repeat_password == null) {
      errors.repeat_password = props.t("required_field");
    }
    if (values.password == null) {
      errors.password = props.t("required_field");
    }
    if (values.password !== values.repeat_password) {
      errors.password = props.t("Ulike passord");
    }
    if (twoFactorEnabled && values.twoFactorPhone == null) {
      errors.twoFactorPhone = props.t("required_field");
    }
    if (twoFactorEnabled && values.receivedCode == null) {
      errors.receivedCode = props.t("required_field");
    }
  };

  const passwordRequirement = () => {
    //only numeric
    if (
      values.password != null &&
      /^\d+$/i.test(values.password) &&
      values.password.length > 0
    ) {
      errors.password = props.t("only_numeric");
    }

    if (values.password != null && values.password.length < 10) {
      errors.password = props.t("min_signs");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.twoFactorPhone &&
      !/^\+[0-9]+|[0-9]+/i.test(values.twoFactorPhone) &&
      values.twoFactorPhone.length > 0
    ) {
      errors.twoFactorPhone = props.t("invalid_phonenumber");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();
  passwordRequirement();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateNumber = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );
  const requiredFieldsCheck = () => {
    if (!values.twoFactorPhone) {
      errors.twoFactorPhone = props.t("required_field");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.twoFactorPhone &&
      !/^\+[0-9]+|[0-9]+/i.test(values.twoFactorPhone) &&
      values.twoFactorPhone.length > 0
    ) {
      errors.twoFactorPhone = props.t("invalid_phonenumber");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};
