import React from "react";
import { FlexContainer } from "../../components/FlexContainer";
import InsiderDocumentation from "./InsiderDocumentation";
import Button from "../../components/Button";
import InsiderListList from "./InsiderListList";
import { withTranslation } from "react-i18next";
import Input from "../../components/Input";
import { dateGenerator, uuidGenerator } from "../InsiderToolContainer";
import { connect } from "react-redux";
import {
  createConfidentialListData,
  createInsiderListData,
  fetchAllInsiders,
  fetchInsiderListList,
  InsiderType
} from "../InsiderToolActions";
import Loader, { OverlayLoader } from "../../components/Loader";
import { Modal } from "../../components/Modal";
import { CreateNewListModal } from "../modals/insiderListList/CreateNewListModal";
import { TooltipWrapper } from "../components";
import { Tooltip } from "../../components/Tooltip";
import { ActionText } from "../../components/ActionText";
import { getGlobalSubject } from "../../obsvcClient";

export const LIST_ACTIVE = "active";
export const LIST_CLOSED = "closed";

class InsiderListHome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showNewInsiderListModal: false,
      showNewConfidentialityListModal: false,
      showOverlayLoader: false,
      showLoader: true,
      filteredListIdsMap: null,
      searchValue: ""
    };
  }

  onNewList = insiderType => () => {
    const { dispatch, organsationName, history, base } = this.props;
    const id = uuidGenerator();
    const dateData = dateGenerator();

    const insiderlist_for = organsationName;
    const createListFnc =
      InsiderType.LIST === insiderType
        ? createInsiderListData
        : createConfidentialListData;

    Promise.resolve(this.setState({ loading: true }))
      .then(() =>
        dispatch(
          createListFnc(id, {
            ...dateData,
            code_name: "LIST_" + dateData.created,
            insiderlist_for,
            listState: LIST_ACTIVE,
            createdBy: getGlobalSubject()
          })
        )
      )
      .then(
        () =>
          new Promise(resolve =>
            this.setState(
              {
                loading: false,
                showNewInsiderListModal: false,
                showNewConfidentialityListModal: false
              },
              resolve
            )
          )
      )
      .then(() => history.push(`${base}/${insiderType}/${id}`))
      .then(() => dispatch(fetchInsiderListList()))
      .catch(e => console.log(e));
  };

  onInsiderListClick = (id, listType) => {
    const { history, base } = this.props;

    if (listType === InsiderType.PERMANENT_INSIDERS) {
      history.push(`${base}/${listType}`);
    } else {
      history.push(`${base}/${listType}/${id}`);
    }
  };

  onInsiderSearch = () => {
    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() => this.props.dispatch(fetchAllInsiders()))
      .then(data =>
        data.entries
          .filter(user =>
            `${user.data.first_name || ""} ${user.data.surname || ""}`
              .toLowerCase()
              .includes((this.searchInput.value || "").toLowerCase())
          )
          .map(listData => [listData.ownerId, true])
      )
      .then(filteredListIds => {
        this.setState({
          showOverlayLoader: false,
          filteredListIdsMap: new Map(filteredListIds)
        });
      });
  };

  filterInsiderList = list =>
    this.state.filteredListIdsMap === null ||
    this.state.filteredListIdsMap.get(list.listId)
      ? true
      : false;

  clearSearch = _ => {
    this.setState(
      { filteredListIdsMap: null },
      _ => (this.searchInput.value = "")
    );
  };

  componentDidMount() {
    this.props
      .dispatch(fetchInsiderListList())
      .then(() => this.setState({ showLoader: false }))
      .catch(e => console.log(e));
  }

  render() {
    const { t, insiderListList, publicProps } = this.props;

    const filteredInsiderListList = insiderListList.filter(
      this.filterInsiderList
    );

    if (this.state.showLoader) return <Loader />;

    const insiderListCreationIsInactive =
      publicProps &&
      publicProps["insidertool.global.createInsiderListDisabled"] === "true";

    return (
      <React.Fragment>
        {this.state.showOverlayLoader ? <OverlayLoader /> : ""}

        <FlexContainer column>
          <InsiderDocumentation t={this.props.t} />

          <FlexContainer spaceBetween row margin={[30, 0, 30, 0]}>
            <FlexContainer fitToContent row>
              <Button.Standard
                onClick={() => {
                  if (!insiderListCreationIsInactive) {
                    this.setState({ showNewInsiderListModal: true });
                  }
                }}
                margin={[0, 16, 0, 0]}
                inactive={insiderListCreationIsInactive}
              >
                {t("create_insider_list")}
              </Button.Standard>
              <Button.Standard
                inverted
                onClick={() => {
                  if (!insiderListCreationIsInactive) {
                    this.setState({ showNewConfidentialityListModal: true });
                  }
                }}
                inactive={insiderListCreationIsInactive}
              >
                {t("new_insider_project_list")}
              </Button.Standard>
            </FlexContainer>

            <FlexContainer column fitToContent width="auto">
              <FlexContainer fitToContent width={"auto"} row>
                <Input
                  background={"rgba(238, 241, 246, 0.5)"}
                  width={"345px"}
                  name="insiderSearch"
                  onKeyDown={e =>
                    e.keyCode === 13 ? this.onInsiderSearch() : undefined
                  }
                  ref={x => (this.searchInput = x)}
                  greyPlaceholder
                  placeholder={t("search_for_persons")}
                />

                <TooltipWrapper>
                  <Button.Standard
                    helpCursor
                    notRounded
                    onClick={this.onInsiderSearch}
                  >
                    {t("search")}
                  </Button.Standard>
                  <Tooltip>{t("it_search_persons_help")}</Tooltip>
                </TooltipWrapper>
              </FlexContainer>
              {this.state.filteredListIdsMap != null ? (
                <ActionText onClick={this.clearSearch}>
                  {t("reset_search")}
                </ActionText>
              ) : (
                ""
              )}
            </FlexContainer>
          </FlexContainer>

          <InsiderListList
            data={filteredInsiderListList}
            onInsiderListClick={this.onInsiderListClick}
            userList={this.props.userList}
            userMap={this.props.userMap}
            history={this.props.history}
            base={this.props.base}
            filterMap={this.props.filterMap}
            updateFilterMap={this.props.updateFilterMap}
          />
        </FlexContainer>

        <Modal
          header={t("create_insider_list")}
          isOpen={this.state.showNewInsiderListModal}
          onClose={() =>
            this.setState({
              showNewInsiderListModal: false
            })
          }
        >
          <CreateNewListModal
            t={t}
            onSubmit={this.onNewList(InsiderType.LIST)}
            onSubmitLabel={"create_insider_list"}
            onCancel={() => {
              this.setState({
                showNewInsiderListModal: false
              });
            }}
            listType={InsiderType.LIST}
          />
        </Modal>
        <Modal
          header={t("new_insider_project_list")}
          isOpen={this.state.showNewConfidentialityListModal}
          onClose={() =>
            this.setState({
              showNewConfidentialityListModal: false
            })
          }
        >
          <CreateNewListModal
            t={t}
            onSubmit={this.onNewList(InsiderType.CONFIDENTIALITY_LIST)}
            onSubmitLabel={"new_insider_project_list"}
            onCancel={() => {
              this.setState({
                showNewConfidentialityListModal: false
              });
            }}
            listType={InsiderType.CONFIDENTIALITY_LIST}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loginUser, insiderToolReducer, systemAdministratorReducer } = state;

  return {
    user: loginUser.user,
    organsationName: loginUser.userOrganisation.name,
    insiderListList: insiderToolReducer.insiderListList.list,
    userList: insiderToolReducer.userList.list,
    userMap: insiderToolReducer.userList.map,
    publicProps: systemAdministratorReducer.publicProps.object || {}
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderListHome)
);
