import React from "react";
import styled from "styled-components";

const OptionItem = styled.div`
  cursor: pointer;
  background-color: white;
  &:hover {
    background-color: rgba(178, 207, 174, 0.2);
  }
  padding: 0px 7px 0px 7px;
  margin-top: 10px;
  color: black;
  font-family: "Open sans", sans-serif;
  font-size: 16px;
  text-align: left;
`;

const SelectOptionsContainerContent = styled.div`
  position: fixed;
  background-color: white;
  width: 300px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: block;
  margin: 30px 0 0 -10px;
  padding-bottom: 7px;
  font-weight: 300;
  top: ${props => (props.top ? props.top + "px" : "0px")};
  left: ${props => (props.left ? props.left + "px" : "0px")};
  cursor: pointer;
`;

export const SelectOptionsContainer = props => {
  const {
    t,
    markAllNotNotified,
    markAllNotConfirmed,
    markAll,
    markAllActive,
    markAllInactive,
    deselectAll,
    left,
    top
  } = props;

  return (
    <SelectOptionsContainerContent left={left} top={top}>
      <OptionItem onClick={markAllNotNotified}>
        {t("it_mark_all_not_notified")}
      </OptionItem>
      <OptionItem onClick={markAllNotConfirmed}>
        {t("it_mark_all_not_confirmed")}
      </OptionItem>
      <OptionItem onClick={markAll}>{t("it_mark_all_persons")}</OptionItem>
      <OptionItem onClick={markAllActive}>{t("it_mark_all_active")}</OptionItem>
      <OptionItem onClick={markAllInactive}>
        {t("it_mark_all_inactive")}
      </OptionItem>
      <OptionItem onClick={deselectAll}>{t("it_deselect_all")}</OptionItem>
    </SelectOptionsContainerContent>
  );
};
