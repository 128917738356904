import { updateData } from "./CommonActions";
import { postData } from "./CommonActionsNoObsvc";

export const MFAActions = {
  registerNumber: "REGISTER_PHONENUMBER",
  receiveOneTimeCode: "RECEIVE_ONE_TIME_CODE",
  verifyOneTimeCode: "VERIFY_ONE_TIME_CODE",
  validationFail: "VALIDATION_FAILED",
  verifySuccess: "VERIFICATION_SUCCESS",
  verifyFail: "VERIFICATION_FAILED",
};

export const User = {
  request: "REQUEST_PROFILE",
  postSuccess: "RECEIVE_PROFILE",
  postFail: "RECEIVE_PROFILE_FAILED",
  clear: "CLEAR_USER",
};

export const resetUserData = () => {
  return { type: User.clear };
};

export const fetchUser = (globalSubject) =>
  postData(
    User,
    undefined,
    {
      params: { globalSubject },
    },
    "v1/sysadmin/loginPerson/get"
  );

export const UpdateUserMessages = {
  update: "UPDATE_USER_INFO",
  updateFail: "UPDATE_USER_INFO_FAILED",
  updateSuccess: "UPDATE_USER_INFO_SUCCESS",
};

export const updateUser = (userData, onSuccess) =>
  updateData(
    {
      ...UpdateUserMessages,
      params: { ...userData },
      obsvcFilename: "admin/updateUser.obsvc",
    },
    onSuccess
  );

export const Credentials = {
  request: "UPDATE_MFA",
  postSuccess: "UPDATE_MFA",
  postFail: "UPDATE_MFA_FAILED",
};

/*
ChangeType description:
PASSWORD => 1,
TWO_FACTOR_INITIAL_UPDATE => 2,
TWO_FACTOR_CODE_SEND => 10,
TWO_FACTOR_NUMBER_UPDATE => 11;
*/
export const updateCredentials = (
  globalSubject,
  code,
  country,
  phone,
  protocol,
  resetSecret,
  changeType,
  oldPassword,
  newPassword
) =>
  postData(
    Credentials,
    undefined,
    {
      globalSubject,
      resetSecret,
      changeType,
      newCredentials: {
        phone,
        country,
        protocol,
        code,
        password: newPassword,
      },
      oldCredentials: {
        password: oldPassword,
      },
    },
    "v1/authn/twoFactor/updateCredentials"
  );

