import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FlexContainer } from "../components/FlexContainer";
import {PageHeading} from "../components/PageHeading";
import {reduxForm} from "redux-form";
import Input from "../components/Input";
import Button from "../components/Button";
import styled from "styled-components";
import {fetchIssuanceDetails, postComment, updateIssuance} from "./ShareIssuanceActions";
import {getOBNTDateTime, hasValue} from "../util/common";
import Textarea from "../components/Textarea";
import Label from "../components/Label";
import {OverlayLoader} from "../components/Loader";
import Shiitake from "shiitake";
import Link from "../components/Link";

export const Text = styled.p`
  font-size: ${props => (props.fontSize ? props.fontSize + "px;" : "16px;")};
    font-weight: ${props => (props.bold ? "600;" : "100;")};
    ${(props) => props.margin ? "margin: " +
                    props.margin.reduce((acc, val) => (acc += val + "px "), "") + ";" : ""}
    ${props => (props.test ? "border: 1px solid red;" : "")};
`;

const Comment = styled.div`
    ${props => (props.border ? "border-top: #eaeaea 1px solid;" : "")};
    ${props => (props.test ? "border: 1px solid blue;" : "")};
    ${props => (props.superBubble ?  //TO THE LEFT
            // "width: 60%;" +
            "padding-left: 15px;" +
            // "text-align: right;" +
            "justify-content: left;" +
            "margin-left: 5px;" +
            "background-color: rgba(178, 207, 174, 0.2);" +
            "border: solid 1px #eaeaea;" 
            // "border-radius: 20px;" 
            : "")};
    ${props => (props.userBubble ? //TO THE RIGHT
            // "width: 60%;" +
            "padding-left: 15px;" +
            "text-align: left;" +
            "margin-left: 5px;" +
            "background-color: rgba(199, 211, 227, 0.3);" +
            "border: solid 1px #eaeaea;" 
            // "border-radius: 20px;"
            : "")};
`;

const CommentBody = styled.div`
  white-space: pre-wrap;
  padding: 0 0 10px 0;
`;

class ChatBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newComment: "",
            loading: false,
            commentExpanded: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps?.comments?.length !== this.props?.comments?.length) {
            this.scrollToBottom();
        }
    }


    scrollToBottom = () => {
        const { commentList } = this.refs;
        commentList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    render() {
        const { t } = this.props;

        const addComment = () => {
            if (hasValue(this.props.id)) {
                this.setState({loading: true}, () => {
                    this.props.dispatch(postComment(this.state.newComment, this.props.id))
                        .then(this.props.fetchIssuanceDetails(this.props.id))
                        .finally(() => this.setState({newComment: "", loading: false}));
                })
            }
        }

        const getDisplayUsername = () => {
            return this.props.isSuperUser? "OSLO BØRS" :
                this.props.currentUserInfo?.username.toUpperCase();
        }

        const isSuperUserComment = (info) => {
            return info.internal;
        }

        return (
            <FlexContainer  margin={[20, 10, 10, 10]} >
                <PageHeading margin={[10, 0, 10, 0]}>
                    {t("share_communication")}
                </PageHeading>

                {this.state.loading && <OverlayLoader/>}

                <FlexContainer center>
                    {this.props.comments?.length > 0 && <FlexContainer border maxHeight="400" height="100%" scrollbarsOnVerticalOverflow>
                        {this.props.comments.map((info, index) => {
                            return (
                                <FlexContainer key={index} width="99%" ref="commentList">
                                    {this.props.simple ?
                                        <FlexContainer
                                                       textAlign={isSuperUserComment(info) ? "right" : "left"}>
                                            <Comment border={index!==0} >
                                                <FlexContainer width={"90%"} right={isSuperUserComment(info)} >
                                                    <Text margin={[5,0,2,0]}  bold >{info.displayUser.toUpperCase()}</Text>
                                                    <Text margin={[3,0,0,0]} fontSize={"13"}>{getOBNTDateTime(info.createdTime)}</Text>
                                                    <Text>{info.comment}</Text>
                                                </FlexContainer>
                                            </Comment>
                                        </FlexContainer> :

                                        <FlexContainer
                                            margin={[5,5,5,5]}
                                        >
                                            <Comment superBubble={true} userBubble={!isSuperUserComment(info)} >
                                                <FlexContainer>
                                                    <Text margin={[10,0,2,0]}  bold >{info.displayUser.toUpperCase()}</Text>
                                                    <Text margin={[3,0,0,0]} fontSize={"13"}>{getOBNTDateTime(info.createdTime)}</Text>

                                                    <CommentBody>
                                                        {!this.state.commentExpanded.includes(index) ? (
                                                            <FlexContainer>
                                                                <Shiitake
                                                                    lines={5}
                                                                    overflowNode={
                                                                        <span>
                                                                            ...
                                                                            <Link
                                                                                margin={[0, 0, 0, 5]}
                                                                                onClick={() => {
                                                                                    const newCommentExpanded = this.state.commentExpanded;
                                                                                    newCommentExpanded.push(index);
                                                                                    this.setState({ commentExpanded: newCommentExpanded });
                                                                                }}
                                                                            >
                                                                              {t("show_more")}
                                                                            </Link>
                                                                          </span>
                                                                    }
                                                                >
                                                                    {info.comment}
                                                                </Shiitake>
                                                            </FlexContainer>
                                                        ) : (
                                                            <FlexContainer column>
                                                                {info.comment}
                                                                <Link
                                                                    margin={[5, 0, 0, 0]}
                                                                    onClick={() => {
                                                                        const newCommentExpanded = this.state.commentExpanded;
                                                                        newCommentExpanded.splice(newCommentExpanded.indexOf(index), 1);
                                                                        this.setState({ commentExpanded: newCommentExpanded });
                                                                    }}
                                                                >
                                                                    {t("show_less")}
                                                                </Link>
                                                            </FlexContainer>
                                                        )}
                                                    </CommentBody>
                                                </FlexContainer>
                                            </Comment>
                                        </FlexContainer>

                                    }
                                </FlexContainer>
                            )
                        })}
                    </FlexContainer>}
                    {
                        this.props.data?.status !== 2 &&
                            <FlexContainer border margin={[20,0,0,0]} >
                                <Label bold margin={[15,0,5,30]}>{getDisplayUsername()}</Label>
                                <FlexContainer trueCenter>
                                    <Textarea  width={"95%"} margin={[5,5,5,5]} value={this.state.newComment}
                                               onChange={(e) => this.setState({newComment: e.target.value})}
                                              noResize {...this.props}/>
                                    <FlexContainer borderTop width={"90%"}/>
                                </FlexContainer>

                                <FlexContainer row flexEnd vAlignCenter padding={[10,25,10,10]}>
                                    <Button.Standard
                                        onClick={addComment}>
                                        {t("share_comment_add")}
                                    </Button.Standard>
                                </FlexContainer>
                            </FlexContainer>
                    }

                </FlexContainer>
            </FlexContainer>
        );
    }
}

ChatBox = reduxForm({
    form: "ChatBox",
})(ChatBox);

ChatBox.propTypes = {
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const {  } = state;

    return {

    };
}
export default connect(mapStateToProps)(
    withTranslation("translations")(ChatBox)
);
