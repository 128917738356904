import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Modal } from "../../components/Modal";
import { FlexContainer } from "../../components/FlexContainer";
import { reduxForm } from "redux-form";
import {
  fetchInsiderList,
  fetchInsiderListList,
  InsiderType,
  updateInsiderListData
} from "../InsiderToolActions";
import { OverlayLoader } from "../../components/Loader";
import { ListComponentTemplate } from "./ListComponentTemplate";

export class InsiderListInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEdit: false,
      showOverlayLoader: false
    };
  }

  isVisibleInView = field => ["insiderlist_metadata"].includes(field);
  isVisibleInEdit = field => this.isVisibleInView(field);

  toggleEditModal = () =>
    this.setState({
      showEdit: !this.state.showEdit
    });

  onSubmit = values => {
    const { listId, dispatch, listType } = this.props;

    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() =>
        dispatch(
          updateInsiderListData(
            InsiderType.LIST,
            {
              ...values,
              type: listType,
              updated: this.props.insiderListInfo.data.updated
            },
            listId
          )
        )
      )
      .then(() => dispatch(fetchInsiderList(listId)))
      .then(() => dispatch(fetchInsiderListList()))
      .then(this.toggleEditModal)
      .then(() => this.setState({ showOverlayLoader: false }));
  };

  render() {
    const { t, insiderListInfo, listType, hasEditAccess } = this.props;

    const initalValues = {
      insiderlist_for: insiderListInfo.data
        ? insiderListInfo.data.insiderlist_for
        : "",
      code_name: insiderListInfo.data ? insiderListInfo.data.code_name : "",
      created: insiderListInfo.data ? insiderListInfo.data.created : "",
      type: insiderListInfo.data ? insiderListInfo.data.type : "",
      listState: insiderListInfo.data ? insiderListInfo.data.listState : "",
      createdBy: insiderListInfo.data ? insiderListInfo.data.createdBy : ""
    };

    return (
      <FlexContainer row>
        {this.state.showOverlayLoader ? <OverlayLoader /> : ""}

        <InsiderListInformationView
          initialValues={initalValues}
          listType={listType}
          onEdit={this.toggleEditModal}
          isVisible={this.isVisibleInView}
          disableEditMode={
            hasEditAccess === false ||
            this.props.insiderListInfo.data.listState === "closed"
          }
        />

        <Modal
          width={"65%"}
          key={"modalInsiderInfo"}
          isOpen={this.state.showEdit}
          onClose={this.toggleEditModal}
          header={t("it_edit_insiderlist_info")}
        >
          <InsiderListInformationEdit
            onSubmit={this.onSubmit}
            initialValues={initalValues}
            listType={listType}
            onClose={this.toggleEditModal}
            isVisible={this.isVisibleInEdit}
            inEditMode={true}
          />
        </Modal>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;
  const inHistoricMode = insiderToolReducer.insiderList.historicMode;

  return {
    insiderListInfo: insiderToolReducer.insiderList.insiderListInfo,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    hasEditAccess:
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode,
    userMap: insiderToolReducer.userList.map
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderListInformation)
);

class InsiderListInformationView extends React.Component {
  render() {
    return <ListComponentTemplate {...this.props} />;
  }
}
InsiderListInformationView = reduxForm({
  form: "insiderListInformationView",
  enableReinitialize: true
})(withTranslation("translations")(InsiderListInformationView));

class InsiderListInformationEdit extends React.Component {
  render() {
    return (
      <ListComponentTemplate
        {...this.props}
        onSubmit={this.props.handleSubmit(this.props.onSubmit)}
      />
    );
  }
}
InsiderListInformationEdit = reduxForm({
  form: "insiderListInformationEdit",
  enableReinitialize: true
})(withTranslation("translations")(InsiderListInformationEdit));
