import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { Field, reduxForm } from "redux-form";

import Table, { TD, TR } from "../components/Table";
import SearchableSelect from "../components/SearchableSelect";
import { HeaderPanel } from "../components/HeaderPanel";
import Label from "../components/Label";
import SelectionBoxes from "../components/SelectionBoxes";
import Button from "../components/Button";
import Input from "../components/Input";
import CheckBox from "../components/CheckBox";
import {
  OrganisationActions,
  RoleTip,
  RoleTipContiner
} from "./AdminContainer";
import Loader from "../components/Loader";
import { PageHeading } from "../components/PageHeading";
import { ErrorText } from "../components/ErrorText";
import { NotificationManager } from "react-notifications";
import { WarningText } from "../components/FileUploader";

const IsActiveBox = props => (
  <CheckBox noWrap checked={props.showOnlyActiveOrganisations} {...props} />
);

const OrganisationDropdownBox = props => (
  <FlexContainer column margin={[0, 0, 38, 0]}>
    <Label htmlFor={props.name}>{props.label}</Label>
    <FlexContainer id={props.name} row marginTop={10}>
      <FlexContainer maxWidth={373}>
        <SearchableSelect {...props} />
      </FlexContainer>
      <IsActiveBox
        margin={[8, 0, 0, 15]}
        label={props.t("show_only_active_organisations")}
        onChange={props.onIsActiveChange}
        showOnlyActiveOrganisations={props.showOnlyActiveOrganisations}
      />
      <Button.Standard
        margin={[0, 16, 0, 20]}
        onClick={props.onFetchOrganisation}
        inverted
      >
        {props.t("fetch")}
      </Button.Standard>
      <Button.Standard onClick={props.onNewAction}>
        {props.t("new")}
      </Button.Standard>
    </FlexContainer>
  </FlexContainer>
);

const InputBox = props => {
  return (
    <FlexContainer column maxWidth={472} {...props}>
      <Label htmlFor={props.input.name}>{props.label}</Label>
      <Input border id={props.input.name} {...props.input} />
    </FlexContainer>
  );
};

const SelectionBoxesContainer = props => (
  <FlexContainer column margin={[0, 0, 24, 0]}>
    <Label htmlFor={props.name}>{props.t("company")}</Label>
    <SelectionBoxes singleSelect {...props} />
  </FlexContainer>
);

class OrganisationForm extends React.Component {
  render() {
    const { t, companySourceList, companyTargetList } = this.props;

    return (
      <form>
        <FlexContainer>
          <HeaderPanel marginBottom={16} noTextTransform>
            {t("organisation")}
            {!this.props.isActive ? (
              <ErrorText fontSize="16px">
                {t("organisation_deactivated")}
              </ErrorText>
            ) : (
              ""
            )}
          </HeaderPanel>
          <FlexContainer row>
            <FlexContainer column margin={[0, 0, 16, 0]}>
              <Field component={InputBox} name={"name"} label={t("name")} />
              <Field
                margin={[16, 0, 16, 0]}
                component={InputBox}
                name={"loginNamePrefix"}
                label={t("loginPrefix")}
              />
              <Field
                component={CheckBox}
                useReduxCheckState={true}
                name="internal"
                label={t("internal_organisation")}
                fitToContent
              />
            </FlexContainer>
            <FlexContainer>
              <FlexContainer column maxWidth={472} {...this.props}>
                <Label>{t("organisationId")}</Label>
                <FlexContainer
                  border
                  minHeight={35}
                  padding={[0, 0, 0, 16]}
                  center
                >
                  {this.props.initialValues.id}
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <Field
            component={SelectionBoxesContainer}
            name={"companies"}
            sourceId={
              this.props.organisation != null ? this.props.organisation.id : -1
            }
            selectionArray={companySourceList}
            targetArray={companyTargetList}
            t={t}
          />

          <FlexContainer row flexEnd vAlignCenter margin={[0, 0, 24, 0]}>
            <Button.Standard
              inactive={this.props.pristine}
              onClick={this.props.handleSubmit}
              margin={[0, 24, 0, 0]}
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={this.props.onCancel}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </FlexContainer>
      </form>
    );
  }
}

OrganisationForm = reduxForm({
  form: "organisation"
})(OrganisationForm);

class Organisation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewOrganisation: false,
      showOrganisationBox: false,
      selectedOrganisation: undefined,
      showLoader: false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.selectedOrganisation) {
      const { selectedOrganisation } = this.state;
      const potentialNewOrg = this.props.organisationList.find(
        org => org.value === selectedOrganisation.value
      );

      if (
        potentialNewOrg &&
        selectedOrganisation.label !== potentialNewOrg.label
      ) {
        this.setState({ selectedOrganisation: potentialNewOrg });
      }
    }
  }

  render() {
    const { t } = this.props;
    const props = this.props;

    const thLabels = [
      t("username"),
      t("name"),
      t("telephone"),
      t("email"),
      t("active"),
      t("roles")
    ];
    const arrWidth = [166, 206, 138, 478, 85, 87];
    const headerProps = [, , , , { center: true }];

    const userRows =
      props.userList != null
        ? props.userList.map((row, index) => (
            <TR key={index}>
              <TD ellipsis width={arrWidth[0]}>
                {row.username}
              </TD>
              <TD ellipsis width={arrWidth[1]}>
                {row.firstName + " " + row.lastName}
              </TD>
              <TD ellipsis width={arrWidth[2]}>
                {row.phoneNumbers[0].phone}
              </TD>
              <TD ellipsis width={arrWidth[3]}>
                {row.email}
              </TD>
              <TD width={arrWidth[4]} center>
                <CheckBox disabled center checked={row.enabled} />
              </TD>
              <TD ellipsis width={arrWidth[5]}>
                <RoleTipContiner>
                  {props.roles(row.rolesAbbr)}
                  <RoleTip>
                    {this.props.roleMouseOverText(row.rolesAbbr)}
                  </RoleTip>
                </RoleTipContiner>
              </TD>
            </TR>
          ))
        : [];

    const showUserList =
      props.userList != null &&
      props.userList.length > 0 &&
      this.state.showOrganisationBox;

    return (
      <FlexContainer column margin={[0, 0, 40, 0]}>
        <OrganisationDropdownBox
          name={"organisation"}
          label={t("organisation")}
          value={this.state.selectedOrganisation}
          options={this.props.organisationList}
          onSelect={org => this.setState({ selectedOrganisation: org })}
          onFetchOrganisation={() => {
            if (!this.state.selectedOrganisation) {
              NotificationManager.error(
                <WarningText>{t("no_organisation_selected")}</WarningText>,
                "",
                10000
              );
            } else {
              this.setState({ showLoader: true });
              this.props
                .onFetchOrganisation(this.state.selectedOrganisation)
                .then(() =>
                  this.setState({
                    showOrganisationBox: true,
                    createNewOrganisation: false,
                    showLoader: false
                  })
                );
            }
          }}
          onNewAction={() => {
            this.setState({
              showOrganisationBox: true,
              createNewOrganisation: true
            });
          }}
          t={t}
          searchable={true}
          placeholder={t("select")}
          forceUpdate
          onIsActiveChange={this.props.onShowActiveOrganisationChange}
          showOnlyActiveOrganisations={this.props.showOnlyActiveOrganisations}
        />

        {this.state.showOrganisationBox ? (
          <OrganisationForm
            {...props}
            companyTargetList={
              this.state.createNewOrganisation
                ? []
                : this.props.companyTargetList
            }
            enableReinitialize={true}
            onSubmit={values => {
              props.onSaveOrganisation(
                values,
                this.state.createNewOrganisation
                  ? OrganisationActions.CREATE_NEW
                  : OrganisationActions.EDIT
              );
              this.setState({
                showOrganisationBox: false,
                createNewOrganisation: false
              });
            }}
            onCancel={() =>
              this.setState({
                showOrganisationBox: false,
                createNewOrganisation: false
              })
            }
            initialValues={
              this.state.createNewOrganisation
                ? { internal: false }
                : { ...props.organisation }
            }
          />
        ) : this.state.showLoader ? (
          <Loader />
        ) : (
          undefined
        )}
        <FlexContainer
          column
          hidden={
            this.state.createNewOrganisation || !this.state.showOrganisationBox
          }
          margin={[20, 0, 0, 0]}
        >
          <FlexContainer row spaceBetween>
            <PageHeading margin={[0, 0, 16, 0]} color={"black"}>
              {t("users")}
            </PageHeading>

            <FlexContainer row width="auto">
              <Button.Standard
                onClick={() => {
                  this.setState(
                    { showLoader: true, showOrganisationBox: false },
                    () =>
                      props.toggleOrgStatus().then(() =>
                        this.setState({
                          showLoader: false,
                          showOrganisationBox: true
                        })
                      )
                  );
                }}
                margin={[0, 10, 0, 0]}
              >
                {this.props.isActive ? t("deactivate") : t("activate")}
              </Button.Standard>
              <Button.Standard onClick={props.newUserAction}>
                {t("new_user")}
              </Button.Standard>
            </FlexContainer>
          </FlexContainer>
          {showUserList ? (
            <Table
              tableRows={userRows}
              forceUpdate={true}
              tableHeaderProps={headerProps}
              tableHeaderLabels={thLabels}
            />
          ) : (
            undefined
          )}
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default Organisation;
