import styled from "styled-components";

export const ErrorText = styled.span`
  color: ${(props) => (props.brighterRed ? "#e69494;" : "#df4747;")};
  white-space: ${(props) => (props.preWrap ? "pre-wrap;" : "nowrap;")};
  font-size: 14px;
  font-family: inherit;
  font-weight: 600;

  ${(props) => (props.fontSize ? "font-size: " + props.fontSize + ";" : "")};
  ${(props) =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};

  ${(props) =>
      props.margin
          ? "margin: " +
          props.margin.reduce((acc, val) => (acc += val + "px "), "") +
          ";"
          : ""}
  
  ${(props) => (props.pointer ? "cursor: pointer;" : "")}
`;
