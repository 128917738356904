import React from "react";
import "../css/insiderTool.css";
import { withTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import InsiderListHome from "./home/InsiderListHome";
import { connect } from "react-redux";
import {
  PERMANENT_ID,
  InsiderType,
  fetchUserList,
  fetchKnownPersons,
  getCountries
} from "./InsiderToolActions";
import InsiderList from "./insiderList/InsiderList";
import { FlexContainer } from "../components/FlexContainer";
import moment from "moment/moment";
import InsiderListHeaderRouting from "./InsiderListHeaderRouting";
import InternalDocumentationHome from "./internalDocumentation/InternalDocumentationHome";
import Loader from "../components/Loader";

export const uuidGenerator = _ =>
  "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const dateGenerator = _ => {
  const now = moment();
  const created = now.toISOString();

  return { created };
};

export const INTERNAL_DOCUMENTATION = "internalDocumentation";

class InsiderToolContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      insiderListListFilterMap: new Map()
    };
  }

  componentWillMount() {
    Promise.resolve(this.setState({ loading: true }))
      .then(() =>
        this.props.dispatch(fetchUserList(this.props.user.organisationId))
      )
      .then(() => this.props.dispatch(fetchKnownPersons()))
      .then(() => this.props.dispatch(getCountries()))
      .catch(e => console.log(e))
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const { base } = this.props;

    if (this.state.loading) return <Loader />;
    return (
      <FlexContainer column margin={[0, 0, 30, 0]}>
        <InsiderListHeaderRouting {...this.props} />
        <Switch>
          <Route
            exact
            path={base}
            render={props => (
              <InsiderListHome
                base={base}
                history={props.history}
                filterMap={this.state.insiderListListFilterMap}
                updateFilterMap={filterMap =>
                  this.setState({ insiderListListFilterMap: filterMap })
                }
              />
            )}
          />
          <Route
            path={`${base}/${InsiderType.LIST}/:guid`}
            render={props => {
              const guid = props.match.params.guid;

              return <InsiderList listId={guid} type={InsiderType.LIST} />;
            }}
          />
          <Route
            path={`${base}/${InsiderType.CONFIDENTIALITY_LIST}/:guid`}
            render={props => {
              const guid = props.match.params.guid;

              return (
                <InsiderList
                  listId={guid}
                  type={InsiderType.CONFIDENTIALITY_LIST}
                />
              );
            }}
          />

          <Route
            path={`${base}/${InsiderType.PERMANENT_INSIDERS}`}
            render={_ => {
              return (
                <InsiderList
                  listId={PERMANENT_ID}
                  type={InsiderType.PERMANENT_INSIDERS}
                />
              );
            }}
          />
          <Route
            path={`${base}/${INTERNAL_DOCUMENTATION}`}
            render={props => {
              return (
                <InternalDocumentationHome
                  {...props}
                  base={`${base}/${INTERNAL_DOCUMENTATION}`}
                />
              );
            }}
          />
        </Switch>
      </FlexContainer>
    );
  }
}
function mapStateToProps(state) {
  const { loginUser } = state;

  return {
    user: loginUser.user
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderToolContainer)
);
