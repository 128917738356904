export const validatePassword = (values, props, requiredFieldsList) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredFieldsCheck = () => {
    if (requiredFieldsList) {
      requiredFieldsList.forEach((field) => {
        if (values[field] == null || values[field].length < 1) {
          errors[field] = props.t("required_field");
        }
      });
    }
  };

  const differentPasswords = () => {
    if (values.newPassword !== values.repeatPassword) {
      errors.repeatPassword = props.t("passwords_does_not_match");
    }
  };

  requiredFieldsCheck();
  differentPasswords();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};
