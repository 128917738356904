import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import "../css/pagination.css";
import { MAX_NUM_MESSAGES } from "../util/common";

const CenterText = styled.div`
  display: flex;
  padding: 0 0 20px 0;
  justify-content: center;
`;

function WithPagination(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.maxResultOnPage = 10;
      this.pageRangeDisplayed = 4;

      this.state = {
        activePage: 1
      };

      this.keyHandler = this.keyHandler.bind(this);
      this.getLastPage = this.getLastPage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
      // Reset pagination when we receive new data.
      //this.setState({ activePage: 1 });
    }

    getLastPage() {
      return this.props.data != null
        ? this.props.data.length / this.maxResultOnPage
        : 1;
    }

    keyHandler(e) {
      if (
        e.keyCode === 39 &&
        e.shiftKey &&
        this.state.activePage < this.getLastPage()
      ) {
        this.setState({ activePage: this.state.activePage + 1 });
      } else if (e.keyCode === 37 && e.shiftKey && this.state.activePage > 1)
        this.setState({ activePage: this.state.activePage - 1 });
    }
    componentDidMount() {
      window.addEventListener("keydown", this.keyHandler);
    }
    componentWillUnmount() {
      window.removeEventListener("keydown", this.keyHandler);
    }
    render() {
      const { data } = this.props;
      return [
        <WrappedComponent
          key="paginatedData"
          {...this.props}
          maxResultOnPage={this.maxResultOnPage}
          activePage={this.state.activePage}
        />,
        data != null && data.length > this.maxResultOnPage ? (
          <Pagination
            key="paginationModule"
            itemsCountPerPage={this.maxResultOnPage}
            activePage={this.state.activePage}
            totalItemsCount={data != null ? data.length : 0}
            onChange={pageNumber => {
              this.setState({ activePage: pageNumber });
            }}
            pageRangeDisplayed={this.pageRangeDisplayed}
          />
        ) : (
          ""
        ),
        data != null &&
        this.state.activePage >= data.length / this.maxResultOnPage &&
        this.props.showOverLimitMessage &&
        data.length >= MAX_NUM_MESSAGES ? (
          <CenterText key="infoText">
            {this.props.t("maxResultReached")}
          </CenterText>
        ) : (
          ""
        )
      ];
    }
  };
}

export default WithPagination;
