import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FlexContainer } from "../components/FlexContainer";
import NotificationList from "./NotificationList";
import { Route } from "react-router-dom";
import NotificationForm from "./NotificationForm";
import moment from "moment";
import { destroy, SubmissionError } from "redux-form";
import {
  createNotification,
  fetchNotificationList,
  fetchOrganisation,
  fetchOrganisationList,
} from "./NotificationActions";
import ConfirmNotification from "./ConfirmNotification";
import { validateFields } from "./NotificationFormValidator";
import {hasValue, isSuperUser} from "../util/common";
import WithPagination from "../components/PaginationContainer";
import Loader from "../components/Loader";

const PaginatedNotificationList = WithPagination(NotificationList);

class NotificationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewNotificationModal: false,
      selectedOrganisationCompanies: undefined,
      loading: true,
      notificationList: [],
    };

    this.saveNotification = this.saveNotification.bind(this);
  }

    componentWillMount() {
    this.props.dispatch(fetchNotificationList(this.props.organisationId))
       .then((res) => {
          this.setState({notificationList: res.data.entity})
          return res
       })
       .then((res) => this.getFilteredSortedList(res.data.entity, undefined))
      .then(() => this.props.dispatch(fetchOrganisationList()))
      .then((organisationList) => {
        if (
          organisationList.data &&
          organisationList.data.entity.length === 1
        ) {
          this.props
            .dispatch(fetchOrganisation(organisationList.data.entity[0].id))
            .then((res) => {
              this.setState({
                selectedOrganisationCompanies: res.data.entity.companies,
              });
            });
        }
      })
      .then(() => this.setState({ loading: false }))
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this.props.dispatch(destroy("notificationForm"));
  }

  isCreatedBetweenFilterDates = (created, values) => {
        if (values.fromDate) {
            if (values.toDate) {
                return (
                    created.isAfter(
                        moment(values.fromDate, "DD.MM.YYYY").startOf("day")) &&
                    created.isBefore(moment(values.toDate, "DD.MM.YYYY").endOf("day"))
                );
            }
            return created.isAfter(
                moment(values.fromDate, "DD.MM.YYYY").startOf("day"));
        } else if (values.toDate) {
            return created.isBefore(moment(values.toDate, "DD.MM.YYYY").endOf("day"));
        }
        return true;
    };

  isIssuerPartOfList = (issuer, values) => {
        if (values.issuer == null) {
            return true;
        }
        if (issuer != null) {
            return issuer.toLowerCase().includes(values.issuer.toLowerCase());
        }
        else if (issuer == null) {
            return false;
        }
        return true;
    };

  saveNotification(notificationObject) {
    this.setState({loading: true})
    this.props
      .dispatch(createNotification(notificationObject))
      .then(() =>
        this.props.dispatch(fetchNotificationList(this.props.organisationId))
      )
    .then((res) => {
        this.setState({notificationList: res.data.entity, loading: false})
        return res
    })
    .then((res) => this.getFilteredSortedList(res.data.entity, undefined))
      .then(() => this.props.history.push(`${this.props.base}`))
      .then(() => this.props.dispatch(destroy("notificationForm")))
      .catch((err) => {
        this.props.history.push(`${this.props.base}/notificationForm`);
        console.log(err);
      });
  }

  getFilteredSortedList(list, values) {
      const sortedList = list.sort((a, b) => {
              const aPub = moment(a.createdTime);
              const bPub = moment(b.createdTime);

              if (aPub.isAfter(bPub)) return -1;
              else if (aPub.isBefore(bPub)) return 1;
              return 0;
          })
      if (hasValue(values)) {
          return sortedList
              .filter((row) => this.isCreatedBetweenFilterDates(moment(row.createdTime), values))
          .filter((row) => this.isIssuerPartOfList(row.issuer, values));
      }
  }

    getListWithFilter = (values) => {
        this.setState({
            filterString: values.issuer ? values.issuer : undefined,
        });
        this.setState({
            fromDate: values.fromDate
                ? moment(values.fromDate, "DD.MM.YYYY")
                : undefined,
            toDate: values.toDate ? moment(values.toDate, "DD.MM.YYYY") : undefined,
        });

        const notifications = this.getFilteredSortedList(this.props.notificationList, values)
        this.setState({notificationList: notifications})
    };

    cancel = () => {
        this.props.dispatch(destroy("notificationForm"));
        this.props.history.push(`${this.props.base}`);
    };

  render() {
    const { t, base } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <FlexContainer column>
        <Route
          exact
          path={`${base}`}
          render={() => {
            return this.props.notificationListIsFetched  ? (
              <FlexContainer>
                <PaginatedNotificationList
                  getListWithFilter={this.getListWithFilter}
                  key="recent"
                  t={t}
                  data={this.state.notificationList}
                  {...this.props}
                />
              </FlexContainer>
            ) : (
              ""
            );
          }}
        />

        <Route
          exact
          path={`${base}/notificationForm`}
          render={() => {
            return (
              <NotificationForm
                {...this.props}
                onCancel={this.cancel}
                onSelectOrganisation={(value) => {
                  this.props
                    .dispatch(fetchOrganisation(value.value))
                    .then((res) => {
                      this.setState({
                        selectedOrganisationCompanies:
                          res.data.entity.companies,
                      });
                    })
                    .catch((err) => console.log(err));
                  return Promise.resolve();
                }}
                onSubmit={(values) => {
                  return new Promise((resolve, reject) => {
                    const errors = validateFields(
                      values,
                      t,
                      this.props.isSuperUser
                    );
                    if (errors) {
                      reject(new SubmissionError(errors));
                    } else {
                      this.props.history.push(`${base}/confirmNotification`);
                      resolve();
                    }
                  });
                }}
                selectedOrganisationCompanies={
                  this.state.selectedOrganisationCompanies
                }
              />
            );
          }}
        />

        <Route
          exact
          path={`${base}/confirmNotification`}
          render={() => {
            return (
              <ConfirmNotification
                {...this.props}
                onCancel={this.cancel}
                onBack={() =>
                  this.props.history.push(`${base}/notificationForm`)
                }
                saveNotification={this.saveNotification}
              />
            );
          }}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { form, notificationReducer, loginUser } = state;
  return {
    notificationForm:
      form.notificationForm != null ? form.notificationForm.values : undefined,
    organisationList: notificationReducer.organisationList.list,
    issuerList: notificationReducer.issuerList.list,
      issuerListIsFetched: notificationReducer.issuerList.isFetched,
    organisationId: loginUser.user.organisationId,
    isSuperUser: isSuperUser(loginUser.user),
    notificationList: notificationReducer.notificationList.list,
    notificationListIsFetched: notificationReducer.notificationList.isFetched,
  };

}

export default connect(mapStateToProps)(
  withTranslation("translations")(NotificationContainer)
);
