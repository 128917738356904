import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import Button from "../../../components/Button";
import Table, { TD, TR } from "../../../components/Table";
import Label from "../../../components/Label";
import { ErrorText } from "../../../components/ErrorText";
import Input from "../../../components/Input";
import SearchableSelect from "../../../components/SearchableSelect";
import { InsiderType } from "../../InsiderToolActions";
import { TooltipWrapper } from "../../components";
import { Tooltip } from "../../../components/Tooltip";
import { getLanguage } from "../../../util/common";

const InputWBoarder = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={350} margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        border
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

const DropDownWithBorder = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={350} margin={[0, 0, 16]} minWidth={150}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect options={props.options} t={props.t} {...props} />
    </FlexContainer>
  );
};

const TooltipText = props => {
  const { t } = props;
  return (
    <Label color={"black"}>
      {t("it_two_factor_phone_help_1")}
      <Label underline>{t("it_two_factor_phone_help_2")}</Label>
    </Label>
  );
};

export class ImportExcel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailSelected: false
    };
  }

  render() {
    const { t, handleSubmit, onSubmit, countryOptions } = this.props;

    const thLabels = [
      t("first_name"),
      t("last_name"),
      t("email"),
      t("it_area_code"),
      <TooltipWrapper helpCursor>
        <Label maxWidth tooltip>
          {t("telephone")}
        </Label>
        <Tooltip>
          <TooltipText t={t} />
        </Tooltip>
      </TooltipWrapper>,
      t("it_obtained")
    ];

    const rows =
      this.props.importList != null
        ? this.props.importList.map((row, index) => (
            <TR key={index}>
              <TD>
                <Field
                  component={InputWBoarder}
                  name={index + "-" + "first_name"}
                />
              </TD>
              <TD>
                <Field
                  component={InputWBoarder}
                  name={index + "-" + "surname"}
                />
              </TD>
              <TD>
                <Field component={InputWBoarder} name={index + "-" + "email"} />
              </TD>
              <TD>
                <Field
                  component={DropDownWithBorder}
                  name={index + "-twoFactorCountryCode"}
                  options={countryOptions}
                  t={t}
                />
              </TD>
              <TD>
                <Field
                  component={InputWBoarder}
                  name={index + "-twoFactorPhone"}
                />
              </TD>
              <TD>
                <Field
                  component={InputWBoarder}
                  name={index + "-obtained"}
                  disabled
                />
              </TD>
            </TR>
          ))
        : [];

    return (
      <FlexContainer>
        <FlexContainer margin={[24, 0, 24, 0]} row vAlignCenter flexEnd />

        <Table
          tableRows={rows}
          tableHeaderLabels={thLabels}
          stickyOption={false}
        />
        {this.props.importList.length > 0 ? (
          <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
            <Button.Standard
              onClick={handleSubmit(onSubmit)}
              margin={[0, 24, 0, 0]}
            >
              {t("Ok")}
            </Button.Standard>

            <Button.Text onClick={this.props.onCancel}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        ) : (
          ""
        )}
      </FlexContainer>
    );
  }
}

ImportExcel = reduxForm({
  form: "importExcel"
})(ImportExcel);

export default ImportExcel;
