import { combineReducers } from "redux";
import {
  GetInsiderLogSyncDetailsByUserAction,
  ListInsiderSyncAction, ListInsiderSyncClients
} from "./InsiderSyncDetialsActions";

const initalInsiderSyncDetailsState = {
  list: []
};
export const insiderSyncDetialsList = (
  state = {
    ...initalInsiderSyncDetailsState
  },
  action
) => {
  switch (action.type) {
    case ListInsiderSyncAction.receive:
      return Object.assign({}, state, {
        list: action.data || []
      });
    default:
      return state;
  }
};

const initialInsiderSyncClientsState = {
  list: []
};
export const insiderSyncClients = (
    state = {
      ...initialInsiderSyncClientsState
    },
    action
) => {
  switch (action.type) {
    case ListInsiderSyncClients.receive:
      return Object.assign({}, state, {
        list: action.data || []
      });
    default:
      return state;
  }
};

const initalInsiderSyncDetailsByUserIdState = {
  insiderLogUrl: {},
  insiderLogUrlIsFetched: false
};
export const insiderSyncDetialsByUser = (
  state = {
    ...initalInsiderSyncDetailsByUserIdState
  },
  action
) => {
  switch (action.type) {
    case GetInsiderLogSyncDetailsByUserAction.receive:
      return Object.assign({}, state, {
        insiderLogUrl: action.data || {},
        insiderLogUrlIsFetched: true
      });
    default:
      return state;
  }
};

const insiderSyncDetailsReducer = combineReducers({
  insiderSyncDetialsList,
  insiderSyncDetialsByUser,
  insiderSyncClients
});

export default insiderSyncDetailsReducer;
