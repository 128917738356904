import { FlexContainer } from "../../components/FlexContainer";
import Table, { TD, TR } from "../../components/Table";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import { Icon } from "../components";
import { EDIT_DOCUMENT, NEW_DOCUMENT } from "./InternalDocumentationHome";
import { getDateTimeInOBNTFormat } from "../UTCUtils";
import Button from "../../components/Button";
import FilterBox from "../../components/FilterBox";
import moment from "moment";
import { HelpIcon } from "../../components/HelpIcon";
import i18n from "../../i18n";

const Link = styled.a`
  text-align: left;
  color: #336699;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-weight: 600;
`;

const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1
};

const SortOnType = {
  TITLE: 0,
  TYPE: 1,
  CREATED: 2,
  UPDATED: 3,
  UPDATEDBY: 4
};

class InternalDocumentationTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changed: false,
      filterMap: new Map(),
      SortOnType: SortOnType.CREATED,
      sortOrder: sortOrder.DESCENDING
    };
  }

  typeList = [];

  TableHeaderWithFilter = ({ id, list }) => (
    <FilterBox
      enableToggleAll
      onChange={this.updateFilterMap}
      isChecked={this.isFilterOptionChecked}
      data={list}
      id={id}
      enableToggleAll
      isAllChecked={this.isAllChecked(list)}
      checkAllText={this.props.t("select_all")}
      onChangeAll={state => this.updateFilterMapWithList(list, state)}
    />
  );

  TableHeaderSort = ({ SortOnType }) => (
    <Icon
      height={20}
      width={20}
      margin={[3, 0, 0, 3]}
      src="/icons/sort-white-24px.svg"
      onClick={() =>
        this.state.sortOrder === sortOrder.DESCENDING
          ? this.setState({ sortOrder: sortOrder.ASCENDING, SortOnType })
          : this.setState({ sortOrder: sortOrder.DESCENDING, SortOnType })
      }
      alt={"sort"}
    />
  );

  sortFunction = (aData, bData) => {
    if (aData > bData) return 1 * this.state.sortOrder;
    if (aData < bData) return -1 * this.state.sortOrder;
  };

  sortTimeFunction = (aTime, bTime) => {
    if (aTime.isBefore(bTime)) return 1 * this.state.sortOrder;
    if (aTime.isAfter(bTime)) return -1 * this.state.sortOrder;
  };

  listSort = (a, b) => {
    const aCreatedTime = moment(a.data.created);
    const bCreatedTime = moment(b.data.created);

    const aUpdatedTime = moment(a.data.updated);
    const bUpdatedTime = moment(b.data.updated);

    const sortUpdated = () => {
      if (aUpdatedTime.isBefore(bUpdatedTime)) return 1 * this.state.sortOrder;
      if (aUpdatedTime.isAfter(bUpdatedTime)) return -1 * this.state.sortOrder;
    };

    const sortCreated = (descending = undefined) => {
      if (aCreatedTime.isBefore(bCreatedTime))
        return 1 * (descending || this.state.sortOrder);
      if (aCreatedTime.isAfter(bCreatedTime))
        return -1 * (descending || this.state.sortOrder);
    };

    switch (this.state.SortOnType) {
      case SortOnType.TITLE:
        return (
          this.sortFunction(a.data.title, b.data.title) || sortCreated(true)
        );

      case SortOnType.TYPE:
        return this.sortFunction(a.data.type, b.data.type) || sortCreated(true);

      case SortOnType.CREATED:
        return this.sortTimeFunction(aCreatedTime, bCreatedTime);

      case SortOnType.UPDATED:
        return this.sortTimeFunction(aUpdatedTime, bUpdatedTime);

      case SortOnType.CREATEDBY:
        return this.sortFunction(
          this.props.userMap.get(a.who)
            ? this.props.userMap.get(a.who).label
            : this.props.t("it_deactivated_user"),
          this.props.userMap.get(b.who)
            ? this.props.userMap.get(b.who).label
            : this.props.t("it_deactivated_user")
        );
    }
  };

  filter = row => {
    if (this.state.filterMap.get(row.data.type)) {
      return true;
    }
    return false;
  };

  updateFilterMapWithList = (list, state) => {
    var newMap = new Map(this.state.filterMap.entries());
    list.forEach(e => {
      if (state) {
        newMap.delete(e);
      } else {
        newMap.set(e, true);
      }
    });
    this.setState({ filterMap: newMap });
  };

  updateFilterMap = (value, state) => {
    var newMap = new Map(this.state.filterMap.entries());
    if (state === true) {
      newMap.delete(value);
    } else {
      newMap.set(value, true);
    }
    this.setState({ filterMap: newMap });
  };

  popultateFilterBoxes = _ => {
    if (this.props.docList != null) {
      this.props.docList.map(row => {
        this.typeList.push(row.data.type);
      });
      this.typeList = this.typeList.distinctValues();
    }
  };

  isFilterOptionChecked = value => {
    return !this.state.filterMap.has(value);
  };

  isAllChecked = list => {
    var isChecked = true;
    list.forEach(e => (isChecked &= this.isFilterOptionChecked(e)));
    return isChecked === 1;
  };

  componentWillMount() {
    this.popultateFilterBoxes();
  }

  render() {
    const {
      docList,
      t,
      onClickDocument,
      onEditDocument,
      onNewDocument
    } = this.props;

    const thLabels = [
      <FlexContainer row>
        {t("title")}
        <this.TableHeaderSort SortOnType={SortOnType.TITLE} />
      </FlexContainer>,
      <FlexContainer row>
        {t("type")}
        <this.TableHeaderSort SortOnType={SortOnType.TYPE} />
        <this.TableHeaderWithFilter id={"type"} list={this.typeList} />
      </FlexContainer>,
      <FlexContainer row>
        {t("created")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATED} />
      </FlexContainer>,
      <FlexContainer row>
        {t("updated")}
        <this.TableHeaderSort SortOnType={SortOnType.UPDATED} />
      </FlexContainer>,
      <FlexContainer noWrap row>
        {t("updated_by")}
        <this.TableHeaderSort SortOnType={SortOnType.UPDATEDBY} />
      </FlexContainer>
    ];

    thLabels.push(t("edit"));
    thLabels.push(t("delete"));

    const headerProps = [, , , , , { center: true }, { center: true }];

    const rows = docList
      ? docList
          .filter(row => !this.filter(row))
          .sort(this.listSort)
          .map((doc, index) => {
            return (
              <TR key={index}>
                <TD pointer onClick={() => onClickDocument(doc.id)}>
                  <Link>{doc.data.title}</Link>
                </TD>
                <TD>{doc.data.type}</TD>
                <TD boldFirstLine width={120} minWidth={120}>
                  {getDateTimeInOBNTFormat(
                    doc.data.created,
                    this.props.showUTCTime
                  )}
                </TD>
                <TD boldFirstLine width={120} minWidth={120}>
                  {getDateTimeInOBNTFormat(
                    doc.data.updated,
                    this.props.showUTCTime
                  )}
                </TD>
                <TD width={130} minWidth={130}>
                  {doc.data.createdBy && this.props.userMap.get(doc.who)
                    ? this.props.userMap.get(doc.who).label
                    : ""}
                </TD>

                <TD center width={80} minWidth={80}>
                  <Icon
                    inactive={!this.props.hasEditAccess(doc.data.createdBy)}
                    src="/icons/ic-edit-black-24-px.svg"
                    alt={t("edit")}
                    onClick={() =>
                      this.props.hasEditAccess(doc.data.createdBy)
                        ? onEditDocument(doc.id)
                        : undefined
                    }
                  />
                </TD>

                <TD center width={80} minWidth={80}>
                  <Icon
                    inactive={!this.props.hasEditAccess(doc.data.createdBy)}
                    src={"/icons/red-cross-24.svg"}
                    alt={t("delete")}
                    onClick={() =>
                      this.props.hasEditAccess(doc.data.createdBy)
                        ? this.props.onDeleteDocument(doc.id)
                        : undefined
                    }
                  />
                </TD>
              </TR>
            );
          })
      : [];

    // If everything has been filetered out then we have to add something the let the table header be visibile
    if (this.state.filterMap.size > 0 && rows.length === 0)
      rows.push(undefined);
    return (
      <FlexContainer column>
        <FlexContainer row vAlignCenter>
          <Button.Standard onClick={onNewDocument} margin={[30, 0, 20, 0]}>
            {t("new_document")}
          </Button.Standard>

          <FlexContainer flexEnd row>
            {t("it_information")}
            <HelpIcon
              language={i18n.language}
              fileName={"it/internal_documentation"}
              margin={[0, 0, -3, 3]}
            />
          </FlexContainer>
        </FlexContainer>

        <Table
          tableRows={rows}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
        />
      </FlexContainer>
    );
  }
}

InternalDocumentationTable = reduxForm({
  form: "internalDocumentationTable"
})(InternalDocumentationTable);

export default withTranslation("translations")(InternalDocumentationTable);
