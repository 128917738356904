import moment from "moment/moment";
import { emailRequirementValidator } from "../util/emailValidator";

const hasValue = (value) => {
  return value != null && value.length > 0;
};

const fieldLengthExceeded = (values, maximumCharacters) => {
  return values !== undefined && values.length > maximumCharacters;
};

export const validate = (
  values,
  person,
  isPrimary,
  t,
  showPN,
  checkValidFrom,
  primaryValidFrom
) => {
  const errors = {};
  const fieldLengthCheck = () => {
    if (fieldLengthExceeded(values.address, 80)) {
      errors.address = t("field_length_exceeded", {
        length: 80,
      });
    }
  };

  const requiredFieldsCheck = () => {
    if (person) {
      if (!hasValue(values.surname)) {
        errors.surname = t("required_field");
      }
      if (!hasValue(values.firstName)) {
        errors.firstName = t("required_field");
      }
      if (showPN) {
        if (values.norwegian && !hasValue(values.idNumber)) {
          errors.idNumber = t("required_field");
        }
      }
    } else {
      if (!hasValue(values.companyName)) {
        errors.companyName = t("required_field");
      }
      if (values.norwegian && !hasValue(values.idNumber)) {
        errors.idNumber = t("required_field");
      }
    }

    if (!isPrimary) {
      if (
        values.primaryInsider == null ||
        !hasValue(values.primaryInsider.value + "")
      ) {
        errors.primaryInsider = t("required_field");
      }
    } else {
      if (!hasValue(values.position) && !hasValue(values.commission)) {
        errors.position = t("commission_or_employment_required");
        errors.commission = t("commission_or_employment_required");
      }
    }

    if (!hasValue(values.postalCode)) {
      errors.postalCode = t("required_field");
    }
    if (!hasValue(values.postalPlace)) {
      errors.postalPlace = t("required_field");
    }
    if (
      values.countryOption == null ||
      !hasValue(values.countryOption.value + "")
    ) {
      errors.countryOption = t("required_field");
    }
    if (checkValidFrom && !hasValue(values.validFrom)) {
      errors.validFrom = t("required_field");
    }
    if (!hasValue(values.email)) {
      errors.email = t("required_field");
    }
    if (
      values.languageOption == null ||
      !hasValue(values.languageOption.value + "")
    ) {
      errors.languageOption = t("required_field");
    }
  };

  const fieldValueCheck = () => {
    if (values.idNumber != null && values.idNumber.length > 11) {
      errors.idNumber = person
        ? t("id_number_too_long")
        : t("org_number_too_long");
    }
    if (values.postalCode != null && values.postalCode.length > 10) {
      errors.postalCode = t("postal_code_too_long");
    }
    if (values.postalPlace != null && values.postalPlace.length > 50) {
      errors.postalPlace = t("postal_place_too_long");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = t("invalid_email");
    }
    if (values.email != null && values.email.length > 50) {
      errors.email = t("email_too_long");
    }
    if (values.phone1 != null && values.phone1.length > 15) {
      errors.phone1 = t("phone_too_long");
    }
    if (values.phone2 != null && values.phone2.length > 15) {
      errors.phone2 = t("phone_too_long");
    }
    if (values.position != null && values.position.length > 50) {
      errors.position = t("position_too_long");
    }
    if (values.commission != null && values.commission.length > 50) {
      errors.commission = t("commission_too_long");
    }
    if (values.country != null && values.country.length > 50) {
      errors.country = t("country_too_long");
    }
    if (values.comment != null && values.comment.length > 500) {
      errors.comment = t("comment_too_long");
    }

    if (person) {
      if (values.firstName != null && values.firstName.length > 50) {
        errors.firstName = t("first_name_too_long");
      }
      if (values.middleName != null && values.middleName.length > 50) {
        errors.middleName = t("middle_name_too_long");
      }
      if (values.surname != null && values.surname.length > 50) {
        errors.surname = t("surname_too_long");
      }
    }

    if (!person) {
      if (values.companyName != null && values.companyName.length > 50) {
        errors.companyName = t("company_name_too_long");
      }
    }
    if (
      values.validTo &&
      !(
        moment(values.validTo, "DD.MM.YYYY", true).isValid() ||
        moment(values.validTo, "YYYY-MM-DD", true).isValid()
      )
    ) {
      errors.validTo = t("invalid_date");
    }
    if (
      checkValidFrom &&
      values.validFrom &&
      !(
        moment(values.validFrom, "DD.MM.YYYY", true).isValid() ||
        moment(values.validFrom, "YYYY-MM-DD", true).isValid()
      )
    ) {
      errors.validFrom = t("invalid_date");
    }
    if (
      checkValidFrom &&
      values.validFrom &&
      primaryValidFrom &&
      (moment(values.validFrom, "DD.MM.YYYY", true).isValid() ||
        moment(values.validFrom, "YYYY-MM-DD", true).isValid())
    ) {

      if (new Date(formatDate(values.validFrom)) < new Date(formatDate(primaryValidFrom))) {
        errors.validFrom = t("invalid_date");
      }
    }
    if (values.validFrom && values.validTo) {
      if (new Date(formatDate(values.validFrom)) > new Date(formatDate(values.validTo))) {
        errors.validTo = t("invalid_date");
      }
    }
  };

  fieldLengthCheck();
  fieldValueCheck();
  requiredFieldsCheck();
  return Object.keys(errors).length > 0 ? errors : undefined;
};

const formatDate = (oldDate)  => {
  if (!moment(oldDate, "YYYY-MM-DD", true).isValid()) {
    return moment(oldDate, "DD.MM.YYYY", true).format("YYYY-MM-DD")
  }
  return oldDate
}
