import { combineReducers } from "redux";
import {
  NewCompany,
  UpdateCompany,
  NewContact,
  UpdateContact,
  Parameters,
  GetCompany,
  GetContactPerson,
  ContactPersonList,
  CompanyList,
} from "./CompanyInformationActions";

const initalContState = {
  list: [],
  isFetched: false,
};

export const contactPersonList = (
  state = {
    ...initalContState,
  },
  action
) => {
  switch (action.type) {
    case ContactPersonList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case ContactPersonList.postFail:
      return Object.assign({}, state, {
        isFetched: false,
      });
    default:
      return state;
  }
};

const initalCompState = {
  list: [],
};
export const companyList = (
  state = {
    ...initalCompState,
  },
  action
) => {
  switch (action.type) {
    case CompanyList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
      });
    default:
      return state;
  }
};

const initalGetContactState = {
  contact: undefined,
  isFetched: false,
};

export const getContactPerson = (
  state = {
    ...initalGetContactState,
  },
  action
) => {
  switch (action.type) {
    case GetContactPerson.request:
      return Object.assign({}, state, {
        contact: action.data.data.entity,
        isFetched: undefined,
      });
    case GetContactPerson.postSuccess:
      return Object.assign({}, state, {
        contact: action.data.data.entity,
        isFetched: true,
      });
    case GetContactPerson.clear:
      return Object.assign({}, state, {
        ...initalGetContactState,
      });
    default:
      return state;
  }
};

const initialParamState = {
  list: undefined,
  isFetched: false,
};

export const parameters = (
  state = {
    ...initialParamState,
  },
  action
) => {
  switch (action.type) {
    case Parameters.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity,
        isFetched: true,
      });
    case Parameters.request:
      return Object.assign({}, state, {
        isFetched: false,
      });
    default:
      return state;
  }
};

const initUpdateContact = {
  isUpdated: false,
  isCreated: false,
};
export const contactPerson = (
  state = {
    ...initUpdateContact,
  },
  action
) => {
  switch (action.type) {
    case UpdateContact.request:
      return Object.assign({}, state, {
        isUpdated: false,
        isCreated: true,
      });
    case UpdateContact.postSuccess:
      return Object.assign({}, state, {
        isUpdated: true,
        isCreated: true,
      });
    case UpdateContact.postFail:
      return Object.assign({}, state, {
        isUpdated: false,
        isCreated: false,
      });
    case NewContact.request:
      return Object.assign({}, state, {
        isCreated: true,
      });
    case NewContact.postSuccess:
      return Object.assign({}, state, {
        isCreated: true,
      });
    case NewContact.postFail:
      return Object.assign({}, state, {
        isCreated: false,
      });
    default:
      return state;
  }
};

const initalGetCompState = {
  list: [],
  isFetched: false,
  isCreated: false,
};
export const getCompany = (
  state = {
    ...initalGetCompState,
  },
  action
) => {
  switch (action.type) {
    case NewCompany.postSuccess:
    case GetCompany.request:
      return Object.assign({}, state, {
        isFetched: false,
      });
    case GetCompany.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
        isCreated: true,
      });
    default:
      return state;
  }
};

const initUpdateCompany = {
  isUpdated: false,
};
export const company = (
  state = {
    ...initUpdateCompany,
  },
  action
) => {
  switch (action.type) {
    case UpdateCompany.request:
      return Object.assign({}, state, {
        isUpdated: false,
      });
    case UpdateCompany.postSuccess:
      return Object.assign({}, state, {
        isUpdated: true,
      });
    case UpdateCompany.postFail:
      return Object.assign({}, state, {
        isUpdated: false,
      });
    default:
      return state;
  }
};

const companyInformationReducer = combineReducers({
  companyList,
  contactPersonList,
  getContactPerson,
  getCompany,
  parameters,
  contactPerson,
  company,
});

export default companyInformationReducer;
