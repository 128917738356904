import { postData } from "../actions/CommonActionsNoObsvc";

export const FinanceCalendarList = {
  request: "REQUEST_FINANCE_CALENDAR",
  postSuccess: "RECEIVE_FINANCE_CALENDAR",
  postFail: "RECEIVE_FINANCE_CALENDAR_FAILED",
};
export const fetchFinancialCalendar = (id) =>
  postData(FinanceCalendarList, undefined, { id }, "v1/financialcalendar/get");

export const OptionalEventList = {
  request: "REQUEST_OPTIONAL_EVENT",
  postSuccess: "RECEIVE_OPTIONAL_EVENT",
  postFail: "RECEIVE_OPTIONAL_EVENT_FAILED",
};

export const fetchOptionalEvents = () =>
  postData(
    OptionalEventList,
    undefined,
    undefined,
    "v1/financialcalendar/getOptionalEventTypes"
  );

export const AddCalendarList = {
  request: "CREATE_FINANCIAL_CALENDAR",
  postSuccess: "CREATE_FINANCIAL_CALENDAR_SUCCESS",
  postFail: "CREATE_FINANCIAL_CALENDAR_FAILED",
};

export const createFinancialCalendar = (messageData) =>
  postData(
    AddCalendarList,
    undefined,
    { ...messageData },
    "v1/financialcalendar/add"
  );

export const PreviewCalendarMessage = {
  request: "REQUEST_PREVIEW_CALENDAR_MESSAGE",
  postSuccess: "RECEIVE_PREVIEW_CALENDAR_MESSAGE",
  postFail: "RECEIVE_PREVIEW_CALENDAR_MESSAGE_FAILED",
  clearPreviewMessages: "CLEAR_PREVIEW_MESSAGES",
};

export const fetchCalendarMessagePreview = (messageData) =>
  postData(
    PreviewCalendarMessage,
    undefined,
    { ...messageData },
    "v1/financialcalendar/getMessagePreview"
  );

export const clearPreviewMessages = () => {
  return { type: PreviewCalendarMessage.clearPreviewMessages };
};
