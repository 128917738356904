import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";

function Root(props) {
  return (
    <Router>
      <Provider store={props.store}>
        <App />
      </Provider>
    </Router>
  );
}

Root.propTypes = {
  store: PropTypes.object.isRequired
};

export default Root;
