import { postData } from "../actions/CommonActionsNoObsvc";

export const UsersWithTwoFactorDisabledList = {
  request: "REQUEST_USERS_TWO_FACTOR_DISABLED_LIST",
  postSuccess: "RECEIVE_NOTIFICATION_LIST",
  postFail: "RECEIVE_NOTIFICATION_LIST_FAILED",
};

export const fetchUsersTwoFactorDisabled = () =>
  postData(
    UsersWithTwoFactorDisabledList,
    undefined,
    undefined,
    "v1/sysadmin/getUsersTwoFactorDisabled"
  );

export const LoggedInUsers = {
  request: "REQUEST_LOGGED_IN_USERS",
  postSuccess: "RECEIVE_LOGGED_IN_USERS",
  postFail: "RECEIVE_LOGGED_IN_USERS_FAILED",
};

export const fetchLoggedInUsers = () =>
  postData(LoggedInUsers, undefined, undefined, "v1/sysadmin/listLogins");

export const FetchSystemProps = {
  request: "REQUEST_SYSTEM_PROPS",
  postSuccess: "RECEIVE_SYSTEM_PROPS",
  postFail: "RECEIVE_SYSTEM_PROPS_FAILED",
};

export const fetchSystemProps = () =>
  postData(FetchSystemProps, undefined, undefined, "v1/sysadmin/listSysProps");

export const UpdateSystemProps = {
  request: "UPDATE_SYSTEM_PROPS_REQUEST",
  postSuccess: "UPDATE_SYSTEM_PROPS_SUCCESS",
  postFail: "UPDATE_SYSTEM_PROPS_FAILED",
};

export const updateSystemProps = (systemPropsCopy) =>
  postData(
    UpdateSystemProps,
    undefined,
    { sysProps: systemPropsCopy },
    "v1/sysadmin/updateSysProps"
  );

export const DeleteSystemProps = {
  request: "DELETE_SYSTEM_PROP_REQUEST",
  postSuccess: "DELETE_SYSTEM_PROP_SUCCESS",
  postFail: "DELETE_SYSTEM_PROP_FAILED",
};

export const removeSystemProp = (sysPropToBeRemoved) =>
  postData(
    DeleteSystemProps,
    undefined,
    { sysPropToBeRemoved },
    "v1/sysadmin/removeSysProp"
  );

export const PublicProps = {
  request: "REQUEST_PUBLIC_PROPS",
  postSuccess: "RECEIVE_PUBLIC_PROPS",
  postFail: "RECEIVE_PUBLIC_PROPS_FAILED",
};

export const fetchPublicProps = (orgId) =>
  postData(PublicProps, undefined, { orgId }, "v1/sysadmin/listPublicProps");

export const OrganisationList = {
  request: "REQUEST_ORGANIZATION_LIST_SYSADMIN",
  postSuccess: "RECEIVE_ORGANIZATION_LIST_SYSADMIN",
  postFail: "RECEIVE_ORGANIZATION_LIST_SYSADMIN_FAILED",
};

export const fetchOrganisationList = () =>
  postData(
    OrganisationList,
    undefined,
    undefined,
    "v1/sysadmin/organisation/list"
  );

export const CompanyListActions = {
  request: "REQUEST_ISSUERS",
  postSuccess: "RECEIVE_ISSUERS",
  postFail: "RECEIVE_ISSUERS_FAILED",
};
export const fetchCompanyList = () =>
  postData(CompanyListActions, undefined, undefined, "v1/company/list");
