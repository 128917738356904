import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {PageHeading} from "../components/PageHeading";
import EmailStatus from "./EmailStatus";
import BatchjobsStatus from "./BatchjobsStatus";
import Healthchecks from "./Healthchecks";

const BATCHJOBS = "batchjobs"
const EMAIL = "email"
const HEALTHCHECK = "healthcheck"

class StatusContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: EMAIL
        };
    }

    render() {
        const { t } = this.props;

        return (
            <FlexContainer borderTop center vAlignCenter margin={[0, 0, 0, 0]}>

                <FlexContainer row margin={[20, 0, 0, 0]} center>
                    <PageHeading
                        isFocusable
                        fontSize={18}
                        opacity={this.state.page === BATCHJOBS ? "1.0" : "0.5"}
                        underline={this.state.page === BATCHJOBS}
                        pointer={this.state.page !== BATCHJOBS}
                        onClick={() => this.setState({page: BATCHJOBS})}
                        margin={[0, 25, 25, 0]}
                    >
                        {t("Batchjobs")}
                    </PageHeading>
                    <PageHeading
                        isFocusable
                        fontSize={18}
                        opacity={this.state.page === EMAIL ? "1.0" : "0.5"}
                        underline={this.state.page === EMAIL}
                        pointer={this.state.page !== EMAIL}
                        onClick={() => this.setState({page: EMAIL})}
                        margin={[0, 25, 25, 0]}
                    >
                        {t("Email")}
                    </PageHeading>
                    <PageHeading
                        isFocusable
                        fontSize={18}
                        opacity={this.state.page === HEALTHCHECK ? "1.0" : "0.5"}
                        underline={this.state.page === HEALTHCHECK}
                        pointer={this.state.page !== HEALTHCHECK}
                        onClick={() => this.setState({page: HEALTHCHECK})}
                        margin={[0, 25, 25, 0]}
                    >
                        {t("Healthcheck")}
                    </PageHeading>
                </FlexContainer>

                {this.state.page === EMAIL && <EmailStatus
                    initialValues={{
                        to: '',
                        from: 'no-reply@euronext.com',
                        subject: "Testing",
                        body: "This is a test."
                    }}
                />}
                {this.state.page === BATCHJOBS &&
                    <BatchjobsStatus/>}

                {this.state.page === HEALTHCHECK &&
                    <Healthchecks
                    />}

            </FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const {

    } = state;

    return {
    };
}

export default connect(mapStateToProps)(withTranslation("translations")(StatusContainer));
