import React from "react";
import SearchableSelect from "../components/SearchableSelect";
import { Field, reduxForm, SubmissionError } from "redux-form";
import CheckBox from "../components/CheckBox";
import { WhiteDropDown } from "../components/WhiteDropDown";
import { FlexContainer } from "../components/FlexContainer";
import Label from "../components/Label";
import Input from "../components/Input";
import Table, { TD, TR } from "../components/Table";
import Button from "../components/Button";
import { Modal } from "../components/Modal";
import { connect } from "react-redux";
import { ErrorText } from "../components/ErrorText";
import Loader from "../components/Loader";
import CreatableSelect from "../components/CreatableSelect";
import {
  validateEditContactPerson,
  validateNewContactPerson,
} from "./Validators";
import i18n from "../i18n";
import { HelpIcon } from "../components/HelpIcon";

export const IsActiveBox = (props) => (
  <FlexContainer width={"auto"} row vAlignCenter margin={[0, 16, 0, 0]}>
    <CheckBox {...props} />
  </FlexContainer>
);

const InputBox = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 0, 16]}>
      <Label htmlFor={props.input.name} margin={[0, 0, 8, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
        maxWidth
      />
    </FlexContainer>
  );
};

export const SearchDropDownWlabel = (props) => {
  const { error } = props.meta;
  return (
    <FlexContainer column margin={[0, 8, 8, 0]}>
      <Label
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 8, 0]}
        {...props}
      >
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
        onSelect={props.getContact}
      />
    </FlexContainer>
  );
};

export const InputDropDown = (props) => {
  const name = props.input != null ? props.input.name : props.name;
  if (props.isVisible(name))
    return <Field component={_InputDropDown} {...props} />;
  return "";
};
export const _InputDropDown = (props) => {
  const { error } = props.meta;

  const { isEditable } = props;
  const name = props.input != null ? props.input.name : props.name;

  const isEditableFnc = isEditable != null ? isEditable : () => true;
  const disabled = isEditableFnc(name) ? {} : { disabled: true };

  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
        {...disabled}
      />
    </FlexContainer>
  );
};

export const AuditSearchDropDown = (props) => {
  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <CreatableSelect
        placeholder={props.placeholder}
        searchable={true}
        {...props}
        input={{
          name: props.name,
          onChange: (value) => {
            props.input.onChange(value);
            if (props.onChangedContact != null) {
              props.onChangedContact(value);
            }
          },
          value: props.input.value,
        }}
        options={props.options}
      />
    </FlexContainer>
  );
};

class NewAuditMemberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { t } = this.props;
    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validateNewContactPerson(values, this.props);
        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          this.setState({ loading: true }, () =>
            this.props.onSubmit().then(() => this.setState({ loading: false }))
          );
          resolve();
        }
      });
    };

    return (
      <FlexContainer maxWidth={500}>
        <form>
          <Field
            component={InputBox}
            border
            name="firstName"
            label={t("first_name") + "*"}
          />
          <Field
            component={InputBox}
            border
            name="lastName"
            label={t("last_name") + "*"}
          />
          <Field
            component={InputBox}
            border
            name="mobile"
            label={t("mobile_number") + "*"}
          />

          <Field
            component={InputBox}
            border
            name="office"
            label={t("direct_number")}
          />
          <Field
            component={InputBox}
            border
            name="email"
            label={t("email") + "*"}
          />
          <Label bold>{t("required_fields")}</Label>
          <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={
                !this.state.loading
                  ? this.props.handleSubmit(onSubmit)
                  : undefined
              }
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={() => this.props.onClose()}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

NewAuditMemberModal = reduxForm({
  form: "newAuditCommitteeMemberForm",
})(NewAuditMemberModal);

class EditAuditModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }
  render() {
    const { t } = this.props;
    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        const errors = validateEditContactPerson(values, this.props);
        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          this.setState({ loading: true }, () =>
            this.props.onSubmit().then(() => this.setState({ loading: false }))
          );
          resolve();
        }
      });
    };

    return (
      <form>
        <FlexContainer minWidth={500}>
          <FlexContainer>
            <Field
              component={SearchDropDownWlabel}
              name="editContactPerson"
              getContact={this.props.getContact}
              options={this.props.contactOptions}
              placeholder={t("select")}
              label={t("name") + " *"}
              t={this.props.t}
              clearable={false}
              maxWidth
            />
          </FlexContainer>
          {this.props.showDeleteButton ? (
            <FlexContainer>
              <Field
                component={InputBox}
                border
                name="mobile"
                label={t("mobile_number") + "*"}
              />
              <Field
                component={InputBox}
                border
                name="office"
                label={t("direct_number")}
              />
              <Field
                component={InputBox}
                border
                name="email"
                label={t("email") + " *"}
              />
              <Label bold>{t("required_fields")}</Label>
            </FlexContainer>
          ) : undefined}
        </FlexContainer>

        <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
          {this.props.showDeleteButton ? (
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={
                !this.state.loading
                  ? this.props.handleSubmit(onSubmit)
                  : undefined
              }
            >
              {t("save")}
            </Button.Standard>
          ) : undefined}
          {this.props.showDeleteButton ? (
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={() => {
                if (!this.state.loading) {
                  this.props.onConfirmDeleteMember();
                }
              }}
            >
              {t("delete")}
            </Button.Standard>
          ) : undefined}
          <Button.Text onClick={() => this.props.onClose()}>
            {t("cancel")}
          </Button.Text>
        </FlexContainer>
      </form>
    );
  }
}

EditAuditModal = reduxForm({
  form: "editAuditModalForm",
})(EditAuditModal);

class AuditMainForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { tv: this.props.initialValues };
  }

  componentDidUpdate(prevProps) {
    if (!Object.compare(prevProps.initialValues, this.props.initialValues)) {
      this.setState({ tv: this.props.initialValues });
    }
  }

  render() {
    const { t, isVisible, isEditable, isRequired } = this.props;

    this.contactPersonMap = new Map();
    this.props.contactPersonList.forEach((contact) => {
      if (contact.contactPersonType == 3) {
        this.contactPersonMap.set(contact.id, contact);
      }
    });
    const thLabels = [
      t("audit_committee_member"),
      t("contact_person"),
      t("email"),
    ];
    const arrWidth = [300, 300, 200];
    const headerProps = [
      { fontWeight: 500 },
      { fontWeight: 500 },
      { fontWeight: 500 },
    ];

    const tableRows = this.props.roleList.map((role, index) => (
      <TR key={index}>
        <TD width={arrWidth[0]} fontSize={14}>
          {this.props.initialValues[role + "role"]}
          {this.props.isRequired(role) ? "*" : ""}
          <HelpIcon
            language={i18n.language}
            fileName={"ci_ru_medlem"}
            margin={[0, 0, -3, 3]}
          />
        </TD>

        <TD width={arrWidth[1]} fontSize={14}>
          <Field
            onChangedContact={(value) => {
              var selected = undefined;
              if (value != null) {
                selected = this.props.contactPersonList.find(
                  (contact) => contact.id === value.value
                );
              }
              var newObj = { ...this.state.tv };
              newObj[role + "email"] =
                selected != null ? selected.email : undefined;
              this.setState({ tv: newObj });
            }}
            component={AuditSearchDropDown}
            options={this.props.contactOptions}
            onNewOptionClick={this.props.onOpenNewAuditCommitteeModal}
            placeholder={t("select")}
            name={role + "selectedContact"}
            t={this.props.t}
          />
        </TD>
        <TD width={arrWidth[2]} fontSize={14}>
          {this.state.tv[role + "email"]}
        </TD>
      </TR>
    ));
    return (
      <form>
        <Label margin={[0, 0, 3, 0]}>
          {" "}
          {t("audit_committee_legislation") +
            (isRequired("establishedBy") ? "*" : "")}
          <HelpIcon
            language={i18n.language}
            fileName={"ci_ru_etablertEtter"}
            margin={[0, 0, -3, 3]}
          />
        </Label>
        <InputDropDown
          isVisible={isVisible}
          isEditable={isEditable}
          margin={[0, 0, 16, 0]}
          placeholder={t("select")}
          options={this.props.auditCommitteeEstablishedByTypes}
          name={"establishedBy"}
          t={this.props.t}
        />

        <Label margin={[0, 0, 3, 0]}>
          {" "}
          {t("audit_committee_legislation_article") +
            (isRequired("electedBy") ? "*" : "")}
          <HelpIcon
            language={i18n.language}
            fileName={"ci_ru_valgtAv"}
            margin={[0, 0, -3, 3]}
          />
        </Label>
        <InputDropDown
          isVisible={isVisible}
          isEditable={isEditable}
          margin={[0, 0, 16, 0]}
          placeholder={t("select")}
          options={this.props.auditCommitteeElectedByTypes}
          name={"electedBy"}
          t={this.props.t}
        />

        <Label margin={[0, 0, 3, 0]}>
          {" "}
          {t("audit_committee_as_board") +
            (isRequired("collectively") ? "*" : "")}
          <HelpIcon
            language={i18n.language}
            fileName={"ci_ru_samlet"}
            margin={[0, 0, -3, 3]}
          />
        </Label>
        <InputDropDown
          isVisible={isVisible}
          isEditable={isEditable}
          placeholder={t("select")}
          options={this.props.auditCommitteeWorkingCollectivelyTypes}
          name={"collectively"}
          t={this.props.t}
        />

        <FlexContainer margin={[16, 0, 0, 0]}>
          <Table
            forceUpdate={true}
            tableRows={tableRows}
            stickyOption={false}
            tableHeaderLabels={thLabels}
            tableHeaderProps={headerProps}
          />
        </FlexContainer>
      </form>
    );
  }
}

AuditMainForm = reduxForm({
  form: "auditMainForm",
  enableReinitialize: true,
})(AuditMainForm);

class AuditCommitteeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.submit = this.submit.bind(this);
  }

  submit() {
    return this.auditCommiteeForm.submit();
  }
  render() {
    const { t } = this.props;

    return [
      <FlexContainer key="main">
        <WhiteDropDown
          greenBackground
          onClick={() => {
            this.props.onToggleAuditCommittee();
          }}
          isOpen={this.props.isAuditCommitteeOpen}
          key={"auditCommitteeForm"}
          header={t("audit_committee")}
        >
          <FlexContainer>
            <AuditMainForm
              {...this.props}
              ref={(x) => (this.auditCommiteeForm = x)}
              t={this.props.t}
              onSubmit={this.props.onSubmit}
              //initialValues={this.props.initVal}
              roleList={this.props.roleList}
              getContact={this.props.getContact}
              contactPersonList={this.props.contactPersonList}
              contactOptions={this.props.contactOptions}
              initialValues={{
                ...this.props.initVal,
                ...this.props.initialValues,
                ...this.props.initialAuditCommitteeValues,
              }}
              {...this.props.parameterOptions}
            />
          </FlexContainer>

          <FlexContainer spaceBetween column>
            <FlexContainer row>
              <Button.Standard
                onClick={() => {
                  this.props.onOpenNewAuditCommitteeModal();
                }}
                margin={[16, 16, 0, 0]}
              >
                {t("add_audit_member")}
              </Button.Standard>
              <Button.Standard
                onClick={() => {
                  this.props.onEditAuditMemberModal();
                }}
                margin={[16, 0, 16, 0]}
              >
                {t("edit_audit_committee_member")}
              </Button.Standard>
            </FlexContainer>
          </FlexContainer>
        </WhiteDropDown>
      </FlexContainer>,
      <Modal
        key={"ModalNewAuditCommitteeMember"}
        center
        header={t("add_new_audit_committee_member")}
        isOpen={this.props.openNewAuditCommitteeModal}
        onClose={() => this.props.onCloseNewAuditCommitteeModal()}
      >
        <NewAuditMemberModal
          t={this.props.t}
          onSubmit={this.props.onSubmitNew}
          onClose={this.props.onCloseNewAuditCommitteeModal}
        />
      </Modal>,

      <Modal
        key={"ModalEditAuditMember"}
        minHeight={200}
        center
        header={t("edit_or_delete_audit_member")}
        isOpen={this.props.openEditAuditMemberModal}
        onClose={() => {
          this.props.onCloseEditAuditCommitteeModal();
        }}
      >
        <FlexContainer padding={[16, 16, 16, 16]} column vAlignCenter>
          <EditAuditModal
            enableReinitialize={true}
            {...this.props}
            initialValues={{ ...this.props.initialAuditMember }}
            onSubmit={this.props.onSubmitEdit}
            onClose={this.props.onCloseEditAuditCommitteeModal}
            onDelete={this.props.deleteContact}
            onConfirmDeleteMember={this.props.onConfirmDeleteMember}
            showDeleteButton={this.props.showDeleteButton}
          />
        </FlexContainer>
      </Modal>,
      <Modal
        key={"confirmDeleteMember"}
        isOpen={this.props.openConfirmModalMember}
        header={t("delete_accountant")}
        onClose={() => this.props.onCloseConfirmModal()}
      >
        <FlexContainer>
          <FlexContainer column>
            <Label bold>
              {t("delete_contactperson_info", {
                contact: this.props.contactInfo,
              })}
            </Label>
          </FlexContainer>
          <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
            <Button.Standard
              margin={[0, 16, 0, 0]}
              inactive={this.state.loading}
              onClick={() => {
                if (!this.state.loading)
                  this.setState({ loading: true }, () =>
                    this.props
                      .deleteContact()
                      .then(() => this.setState({ loading: false }))
                  );
              }}
            >
              {t("delete")}
            </Button.Standard>
            <Button.Text onClick={() => this.props.onCloseConfirmModal()}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </FlexContainer>
      </Modal>,
    ];
  }
}
export default AuditCommitteeForm;
