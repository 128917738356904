import React from "react";
import Table, { TD, TR } from "../components/Table";
import { connect } from "react-redux";
import styled from "styled-components";
import { browserIsIE, getOBNTDateTime } from "../util/common";
import { FlexContainer } from "../components/FlexContainer";
import DayPicker from "../components/DayPicker";
import Label from "../components/Label";
import { Field, reduxForm } from "redux-form";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import { ActionText } from "../components/ActionText";
import { MAX_RESULT_ON_PAGE } from "../components/PaginationContainer";
import NotificationViewer from "./NotificationViewer";
import { Icon } from "../insiderTool/components";
import moment from "moment";
import { Modal } from "../components/Modal";
import {
  fetchNotification,
  fetchNotificationList,
  setStatusNotification
} from "./NotificationActions";
import Input from "../components/Input";
import { ConfirmModal } from "../components/ConfirmModal";
import { getNotificationStatusTextPostfix } from "./NotificationUtils";
import {OverlayLoader} from "../components/Loader";

const NotificationTitle = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1
};

const SortOnType = {
  CREATED: 0,
  STATUS: 1,
  CREATEDBY: 2
};

const SearchBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]} maxWidth>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>
      <Input
        {...props}
        {...props.input}
        border
        onKeyDown={e => {
          switch (e.keyCode) {
            case 13: //Enter
              props.handleSubmit();
          }
        }}
      />
    </FlexContainer>
  );
};

const DateBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, props.noRightMarign ? 0 : 8, 0, 0]}>
      <Label
        maxWidth
        bold
        htmlFor={props.name}
        margin={[8, props.noRightMarign ? 0 : 8, 3, 0]}
      >
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <DayPicker {...props} border={true} disableDaysAfter={new Date()} />
    </FlexContainer>
  );
};

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <form autoComplete="off">
          <FlexContainer margin={[10, 0, 10, 0]}>
            {t("overview_previous_notifications")}
          </FlexContainer>

          <FlexContainer
            row
            margin={[10, 0, 10, 0]}
            vAlignEnd
            spaceBetween
            padding={browserIsIE() ? [0, 10, 0, 0] : [0]}
          >
            <FlexContainer minWidth={900} row>
              {this.props.isSuperUser ? (
                <Field
                  name="issuer"
                  noRightMargin
                  component={SearchBox}
                  handleSubmit={this.props.handleSubmit(this.props.onSubmit)}
                  label={t("issuer")}
                />
              ) : (
                ""
              )}

              <Field
                name="fromDate"
                component={DateBox}
                label={t("fromDate")}
              />
              <Field name="toDate" component={DateBox} label={t("toDate")} />
            </FlexContainer>
            <FlexContainer vAlignCenter row width="auto" spaceBetween>
              <ActionText
                margin={[0, 0, 7, 20]}
                onClick={() => {
                  this.props.reset();
                  this.props.resetFilter();
                }}
              >
                {t("reset_search")}
              </ActionText>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}
SearchComponent = reduxForm({ form: "notificationSearch" })(SearchComponent);

class NotificationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: undefined,
      toDate: undefined,
      filterString: undefined,
      showNotificationViewer: false,
      clickedNotification: undefined,
      loading: false,
      showDeleteModal: false,
      entityToDelete: undefined,
      showConfirmDeleteNotificationModal: false,
      SortOnType: SortOnType.CREATED,
      sortOrder: sortOrder.DESCENDING
    };
  }

  TableHeaderSort = ({ SortOnType }) => (
    <Icon
      height={20}
      width={20}
      margin={[3, 0, 0, 3]}
      src="/icons/sort-white-24px.svg"
      onClick={() => {
        this.state.sortOrder === sortOrder.DESCENDING
          ? this.setState({ sortOrder: sortOrder.ASCENDING, SortOnType })
          : this.setState({ sortOrder: sortOrder.DESCENDING, SortOnType });
      }}
      alt={"sort"}
    />
  );

  sortFunction = (aData, bData) => {
    if (aData > bData) return 1 * this.state.sortOrder;
    if (aData < bData) return -1 * this.state.sortOrder;
  };

  sortTimeFunction = (aTime, bTime) => {
    if (aTime.isBefore(bTime)) return 1 * this.state.sortOrder;
    if (aTime.isAfter(bTime)) return -1 * this.state.sortOrder;
  };

  listSort = (a, b) => {
    const aCreatedTime = moment(a.createdTime);
    const bCreatedTime = moment(b.createdTime);

    const sortCreated = (descending = undefined) => {
      if (aCreatedTime.isBefore(bCreatedTime))
        return 1 * (descending || this.state.sortOrder);
      if (aCreatedTime.isAfter(bCreatedTime))
        return -1 * (descending || this.state.sortOrder);
    };

    switch (this.state.SortOnType) {
      case SortOnType.STATUS:
        return this.sortFunction(a.status, b.status) || sortCreated(true);

      case SortOnType.CREATED:
        return (
          this.sortTimeFunction(aCreatedTime, bCreatedTime) || sortCreated(true)
        );

      case SortOnType.CREATEDBY:
        return this.sortFunction(
          a.createdUsername,
          b.createdUsername || sortCreated(true)
        );
    }
  };

  onNotificationClick = notification => {
    this.setState({ loading: true }, () =>
      this.props
        .dispatch(
          fetchNotification(notification.id, notification.organisationId)
        )
        .then(() =>
          this.setState({
            showNotificationViewer: true,
            clickedNotification: notification,
            loading: false
          })
        )
    );
  };
  render() {
    const { t, isSuperUser, base } = this.props;

    const thLabels = [
      <FlexContainer row>
        {t("created")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATED} />
      </FlexContainer>,
      t("issuer"),
      <FlexContainer row>
        {t("list_created_by")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATEDBY} />
      </FlexContainer>,
      t("number_of_decision_makers")
    ];
    const thLabelsSuper = [
      <FlexContainer row>
        {t("created")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATED} />
      </FlexContainer>,
      t("issuer"),
      <FlexContainer row>
        {t("list_created_by")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATEDBY} />
      </FlexContainer>,
      <FlexContainer row>
        {t("status")}
        <this.TableHeaderSort SortOnType={SortOnType.STATUS} />
      </FlexContainer>,
      t("number_of_decision_makers"),
      t("delete")
    ];

    const headerProps = [, , , { center: true }];
    const headerPropsSuper = [, , , , { center: true }, { center: true }];

    const setDeleteStatus = (entity, status) => {
      this.props
        .dispatch(
          setStatusNotification({
            organisationId: entity.organisationId,
            id: entity.id,
            status: status
          })
        )
      .then(() => {
          this.setState({loading: true})
      })
        .then(() =>
          this.props.dispatch(fetchNotificationList(this.props.organisationId))
        )
        .then(() =>
          this.setState({
            showDeleteModal: false,
            entityToDelete: undefined,
            showConfirmDeleteNotificationModal: false,
              loading: false
          })
        )
        .catch(err => console.log(err));
    };

    const showDeleteModal = entity => {
      this.setState({ showDeleteModal: true, entityToDelete: entity});
    };

    const tableRows =
      this.props.data != null
        ? this.props.data.sort(this.listSort).map((entity, index) => (
            <TR key={index} lineThrough={entity.status === 128}>
              <TD pointer onClick={() => this.onNotificationClick(entity)}>
                <NotificationTitle>
                  {getOBNTDateTime(entity.createdTime)}
                </NotificationTitle>
              </TD>
              <TD>{entity.issuer ? entity.issuer : entity.issuerId}</TD>
              <TD>{entity.createdUsername}</TD>
              {isSuperUser ? (
                <TD>
                  {t(
                    "notification_status_" +
                      getNotificationStatusTextPostfix(entity.status)
                  )}
                </TD>
              ) : undefined}

              <TD center>{entity.numResponsibles}</TD>
              {isSuperUser ? (
                <TD pointer center>
                  <img
                    alt={"Delete symbol"}
                    src="/icons/red-cross-24.svg"
                    onClick={() => showDeleteModal(entity)}
                  />
                </TD>
              ) : undefined}
            </TR>
          ))
        : [];

    return (
      <React.Fragment>
          {this.state.loading ? <OverlayLoader /> : undefined}
          <FlexContainer column padding={[0, 0, 15, 0]}>
          <FlexContainer row spaceBetween>
            <Button.Standard
              onClick={() => {
                this.props.history.push(base + `/notificationForm`);
              }}
            >
              {t("new_notification")}
            </Button.Standard>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer
          column
          padding={
            tableRows && tableRows.length < MAX_RESULT_ON_PAGE
              ? [0, 0, 0, 0]
              : []
          }
          margin={[0, 0, 15, 0]}
        >
          <FlexContainer row>
            <SearchComponent
              onSubmit={this.props.getListWithFilter}
              onChange={this.props.getListWithFilter}
              companyOptionList={this.props.companyOptionList}
              t={t}
              resetFilter={() =>
                this.setState({
                  fromDate: undefined,
              toDate: undefined,
                  filterString: undefined
                })
              }
              {...this.props}
            />
          </FlexContainer>
          <Table
            tableRows={tableRows}
            tableHeaderLabels={isSuperUser ? thLabelsSuper : thLabels}
            tableHeaderProps={isSuperUser ? headerPropsSuper : headerProps}
            forceUpdate={true}
            t={t}
          />
          {this.state.showNotificationViewer ? (
            <Modal
              width={"81%"}
              key={"showNotificationModal"}
              header={t("delayed_publication")}
              isOpen={this.state.showNotificationViewer}
              onClose={() =>
                this.setState({
                  showNotificationViewer: false,
                  clickedNotification: undefined
                })
              }
            >
              <FlexContainer column>
                <NotificationViewer
                  id={this.state.clickedNotification.id}
                  {...this.props}
                />
                <FlexContainer
                  row
                  center
                  flexEnd
                  vAlignCenter
                  margin={[16, 0, 16, 0]}
                >
                  <Button.Text
                    onClick={() =>
                      this.setState({
                        showNotificationViewer: false,
                        clickedNotification: undefined
                      })
                    }
                  >
                    {t("close")}
                  </Button.Text>
                </FlexContainer>
              </FlexContainer>
            </Modal>
          ) : (
            ""
          )}
          {this.state.showDeleteModal ? (
            <Modal
              isOpen={this.state.showDeleteModal}
              onClose={() =>
                this.setState({
                  showDeleteModal: false,
                  entityToDelete: undefined
                })
              }
              width={"40%"}
              header={t("delete_notification")}
            >
              <FlexContainer>
                <FlexContainer row vAlignCenter margin={[16, 0, 50, 0]}>
                  {t("confirm_mark_notification_deleted", {
                    created: getOBNTDateTime(
                      this.state.entityToDelete.createdTime
                    )
                  })}
                </FlexContainer>
                <FlexContainer row center vAlignCenter margin={[16, 0, 16, 0]}>
                  <FlexContainer center flexStart>
                    <Button.Standard
                      inverted
                      onClick={() => {
                        this.setState({
                          showConfirmDeleteNotificationModal: true
                        });
                      }}
                    >
                      {t("delete_permanently")}
                    </Button.Standard>
                  </FlexContainer>
                  <FlexContainer row flexEnd vAlignCenter>
                    {this.state.entityToDelete.status !== 128 &&
                    this.state.entityToDelete.status !== 129 ? (
                      <Button.Standard
                        margin={[0, 10, 0, 0]}
                        onClick={() => {
                          setDeleteStatus(this.state.entityToDelete, 128);
                        }}
                      >
                        {t("delete")}
                      </Button.Standard>
                    ) : undefined}
                    <Button.Text
                      onClick={() =>
                        this.setState({
                          showDeleteModal: false,
                          entityToDelete: undefined
                        })
                      }
                    >
                      {t("close")}
                    </Button.Text>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Modal>
          ) : undefined}
          {this.state.showConfirmDeleteNotificationModal ? (

            <ConfirmModal
              onClose={() =>
                this.setState({ showConfirmDeleteNotificationModal: false })
              }
              cancelText={t("cancel")}
              confirmText={t("confirm")}
              onConfirm={() => {
                setDeleteStatus(this.state.entityToDelete, 129);
              }}
              isOpen={this.state.showConfirmDeleteNotificationModal}
            >
              <FlexContainer center vAlignCenter>
                {t("delete_notification_confirmation_text")}
              </FlexContainer>
            </ConfirmModal>
          ) : undefined}
        </FlexContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { notificationReducer } = state;
  return {
    organisationList: notificationReducer.organisationList.list
  };
}

export default connect(mapStateToProps)(NotificationList);
