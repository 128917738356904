import { Field, reduxForm } from "redux-form";
import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import { DateBox, TextAreaBox } from "../../components";
import Button from "../../../components/Button";

class AdministerStorageTimeModal extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <FlexContainer textAlign={"left"}>
        <form autoComplete={"off"}>
          <FlexContainer width={"50%"} flexStart>
            <Field
              component={DateBox}
              name="deletion_date"
              label={t("date")}
              border
              disableDaysBefore={this.props.disableDaysBefore}
              numPreviousYears={0}
              numFutureYears={10}
            />
            <Field
              margin={[16, 0, 0, 0]}
              component={TextAreaBox}
              border
              noResize
              name="reason_for_changing_deletion_time"
              label={t("it_reason_for_changing_deletion_time")}
            />
          </FlexContainer>
          <FlexContainer row vAlignCenter flexEnd>
            <Button.Standard
              onClick={this.props.handleSubmit}
              margin={[0, 24, 0, 0]}
            >
              {t("update")}
            </Button.Standard>
            <Button.Text onClick={this.props.onCancel}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

AdministerStorageTimeModal = reduxForm({
  form: "administerStorageTimeModal"
})(AdministerStorageTimeModal);

export default AdministerStorageTimeModal;
