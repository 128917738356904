import { combineReducers } from "redux";
import {
  EmailDistributionActions,
  EmailDistributionContactsByListIdActions,
  EmailDistributionStatusActions
} from "./EmailDistributionActions";
import { CompanyListActions } from "../actions/CommonActions";

const initialDistributionListState = {
  list: [],
  isFetched: false
};
export const distributionList = (
  state = {
    ...initialDistributionListState
  },
  action
) => {
  switch (action.type) {
    case EmailDistributionActions.receive:
      return Object.assign({}, state, {
        list: action.data ? action.data : [],
        isFetched: true
      });

    case EmailDistributionActions.receiveFail:
      return Object.assign({}, state, {
        receiveFailed: true
      });

    default:
      return state;
  }
};

const initialDistributionContactListState = {
  list: [],
  isFetched: false
};
export const distributionContactList = (
  state = {
    ...initialDistributionContactListState
  },
  action
) => {
  switch (action.type) {
    case EmailDistributionContactsByListIdActions.receive:
      return Object.assign({}, state, {
        list: action.data ? action.data : [],
        isFetched: true
      });

    case EmailDistributionContactsByListIdActions.receiveFail:
      return Object.assign({}, state, {
        receiveFailed: true
      });

    default:
      return state;
  }
};

const initialDistributionStatusListState = {
  list: [],
  isFetched: false
};
export const distributionStatusList = (
  state = {
    ...initialDistributionStatusListState
  },
  action
) => {
  switch (action.type) {
    case EmailDistributionStatusActions.receive:
      return Object.assign({}, state, {
        list: action.data ? action.data : [],
        isFetched: true
      });

    case EmailDistributionStatusActions.receiveFail:
      return Object.assign({}, state, {
        receiveFailed: true
      });

    default:
      return state;
  }
};

const initalCompState = {
  list: []
};
export const companyList = (
  state = {
    ...initalCompState
  },
  action
) => {
  switch (action.type) {
    case CompanyListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || []
      });
    default:
      return state;
  }
};

const emailDistributionReducer = combineReducers({
  distributionList,
  distributionContactList,
  companyList,
  distributionStatusList
});

export default emailDistributionReducer;
