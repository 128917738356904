import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Modal } from "../../../components/Modal";
import { FlexContainer } from "../../../components/FlexContainer";
import RadioButton from "../../../components/RadioButton";
import React from "react";
import Button from "../../../components/Button";

import { OverlayLoader } from "../../../components/Loader";
import { TextAreaBox, TextBox } from "../../components";

const SendMailRadioButton = props => {
  return (
    <FlexContainer row margin={[24, 0, 10, 0]} vAlignCenter>
      <RadioButton
        {...props}
        checked
        margin={[0, 0, 8, 0]}
        radioValue={"sendMail"}
        label={props.t("send_mail")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onSendMail();
            props.input.onChange(value);
          }
        }}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"do_not_send_mail"}
        label={props.t("do_not_send_mail")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onDoNotSendMail();
            props.input.onChange(value);
          }
        }}
      />
    </FlexContainer>
  );
};

class InactivateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailSelected: true,
      pageOne: true,
      showLoader: false
    };
  }

  getHeader = () => {
    return this.state.pageOne ? "it_warning" : "it_send_mail";
  };

  render() {
    const { t, onClose, onConfirm, insiderPersonFormValues } = this.props;

    const onSendMail = () => {
      this.setState({ sendMailSelected: true });
    };
    const onDoNotSendMail = () => {
      this.setState({ sendMailSelected: false });
    };

    return (
      <React.Fragment>
        {this.state.showLoader ? <OverlayLoader /> : undefined}
        <Modal isOpen onClose={onClose} header={t(this.getHeader())}>
          {this.state.pageOne ? (
            <FlexContainer>
              <FlexContainer>
                {t("it_are_you_sure_you_want_to_inactivate_insider")}
              </FlexContainer>
              <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
                <Button.Standard
                  onClick={() => {
                    onConfirm(insiderPersonFormValues).then(() =>
                      this.setState({ pageOne: false })
                    );
                  }}
                  margin={[0, 24, 0, 0]}
                >
                  {t("it_confirm")}
                </Button.Standard>
                <Button.Text onClick={onClose}>{t("cancel")}</Button.Text>
              </FlexContainer>
            </FlexContainer>
          ) : (
            <FlexContainer>
              <FlexContainer>
                <FlexContainer>
                  {t("it_status_is_changed_to_inactive")}
                </FlexContainer>
                <Field
                  name={"sendMail"}
                  t={this.props.t}
                  component={SendMailRadioButton}
                  onSendMail={onSendMail}
                  onDoNotSendMail={onDoNotSendMail}
                />
              </FlexContainer>
              {this.state.sendMailSelected ? (
                <FlexContainer>
                  <FlexContainer>
                    {this.state.sendMailSelected ? (
                      <Field
                        overflowHidden
                        readOnly
                        height="auto"
                        name="subject"
                        component={TextBox}
                        noResize
                        noLabel={!this.state.sendMailSelected}
                        label={
                          t("subject", { lng: "en" }) +
                          " / " +
                          t("subject", { lng: "no" })
                        }
                        bold
                        margin={[0, 0, 16, 0]}
                      />
                    ) : (
                      undefined
                    )}
                  </FlexContainer>
                  <FlexContainer row>
                    <Field
                      overflowHidden
                      readOnly
                      height="auto"
                      name="body_0"
                      component={TextBox}
                      noResize
                      noLabel={!this.state.sendMailSelected}
                      label={t("it_message_in_english")}
                      bold
                      textMargin={[26, 0, 10, 0]}
                    />
                    <Field
                      overflowHidden
                      readOnly
                      height="auto"
                      name="body_2"
                      component={TextBox}
                      noResize
                      noLabel={!this.state.sendMailSelected}
                      label={t("it_message_in_norwegian")}
                      margin={[0, 0, 10, 10]}
                      bold
                      textMargin={[8, 0, 10, 0]}
                    />
                  </FlexContainer>
                  <FlexContainer row>
                    <Field
                      height="195px"
                      border
                      name="body_1"
                      component={TextAreaBox}
                      noResize
                    />
                    <Field
                      height="195px"
                      border
                      name="body_3"
                      component={TextAreaBox}
                      noResize
                    />
                  </FlexContainer>
                </FlexContainer>
              ) : (
                <FlexContainer>
                  {t("it_chosen_not_to_notify_insider")}
                </FlexContainer>
              )}
              <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
                <Button.Standard
                  onClick={() =>
                    this.props.notifyInactivePerson(insiderPersonFormValues)
                  }
                  margin={[0, 24, 0, 0]}
                >
                  {this.state.sendMailSelected ? t("send_mail") : t("OK")}
                </Button.Standard>
              </FlexContainer>
            </FlexContainer>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

InactivateModal = reduxForm({
  form: "inactivateModalForm"
})(InactivateModal);

function mapStateToProps(state) {
  const { form, insiderToolReducer } = state;

  return {
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    inactivateModalFormValues: form.inactivateModalForm
      ? form.inactivateModalForm.values
      : undefined,
    insiderPersonFormValues: form.insiderPerson
      ? form.insiderPerson.values
      : undefined
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InactivateModal)
);
