import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FlexContainer } from "../../components/FlexContainer";
import WithPagination from "../../components/PaginationContainer";
import UsersTwoFactorDisabledList, {
  SortOnType,
  SortOrder
} from "./UsersTwoFactorDisabledList";
import React from "react";
import moment from "moment";
import {fetchUsersTwoFactorDisabled} from "../SystemAdministratorActions";

const PaginatedUsersWithDisabledTwoFactorList = WithPagination(
  UsersTwoFactorDisabledList
);

class UsersTwoFactorDisabledContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortOrder: SortOrder.ASCENDING,
      SortOnType: SortOnType.USERNAME,
      loading: false
    };
  }

  componentWillMount() {
    if (this.props.usersTwoFactorDisabled.length === 0) {
      this.setState({loading: true})
      this.props.dispatch(fetchUsersTwoFactorDisabled()).then(() => {
        this.setState({loading: false})
      })
    }
  }

  sortFunction = (aData, bData) => {
    if (aData > bData) return this.state.sortOrder;
    if (aData < bData) return -1 * this.state.sortOrder;
  };

  listSort = (a, b) => {
    const aCreatedTime = moment(a.createdTime);
    const bCreatedTime = moment(b.createdTime);

    const sortCreated = (descending = undefined) => {
      if (aCreatedTime.isBefore(bCreatedTime))
        return 1 * (descending || this.state.sortOrder);
      if (aCreatedTime.isAfter(bCreatedTime))
        return -1 * (descending || this.state.sortOrder);
    };

    switch (this.state.SortOnType) {
      case SortOnType.USERNAME:
        return this.sortFunction(a.username, b.username) || sortCreated(true);

      case SortOnType.FIRSTNAME:
        return this.sortFunction(a.firstName, b.firstName) || sortCreated(true);

      case SortOnType.LASTNAME:
        return this.sortFunction(a.lastName, b.lastName || sortCreated(true));

      case SortOnType.ORGANISATION:
        return this.sortFunction(
          a.groups[0].name,
          b.groups[0].name || sortCreated(true)
        );

      case SortOnType.PROTOCOL:
        return this.sortFunction(
          a.twoFactorMessageProtocol,
          b.twoFactorMessageProtocol || sortCreated(true)
        );
    }
  };

  setSortOrder = sortOnType => {
    let sortOrder = SortOrder.ASCENDING;

    if (
      sortOnType === this.state.SortOnType &&
      this.state.sortOrder === SortOrder.ASCENDING
    ) {
      sortOrder = SortOrder.DESCENDING;
    }

    this.setState({
      sortOrder: sortOrder,
      SortOnType: sortOnType
    });
  };

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <PaginatedUsersWithDisabledTwoFactorList
          key="recent"
          t={t}
          loading={this.state.loading}
          data={this.props.usersTwoFactorDisabled.sort(this.listSort)}
          {...this.props}
          setSortOnType={this.setSortOrder}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { systemAdministratorReducer, form } = state;
  const isUserNamePartOfList = username => {
    if (form.userSearch && form.userSearch.values) {
      if (form.userSearch.values.username == null) {
        return true;
      }
      if (username != null) {
        if (form.userSearch.values.username != null) {
          return username
            .toLowerCase()
            .includes(form.userSearch.values.username.toLowerCase());
        }
      }
      if (form.userSearch.values.username != null && username == null) {
        return false;
      }
      return true;
    }
    return true;
  };

  const isOrganisationPartOfList = row => {
    const organisationSearchString =
      form.userSearch &&
      form.userSearch.values &&
      form.userSearch.values.organisation
        ? form.userSearch.values.organisation.toLowerCase()
        : undefined;
    if (!organisationSearchString) {
      return true;
    }

    const organisation =
      row.groups && row.groups[0] && row.groups[0].name
        ? row.groups[0].name.toLowerCase()
        : undefined;
    if (!organisation) {
      return true;
    }

    return organisation.includes(organisationSearchString);
  };

  const usersTwoFactorDisabled = (
    systemAdministratorReducer.usersTwoFactorDisabledList.list || []
  )
    .filter(row => isUserNamePartOfList(row.username))
    .filter(row => isOrganisationPartOfList(row));

  return {
    usersTwoFactorDisabled: usersTwoFactorDisabled
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(UsersTwoFactorDisabledContainer)
);
