import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {FlexContainer} from "../components/FlexContainer";
import {PageHeading} from "../components/PageHeading";
import {Field, reduxForm} from "redux-form";
import {DropDownBox, InputBox, TextAreaBox} from "../insiderTool/components";
import SearchableSelectNew from "../components/SearchableSelectNew";
import Label from "../components/Label";
import {Text} from "./ChatBox";
import Button from "../components/Button";
import {getLanguage, hasValue} from "../util/common";
import {ConfirmModal} from "../components/ConfirmModal";
import {StyledInput} from "../components/Input";
import styled from "styled-components";
import RadioButton from "../components/RadioButton";
import {ErrorText} from "../components/ErrorText";
import Loader from "../components/Loader";
import {HelpIcon} from "../components/HelpIcon";
import i18n from "../i18n";

const LabelWithHelp = (props) => (
    <FlexContainer row>
        {props.label}
        <HelpIcon
            language={i18n.language}
            fileName={props.helpFileName}
            margin={[0, 0, -3, 5]}
            incomingPrefix="shares"
        />
    </FlexContainer>
);

const RadioButtonGroup = props => {
    const { error } = props.meta ? props.meta : {};

    // TODO map from backend parameters
    return (
        <FlexContainer margin={[17, 0, 0, 0]}>
            <FlexContainer row>
                {/*<Label bold>{props.t("share_prospectus_reason") + "*:"}</Label>*/}
                <Label bold>
                    <LabelWithHelp label={props.t("share_prospectus_reason") + "*:"} helpFileName={"share_prospectus_reason"}/>
                </Label>
                <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
            </FlexContainer>
            <RadioButton
                {...props}
                margin={[5, 0, 8, 3]}
                radioValue={1}
                label={props.t("share_prospectus_reason0")}
                input={{
                    value: props.input.value,
                    onChange: (value) => {
                        props.test();
                        props.input.onChange(value);
                    },
                }}
            />
            <RadioButton
                {...props}
                margin={[0, 0, 8, 3]}
                radioValue={2}
                label={props.t("share_prospectus_reason1")}
                input={{
                    value: props.input.value,
                    onChange: (value) => {
                        props.test();
                        props.input.onChange(value);
                    },
                }}
            />
            <RadioButton
                {...props}
                margin={[0, 0, 8, 3]}
                radioValue={3}
                label={props.t("share_prospectus_reason2")}
                input={{
                    value: props.input.value,
                    onChange: (value) => {
                        props.test();
                        props.input.onChange(value);
                    },
                }}
            />
        </FlexContainer>
    );
};


const StyledSymbol = styled.p`
    color: grey;
   position: absolute;
   box-sizing:border-box;
   right: 10px;
   transform: translateY(-50%);
`;

export const TextTip = styled.span`
  visibility: hidden;
  color: #000;
  text-decoration: none;
  padding: 3px;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  ${(props) => (props.rounded ? "border: solid 1px #eaeaea; border-radius: 5px;" : "")}
  ${(props) => (props.compressed ? "z-index: 1000;" : "")}
`;

export const TextTipContainer = styled.a`
    ${(props) => (props.compressed ? "position: relative; display: inline-block;" : "")}
    &:hover ${TextTip} {
    visibility: visible;
  }
`;

const InputFieldWithSymbol = (props) => {
    const { error } = props.meta ? props.meta : {};
    const updateValue = (val) => {
        props.input.onChange(val);
    };
    return (
        <FlexContainer margin={[15,0,0,0]}>
            <FlexContainer row>
                {props.label &&
                    <Label bold margin={[0,5,3,0]}
                           marginTop={props.labelMargin ? props.labelMargin : "0"}>{props.label} </Label>}
                {props.tooltip &&
                    <TextTipContainer>
                        <img id={"share_help"}
                             alt={"Info"}
                             src="/icons/help-green-16px.svg"
                             width={"15"} height={"15"}
                        />
                        <TextTip>{props.tooltip}</TextTip>
                    </TextTipContainer>
                }
                <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>

            </FlexContainer>
                <FlexContainer relative>
                    <StyledInput border {...props} margin={[0,0,0,0]} value={props.input.value}
                    onChange={(e) => updateValue(e.target.value)}
                    />
                    <StyledSymbol>{props.symbol}</StyledSymbol>
                </FlexContainer>
        </FlexContainer>
    )
}

const DropDownWithLabel = (props) => {
    const { error } = props.meta ? props.meta : {};
    const updateValue = (val) => {
        props.input.onChange(val);
        if (props.onSelect) {
            props.onSelect(val)
        }
    };
    return (
        <FlexContainer marginTop={props.top ? 0 : 7}>
            <FlexContainer row>
                <Label bold margin={[8,0,3,0]}>{props.label}</Label>
                <ErrorText padding={[7, 0, 2, 5]}>{error}</ErrorText>
            </FlexContainer>
            <SearchableSelectNew
                name={props.name}
                onSelect={(e) =>
                    updateValue(e)
                }
                options={props.options}
                selectIfOnlyOneOption
                {...props}
            />
        </FlexContainer>

    )
}

class ShareIssuanceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cancelModal: false,
            manualInstrument: false,
            showWarning: "",
            current: undefined,
            change: undefined,
            new: undefined,
            prospectus: undefined,
            prospectusYesReason: "undefined"
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.initialValues?.issuerId !== prevProps.initialValues?.issuerId) {
            if (hasValue(this.props.initialValues.isin)) {
                let matched = false;
                this.props.instruments.filter((i) => {
                    if (i.value === this.props.initialValues.isin || i.value === this.props.initialValues.isin.value) {
                        matched = true;
                    }
                })
                if (!matched) {
                    this.setState({manualInstrument: true})
                }
            }
        }
        if (!this.state.prospectus && this.props.initialValues) {
            if (this.props.initialValues.prospectusRequired === true || this.props.initialValues.prospectusRequired === "true" || this.props.initialValues.prospectusRequired?.value === "true") {
                this.setState({prospectus: "true"})
            } else if (this.props.initialValues.prospectusRequired === false || this.props.initialValues.prospectusRequired === "false" || this.props.initialValues.prospectusRequired?.value === "false") {
                this.setState({prospectus: "false"})
            }
        }
    }

    mapToOptions(parameters) {
        if (parameters!== undefined) {
            return parameters.map((opt) => {
                return {label: opt["label" + getLanguage().toUpperCase()], value: opt.value}
            });
        } return []
    }

    render() {
        const { t } = this.props;

        //TODO implementeres i fase 2

        // const onShareValueChange = (val) => {
        //     const name = val.target.name
        //     const value = val.target.value === "" ? undefined : val.target.value
        //
        //     if (name === "currentNumberOfShares") {
        //         this.setState({current: value})
        //     } else if (name === "changeNumberOfShares") {
        //         this.setState({change: value})
        //     } else {
        //         this.setState({new: value})
        //     }
        // }
        //
        // const doMath = (current, neww, change) => {
        //     const actualChangeSubtraction = parseInt(current) - parseInt(change);
        //     const actualChangeAddition = parseInt(current) + parseInt(change);
        //     const percentageDiff = () => {
        //         const percentage = (change / current) * 100;
        //         return percentage > 20
        //     }
        //
        //     if ((actualChangeSubtraction !== parseInt(neww)) && (actualChangeAddition !== parseInt(neww))) {
        //         return "Tallene samsvarer ikke"
        //     } else if (percentageDiff()) {
        //         return "Differansen  overstiger 20%"
        //     } else {
        //         return ""
        //     }
        // }
        //
        // const handleBlur = () => {
        //     if (hasValue(this.state.current) && hasValue(this.state.new) && hasValue(this.state.change)) {
        //         const mathResult = doMath(this.state.current, this.state.new, this.state.change)
        //         this.setState({showWarning: mathResult})
        //     } else {
        //         this.setState({showWarning: ""})
        //     }
        // }

        const filterMarketIfOnlyOneInstrument = () => {
            if (this.props.instruments && this.props.instruments.length === 1 && this.props.instruments[0] && this.props.instruments[0].props["mic"]) {
                return this.props.parameters.markets?.filter((market) => market.value === this.props.instruments[0].props["mic"]);
            } else {
                return this.props.parameters.markets;
            }
        }

        if (this.props.loading) {
            return <Loader/>;
        }

        return (
            <form autoComplete="off">
                <PageHeading margin={[0, 0, 16, 0]}>
                    {t("share_form_headline")}
                </PageHeading>
                <Text>{t("share_form_note")}</Text>
                <FlexContainer greyBackground padding={[15,25,20,25]}>
                    <FlexContainer row>
                        <FlexContainer>
                            {this.props.isSuperUser &&
                                <Field
                                    component={DropDownWithLabel}
                                    label={t("organisation") + "*:"}
                                    name={"organisationId"}
                                    onSelect={this.props.onOrgSelect}
                                    options={this.mapToOptions(this.props.parameters.organisations)}
                                    selectIfOnlyOneOption
                                    top t={t}
                                />
                            }
                            <Field
                                component={DropDownWithLabel}
                                label={t("issuer") + "*:"}
                                name={"issuerId"}
                                onSelect={this.props.onIssuerSelect}
                                options={this.mapToOptions(this.props.filteredIssuers.length > 0 ?
                                    this.props.filteredIssuers : this.props.parameters.issuers)
                            }
                                selectIfOnlyOneOption={this.props.filteredIssuers !== undefined}
                                top={!this.props.isSuperUser} t={t}
                            />
                            <Field
                                component={DropDownWithLabel}
                                label={t("instrument") + "*:"}
                                name={"instrument"}
                                options={this.mapToOptions(this.props.instruments) || []}
                                selectIfOnlyOneOption
                                t={t}
                            />

                            <Field
                                component={DropDownWithLabel}
                                label={t("share_market") + "*:"}
                                name={"mic"}
                                options={this.mapToOptions(filterMarketIfOnlyOneInstrument())}
                                selectIfOnlyOneOption
                                t={t}
                                forceUpdate
                            />


                            <FlexContainer marginBottom={20}>
                                <Label bold margin={[20,5,-10,0]}>{t("share_contact")}</Label>
                                <Field
                                    component={InputFieldWithSymbol}
                                    name={"contactName"}
                                    label={t("name") + "*:"}
                                    t={t} bold
                                    maxLength={255}
                                />
                                <FlexContainer row>
                                    <FlexContainer margin={[0,10,0,0]} width="85%">
                                        <Field
                                            component={InputFieldWithSymbol}
                                            name={"contactPhone"}
                                            label={t("phone") + "*:"}
                                            t={t} bold
                                            maxLength={20}
                                        />
                                    </FlexContainer>
                                    <Field
                                        component={InputFieldWithSymbol}
                                        name={"contactMail"}
                                        label={t("share_contact_mail_input") + "*:"}
                                        t={t} bold
                                        maxLength={255}
                                    />
                                </FlexContainer>
                            </FlexContainer>
                            <Field
                                margin={[8,0,0,0]}
                                component={TextAreaBox}
                                name={"reason"}
                                maxLength={300}
                                label={t("share_reason") + "*:"}
                                helpTextFile="share_reason"
                                incomingPrefix="shares"
                                t={t} bold
                                noResize
                                fontSize="14px;"
                                openSans
                                height="70px"
                                border
                            />

                            <Field
                                margin={[8,0,0,0]}
                                component={TextAreaBox}
                                name={"reference"}
                                maxLength={1000}
                                label={t("share_reference") + "*:"}
                                helpTextFile="share_reference"
                                incomingPrefix="shares"
                                t={t} bold
                                noResize
                                fontSize="14px;"
                                openSans
                                border
                            />

                        </FlexContainer>
                        <FlexContainer width={"10%"}/>
                        <FlexContainer>
                            <Field
                                component={InputBox}
                                name={"currentNumberOfShares"}
                                label={t("share_current") + "*:"}
                                helpTextFile="share_current"
                                incomingPrefix="shares"
                                // onChange={onShareValueChange}
                                // onBlur={handleBlur}
                                t={t} bold
                                type="number"
                            />
                            <Field
                                margin={[8,0,0,0]}
                                component={TextAreaBox}
                                name={"changeNumberOfShares"}
                                label={t("share_change") + "*:"}
                                helpTextFile="share_change"
                                incomingPrefix="shares"
                                // onChange={onShareValueChange}
                                // onBlur={handleBlur}
                                t={t} bold
                                maxLength={500}
                                noResize
                                fontSize="14px;"
                                openSans
                                height="70px"
                                border
                            />
                            <Field
                                margin={[8,0,0,0]}
                                component={InputBox}
                                name={"percentageNumberOfShares"}
                                label={t("share_percentage") + "*:"}
                                helpTextFile="share_percentage"
                                incomingPrefix="shares"
                                t={t} bold
                                type="number"
                            />
                            <Field
                                margin={[8,0,0,0]}
                                component={TextAreaBox}
                                name={"newNumberOfShares"}
                                label={t("share_new") + "*:"}
                                helpTextFile="share_new"
                                incomingPrefix="shares"
                                // onChange={onShareValueChange}
                                // onBlur={handleBlur}
                                t={t} bold
                                maxLength={500}
                                noResize
                                fontSize="14px;"
                                openSans
                                height="70px"
                                border
                            />
                            <Field
                                margin={[8,0,0,0]}
                                component={TextAreaBox}
                                name={"effectiveDate"}
                                maxLength={255}
                                label={t("share_effective_date") + "*:"}
                                helpTextFile="share_effective_date"
                                incomingPrefix="shares"
                                t={t} bold
                                noResize
                                fontSize="14px;"
                                openSans
                                height="70px"
                                border
                            />
                            <Field
                                component={DropDownWithLabel}
                                name={"prospectusRequired"}
                                // label={t("share_prospectus") + "*:"}
                                label={
                                    <LabelWithHelp
                                        label={t("share_prospectus") + ":*"}
                                        helpFileName={"share_prospectus"}
                                    />
                                }
                                options={this.mapToOptions(this.props.parameters.boolValues)}
                                onSelect={(e) => this.setState({prospectus: e.value})}
                                t={t}
                            />
                            {this.state.prospectus === "false" &&
                                <FlexContainer>
                                    <Field
                                        component={TextAreaBox}
                                        name="prospectusNoReason"
                                        label={t("share_prospectus_no_desc") + "*:"}
                                        helpTextFile="share_prospectus_no_desc"
                                        incomingPrefix="shares"
                                        bold border
                                        noResize maxLength={1500}
                                        fontSize="14px;" openSans
                                        margin={[8,0,0,0]}
                                    />
                                    <Field
                                        component={TextAreaBox}
                                        name={"prospectusNoCumulative"}
                                        label={t("share_prospectus_no_cumulative") + "*:"}
                                        helpTextFile="share_prospectus_no_cumulative"
                                        incomingPrefix="shares"
                                        bold border
                                        noResize maxLength={1500}
                                        fontSize="14px;" openSans
                                        margin={[8,0,0,0]}
                                    />
                                </FlexContainer>

                            }
                            {this.state.prospectus === "true" &&
                                <FlexContainer>
                                    <Field
                                        component={RadioButtonGroup}
                                        name="prospectusYesReason"
                                        t={t}
                                        test={(value) => this.setState({ prospectusYesReason: value })}
                                        margin={[8,0,0,0]}
                                    />
                                    <Field
                                        component={TextAreaBox}
                                        name="prospectusYesMechanisms"
                                        label={t("share_prospectus_yes_desc") + "*:"}
                                        helpTextFile="share_prospectus_yes_desc"
                                        incomingPrefix="shares"
                                        bold border
                                        noResize maxLength={1500}
                                        fontSize="14px;" openSans
                                        margin={[8,0,0,0]}
                                    />
                                </FlexContainer>
                            }
                        </FlexContainer>
                    </FlexContainer>

                    <Field
                        component={TextAreaBox}
                        name="comment"
                        label={t("comments_optional")}
                        incomingPrefix="shares"
                        bold
                        border
                        noResize
                        maxLength={1500}
                        margin={[10,0,0,0]}
                        fontSize="14px;"
                        openSans
                    />

                    {(this.props.isSuperUser && hasValue(this.props.initialValues.status)) &&
                        <FlexContainer>
                        <FlexContainer borderTop margin={[20, 0, 20, 0]} row>
                            <Field
                                component={DropDownBox}
                                name={"status"}
                                label={t("share_status_change")}
                                options={this.mapToOptions(this.props.parameters.statusTypes) || []}
                                t={t}
                            />
                            <FlexContainer width={"10%"}/>
                            <Field
                                component={DropDownBox}
                                name={"closed"}
                                label={t("share_can_be_set_to_closed")}
                                options={this.mapToOptions(this.props.parameters.boolValues)}
                                t={t}
                            />
                        </FlexContainer>
                            <Field
                                component={TextAreaBox}
                                name="internalComment"
                                label={t("share_comment_internal")}
                                bold
                                border
                                noResize
                                maxLength={1000}
                                fontSize="14px;"
                                openSans
                            />
                        </FlexContainer>
                    }

                    {this.state.showWarning !== "" &&
                        <FlexContainer margin={[15, 0, 0, 0]} >
                            <FlexContainer center textAlign={"center"}>
                                <Label bold color={"red"} fontSize={"16"}>{this.state.showWarning}</Label>
                            </FlexContainer>
                        </FlexContainer>
                    }
                    {this.props.isSuperUser && this.props.id && <FlexContainer margin={[10,0,0,0]}>
                        <Field
                            component={TextAreaBox}
                            name="commentToIssuer"
                            label={<Label color="#FD3560" bold fontSize="16">{t("share_comment_to_issuer")}</Label>}
                            extraLabel={t("share_comment_to_issuer_sub", {communication: t("share_communication")})}
                            bold
                            borderWithColor="#FD3560"
                            noResize
                            maxLength={1000}
                            fontSize="14px;"
                            openSans
                        />
                    </FlexContainer> }
                </FlexContainer>

                <FlexContainer row flexEnd vAlignCenter margin={[15, 0, 0, 0]}>
                    <Button.Text margin={[0, 10, 0, 0]}
                                 onClick={() => this.setState({cancelModal: true})}>
                        {t("cancel")}
                    </Button.Text>
                    <Button.Standard margin={[0, 10, 0, 0]}
                                     onClick={this.props.handleSubmit(this.props.onSubmit)}>
                        {t("next")}
                    </Button.Standard>
                </FlexContainer>
                <ConfirmModal
                    isOpen={this.state.cancelModal}
                    onClose={() => this.setState({cancelModal: false})}
                    width={"40%"}
                    header={t("share_cancel_headline")}
                    onConfirm={this.props.onCancel}
                    cancelText={t("cancel")}
                    confirmText={t("okay")}
                >
                    <FlexContainer center vAlignCenter>
                        {t("share_cancel_text")}
                    </FlexContainer>
                </ConfirmModal>

            </form>
        );
    }
}

ShareIssuanceForm = reduxForm({
    form: "ShareIssuanceForm",
    destroyOnUnmount: true,
    enableReinitialize: true
})(ShareIssuanceForm);

ShareIssuanceForm.propTypes = {
    dispatch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    const {  } = state;

    return {

    };
}
export default connect(mapStateToProps)(
    withTranslation("translations")(ShareIssuanceForm)
);
