import React from "react";

import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import { PageHeading } from "../components/PageHeading";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import Input from "../components/Input";
import { emailRequirementValidator } from "../util/emailValidator";

const InfoHeader = styled.h3`
  text-align: left;
`;

const InputWithError = props => {
  const { error, touched } = props.meta;

  return (
    <FlexContainer margin={[0, 0, 16, 0]} vAlignCenter>
      <Input hasError={touched && error} {...props} />
    </FlexContainer>
  );
};

class RequestEmailForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const props = this.props;
    const { t, values } = this.props;

    console.log(props);

    return (
      <FlexContainer maxWidth={767}>
        <form onSubmit={props.handleSubmit}>
          <FlexContainer
            margin={[75, 0, 0, 0]}
            padding={[40, 40, 40, 40]}
            blueBackground
            vAlignCenter
            column
          >
            <PageHeading margin={[0, 0, 40, 0]} uppercase color="white">
              {t("forgot_password")}
            </PageHeading>
            <FlexContainer hidden={!props.error} margin={[0, 0, 5, 0]}>
              <ErrorText>{props.error}</ErrorText>
            </FlexContainer>
            <FlexContainer vAlignCenter>
              <Field
                name="email"
                component={InputWithError}
                greyPlaceholder
                maxWidth={460}
                placeholder={t("email")}
              />

              <Field
                name="username"
                component={InputWithError}
                greyPlaceholder
                maxWidth={460}
                margin={[0, 0, 16, 0]}
                placeholder={t("username")}
              />

              <FlexContainer row center margin={[40, 0, 0, 0]}>
                <Button.Form type="submit">{t("send_email")}</Button.Form>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.email || emailRequirementValidator(values.email)) {
    errors.email = props.t("required_field");
  }

  if (!values.username) {
    errors.username = props.t("required_field");
  }

  return errors;
};

export default reduxForm({
  form: "forgotPassword",
  validate
})(RequestEmailForm);
