import {fetchData, postData} from "../actions/CommonActionsNoObsvc";

export const SendEmail = {
    request: "REQUEST_SEND_EMAIL",
    postSuccess: "SEND_EMAIL_SUCCESS",
    postFail: "SEND_EMAIL_FAILED",
};

export const sendEmail = (values) =>
    postData(
        SendEmail,
        undefined,
        values,
        "v1/sysadmin/testEmail",
        true, undefined, undefined, false
    );

export const EmailStatus = {
    request: "REQUEST_EMAIL_STATUS",
    receive: "RECEIVE_EMAIL_STATUS",
    receiveFail: "RECEIVE_EMAIL_STATUS_FAILED",
};

export const getEmailStatus = () =>
    fetchData(
        EmailStatus,
        undefined,
        "v1/sysadmin/emailStatus",
        false
    );

export const Healthchecks = {
    request: "REQUEST_HEALTHCHECKS",
    receive: "RECEIVE_HEALTHCHECKS",
    receiveFail: "RECEIVE_HEALTHCHECKS_FAILED",
};

export const getHealthchecks = () =>
    fetchData(
        Healthchecks,
        undefined,
        "v1/sysadmin/healthchecks",
        false
    );