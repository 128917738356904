import React, {Component} from "react";
import {Helmet} from "react-helmet";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {reduxForm} from "redux-form";
import {withTranslation} from "react-i18next";
import styled, {css} from "styled-components";
import i18n from "./i18n";
import FinanskalenderContainer from "./finanskalender/FinanskalenderContainer";
import FocusElement from "./components/FocusElement";
import CompanyContainer from "./companyInformation/CompanyContainer";
import OBNotificationContainer from "./NotifyOB/NotificationContainer";
import SystemAdministratorContainer from "./systemAdmin/SystemAdministratorContainer";
import {withRouter} from "react-router";
import MAContainer from "./infoboard/InfoboardContainer";
import MyProfile from "./Profil/MyProfile";
import LoginContainer from "./login/LoginContainer";
import ResetPasswordFormContainer from "./resetPassword/ResetPasswordFormContainer";
import NewUserFormContainer from "./NewUser/NewUserFormContainer";
import RequestEmailFormContainer from "./resetPassword/RequestEmailFormContainer";
import InsiderList from "./innsider/InsiderList";
import {
  deleteCASLogin,
  deleteToken,
  getCASLogin,
  getToken,
  getUserName,
  requestLogger,
  setCASLogin,
  setToken,
} from "./obsvcClient";
import {
  deleteLocalLanguage,
  getLocalLanguage,
  logout,
  setLocalLanguage,
  setupLoginData,
} from "./actions/CommonActions";
import NewsMessageContainer from "./messages/NewsMessageContainer";
import Auditlog from "./auditlog/Auditlog";
import Home from "./Home";

import {FlexContainer} from "./components/FlexContainer";
import {ConfirmModal} from "./components/ConfirmModal";
import Loader from "./components/Loader";
import {Modal} from "./components/Modal";
import InsiderToolContainer from "./insiderTool/InsiderToolContainer";
import InsiderApproveFormContainer from "./insiderTool/external/InsiderApproveFormContainer";
import {NotificationContainer} from "react-notifications";
import {StyledLink} from "./components/StyledLink";
import {APP_WIDTH, getInsiderLogURL, media, sizes,} from "./util/common";

import {parse} from "query-string";
import {casLogout, logOut, maintenanceLogin} from "./login/LoginActions";
import {isModuleVisible, isSsoEnabled} from "./util/accessUtil";
import IncompatibleUserContainer from "./incompatibleUser/IncompatibleUserContainer";
import EmailDistributionContainer from "./emailDistribution/EmailDistributionContainer";
import UnsubscribeEmailListContainer, {UNSUBSCRIBE_ROOT_PATH,} from "./unsubscribe/UnsubscribeEmailListContainer";
import InactiveLogout from "./login/InactiveLogout";
import LogoutModal from "./components/LogoutModal";
import {fetchEnv, fetchSSOEnabled, setMaintenance} from "./actions/CommonActionsNoObsvc";
import HelpDocumentsContainer from "./components/HelpDocumentsContainer";
import EmissionContainer from "./emission/ShareIssuanceContainer";
import MaintenanceView from "./maintenance/MaintenanceView";
import {getMaintenance} from "./noObsvcClient";

const StyledNotificationContainer = styled(NotificationContainer)`
  .notification-container {
    width: 500px;
  }
`;

Array.prototype.distinctValues = function () {
  return this.filter((value, index, array) => array.indexOf(value) === index);
};

Array.prototype.equal = function (arr) {
  if (this.length !== arr.length) {
    return false;
  }

  var counter;
  for (counter = 0; counter < this.length; counter++) {
    if (!arr.includes(this[counter])) break;
  }
  return counter === this.length;
};

Object.compare = function (obj1, obj2) {
  // Handle null objects
  if (obj1 == null && obj2 == null) return true;
  if (obj1 == null) return false;
  if (obj2 == null) return false;

  //Loop through properties in object 1
  for (var p in obj1) {
    //Check property exists on both objects
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false;

    switch (typeof obj1[p]) {
      //Deep compare objects
      case "object":
        if (!Object.compare(obj1[p], obj2[p])) return false;
        break;
      //Compare function code
      case "function":
        if (
          typeof obj2[p] == "undefined" ||
          (p != "compare" && obj1[p].toString() != obj2[p].toString())
        )
          return false;
        break;
      //Compare values
      default:
        if (obj1[p] != obj2[p]) return false;
    }
  }

  //Check object 2 for any extra properties
  for (var p in obj2) {
    if (typeof obj1[p] == "undefined") return false;
  }
  return true;
};

const AppGrid = styled.div`
  display: grid;
  display: -ms-grid;

  width: 100%;

  /*IE 11*/
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 132px 1fr;
  /*******/

  grid-template-columns: 1fr;
  grid-template-rows: 132px 1fr;
`;

const HeaderGrid = styled.div`
  display: grid;
  display: -ms-grid;

  height: 109px;

  /*IE 11*/
  -ms-grid-columns: 1fr 1fr 1fr;
  -ms-grid-rows: 1fr;

  -ms-grid-row: 1;
  -ms-grid-column: 1;
  /*******/

  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;

  ${(props) =>
    props.testEnvironment
      ? "background: linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%);"
      : "background-color: #1c315b;"};
`;

const HeaderLeft = styled.div`
  -ms-grid-row: 1;
  -ms-grid-column: 1;

  padding-left: 80px;
  justify-self: start;
  -ms-grid-column-align: start;

  align-self: center;
  -ms-grid-row-align: center;

  color: white;
  ${media.phone`
    padding-left: 20px;
   `};
`;

const HeaderCenter = styled(HeaderLeft)`
  -ms-grid-row: 1;
  -ms-grid-column: 2;

  justify-self: center;
  -ms-grid-column-align: center;

  padding: 0px;
  text-align: center;
`;

const Heading = styled.h1`
  font-size: 40px;
  font-weight: 600;
  color: white;
  ${media.phone`
    font-size: 20px;
    padding-top: 30px;
   `};
`;

const HeaderRight = styled(HeaderLeft)`
  justify-self: end;
  -ms-grid-column-align: end;

  -ms-grid-row: 1;
  -ms-grid-column: 3;

  padding: 0 80px 0 0;
  ${media.phone`
    font-size: 13px;
    padding: 0 0 0 0;
   `};
`;

const MainPanel = styled.div`
  -ms-grid-row: 2;
  -ms-grid-column: 1;

  display: flex;
  justify-content: center;
  justify-self: center;
  margin: 0 5px 20px 5px;
  -ms-grid-column-align: center;

  width: 100%;
  max-width: ${APP_WIDTH}px;
  ${media.phone`
    max-width: 400px;
   `};
`;

const MenuElementsContainer = styled.aside`
  height: 100%;
  position: absolute;

  font-size: 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.testEnvironment
      ? "background: linear-gradient(360deg, #FE6B8B 30%, #FF8E53 90%);"
      : "background-color: #1c315b;"};

  overflow-x: hidden;
  transition: 0.2s;
  right: 0px;
  top: 91px;
  z-index: 100;
  ${(props) =>
    props.open
      ? css`
          width: 410px;
          padding: 24px 0px 24px 0px;
        `
      : css`
          width: 0;
          padding: 0;
        `};
`;

const MenuElement = (props) => (
  <FocusElement padding={[0, 0, 16, 64]} {...props}>
    {props.children}
  </FocusElement>
);

const LanguageSelector = styled.span`
  color: white;
  font-size: inherit;
  margin: 0 20px 0 0;
`;

const Icon = styled.img`
  ${(props) => (props.absolute ? "position: absolute;" : "")} ${(props) =>
    props.hidden ? "visibility: hidden;" : ""};
  ${(props) => (props.left ? "left: " + props.left + "px;" : "")};
  ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

const HideableMenuElement = (props) => (
  <FlexContainer relativeContainer padding={[0, 0, 0, 64]} vAlignCenter row>
    <Icon
      margin={[0, 16, 0, 0]}
      left={24}
      absolute
      hidden={props.isOpen}
      src="/icons/ic-keyboard-arrow-down-white-24-px.svg"
    />
    <Icon
      margin={[0, 16, 0, 0]}
      left={24}
      absolute
      hidden={!props.isOpen}
      src="/icons/ic-keyboard-arrow-up-white-24-px.svg"
    />
    {props.children}
  </FlexContainer>
);

const Text = styled.p`
  font-weight: 100;
  font-size: 16px;
`;

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      showLogger: false,
      showHelpDocuments: false,
      showContactMaHelpModal: false,
      showLogoutModal: false,
    };
    this.handleClickEvent = this.handleClickEvent.bind(this);
  }

  handleClickEvent(e) {
    const clickIsInsideMenu = (target) =>
      target == null
        ? false
        : ["menu", "menuIcon"].includes(target.id)
        ? true
        : clickIsInsideMenu(target.parentNode);

    if (
      !clickIsInsideMenu(e.target) &&
      e.target.id !== "menuIcon" &&
      this.state.display
    ) {
      this.setState({ display: false });
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClickEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickEvent);
  }

  render() {
    const toggleMenu = () => this.setState({ display: !this.state.display });
    const { t, publicProps } = this.props;

    return (
      <form>
        <FocusElement id="menuIcon" onClick={toggleMenu}>
          <Icon
            width={!this.state.display ? "28px" : "22px"}
            height={!this.state.display ? "28px" : "22px"}
            hidden={!this.props.showIcon}
            src={
              !this.state.display
                ? "/icons/ic-burger-24-px.svg"
                : "/icons/menu_close.svg"
            }
            alt="menu"
          />{" "}
        </FocusElement>

        <MenuElementsContainer
          id="menu"
          open={this.state.display}
          key="menuComponent"
          testEnvironment={this.props.testEnvironment}
        >
          <MenuElement
            noPointer
            fontSize={16}
            padding={[0, 0, 40, 64]}
            noFocus={true}
          >
            {t("logged_in_as") + ": " + getUserName()}
          </MenuElement>

          <MenuElement
            noFocus={!this.state.display}
            onClick={(e) => {
              this.props.history.push("/home");
              toggleMenu();
            }}
          >
            {t("home")}
          </MenuElement>

          {isModuleVisible("news", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                this.props.history.push("/newsmessage");
                toggleMenu();
              }}
            >
              {t("messages")}
            </MenuElement>
          ) : (
            ""
          )}

          {/*TODO: Use proper module/access - check!!*/}
          {isModuleVisible("emaildist", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/emailDist");
                toggleMenu();
              }}
            >
              {t("email_management")}
            </MenuElement>
          ) : undefined}

          {isModuleVisible("financialcalendar", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                this.props.history.push("/finanskalender/");
                toggleMenu();
              }}
            >
              {t("finance_calendar")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("company", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                this.props.history.push("/companyInformation/form");
                toggleMenu();
              }}
            >
              {t("company_information")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("insider", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/innsider");
                toggleMenu();
              }}
            >
              {t("insiderregister")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("notification", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/notification");
                toggleMenu();
              }}
            >
              {t("delayed_publication")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("insidertool", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/insidertool");
                toggleMenu();
              }}
            >
              {t("insidertool")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("insiderlog", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                window.open(
                  getInsiderLogURL(publicProps, this.props.insiderLogUrl),
                  "_blank"
                );
              }}
            >
              {t("insider_log")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("issuance", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/shareissuance");
                toggleMenu();
              }}
            >
              {t("share_module_name")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("myProfile", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                if (
                  publicProps.connectRoot &&
                  publicProps.connectRoot.length > 0
                ) {
                  // window.location.assign(publicProps.connectRoot + "/user/edit");
                  window.location = publicProps.connectRoot + "/user/edit";
                } else {
                  this.props.history.push("/profil");
                  toggleMenu();
                }
              }}
            >
              {t("my_profile")}
            </MenuElement>
          ) : undefined}

          {isModuleVisible("auditlog", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                this.props.history.push("/auditlog");
                toggleMenu();
              }}
            >
              {t("auditlog")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("infoboard", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={(e) => {
                this.props.history.push("/ma/");
                toggleMenu();
              }}
            >
              {t("info_from_ma_short")}
            </MenuElement>
          ) : (
            ""
          )}

          {isModuleVisible("systemadministrator", this.props.visibleModules) ? (
            <MenuElement
              noFocus={!this.state.display}
              onClick={() => {
                this.props.history.push("/system");
                toggleMenu();
              }}
            >
              {t("system_administration")}
            </MenuElement>
          ) : (
            ""
          )}

          <MenuElement
            onClick={() =>
              this.setState({
                showHelpDocuments: !this.state.showHelpDocuments,
              })
            }
            key="helpDocuments"
            white
            padding={[0, 0, 16, 0]}
            noFocus={!this.state.display}
          >
            <HideableMenuElement isOpen={this.state.showHelpDocuments}>
              {t("help_documents")}
            </HideableMenuElement>
          </MenuElement>

          <HelpDocumentsContainer
            isOpen={this.state.showHelpDocuments}
            t={t}
            key="helpDocumentsBody"
            showContactMaHelpModal={() =>
              this.setState({ showContactMaHelpModal: true })
            }
            onCloseContactMaHelpModal={() =>
              this.setState({ showContactMaHelpModal: false })
            }
            dispatch={this.props.dispatch}
          />

          <MenuElement>
            <Link
              onClick={() => {
                window.open(
                  "https://www.euronext.com/en/privacy-statement",
                  "_blank"
                );
              }}
            >
              {this.props.t("access_privacy")}
            </Link>
          </MenuElement>

          <MenuElement
            noFocus={!this.state.display}
            onClick={() => {
              if (getCASLogin()) {
                if (
                  this.props.currentUserInfo &&
                  this.props.currentUserInfo.otherProperties &&
                  this.props.currentUserInfo.otherProperties
                    .doNotShowLogoutView &&
                  this.props.currentUserInfo.otherProperties
                    .doNotShowLogoutView === "false"
                ) {
                  this.setState({ showLogoutModal: true });
                } else {
                  this.props.dispatch(casLogout(false)).then(() => {
                    deleteToken();
                    deleteCASLogin();
                    deleteLocalLanguage();
                    window.location = publicProps.connectRoot + "/user/logout";
                  });
                }
              } else {
                this.props.dispatch(logout()).then(() => {
                  deleteToken();
                  deleteLocalLanguage();
                  window.location = "login";
                });
              }
            }}
          >
            {t("logout")}
          </MenuElement>
          <Modal
            isOpen={this.state.showContactMaHelpModal}
            onClose={() => this.setState({ showContactMaHelpModal: false })}
            header={t("contact_ma")}
          >
            <FlexContainer>
              <Text>
                {t("contact_ma_during_opening_hours")} <br />
                {t("phone")}: + 47 22 34 19 45
                <br />
                {t("email")}:{" "}
                <StyledLink href={"mailto:ma@oslobors.no"}>
                  ma@oslobors.no
                </StyledLink>
              </Text>
              <Text>
                {t("publication_service_questions")} <br />
                {t("phone")}: +47 22 34 18 16
                <br />
                {t("email")}:{" "}
                <StyledLink href={"mailto:corporate.services@oslobors.no"}>
                  corporate.services@oslobors.no
                </StyledLink>
              </Text>
            </FlexContainer>
          </Modal>
          {this.state.showLogoutModal ? (
            <Modal
              isOpen={this.state.showLogoutModal}
              onClose={() => this.setState({ showLogoutModal: false })}
              header={t("logout")}
              key={"logoutModal"}
            >
              <LogoutModal
                onSubmit={(values) => {
                  this.props
                    .dispatch(casLogout(values.doNotShowAgain))
                    .then(() => {
                      deleteToken();
                      deleteLocalLanguage();
                      window.location =
                        publicProps.connectRoot + "/user/logout";
                    });
                }}
                onClose={() => {
                  this.setState({
                    showLogoutModal: false,
                  });
                }}
                initialValues={{
                  doNotShowAgain: false,
                }}
                enableReinitialize={true}
                forceUpdate={true}
              />
            </Modal>
          ) : undefined}
        </MenuElementsContainer>
      </form>
    );
  }
}

Menu = reduxForm({
  form: "menuForm",
})(Menu);

const Logo = (props) => {
  if (props.config.logoName) {
    return <img src={"/icons/" + props.config.logoName} alt={"Logo"} />;
  } else if (window.innerWidth >= sizes.phone) {
    return (
      <img
        src="/icons/Oslo-Børs_Logo-RGB_Reverse.svg"
        width="262px"
        height="90px"
        alt={"Logo"}
      />
    );
  } else {
    return (
      <img
        src="/icons/Oslo-Børs_Logo-RGB_Reverse.svg"
        width="207px"
        height="65px"
        alt={"Logo"}
      />
    );
  }
};
const LanguageLink = styled.span`
  cursor: pointer;
  ${(props) => (props.underline ? "text-decoration: underline;" : "")} ${(
    props
  ) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

const Link = styled.span`
  cursor: pointer;
  ${(props) => (props.underline ? "text-decoration: underline;" : "")} ${(
    props
  ) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLogger: false,
      sessionTimeout: undefined,
      sessionLogOut: false,
    };
    this.handleKeyDownEvent = this.handleKeyDownEvent.bind(this);
    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.sessionTimeout = this.sessionTimeout.bind(this);
    this.resetTimer = this.resetTimer.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      getToken() &&
      this.props.incompatibleUser &&
      this.props.location.pathname !== "/userCheck"
    ) {
      this.props.history.push("/userCheck");
    }
  }

  sessionTimeout() {
    this.setState({ sessionLogOut: true }, () => {
      this.props.dispatch(logOut());
      this.props.history.push("/inactiveLogout");
    });
  }

  resetTimer() {
    const sessionTimeOutValue = 60 * 60 * 1000; //One hour
    // const sessionTimeOutValue = 10 * 1000; //One hour
    clearTimeout(this.timerId);

    if (getToken()) {
      // If user is internal, we don't want timeout.
      if (
        !(this.props.userOrganisation && this.props.userOrganisation.internal)
      )
        this.timerId = setTimeout(this.sessionTimeout, sessionTimeOutValue);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDownEvent);
    window.removeEventListener("click", this.handleClickEvent);
    clearTimeout(this.timerId);
  }

  handleEnvData(envEntity) {
    if (envEntity.maintenance) {
      this.props.dispatch(maintenanceLogin())
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            const loginAvailable = err.data?.details?.find((detail) => detail.code === 1499);
            if (loginAvailable) {
              return true;
            } else {
              this.props.dispatch(setMaintenance({maintenance: envEntity.maintenance, norwegianMaintenanceText: envEntity.norwegianMaintenanceText, englishMaintenanceText: envEntity.englishMaintenanceText}));
            }
          });
    }
    else if (envEntity && envEntity.languages && envEntity.languages.length === 1) {
      i18n.changeLanguage(envEntity.languages[0]);
    }
  }

  componentDidMount() {
    if (getLocalLanguage()) {
      i18n.changeLanguage(getLocalLanguage());
    }
    this.props.dispatch(fetchEnv())
      .then((res) => {
        this.handleEnvData(res.data.entity);
      })
      .then(() => this.props.dispatch(fetchSSOEnabled()))
      .catch((err) => {
        console.log(err);
        this.props.dispatch(fetchEnv())
            .then((res) => this.handleEnvData(res.data.entity))
            .catch((err) => {
              console.log(err);
              getMaintenance()
                  .then(res => {
                    if (res.maintenance) {
                      this.props.dispatch(setMaintenance(res));
                    } else {
                      this.props.dispatch(setMaintenance({maintenance: true}));
                    }
                  });
            });
      });

    const parsedParams = parse(window.location.search, {
      decode: false,
    });

    //Hack for IE as IE doesn't know where it's sessionStorage is for some reason...
    if (parsedParams && parsedParams.token) {
      setToken(parsedParams.token);
      setCASLogin();
    }
    if (getToken()) {
      const loggedInWithCAS = getCASLogin() && getCASLogin() === "true";
      this.props
        .dispatch(setupLoginData(loggedInWithCAS))
        .then(this.resetTimer)
    }
    window.addEventListener("keydown", this.handleKeyDownEvent);
    window.addEventListener("click", this.handleClickEvent);
  }

  handleKeyDownEvent(e) {
    if (e.shiftKey && e.ctrlKey && e.keyCode === 76)
      this.setState({ showLogger: true });
    else if (this.state.showLogger && e.keyCode === 27)
      this.setState({ showLogger: false });

    this.resetTimer();
  }

  handleClickEvent(e) {
    this.resetTimer();
  }

  render() {
    const { t, publicProps, config, maintenance } = this.props;

    const getLanguageSelections = () => {
      return (
        <LanguageSelector>
          {config && config.languages ? (
            config.languages.map((language, index) => {
              return (
                <React.Fragment key={index}>
                  <LanguageLink
                    margin={[0, 5, 0, 0]}
                    underline={i18n.language === language}
                    onClick={() => {
                      setLocalLanguage(language);
                      i18n.changeLanguage(language);
                    }}
                  >
                    {t("language_selection_" + language)}
                  </LanguageLink>
                  {index < config.languages.length - 1 ? " | " : undefined}
                </React.Fragment>
              );
            })
          ) : (
            <React.Fragment>
              <LanguageLink
                margin={[0, 5, 0, 0]}
                underline={i18n.language === "en"}
                onClick={() => {
                  setLocalLanguage("en");
                  i18n.changeLanguage("en");
                }}
              >
                {t("language_selection_en")}
              </LanguageLink>
              |
              <LanguageLink
                margin={[0, 0, 0, 5]}
                underline={i18n.language === "nb" || i18n.language == null}
                onClick={() => {
                  setLocalLanguage("nb");
                  i18n.changeLanguage("nb");
                }}
              >
                {t("language_selection_nb")}
              </LanguageLink>
            </React.Fragment>
          )}
        </LanguageSelector>
      );
    };

    const resetPage = () => window.location.assign("/");

    const search = window.location.search;

    const getConnectLinkIfExists = () => {
      return this.props &&
        this.props.publicProps &&
        this.props.publicProps.connectRoot ? (
        <Link
          margin={[0, 30, 0, 5]}
          underline
          onClick={() => {
            window.location = this.props.publicProps.connectRoot;
          }}
        >
          {t("access_connect")}
        </Link>
      ) : undefined;
    };

    // const getVisibleModules = () => {
    //   if (this.props.config.config.modules !== undefined && this.props.visibleModules !== undefined) {
    //     return this.props.visibleModules.filter(module => this.props.config.config.modules.includes(module));
    //   }
    //   return this.props.visibleModules || [];
    // }

    return (
      <AppGrid key={"main_"}>
        <Helmet htmlAttributes={{ lang: i18n.language || "nb" }}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <link
            href="//fonts.googleapis.com/css?family=Open+Sans:400italic,600italic,700italic,400,600,700"
            rel="stylesheet"
            type="text/css"
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
          />
        </Helmet>
        <HeaderGrid testEnvironment={config.testEnvironment} role="header">
          <HeaderLeft>
            <FocusElement onClick={(e) => resetPage()}>
              <Logo {...this.props} />
            </FocusElement>
          </HeaderLeft>
          <HeaderCenter>
            <FocusElement onClick={(e) => resetPage()}>
              <FlexContainer row>
                <Heading>NewsPoint</Heading>{" "}
                {config.testEnvironment ? (
                  <FlexContainer margin={[50, 0, 0, 5]}>
                    <p>test</p>
                  </FlexContainer>
                ) : (
                  ""
                )}
              </FlexContainer>
            </FocusElement>
          </HeaderCenter>
          <HeaderRight>
            <FlexContainer row vAlignCenter>
              {getConnectLinkIfExists()}
              {this.props.config.languages &&
              this.props.config.languages.length > 1
                ? getLanguageSelections()
                : ""}
              {getToken() && !this.props.incompatibleUser ? (
                <Menu
                  currentUserInfo={this.props.currentUserInfo}
                  testEnvironment={config.testEnvironment}
                  showIcon={this.props.loginComplete}
                  visibleModules={this.props.visibleModules}
                  dispatch={this.props.dispatch}
                  history={this.props.history}
                  t={t}
                  publicProps={publicProps}
                  insiderLogUrl={this.props.insiderLogUrl}
                />
              ) : (
                ""
              )}
            </FlexContainer>
          </HeaderRight>
        </HeaderGrid>
        {
          maintenance ? <MaintenanceView/> :
        <MainPanel key={"mainPanel"} role="main">
          <Route
            render={(props) => {
              return getToken() ? (
                this.props.loginComplete ? (
                  <Switch>
                    <Route
                      path={"/home"}
                      render={(props) => (
                        <Home
                          base="/home"
                          insiderLogUrl={this.props.insiderLogUrl}
                        />
                      )}
                    />

                    {isModuleVisible("news", this.props.visibleModules) ? (
                      <Route
                        path={"/newsmessage/:page?"}
                        render={(props) => (
                          <NewsMessageContainer
                            base="/newsmessage"
                            {...props}
                          />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible(
                      "notification",
                      this.props.visibleModules
                    ) ? (
                      <Route
                        path={"/notification:page?"}
                        render={(props) => (
                          <OBNotificationContainer
                            {...props}
                            base="/notification"
                          />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible(
                      "systemadministrator",
                      this.props.visibleModules
                    ) ? (
                      <Route
                        path={"/system:page?"}
                        render={(props) => (
                          <SystemAdministratorContainer
                            {...props}
                            base="/system"
                            insider={isModuleVisible(
                              "insider",
                              this.props.visibleModules
                            )}
                          />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible("company", this.props.visibleModules) ? (
                      <Route
                        path="/companyInformation/:page"
                        render={(props) => (
                          <CompanyContainer
                            base="/companyInformation"
                            {...props}
                          />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible(
                      "financialcalendar",
                      this.props.visibleModules
                    ) ? (
                      <Route
                        path="/finanskalender/:page?"
                        render={(props) => (
                          <FinanskalenderContainer
                            base="/finanskalender"
                            {...props}
                          />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible("auditlog", this.props.visibleModules) ? (
                      <Route
                        exact
                        path="/auditlog"
                        render={() => <Auditlog />}
                      />
                    ) : undefined}

                    {isModuleVisible("infoboard", this.props.visibleModules) ? (
                      <Route
                        path="/ma"
                        render={(props) => (
                          <MAContainer base="/ma" {...props} />
                        )}
                      />
                    ) : undefined}

                    {isModuleVisible("insider", this.props.visibleModules) ? (
                      <Route
                        path="/innsider"
                        render={(props) => <InsiderList />}
                      />
                    ) : (
                      ""
                    )}

                    {isModuleVisible("issuance", this.props.visibleModules) ? (
                        <Route
                            path="/shareissuance"
                            render={(props) =>
                                <EmissionContainer
                                    base={"/shareissuance"}
                                    {...props}
                            />}
                        />
                    ) : (
                        ""
                    )}

                    {isModuleVisible(
                      "insidertool",
                      this.props.visibleModules
                    ) ? (
                      <Route
                        path="/insidertool"
                        render={(props) => (
                          <InsiderToolContainer
                            base="/insidertool"
                            {...props}
                          />
                        )}
                      />
                    ) : (
                      ""
                    )}
                    <Route
                      path="/insider/update"
                      render={(props) => {
                        const parsedParams = parse(props.location.search, {
                          decode: false,
                        });

                        return (
                          <InsiderApproveFormContainer
                            base="/insider/update"
                            token={parsedParams.id}
                            {...props}
                          />
                        );
                      }}
                    />

                    {isModuleVisible("myProfile", this.props.visibleModules) ? (
                      <Route
                        path="/profil"
                        render={(props) => {
                          if (
                            publicProps.connectRoot &&
                            publicProps.connectRoot.length > 0
                          ) {
                            // window.location.assign(publicProps.connectRoot);
                            window.location =
                              publicProps.connectRoot + "/user/edit";
                            return "";
                          } else {
                            return <MyProfile {...props} />;
                          }
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <Route
                      path="/userCheck"
                      render={(props) => (
                        <IncompatibleUserContainer {...props} />
                      )}
                    />

                    {/*TODO: Use proper module/access - check!!*/}
                    {isModuleVisible("emaildist", this.props.visibleModules) ? (
                      <Route
                        path="/emailDist"
                        render={(props) => (
                          <EmailDistributionContainer
                            base={"/emailDist"}
                            {...props}
                          />
                        )}
                      />
                    ) : (
                      ""
                    )}

                    <Redirect to="/home" />
                  </Switch>
                ) : (
                  <Loader />
                )
              ) : (
                <Switch>
                  {isSsoEnabled("ssoEnabled", this.props.config.adminOptions) ? (
                      <Route
                          exact
                          path="/login"
                          render={(props) => {
                            return (
                                <LoginContainer
                                    sessionLogOut={this.state.sessionLogOut}
                                    setTimer={this.resetTimer}
                                />
                            );
                          }}
                      />
                  ) : (
                      ""
                  )}
                  <Route
                    exact
                    path="/forgotPassword"
                    render={() => <RequestEmailFormContainer />}
                  />
                  <Route
                    exact
                    path="/resetPassword/:globalSubject/:resetSecret/:lang"
                    render={(props) => <ResetPasswordFormContainer {...props} />}
                  />
                  <Route
                    exact
                    path="/newUser/:globalSubject/:resetSecret/:lang"
                    render={() => <NewUserFormContainer />}
                  />
                  <Route
                    path="/insider/update"
                    render={(props) => {
                      const parsedParams = parse(props.location.search, {
                        decode: false,
                      });

                      return (
                        <InsiderApproveFormContainer
                          base="/insider/update"
                          token={parsedParams.id}
                          {...props}
                        />
                      );
                    }}
                  />
                  <Route
                    path={UNSUBSCRIBE_ROOT_PATH}
                    render={(props) => (
                      <UnsubscribeEmailListContainer {...props} />
                    )}
                  />
                  <Route
                    path={"/loginPWD"}
                    render={(props) => (
                      <LoginContainer
                        sessionLogOut={this.state.sessionLogOut}
                        setTimer={this.resetTimer}
                      />
                    )}
                  />
                  <Route
                    path={"/inactiveLogout"}
                    render={(props) => <InactiveLogout {...props} />}
                  />
                  {isSsoEnabled("ssoEnabled", this.props.config.adminOptions) ?
                      <Redirect to={"/login" + search} /> : <Redirect to={"/loginPWD" + search} /> }
                </Switch>
              );
            }}
          />
          <ConfirmModal
            isOpen={this.state.showLogger}
            onClose={() => this.setState({ showLogger: false })}
            header={"LOG"}
            onConfirm={() => this.setState({ showLogger: false })}
            confirmText={t("okay")}
          >
            <FlexContainer id="logger">{requestLogger.reverse()}</FlexContainer>
          </ConfirmModal>
        </MainPanel>
        }

        <StyledNotificationContainer />
      </AppGrid>
    );
  }
}

function mapStateToProps(state) {
  const {
    loginUser,
    systemAdministratorReducer,
    config,
    visibleModulesReducer,
    insiderSyncDetailsReducer,
      maintenance,
  } = state;

  return {
    loginComplete: loginUser.loginComplete,
    userOrganisation: loginUser.userOrganisation,
    currentUserInfo: loginUser.user,
    config: config.config,
    publicProps: systemAdministratorReducer.publicProps.object || {},
    incompatibleUser: loginUser.incompatibleUser,
    visibleModules: visibleModulesReducer.visibleModules || [],
    insiderLogUrl:
      insiderSyncDetailsReducer.insiderSyncDetialsByUser.insiderLogUrl || {},
    maintenance: maintenance.maintenance,
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(App))
);
