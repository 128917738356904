import React from "react";
import { withTranslation } from "react-i18next";
import Table, { TD, TR } from "../../../components/Table";
import Button from "../../../components/Button";
import CheckBox from "../../../components/CheckBox";
import { Icon } from "../../components";
import { FlexContainer } from "../../../components/FlexContainer";
import { ErrorText } from "../../../components/ErrorText";
import { Field, reduxForm } from "redux-form";
import { fetchTraceLog } from "../../InsiderToolActions";
import { connect } from "react-redux";
import { getLanguage } from "../../../util/common";
import ButtonRowInsiders from "./ButtonRowInsiders";
import Tracelog from "../../modals/insiderList/Tracelog";
import Shiitake from "shiitake";

class InsiderTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTracelog: false,
      insiderId: undefined
    };
  }

  toggleTraceLog = insiderId => {
    const { dispatch, listId } = this.props;

    if (this.state.showTracelog) this.setState({ showTracelog: false });
    else {
      Promise.resolve(this.setState({ showOverlayLoader: true }))
        .then(() => dispatch(fetchTraceLog(listId)))
        .then(() =>
          this.setState({
            showOverlayLoader: false,
            showTracelog: true,
            insiderId
          })
        );
    }
  };

  getValue = (value, state) => {
    return value ? (
      value
    ) : (
      <ErrorText brighterRed={state === "inactive"}>
        {this.props.t("incomplete")}
      </ErrorText>
    );
  };

  getCountryFromList = country => {
    const countryObj = this.props.countries.find(
      element => element.id === country
    );
    if (countryObj) {
      return getLanguage() === "No" ? countryObj.nameNo : countryObj.nameEn;
    } else return country;
  };

  getFullAddress = (row, state) => {
    return (
      <React.Fragment>
        <FlexContainer>{row.address ? row.address : undefined}</FlexContainer>
        <FlexContainer>
          {row.post_code && row.city
            ? row.post_code + " " + row.city
            : undefined}
        </FlexContainer>
        <FlexContainer>{this.getCountryFromList(row.country)}</FlexContainer>
        {!(row.address && row.post_code && row.city && row.country) ? (
          <ErrorText brighterRed={state === "inactive"}>
            {this.props.t("incomplete")}
          </ErrorText>
        ) : (
          undefined
        )}
      </React.Fragment>
    );
  };

  getCompanyNameAndAddress = (row, state) => {
    return (
      <React.Fragment>
        <FlexContainer>{row.company_name}</FlexContainer>
        <FlexContainer>
          {row.company_address ? row.company_address : undefined}
        </FlexContainer>
        <FlexContainer>
          {row.company_post_code && row.company_city
            ? row.company_post_code + " " + row.company_city
            : undefined}
        </FlexContainer>
        <FlexContainer>
          {this.getCountryFromList(row.company_country)}
        </FlexContainer>
        {!(
          row.company_name &&
          row.company_address &&
          row.company_post_code &&
          row.company_city &&
          row.company_country
        ) ? (
          <ErrorText brighterRed={state === "inactive"}>
            {this.props.t("incomplete")}
          </ErrorText>
        ) : (
          undefined
        )}
      </React.Fragment>
    );
  };

  getConcatenatedName = (row, state) => {
    return (
      <React.Fragment>
        <FlexContainer>{row.first_name}</FlexContainer>
        <FlexContainer>{row.surname}</FlexContainer>
        <FlexContainer>
          {row.birth_surname ? `(${row.birth_surname})` : ""}
        </FlexContainer>

        {!(row.first_name && row.surname && row.birth_surname) ? (
          <ErrorText brighterRed={state === "inactive"}>
            {this.props.t("incomplete")}
          </ErrorText>
        ) : (
          undefined
        )}
      </React.Fragment>
    );
  };

  getTwoValuesBasedOnKey = (row, keyBase, state) => {
    return (
      <React.Fragment>
        <FlexContainer>{row[keyBase] ? row[keyBase] : undefined}</FlexContainer>
        <FlexContainer>
          {row[keyBase + "_2"] ? row[keyBase + "_2"] : undefined}
        </FlexContainer>
        {!(row[keyBase] || row[keyBase + "_2"]) ? (
          <ErrorText brighterRed={state === "inactive"}>
            {this.props.t("incomplete")}
          </ErrorText>
        ) : (
          undefined
        )}
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;

    const rows = this.props.onlyDisplayTableHeader
      ? [undefined]
      : this.props.data.map(data => {
          const row = data.data;
          const state = this.props.getState(data.id);

          return (
            <TR
              key={data.id}
              greenBackground={state === "confirmed"}
              yellowBackground={state === "notified"}
              blueBackground={state === "responded"}
              disableAlternation
              height={100}
            >
              <TD sticky greyText={state === "inactive"}>
                {this.props.hasEditAccess ? (
                  <FlexContainer column vAlignCenter spaceBetween>
                    <Field
                      key={data.id}
                      component={CheckBox}
                      name={data.id}
                      useReduxCheckState
                    />
                    <Icon
                      src="/icons/ic-edit-black-24-px.svg"
                      alt={t("edit")}
                      onClick={() => {
                        if (this.props.hasEditAccess) {
                          this.props.showEditInsiderForm(data.id);
                        }
                      }}
                      margin={[10, 0, 10, 0]}
                    />
                  </FlexContainer>
                ) : (
                  ""
                )}
              </TD>
              <TD sticky alignTop greyText={state === "inactive"}>
                {t("it_" + state)}
              </TD>
              <TD
                sticky
                rightBorder
                pointer={this.props.hasEditAccess}
                onClick={() => {
                  if (this.props.hasEditAccess) {
                    this.props.showEditInsiderForm(data.id);
                  }
                }}
                alignTop
                greyText={state === "inactive"}
              >
                {this.getConcatenatedName(row, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getValue(row.email, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getTwoValuesBasedOnKey(row, "phone_work", state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getCompanyNameAndAddress(row, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getValue(row.insider_function_and_reason, state)}
              </TD>
              <TD
                alignTop
                width={120}
                minWidth={120}
                greyText={state === "inactive"}
              >
                {this.getValue(row.obtainedTime, state)}
              </TD>
              <TD
                alignTop
                width={120}
                minWidth={120}
                greyText={state === "inactive"}
              >
                {row.ceasedTime}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getValue(row.date_of_birth, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getValue(row.idnumber, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getTwoValuesBasedOnKey(row, "phone_private", state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                {this.getFullAddress(row, state)}
              </TD>
              <TD alignTop greyText={state === "inactive"}>
                <Shiitake lines={4} overflowNode={"..."}>
                  {row.comment}
                </Shiitake>
              </TD>
              <TD>
                <Button.Standard onClick={_ => this.toggleTraceLog(data.id)}>
                  {t("view")}
                </Button.Standard>
              </TD>
            </TR>
          );
        });

    return [
      <FlexContainer
        column
        relativeContainer
        margin={[30, 0, 20, 0]}
        key={"insiderTableContainer"}
      >
        {rows.length > 0 ? (
          <React.Fragment>
            <ButtonRowInsiders />
            <Table
              tableRows={rows}
              tableHeaderLabels={this.props.thLabels}
              stickyColumns
              numberOfStickyColumns={3}
            />
          </React.Fragment>
        ) : (
          ""
        )}
      </FlexContainer>,

      this.state.showTracelog ? (
        <Tracelog
          key={"tracelog"}
          onClose={this.toggleTraceLog}
          data={this.props.tracelog.filter(t => {
            return t.id === this.state.insiderId;
          })}
        />
      ) : (
        undefined
      )
    ];
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;

  return {
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    countries: insiderToolReducer.countries.countries
      ? insiderToolReducer.countries.countries
      : [],
    tracelog: insiderToolReducer.tracelog.insiderData
  };
}

InsiderTable = reduxForm({
  form: "insiderTableForm"
})(InsiderTable);

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderTable)
);
