import React from "react";
import { InputBox } from "./MyProfile";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import Label from "../components/Label";
import Button from "../components/Button";
import { NotificationManager } from "react-notifications";
import { updateCredentials } from "../actions/MFAActions";
import { validatePassword } from "../util/Validators";

class PasswordBoxForm extends React.Component {
  render() {
    const props = this.props;
    return (
      <FlexContainer vAlignCenter center column maxWidth={374}>
        <form>
          <Field
            component={InputBox}
            type="password"
            margin={[0, 0, 16, 0]}
            name="currentPassword"
            label={props.t("current_password")}
          />
          <Field
            component={InputBox}
            type="password"
            margin={[0, 0, 16, 0]}
            name="newPassword"
            label={props.t("new_password")}
          />
          <Field
            component={InputBox}
            type="password"
            margin={[0, 0, 16, 0]}
            name="repeatPassword"
            label={props.t("repeat_password")}
            onKeyDown={e => {
              switch (e.keyCode) {
                case 13: //Enter
                  props.handleSubmit();
              }
            }}
          />

          <Label fullWidth>*{props.t("password_info_1")}</Label>
          <br />
          <Label fullWidth margin={[5, 0, 0, 0]}>
            *{props.t("password_info_2")}
          </Label>
          <br />
          <Label fullWidth margin={[5, 0, 0, 0]}>
            *{props.t("password_info_3")}
          </Label>

          <Button.Standard onClick={props.handleSubmit} margin={[40, 0, 0, 0]}>
            {props.t("change_password")}
          </Button.Standard>
        </form>
      </FlexContainer>
    );
  }
}

PasswordBoxForm = reduxForm({
  form: "passwordBoxForm"
})(PasswordBoxForm);

class PasswordBox extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;

    return (
      <PasswordBoxForm
        t={t}
        onSubmit={(password, dispatch, props) => {
          const sendPasswordValues = () => {
            return dispatch(
              updateCredentials(
                null,
                null,
                null,
                null,
                null,
                null,
                1,
                password.currentPassword,
                password.newPassword
              )
            );
          };
          const handleServerErrors = (error, reject) => {
            if (error.data.details != null) {
              if (error.data.details.some(e => e.code === 1221)) {
                return reject(
                  new SubmissionError({
                    newPassword: this.props.t("password_missing_char"),
                    repeatPassword: this.props.t("password_missing_char")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1222)) {
                return reject(
                  new SubmissionError({
                    newPassword: this.props.t("min_signs")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1223)) {
                return reject(
                  new SubmissionError({
                    newPassword: this.props.t("password_reused"),
                    repeatPassword: this.props.t("password_reused")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1224)) {
                return reject(
                  new SubmissionError({
                    newPassword: this.props.t("password_username"),
                    repeatPassword: this.props.t("password_username")
                  })
                );
              } else {
                error.data.details.forEach(e =>
                  NotificationManager.error(e.message, "", 0)
                );
              }
            } else NotificationManager.error(t("an_error_occured"), "", 0);
          };

          const requiredFieldsList = [
            "currentPassword",
            "newPassword",
            "repeatPassword"
          ];

          const errors = validatePassword(password, props, requiredFieldsList);

          return new Promise((resolve, reject) => {
            if (errors) {
              return reject(new SubmissionError(errors));
            }
            sendPasswordValues()
              .then(() => NotificationManager.success(t("password_updated")))
              .then(resolve)
              .then(this.props.closePasswordModal)
              .catch(error => handleServerErrors(error, reject));
          });
        }}
      />
    );
  }
}

export default PasswordBox;
