import React from "react";
import { FlexContainer } from "../../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import SelectionBox from "../../components/SelectionBox";
import Label from "../../components/Label";
import Button from "../../components/Button";
import RadioButton from "../../components/RadioButton";
import { ErrorText } from "../../components/ErrorText";
import { ACCESS_LEVEL } from "../../util/common";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Modal } from "../../components/Modal";
import i18n from "../../i18n";
import { fetchInsiderList, addUser, InsiderType } from "../InsiderToolActions";
import { HelpIcon } from "../../components/HelpIcon";
import { reset, SubmissionError } from "redux-form";

const RadioButtonGroup = props => {
  return (
    <FlexContainer column>
      <Label>{props.t("access")}</Label>
      {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
      <FlexContainer border>
        <FlexContainer padding={[18, 0, 0, 10]}>
          <RadioButton
            checked={props.option === 0}
            margin={[0, 0, 16, 0]}
            radioValue={1}
            label={props.t("it_insider_permission_1")}
            {...props}
          />
          <RadioButton
            checked={props.option === 1}
            margin={[0, 0, 16, 0]}
            radioValue={2}
            label={props.t("it_insider_permission_2")}
            {...props}
          />

          <RadioButton
            checked={props.option === 2}
            margin={[0, 0, 16, 0]}
            radioValue={3}
            label={props.t("it_insider_permission_3")}
            {...props}
          />

          <RadioButton
            checked={props.option === 0}
            margin={[0, 0, 16, 0]}
            radioValue={0}
            label={props.t("it_insider_permission_0")}
            {...props}
          />
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export class PrivelegesModalForm extends React.Component {
  getUserListForModal = () => {
    return this.props.usersWithAccessToIT && this.props.usersWithAccessToList
      ? this.props.usersWithAccessToIT
          .filter(
            user => user.isAdmin === false && user.id !== this.props.loginUserId
          )
          .map(user => {
            const userWithAccess = this.props.usersWithAccessToList.find(
              e => e.id === user.id
            );
            var accessLevel = ACCESS_LEVEL.NO_ACCESS;
            if (userWithAccess) {
              accessLevel = userWithAccess.accessLevel;
            }
            return {
              accessLevel,
              ...user
            };
          })
      : [];
  };

  render() {
    const { t, edit } = this.props;
    return (
      <FlexContainer>
        <FlexContainer row>
          <FlexContainer margin={[0, 50, 0, 0]}>
            <Field
              component={SelectionBox}
              name={"users"}
              options={this.getUserListForModal()}
              t={t}
              label={t("users")}
              edit={edit}
            />
          </FlexContainer>
          {edit ? (
            <FlexContainer flexStart>
              <Field name="privilege" component={RadioButtonGroup} t={t} />
            </FlexContainer>
          ) : (
            undefined
          )}
        </FlexContainer>
        {edit ? (
          <FlexContainer row vAlignCenter flexEnd>
            <Button.Standard
              onClick={this.props.handleSubmit}
              margin={[0, 24, 0, 0]}
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={this.props.onCancel}>
              {t("cancel")}
            </Button.Text>
          </FlexContainer>
        ) : (
          undefined
        )}
      </FlexContainer>
    );
  }
}

PrivelegesModalForm = reduxForm({
  form: "privelegesModalForm"
})(PrivelegesModalForm);

class PrivelegesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditPrivilegesModal: true,
      showLoader: false
    };
  }

  validate = values => {
    const errors = {};
    if (values.privilege === undefined || values.privilege < 0) {
      errors.privilege = this.props.t("required_field");
    }
    if (!values.users) {
      errors.users = this.props.t("required_field");
    }
    return Object.keys(errors).length > 0 ? errors : undefined;
  };

  setPrivileges = values => {
    return new Promise((resolve, reject) => {
      const errors = this.validate(values);

      if (errors) {
        reject(new SubmissionError(errors));
      } else {
        values.users.forEach((user, index) => {
          this.props
            .dispatch(addUser(user, this.props.listId, values.privilege))
            .then(() => {
              if (index === values.users.length - 1) {
                resolve();
              }
            });
        });
      }
    });
  };

  privilegesHeader = (
    <FlexContainer row center>
      {this.props.t("access_administration")}
      {this.props.listType === InsiderType.LIST ? (
        <HelpIcon
          margin={[8, 0, -3, 5]}
          fileName={"privileges_modal"}
          language={i18n.language}
          insiderToolHelp
          height={"18"}
          width={"18"}
        />
      ) : (
        undefined
      )}
    </FlexContainer>
  );

  render() {
    const { listOpened, listId } = this.props;
    return (
      <Modal
        header={this.privilegesHeader}
        isOpen={true}
        onClose={() => this.props.toggleShowEditPrivilegesModal()}
      >
        <FlexContainer textAlign={"left"}>
          <form>
            <PrivelegesModalForm
              {...this.props}
              onSubmit={values => {
                this.setState({ showLoader: true });
                return this.setPrivileges(values)
                  .then(() => {
                    this.setState({
                      showLoader: false
                    });
                    this.props.toggleShowEditPrivilegesModal();
                    if (listOpened) {
                      this.props.dispatch(fetchInsiderList(listId));
                    }
                  })
                  .catch(e => {
                    this.setState({ showLoader: false });
                    throw e;
                  });
              }}
              onCancel={() => {
                this.props.toggleShowEditPrivilegesModal();
              }}
            />
          </form>
        </FlexContainer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { loginUser, insiderToolReducer } = state;

  return {
    loginUserId: loginUser.user.globalSubject,
    usersWithAccessToIT: insiderToolReducer.userList.list,
    usersWithAccessToList: insiderToolReducer.insiderList.users
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(PrivelegesModal)
);
