import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import NewUserForm from "./NewUserForm";
import { fetchAuthParams } from "./NewUserActions";
import { updateCredentials } from "../actions/MFAActions";
import { SubmissionError } from "redux-form";
import { validatePassword, validateNumber } from "./Validators";
import { getLanguage } from "../util/common";
import Loader, { OverlayLoader } from "../components/Loader";
import i18n from "../i18n";

class NewUserFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.globalSubject = props.match ? props.match.params.globalSubject : undefined;
    this.resetSecret = props.match ? props.match.params.resetSecret : undefined;
    this.lang = props.match ? props.match.params.lang : undefined;
    this.state = {
      twoFactorCodeRequested: false,
      redirect: false,
      loading: false
    };
  }

  componentWillMount() {
    this.props.dispatch(fetchAuthParams(this.globalSubject));
    if (this.lang != null)
      return new Promise(resolve =>
        resolve(
          this.lang === "en"
            ? i18n.changeLanguage("en")
            : i18n.changeLanguage("nb")
        )
      );
    else i18n.changeLanguage("nb");
    if (!this.props.match) {
      const url = window.location.href;
      const urlSplit = url.split('/')
      this.globalSubject = urlSplit[4]
      this.resetSecret = urlSplit[5]
      this.lang = urlSplit[6]
    }

  }

  render() {
    var parameterOptions = {};
    const paramList = this.props.authParamList;

    if (paramList != null) {
      Object.keys(paramList).forEach(paramName => {
        if (paramList[paramName].map != null) {
          parameterOptions[paramName] = paramList[paramName] //.filter((p)=> p.id == 1)
            .map(params => {
              const label = params["name" + getLanguage()];

              return {
                label: label != null ? label.trim() : label,
                value: params.id
              };
            });
        }
      });
    }

    // initial value:
    var initVal = {};
    initVal["areaCode"] = { label: "Norway", value: "NO" };

    if (this.state.redirect) {
      NotificationManager.info(this.props.t("new_password_information"));
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <NewUserForm
          t={this.props.t}
          onVerifyMfa={(value, dispatch, props) => {
            const sendMfaValues = () => {
              return this.props.dispatch(
                updateCredentials(
                  this.globalSubject,
                  null,
                  value.areaCode.value,
                  value.twoFactorPhone,
                  value.protocol,
                  this.resetSecret,
                  12
                )
              );
            };
            const handleServerErrors = (error, reject) => {
              if (error.data.details != null) {
                if (error.data.details.some(e => e.code == 1234)) {
                  return reject(
                    NotificationManager.error(this.props.t("link_expired"))
                    // new SubmissionError({
                    //   twoFactorPhone: this.props.t("link_expired")
                    // })
                  );
                }
                if (error.data.details.some(e => e.code == 1235)) {
                  return reject(
                    NotificationManager.error(this.props.t("link_error"))
                  );
                } else {
                  error.data.details.forEach(e =>
                    NotificationManager.error(e.message, "", 0)
                  );
                }
              } else
                NotificationManager.error(
                  this.props.t("an_error_occured"),
                  "",
                  0
                );
            };

            const errors = validateNumber(value, props);

            return new Promise((resolve, reject) => {
              if (errors) {
                return reject(new SubmissionError(errors));
              }
              Promise.resolve(this.setState({ loading: true })).then(() =>
                sendMfaValues()
                  .then(() => this.setState({ twoFactorCodeRequested: true }))
                  .then(() => this.setState({ loading: false }))
                  .then(resolve)
                  .catch(error => handleServerErrors(error, reject))
                  .then(() => this.setState({ loading: false }))
              );
            });
          }}
          twoFactorCodeRequested={this.state.twoFactorCodeRequested}
          {...parameterOptions}
          initialValues={{
            ...initVal
          }}
          loading={this.state.loading}
          /*
          ===============
          Send Password
          ===============
          */
          onSave={(value, dispatch, props) => {
            const sendPasswordValues = () => {
              return this.props.dispatch(
                updateCredentials(
                  this.globalSubject,
                  value.receivedCode,
                  null,
                  null,
                  null,
                  this.resetSecret,
                  2,
                  null,
                  value.repeat_password
                )
              );
            };

            const handleServerErrors = (error, reject) => {
              if (error.data.details != null) {
                if (error.data.details.some(e => e.code == 1201)) {
                  return reject(
                    NotificationManager.error(props.t("invalid_one_time_code"))
                  );
                }
                if (error.data.details.some(e => e.code == 1222)) {
                  return reject(
                    new SubmissionError({
                      password: this.props.t("min_signs")
                    })
                  );
                }
                if (error.data.details.some(e => e.code == 1234)) {
                  return reject(
                    new SubmissionError({
                      password: this.props.t("link_expired")
                    })
                  );
                }
                if (error.data.details.some(e => e.code == 1223)) {
                  return reject(
                    new SubmissionError({
                      password: this.props.t("password_reused")
                    })
                  );
                }
                if (error.data.details.some(e => e.code == 1224)) {
                  return reject(
                    new SubmissionError({
                      password: this.props.t("password_username")
                    })
                  );
                } else {
                  error.data.details.forEach(e =>
                    NotificationManager.error(e.message, "", 0)
                  );
                }
              } else
                NotificationManager.error(
                  this.props.t("an_error_occured"),
                  "",
                  0
                );
            };

            const errors = validatePassword(
              value,
              props,
              this.props.authParamList.twoFactorEnabled
            );

            return new Promise((resolve, reject) => {
              if (errors) {
                return reject(new SubmissionError(errors));
              }
              sendPasswordValues()
                .then(() =>
                  NotificationManager.success(this.props.t("password_updated"))
                )
                .then(resolve)
                .then(() => this.setState({ redirect: true }))
                .catch(error => handleServerErrors(error, reject));
            });
          }}
          twoFactorEnabled={this.props.authParamList.twoFactorEnabled}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { form, newUserReducer } = state;

  return {
    passwordForm:
      form.resetPassword != null ? form.resetPassword.values : undefined,

    authParamList: newUserReducer.authParamList.list
  };
}

NewUserFormContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(
  withTranslation("translations")(NewUserFormContainer)
);
