import {UserTypes} from "./AdminContainer";
import {emailRequirementValidator} from "../util/emailValidator";

export const userFormValidator = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  /*
   * Check if selected userType is 'admin' or 'user'. If that's the case, they need to have at least one 'sub-value'(module-access)
   * */
  const adminOrUserWithNoSubtype = () => {
    let missingRoles = false;
    if (
      values.userType &&
      (values.userType === "admin" || values.userType === "user")
    ) {
      missingRoles = true;
      const roleValues = values[values.userType];
      if (roleValues) {
        Object.keys(roleValues).forEach((key) => {
          if (roleValues[key] === true) {
            missingRoles = false;
          }
        });
      }
    }
    return missingRoles;
  };

  const validateFieldLength = (field, maximumCharacters) => {
    if (
      values[field] !== undefined &&
      values[field].length > maximumCharacters
    ) {
      errors[field] = props.t("field_length_exceeded", {
        length: maximumCharacters,
      });
    }
  };

  const requiredFieldsCheck = () => {
    if (values.username == null) {
      errors.username = props.t("required_field");
    }
    validateFieldLength("username", 40);
    if (values.email == null) {
      errors.email = props.t("required_field");
    }
    if (values.language == null) {
      errors.language = props.t("required_field");
    }
    if (values.organisation == null) {
      errors.organisation = props.t("required_field");
    }
    if (values.firstName == null) {
      errors.firstName = props.t("required_field");
    }
    if (values.lastName == null) {
      errors.lastName = props.t("required_field");
    }
    if (values.mobile == null) {
      errors.mobile = props.t("required_field");
    }
    if ((values.userType == null && values.unknownRoles == null)
        || (values.userType === UserTypes.UNKNOWN && values.unknownRoles == null)) {
      errors.userType = props.t("required_field");
      errors.unknownRoles = props.t("required_field_option");
    }
    if (adminOrUserWithNoSubtype()) {
      errors.userType = props.t("select_at_least_one_role_for_the_user");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.mobile != null &&
      !/^\+[0-9]+|^[0-9]+/i.test(values.mobile) &&
      values.mobile.length > 0
    ) {
      errors.mobile = props.t("invalid_phonenumber");
    }
    if (
      values.office != null &&
      !/[0-9]+/i.test(values.office) &&
      values.office.length > 0
    ) {
      errors.office = props.t("invalid_phonenumber");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  const securityCheck = () => {
    const internalOrg = () =>
      values.organisation != null && values.organisation.value.isInternal;

    const selectedSuperUser = () => values.userType === UserTypes.SUPERUSER;
    const selectedOperator = () =>
      values.userType === UserTypes.ADMIN &&
      values.admin != null &&
      values.admin.operations;
    const selectedCorporate = () => values.userType === UserTypes.CORPORATE;

    if (!internalOrg() && selectedSuperUser()) {
      errors.organisation = props.t("not_internal_superuser");
    }

    if (!internalOrg() && selectedOperator()) {
      errors.organisation = props.t("not_internal_operator");
    }

    if (!internalOrg() && selectedCorporate()) {
      errors.organisation = props.t("not_internal_corporate");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();
  securityCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const allAuthValuesAreFalse = (formattedValues, props) => {
  const errors = {};
  let typeValueKeys;
  if (formattedValues.otherProperties) {
    typeValueKeys = Object.keys(formattedValues.otherProperties)
      .filter((key) => key.startsWith("type_"))
      .map((key) => key);
  }
  const keyWithTrueValue = typeValueKeys.find((key) => {
    return formattedValues.otherProperties[key] === "true";
  });
  if (typeValueKeys && !keyWithTrueValue) {
    errors.auth_option_header = props.t(
      "not_allowed_with_all_auth_types_false"
    );
  }
  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

export const passwordValidator = (values, props) => {
  const errors = {};
  if (values.newPassword == null) {
    errors.newPassword = props.t("required_field");
  }
  if (values.repeatPassword == null) {
    errors.repeatPassword = props.t("required_field");
  }
  if (values.newPassword !== values.repeatPassword) {
    errors.newPassword = props.t("passwords_dont_match");
  }
  if (Object.keys(errors).length > 0) {
    return errors;
  }
};

export const validatePassword = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (values[key] != null && values[key].length === 0) {
      values[key] = null;
    }
  });
  const requiredFieldsCheck = () => {
    if (values.repeatPassword == null) {
      errors.repeatPassword = props.t("required_field");
    }
    if (values.newPassword == null) {
      errors.newPassword = props.t("required_field");
    }
    if (values.newPassword !== values.repeatPassword) {
      errors.newPassword = props.t("Ulike passord");
    }
    if (values.twoFactorPhone == null) {
      errors.twoFactorPhone = props.t("required_field");
    }
    if (values.receivedCode == null) {
      errors.receivedCode = props.t("required_field");
    }
  };

  const passwordRequirement = () => {
    //only numeric
    if (
      values.newPassword != null &&
      /^\d+$/i.test(values.newPassword) &&
      values.newPassword.length > 0
    ) {
      errors.newPassword = props.t("only_numeric");
    }
    if (values.newPassword != null && values.newPassword.length < 9) {
      errors.newPassword = props.t("min_signs");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.twoFactorPhone != null &&
      !/^\+[0-9]+|[0-9]+/i.test(values.twoFactorPhone) &&
      values.twoFactorPhone.length > 0
    ) {
      errors.twoFactorPhone = props.t("invalid_phonenumber");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();
  passwordRequirement();

  console.log(errors);

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const emailValidator = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) => {
    if (values[key] != null && values[key].length === 0) {
      values[key] = null;
    }
  });
  const requiredFieldsCheck = () => {
    if (values.email == null) {
      errors.email = props.t("required_field");
    }
  };
  const valueFormatCheck = () => {
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};
