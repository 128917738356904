import styled from "styled-components";

export const StyledLink = styled.a`
  text-align: left;
  color: #336699;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
