import React from "react";
import {RESPONSIBLE_PERSON_REG_EX} from "./ResponsiblePersonUtils";
import {FlexContainer} from "../components/FlexContainer";
import {Field} from "redux-form";
import {Icon, InputBox} from "../insiderTool/components";
import Label from "../components/Label";
import {HelpIcon} from "../components/HelpIcon";
import i18n from "../i18n";
import Button from "../components/Button";
import {connect} from "react-redux";
import {ErrorText} from "../components/ErrorText";

const MissingPersonsError = props => {
    const { error } = props.meta;

    return error ? <ErrorText>{error}</ErrorText> : "";
};

const ResponsiblePersonsList = props => {
    const { t, change } = props;

    const isFirstRow = i => i === 0;
    const remove = i => {
        change(`responsible_firstName_${i}`, "");
        change(`responsible_lastName_${i}`, "");
        change(`responsible_title_${i}`, "");
        change(`responsible_email_${i}`, "");
        change(`responsible_phone_${i}`, "");

        props.removeResponsiblePersonRow(i);
    };

    return Array.from(props.mapResponsiblePersons.keys()).map(
        (i, index) => (
            <FlexContainer row key={"personRow_" + index} vAlignEnd>
                <Field
                    component={InputBox}
                    name={`responsible_firstName_${i}`}
                    label={t("first_name") + "*"}
                    noLabel={!isFirstRow(index)}
                    showError
                    margin={[0, 0, 10, 0]}
                />
                <Field
                    component={InputBox}
                    name={`responsible_lastName_${i}`}
                    label={t("surname") + "*"}
                    noLabel={!isFirstRow(index)}
                    showError
                    margin={[0, 0, 10, 5]}
                />
                <Field
                    component={InputBox}
                    noWrap
                    name={`responsible_title_${i}`}
                    label={t("position_within_issuer") + "*"}
                    noLabel={!isFirstRow(index)}
                    showError
                    margin={[0, 0, 10, 5]}
                />
                <Field
                    component={InputBox}
                    noWrap
                    name={`responsible_email_${i}`}
                    label={t("email") + "*"}
                    noLabel={!isFirstRow(index)}
                    showError
                    margin={[0, 0, 10, 5]}
                />
                <Field
                    component={InputBox}
                    noWrap
                    name={`responsible_phone_${i}`}
                    label={t("phone") + "*"}
                    noLabel={!isFirstRow(index)}
                    showError
                    margin={[0, 0, 10, 5]}
                />
                <FlexContainer flexEnd width="auto" margin={[0, 0, 22, 10]}>
                    {
                        !isFirstRow(index) ||
                        (props.mapResponsiblePersons.size > 1 && isFirstRow(index)) ? (
                            <Icon
                                id={"removeResponsiblePerson" + index}
                                onClick={() => remove(index)}
                                src="/icons/red-cross-24.svg"
                            />
                        ) : (
                            ""
                        )}
                </FlexContainer>
            </FlexContainer>
        )
    );
}

class ResponsiblePersons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapResponsiblePersons: new Map([[0, 0]]),
        };
        this.createResponsiblePersonsMap = this.createResponsiblePersonsMap.bind(
            this
        );
    }

    createResponsiblePersonsMap = () => {
        const { notificationFormValues } = this.props;
        const responsiblePersonsKeys = key =>
            key.match(`${RESPONSIBLE_PERSON_REG_EX}`) != null;
        const toPersonIndex = key =>
            key.match(`${RESPONSIBLE_PERSON_REG_EX}_([0-9]+)`)[2];
        const nonNullValues = key => notificationFormValues[key] != null;
        const toArrayTuple = i => [i, 0];
        const initialValue = [[0, 0]];
        let map = new Map(
            Object.keys(notificationFormValues || {})
                .filter(nonNullValues)
                .filter(responsiblePersonsKeys)
                .map(toPersonIndex)
                .distinctValues()
                .map(toArrayTuple)
                .sort()
        );

        if (map.size < 1) {
            map = new Map(initialValue);
        }
        return map;
    };

    componentDidMount() {
        const newMap = this.createResponsiblePersonsMap();
        this.setState({ mapResponsiblePersons: newMap });
    }

    addResponsiblePersonRow = () => {
        const maxKeyValue = Array.from(
            this.state.mapResponsiblePersons.keys()
        ).reduce(
            (acc, val) => (Number(val) > Number(acc) ? Number(val) : Number(acc)),
            0
        );

        const newMap = new Map(this.state.mapResponsiblePersons.entries()).set(
            maxKeyValue + 1,
            0
        );

        this.setState({ mapResponsiblePersons: newMap });
    };

    removeResponsiblePersonRow = i => {
        const newMap = new Map(this.state.mapResponsiblePersons.entries());
        newMap.delete(i);
        this.setState({ mapResponsiblePersons: newMap });
    };



    render() {
        const { t } = this.props;
        return (
            <FlexContainer>
                <FlexContainer
                    backgroundColor={"rgba(199,211,227,0.3)"}
                    margin={[10, 0, 10, 0]}
                    padding={[10, 10, 10, 10]}
                >
                    <Label bold fontSize="16">
                        {t("it_person_nca")}
                        <HelpIcon
                            margin={[0, 0, -3, 5]}
                            fileName={"person_nca"}
                            incomingPrefix={"notification"}
                            language={i18n.language}
                            height={"16"}
                            width={"16"}
                        />
                    </Label>

                    <Field
                        fontSize={"14"}
                        component={InputBox}
                        name="responsibleFirstName"
                        label={t("first_name") + "*"}
                        bold
                    />
                    <Field
                        fontSize={"14"}
                        component={InputBox}
                        name="responsibleLastName"
                        label={t("last_name") + "*"}
                        bold
                    />

                    <Field
                        fontSize={"14"}
                        component={InputBox}
                        name="responsiblePosition"
                        label={t("position_within_issuer") + "*"}
                        bold
                    />

                    <Field
                        fontSize={"14"}
                        component={InputBox}
                        name="responsibleEmail"
                        label={t("email_work") + "*"}
                        bold
                    />

                    <Field
                        fontSize={"14"}
                        component={InputBox}
                        name="responsiblePhoneNumber"
                        label={t("phone_work") + "*"}
                        bold
                    />
                </FlexContainer>

                <FlexContainer
                    backgroundColor={"rgba(199,211,227,0.3)"}
                    margin={[10, 0, 10, 0]}
                    padding={[10, 10, 10, 10]}
                >
                    <Label fontSize="16" bold>
                        {t("it_responsible_decision_header_list")}
                        <HelpIcon
                            margin={[0, 0, -3, 5]}
                            fileName={"responsible_decision_nca"}
                            incomingPrefix={"notification"}
                            language={i18n.language}
                            height={"16"}
                            width={"16"}
                        />
                    </Label>
                    <Field
                        name="missingResponsiblePersonsError"
                        component={MissingPersonsError}
                    />
                    <FlexContainer
                        column
                        id="responsiblePersonList"
                        key="responsiblePersonList"
                    >
                        <Field
                            name="responsiblePersonsList"
                            component={ResponsiblePersonsList}
                            mapResponsiblePersons={this.state.mapResponsiblePersons}
                            removeResponsiblePersonRow={this.removeResponsiblePersonRow}
                            {...this.props}
                        />
                    </FlexContainer>

                    <FlexContainer row margin={[10, 0, 0, 0]}>
                        <Button.Standard
                            id="addResponsiblePersonBtn"
                            onClick={this.addResponsiblePersonRow}
                        >
                            {t("add_new_person")}
                        </Button.Standard>
                    </FlexContainer>
                </FlexContainer>
                <FlexContainer row flexEnd vAlignCenter margin={[0, 0, 10, 0]}>
                    <FlexContainer row flexEnd vAlignCenter margin={[0, 24, 0, 0]}>
                        <Button.Standard
                            onClick={this.props.handleSubmit(this.props.onSubmit)}
                        >
                            {this.props.t("next_step")}
                        </Button.Standard>
                    </FlexContainer>
                    <Button.Text onClick={this.props.onCancel}>
                        {this.props.t("cancel")}
                    </Button.Text>
                </FlexContainer>
            </FlexContainer>
        )
    }
}

export default connect()(ResponsiblePersons);
