import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {FlexContainer} from "../components/FlexContainer";
import {Route, Switch} from "react-router-dom";
import {
    clearIssuanceDetails, clearIssuanceInstruments, clearIssuanceIssuers,
    createIssuance,
    fetchIssuanceDetails, fetchIssuanceInstruments, fetchIssuanceIssuers,
    fetchIssuanceList,
    fetchIssuanceParameters, postComment,
    updateIssuance, updateIssuanceStatus
} from "./ShareIssuanceActions";
import {getLanguage, hasValue, isSuperUser} from "../util/common";
import ChatBox from "./ChatBox";
import ShareIssuanceList from "./ShareIssuanceList";
import ShareIssuancePreview from "./ShareIssuancePreview";
import ShareIssuanceReceipt from "./ShareIssuanceReceipt";
import ShareIssuanceForm from "./ShareIssuanceForm";
import {validate} from "./ShareIssuanceValidator";
import {SubmissionError} from "redux-form";
import moment from "moment";
import {NotificationManager} from "react-notifications";

import "../css/share-issuance.css";
import WithPagination, {MAX_RESULT_ON_PAGE} from "../components/PaginationContainer";
import {OverlayLoader} from "../components/Loader";


export const getCorrectDateFormat = (date) => {
    return moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');
};

export const mapStatus = (statusTypes, status, t) => {
    const statusToMap = hasValue(status.value)? status.value : status
    const type = statusTypes?.find((statusType) => statusType.value === statusToMap);
    return type ? type["label" + getLanguage().toUpperCase()] : t("unknown");
};

const PaginatedIssuanceList = WithPagination(ShareIssuanceList);

class ShareIssuanceContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formValues: undefined,
            loading: true,
            overlayLoading: false
        };
    }

    componentDidMount() {
        const re = new RegExp(`${this.props.base}/(form|preview)/(.+)`, "g");
        const pathName = re.exec(this.props.location.pathname);
        this.props.dispatch(fetchIssuanceList({pageSize: MAX_RESULT_ON_PAGE, page: 0}))
            .then(() => this.props.dispatch(fetchIssuanceParameters()))
            .then(() => {
                if (pathName) {
                    this.props.dispatch(fetchIssuanceDetails(pathName[2]))
                        .then((res) => {
                            this.setState({formValues: res});
                            if (res.issuerId) {
                                this.props.dispatch(fetchIssuanceIssuers(res.organisationId))
                                    .then(() => this.props.dispatch(fetchIssuanceInstruments(res.issuerId)))
                                    .finally(() => this.setState({loading: false}));
                            }
                        });
                }
            })
            .finally(() => {
                if (!pathName) {
                    this.setState({loading: false});
                }
            });
    }

    render() {
        const { base } = this.props;

        const onFormSubmit = (e) => {
            if (hasValue(e.status)) {
                e.update = true;
            }
            const errors = validate(e, this.props);

            return new Promise((resolve, reject) => {
                if (Object.keys(errors).length > 0) {
                    console.log(errors)
                    return reject(new SubmissionError(errors));
                } else {
                    this.setState({formValues: e})
                    this.props.history.push(`${base}/preview/${e.id}`)
                }
            })
        }

        const onCancel = () => {
            this.setState({formValues: undefined})
            this.props.dispatch(clearIssuanceDetails())
            this.props.dispatch(clearIssuanceInstruments())
            this.props.dispatch(clearIssuanceIssuers())
            this.props.dispatch(fetchIssuanceList())
            this.props.history.push(`${base}`)
        }

        const triggerFetchIssuanceDetails = (id) => {
            this.setState({loading: true}, () => this.props.dispatch(fetchIssuanceDetails(id))
                .then((res) => {
                    this.setState({formValues: res});
                })
                .finally(() => this.setState({loading: false})));
        }

        const onListEntityClick = (id) => {
            this.props.dispatch(fetchIssuanceDetails(id))
                .then((res) => {
                    this.setState({formValues: res});
                    return res;
            }).then((res) => {
                this.props.dispatch(fetchIssuanceInstruments(res.issuerId));
            }).then(() => {
                this.props.history.push(`${base}/preview/${id}`)
            });
        }

        const onIssuerSelect = (issuerId) => {
            this.props.dispatch(clearIssuanceInstruments())
            if (hasValue(issuerId)) {
                const sendId = issuerId.value ? issuerId.value : issuerId;
                this.props.dispatch(fetchIssuanceInstruments(sendId))
            }
        }

        const onOrganisationSelect = (orgId) => {
            this.props.dispatch(clearIssuanceIssuers())
            this.props.dispatch(clearIssuanceInstruments())
            if (hasValue(orgId)) {
                const sendId = orgId.value ? orgId.value : orgId;
                this.props.dispatch(fetchIssuanceIssuers(sendId))
            }
        }

        const onPreviewSubmit = () => {
            const hasMainOrValue = (val) => {
                return hasValue(val) ? hasValue(val.value) ? val.value : val : undefined
            }

            const vals = this.state.formValues;
            const sendObj = {}
            sendObj.currentNumberOfShares = vals.currentNumberOfShares;
            sendObj.changeNumberOfShares = vals.changeNumberOfShares;
            sendObj.newNumberOfShares = vals.newNumberOfShares;
            sendObj.requestType = hasMainOrValue(vals.requestType)
            sendObj.reason = vals.reason;
            sendObj.issuerId = hasMainOrValue(vals.issuerId)
            sendObj.mic = hasMainOrValue(vals.mic)
            sendObj.prospectusRequired = hasMainOrValue(vals.prospectusRequired)

            sendObj.prospectusYesReason = vals.prospectusYesReason
            sendObj.prospectusYesMechanisms = vals.prospectusYesMechanisms
            sendObj.prospectusNoReason = vals.prospectusNoReason
            sendObj.prospectusNoCumulative = vals.prospectusNoCumulative
            sendObj.percentageNumberOfShares = vals.percentageNumberOfShares
            sendObj.reference = vals.reference
            sendObj.comment = vals.comment
            sendObj.contactName = vals.contactName
            sendObj.contactPhone = vals.contactPhone
            sendObj.contactMail = vals.contactMail
            sendObj.effectiveDate = vals.effectiveDate;

            sendObj.isin = hasMainOrValue(vals.isin)
            if (!hasValue(sendObj.isin)) {
                sendObj.isin = hasMainOrValue(vals.instrument);
            }

            if (this.props.isSuperUser) {
                const isClosed = hasMainOrValue(vals.closed);
                sendObj.status = hasMainOrValue(vals.status)
                sendObj.closed = isClosed;
                sendObj.internalComment = vals.internalComment;
                sendObj.organisationId = hasMainOrValue(vals.organisationId);
            }

            if (hasValue(vals.status)) {
                this.props.dispatch(updateIssuance(sendObj, vals.id))
                    .then((res) => {
                        if (vals.commentToIssuer) {
                            return this.props.dispatch(postComment(vals.commentToIssuer, res.id))
                        }
                    })
                    .then(() => {
                        this.props.history.push(`${base}/receipt`)
                    });
            } else {
                this.props.dispatch(createIssuance(sendObj)).then(() => {
                    this.props.history.push(`${base}/receipt`)
                });
            }
        }

        const startProcessing = () => {
            const { id } = this.state.formValues;
            this.props.dispatch(updateIssuanceStatus(id, 1))
                .then(() => {
                    NotificationManager.success(this.props.t("share_update_status_success"));
                    triggerFetchIssuanceDetails(id);
                })
                .catch((error) => console.log(error));
        }

        const onPageClick = (page) => {
            this.setState({ overlayLoading: true }, () =>
                new Promise((resolve) => {
                    resolve(this.props.dispatch(fetchIssuanceList({pageSize: MAX_RESULT_ON_PAGE, page: (page - 1) })));
                })
                    .then(() => this.setState({ overlayLoading: false })))

        }

        return (
            <FlexContainer>
                {this.state.overlayLoading && <OverlayLoader /> }
                <Switch>
                    <Route
                        exact
                        path={`${base}`}
                        render={() =>  <PaginatedIssuanceList {...this.props} base={base}
                                                              data={this.props.issuanceList}
                                                              total={this.props.issuanceListTotalCount}
                                                              page={(this.props.issuanceListPage + 1)}
                                                              onPageClick={onPageClick}
                                                              onListEntityClick={onListEntityClick}
                                                              loading={this.state.loading}
                                                              parameters={this.props.issuanceParameters}
                        />}
                    />
                    <Route
                        path={`${base}/form/:id?`}
                        render={(props) => {
                            const { id } = props.match.params;
                            return (<ShareIssuanceForm {...this.props}
                                          initialValues={{
                                              ...this.state.formValues
                                          }}
                                          loading={this.state.loading}
                                          onIssuerSelect={(e) => onIssuerSelect(e)}
                                          onCancel={onCancel}
                                          parameters={this.props.issuanceParameters}
                                          instruments={this.props.instruments}
                                          onOrgSelect={(e) => onOrganisationSelect(e)}
                                          filteredIssuers={this.props.issuers}
                                          onSubmit={(e) => onFormSubmit(e)}
                                          id={id}
                            />)}
                        }
                    />
                    <Route
                        path={`${base}/preview/:id?`}
                        render={(props) => {
                            const { id } = props.match.params;
                            return (<ShareIssuancePreview {...this.props}
                                             base={base}
                                             onCancel={onCancel}
                                             data={this.state.formValues}
                                             onSubmit={onPreviewSubmit}
                                             parameters={this.props.issuanceParameters}
                                             id={id}
                                             fetchIssuanceDetails={triggerFetchIssuanceDetails}
                                             startProcessing={startProcessing}
                            />)}
                        }
                    />
                    <Route
                        path={`${base}/receipt`}
                        render={() =>
                            <ShareIssuanceReceipt onBack={onCancel}{...this.props}/>}
                    />

                    <Route
                        path={`${base}/chat`}
                        render={() =>
                        <ChatBox {...this.props} comments={this.props.issuanceDetails.comments}
                        id={1}/>}
                    />
                </Switch>
            </FlexContainer>
        );
    }
}

ShareIssuanceContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    const { issuanceReducer, loginUser,
        companyInformationReducer } = state;

    return {
        issuanceList: issuanceReducer.issuanceList.list,
        issuanceListPage: issuanceReducer.issuanceList.pageNumber,
        issuanceListTotalCount: issuanceReducer.issuanceList.totalElements,
        issuanceDetails: issuanceReducer.issuanceDetails.details,
        issuanceParameters: issuanceReducer.issuanceParameters.list,
        isSuperUser: isSuperUser(loginUser.user),
        companyList: companyInformationReducer.companyList.list,
        userOrganisation: loginUser.userOrganisation,
        currentUserInfo: loginUser.user,
        instruments: issuanceReducer.issuanceInstruments.list,
        issuers: issuanceReducer.issuanceIssuers.list
    };
}

export default connect(mapStateToProps)(
    withTranslation("translations")(ShareIssuanceContainer)
);
