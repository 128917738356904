import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {ErrorText} from "../components/ErrorText";
import Label from "../components/Label";
import {Field} from "redux-form";
import Input from "../components/Input";
import {userAction} from "./AdminContainer";
import SearchableSelect from "../components/SearchableSelect";
import Button from "../components/Button";
import {InfoText} from "../finanskalender/Edit";
import RadioButton from "../components/RadioButton";
import CheckBox from "../components/CheckBox";
import {getLanguage, hasValue} from "../util/common";
import SearchableMultiSelect from "../components/SearchableMultiSelect";
import {Modal} from "../components/Modal";
import {setPasswordPolicy} from "./AdminActions";
import SearchableSelectNew from "../components/SearchableSelectNew";
import {NotificationManager} from "react-notifications";

export const LabelWithError = (props) => {
    const { error } = props.meta;
    return (
        <FlexContainer margin={[20, 0, 16, 0]}>
            {error ? <ErrorText>{error}</ErrorText> : undefined}
            <Label bold>{props.label}:</Label>
        </FlexContainer>
    );
};

export const TwoFactorCheckBox = (props) => (
    <FlexContainer margin={[0, 0, 0, 0]}>
        <CheckBox
            {...props.input}
            {...props}
            checked={props.input.value}
            onChange={(value) => {
                props.input.onChange(value);
                props.toggleTwoFactorInfo();
            }}
        />
    </FlexContainer>
);

export const RadioButtonUserTypeGroup = (props) => {
    const { error } = props.meta;
    return (
        <FlexContainer column>
            {error && props.index === 0 ? <ErrorText>{error}</ErrorText> : undefined}
            <RadioButton
                fitToContent
                margin={[0, 0, 8, 0]}
                radioValue={props.userTypeName}
                label={props.t(props.userTypeNameLabel)}
                {...props}
            />
            {props.userTypeRoles != null ? (
                <FlexContainer
                    hidden={!(props.input.value === props.userTypeName)}
                    column
                    margin={[8, 0, 0, 16]}
                >
                    {props.userTypeRoles
                        .filter((role) =>
                            props.hiddenRoles
                                ? !props.hiddenRoles.includes(role.id)
                                : true
                        )
                        .map((role) => (
                            <Field
                                key={role.id}
                                fitToContent
                                component={CheckBox}
                                margin={[0, 0, 8, 0]}
                                label={props.t(`user_type_role_${role.id}`)}
                                name={`${props.userTypeName}.${role.id}`}
                            />
                        ))}
                </FlexContainer>
            ) : undefined}
        </FlexContainer>
    );
};

export const RadioButtonTwoFactorProtocolGroup = (props) => {
    return (
        <FlexContainer row margin={[24, 0, 10, 0]} vAlignCenter>
            <RadioButton
                {...props}
                margin={[0, 0, 8, 0]}
                radioValue={"SMS"}
                label={props.t("sms")}
            />
            <RadioButton
                {...props}
                component={RadioButton}
                margin={[0, 0, 8, 10]}
                radioValue={"VOICE"}
                label={props.t("speech_message")}
            />
        </FlexContainer>
    );
};

export const CompanyListBox = (props) => {
    const companies = props.children.filter((company) => company !== "undefined\n");
    return (
        <FlexContainer
            preWrap
            scrollbarsOnOverflow
            whiteBackground
            height={"125px"}
            padding={[8, 8, 8, 8]}
        >
            {companies}
        </FlexContainer>
    )
}

class UserEditRoleManager extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            policyModal: false
        };
    }

    render() {
        const { t, userTypes } = this.props;

        const countryOptions =
            this.props.parameterList && this.props.parameterList.countries
                ? this.props.parameterList.countries.map((country) => {
                    return {
                        label: getLanguage() === "No" ? country.nameNo : country.nameEn,
                        value: country.id,
                    };
                })
                : [];

        const getFilteredUserTypes = () => {
            return Object.values(userTypes).filter((role) => {
                    return role.advanced === this.props.advanced})
        }

        const userStrategies = [
            { label: t("password_policy_normal"), value: 0 },
            { label: t("password_policy_api"), value: 1 },
            { label: t("password_policy_newsfeed"), value: 2 },
            { label: t("password_policy_test"), value: 42 },
        ];

        const onPolicyChange = (val) => {
            if (hasValue(val?.policy?.value)) {
                this.props.dispatch(setPasswordPolicy(this.props.user.globalSubject, 4, val.policy.value ))
                    .then(() => this.setState({ policyModal: false }))
                    .then(() => NotificationManager.success(this.props.t("password_policy_updated")))
            }
        }

        return (
            <FlexContainer column>
                <FlexContainer row>
                    <FlexContainer column>
                        {userTypes != null &&
                            <FlexContainer>
                                <FlexContainer key="roleGroupLabel" margin={[0, 0, 16, 0]} row>
                                    <Label bold>
                                    {t("roles")}:*
                                    </Label>
                                    <FlexContainer vAlignCenter flexEnd row >
                                        <Label margin={[0,5,0,0]}>{this.props.t("advanced")}</Label>
                                        <CheckBox onChange={this.props.onAdvancedChange}
                                                  checked={this.props.advanced}
                                        />
                                    </FlexContainer>
                                </FlexContainer>
                                <FlexContainer>
                                    {this.props.advanced ?
                                        <Field
                                            component={SearchableMultiSelect}
                                            t={this.props.t}
                                            placeholder={t("select")}
                                            options={getFilteredUserTypes().map((userType) => {
                                                return (
                                                    { value: userType.id, label: userType.id }
                                                )
                                            }) || []}
                                            name="userType"
                                            margin={[0,0,10,0]}
                                            multi
                                        /> :
                                     <FlexContainer>
                                        {getFilteredUserTypes().map((userType, index) => {
                                    return (
                                        <Field
                                            name="userType"
                                            key={userType.id}
                                            language={this.props.language}
                                            t={t}
                                            index={index}
                                            component={RadioButtonUserTypeGroup}
                                            userTypeName={userType.id}
                                            userTypeNameLabel={`user_type_${userType.id}`}
                                            userTypeRoles={userType.userRoles}
                                            hiddenRoles={this.props.hiddenRoles}
                                            onChange={this.props.onUserTypeChange}
                                        />
                                    )}
                                    )}
                                        </FlexContainer>
                                    }
                            </FlexContainer>
                            </FlexContainer>
                        }
                    </FlexContainer>

                </FlexContainer>
                {this.props.showTwoFactorCheckBox ? (
                    <FlexContainer>
                        <Label key={"2mfa"} bold margin={[20, 0, 16, 0]}>
                            {t("signin")}:
                        </Label>

                        <Field
                            disabled={!props.editTwoFactor}
                            component={TwoFactorCheckBox}
                            toggleTwoFactorInfo={props.toggleTwoFactorInfo}
                            name="enableTwoFactor"
                            label={t("activate_mfa")}
                        />

                        {this.props.isTwoFactorActive === false && this.props.action === userAction.NEW && (
                            <InfoText>{t("setPasswordReminder")}</InfoText>
                        )}

                        <Field
                            name={"twoFactorProtocol"}
                            t={t}
                            component={RadioButtonTwoFactorProtocolGroup}
                            twoFactorMessageProtocol={this.props.twoFactorMessageProtocol}
                        />

                        <Label>{t("phonenumber_for_receival_mfa")}:</Label>
                        <FlexContainer row margin={[8, 0, 30, 0]}>
                            <Field
                                t={t}
                                component={SearchableSelect}
                                searchable={true}
                                name="areaCode"
                                maxWidth={200}
                                margin={[0, 24, 0, 0]}
                                placeholder={t("choose_area_code")}
                                options={countryOptions}
                            />
                            <Field
                                component={Input}
                                name={"twoFactorPhone"}
                                border
                                maxWidth={208}
                                margin={[0, 24, 0, 0]}
                                placeholder={t("telephonenumber")}
                            />
                        </FlexContainer>
                    </FlexContainer>
                ) : undefined}
                <Label margin={[0, 0, 8, 0]}>{t("issuers")}:</Label>
                <CompanyListBox>
                    {this.props.companyList ?
                        (this.props.companyList.map((comp) => comp + "\n")) : []
                    }
                </CompanyListBox>
                <FlexContainer row>
                    {this.props.action === userAction.EDIT ? (
                        <Button.Standard
                            onClick={this.props.handleSubmit(this.props.onResetPassword)}
                            margin={[16, 0, 0, 0]}
                        >
                            {t("reset_password")}
                        </Button.Standard>
                    ) : undefined}
                    {this.props.action === userAction.EDIT && this.props.showSetPasswordButton ? (
                        <Button.Standard
                            onClick={this.props.onOpenSetPasswordModal}
                            margin={[16, 0, 0, 16]}
                        >
                            {t("set_password")}
                        </Button.Standard>
                    ) : undefined}
                    {this.props.action === userAction.EDIT && this.props.advanced &&
                        <Button.Standard
                            onClick={() => this.setState({ policyModal: true })}
                            margin={[16, 0, 0, 16]}
                        >
                            {t("password_policy")}
                        </Button.Standard>}
                </FlexContainer>
                {this.props.action === userAction.EDIT && this.props.advanced ? (
                    <FlexContainer row>
                        <Button.Standard
                            onClick={() => this.props.onOpenKeyManagementModal("public")}
                            margin={[16, 0, 0, 0]}
                        >
                            {t("public_keys")}
                        </Button.Standard>

                        <Button.Standard
                            onClick={() => this.props.onOpenKeyManagementModal("api")}
                            margin={[16, 0, 0, 32]}
                        >
                            {t("api_keys")}
                        </Button.Standard>

                        <Button.Standard
                            onClick={() => this.props.onOpenNewsfeedManagement()}
                            margin={[16, 0, 0, 32]}
                        >
                            {t("Newsfeed")}
                        </Button.Standard>
                    </FlexContainer>
                ) : undefined}
                <FlexContainer margin={[20, 0, 0, 0]}>
                    <Field
                        component={LabelWithError}
                        name={"auth_option_header"}
                        label={t("auth_options")}
                    />
                    {this.props.getAuthOptions}
                </FlexContainer>

                {this.state.policyModal &&
                    <Modal
                        isOpen={this.state.policyModal}
                        header={t("password_policy")}
                        onClose={() => this.setState({ policyModal: false })}
                    >
                        <FlexContainer margin={[0, 0, 0, 0]} flexEnd vAlignCenter row>
                            <FlexContainer trueCenter>
                                <FlexContainer center width={"50%"} margin={[10,0,70,0]}>
                                    <Field
                                        name="policy"
                                        t={this.props.t}
                                        component={SearchableSelectNew}
                                        options={userStrategies}
                                        placeholder={this.props.t("select")}
                                    />
                                </FlexContainer>

                                <FlexContainer margin={[24, 0, 0, 0]} flexEnd vAlignCenter row>
                                    <Button.Standard
                                        margin={[0, 16, 0, 0]}
                                        onClick={this.props.handleSubmit(onPolicyChange)}
                                    >
                                        {t("save")}
                                    </Button.Standard>
                                    <Button.Text
                                        onClick={() => this.setState({ policyModal: false })}
                                    >
                                        {t("cancel")}
                                    </Button.Text>
                                </FlexContainer>
                            </FlexContainer>
                        </FlexContainer>
                    </Modal>
                }
            </FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const {

    } = state;

    return {
    };
}

export default connect(mapStateToProps)(withTranslation("translations")(UserEditRoleManager));
