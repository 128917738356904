import React from "react";
import styled, { css } from "styled-components";
import FocusElement from "./FocusElement";
import { browserIsIE } from "../util/common";

const Label = styled.label`
  /*select -> user cannot highlight text*/
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  display: flex;
  align-items: flex-start;
  color: black;
  &:focus {
    outline: none;
  }

  ${(props) =>
    props.fitToContent
      ? css`
          ${browserIsIE() ? "display: table;" : "width: fit-content;"};
        `
      : ""};

  ${(props) => (props.disabled ? "" : "cursor: pointer;")}
  ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
    ${(props) => (props.center ? "justify-content: center;" : "")}
    ${(props) => (props.noWrap ? "white-space: nowrap;" : "")}
    ${(props) =>
    props.fontSize ? "font-size: " + props.fontSize + "px;" : ""};
`;

const Icon = styled.img`
  display: ${(props) => (props.displayIcon ? "inline;" : "none;")};
  ${(props) => (props.disabled ? "" : "cursor: pointer;")} ${(props) =>
    props.rightPadding ? "padding-right: 8px;" : ""};
`;

class CheckBox extends React.Component {
  constructor(props) {
    super(props);
    this.onChange =
      this.props.onChange ||
      (this.props.input != null ? this.props.input.onChange : undefined);
    this.state = {
      checked: false,
    };
  }
  hasChangedInUI = false;

  componentWillMount() {
    const isChecked =
      this.props.checked ||
      (this.props.input != null ? this.props.input.value : false);

    this.setState({ checked: isChecked });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.hasChangedInUI && nextProps.checked != null) {
      this.setState({ checked: nextProps.checked });
    } else if (this.props.useReduxCheckState) {
      this.setState({ checked: nextProps.input.value });
    }
  }

  render() {
    const updateCheckBox = () => {
      this.hasChangedInUI = true;
      this.onChange(!this.state.checked);
      this.setState({ checked: !this.state.checked });
    };

    const onEnterKeyPress = (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        return !this.props.disabled ? updateCheckBox() : undefined;
      }
    };

    return (
      <Label
        {...this.props}
        onClick={!this.props.disabled ? updateCheckBox : undefined}
      >
        <Icon
          onKeyDown={onEnterKeyPress}
          tabIndex="0"
          disabled={this.props.disabled}
          rightPadding={this.props.label != null && this.props.label.length > 0}
          displayIcon={!this.state.checked}
          aria-hidden="true"
          alt={this.props.label}
          src="/icons/ic-check-box-blank-24-px.svg"
        />
        <Icon
          onKeyDown={onEnterKeyPress}
          tabIndex="0"
          disabled={this.props.disabled}
          rightPadding={this.props.label != null && this.props.label.length > 0}
          displayIcon={this.state.checked}
          aria-hidden="true"
          alt={this.props.label}
          src="/icons/ic-check-box-checked-24-px.svg"
        />

        {this.props.label}
      </Label>
    );
  }
}

CheckBox.defaultProps = {
  disabled: false,
  useReduxCheckState: false,
};

export default CheckBox;
