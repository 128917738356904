import { combineReducers } from "redux";
import { MFAActions, MFACode, User } from "../actions/MFAActions";
import { GetAuthenticateParams } from "./NewUserActions";

const initalAuthParams = {
  list: [],
  isFetched: false,
};

export const authParamList = (
  state = {
    ...initalAuthParams,
  },
  action
) => {
  switch (action.type) {
    case GetAuthenticateParams.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case GetAuthenticateParams.request:
      return Object.assign({}, state, {
        isFetched: false,
      });
    default:
      return state;
  }
};

const newUserReducer = combineReducers({
  authParamList,
});

export default newUserReducer;
