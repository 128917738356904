import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import { ErrorText } from "../components/ErrorText";
import { FlexContainer } from "../components/FlexContainer";
import Input from "../components/Input";
import Label from "../components/Label";
import { MAX_RESULT_ON_PAGE } from "../components/PaginationContainer";
import Table, { TD, TR } from "../components/Table";
import Button from "../components/Button";
import SearchableSelectNew from "../components/SearchableSelectNew";
import { reset } from "redux-form";

const ListTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const AddListBox = (props) => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]} maxWidth>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:
        {error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>
      <Input {...props} {...props.input} border />
    </FlexContainer>
  );
};

class AddToListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
          <FlexContainer margin={[10, 0, 10, 0]}>
            {t("edist_distribution_list_info")}
          </FlexContainer>

          <FlexContainer row margin={[10, 0, 10, 0]} vAlignEnd spaceBetween>
            <FlexContainer minWidth={900} row>
              <Field
                name="name"
                noRightMargin
                component={AddListBox}
                label={t("edist_list_name")}
              />
              <Field
                name="description"
                noRightMargin
                component={AddListBox}
                label={t("edist_list_description")}
              />
            </FlexContainer>
            <FlexContainer vAlignCenter row width="auto" spaceBetween>
              <Button.Standard
                margin={[0, 0, 0, 20]}
                onClick={this.props.handleSubmit(this.props.addNewList)}
              >
                {t("edist_add_new_list")}
              </Button.Standard>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}
AddToListComponent = reduxForm({ form: "addToListComponent" })(
  AddToListComponent
);

class OrganisationSectionComponent extends React.Component {
  render() {
    const { organisationDropdownList, selectOrganisation, t, fetchAllLists } =
      this.props;
    return (
      <FlexContainer margin={[10, 0, 20, 0]} row>
        <Field
          component={SearchableSelectNew}
          options={organisationDropdownList}
          t={t}
          maxWidth={"360"}
          onSelect={(organisation) => {
            selectOrganisation(organisation.value);
          }}
          placeholder={t("organisation")}
          name={"organisation"}
          clearable={false}
        />
        <Button.Standard margin={[0, 0, 0, 20]} onClick={fetchAllLists}>
          {t("edist_fetch_all_lists")}
        </Button.Standard>
      </FlexContainer>
    );
  }
}
OrganisationSectionComponent = reduxForm({
  form: "organisationSectionComponent",
})(OrganisationSectionComponent);

const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1,
};

class EmailDistributionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      sortOrder: sortOrder.DESCENDING,

      selectedOrganisation: undefined,
    };
  }

  organisationNameSort = (a, b) => {
    if (a.orgName > b.orgName) return 1 * this.state.sortOrder;
    if (a.orgName < b.orgName) return -1 * this.state.sortOrder;
    return 0;
  };

  render() {
    const { t } = this.props;
    const thLabels = [
      t("edist_list_name"),
      t("edist_list_description"),

      t("status"),
    ];
    if (this.props.isSuperUser) {
      thLabels.push(t("edist_organisation"));
    }

    const tableRows =
      this.props.data != null
        ? this.props.data
            .filter((organisation) => {
              if (!this.props.isSuperUser) {
                return true;
              } else {
                if (this.state.selectedOrganisation) {
                  return organisation.orgId === this.state.selectedOrganisation;
                } else {
                  return true;
                }
              }
            })
            .sort(this.organisationNameSort)
            .map((entity, index) => (
              <TR key={index}>
                <TD
                  pointer
                  onClick={() =>
                    this.props.history.push(
                      `${this.props.base}/list/${entity.id}`
                    )
                  }
                >
                  <ListTitle>{entity.name}</ListTitle>
                </TD>
                <TD>{entity.description}</TD>

                <TD>{t("edist_status_" + entity.status)}</TD>
                {this.props.isSuperUser ? (
                  <TD> {entity.orgName} </TD>
                ) : undefined}
              </TR>
            ))
        : [];

    const onSortClick = () =>
      this.state.sortOrder === sortOrder.DESCENDING
        ? this.setState({ sortOrder: sortOrder.ASCENDING })
        : this.setState({ sortOrder: sortOrder.DESCENDING });

    const tableHeaderProps = [
      undefined,
      undefined,
      undefined,
      {
        onClick: () => onSortClick(),
        pointer: true,
      },
    ];

    const organisationDropdownList =
      this.props.organisationList && this.props.organisationList.length > 0
        ? this.props.organisationList.map((organisation) => {
            return { label: organisation.name, value: organisation.id };
          })
        : [];

    const selectOrganisation = (organisationId) => {
      this.setState({ selectedOrganisation: organisationId });
    };

    const fetchAllLists = () => {
      this.setState({ selectedOrganisation: undefined });
      this.props.dispatch(reset("organisationSectionComponent"));
      this.props.fetchAllListsSuperUser();
    };

    return (
      <React.Fragment>
        <FlexContainer
          column
          padding={
            tableRows && tableRows.length < MAX_RESULT_ON_PAGE
              ? [0, 0, 0, 0]
              : []
          }
          margin={[0, 0, 15, 0]}
        >
          <FlexContainer row>
            <AddToListComponent
              addNewList={this.props.addNewList}
              t={t}
              {...this.props}
            />
          </FlexContainer>

          {this.props.isSuperUser ? (
            <OrganisationSectionComponent
              organisationDropdownList={organisationDropdownList}
              selectOrganisation={selectOrganisation}
              t={t}
              fetchAllLists={fetchAllLists}
            />
          ) : undefined}

          <Table
            tableRows={tableRows}
            tableHeaderLabels={thLabels}
            forceUpdate={true}
            t={t}
            tableHeaderProps={this.props.isSuperUser ? tableHeaderProps : []}
          />
        </FlexContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(EmailDistributionList);
