import {
  AUDIT_COMMITEE_MEMBER_1,
  AUDIT_COMMITEE_MEMBER_2, hasValue,
} from "../util/common";
import { emailRequirementValidator } from "../util/emailValidator";

export const validateNewContactPerson = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );
  const requiredFieldsCheck = () => {
    if (values.email == null) {
      errors.email = props.t("required_field");
    }
    if (values.firstName == null) {
      errors.firstName = props.t("required_field");
    }
    if (values.lastName == null) {
      errors.lastName = props.t("required_field");
    }
    if (values.mobile == null && values.office == null) {
      errors.mobile = props.t("required_field_phone");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.mobile != null &&
      !/^\+[0-9]+|[0-9]+/i.test(values.mobile) &&
      values.mobile.length > 0
    ) {
      errors.mobile = props.t("invalid_phonenumber");
    }
    if (
      values.office != null &&
      !/[0-9]+/i.test(values.office) &&
      values.office.length > 0
    ) {
      errors.office = props.t("invalid_phonenumber");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateEditContactPerson = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredFieldEdit = () => {
    if (!values.email) {
      errors.email = props.t("required_field");
    }
    if (!values.firstName) {
      errors.firstName = props.t("required_field");
    }
    if (!values.lastName) {
      errors.lastName = props.t("required_field");
    }
    if (!values.mobile && !values.office) {
      errors.mobile = props.t("required_field_phone");
    }
    if (!values.editContactPerson) {
      errors.editContactPerson = props.t("required_field");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.mobile &&
      !/^\+[0-9]+|[0-9]+/i.test(values.mobile) &&
      values.mobile.length > 0
    ) {
      errors.mobile = props.t("invalid_phonenumber");
    }
    if (
      values.office &&
      !/[0-9]+/i.test(values.office) &&
      values.office.length > 0
    ) {
      errors.office = props.t("invalid_phonenumber");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredFieldEdit();
  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateNewAccountant = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );
  const requiredFieldsCheck = () => {
    if (!values.accountant_country) {
      errors.accountant_country = props.t("required_field");
    }
    if (!values.company_name) {
      errors.company_name = props.t("required_field");
    }
    if (!values.email) {
      errors.email = props.t("required_field");
    }
    if (!values.firstName) {
      errors.firstName = props.t("required_field");
    }
    if (!values.lastName) {
      errors.lastName = props.t("required_field");
    }
    if (!values.mobile && !values.office) {
      errors.mobile = props.t("required_field_phone");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.mobile &&
      !/^\+[0-9]+|[0-9]+/i.test(values.mobile) &&
      values.mobile.length > 0
    ) {
      errors.mobile = props.t("invalid_phonenumber");
    }
    if (
      values.office &&
      !/[0-9]+/i.test(values.office) &&
      values.office.length > 0
    ) {
      errors.office = props.t("invalid_phonenumber");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredFieldsCheck();
  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const validateEditAccountant = (values, props) => {
  const errors = {};
  Object.keys(values).forEach((key) =>
    values[key] != null && values[key].length === 0
      ? (values[key] = null)
      : undefined
  );

  const requiredFieldEdit = () => {
    if (!values.accountantFirm) {
      errors.accountantFirm = props.t("required_field");
    }
    if (!values.accountant_country) {
      errors.accountant_country = props.t("required_field");
    }
    if (!values.email) {
      errors.email = props.t("required_field");
    }
    if (!values.firstName) {
      errors.firstName = props.t("required_field");
    }
    if (!values.lastName) {
      errors.lastName = props.t("required_field");
    }
    if (!values.mobile && !values.office) {
      errors.mobile = props.t("required_field_phone");
    }
    if (!values.editContactPerson) {
      errors.editContactPerson = props.t("required_field");
    }
  };

  const valueFormatCheck = () => {
    if (
      values.mobile != null &&
      !/^\+[0-9]+|[0-9]+/i.test(values.mobile) &&
      values.mobile.length > 0
    ) {
      errors.mobile = props.t("invalid_phonenumber");
    }
    if (
      values.office != null &&
      !/[0-9]+/i.test(values.office) &&
      values.office.length > 0
    ) {
      errors.office = props.t("invalid_phonenumber");
    }
    if (emailRequirementValidator(values.email)) {
      errors.email = props.t("invalid_email");
    }
  };

  requiredFieldEdit();
  valueFormatCheck();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const ValidateOrg = (values, props) => {
  const errors = {};
  if (values) {
    Object.keys(values).forEach((key) =>
      values[key] && values[key].length === 0 ? (values[key] = null) : undefined
    );
  }

  const requiredFieldUpdate = () => {
    if (values) {
      if (values.orgno && values.orgno.length > 11) {
        errors.orgno = props.t("organization_number_too_long");
      }
    }
  };

  requiredFieldUpdate();

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const NewCompanyValidator = (values, props) => {
  var errors = {};
  const { t } = props;

  if (Object.keys(values).length !== 0 && values.constructor !== Object) {
    errors.orgno = t("required_field");
  }

  if (values["roles"] == null || values["roles"].length === 0) {
    errors.roles = t("required_field");
  }
  if (values["issuerStatus"] == null || values["issuerStatus"].length === 0) {
    errors.issuerStatus = t("required_field");
  }

  if (values["longName"] == null || values["longName"].length === 0) {
    errors.longName = t("required_field");
  }

  if (values["shortName"] == null || values["shortName"].length === 0) {
    errors.shortName = t("required_field");
  }

  if (values["orgno"] == null || values["orgno"].length === 0) {
    errors.orgno = t("required_field");
  }

  if (Object.keys(values).length !== 0 && values.orgno != null) {
    if (values["orgno"].length > 11) {
      errors.orgno = props.t("organization_number_too_long");
    }
  }

  if (hasValue(values.longName) && values.longName.length > 40) {
    errors.longName = props.t("longname_too_long");
  }

  if (hasValue(values.shortName) && values.shortName.length > 30) {
    errors.shortName = props.t("shortname_too_long");
  }

  if (hasValue(values.symbol) && values.symbol.length > 16) {
    errors.symbol = props.t("symbol_too_long");
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const CompanyDetailsValidator = (values, props) => {
  const errors = {};
  const { t } = props;

  const validateFieldLength = (field, maximumCharacters) => {
    if (values[field] !== undefined && values[field].length > maximumCharacters) {
      errors[field] = t("field_length_exceeded", {length: maximumCharacters});
    }
  }

  const validateField = (field) => {
    if (
      props.isRequired(field) &&
      (values[field] == null ||
        values[field].length === 0 ||
        values[field].value === null)
    )
      errors[field] = t("required_field");
  };

  validateField("lei");
  validateField("cid");
  validateField("crmIntegrated");
  validateField("longName");
  validateField("shortName");
  validateField("symbol");
  validateField("roles");
  validateField("issuerStatus");
  validateField("country");
  validateField("orgno");
  validateField("websiteAddress");
  validateField("phone");
  validateField("prospectusInspection");
  validateField("jurisdictionDomcicle");
  validateField("regAsGroup");
  validateField("primaryListing");
  validateField("email");
  validateField("periodicInformation");
  validateField("jurisdictionTakeOverRules");
  validateField("accountingStandard");
  validateField("logo");


  validateFieldLength("longName", 40)
  validateFieldLength("shortName", 30)
  validateFieldLength("orgno", 11)
  validateFieldLength("lei", 20)
  validateFieldLength("symbol", 16)
  validateFieldLength("websiteAddress", 255)
  validateFieldLength("email", 255)
  validateFieldLength("phone", 30)



  if (
    values["logoContentType"] != null &&
    values["logoContentType"].length > 0 &&
    values["logoContentType"].match(/png|jpg|bmp|gif|jpeg/) == null
  ) {
    errors["logo"] = t("logo_extension_error");
  }
  if (
      values.phone != null &&
      !/^\+[0-9]+|[0-9]+/i.test(values.phone) &&
      values.phone.length > 0
  ) {
    errors.phone = props.t("invalid_phonenumber");
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const AddressValidator = (values, props) => {
  var errors = {};
  const { t } = props;

  const validateFieldLength = (field, maximumCharacters) => {
    if (values[field] !== undefined && values[field].length > maximumCharacters) {
      errors[field] = t("field_length_exceeded", {length: maximumCharacters});
    }
  }

  if (props.isRequired("address")) {
    if (values["1_address1"] == null || values["1_address1"].length === 0)
      errors["error_placeholder"] = t("required_field");
  }

  validateFieldLength("1_address1", 255)
  validateFieldLength("1_address2", 255)
  validateFieldLength("1_pcode", 255)
  validateFieldLength("1_city", 255)
  validateFieldLength("1_foreign_postal", 255)

  validateFieldLength("2_address1", 255)
  validateFieldLength("2_address2", 255)
  validateFieldLength("2_pcode", 255)
  validateFieldLength("2_city", 255)
  validateFieldLength("2_foreign_postal", 255)

  validateFieldLength("3_address1", 255)
  validateFieldLength("3_address2", 255)
  validateFieldLength("3_pcode", 255)
  validateFieldLength("3_city", 255)
  validateFieldLength("3_foreign_postal", 255)

  validateFieldLength("4_address1", 255)
  validateFieldLength("4_address2", 255)
  validateFieldLength("4_pcode", 255)
  validateFieldLength("4_city", 255)
  validateFieldLength("4_foreign_postal", 255)

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const ContactPersonValidator = (values, props) => {
  var errors = {};
  const { t } = props;

  const validateField = (roleId) => {
    const field = roleId + "selectedContact";
    if (
      props.isRequired(roleId) &&
      (values[field] == null || values[field].length === 0)
    )
      errors[field] = t("required_field");
  };

  Object.keys(values).forEach((role) => {
    const roleId = Number(role[0]);
    validateField(roleId);
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const AuditCommitteeValidator = (values, props) => {
  var errors = {};
  const { t } = props;

  if (
    props.isRequired("collectively") &&
    (values.collectively == null || values.collectively.length === 0)
  )
    errors.collectively = t("required_field");

  if (
    props.isRequired("electedBy") &&
    (values.electedBy == null || values.electedBy.length === 0)
  )
    errors.electedBy = t("required_field");

  if (
    props.isRequired("establishedBy") &&
    (values.establishedBy == null || values.establishedBy.length === 0)
  )
    errors.establishedBy = t("required_field");

  if (
    props.isRequired(AUDIT_COMMITEE_MEMBER_1) &&
    values[AUDIT_COMMITEE_MEMBER_1 + "selectedContact"] == null
  )
    errors[AUDIT_COMMITEE_MEMBER_1 + "selectedContact"] = t("required_field");

  if (
    props.isRequired(AUDIT_COMMITEE_MEMBER_2) &&
    values[AUDIT_COMMITEE_MEMBER_2 + "selectedContact"] == null
  )
    errors[AUDIT_COMMITEE_MEMBER_2 + "selectedContact"] = t("required_field");

  return Object.keys(errors).length > 0 ? errors : undefined;
};

export const DummyValidator = (values, props) => {
  return undefined;
};
