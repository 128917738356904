import {fetchData, postData, putData} from "../actions/CommonActionsNoObsvc";

export const MessageActions = {
  request: "REQUEST_MESSAGE",
  postSuccess: "RECEIVE_MESSAGE",
  postFail: "RECEIVE_MESSAGE_FAILED",
  clear: "CLEAR_MESSAGE",
};
export const resetMessageData = () => {
  return { type: MessageActions.clear };
};

export const fetchMessage = (id) =>
  postData(MessageActions, undefined, { id }, "v1/news/get");

export const CorrectionMessageActions = {
  request: "REQUEST_CORRECTION_MESSAGE",
  postSuccess: "RECEIVE_CORRECTION_MESSAGE",
  postFail: "RECEIVE_CORRECTION_MESSAGE_FAILED",
};
export const fetchCorrectionMessage = (id) =>
  postData(CorrectionMessageActions, undefined, { id }, "v1/news/correction");

export const MessageListActions = {
  request: "REQUEST_MESSAGE_LIST",
  postSuccess: "RECEIVE_MESAGE_LIST",
  postFail: "RECEIVE_MESSAGE_LIST_FAILED",
};

export const fetchMessageList = (params) =>
  postData(
    MessageListActions,
    undefined,
    {
      messageStatuses: ["PUBLISHED", "DELETED"],
      ...params,
      ...(params && params.cids ? { cids: [params.cids] } : {}),
      limit: params && params.page ? params.page : 1,
    },
    "v1/news/list"
  );

export const MessageCountActions = {
  request: "REQUEST_MESSAGE_COUNT",
  postSuccess: "RECEIVE_MESSAGE_COUNT",
  postFail: "RECEIVE_MESSAGE_COUNT_FAILED",
};

export const fetchMessageCount = (params) =>
    postData(
        MessageCountActions,
        undefined,
        {
          messageStatuses: ["PUBLISHED", "DELETED"],
          ...params,
          ...(params && params.cids ? { cids: [params.cids] } : {}),
        },
        "v1/news/numberOfMessages"
    );

export const MessageDraftCountActions = {
  request: "REQUEST_MESSAGE_DRAFT_COUNT",
  postSuccess: "RECEIVE_MESSAGE_DRAFT_COUNT",
  postFail: "RECEIVE_MESSAGE_DRAFT_COUNT_FAILED",
};

export const fetchDraftMessageCount = (params) =>
    postData(
        MessageDraftCountActions,
        undefined,
        {
          messageStatuses: ["DRAFT", "PENDING_PUBLISHING"],
          ...params,
          ...(params && params.cids ? { cids: [params.cids] } : {}),
        },
        "v1/news/numberOfMessages"
    );

export const DraftMessageListActions = {
  request: "REQUEST_DRAFT_MESSAGE_LIST",
  postSuccess: "RECEIVE_DRAFT_MESAGE_LIST",
  postFail: "RECEIVE_DRAFT_MESSAGE_LIST_FAILED",
};

export const fetchDraftMessageList = (params) =>
  postData(
    DraftMessageListActions,
    undefined,
    {
      messageStatuses: ["DRAFT", "PENDING_PUBLISHING"],
      ...params,
      ...(params && params.cids ? { cids: [params.cids] } : {}),
        limit: params && params.page ? params.page : 1,
    },
    "v1/news/list"
  );

export const Securities = {
  request: "REQUEST_ISSUER_GET",
  postSuccess: "RECEIVE_ISSUER_GET",
  postFail: "RECEIVE_ISSUER_GET_FAILED",
  clear_securities: "CLEAR_SECURITY_LIST",
};
export const fetchSecurities = (id) =>
  postData(Securities, undefined, { id: id }, "v1/news/getSecuritiesByCompany");

export const clearSecurities = () => {
  return { type: Securities.clear_securities };
};

export const CreateNewsMessage = {
  post: "CREATE_NEWS_MESSAGE",
  postSuccess: "CREATE_SUCCESS_NEWS_MESSAGE",
  postFail: "CREATE_FAIL_NEWS_MESSAGE",
};

export const createNewsMessage = (message) =>
  postData(CreateNewsMessage, undefined, { ...message }, "v1/news/add");

export const UpdateNewsMessage = {
  post: "UPDATE_NEWS_MESSAGE",
  postSuccess: "UPDATE_NEWS_MESSAGE_SUCCESS",
  postFail: "UPDATE_NEWS_MESSAGE_FAIL",
};

export const updateNewsMessage = (message) =>
  postData(UpdateNewsMessage, undefined, { ...message }, "v1/news/update");

export const FetchAttachmentActions = {
  request: "REQUEST_ATTACHMENT",
  postSuccess: "RECEIVE_ATTACHMENT",
  postFail: "RECEIVE_ATTACHMENT_FAILED",
};

export const fetchAttachment = (attId) =>
  postData(
    FetchAttachmentActions,
    undefined,
    { attId: attId },
    "v1/news/attachment/get?obsvc.item=1" // Fixme
  );

export const UploadAttachmentActions = {
  request: "UPLOAD_NEWS_ATTACHMENT",
  postSuccess: "UPLOAD_NEWS_ATTACHMENT_FAILED",
  postFail: "UPLOAD_NEWS_ATTACHMENT_SUCCESS",
};

export const uploadAttachment = (attachment) =>
  postData(
    UploadAttachmentActions,
    undefined,
    attachment,
    "v1/news/attachment/add",
    false
  );

export const DeleteAttachmentActions = {
  request: "DELETE_ATTACHMENT",
  postSuccess: "DELETE_ATTACHMENT_FAILED",
  postFail: "DELETE_ATTACHMENT_SUCCESS",
};

export const removeAttachment = (attId) =>
  postData(
    DeleteAttachmentActions,
    undefined,
    { attId },
    "v1/news/attachment/remove"
  );

export const RemoveNewsMessage = {
  post: "DELETE_NEWS_MESSAGE",
  postSuccess: "DELETE_NEWS_MESSAGE_SUCCESS",
  postFail: "DELETE_NEWS_MESSAGE_FAILED",
};

export const removeNewsMessage = (id) =>
  postData(RemoveNewsMessage, undefined, { id }, "v1/news/remove");

export const GlobalMessagePermissionActions = {
  request: "REQUEST_GLOBAL_MESSAGE_PERMISSION",
  postSuccess: "RECEIVE_GLOBAL_MESSAGE_PERMISSION_SUCCESS",
  postFail: "RECEIVE_GLOBAL_MESSAGE_PERMISSION_FAILED",
};
export const fetchGlobalMessagePermission = (id) =>
  postData(
    GlobalMessagePermissionActions,
    undefined,
    { id },
    "v1/news/permissions"
  );

export const MessagePermissionActions = {
  request: "REQUEST_MESSAGE_PERMISSION",
  postSuccess: "RECEIVE_MESSAGE_PERMISSION_SUCCESS",
  postFail: "RECEIVE_MESSAGE_PERMISSION_FAILED",
};
export const fetchMessagePermission = (id, cid) =>
  postData(
    MessagePermissionActions,
    undefined,
    { newsId: id, cid: cid },
    "v1/news/permissions"
  );

export const AttachmentListActions = {
  request: "REQUEST_ATTACHMENT_LIST",
  postSuccess: "RECEIVE_ATTACHMENT_LIST",
  postFail: "RECEIVE_ATTACHMENT_LIST_FAILED",
};

export const fetchAttachmentList = (newsId) =>
  postData(
    AttachmentListActions,
    undefined,
    { newsId },
    "v1/news/attachment/list"
  );

export const MessageParametersActions = {
  request: "REQUEST_NEWS_PARAMS",
  postSuccess: "RECEIVE_NEWS_PARAMS",
  postFail: "RECEIVE_NEWS_PARAMS_FAILED",
};

export const fetchMessageParams = () =>
  postData(
    MessageParametersActions,
    undefined,
    undefined,
    "v1/news/parameters"
  );

export const CategoryActions = {
  request: "REQUEST_CATEGORIES",
  postSuccess: "RECEIVE_CATEGORIES",
  postFail: "RECEIVE_CATEGORIES_FAILED",
};
export const fetchCategories = (id) =>
  postData(CategoryActions, undefined, { id }, "v1/news/categories/list");

export const ChannelActions = {
    request: "REQUEST_CHANNELS",
    receive: "RECEIVE_CHANNELS",
    receiveFail: "RECEIVE_CHANNELS_FAILED",
};
export const fetchChannels = () =>
    fetchData(ChannelActions, undefined,  "v1/news/channels", false);

export const RepublishMessageActions = {
    request: "REQUEST_REPUBLISH",
    postSuccess: "RECEIVE_REPUBLISH",
    postFail: "RECEIVE_REPUBLISH_FAILED",
};
export const republishMessage = (msg) =>
    postData(RepublishMessageActions, undefined, msg, "v1/news/republish");

export const UpdateAttachmentActions = {
    request: "UPDATE_ATTACHMENT",
    putSuccess: "UPDATE_ATTACHMENT_FAILED",
    putFail: "UPDATE_ATTACHMENT_SUCCESS",
};

export const updateAttachments = (attachments) =>
    putData(
        UpdateAttachmentActions,
        undefined,
        { attachments },
        "v1/news/attachment/updateFilename"
    );
