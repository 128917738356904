import moment from "moment";
import { OBNTInsiderToolDateTimeFormat } from "../../../util/common";
import { emailRequirementValidator } from "../../../util/emailValidator";

export const validateInsiderPerson = (values, props) => {
  const { t } = props;
  var errors = {};

  const validObtainedTimeDate = !(
    (values.obtained_time != null && values.obtained_date == null) ||
    (values.obtained_time == null && values.obtained_date != null)
  );
  const validCeasedTimeDate = !(
    (values.ceased_time != null && values.ceased_date == null) ||
    (values.ceased_time == null && values.ceased_date != null)
  );
  const ceasedTimeDateIsInFuture = moment(
    values.ceased_date + " " + values.ceased_time,
    OBNTInsiderToolDateTimeFormat
  ).isAfter(moment.now());

  if (emailRequirementValidator(values.email))
    errors.email = t("invalid_email");
  if (!validObtainedTimeDate)
    errors.obtainedTimeDateError = t("it_both_time_values_must_be_filled");

  if (!validCeasedTimeDate)
    errors.ceasedTimeDateError = t("it_both_time_values_must_be_filled");
  else if (ceasedTimeDateIsInFuture)
    errors.ceasedTimeDateError = t("it_ceased_date_cannot_be_in_the_future");

  if (values.twoFactorPhone != null && values.twoFactorPhone.length > 0) {
    if (twoFactorNumberIncludesIllegalChars(values.twoFactorPhone)) {
      errors.twoFactorPhone = t("it_only_numbers_allowed");
    }
  }

  return Object.keys(errors).length > 0 ? errors : undefined;
};

const twoFactorNumberIncludesIllegalChars = twoFactorNumber => {
  return twoFactorNumber.startsWith("00") || !/^\d+$/i.test(twoFactorNumber);
};

export const validateInsiderFromExcel = (values, props) => {
  const { t } = props;
  const errors = {};

  Object.keys(values).forEach(key => {
    const index = key.substring(0, key.indexOf("-"));
    if (key.indexOf("-twoFactorPhone") > -1) {
      if (values[key] && !values[index + "-twoFactorCountryCode"]) {
        errors[index + "-twoFactorCountryCode"] = t(
          "it_country_code_is_required"
        );
      }
    }

    if (key.indexOf("twoFactorCountryCode") > -1) {
      const twoFactorNumber = values[index + "-twoFactorPhone"];

      if (twoFactorNumber != null && twoFactorNumber.length > 0)
        if (twoFactorNumberIncludesIllegalChars(twoFactorNumber)) {
          errors[index + "-twoFactorPhone"] = t("it_only_numbers_allowed");
        }
    }
    if (key.indexOf("email") > -1) {
      const mail = values[index + "-email"];
      if (emailRequirementValidator(mail)) {
        errors[index + "-email"] = t("invalid_email");
      }
    }
  });

  return Object.keys(errors).length > 0 ? errors : undefined;
};
