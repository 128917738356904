import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {getHealthchecks} from "./StatusActions";
import {getFormattedTime} from "../util/common";
import Label from "../components/Label";
import Button from "../components/Button";
import Table, {TD, TR} from "../components/Table";
import Loader from "../components/Loader";

class Healthchecks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            time: undefined,
            loading: true
        };
    }

    componentDidMount() {
        this.fetchHealthchecks()
    }

    fetchHealthchecks() {
        this.props.dispatch(getHealthchecks())
            .then(() => {
                this.setState({time: getFormattedTime(), loading: false})
            })
    }
    render() {
        const { t } = this.props;

        const tableHeaderLabels = [t("name"), t("Server"), t("Healthy")];
        const tableHeaderProps = [undefined, undefined, { center: true }];
        const tableRows = this.props.healthchecks?.map((row, index) => {
            return  (
                <TR key={index}>
                    <TD bold>{row.name}</TD>
                    <TD>{row.server}</TD>
                    <TD center>{row.healthy ?
                        <Label fontSize={"16"} color={"green"}>{t("yes")}</Label> :
                        <Label fontSize={"16"} color={"red"}>{t("no")}</Label>}
                    </TD>
                </TR>
            )
        })

        return (
            <FlexContainer center vAlignCenter margin={[0, 0, 0, 0]}>

                <FlexContainer row vAlignCenter spaceBetween>
                    <Label fontSize={18} bold>Status ({this.state.time}):</Label>
                    <Button.Standard margin={[2,2,2,2]}
                                     onClick={() => this.fetchHealthchecks()}>{t("refresh")}
                    </Button.Standard>
                </FlexContainer>

                {this.state.loading && <Loader />}

                {this.props.healthchecks.length > 0 &&
                    <Table
                        tableRows={tableRows}
                        tableHeaderLabels={tableHeaderLabels}
                        tableHeaderProps={tableHeaderProps}
                        t={t}
                        forceUpdate={true}
                    /> }
            </FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const { statusReducer } = state;

    return {
        healthchecks: statusReducer.healthchecks.list,
        healthchecksFetched: statusReducer.healthchecks.isFetched
    };
}

export default connect(mapStateToProps)(withTranslation("translations")(Healthchecks));
