import {
  FinanceCalendarList,
  OptionalEventList,
  PreviewCalendarMessage,
} from "./FinanceCalendarActions";
import { combineReducers } from "redux";

const initalState = {
  financialCalendar: {},
  error: undefined,
};

export const calendar = (
  state = {
    ...initalState,
  },
  action
) => {
  switch (action.type) {
    case FinanceCalendarList.postSuccess:
      return Object.assign({}, state, {
        financialCalendar: action.data.data.entity,
      });
    case FinanceCalendarList.postFail:
      return Object.assign({}, state, {
        financialCalendar: {},
      });
    default:
      return state;
  }
};

const initialOptionalState = {
  optionalEvents: [],
  error: undefined,
  optionalEventsIsFetched: false,
};

export const optionalEvents = (
  state = {
    ...initialOptionalState,
  },
  action
) => {
  switch (action.type) {
    case OptionalEventList.postSuccess:
      return Object.assign({}, state, {
        optionalEvents: action.data.data.entity,
        optionalEventsIsFetched: true,
      });
    case OptionalEventList.postFail:
      return Object.assign({}, state, {
        optionalEvents: [],
        optionalEventsIsFetched: false,
      });
    default:
      return state;
  }
};

const initialPreviewMessageState = {
  previewMessageList: undefined,
  error: undefined,
  previewMessageIsFetched: false,
};

const previewMessage = (
  state = {
    ...initialPreviewMessageState,
  },
  action
) => {
  switch (action.type) {
    case PreviewCalendarMessage.postSuccess:
      return Object.assign({}, state, {
        previewMessageList: action.data.data.entity,
        previewMessageIsFetched: true,
      });
    case PreviewCalendarMessage.postFail:
      return Object.assign({}, state, {
        previewMessageList: undefined,
        previewMessageIsFetched: false,
        error: action.error,
      });
    case PreviewCalendarMessage.clearPreviewMessages:
      return Object.assign({}, state, {
        ...initialPreviewMessageState,
      });
    default:
      return state;
  }
};

const financialReducer = combineReducers({
  calendar,
  optionalEvents,
  previewMessage,
});

export default financialReducer;
