import React from "react";
import styled from "styled-components";
import { Modal } from "../components/Modal";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { FlexContainer } from "../components/FlexContainer";
import { ActionText } from "../components/ActionText";
import Button from "../components/Button";
import Input from "../components/Input";
import { PageHeading } from "../components/PageHeading";
import { ErrorText } from "../components/ErrorText";
import { Link } from "react-router-dom";
import i18n from "../i18n";
import { StyledLink } from "../components/StyledLink";

const InfoHeader = styled.h3`
  text-align: left;
`;

const StyledRouterLink = styled(Link)`
  text-align: left;
  color: #336699;
  text-decoration: none;
  margin-left: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.p`
  ${props => (props.bold ? "font-weight: 600;" : "")};
  font-size: 16px;
`;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };
  }

  render() {
    const props = this.props;
    const { t, loginValues } = this.props;

    const loginBoxMargin =
      props.underMaintenance || props.sessionLogOut
        ? [40, 0, 0, 0]
        : [75, 0, 0, 0];

    const linkIsShown = key => {
      return (
        this.props.config &&
        this.props.config.loginLinks &&
        this.props.config.loginLinks.includes(key)
      );
    };

    const onlyForgottenPasswordIsShown = () => {
      return (
        this.props.config &&
        this.props.config.loginLinks &&
        this.props.config.loginLinks.length === 1 &&
        this.props.config.loginLinks[0] === "forgottenPassword"
      );
    };

    return (
      <FlexContainer maxWidth={767}>
        <form>
          <FlexContainer
            greyBackground
            margin={[24, 40, 0, 40]}
            hidden={!props.underMaintenance}
          >
            <InfoHeader>
              {props.underMaintenance != null
                ? props.underMaintenance.header
                : ""}
            </InfoHeader>
            {props.underMaintenance != null ? props.underMaintenance.body : ""}
          </FlexContainer>

          {props.sessionLogOut ? (
            <FlexContainer vAlignCenter margin={[35, 40, 0, 40]}>
              <ErrorText>{t("logged_out_due_to_inactivity")}</ErrorText>
            </FlexContainer>
          ) : undefined}

          <FlexContainer
            margin={loginBoxMargin}
            padding={[40, 40, 40, 40]}
            blueBackground
            vAlignCenter
            column
          >
            <PageHeading margin={[0, 0, 40, 0]} uppercase color="white">
              {t("login")}
            </PageHeading>
            <FlexContainer
              hidden={!props.loginError.length > 0}
              margin={[0, 0, 5, 40]}
              width={"100%"}
              maxWidth={500}
            >
              <ErrorText preWrap>{props.loginError}</ErrorText>
            </FlexContainer>
            <FlexContainer vAlignCenter>
              <Field
                name="username"
                component={Input}
                fontSize="16px"
                greyPlaceholder
                maxWidth={460}
                margin={[0, 0, 16, 0]}
                autoComplete="on"
                placeholder={t("username")}
                isHidden={props.loginSuccessPhase1}
              />

              <Field
                name="password"
                fontSize="16px"
                component={Input}
                greyPlaceholder
                maxWidth={460}
                margin={[0, 0, 16, 0]}
                placeholder={t("password")}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 13: //Enter
                      props.onClickAction(loginValues);
                  }
                }}
                type="password"
                isHidden={props.loginSuccessPhase1}
              />

              <Field
                name="newPassword1"
                fontSize="16px"
                component={Input}
                greyPlaceholder
                maxWidth={460}
                placeholder={t("new_password")}
                type="text"
                isHidden={props.changePasswordRequired === false}
              />

              <Field
                name="newPassword2"
                fontSize="16px"
                component={Input}
                greyPlaceholder
                maxWidth={460}
                placeholder={t("repate_new_password")}
                type="text"
                isHidden={props.changePasswordRequired === false}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 13: //Enter
                      props.onClickAction(loginValues);
                  }
                }}
              />

              <Field
                name="mfacode"
                fontSize="16px"
                component={Input}
                greyPlaceholder
                maxWidth={460}
                placeholder={t("mfa_code")}
                type="text"
                isHidden={props.additionalSteps === false}
                onKeyDown={e => {
                  switch (e.keyCode) {
                    case 13: //Enter
                      props.onClickAction(loginValues);
                  }
                }}
              />

              <FlexContainer row center margin={[40, 0, 0, 0]}>
                <Button.Standard
                  onClick={() => props.onClickAction(loginValues)}
                >
                  {t("login")}
                </Button.Standard>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>

          <Modal
            isOpen={this.props.showHelpModal}
            onClose={this.props.onCloseHelpModal}
            header={t("need_help")}
          >
            <FlexContainer>
              <Text>{t("login_problems")}</Text>
              <Text>
                {t("contact_before_close")} <br />
                {t("phone")}: + 47 22 34 19 45<br />
                {t("email")}:{" "}
                <StyledLink href={"mailto:ma@oslobors.no"}>
                  ma@oslobors.no
                </StyledLink>
              </Text>
              <Text>
                {t("publication_service_questions")}
                <br />
                {t("phone")}: +47 22 34 18 16<br />
                {t("email")}:{" "}
                <StyledLink href={"mailto:corporate.services@oslobors.no"}>
                  corporate.services@oslobors.no
                </StyledLink>
              </Text>
            </FlexContainer>
          </Modal>

          <FlexContainer
            row
            margin={[24, 0, 0, 0]}
            spaceBetween
            flexEnd={onlyForgottenPasswordIsShown()}
          >
            {linkIsShown("help") ? (
              <ActionText
                onClick={() => this.props.onHelp()}
                margin={[0, 40, 0, 0]}
              >
                {t("need_help")}
              </ActionText>
            ) :
              undefined
            }
            {linkIsShown("privacyPolicy") ? (
              <StyledLink
                href={
                  i18n.language === "en"
                    ? this.props.config && this.props.config.privacyPolicyLinkEn
                      ? this.props.config.privacyPolicyLinkEn
                      : "https://www.euronext.com/en/privacy-statement"
                    : this.props.config && this.props.config.privacyPolicyLinkNo
                      ? this.props.config.privacyPolicyLinkNo
                      : "https://www.euronext.com/en/privacy-statement"
                }
                target="_blank"
              >
                {t("oslo_bors_privacy")}
              </StyledLink>
            ) : undefined}
            <StyledRouterLink to="/forgotPassword">
              {t("forgot_password_q")}
            </StyledRouterLink>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { form, config } = state;
  return {
    loginValues: form.login != null ? form.login.values || {} : {},
    config: config.config
  };
}

Login = connect(mapStateToProps)(Login);
Login = reduxForm({
  form: "login"
})(Login);

export default Login;
