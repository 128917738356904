import React from "react";
import FocusElement from "./FocusElement";
import styled from "styled-components";

const Text = styled(FocusElement)`
  color: #336699;
  cursor: pointer;
  white-space: nowrap;
`;

export const ActionText = props => (
  <Text {...props}>
    <span>{props.children}</span>
  </Text>
);
