import SearchableSelect from "../components/SearchableSelect";
import React from "react";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import DayPicker from "../components/DayPicker";
import { reduxForm } from "redux-form";
import CheckBox from "../components/CheckBox";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import { allowedToEdit, InputBox, LabelWithHelp } from "./InsiderList";
import Label from "../components/Label";
import { NotificationManager } from "react-notifications";
import { WarningText } from "../components/FileUploader";

export const RelatedInsidersBox = (props) => (
  <FlexContainer
    pre
    border
    scrollbarsOnOverflow
    whiteBackground
    height={"125px"}
    margin={[0, 0, 16, 0]}
  >
    {props.children}
  </FlexContainer>
);

export class PrimaryPerson extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <FlexContainer>
        <FlexContainer row>
          <FlexContainer column margin={[0, 40, 0, 0]}>
            {this.props.state.showPN && (
              <InputBox
                border
                label={
                  <LabelWithHelp
                    label={t("id_number") + ":*"}
                    helpFileName={"insider_id_number"}
                  />
                }
                name="idNumber"
                component={Input}
              />
            )}
            {this.props.state.showPN && (
              <InputBox
                autoComplete="new-password"
                noLabel
                height="20px"
                label={t("valid_id_number")}
                name="norwegian"
                component={CheckBox}
                checked={this.props.initialValues.norwegian}
                fontSize={14}
              />
            )}
            <InputBox
              autoComplete="new-password"
              border
              label={t("surname") + ":*"}
              name="surname"
              component={Input}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("middle_name")}
              name="middleName"
              component={Input}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("first_name") + ":*"}
              name="firstName"
              component={Input}
            />
            <InputBox
              autoComplete="new-password"
              height="131px"
              border
              label={t("address")}
              name="address"
              component={Textarea}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("post_code") + ":*"}
              name="postalCode"
              component={Input}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("postal_place") + ":*"}
              name="postalPlace"
              component={Input}
            />
            <InputBox
              label={t("country") + ":*"}
              name="countryOption"
              t={t}
              component={SearchableSelect}
              options={this.props.countryOptions}
              searchable={true}
              placeholder={this.props.t("select")}
            />
            {!this.props.state.newModalOpened && (
              <FlexContainer>
                <Label margin={[0, 0, 8, 0]}>{t("related_insiders")}:</Label>
                <RelatedInsidersBox>
                  {this.props.initialValues.relatedInsiders != null &&
                    this.props.initialValues.relatedInsiders.map(
                      (insider) =>
                        (insider.firstName
                          ? insider.firstName +
                            " " +
                            insider.middleName +
                            " " +
                            insider.surname
                          : insider.companyName) + "\n"
                    )}
                </RelatedInsidersBox>
              </FlexContainer>
            )}
          </FlexContainer>
          <FlexContainer column>
            {!this.props.state.hideValidDates ? (
              <React.Fragment>
                {this.props.state.newModalOpened ? (
                  <InputBox
                    border
                    label={t("valid_from") + ":*"}
                    name="validFrom"
                    component={DayPicker}
                    numFutureYears={5}
                    numPreviousYears={2}
                    disableDaysBefore={
                      new Date(this.props.state.insiderStartDate)
                    }
                  />
                ) : (
                  <InputBox
                    border
                    label={t("valid_from") + ":*"}
                    name="validFrom"
                    disabled
                    component={Input}
                  />
                )}
                <InputBox
                  border
                  label={t("valid_to")}
                  name="validTo"
                  component={DayPicker}
                  numFutureYears={5}
                  numPreviousYears={2}
                  keepValueOnBlurOrEnter
                />
              </React.Fragment>
            ) : undefined}
            <InputBox
              border
              autoComplete="new-password"
              label={t("email") + ":*"}
              name="email"
              component={Input}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("phone")}
              name="phone1"
              component={Input}
            />
            <InputBox
              border
              autoComplete="new-password"
              label={t("phone") + "2"}
              name="phone2"
              component={Input}
            />
            <Label italic margin={[0, 0, 5, 0]}>
              {t("employment_commision_mandatory")}
            </Label>
            <InputBox
              border
              label={t("employment")}
              name="position"
              component={Input}
            />
            <InputBox
              border
              label={t("commission")}
              name="commission"
              component={Input}
            />
            <InputBox
              label={t("language") + ":*"}
              name="languageOption"
              t={t}
              component={SearchableSelect}
              options={this.props.languageOptions}
              searchable={true}
              placeholder={this.props.t("select")}
            />
            <InputBox
              autoComplete="new-password"
              height="131px"
              border
              label={t("comment")}
              name="comment"
              component={Textarea}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer row vAlignCenter flexEnd>
          <Button.Standard
            onClick={() => {
              if (allowedToEdit(this.props.initialValues)) {
                NotificationManager.error(
                  <WarningText>{t("save_inactive_insider")}</WarningText>,
                  "",
                  10000
                );
              } else {
                return this.props.handleSubmit();
              }
            }}
            margin={[0, 24, 0, 0]}
            inactive={allowedToEdit(this.props.initialValues)}
          >
            {t("save")}
          </Button.Standard>
          <Button.Text onClick={this.props.onCancel}>{t("cancel")}</Button.Text>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

PrimaryPerson = reduxForm({
  form: "primaryPerson",
})(PrimaryPerson);

export class PrimaryFirm extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <FlexContainer>
        <FlexContainer row>
          <FlexContainer column margin={[0, 40, 0, 0]}>
            <InputBox
              border
              label={
                <LabelWithHelp
                  label={t("bus_number") + ":*"}
                  helpFileName={"insider_org_number"}
                />
              }
              name="idNumber"
              component={Input}
            />
            <InputBox
              noLabel
              height="20px"
              label={t("valid_bus_number")}
              name="norwegian"
              component={CheckBox}
              checked={this.props.initialValues.norwegian}
              fontSize={14}
            />
            <InputBox
              border
              label={t("firm") + ":*"}
              name="companyName"
              component={Input}
            />
            <InputBox
              height="131px"
              border
              label={t("address")}
              name="address"
              component={Textarea}
            />
            <InputBox
              border
              label={t("post_code") + ":*"}
              name="postalCode"
              component={Input}
            />
            <InputBox
              border
              label={t("postal_place") + ":*"}
              name="postalPlace"
              component={Input}
            />
            <InputBox
              label={t("country") + ":*"}
              name="countryOption"
              t={t}
              component={SearchableSelect}
              options={this.props.countryOptions}
              searchable={true}
              placeholder={this.props.t("select")}
            />
            {!this.props.state.newModalOpened ? (
              <FlexContainer>
                <Label margin={[0, 0, 8, 0]}>{t("related_insiders")}:</Label>
                <RelatedInsidersBox>
                  {this.props.initialValues.relatedInsiders != null
                    ? this.props.initialValues.relatedInsiders.map(
                        (insider) =>
                          (insider.person
                            ? insider.firstName +
                              " " +
                              insider.middleName +
                              " " +
                              insider.surname
                            : insider.companyName) + "\n"
                      )
                    : ""}
                </RelatedInsidersBox>
              </FlexContainer>
            ) : undefined}
          </FlexContainer>
          <FlexContainer column>
            {!this.props.state.hideValidDates ? (
              <React.Fragment>
                {this.props.state.newModalOpened ? (
                  <InputBox
                    border
                    label={t("valid_from") + ":*"}
                    name="validFrom"
                    component={DayPicker}
                    numFutureYears={5}
                    numPreviousYears={2}
                    disableDaysBefore={
                      new Date(this.props.state.insiderStartDate)
                    }
                  />
                ) : (
                  <InputBox
                    border
                    label={t("valid_from") + ":*"}
                    name="validFrom"
                    disabled
                    component={Input}
                  />
                )}
                <InputBox
                  border
                  label={t("valid_to")}
                  name="validTo"
                  component={DayPicker}
                  numFutureYears={5}
                  numPreviousYears={2}
                  keepValueOnBlurOrEnter
                />
              </React.Fragment>
            ) : undefined}
            <InputBox
              border
              label={t("email") + ":*"}
              name="email"
              component={Input}
            />
            <InputBox
              border
              label={t("phone")}
              name="phone1"
              component={Input}
            />
            <InputBox
              border
              label={t("phone") + "2"}
              name="phone2"
              component={Input}
            />
            <Label italic margin={[0, 0, 5, 0]}>
              {t("employment_commision_mandatory")}
            </Label>
            <InputBox
              border
              label={t("employment")}
              name="position"
              component={Input}
            />
            <InputBox
              border
              label={t("commission")}
              name="commission"
              component={Input}
            />
            <InputBox
              label={t("language") + ":*"}
              name="languageOption"
              t={t}
              component={SearchableSelect}
              searchable={true}
              options={this.props.languageOptions}
              placeholder={this.props.t("select")}
            />
            <InputBox
              height="131px"
              border
              label={t("comment")}
              name="comment"
              component={Textarea}
            />
          </FlexContainer>
        </FlexContainer>
        <FlexContainer row vAlignCenter flexEnd>
          <Button.Standard
            onClick={() => {
              if (allowedToEdit(this.props.initialValues)) {
                NotificationManager.error(
                  <WarningText>{t("save_inactive_insider")}</WarningText>,
                  "",
                  10000
                );
              } else {
                return this.props.handleSubmit();
              }
            }}
            margin={[0, 24, 0, 0]}
            inactive={allowedToEdit(this.props.initialValues)}
          >
            {t("save")}
          </Button.Standard>
          <Button.Text onClick={this.props.onCancel}>{t("cancel")}</Button.Text>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

PrimaryFirm = reduxForm({
  form: "primaryFirm",
})(PrimaryFirm);
