import React from "react";
import styled from "styled-components";

const StyledTextarea = styled.textarea`
  box-sizing: border-box;
  border: 0;
  height: 100px;
  text-overflow: ellipsis;
  outline: none;
  border-radius: 0;
  padding-left: 16px;
  ${props => (props.fontSize ? "font-size: " + props.fontSize + ";" : "")}
  ${props => (props.openSans ? "font-family: 'Open sans',sans-serif;" : "")}
  ${props => (props.minHeight ? "min-height:" + props.minHeight + ";" : "min-height:10%;")};

    &::placeholder {
    color: black;
    ${props => (props.greyPlaceholder ? "color: rgba(0, 0, 0, 0.6);" : "")}
  }
  &:-ms-input-placeholder {
    color: black;
    ${props => (props.greyPlaceholder ? "color: rgba(0, 0, 0, 0.6);" : "")}
  }

  ${props => (props.border ? "border: solid 1px #d0d0d0;" : "")}
  ${props => (props.borderWithColor ? `border: solid 1px ${props.borderWithColor};` : "")}
  ${props => (props.height ? "height: " + props.height + ";" : "")}
  ${props => (props.width ? "width: " + props.width + ";" : "width; 100%;")}
    ${props =>
      props.maxWidth ? "max-width: " + props.maxWidth + "px;width:100%;" : ""}
    ${props =>
      props.margin
        ? "margin: " +
          props.margin.reduce((acc, val) => (acc += val + "px "), "") +
          ";"
        : ""}
    ${props => (props.isHidden ? "display:none;" : "")}
    ${props => (props.noResize ? "resize: none;" : "")}
    ${props => (props.overflowHidden ? "overflow: hidden;" : "")}
  ${props => (props.textAreaCopy ? "padding-right: 22px; padding-top: 5px;" : "")}
`;

const Textarea = props => {
  return (
      <StyledTextarea {...props.input} {...props} />);
}

export default Textarea;
