import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import DayPicker from "../components/DayPicker";
import moment from "moment";

import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import Input from "../components/Input";
import { getOBNTDateTime, OBNTDateFormat, getLanguage } from "../util/common";
import i18n from "../i18n";
import CheckBox from "../components/CheckBox";
import {
  CURRENT,
  FUTURE,
  isSameOrBefore,
  PAST
} from "./FinanskalenderContainer";
import { WhiteDropDown } from "../components/WhiteDropDown";
import Period from "./Period";
import { ErrorText } from "../components/ErrorText";
import SearchableSelectNew from "../components/SearchableSelectNew";

const Link = styled.a`
  text-decoration: none;
  color: #336699;
  &:hover {
    text-decoration: underline;
  }
`;

export const InfoText = styled.p`
  white-space: pre-line;
  ${props =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""} ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;
export const InfoTextSpan = styled.span`
  white-space: pre-line;
  ${props =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""} ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

export const ContentHeading = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

export const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 16px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  ${props =>
    props.maxWidth ? "max-width: " + props.maxWidth + "px; width: auto;" : ""}
  ${props => (props.left ? "margin-right: 8px;" : "")}
    ${props => (props.right ? "margin-left: 8px;" : "")}
`;
export const InputHeading = styled.span`
  font-size: 14px;
  margin-bottom: 8px;
  ${props => (props.bold ? "font-weight: 600;" : "")};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  background-color: #336699;
  width: 100%;
  height: 38px;
  color: #ffffff;
  padding-left: 16px;
  box-sizing: border-box;
  padding: 0 0 0 16px;
`;

const Message = styled.textarea`
  box-sizing: border-box;
  padding: 10px 10px 10px 10px;
  height: 224px;
  resize: none;
  width: 100%;
  border: 8px;
  font-family: inherit;
  font-size: inherit;
  &:focus::-webkit-input-placeholder {
    color: transparent;
  }
  &:focus:-moz-placeholder {
    color: transparent;
  } /* FF 4-18 */
  &:focus::-moz-placeholder {
    color: transparent;
  } /* FF 19+ */
  &:focus:-ms-input-placeholder {
    color: transparent;
  } /* IE 10+ */
`;

const MessageBoxBlock = styled.div`
  width: 100%;
  ${props => (props.left ? "margin-right: 9.5px" : "")} ${props =>
    props.right ? "margin-left: 9.5px" : ""};
`;

const ErrorBox = styled.span`
  display: flex;
  box-sizing: border-box;
  flex-direction: ${props => (props.row ? "row;" : "column;")};
  flex-direction: ${props => (props.column ? "column;" : "")};
  margin: auto;
`;

class FinanceMessageBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      messageLength: 0
    };
  }

  render() {
    const {
      name,
      header,
      info,
      messagePlaceholder,
      maxTextLength,
      maxColumns,
      maxRows,
      disabled,
      input
    } = this.props;

    const flexProps = {
      padding: [0, 0, 0, 0],
      backgroundColor: "rgba(178, 207, 174, 0.2)"
    };

    const handleChange = event => {
      this.props.handleChange(event.target.value);
    };

    return (
      <FlexContainer {...flexProps}>
        <Header>{header}</Header>
        <FlexContainer padding={[14, 8, 0, 8]} column>
          <Message
            placeholder={messagePlaceholder}
            maxLength={maxTextLength}
            {...name}
            {...input}
            cols={maxColumns}
            rows={maxRows}
            disabled={disabled}
            onChange={handleChange}
            value={
              this.props.initialValues && this.props.initialValues.value
                ? this.props.initialValues.value
                : undefined
            }
          />
        </FlexContainer>
        {info ? (
          <FlexContainer row flexEnd padding={[8, 8, 8, 0]}>
            {info}
          </FlexContainer>
        ) : undefined}
      </FlexContainer>
    );
  }
}

const LastModifiedInfo = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1.43;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 195px;
  width: 100%;
`;

class EditForm extends React.Component {
  render() {
    const { t } = this.props;

    const addOptionalEvent = (period, id) => {
      this.props.addOptionalEvent(period, id);
    };

    const removeOptionalEvent = (period, index) => {
      this.props.removeOptionalEvent(period, index);
    };

    const setDate = (index, period, date) => {
      this.props.updateDateForRequiredEvent(index, period, date);
    };

    const setDateForOptionalEvent = (index, period, date) => {
      this.props.updateDateForOptionalEvent(index, period, date);
    };

    const updateOptionalEvent = (eventId, period, index) => {
      this.props.updateOptionalEvent(eventId, period, index);
    };

    const inputRow = (name, period, date, index) => {
      const formattedDate = moment(date, "YYYY-MM-DD", true).isValid()
        ? moment(date, "YYYY-MM-DD").format(OBNTDateFormat)
        : date;
      return (
        <InputRow key={index}>
          <InputBox left>
            <InputHeading>{t("event")}</InputHeading>
            <Field
              name={name + "_text"}
              component={Input}
              readOnly
              props={{ value: name }}
            />
          </InputBox>
          <InputBox right>
            <InputHeading>{t("event_publication")}</InputHeading>
            {!date ||
            !moment(date, "YYYY-MM-DD", true).isValid() ||
            isSameOrBefore(date) ||
            this.props.isSuperUser ? (
              <Field
                name={"date"}
                placeholder={""}
                component={DayPicker}
                dateFormat={OBNTDateFormat}
                input={{
                  value: formattedDate ? formattedDate : undefined,
                  onChange: date => {
                    if (formattedDate || date) {
                      setDate(index, period, date);
                    }
                  }
                }}
                disableDaysBefore={new Date()}
                numFutureYears={2}
                numPreviousYears={0}
              />
            ) : (
              <Field
                name={"date"}
                component={Input}
                readOnly
                props={{ value: formattedDate }}
                background={"#F2F2F2"}
              />
            )}
          </InputBox>
        </InputRow>
      );
    };

    const optionalEvents = this.props.optionalEvents
      ? this.props.optionalEvents
          .filter(event => event.eventType !== 2)
          .map(event => {
            if (i18n.language === "nb") {
              return {
                label: event.name_NO,
                value: event.id,
                type: event.type
              };
            } else {
              return {
                label: event.name_EN,
                value: event.id,
                type: event.type
              };
            }
          })
      : [];

    let pastIndex = 0;
    const pastRequiredEvents =
      this.props.state.financialCalendar &&
      this.props.state.financialCalendar.pastPeriod &&
      this.props.state.financialCalendar.pastPeriod.requiredEvents
        ? this.props.state.financialCalendar.pastPeriod.requiredEvents.map(
            event => {
              if (i18n.language === "nb") {
                return inputRow(
                  event.eventType.name_NO,
                  PAST,
                  event.eventDate,
                  pastIndex++
                );
              } else {
                return inputRow(
                  event.eventType.name_EN,
                  PAST,
                  event.eventDate,
                  pastIndex++
                );
              }
            }
          )
        : undefined;

    let currentIndex = 0;
    const currentRequiredEvents =
      this.props.state.financialCalendar &&
      this.props.state.financialCalendar.currentPeriod &&
      this.props.state.financialCalendar.currentPeriod.requiredEvents
        ? this.props.state.financialCalendar.currentPeriod.requiredEvents.map(
            event => {
              if (i18n.language === "nb") {
                return inputRow(
                  event.eventType.name_NO,
                  CURRENT,
                  event.eventDate,
                  currentIndex++
                );
              } else {
                return inputRow(
                  event.eventType.name_EN,
                  CURRENT,
                  event.eventDate,
                  currentIndex++
                );
              }
            }
          )
        : undefined;

    let futureIndex = 0;

    const futureRequiredEvents =
      this.props.state.financialCalendar &&
      this.props.state.financialCalendar.futurePeriod &&
      this.props.state.financialCalendar.futurePeriod.requiredEvents
        ? this.props.state.financialCalendar.futurePeriod.requiredEvents.map(
            event => {
              if (i18n.language === "nb") {
                return inputRow(
                  event.eventType.name_NO,
                  FUTURE,
                  event.eventDate,
                  futureIndex++
                );
              } else {
                return inputRow(
                  event.eventType.name_EN,
                  FUTURE,
                  event.eventDate,
                  futureIndex++
                );
              }
            }
          )
        : undefined;

    return (
      <form>
        <FlexContainer width={"1160px"} fontSize="14px">
          <PageHeading>{t("heading")}</PageHeading>
          <InputBox>
            <InputHeading bold>{t("issuer")}</InputHeading>
            <FlexContainer maxWidth={373}>
              <Field
                name="issuer"
                component={SearchableSelectNew}
                options={this.props.companyOptions}
                t={t}
                searchable={true}
                placeholder={t("select")}
                onChange={company => {
                  this.props.fetchFinancialCalendar(company.value);
                }}
                clearable={false}
              />
            </FlexContainer>
          </InputBox>
          {this.props.state.financialCalendar ? (
            <FlexContainer
              hidden={this.props.state.financialCalendar === undefined}
            >
              <InfoText margin={[16, 0, 0, 0]}>
                {t("first_part_financial_help")}
              </InfoText>
              <InfoText margin={[16, 0, 0, 0]}>
                {t("heading_info_text")}
              </InfoText>
              <WhiteDropDown
                onClick={() => {
                  this.props.openClosePeriod(PAST);
                }}
                isOpen={this.props.state.pastIsOpen}
                key="pastCalendar"
                whiteBackground
                header={t("fiscal_year", {
                  year: this.props.state.financialCalendar
                    ? this.props.state.financialCalendar.pastPeriod.validForYear
                    : ""
                })}
                greenBackground
                containerNoMarginTop
                noBodyPadding
              >
                <Period
                  t={t}
                  requiredEvents={pastRequiredEvents}
                  addOptionalEvent={this.props.addOptionalEvent}
                  removeOptionalEvent={this.props.removeOptionalEvent}
                  period={PAST}
                  existingEvents={
                    this.props.state.financialCalendar.pastPeriod
                      ? this.props.state.financialCalendar.pastPeriod
                          .optionalEvents
                      : undefined
                  }
                  onRemoveUserRow={removeOptionalEvent}
                  onAddUserRow={addOptionalEvent}
                  updateEvent={updateOptionalEvent}
                  updateDateForOptionalEvent={setDateForOptionalEvent}
                  optionalEvents={optionalEvents}
                  isSuperUser={this.props.isSuperUser}
                />
              </WhiteDropDown>
              <WhiteDropDown
                onClick={() => this.props.openClosePeriod(CURRENT)}
                isOpen={this.props.state.currentIsOpen}
                key="currentCalendar"
                whiteBackground
                header={t("fiscal_year", {
                  year: this.props.state.financialCalendar
                    ? this.props.state.financialCalendar.currentPeriod
                        .validForYear
                    : ""
                })}
                greenBackground
                containerNoMarginTop
                noBodyPadding
              >
                <Period
                  t={t}
                  requiredEvents={currentRequiredEvents}
                  addOptionalEvent={this.props.addOptionalEvent}
                  removeOptionalEvent={this.props.removeOptionalEvent}
                  period={CURRENT}
                  existingEvents={
                    this.props.state.financialCalendar.currentPeriod
                      .optionalEvents
                  }
                  onRemoveUserRow={removeOptionalEvent}
                  onAddUserRow={addOptionalEvent}
                  updateEvent={updateOptionalEvent}
                  updateDateForOptionalEvent={setDateForOptionalEvent}
                  optionalEvents={optionalEvents}
                  isSuperUser={this.props.isSuperUser}
                />
              </WhiteDropDown>
              <WhiteDropDown
                onClick={() => this.props.openClosePeriod(FUTURE)}
                isOpen={this.props.state.futureIsOpen}
                key="futureCalendar"
                whiteBackground
                header={t("fiscal_year", {
                  year: this.props.state.financialCalendar
                    ? this.props.state.financialCalendar.futurePeriod
                        .validForYear
                    : ""
                })}
                greenBackground
                containerNoMarginTop
                noBodyPadding
              >
                <Period
                  t={t}
                  requiredEvents={futureRequiredEvents}
                  existingEvents={
                    this.props.state.financialCalendar.futurePeriod
                      .optionalEvents
                  }
                  period={FUTURE}
                  onRemoveUserRow={removeOptionalEvent}
                  onAddUserRow={addOptionalEvent}
                  updateEvent={updateOptionalEvent}
                  updateDateForOptionalEvent={setDateForOptionalEvent}
                  optionalEvents={optionalEvents}
                  isSuperUser={this.props.isSuperUser}
                />
              </WhiteDropDown>
              <FlexContainer margin={[10, 0, 12, 0]}>
                <InfoTextSpan padding={[16, 0, 0, 0]}>
                  {t("message_info_text1")}
                  <Link
                    href={
                      (this.props.config &&
                      this.props.config.newsWebWithLanguageIdLink
                        ? this.props.config.newsWebWithLanguageIdLink
                        : "https://newsweb.oslobors.no/search?languageId=") +
                      getLanguage().toLowerCase()
                    }
                    target="_blank"
                  >
                    NewsWeb.
                  </Link>
                </InfoTextSpan>
                <InfoTextSpan padding={[0, 0, 16, 0]}>
                  {t("message_info_text2")}
                  <Link
                    href={
                      i18n.language === "nb"
                        ? "https://live.euronext.com/nb/markets/oslo/financial-events"
                        : "https://live.euronext.com/en/markets/oslo/financial-events"
                    }
                    target="_blank"
                  >
                    {this.props.config && this.props.config.homepageName
                      ? this.props.config.homepageName
                      : "Euronext"}
                  </Link>
                  <InfoTextSpan>{t("message_info_text3")}</InfoTextSpan>
                </InfoTextSpan>
              </FlexContainer>

              <FlexContainer spaceBetween column margin={[0, 0, 12, 0]}>
                <Field
                  label={t("norwegian_message")}
                  name="publishNewsNO"
                  component={CheckBox}
                  margin={[0, 40, 0, 0]}
                  input={{
                    value: this.props.state.financialCalendar.publishNewsNO,
                    onChange: this.props.norwegianMessageEnabled
                  }}
                />
                <Field
                  label={t("english_message")}
                  name="publishNewsEN"
                  component={CheckBox}
                  margin={[0, 40, 0, 0]}
                  input={{
                    value: this.props.state.financialCalendar.publishNewsEN,
                    onChange: this.props.englishMessageEnabled
                  }}
                />
              </FlexContainer>

              <FlexContainer row margin={[0, 0, 24, 0]}>
                <MessageBoxBlock left>
                  <Field
                    name={"message_nb"}
                    component={FinanceMessageBox}
                    header={t("additional_info_no")}
                    messagePlaceholder={t("stock_exchange_announcement")}
                    info={t("char_left") + (1500 - this.props.noCharsLength)}
                    maxTextLength={"1500"}
                    disabled={!this.props.state.financialCalendar.publishNewsNO}
                    handleChange={this.props.handleNorwegianMessageChange}
                    initialValues={{
                      value: this.props.state.financialCalendar.commentary_NO
                    }}
                  />
                </MessageBoxBlock>
                <MessageBoxBlock right>
                  <Field
                    name={"message_en"}
                    component={FinanceMessageBox}
                    header={t("additional_info_en")}
                    messagePlaceholder={t("stock_exchange_announcement")}
                    info={t("char_left") + (1500 - this.props.engCharsLength)}
                    maxTextLength={"1500"}
                    disabled={!this.props.state.financialCalendar.publishNewsEN}
                    handleChange={this.props.handleEnglishMessageChange}
                    initialValues={{
                      value: this.props.state.financialCalendar.commentary_EN
                    }}
                  />
                </MessageBoxBlock>
              </FlexContainer>

              <FlexContainer row spaceBetween margin={[0, 0, 24, 0]}>
                <LastModifiedInfo>
                  <span>
                    {t("last_modified", {
                      userName: this.props.state.financialCalendar.lastSavedBy,
                      date: getOBNTDateTime(
                        this.props.state.financialCalendar.lastSavedTime
                      )
                    })}
                  </span>
                  <span>
                    {t("published_by", {
                      userName: this.props.state.financialCalendar.publishedBy,
                      date: getOBNTDateTime(
                        this.props.state.financialCalendar.publishedTime
                      )
                    })}
                  </span>
                </LastModifiedInfo>
                <ErrorBox>
                  {this.props.state.errorMissing ? (
                    <ErrorText preWrap>
                      {t(this.props.state.errorMissing)}
                    </ErrorText>
                  ) : undefined}
                  {this.props.state.errorInvalid ? (
                    <ErrorText preWrap>
                      {t(this.props.state.errorInvalid)}
                    </ErrorText>
                  ) : undefined}
                  {this.props.state.errorNoMessage ? (
                    <ErrorText preWrap>
                      {t(this.props.state.errorNoMessage)}
                    </ErrorText>
                  ) : undefined}
                </ErrorBox>
                <ButtonGroup>
                  <Button.Standard onClick={this.props.onSubmit}>
                    {t("next_step")}
                  </Button.Standard>
                  <Button.Text onClick={this.props.onCancel}>
                    {t("cancel")}
                  </Button.Text>
                </ButtonGroup>
              </FlexContainer>
            </FlexContainer>
          ) : null}
        </FlexContainer>
      </form>
    );
  }
}

EditForm = reduxForm({
  form: "finanskalender",
  destroyOnUnmount: false
})(EditForm);

function Edit(props) {
  const { t } = props;

  return (
    <EditForm
      onSubmit={props.onSubmit}
      t={t}
      {...props}
      onCancel={props.onCancel}
    />
  );
}

Edit.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Edit;
