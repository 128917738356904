import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Field, reset, SubmissionError } from "redux-form";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import Table, { TD, TR } from "../components/Table";
import NewInsiderModal from "./NewInsiderModal";
import { Modal } from "../components/Modal";
import Label from "../components/Label";
import {
  addInsider,
  deactivateInsider,
  fetchCountriesList,
  fetchInsiderList,
  fetchPrimaryInsiderList,
  getValidFrom,
  resetInsiderData,
  updateInsider,
} from "./InsiderActions";
import { PrimaryFirm, PrimaryPerson } from "./Primary";
import { RelatedFirm, RelatedPerson } from "./Related";
import { fetchCompanyList } from "../companyInformation/CompanyInformationActions";
import Button from "../components/Button";
import Loader, { OverlayLoader } from "../components/Loader";
import moment from "moment/moment";
import { ConfirmModal } from "../components/ConfirmModal";
import { ErrorText } from "../components/ErrorText";
import { fetchCompanyPermission } from "../actions/CommonActions";
import { getOBNTDate } from "../util/common";
import { NotificationManager } from "react-notifications";
import i18n from "../i18n";
import { HelpIcon } from "../components/HelpIcon";
import { validate } from "./InsiderValidator";
import { handleServerErrors } from "./HandleServerErrors";
import SearchableSelectNew from "../components/SearchableSelectNew";
import { InsiderStateRadioButtons } from "./InsiderStateRadioButtons";
import styled from "styled-components";

const IssuerBox = (props) => (
  <FlexContainer column>
    <Label htmlFor="issuer" margin={[0, 0, 3, 0]}>
      {props.t("issuer")}
    </Label>
    <FlexContainer row>
      <SearchableSelectNew
        id="issuer"
        input={{
          name: "issuer",
          onChange: props.onSelect,
          value: props.value,
        }}
        name={"issuer"}
        options={props.options}
        t={props.t}
        searchable={true}
        placeholder={props.t("select")}
        selectIfOnlyOneOption={true}
      />
    </FlexContainer>
  </FlexContainer>
);

class InputField extends React.Component {
  render() {
    const props = this.props;
    const { error } = props.meta;
    const { PropsComponent } = props;
    const { height } = props;

    return (
      <FlexContainer column margin={[0, 0, 16, 0]} height={height}>
        {props.noLabel ? (
          ""
        ) : (
          <Label margin={[0, 0, 3, 0]} htmlFor={props.name}>
            {props.label}
          </Label>
        )}
        {error ? <ErrorText>{error}</ErrorText> : undefined}
        <PropsComponent {...props} />
      </FlexContainer>
    );
  }
}

export const InputBox = (props) => {
  return (
    <Field {...props} component={InputField} PropsComponent={props.component} />
  );
};

export const LabelWithHelp = (props) => (
  <FlexContainer row>
    {props.label}
    <HelpIcon
      language={i18n.language}
      fileName={props.helpFileName}
      margin={[3, 0, -1, 3]}
    />
  </FlexContainer>
);

export const allowedToEdit = (initialValues) =>
  initialValues &&
  initialValues.validTo &&
  moment(initialValues.validTo).endOf("day").isBefore(moment().startOf("day"));

const LinkLookALike = styled.span`
  color: #336699;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

class InsiderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPrimaryFirm: false,
      showPrimaryPerson: false,
      showRelatedPerson: false,
      showRelatedFirm: false,
      showEditInsider: false,
      showNewEntry: false,
      showDeactivateModal: false,
      loader: false,
      issuerObj: { label: "", value: -1, id: -1 },
      selectedRow: {},
      initialValues: {},
      modalHeader: "",
      newModalOpened: false,
      isPrimary: false,
      showPN: false,
      saving: false,
      insiderList: [],
      sortByColumn: undefined,
      validFromAsc: false,
      validFromDesc: false,
      validToAsc: false,
      validToDesc: false,
      nameAsc: false,
      nameDesc: false,
      insiderStartDate: undefined,
      hideValidDates: false,
      insiderStateFilterValue: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchCompanyList()).catch(() => {});
    this.props.dispatch(fetchCountriesList()).catch(() => {});
  }

  render() {
    const { t, companyList } = this.props;
    const setLoaderFalse = () => {
      this.setState({ loader: false });
    };

    const onFetchInsiderPermissionsSuccess = (cid) => {
      this.props.insiderPermissions &&
        this.props.insiderPermissions.forEach((permission) => {
          if (
            permission.cid === cid &&
            permission.insiderPermissions.some(
              (value) => value === "insider.field.pn"
            )
          ) {
            this.setState({ showPN: true });
          }
        });
    };

    const onFetchInsidersSuccess = () => {
      let sortedInsiders = this.props.insiderList.sort(surnameSort);
      setInsiderListState(sortedInsiders, "name");
      setLoaderFalse();
    };

    const isDateInTheFuture = (date) => {
      const momentDate = moment(date, "YYYY-MM-DD").startOf("day");
      return moment().startOf("day").isBefore(momentDate);
    };

    const fetchInsiderLists = (issuerObj) => {
      if (issuerObj) {
        this.props
          .dispatch(fetchInsiderList(issuerObj.value))
          .then(onFetchInsidersSuccess)
          .catch(setLoaderFalse);
        this.props
          .dispatch(fetchPrimaryInsiderList(issuerObj.value))
          .catch(setLoaderFalse);
        this.props
          .dispatch(getValidFrom(issuerObj.value))
          .then((values) => {
            const date = values.data.entity.validFrom;
            this.setState({
              insiderStartDate: date,
              hideValidDates: isDateInTheFuture(date),
            });
          })
          .catch(() => {
            setLoaderFalse();
          });
        this.props
          .dispatch(fetchCompanyPermission(issuerObj.value))
          .then(() => onFetchInsiderPermissionsSuccess(issuerObj.value + ""))
          .catch(setLoaderFalse);
      } else {
        this.props.dispatch(resetInsiderData());
      }
      this.setState({
        issuerObj: issuerObj,
        loader: issuerObj !== null,
        showPN: false,
      });
    };

    const onEditSelectedRow = (row) => {
      editModal(row);
    };

    const thLabels = [
      t("id_number"),
      t("name"),
      t("position"),
      t("commission"),
      t("valid_from"),
      t("valid_to"),
      t("type"),
      t("modify"),
      t("deactivate"),
    ];
    const thLabelsNoPN = [
      t("name"),
      t("position"),
      t("commission"),
      t("valid_from"),
      t("valid_to"),
      t("type"),
      t("modify"),
      t("deactivate"),
    ];

    const surnameSort = (a, b) => {
      if (!a.surname && !b.surname) return 0;
      if (!a.surname) return 1;
      if (!b.surname) return -1;

      return a.surname
        .toLocaleLowerCase()
        .localeCompare(b.surname.toLocaleLowerCase(), "nb-NO");
    };

    const setInsiderListState = (insiderList, sortName) => {
      this.setState({
        insiderList: insiderList,
        sortByColumn: sortName,
        validFromAsc:
          sortName === "validFrom" &&
          (this.state.sortByColumn !== sortName || this.state.validFromDesc),
        validFromDesc: sortName === "validFrom" && this.state.validFromAsc,
        validToAsc:
          sortName === "validTo" &&
          (this.state.sortByColumn !== sortName || this.state.validToDesc),
        validToDesc: sortName === "validTo" && this.state.validToAsc,
        nameAsc:
          sortName === "name" &&
          (this.state.sortByColumn !== sortName || this.state.nameDesc),
        nameDesc: sortName === "name" && this.state.nameAsc,
      });
    };

    const sortInsidersByName = () => {
      let sortedInsiders;
      if (this.state.sortByColumn === "name") {
        sortedInsiders = this.state.insiderList.reverse();
      } else {
        sortedInsiders = this.state.insiderList.sort(surnameSort);
      }
      setInsiderListState(sortedInsiders, "name");
    };

    const dateIsBeforeDate = (a, b) => {
      const aIsBeforeB = a.isBefore(b);
      if (aIsBeforeB) {
        return 1;
      } else {
        return -1;
      }
    };

    const validFromSort = (a, b) => {
      if (!a.validFrom && !b.validFrom) return 0;
      if (!a.validFrom) return 1;
      if (!b.validFrom) return -1;
      if (a.validFrom === b.validFrom) return 0;

      const valueFromA = moment(a.validFrom, "YYYY-MM-DD");
      const valueFromB = moment(b.validFrom, "YYYY-MM-DD");

      return dateIsBeforeDate(valueFromA, valueFromB);
    };

    const validToSort = (a, b) => {
      if (!a.validTo && !b.validTo) return 0;
      if (!a.validTo) return 1;
      if (!b.validTo) return -1;
      if (a.validTo === b.validTo) return 0;

      const valueFromA = moment(a.validTo, "YYYY-MM-DD");
      const valueFromB = moment(b.validTo, "YYYY-MM-DD");

      return dateIsBeforeDate(valueFromA, valueFromB);
    };

    const sortInsidersByValidFrom = () => {
      let sortedInsiders = this.state.insiderList;
      if (this.state.sortByColumn === "validFrom") {
        sortedInsiders = sortedInsiders.reverse();
      } else {
        sortedInsiders = sortedInsiders.sort(validFromSort);
      }
      setInsiderListState(sortedInsiders, "validFrom");
    };

    const sortInsidersByValidTo = () => {
      let sortedInsiders = this.state.insiderList;
      if (this.state.sortByColumn === "validTo") {
        sortedInsiders = sortedInsiders.reverse();
      } else {
        sortedInsiders.sort(validToSort);
      }
      setInsiderListState(sortedInsiders, "validTo");
    };

    const nameSortProps = {
      onClick: sortInsidersByName,
      pointer: true,
      ascending: this.state.nameAsc,
      descending: this.state.nameDesc,
    };
    const validFromSortProps = {
      onClick: sortInsidersByValidFrom,
      pointer: true,
      ascending: this.state.validFromAsc,
      descending: this.state.validFromDesc,
      width: "105px",
      hidden: this.state.hideValidDates,
    };
    const validToSortProps = {
      onClick: sortInsidersByValidTo,
      pointer: true,
      ascending: this.state.validToAsc,
      descending: this.state.validToDesc,
      width: "105px",
      hidden: this.state.hideValidDates,
    };

    const headerProps = [
      ,
      nameSortProps,
      ,
      ,
      validFromSortProps,
      validToSortProps,
      ,
      ,
      { center: true },
    ];
    const headerPropsNoPN = [
      nameSortProps,
      ,
      ,
      validFromSortProps,
      validToSortProps,
      ,
      ,
      { center: true },
    ];

    const showInactivateIcon = (insider) => {
      return (
        !insider.validTo ||
        (insider.validTo &&
          moment()
            .startOf("day")
            .isBefore(moment(insider.validTo, "YYYY-MM-DD")))
      );
    };

    const insiderIsActive = (row) => {
      return (
        !row.validTo ||
        moment(row.validTo).endOf("day").isAfter(moment().startOf("day"))
      );
    };

    const filterInsiders = (insider) => {
      const filterState = this.state.insiderStateFilterValue;
      if (filterState === 0) {
        return insiderIsActive(insider);
      } else if (filterState === 1) {
        return !insiderIsActive(insider);
      } else return true;
    };

    const tableRows = this.state.insiderList
      .filter(filterInsiders)
      .map((row, index) => (
        <TR key={index}>
          {this.state.showPN ? (
            <TD pointer onClick={() => onEditSelectedRow(row)}>
              <LinkLookALike>{row.idNumber}</LinkLookALike>
            </TD>
          ) : undefined}
          <TD pointer onClick={() => onEditSelectedRow(row)}>
            <LinkLookALike>
              {row.person
                ? row.firstName + " " + row.middleName + " " + row.surname
                : row.companyName}
            </LinkLookALike>
          </TD>
          <TD>{row.position}</TD>
          <TD>{row.commission}</TD>
          {!this.state.hideValidDates ? (
            <React.Fragment>
              <TD>{getOBNTDate(row.validFrom)}</TD>
              <TD>{getOBNTDate(row.validTo)}</TD>
            </React.Fragment>
          ) : undefined}
          <TD>{row.type === "Primær" ? t("primary") : t("related")}</TD>
          <TD pointer center>
            <img
              alt={"Edit symbol"}
              src="/icons/ic-edit-black-24-px.svg"
              onClick={() => onEditSelectedRow(row)}
            />
          </TD>
          {showInactivateIcon(row) ? (
            <TD pointer center>
              <img
                alt={"Deactivate symbol"}
                src="/icons/red-cross-24.svg"
                onClick={() => onDeactivateSelected(row)}
              />
            </TD>
          ) : (
            <TD />
          )}
        </TR>
      ));

    const onUpdateInsiderSuccess = () => {
      this.setState({
        showPrimaryFirm: false,
        showPrimaryPerson: false,
        showRelatedPerson: false,
        showRelatedFirm: false,
        showEditInsider: false,
        showNewEntry: false,
        selectedRow: {},
        saving: false,
      });
      fetchInsiderLists(this.state.issuerObj);
    };

    const setSavingFalse = () => {
      this.setState({ saving: false });
    };

    const handleErrorsFromServer = (error, reject) => {
      setSavingFalse();
      return handleServerErrors(error, reject, this.props.t);
    };

    const onAddInsiderSuccess = () => {
      this.setState({
        showPrimaryFirm: false,
        showPrimaryPerson: false,
        showRelatedPerson: false,
        showRelatedFirm: false,
        showEditInsider: false,
        showNewEntry: false,
        selectedRow: {},
        saving: false,
      });
      fetchInsiderLists(this.state.issuerObj);
    };

    const onSaveNew = (values) => {
      if (!values.validFrom) {
        values.validFrom = this.state.insiderStartDate;
      }

      let valueObj = values;
      valueObj.validFrom = moment(
        valueObj.validFrom,
        "DD.MM.YYYY",
        true
      ).isValid()
        ? moment(valueObj.validFrom, "DD.MM.YYYY").format("YYYY-MM-DD")
        : moment(valueObj.validFrom, "YYYY-MM-DD", true).isValid()
        ? valueObj.validFrom
        : undefined;
      valueObj.issuer = { id: this.state.issuerObj.value };
      valueObj.type = this.state.isPrimary ? "Primær" : "Nærstående";
      valueObj.person = this.state.isPerson;

      if (!this.state.isPrimary) {
        const parent = this.props.insiderList.filter((p) => p.id === values.primaryInsider.value)[0];
        if (parent !== undefined) {
          valueObj.parentValidFrom = parent.validFrom;
          valueObj.parentValidTo = parent.validTo;
        }
      }

      return this.props.dispatch(addInsider(valueObj));
    };

    const onSubmit = (values) => {
      const newEntry = this.state.newModalOpened;
      const person = this.state.isPerson;
      const isPrimary = this.state.isPrimary;
      const showPN = this.state.showPN;
      let primaryValidFrom = null;

      if (!isPrimary && values.primaryInsider !== undefined) {
        const primary = this.state.insiderList.find(
          (p) => p.id === values.primaryInsider.value
        );
        primaryValidFrom = primary.validFrom;
      }
      const errors = validate(
          values,
          person,
          isPrimary,
          t,
          showPN,
          !this.state.hideValidDates,
          primaryValidFrom
      )
      return new Promise((resolve, reject) => {
        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          this.setState({ saving: true });

          if (values.languageOption && values.languageOption.value) {
            values.language = values.languageOption.value;
          }

          if (values.countryOption && values.countryOption.value) {
            values.country = values.countryOption.value;
          }

          if (person) {
            values.middleName = values.middleName ? values.middleName : "";
          }

          if (
            values.validTo &&
            moment(values.validTo, "DD.MM.YYYY", true).isValid()
          ) {
            values.validTo = moment(values.validTo, "DD.MM.YYYY").format(
              "YYYY-MM-DD"
            );
          }

          if (!this.state.isPrimary) {
            values.parentInsiderId = values.primaryInsider.value;
          } else {
            values.parentInsiderId = 0;
          }
          if (newEntry) {
            onSaveNew(values)
              .then(onAddInsiderSuccess)
              .then(resolve)
              .catch((error) => {
                handleErrorsFromServer(error, reject);
              });
          } else {
            this.props
              .dispatch(updateInsider(values))
              .then(onUpdateInsiderSuccess)
              .then(resolve)
              .catch((error) => {
                handleErrorsFromServer(error, reject);
              });
          }
        }
      });
    };

    const languageOptions = [
      { label: t("language_no"), value: "no" },
      { label: t("language_en"), value: "en" },
    ];

    const primaryInsiderOptions = this.props.primaryInsiderList.map(
      (insider) => {
        const id = insider.id;
        const name = insider.person
          ? insider.firstName + " " + insider.middleName + " " + insider.surname
          : insider.companyName;
        return { label: name, value: id };
      }
    );

    const countryOptions = this.props.countriesList.map((country, index) => {
      return {
        label: i18n.language === "nb" ? country.norwegian : country.english,
        value: country.countryCode,
      };
    });

    const getPrimaryInsider = (row) => {
      let insider = {};
      this.props.primaryInsiderList.forEach((value) => {
        if (value.id === row.parentInsiderId) {
          insider.label = value.person
            ? value.firstName + " " + value.middleName + " " + value.surname
            : value.companyName;
          insider.value = value.id;
        }
      });
      return insider;
    };

    const getCountry = (row) => {
      let initialCountry = {};
      this.props.countriesList.forEach((country) => {
        if (row.country === country.countryCode) {
          initialCountry.label =
            i18n.language === "nb" ? country.norwegian : country.english;
          initialCountry.value = country.countryCode;
        }
      });
      return initialCountry;
    };

    const editModal = (row) => {
      this.setState({ newModalOpened: false });
      if (row.person && row.type === "Primær") {
        const initialValues = {
          ...row,
          languageOption: {
            label: t("language_" + row.language),
            value: row.language,
          },
          countryOption: {
            ...getCountry(row),
          },
        };
        const header =
          t("edit_primary_insider_person") + " " + this.state.issuerObj.label;
        this.setState({
          initialValues: initialValues,
          showPrimaryPerson: true,
          modalHeader: header,
          isPrimary: true,
        });
      } else if (row.person && row.type === "Nærstående") {
        const initialValues = {
          ...row,
          languageOption: {
            label: t("language_" + row.language),
            value: row.language,
          },
          primaryInsider: {
            ...getPrimaryInsider(row),
          },
          countryOption: {
            ...getCountry(row),
          },
        };
        const header =
          t("edit_related_party_person") + " " + this.state.issuerObj.label;
        this.setState({
          initialValues: initialValues,
          showRelatedPerson: true,
          modalHeader: header,
          isPrimary: false,
        });
      } else if (!row.person && row.type === "Primær") {
        const initialValues = {
          ...row,
          languageOption: {
            label: t("language_" + row.language),
            value: row.language,
          },
          countryOption: {
            ...getCountry(row),
          },
        };
        const header =
          t("edit_primary_insider_firm") + " " + this.state.issuerObj.label;
        this.setState({
          initialValues: initialValues,
          showPrimaryFirm: true,
          modalHeader: header,
          isPrimary: true,
        });
      } else if (!row.person && row.type === "Nærstående") {
        const initialValues = {
          ...row,
          languageOption: {
            label: t("language_" + row.language),
            value: row.language,
          },
          primaryInsider: {
            ...getPrimaryInsider(row),
          },
          countryOption: {
            ...getCountry(row),
          },
        };
        const header =
          t("edit_related_party_firm") + " " + this.state.issuerObj.label;
        this.setState({
          initialValues: initialValues,
          showRelatedFirm: true,
          modalHeader: header,
          isPrimary: false,
        });
      }
    };

    const openCorrectNewModal = (values) => {
      return new Promise((resolve, reject) => {
        const errors = {};
        if (!values.primaryRelated) {
          errors.primaryRelated = t("required_field");
        }
        if (!values.personFirm && !values.primaryRelated.value === 0) {
          errors.personFirm = t("required_field");
        }

        if (Object.keys(errors).length > 0) {
          reject(new SubmissionError(errors));
        } else {
          const primaryRelated = values.primaryRelated.value;
          //If primary insider is chosen then set Person/Firm value too person when user has'nt made any input
          const personFirm =
            values.personFirm && values.personFirm.value
              ? values.personFirm.value
              : 0;
          this.setState({ showNewEntry: false, newModalOpened: true });
          if (primaryRelated === 0) {
            const header =
              t("new_primary_insider_person") +
              " " +
              this.state.issuerObj.label;
            this.setState({
              initialValues: {
                comment: "",
                idNumber: "",
                norwegian: this.state.showPN,
              },
              showPrimaryPerson: true,
              modalHeader: header,
              isPrimary: true,
              isPerson: true,
            });
          } else {
            if (personFirm === 0) {
              const header =
                t("new_related_party_person") +
                " " +
                this.state.issuerObj.label;
              this.setState({
                initialValues: {
                  comment: "",
                  idNumber: "",
                  norwegian: this.state.showPN,
                },
                showRelatedPerson: true,
                modalHeader: header,
                isPrimary: false,
                isPerson: true,
              });
            } else {
              const header =
                t("new_related_party_firm") + " " + this.state.issuerObj.label;
              this.setState({
                initialValues: {
                  comment: "",
                  idNumber: "",
                  norwegian: this.state.showPN,
                },
                showRelatedFirm: true,
                modalHeader: header,
                isPrimary: false,
                isPerson: false,
              });
            }
          }
          resolve();
        }
      });
    };

    const onDeactivateSelected = (row) => {
      this.setState({ showDeactivateModal: true, selectedRow: row });
    };

    const onDeactivateSuccess = () => {
      this.setState({ showDeactivateModal: false, saving: false });
      fetchInsiderLists(this.state.issuerObj);
    };

    const onDeactivateFailed = () => {
      this.setState({ saving: false });
      NotificationManager.error(t("failed_to_deactivate_insider"));
    };
    const deactivateItem = () => {
      this.setState({ saving: true });
      this.props
        .dispatch(deactivateInsider(this.state.selectedRow.id))
        .then(onDeactivateSuccess)
        .catch((err) => onDeactivateFailed(err));
    };

    const getRowText = (row) => {
      const insider = {
        insider:
          (row.type === "Primær"
            ? t("primary_insider") + ": "
            : t("related_party") + ": ") +
          (row.person
            ? row.firstName + " " + row.middleName + " " + row.surname
            : row.companyName),
      };
      return t("deactivate_text", insider);
    };

    return (
      <FlexContainer column>
        <form autoComplete={"off"}>
          {this.state.saving ? <OverlayLoader /> : undefined}
          <PageHeading>{t("insiderregister")}</PageHeading>
          <IssuerBox
            options={companyList.map((element) => {
              return {
                label: element.longName,
                value: element.cid,
              };
            })}
            t={t}
            onSelect={fetchInsiderLists}
            value={this.state.issuerObj}
          />
          <FlexContainer
            row
            margin={[20, 0, 10, 0]}
            hidden={this.state.issuerObj && this.state.issuerObj.id === -1}
            vAlignCenter
          >
            <Button.Standard
              onClick={() => {
                // Make sure all forms are empty when clicking "New"
                this.props.dispatch(reset("primaryPerson"));
                this.props.dispatch(reset("primaryFirm"));
                this.props.dispatch(reset("relatedPerson"));
                this.props.dispatch(reset("relatedFirm"));
                this.props.dispatch(reset("insiderModule"));
                this.setState({ showNewEntry: true });
              }}
              inverted
            >
              {this.props.t("new")}
            </Button.Standard>
            <InsiderStateRadioButtons
              t={t}
              input={{
                value: this.state.insiderStateFilterValue,
                onChange: (value) => {
                  this.setState({ insiderStateFilterValue: value });
                },
              }}
            />
          </FlexContainer>
          {!this.state.loader ? (
            <React.Fragment>
              <FlexContainer hidden={this.state.insiderList.length === 0}>
                <Table
                  tableRows={tableRows}
                  tableHeaderLabels={
                    this.state.showPN ? thLabels : thLabelsNoPN
                  }
                  tableHeaderProps={
                    this.state.showPN ? headerProps : headerPropsNoPN
                  }
                  t={t}
                  forceUpdate={true}
                />
              </FlexContainer>
              <FlexContainer
                hidden={
                  this.state.insiderList.length > 0 ||
                  (this.state.issuerObj && this.state.issuerObj.id === -1)
                }
                center
                vAlignCenter
              >
                <PageHeading>{t("not_found")}</PageHeading>
              </FlexContainer>
            </React.Fragment>
          ) : (
            <Loader />
          )}
          {this.state.showPrimaryPerson ? (
            <Modal
              center
              header={this.state.modalHeader}
              isOpen={this.state.showPrimaryPerson}
              onClose={() => this.setState({ showPrimaryPerson: false })}
              fixedHeightEightyPercent
            >
              <PrimaryPerson
                languageOptions={languageOptions}
                countryOptions={countryOptions}
                t={t}
                onCancel={() => this.setState({ showPrimaryPerson: false })}
                initialValues={{ ...this.state.initialValues }}
                enableReinitialize={true}
                forceUpdate={true}
                state={this.state}
                onSubmit={(values) => onSubmit(values)}
              />
            </Modal>
          ) : undefined}
          {this.state.showPrimaryFirm ? (
            <Modal
              header={this.state.modalHeader}
              isOpen={this.state.showPrimaryFirm}
              onClose={() => this.setState({ showPrimaryFirm: false })}
              fixedHeightEightyPercent
            >
              <PrimaryFirm
                languageOptions={languageOptions}
                countryOptions={countryOptions}
                t={t}
                onCancel={() => this.setState({ showPrimaryFirm: false })}
                initialValues={{ ...this.state.initialValues }}
                enableReinitialize={true}
                forceUpdate={true}
                state={this.state}
                onSubmit={(values) => onSubmit(values)}
              />
            </Modal>
          ) : undefined}
          {this.state.showRelatedPerson ? (
            <Modal
              header={this.state.modalHeader}
              isOpen={this.state.showRelatedPerson}
              onClose={() => this.setState({ showRelatedPerson: false })}
              fixedHeightEightyPercent
            >
              <RelatedPerson
                languageOptions={languageOptions}
                countryOptions={countryOptions}
                t={t}
                insiderOptions={primaryInsiderOptions}
                onCancel={() => this.setState({ showRelatedPerson: false })}
                initialValues={{ ...this.state.initialValues }}
                enableReinitialize={true}
                forceUpdate={true}
                state={this.state}
                onSubmit={(values) => onSubmit(values)}
              />
            </Modal>
          ) : undefined}
          {this.state.showRelatedFirm ? (
            <Modal
              header={this.state.modalHeader}
              isOpen={this.state.showRelatedFirm}
              onClose={() => this.setState({ showRelatedFirm: false })}
              fixedHeightEightyPercent
            >
              <RelatedFirm
                languageOptions={languageOptions}
                countryOptions={countryOptions}
                t={t}
                insiderOptions={primaryInsiderOptions}
                onCancel={() => this.setState({ showRelatedFirm: false })}
                initialValues={{ ...this.state.initialValues }}
                enableReinitialize={true}
                forceUpdate={true}
                state={this.state}
                onSubmit={(values) => onSubmit(values)}
              />
            </Modal>
          ) : undefined}

          <NewInsiderModal
            t={t}
            onCancel={() => this.setState({ showNewEntry: false })}
            enableReinitialize={true}
            forceUpdate={true}
            showNewEntry={this.state.showNewEntry}
            onSubmit={(values) => openCorrectNewModal(values)}
            onClose={() =>
              this.setState({ showNewEntry: false, selectedRow: {} })
            }
          />

          <ConfirmModal
            isOpen={this.state.showDeactivateModal}
            onClose={() => this.setState({ showDeactivateModal: false })}
            width={"25%"}
            header={t("deactivate")}
            confirmText={t("deactivate")}
            cancelText={t("cancel")}
            onConfirm={() => {
              this.setState({ showDeleteModal: false });
              deactivateItem();
            }}
          >
            <FlexContainer row vAlignCenter margin={[16, 0, 16, 0]}>
              {getRowText(this.state.selectedRow)}
            </FlexContainer>
          </ConfirmModal>
        </form>
      </FlexContainer>
    );
  }
}

InsiderList.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    companyInformationReducer,
    insiderReducer,
    companyPermissionReducer,
  } = state;

  return {
    companyList: companyInformationReducer.companyList.list,
    insiderList: insiderReducer.insiderList.list,
    primaryInsiderList: insiderReducer.primaryInsiderList.list,
    countriesList: insiderReducer.countries.list,
    insiderPermissions: companyPermissionReducer.companyPermissions,
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderList)
);
