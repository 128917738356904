import React from "react";
import { Header, InputBox, TextAreaBox } from "../insiderTool/components";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import SearchableSelectNew from "../components/SearchableSelectNew";
import Label from "../components/Label";
import { fetchIssuerList } from "./NotificationActions";
import DayPicker from "../components/DayPicker";
import { ErrorText } from "../components/ErrorText";
import { TimePicker } from "../components/TimePicker";
import { OBNTInsiderToolDateFormat } from "../util/common";
import i18n from "../i18n";
import { HelpIcon } from "../components/HelpIcon";
import { getUtcDateTimeLabelFromFormValues } from "../insiderTool/UTCUtils";
import CheckBox from "../components/CheckBox";
import { PageHeading } from "../components/PageHeading";
import ResponsiblePersons from "./ResponsiblePersons";

const OrganisationDropdownBox = props => {
  return (
      <FlexContainer column margin={[0, 19, 0, 0]}>
        <Label htmlFor={props.input.name} bold margin={[8, 0, 3, 0]}>
          {props.label}:
          {props.meta.error ? (
              <ErrorText padding={[0, 0, 0, 5]}>{props.meta.error}</ErrorText>
          ) : undefined}
        </Label>
        <FlexContainer id={props.input.name} row marginTop={10}>
          <SearchableSelectNew
              {...props}
              onSelect={props.onSelectOrganisation}
              selectIfOnlyOneOption
          />
        </FlexContainer>
      </FlexContainer>
  );
}
const TimePickerBox = props => {
  const { error } = props.meta;
  return (
    <FlexContainer>
      <Label margin={[8, 0, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>{" "}
      <TimePicker {...props} />
    </FlexContainer>
  );
};

const DateBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, props.noRightMarign ? 0 : 8, 0, 0]}>
      <Label
        maxWidth
        htmlFor={props.name}
        margin={[8, props.noRightMarign ? 0 : 8, 3, 0]}
      >
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <DayPicker
        dateFormat={OBNTInsiderToolDateFormat}
        {...props}
        border={true}
        disableDaysAfter={new Date()}
      />
    </FlexContainer>
  );
};

export class NotificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      mapResponsiblePersons: new Map([[0, 0]]),
      selectedOrg: undefined,
      issuerList: [],
      selectedNew: false,
      orgCompanies: []
    };
  }

  componentWillMount() {
    this.props.dispatch(fetchIssuerList());
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.selectedOrganisationCompanies !== undefined && nextProps.selectedOrganisationCompanies !== this.state.orgCompanies && nextProps.issuerListIsFetched===true) {
      this.setState({orgCompanies: nextProps.selectedOrganisationCompanies,
        issuerList: nextProps.issuerList.filter(issuer =>
            nextProps.selectedOrganisationCompanies?.includes(issuer.value))});
    }
  }

  render() {
    const { t, notificationFormValues } = this.props;

    const getDateWithUTCTimeLabel = () => {
      let label = t("date");

      if (
        notificationFormValues &&
        notificationFormValues["fromDate"] &&
        notificationFormValues["responsible_time"]
      ) {
        label +=
          " " +
          getUtcDateTimeLabelFromFormValues(
            notificationFormValues,
            "fromDate",
            "responsible_time"
          );
      }
      return label;
    };

    return (
      <form autoComplete="off">
        {/*
        Company
        */}
        <PageHeading margin={[0, 0, 16, 0]}>
          {t("new_notification")}
        </PageHeading>
        <FlexContainer
          backgroundColor={"rgba(199,211,227,0.3)"}
          margin={[10, 0, 10, 0]}
          padding={[10, 10, 10, 10]}
        >
          {this.props.organisationList &&
          this.props.organisationList.length > 1 ? (
            <Field
              name={"organisationId"}
              label={t("organisation") + "*"}
              component={OrganisationDropdownBox}
              options={this.props.organisationList}
              placeholder={t("select")}
              t={t}
              onSelectOrganisation={value => {
                if (this.state.selectedOrg !== value) {
                  this.props.change("issuerId", null);
                  this.props.onSelectOrganisation(value);
                  this.setState({selectedOrd: value})
                }
              }}
            />
          ) : undefined}

          <FlexContainer row>
            <FlexContainer>
              {notificationFormValues &&
              notificationFormValues.unknownIssuer &&
              notificationFormValues.unknownIssuer === true ? (
                <Field
                  component={InputBox}
                  name="issuer"
                  label={t("full_legal_company_name") + "*:"}
                  bold
                />
              ) : (
                <Field
                  name={"issuerId"}
                  label={t("issuer") + "*"}
                  component={OrganisationDropdownBox}
                  options={this.state.issuerList}
                  placeholder={t("select")}
                  t={t}
                />
              )}
            </FlexContainer>
            <FlexContainer
              width={"17%"}
              center
              vAlignCenter
              margin={[20, 0, 0, 5]}
              row
            >
              <Field
                name={"unknownIssuer"}
                component={CheckBox}
                label={t("other_issuer")}
                margin={[14, 0, 0, 0]}
              />
              <HelpIcon
                margin={[13, 0, -3, 3]}
                fileName={"other_issuer"}
                language={i18n.language}
                incomingPrefix={"notification"}
                height={"16"}
                width={"16"}
              />
            </FlexContainer>
          </FlexContainer>

          <Field
            component={TextAreaBox}
            name="text"
            label={t("comments_optional")}
            bold
            border
            noResize
            maxLength={300}
            fontSize="14px;"
            openSans
          />
        </FlexContainer>

        <FlexContainer
          row
          backgroundColor={"rgba(199,211,227,0.3)"}
          margin={[10, 0, 10, 0]}
          padding={[10, 10, 10, 10]}
        >
          {/* 
        List information
        */}
          <FlexContainer>
            <FlexContainer>
              <Header>
                {t("it_date_time_responsible")}
                <HelpIcon
                  margin={[0, 0, -3, 5]}
                  fileName={"date_time_responsible_nca"}
                  incomingPrefix={"notification"}
                  language={i18n.language}
                  height={"16"}
                  width={"16"}
                />
              </Header>
            </FlexContainer>
            <FlexContainer row vAlignEnd>
              <Field
                name="fromDate"
                component={DateBox}
                label={getDateWithUTCTimeLabel() + "*"}
              />

              <Field
                component={TimePickerBox}
                name="responsible_time"
                border
                t={t}
                label={t("time") + "*"}
              />
            </FlexContainer>

            <Header>
              {t("notification_reference_to_announcement")}
              <HelpIcon
                margin={[0, 0, -3, 5]}
                fileName={"reference_to_announcement_notification"}
                incomingPrefix={"notification"}
                language={i18n.language}
                height={"16"}
                width={"16"}
              />
            </Header>

            <Field
              component={InputBox}
              margin={[0, 8, 0, 0]}
              name="newsMessageReference"
              label={t("language_en") + "*"}
            />
            <Field
              component={InputBox}
              margin={[0, 8, 0, 0]}
              name="newsMessageReferenceNo"
              label={t("language_no")}
            />
          </FlexContainer>
        </FlexContainer>
        <ResponsiblePersons
            {...this.props}
        />

      </form>
    );
  }
}

function mapStateToProps(state) {
  const { notificationReducer, form } = state;

  return {
    organisationList: notificationReducer.organisationList.list.map(org => {
      return { label: org.name, value: org.id };
    }),
    issuerList: notificationReducer.issuerList.list.map(issuer => {
      return { label: issuer.longName, value: issuer.cid };
    }),
    notificationFormValues: form.notificationForm
      ? form.notificationForm.values
      : {}
  };
}

NotificationForm = reduxForm({
  form: "notificationForm",
  destroyOnUnmount: false
})(NotificationForm);

export default connect(mapStateToProps)(NotificationForm);
