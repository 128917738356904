import React from "react";
import styled from "styled-components";
import FocusElement from "./FocusElement";

const Heading = styled.h2`
  font-size: ${(props) => (props.fontSize ? props.fontSize + "px;" : "24px;")};
  font-weight: 600;

  ${(props) => (props.color ? "color: " + props.color + ";" : "color: black;")}
  ${(props) => (props.underline ? "text-decoration: underline;" : "")}
    ${(props) => (props.uppercase ? "text-transform: uppercase;" : "")}
    ${(props) => (props.opacity ? "opacity: " + props.opacity + ";" : "")}
    ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
    ${(props) => (props.pointer ? "cursor: pointer;" : "")}
    ${(props) => (props.hidden === true ? "display: none;" : "display: flex;")}
`;

export const PageHeading = (props) => {
  const { onClick, ...restProps } = props;
  return (
    <Heading {...restProps}>
      {props.isFocusable ? (
        <FocusElement onClick={onClick} noPointer={!props.pointer}>
          {props.children}
        </FocusElement>
      ) : (
        props.children
      )}
    </Heading>
  );
};
