import { FlexContainer } from "../../components/FlexContainer";
import Button from "../../components/Button";
import { Modal } from "../../components/Modal";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { DropDownBox, InputBox } from "../../insiderTool/components";
import CheckBox from "../../components/CheckBox";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class AddInsiderLogUrlModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    return (
      <Modal
        width={"50%"}
        key={"showEditURLModal"}
        header={t("system_add_insider_sync")}
        isOpen={true}
        onClose={this.props.onClose}
        center
        fillContent
      >
        <form onSubmit={e => e.preventDefault()}>
          <FlexContainer column>
            <FlexContainer width={"32%"} >
              <Field
                  t={t}
                  component={DropDownBox}
                  placeholder={t("select")}
                  name={"client"}
                  options={this.props.clients}
                  label={t("Kunde")}
                  onSelect={this.props.onSelectClient}
              />
            </FlexContainer>
            <FlexContainer row margin={[20, 15, 0, 0]}>
              <FlexContainer >
                <Field
                    t={t}
                    component={DropDownBox}
                    placeholder={t("select")}
                    name={"company"}
                    options={this.props.companyList}
                    label={t("company")}
                />
              </FlexContainer>

              <FlexContainer margin={[0, 0, 0, 20]}>
                <Field
                    component={InputBox}
                    name={"insiderLogURL"}
                    border
                    bold
                    label={t("system_insider_log_link")}
                    tooltip={"Kun relevant for InsiderLog"}
                />
              </FlexContainer>

              <FlexContainer margin={[38, 0, 0, 20]}>
                <Field
                  component={CheckBox}
                  name={"insiderLogSync"}
                  border
                  label={t("system_allow_insidersync")}
                />
              </FlexContainer>
            </FlexContainer>
            <FlexContainer
              row
              center
              flexEnd
              vAlignCenter
              margin={[35, 0, 16, 0]}
            >
              <Button.Standard
                margin={[0, 15, 0, 0]}
                onClick={this.props.handleSubmit(
                  this.props.addInsiderSyncDetails
                )}
              >
                {t("save")}
              </Button.Standard>
              <Button.Text onClick={this.props.onClose}>
                {t("close")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        </form>
      </Modal>
    );
  }
}

AddInsiderLogUrlModal = reduxForm({
  form: "addInsiderLogUrlModalForm"
})(AddInsiderLogUrlModal);

function mapStateToProps(state) {
  const {} = state;

  return {};
}

export default connect(mapStateToProps)(
  withTranslation("translations")(AddInsiderLogUrlModal)
);
