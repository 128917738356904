import { combineReducers } from "redux";
import {
  MessageListActions,
  DraftMessageListActions,
  MessageActions,
  Securities,
  FetchAttachmentActions,
  AttachmentListActions,
  CorrectionMessageActions,
  GlobalMessagePermissionActions,
  MessagePermissionActions,
  MessageParametersActions,
  CreateNewsMessage,
  UpdateNewsMessage, MessageCountActions, MessageDraftCountActions, ChannelActions,
} from "./MessageActions";

const initialMessageState = {
  data: undefined,
  isFetched: false,
};
export const message = (
  state = {
    ...initialMessageState,
  },
  action
) => {
  switch (action.type) {
    case CorrectionMessageActions.postSuccess:
    case MessageActions.postSuccess:
    case CreateNewsMessage.postSuccess:
    case UpdateNewsMessage.postSuccess:
      return Object.assign({}, state, {
        data: action.data.data.entity,
        isFetched: true,
      });
    case CorrectionMessageActions.request:
    case MessageActions.request:
      return Object.assign({}, state, {
        isFetched: false,
      });
    case CorrectionMessageActions.postFail:
    case MessageActions.postFail:
    case MessageActions.clear:
      return Object.assign({}, state, {
        ...initialMessageState,
      });

    default:
      return state;
  }
};

const initialMessageListState = {
  list: undefined,
  isFetched: false,
  receiveFailed: false,
};
export const messageList = (
  state = {
    ...initialMessageListState,
  },
  action
) => {
  switch (action.type) {
    case MessageListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data && action.data.data ? action.data.data.entity : [],
        isFetched: true,
      });

    case MessageListActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialMessageCountState = {
  count: 0,
  isFetched: false,
  receiveFailed: false,
};
export const messageCount = (
    state = {
      ...initialMessageCountState,
    },
    action
) => {
  switch (action.type) {
    case MessageCountActions.postSuccess:
      return Object.assign({}, state, {
        count: action.data && action.data.data ? action.data.data.entity : [],
        isFetched: true,
      });

    case MessageCountActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialDraftMessageCountState = {
  count: 0,
  isFetched: false,
  receiveFailed: false,
};
export const messageDraftCount = (
    state = {
      ...initialDraftMessageCountState,
    },
    action
) => {
  switch (action.type) {
    case MessageDraftCountActions.postSuccess:
      return Object.assign({}, state, {
        count: action.data && action.data.data ? action.data.data.entity : [],
        isFetched: true,
      });

    case MessageDraftCountActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialDraftMessageListState = {
  list: undefined,
  isFetched: false,
  receiveFailed: false,
};
export const draftMessageList = (
  state = {
    ...initialDraftMessageListState,
  },
  action
) => {
  switch (action.type) {
    case DraftMessageListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data ? action.data.data.entity : [],
        isFetched: true,
      });

    case DraftMessageListActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initSecurities = {
  list: undefined,
  isFetched: false,
};
export const securities = (
  state = {
    ...initSecurities,
  },
  action
) => {
  switch (action.type) {
    case Securities.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case Securities.clear_securities:
      return Object.assign({}, state, {
        ...initSecurities,
      });
    default:
      return state;
  }
};

const initAttachment = {
  file: undefined,
  list: undefined,
  listIsFetched: false,
};
export const attachment = (
  state = {
    ...initAttachment,
  },
  action
) => {
  switch (action.type) {
    case FetchAttachmentActions.postSuccess:
      return Object.assign({}, state, {
        file: action.payload,
      });
    case AttachmentListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        listIsFetched: true,
      });
    case AttachmentListActions.request:
      return Object.assign({}, state, {
        listIsFetched: false,
      });
    default:
      return state;
  }
};

const initialGlobalMessagePermissionState = {
  list: [],
  isFetched: false,
  receiveFailed: false,
};
export const globalMessagePermissionList = (
  state = {
    ...initialGlobalMessagePermissionState,
  },
  action
) => {
  switch (action.type) {
    case GlobalMessagePermissionActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity.globalMessagePermissions || [],
        isFetched: true,
      });

    case GlobalMessagePermissionActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialMessagePermissionState = {
  list: [],
  isFetched: false,
  receiveFailed: false,
};
export const messagePermissionList = (
  state = {
    ...initialMessagePermissionState,
  },
  action
) => {
  switch (action.type) {
    case MessagePermissionActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity.messagePermissions || [],
        isFetched: true,
      });

    case MessagePermissionActions.request:
      return Object.assign({}, state, {
        list: undefined,
        isFetched: false,
      });

    case MessagePermissionActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialMessageParamsState = {
  attachMaxSize: undefined,
  maxNumAttach: undefined,
  holidays: undefined,
  newswebPrefix: undefined,
  validExtensions: [],
  isFetched: false,
  receiveFailed: false,
  delayedPublicationMaxDaysOnlyBusinessDays: true,
  delayedPublicationMaxDays: 0,
  fileValidation: true
};
export const messageParams = (
  state = {
    ...initialMessageParamsState,
  },
  action
) => {
  switch (action.type) {
    case MessageParametersActions.postSuccess:
      return Object.assign({}, state, {
        attachMaxSize: action.data.data.entity["attachments.max_size"],
        maxNumAttach: action.data.data.entity["attachments.max"],
        holidays: action.data.data.entity.publicHolidays,
        newswebPrefix: action.data.data.entity.httpNewswebPrefix,
        validExtensions: action.data.data.entity["attachments.validExtensions"],
        fileValidation: action.data.data.entity["attachments.enableFileValidation"],
        isFetched: true,
        delayedPublicationMaxDaysOnlyBusinessDays:
          action.data.data.entity.delayedPublicationMaxDaysOnlyBusinessDays,
        delayedPublicationMaxDays:
          action.data.data.entity.delayedPublicationMaxDays,
        messageTitleMinLength: action.data.data.entity.messageTitleMinLength,
        validLanguages: action.data.data.entity.validLanguages ? action.data.data.entity.validLanguages : ["en"]
      });

    case MessageParametersActions.request:
      return Object.assign({}, state, {
        isFetched: false,
      });

    case MessageParametersActions.postFail:
      return Object.assign({}, state, {
        receiveFailed: true,
      });

    default:
      return state;
  }
};

const initialChannels = {
  list: [],
};
export const channels = (
    state = {
      ...initialChannels,
    },
    action
) => {
  switch (action.type) {
    case ChannelActions.receive:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
      });
    default:
      return state;
  }
};

const messageReducer = combineReducers({
  message,
  messageList,
  messageCount,
  messageDraftCount,
  draftMessageList,
  securities,
  attachment,
  globalMessagePermissionList,
  messagePermissionList,
  messageParams,
  channels
});

export default messageReducer;
