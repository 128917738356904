import {
  confirmInsider,
  fetchInsiderList,
  fetchTemplate,
  InsiderType,
  updateInsiderListData
} from "../../InsiderToolActions";
import i18n from "../../../i18n";
import { FlexContainer } from "../../../components/FlexContainer";
import React from "react";
import { Header } from "../../components";
import Label from "../../../components/Label";
import { HelpIcon } from "../../../components/HelpIcon";
import Button from "../../../components/Button";
import styled from "styled-components";
import { browserIsIE } from "../../../util/common";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import SendReminderModal from "../../modals/insiderList/SendReminderModal";
import { Modal } from "../../../components/Modal";
import { getISOTimeFromDateAndTime } from "../../UTCUtils";
import { reset } from "redux-form";
import { OverlayLoader } from "../../../components/Loader";
import UpdateDateTime from "../../modals/insiderList/UpdateDateTime";
import { valuesNotAllowedWithoutMFANumber } from "./handleInsiders/AddNewInsider";

const EditIcon = styled.img`
  margin: 9px 0 9px 9px;
  cursor: pointer;
  align: center;
  padding-right: 15px;
  float: right;
`;

class ButtonRowInsiders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notifyReminder: undefined,
      showNotifyModal: false,
      norwegianSelected: true,
      showEditUpdateTime: false,
      emailTemplateNo: undefined,
      emailTemplateEn: undefined,
      templateFileName: undefined
    };
  }

  getSelectedInsiders = () => {
    const { insiderTableSelection } = this.props;

    let insidersToApprove = [];
    if (insiderTableSelection) {
      Object.keys(insiderTableSelection).forEach(key => {
        if (insiderTableSelection[key]) {
          insidersToApprove.push(key);
        }
      });
    }
    return insidersToApprove;
  };

  prepareNotifyInsiders = () => {
    const { dispatch, listType } = this.props;
    const templateFileName =
      listType === InsiderType.LIST
        ? "inside_notification"
        : "conf_notification";
    this.setState({ templateFileName: templateFileName });
    const insidersToApprove = this.getSelectedInsiders();
    if (insidersToApprove.length < 1) {
      NotificationManager.error(
        this.props.t("it_select_one_or_more_insiders"),
        "",
        0
      );
    } else if (this.someSelectedInsidersHasIncorrectState(["active"])) {
      NotificationManager.error(
        this.props.t("it_some_of_the_selected_are_not_in_active_state"),
        "",
        0
      );
    } else if (
      this.someOfTheSelectedHasMoreValuesThanAllowedWithoutTwoFactorNumber(
        insidersToApprove
      )
    ) {
      NotificationManager.error(
        this.props.t(
          "it_some_of_the_selected_has_more_value_than_allowed_and_no_two_factor_info"
        ),
        "",
        0
      );
    } else {
      Promise.resolve(
        this.setState({ loading: true, notifyReminder: "notify" })
      )
        .then(() => dispatch(fetchTemplate("no", templateFileName)))
        .then(response => this.setState({ emailTemplateNo: response }))
        .then(() => this.props.dispatch(fetchTemplate("en", templateFileName)))
        .then(response => this.setState({ emailTemplateEn: response }))
        .then(() => {
          this.setState({ loading: false, showNotifyModal: true });
        })
        .catch(e => console.log(e));
    }
  };

  prepareReminder = () => {
    const listType = this.props.listType;
    const templateFileName =
      listType === InsiderType.LIST ? "inside_reminder" : "conf_reminder";
    this.setState({ templateFileName: templateFileName });
    const insidersToApprove = this.getSelectedInsiders();
    if (insidersToApprove.length < 1) {
      NotificationManager.error(
        this.props.t("it_select_one_or_more_insiders_reminder"),
        "",
        0
      );
    } else if (
      this.someSelectedInsidersHasIncorrectState(["notified", "responded"])
    ) {
      NotificationManager.error(
        this.props.t("it_some_of_the_selected_are_not_notified"),
        "",
        0
      );
    } else if (
      this.someOfTheSelectedHasMoreValuesThanAllowedWithoutTwoFactorNumber(
        insidersToApprove
      )
    ) {
      NotificationManager.error(
        this.props.t(
          "it_some_of_the_selected_has_more_value_than_allowed_and_no_two_factor_info"
        ),
        "",
        0
      );
    } else {
      this.setState({ loading: true, notifyReminder: "reminder" });
      this.props
        .dispatch(fetchTemplate("no", templateFileName))
        .then(response => this.setState({ emailTemplateNo: response }))
        .then(() => this.props.dispatch(fetchTemplate("en", templateFileName)))
        .then(response => this.setState({ emailTemplateEn: response }))
        .then(() => {
          this.setState({ loading: false, showNotifyModal: true });
        })
        .catch(e => console.log(e));
    }
  };

  someSelectedInsidersHasIncorrectState = stateArray => {
    const { insiderTableSelection } = this.props;

    let someSelectedHasIncorrectState = false;

    Object.keys(insiderTableSelection)
      .filter(key => insiderTableSelection[key] === true)
      .forEach(insider => {
        const insiderState = this.getInsiderState(insider);
        if (!stateArray.includes(insiderState)) {
          someSelectedHasIncorrectState = true;
        }
      });
    return someSelectedHasIncorrectState;
  };

  getInsiderState = insiderId => {
    let state = "";

    this.props.insiderStateData.find(insider => {
      if (insiderId === insider.id) {
        state = insider.short_data;
      }
    });

    return state;
  };

  getInsiderObject = insiderId =>
    this.props.insiders.find(insider => insider.id === insiderId);

  someOfTheSelectedIsMissingValues = insidersToApprove => {
    if (this.props.listType === InsiderType.CONFIDENTIALITY_LIST) {
      return false;
    }
    return insidersToApprove.find(insiderId => {
      const insiderData = this.getInsiderObject(insiderId).data;

      return !(
        insiderData.address &&
        insiderData.company_name &&
        insiderData.company_address &&
        insiderData.company_country &&
        insiderData.country &&
        insiderData.date_of_birth &&
        insiderData.email &&
        insiderData.idnumber &&
        insiderData.insider_function_and_reason &&
        insiderData.first_name &&
        insiderData.obtained &&
        insiderData.phone_private &&
        insiderData.phone_work &&
        insiderData.surname &&
        insiderData.birth_surname
      );
    });
  };

  someOfTheSelectedHasMoreValuesThanAllowedWithoutTwoFactorNumber = insidersToApprove => {
    this.setState({ loading: true });
    let missingTwoFactorAndHasMoreValues = false;
    for (let i = 0; i < insidersToApprove.length; i++) {
      const insiderData = this.getInsiderObject(insidersToApprove[i]).data;
      const twoFactorPhone = insiderData.twoFactorPhone;
      const twoFactorCountryCode = insiderData.twoFactorCountryCode;

      if (!twoFactorPhone || !twoFactorCountryCode) {
        for (let k = 0; k < valuesNotAllowedWithoutMFANumber.length; k++) {
          if (
            insiderData[valuesNotAllowedWithoutMFANumber[k]] &&
            insiderData[valuesNotAllowedWithoutMFANumber[k]].length > 0
          ) {
            missingTwoFactorAndHasMoreValues = true;
            break;
          }
        }
      }
      if (missingTwoFactorAndHasMoreValues) {
        break;
      }
    }

    this.setState({ loading: false });

    return missingTwoFactorAndHasMoreValues;
  };

  fetchListAndResetTable = () => {
    const { dispatch, listId } = this.props;
    dispatch(fetchInsiderList(listId)).then(() => {
      dispatch(reset("insiderTableForm"));
    });
  };

  approveInsiders = () => {
    const { dispatch, listId } = this.props;
    const insidersToApprove = this.getSelectedInsiders();

    if (insidersToApprove.length > 0) {
      if (this.someOfTheSelectedIsMissingValues(insidersToApprove)) {
        NotificationManager.error(
          this.props.t("it_one_or_more_is_missing_values"),
          "",
          0
        );
      } else {
        new Promise(resolve => {
          insidersToApprove.forEach((insiderId, index) => {
            dispatch(confirmInsider(insiderId, listId)).then(() => {
              if (index === insidersToApprove.length - 1) {
                resolve();
              }
            });
          });
        })
          .then(this.fetchListAndResetTable)
          .catch(e => console.log(e));
      }
    } else {
      NotificationManager.error(
        this.props.t("it_select_one_or_more_to_approve"),
        "",
        0
      );
    }
  };

  editUpdateTime = () => {
    this.setState({ showEditUpdateTime: true });
  };

  setUpdatedTime = data => {
    const { dispatch, insiderListInfo, listId } = this.props;
    let listDataCopy = JSON.parse(JSON.stringify(insiderListInfo.data));
    listDataCopy.updated = getISOTimeFromDateAndTime(
      data.update_date,
      data.update_time
    );
    delete listDataCopy.update_date;
    delete listDataCopy.update_time;
    delete listDataCopy.updatedTime;

    dispatch(
      updateInsiderListData(
        InsiderType.LIST,
        { ...listDataCopy },
        listId,
        listId
      )
    )
      .then(() =>
        this.setState({
          showEditUpdateTime: false
        })
      )
      .then(() => dispatch(fetchInsiderList(listId)));
  };

  replaceKeysInString = (string, language) => {
    const { t } = this.props;
    if (string) {
      let replacedString = string.replace(
        /_url_/g,
        t("it_url_replaced_here", { lng: language })
      );
      replacedString = replacedString.replace(
        /_name_ _surname_/g,
        t("it_name_replaced_here", { lng: language })
      );
      replacedString = replacedString.replace(
        /_projectName_/g,
        this.props.insiderListInfo.data.code_name
      );

      return replacedString;
    }
    return string;
  };

  render() {
    const { listType, insiderListInfo, t } = this.props;

    return (
      <React.Fragment>
        {this.state.loading ? <OverlayLoader /> : ""}
        <FlexContainer row margin={[10, 0, 10, 0]} key="buttonRowInsider">
          <FlexContainer row>
            <Button.Standard
              onClick={
                this.props.hasEditAccess
                  ? this.prepareNotifyInsiders
                  : undefined
              }
              margin={[0, 5, 0, 0]}
              inactive={!this.props.hasEditAccess}
            >
              {t("send_notification_insider")}
            </Button.Standard>
            <Button.Standard
              onClick={
                this.props.hasEditAccess ? this.prepareReminder : undefined
              }
              margin={[0, 5, 0, 0]}
              inactive={!this.props.hasEditAccess}
            >
              {t("it_send_reminder_insider")}
            </Button.Standard>
            <Button.Standard
              inactive={!this.props.hasEditAccess}
              onClick={
                this.props.hasEditAccess ? this.approveInsiders : undefined
              }
            >
              {t("it_approve_entry")}
            </Button.Standard>
          </FlexContainer>
          <FlexContainer row vAlignEnd width={browserIsIE() ? "auto" : ""}>
            <FlexContainer column vAlignEnd>
              <Header margin={[-2, 0, 0, 0]}>
                <Label
                  margin={[3, 0, -3, 3]}
                  fileName={
                    listType === InsiderType.LIST
                      ? "last_updated"
                      : "last_updated_conf"
                  }
                  language={i18n.language}
                  insiderToolHelp
                >
                  {t("date_time_update")}
                </Label>
              </Header>
              <Label bold margin={[0, 0, 0, 0]}>
                {insiderListInfo.data.updatedTime}
              </Label>
            </FlexContainer>
            <HelpIcon
              margin={[5, 0, 6, 9]}
              fileName={
                listType === InsiderType.LIST
                  ? "last_updated"
                  : "last_updated_conf"
              }
              language={i18n.language}
              insiderToolHelp
              height={"24"}
              width={"24"}
            />
            {this.props.hasEditAccess ? (
              <EditIcon
                onClick={this.editUpdateTime}
                id={this.props.id + "edit"}
                key={"iconEdit"}
                src={"/icons/ic-edit-black-24-px.svg"}
              />
            ) : (
              ""
            )}
          </FlexContainer>
        </FlexContainer>
        {this.state.showNotifyModal ? (
          <SendReminderModal
            notifyReminder={this.state.notifyReminder}
            templateName={this.state.templateFileName}
            listType={listType}
            key={"sendReminderModal"}
            onClose={() => this.setState({ showNotifyModal: false })}
            onSend={sendFnc => {
              Promise.resolve(this.setState({ loading: true }))
                .then(sendFnc)
                .then(() => this.setState({ loading: false }));
            }}
            initialValues={{
              body_0: this.replaceKeysInString(
                this.state.emailTemplateNo.body[0],
                "nb"
              ),
              body_en_0: this.replaceKeysInString(
                this.state.emailTemplateEn.body[0],
                "en"
              ),
              body_1: "",
              body_en_1: "",
              body_2: this.replaceKeysInString(
                this.state.emailTemplateNo.body[2],
                "nb"
              ),
              body_en_2: this.replaceKeysInString(
                this.state.emailTemplateEn.body[2],
                "en"
              )
            }}
            selectNorwegian={() => {
              this.setState({ norwegianSelected: true });
            }}
            selectEnglish={() => this.setState({ norwegianSelected: false })}
            norwegianSelected={this.state.norwegianSelected}
            emailTemplateNo={this.state.emailTemplateNo}
            emailTemplateEn={this.state.emailTemplateEn}
            insidersToApprove={this.getSelectedInsiders()}
          />
        ) : (
          undefined
        )}
        <Modal
          key={"editUpdateTimeModal"}
          header={t("date_time_update")}
          isOpen={this.state.showEditUpdateTime}
          setHeight={"50%"}
          onClose={() =>
            this.setState({
              showEditUpdateTime: false
            })
          }
        >
          <UpdateDateTime
            onSubmit={this.setUpdatedTime}
            onClose={() => {
              this.setState({
                showEditUpdateTime: false
              });
            }}
            initialValues={{
              update_date: insiderListInfo.data.update_date,
              update_time: insiderListInfo.data.update_time
            }}
            enableReinitialize={true}
            forceUpdate={true}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { form, insiderToolReducer } = state;

  const inHistoricMode = insiderToolReducer.insiderList.historicMode;
  const listActive =
    insiderToolReducer.insiderList.insiderListInfo.data.listState === "active"
      ? true
      : false;

  return {
    insiderTableSelection: form.insiderTableForm
      ? form.insiderTableForm.values
      : undefined,
    insiders: insiderToolReducer.insiderList.insiders,
    insiderListInfo: insiderToolReducer.insiderList.insiderListInfo,
    hasEditAccess:
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode &&
      listActive,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    insiderStateData: insiderToolReducer.insiderList.insiderStateData
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(ButtonRowInsiders)
);
