import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { FlexContainer } from "../components/FlexContainer";
import {PageHeading} from "../components/PageHeading";
import Button from "../components/Button";
import {OverlayLoader} from "../components/Loader";
import {Text} from "./ChatBox";


class ShareIssuanceReceipt extends React.Component {
    render() {
        const { t} = this.props;

        return (
        <FlexContainer column greyBackground padding={[16, 16, 16, 16]}>
            {this.props.loading ? <OverlayLoader /> : undefined}
            <PageHeading>{t("share_receipt_headline")}</PageHeading>

            <FlexContainer>
                <Text>{t("share_receipt_text")} </Text>
            </FlexContainer>

            <FlexContainer>
                <Text>{t("share_receipt_back_text")} </Text>
            </FlexContainer>

            <FlexContainer row flexEnd>
                <Button.Standard margin={[0, 10, 0, 0]}
                                 onClick={this.props.onBack}>
                    {t("back")}
                </Button.Standard>
            </FlexContainer>
        </FlexContainer>
        );
    }
}
export default connect()(
    withTranslation("translations")(ShareIssuanceReceipt)
);
