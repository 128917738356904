import React from "react";
import styled from "styled-components";

const Link = styled.a`
  ${props =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
    ${props => (props.white ? "color: white;" : "")}
    ${props => (props.fontSize ? "font-size: " + props.fontSize + "px;" : "")}
    ${props => (props.noPointer ? "cursor: text;" : "")}
    text-decoration: none;
  display: inherit;
  color: ${props => (props.color ? props.color : "inherit")};
`;

/**
 * Element to wrap around components that should recieve tab focus when navigating with keyboard.
 * @param {*} props
 */
const FocusElement = props => {
  const action = () => (props.onClick != null ? props.onClick() : undefined);
  const attributes = props.noFocus ? ({}["tabIndex"] = "-1") : {};

  return (
    <Link
      className={props.className}
      href="#"
      {...props}
      {...attributes}
      onKeyDown={e => {
        switch (e.keyCode) {
          case 13: //Enter
            e.preventDefault();
            action();
          default:
        }
      }}
      onClick={e => {
        e.preventDefault();
        action();
      }}
    >
      {props.children}
    </Link>
  );
};

export default FocusElement;
