import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";
import {change, destroy, reset, SubmissionError} from "redux-form";
import {connect} from "react-redux";
import CompanyForm, {COMPANY_SELECT} from "./CompanyForm";
import {
  clearContactPerson,
  createCompany,
  createNewContactPerson,
  deleteContactPerson,
  fetchCompany,
  fetchCompanyList,
  fetchContactPerson,
  fetchContactPersonList,
  fetchParameters,
  updateCompany,
  updateContactPerson,
} from "./CompanyInformationActions";

import {fetchCompanyPermission} from "../actions/CommonActions";
import AuditCommitteeForm from "./AuditCommittee";
import Contact from "./Contact";
import CompanyDetailsForm from "./CompanyDetails";
import Address from "./Address";
import Comment from "./Comment";
import {FlexContainer} from "../components/FlexContainer";
import Button from "../components/Button";

import {
  AUDIT_COMMITEE_MEMBER_1,
  AUDIT_COMMITEE_MEMBER_2,
  BOARD_DIRECTOR,
  CEO,
  CFO,
  getLanguage,
  IR,
  LAW_DIRECTOR,
  PRIMARY_BOND,
  PRIMARY_EQUITY,
  SECONDARY_BOND,
  SECONDARY_EQUITY,
} from "../util/common";
import Loader, {OverlayLoader} from "../components/Loader";
import {
  AddressValidator,
  AuditCommitteeValidator,
  CompanyDetailsValidator,
  ContactPersonValidator,
  NewCompanyValidator,
} from "./Validators";

export const COMPANYPAGE = "form";

class CompanyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      detailsValidator: undefined,
      isContactSelected: false,
      isCompanySelected: false,
      isNewCompanySelected: false,

      showDeleteButton: false,
      showEditContactBox: false,
      hideContactBox: false,
      showNewContactBox: false,
      showNewCompanyModal: false,

      openNewAuditCommitteeModal: false,
      openEditAuditMemberModal: false,
      openConfirmModalContact: false,
      openConfirmModalMember: false,

      isCompanyDetailsOpen: false,
      isAddressOpen: false,
      isContactPersonOpen: false,
      isAuditCommitteeOpen: false,
      isCommentOpen: false,

      selectedCompany: null,
      loading: false,
    };

    this.isFieldEditable = this.isFieldEditable.bind(this);
    this.isFieldRequired = this.isFieldRequired.bind(this);
    this.isFieldVisible = this.isFieldVisible.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchCompanyList());
    this.props.dispatch(fetchParameters());
  }

  _setFieldValidationFlag = ({data}) => {
    const {fields} = data.entity != null ? data.entity : {};

    this._doFieldValidation =
      fields != null &&
      fields.requiredFieldsUsers != null &&
      fields.requiredFieldsUsers.length > 0;
    return Promise.resolve();
  };
  doFieldValidation = () => {
    return this._doFieldValidation;
  };

  /*============================================================================================= */
  //                                Validation Functions                                          //
  /*============================================================================================= */
  isFieldRequired(field) {
    const {fields} =
      this.props.parameterList != null ? this.props.parameterList : {};

    if (!this.doFieldValidation()) return false;

    if (fields != null && fields.requiredFieldsUsers) {
      return fields.requiredFieldsUsers.some(
        (f) => f === field || f === "contactPersonRole." + field
      );
    }
  }

  isFieldVisible(field) {
    const {fields} =
      this.props.parameterList != null ? this.props.parameterList : {};

    if (!this.doFieldValidation()) return true;

    if (fields != null && fields.visibleFieldsUsers) {
      return fields.visibleFieldsUsers.some(
        (f) => f === field || f === "contactPersonRole." + field
      );
    }
  }

  isFieldEditable(field) {
    const {fields} =
      this.props.parameterList != null ? this.props.parameterList : {};

    if (!this.doFieldValidation()) return true;

    if (fields != null && fields.editableFieldsUsers) {
      return fields.editableFieldsUsers.some(
        (f) => f === field || f === "contactPersonRole." + field
      );
    }
  }

  render() {
    const {t, base} = this.props;

    const onToggleCompanyDetails = () => {
      this.setState({isCompanyDetailsOpen: !this.state.isCompanyDetailsOpen});
    };
    const openCompanyDetails = () => {
      this.setState({isCompanyDetailsOpen: true});
    };

    const onToggleAddress = () => {
      this.setState({isAddressOpen: !this.state.isAddressOpen});
    };
    const openAddress = () => {
      this.setState({isAddressOpen: true});
    };

    const onToggleContactPersons = () => {
      this.setState({isContactPersonOpen: !this.state.isContactPersonOpen});
    };
    const openContactPersons = () => {
      this.setState({isContactPersonOpen: true});
    };

    const onToggleAuditCommittee = () => {
      this.setState({isAuditCommitteeOpen: !this.state.isAuditCommitteeOpen});
    };
    const onToggleComment = () => {
      this.setState({isCommentOpen: !this.state.isCommentOpen});
    };
    const openAuditCommittee = () => {
      this.setState({isAuditCommitteeOpen: true});
    };

    const closeAllTabs = () => {
      this.setState({isCompanyDetailsOpen: false});
      this.setState({isAddressOpen: false});
      this.setState({isContactPersonOpen: false});
      this.setState({isAuditCommitteeOpen: false});
      this.setState({isCommentOpen: false});
    };

    const editContact = () => {
      this.setState({showEditContactBox: true});
    };

    const newContact = () => {
      this.setState({showNewContactBox: true});
    };

    const onOpenNewCompanyModal = () => {
      this.setState({showNewCompanyModal: true});
    };

    const onOpenNewAuditCommitteeModal = () => {
      this.setState({openNewAuditCommitteeModal: true});
    };
    const onConfirmDeleteContact = () => {
      this.setState({openConfirmModalContact: true});
    };
    const onConfirmDeleteMember = () => {
      this.setState({openConfirmModalMember: true});
    };
    const onEditAuditMemberModal = () => {
      this.setState({openEditAuditMemberModal: true});
    };

    const selectedContact = (value) => {
      if (value.length > 0) {
        this.setState({isContactSelected: true});
      } else {
        this.setState({isContactSelected: false});
      }
    };

    const getContact = (value) => {
      if (value != null) {
        this.props
          .dispatch(fetchContactPerson(value.value))
          .then(() => this.setState({showDeleteButton: true}));
      }
    };

    const getCompany = (company) => {
      if (company && company.value) {
        const cId = company.value;
        this.setState({loading: true, selectedCompany: company}, () =>
          this.props
            .dispatch(fetchParameters(cId))
            .then(this._setFieldValidationFlag)
            .then(
              Promise.all([
                this.props.dispatch(fetchContactPersonList(cId)),
                this.props.dispatch(fetchCompany(cId)),
                this.props.dispatch(fetchCompanyPermission(cId)),
              ])
                .then(() => {
                  this.setState({loading: false, isCompanySelected: true})
                })
                .catch((error) => console.log(error))
            )
        );
      } else {
        this.setState({isCompanySelected: false, selectedCompany: null});
      }
    };

    const newCompany = () => {
      this.setState({isNewCompanySelected: true});
    };

    const createContactObject = (value) => {
      const formatedValue = {};
      formatedValue["phoneNumbers"] = [
        {
          phoneType: "OFFICE",
          phone: value.office != null ? value.office : undefined,
        },
        {
          phoneType: "MOBILE",
          phone: value.mobile != null ? value.mobile : undefined,
        },
      ];
      formatedValue["firstName"] = value.firstName;
      formatedValue["lastName"] = value.lastName;
      formatedValue["email"] = value.email;
      formatedValue["cid"] = this.props.company.cid;

      return formatedValue;
    };

    const destroyContactForms = () => {
      this.props.dispatch(destroy("editAuditModalForm"));
      this.props.dispatch(destroy("contactEditModalForm"));

      this.props.dispatch(destroy("newAuditCommitteeMemberForm"));
      this.props.dispatch(destroy("newContactModalForm"));

      this.props.dispatch(clearContactPerson());
    };

    const showAuditCommittee = () => {
      return this.isFieldVisible("auditMembers");
      // if (this.props.companyPermissions) {
      //   return this.props.companyPermissions.find(
      //     permission =>
      //       permission.cid == this.props.company.cid &&
      //       permission.permissions &&
      //       permission.permissions.some(value =>
      //         value.startsWith("ci.auditCommittee")
      //       )
      //   );
      // }
    };

    const onSubmit = (validator) => (values, _, props) => {
      const errors = validator(values, props);
      return new Promise((resolve, reject) => {
        if (errors) {
          return reject(new SubmissionError(errors));
        }
        else {
          if (!this.doFieldValidation() && values.longName !== undefined && !this.state.isNewCompanySelected && this.state.selectedCompany !== null) {
            this.state.selectedCompany.label = values.longName;
          }
          resolve();
        }
      });
    };

    /*============================================================================================= */
    //                        NEW/UPDATE/DELETE CONTACTPERSONS                                      //
    /*============================================================================================= */

    const newContactPerson = () => {
      const person = createContactObject(this.props.newContactForm);
      person["contactPersonType"] = 1;
      person["title"] = this.props.newContactForm.title;
      return this.props
        .dispatch(createNewContactPerson(person))
        .then(() => this.setState({showNewContactBox: false}))
        .then(() => this.props.dispatch(fetchContactPersonList(this.props.company.cid)))
        .then(() =>
          NotificationManager.success(
            t("contact_person_created"),
            t("success"),
            5000
          )
        );
    };

    const editContactPerson = () => {
      const updatedContactPerson = createContactObject(
        this.props.editContactForm
      );
      updatedContactPerson["id"] = this.props.contactPerson.id;
      updatedContactPerson["contactPersonType"] = 1;
      updatedContactPerson["title"] = this.props.editContactForm.title;

      return this.props
        .dispatch(updateContactPerson(updatedContactPerson))
        .then(() =>
          this.props
            .dispatch(fetchContactPersonList(this.props.company.cid))
            .then(() => destroyContactForms())
            .then(() => this.setState({showEditContactBox: false}))
        )
        .then(() =>
          NotificationManager.success(
            t("contact_person_updated"),
            t("success"),
            5000
          )
        );
    };

    const deleteContact = () => {
      const id = this.props.contactPerson.id;
      const cid = this.props.company.cid;

      return this.props
        .dispatch(deleteContactPerson(id, cid))
        .then(() =>
          this.props
            .dispatch(fetchContactPersonList(this.props.company.cid))
            .then(() => destroyContactForms())
            .then(() =>
              this.setState({
                showEditContactBox: false,
                openEditAuditMemberModal: false,
                openConfirmModalContact: false,
                openConfirmModalMember: false,
              })
            )
            .catch((error) => console.log(error))
        )
        .then(() =>
          NotificationManager.success(t("contact_deleted"), t("success"), 5000)
        );
    };

    const newAuditMember = () => {
      const member = createContactObject(
        this.props.newAuditCommitteeMemberForm
      );
      member["contactPersonType"] = 3;

      return this.props
        .dispatch(createNewContactPerson(member))
        .then(() =>
          this.props
            .dispatch(fetchContactPersonList(this.props.company.cid))
            .then(() => this.setState({openNewAuditCommitteeModal: false}))
            .then(() => destroyContactForms())
        )
        .then(() =>
          NotificationManager.success(
            t("audit_member_created"),
            t("success"),
            5000
          )
        );
    };

    const editAuditMember = () => {
      const member = createContactObject(this.props.editAuditModalForm);
      var form = this.props.editAuditModalForm;
      member["contactPersonType"] = 3;
      member["id"] = form.editContactPerson.value;

      return this.props
        .dispatch(updateContactPerson(member))
        .then(() =>
          this.props
            .dispatch(fetchContactPersonList(this.props.company.cid))
            .then(() => destroyContactForms())
            .then(() => this.setState({openEditAuditMemberModal: false}))
        )
        .then(() =>
          NotificationManager.success(
            t("audit_member_updated"),
            t("success"),
            5000
          )
        );

      //If state is success, update dropdown and close modal
    };

    //get name of contactperson for infomessage
    const getContactPersonName = () => {
      var name = undefined;
      if (this.props.contactPerson != null) {
        name =
          this.props.contactPerson.firstName +
          " " +
          this.props.contactPerson.lastName;
      }
      return name;
    };

    /*============================================================================================= */
    //                                          NEW COMPANY                                         //
    /*============================================================================================= */

    const createCompanyObject = (company) => {
      var formatedValue = {};

      if (company == null) return formatedValue;

      if (company.roles != null) {
        formatedValue["roles"] = [];
        company.roles.map((role) => {
          formatedValue.roles.push(Number(role.value))
        });
      }

      if (company.issuerStatus != null) {
        formatedValue["issuerStatus"] = [];
        company.issuerStatus.map((status) =>
          formatedValue.issuerStatus.push(Number(status.value))
        );
      }

      if (company.shortName != null) {
        formatedValue["shortName"] = company.shortName;
      }
      if (company.longName != null) {
        formatedValue["longName"] = company.longName;
      }
      if (company.leiCode != null) {
        formatedValue["lei"] = company.leiCode;
      }
      if (company.symbol != null) {
        formatedValue["symbol"] = company.symbol;
      }
      if (company.orgno != null) {
        formatedValue["orgno"] = company.orgno;
      }
      return formatedValue;
    };

    const saveNewCompany = () => {
      const handleServerErrors = (error) => {
        this.setState({loading: false, showNewCompanyModal: true});
        if (error.data && error.data.details) {
          if (error.data.details.some((e) => e.code == 1021)) {
            return new SubmissionError({
              leiCode: t("invalid_lei_code"),
            });
          }
        }
        return new SubmissionError({
          validationError: t("invalid_input"),
        });
      };

      var newCompanyObject = createCompanyObject(this.props.newCompanyForm);
      const errors = NewCompanyValidator(newCompanyObject, this.props);
      return new Promise((resolve, reject) => {
        if (errors) {
          return reject(new SubmissionError(errors));
        }

        new Promise((resolve) =>
          this.setState({loading: true, showNewCompanyModal: false}, resolve)
        )
          .then(() => this.props.dispatch(createCompany(newCompanyObject)))
          .then(({data}) => {
            if (data.entity && data.entity.cid) {
              Promise.all([
                this.props.dispatch(fetchCompanyList()),
                this.props.dispatch(fetchContactPersonList(data.entity.cid)),
                this.props.dispatch(fetchCompany(data.entity.cid)),
                this.props.dispatch(fetchCompanyPermission(data.entity.cid)),
                this.props
                  .dispatch(fetchParameters(data.entity.cid))
                  .then(this._setFieldValidationFlag),
              ]).then(() => {
                this.props.dispatch(reset("newCompanyForm"));
                this.props.dispatch(
                  change("newCompanyForm", COMPANY_SELECT, {
                    value: data.entity.cid,
                    label: data.entity.longName,
                  })
                );
              })
            }
          })
          .then(() =>
            resolve(
              this.setState({
                isCompanySelected: true,
                showNewCompanyModal: false,
                loading: false,
              })
            )
          )
          .catch((err) => {
            console.log(err);
            reject(handleServerErrors(err));
          });
      });
    };

    /*============================================================================================= */
    //                                 UPDATE COMPANY                                               //
    /*============================================================================================= */

    //UPDATE COMPANYDETAILS
    const createCompanyDetailsObject = (company) => {
      var formatedValue = {...company};
      if (company.jurisdictionTakeOverRules != null) {
        formatedValue["jurisdictionTakeOverRules"] =
          company.jurisdictionTakeOverRules.value; //Jurisdiksjon for tilbudsregler*:
      }
      if (company.periodicInformation != null) {
        formatedValue["periodicInformation"] =
          company.periodicInformation.value !== "-1" ? company.periodicInformation.value : "ZZ"; //"Hjemstat for periodisk informasjonsplikt*"
      }
      if (company.prospectusInspection != null) {
        formatedValue["prospectusInspection"] =
          company.prospectusInspection.value; //""Hjemstat for prospektkontroll*""
      }
      if (company.jurisdictionDomcicle != null) {
        formatedValue["jurisdictionDomcicle"] =
          company.jurisdictionDomcicle.value; //"Jurisdiksjon (land hvis domsrett anvendes)*"
      }
      if (company.primaryListing != null) {
        formatedValue["primaryListing"] = company.primaryListing.value; // "Markedet hvor selskapets aksjer er primærnotert*"
      }
      if (company.secondaryListing1 != null) {
        formatedValue["secondaryListing1"] = company.secondaryListing1.value; //"Markedet hvor selskapets aksjer er primærnotert (1)"
      }
      if (company.secondaryListing2 != null) {
        formatedValue["secondaryListing2"] = company.secondaryListing2.value; //"Markedet hvor selskapets aksjer er primærnotert (2)"
      }
      if (company.secondaryListing3 != null) {
        formatedValue["secondaryListing3"] = company.secondaryListing3.value; //"Markedet hvor selskapets aksjer er primærnotert (3)"
      }
      if (company.secondaryListing4 != null) {
        formatedValue["secondaryListing4"] = company.secondaryListing4.value; //"Markedet hvor selskapets aksjer er primærnotert (4)"
      }
      if (company.accountingStandard != null) {
        formatedValue["accountingStandard"] = company.accountingStandard.value; //Regnskapsstandard*:
      }
      if (company.country != null) {
        formatedValue["country"] = company.country.value; //land til isin*:
      }
      if (company.regAsGroup != null) {
        formatedValue["regAsGroup"] = Number(company.regAsGroup); //konsern*:
      }
      if (company.crmIntegrated != null) {
        formatedValue["crmIntegrated"] = Number(company.crmIntegrated); //crmintegrasjon*:
      }
      if (company.otherExchange != null) {
        formatedValue["otherExchange"] = Number(company.otherExchange); //otherExchange*:
      }
      if (company.lei != null) {
        if (company.lei.length <= 0) {
          formatedValue["lei"] = null;
        } else {
          formatedValue["lei"] = company.lei;
        }
      } //LeiCode
      if (
        company.issuerStatus != null &&
        company.issuerStatus !== this.props.company.issuerStatus
      ) {
        let issuerStatusObject = [];
        company.issuerStatus.map((value) => {
          issuerStatusObject.push(
            Number(value.value != null ? value.value : value)
          );
        });
        formatedValue["issuerStatus"] = issuerStatusObject;
      }
      if (company.roles != null) {
        let rolesList = [];
        company.roles.map((value) => {
          rolesList.push(Number(value.value != null ? value.value : value));
        });
        formatedValue["roles"] = rolesList;
      }
      if (company.newsAgreement != null)
        formatedValue["newsAgreement"] = company.newsAgreement.value;
      //"Nyhetsavtale"

      return formatedValue;
    };

    //INITIALVALUES ADDRESS
    //TODO: should be a function
    //const decodeAddressObject =()=> {

    const initAddresses = {};
    const companyAddress = this.props.company.addresses;
    if (companyAddress != null) {
      companyAddress.forEach((address) => {
        initAddresses[address.type + "_address1"] = address.address1;
        initAddresses[address.type + "_address2"] = address.address2;
        initAddresses[address.type + "_city"] = address.city;
        initAddresses[address.type + "_ctry"] = address.ctry;
        initAddresses[address.type + "_pcode"] = address.pcode;
        initAddresses[address.type + "_foreign_postal"] = address.address4;
      });
    }

    //}

    ////////////////
    //UPDATE ADDRESS
    ////////////////

    const encodeAddressObject = (address) => {
      const createAddressObject = (type) => {
        var addressObject = {};
        addressObject["address1"] = address[type + "_address1"];
        addressObject["address2"] = address[type + "_address2"];
        addressObject["city"] = address[type + "_city"];
        if (
          address[type + "_ctry"] != null &&
          address[type + "_ctry"].value != null
        ) {
          addressObject["ctry"] = address[type + "_ctry"].value;
        } else {
          addressObject["ctry"] = address[type + "_ctry"];
        }

        addressObject["pcode"] = address[type + "_pcode"];
        addressObject["address4"] = address[type + "_foreign_postal"];
        addressObject["type"] = type;
        return addressObject;
      };

      if (address != null) {
        const addrTypes = [
          {type: "1"},
          {type: "2"},
          {type: "3"},
          {type: "4"},
        ];
        return addrTypes.map((addrType) => createAddressObject(addrType.type));
      }
    };

    ///////////////////////////
    //UPDATE CONTACTPERSON ROLE
    ///////////////////////////

    /*NEW PERSON MAPPING: */
    const createContactPersonRoleObject = (value) => {
      if (this.props.isContactPersonListFetched && this.props.isParamsFetched && value != null) {
        let output = {};
          this.props.parameterList.contactPersonRoles.forEach((role, index) => {
            let sc = value[(index + 1) + "selectedContact"];
            if (sc !== undefined && sc !== null)  {
              if (output[sc.value] === undefined) {
                output[sc.value] = [];
                output[sc.value].push((index + 1));
              } else {
                output[sc.value].push((index + 1));
              }
            }
          });
        return output;
      }
    };

    const roleFiltering = (value) => {
      let out = {};
      //role 7 -> accountant, role 12,13 audit member 1 and 2
      if (value != null) {
        if (value["12selectedContact"] != null) {
          out["12selectedContact"] = value["12selectedContact"];
        }
        if (value["13selectedContact"] != null) {
          out["13selectedContact"] = value["13selectedContact"];
        }
        if (value["7selectedContact"] != null) {
          out["7selectedContact"] = value["7selectedContact"];
        }
      }
      return out;
    };

    ////////////////
    //UPDATE ALL
    ////////////////
    const submitCompanyData = () => {
      //this.setState({loading: true});

      Promise.all([
        this.companyDetails.submit().then((error) => {
          if (error) {
            openCompanyDetails();
            throw error;
          } else Promise.resolve();
        }),

        this.address.submit().then((error) => {
          if (error) {
            openAddress();
            throw error;
          } else Promise.resolve();
        }),

        this.contact.submit().then((error) => {
          if (error) {
            openContactPersons();
            throw error;
          } else Promise.resolve();
        }),

        this.auditCommittee != null
          ? this.auditCommittee.submit().then((error) => {
            if (error) {
              openAuditCommittee();
              throw error;
            } else Promise.resolve();
          })
          : Promise.resolve(),
      ])
        .then(() => updateAllCompanyFields())
        .then(() => {
          this.setState({overlayLoading: false}, () =>
            NotificationManager.success(
              t("company_details_saved"),
              t("success"),
              5000
            )
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({overlayLoading: false});
        });
    };

    const updateAllCompanyFields = () => {
      const auditFormDropdownValuesExtractor = (values) =>
        values != null
          ? Object.keys(values)
            .filter(
              (key) =>
                key.match(/collectively|electedBy|establishedBy/g) != null
            )
            .reduce(
              (acc, key) => ({
                ...acc,
                [key]: values[key] ? values[key].value : null,
              }),
              {}
            )
          : {};

      var addressObject = encodeAddressObject(this.props.addressForm);
      var updateCompanyObject = createCompanyDetailsObject(
        this.props.companyDetailsForm
      );
      var contactRolesObject = createContactPersonRoleObject(
        this.props.contactTableForm
      );
      var auditDropdownValues = auditFormDropdownValuesExtractor(
        this.props.auditMainForm
      );
      var tempAuditObject = roleFiltering(this.props.auditMainForm);
      var auditRoleObject = createContactPersonRoleObject(tempAuditObject);

      var compObject = this.props.company;

      compObject = {
        ...updateCompanyObject,
        addresses: addressObject,
        contactPersonRoles: {
          ...contactRolesObject,
          ...auditRoleObject,
        },
        ...auditDropdownValues,
        ...this.props.commentForm,
      };

      const onUpdateSuccess = () => {
        this.props.dispatch(fetchCompany(this.props.company.cid));
        this.props.dispatch(fetchContactPersonList(this.props.company.cid));
      };

      return this.props.dispatch(updateCompany(compObject))
          .then(() => onUpdateSuccess())
          .then(() => closeAllTabs());
    };

    const contactRoleIds = [
      CEO,
      CFO,
      BOARD_DIRECTOR,
      IR,
      LAW_DIRECTOR,
      PRIMARY_EQUITY,
      SECONDARY_EQUITY,
      PRIMARY_BOND,
      SECONDARY_BOND,
    ];
    const auditMemberRoles = [AUDIT_COMMITEE_MEMBER_1, AUDIT_COMMITEE_MEMBER_2];

    var contactPersonInitVal = {};
    var auditFormInitVal = {};
    if (this.props.isContactPersonListFetched && this.props.isParamsFetched) {
      /*NEW PERSON MAPPING: */
      var personMap = new Map();
      this.props.contactPersonList.forEach((person) => {
        personMap.set(person.id, person);
      });

      if (this.props.isCompanyFetched) {
        const contactPersonInitValRecord = (contactPerson, roleId) => {
          var record = {};
          record[roleId + "lastName"] = contactPerson.lastName;
          record[roleId + "firstName"] = contactPerson.firstName;
          record[roleId + "email"] = contactPerson.email;
          record[roleId + "id"] = contactPerson.id;
          record[roleId + "selectedContact"] = {
            label: contactPerson.firstName + " " + contactPerson.lastName,
            value: contactPerson.id,
          };
          record[roleId + "mobile"] = contactPerson.phoneNumbers[0].phone;
          record[roleId + "office"] = contactPerson.phoneNumbers[1].phone;
          record[roleId + "title"] = contactPerson.title;

          return record;
        };
        const auditFormInitValRecord = (contactPerson, roleId) => {
          var record = {};
          record[roleId + "selectedContact"] = {
            label: contactPerson.firstName + " " + contactPerson.lastName,
            value: contactPerson.id,
          };
          record[roleId + "email"] = contactPerson.email;

          return record;
        };

        const createInitValObject = (recordFactory, roleIds) => {
          var arr = [];
          Object.keys(this.props.company.contactPersonRoles).forEach((pid) => {
            if (this.props.company.contactPersonRoles[pid] != null) {
              arr = arr.concat(
                this.props.company.contactPersonRoles[pid]
                  .filter(
                    (roleId) =>
                      roleIds.includes(roleId) &&
                      personMap.get(Number(pid)) != null
                  )
                  .map((roleId) => {
                    const contactPerson = personMap.get(Number(pid));
                    return recordFactory(contactPerson, roleId);
                  })
              );
            }
          });

          //loop over all roles so that they will be listed, even though there arent any listed in that position
          if (
            this.props.parameterList &&
            this.props.parameterList.contactPersonRoles
          ) {
            arr = arr.concat(
              this.props.parameterList.contactPersonRoles
                .filter((role) => roleIds.includes(Number(role.id)))
                .map((role) => {
                  var obj = {};
                  obj[role.id + "role"] = role["name" + getLanguage()];
                  return obj;
                })
            );
          }

          return arr.reduce((acc, val) => ({...acc, ...val}), {});
        };

        contactPersonInitVal = createInitValObject(
          contactPersonInitValRecord,
          contactRoleIds
        );
        auditFormInitVal = createInitValObject(
          auditFormInitValRecord,
          auditMemberRoles
        );
      }
    }

    //COMPANY DETAILS OPTIONS
    var parameterOptions = {};
    const paramList = this.props.parameterList;

    if (paramList != null) {
      Object.keys(paramList).forEach((paramName) => {
        if (paramList[paramName].map != null)
          parameterOptions[paramName] = paramList[paramName] //.filter((p)=> p.id == 1)
            .map((params) => {
              return {
                label: params["name" + getLanguage()].trim(),
                value: params.id,
              };
            });
      });
    }

    //COMPANY DETAILS INITIALVALUES:
    if (
      this.props.company &&
      paramList &&
      parameterOptions &&
      parameterOptions.exchanges
    ) {
      var initCompDetails = {};
      initCompDetails["primaryListing"] = parameterOptions.exchanges.filter(
        (p) => p.value == this.props.company.primaryListing
      )[0];
      initCompDetails["secondaryListing1"] = parameterOptions.exchanges.filter(
        (p) => p.value == this.props.company.secondaryListing1
      )[0];
      initCompDetails["secondaryListing2"] = parameterOptions.exchanges.filter(
        (p) => p.value == this.props.company.secondaryListing2
      )[0];
      initCompDetails["secondaryListing3"] = parameterOptions.exchanges.filter(
        (p) => p.value == this.props.company.secondaryListing3
      )[0];
      initCompDetails["secondaryListing4"] = parameterOptions.exchanges.filter(
        (p) => p.value == this.props.company.secondaryListing4
      )[0];
      initCompDetails["accountingStandard"] =
        parameterOptions.accountingStandardTypes.filter(
          (p) => p.value == this.props.company.accountingStandard
        )[0];
      initCompDetails["prospectusInspection"] =
        parameterOptions.countries.filter(
          (p) => p.value == this.props.company.prospectusInspection
        )[0];
      initCompDetails["jurisdictionTakeOverRules"] =
        parameterOptions.jurisdictionTypes.filter(
          (p) => p.value == this.props.company.jurisdictionTakeOverRules
        )[0];

      // Using ZZ as workaround for selected "Not applicable" to distinguish when actively selected
      initCompDetails["periodicInformation"] =
          this.props.company.periodicInformation !== null ?
              this.props.company.periodicInformation === "ZZ" ?
                  {value: "-1", label: t("not_applicable")}
                  : parameterOptions.countries.filter(
                    (p) => p.value == this.props.company.periodicInformation
                  )[0] : null;
      initCompDetails["jurisdictionDomcicle"] =
        this.props.company.jurisdictionDomcicle != null
          ? this.props.company.jurisdictionDomcicle === "-1"
            ? {value: "-1", label: t("not_applicable")}
            : parameterOptions.countries.filter(
              (p) => p.value == this.props.company.jurisdictionDomcicle
            )[0]
          : null;

      initCompDetails["country"] = parameterOptions.countries.filter(
        (p) => p.value == this.props.company.country
      )[0];
      initCompDetails["newsAgreementTypes"] =
        parameterOptions.newsAgreementTypes.filter(
          (p) => p.value == this.props.company.newsAgreement
        )[0];
      initCompDetails["newsAgreement"] =
        parameterOptions.newsAgreementTypes.filter(
          (p) => p.value == this.props.company.newsAgreement
        )[0];

      //initval companyRole
      var roles = [];
      if (this.props.company.roles != null) {
        this.props.company.roles.forEach((value) => {
          roles.push(Number(value.id));
        });
      }
      initCompDetails["companyRoleTypes"] =
        parameterOptions.companyRoleTypes.filter(
          (p) => p.id == this.props.company.roles
        )[0];

      this.props.company.regAsGroup !== true
        ? (initCompDetails["regAsGroup"] = "0")
        : (initCompDetails["regAsGroup"] = "1");

      this.props.company.crmIntegrated !== true
        ? (initCompDetails["crmIntegrated"] = "0")
        : (initCompDetails["crmIntegrated"] = "1");

      this.props.company.otherExchange !== true
        ? (initCompDetails["otherExchange"] = "0")
        : (initCompDetails["otherExchange"] = "1");
    }

    /*EDIT CONTACT*/
    const editContactPersonInitVal = {};
    if (this.props.contactPerson != null) {
      editContactPersonInitVal["mobile"] =
        this.props.contactPerson.phoneNumbers[0].phone;
      editContactPersonInitVal["office"] =
        this.props.contactPerson.phoneNumbers[1].phone;
      editContactPersonInitVal["firstName"] =
        this.props.contactPerson.firstName;
      editContactPersonInitVal["lastName"] = this.props.contactPerson.lastName;
      editContactPersonInitVal["email"] = this.props.contactPerson.email;
      editContactPersonInitVal["title"] = this.props.contactPerson.title;
      editContactPersonInitVal.editContactPerson = {
        label:
          this.props.contactPerson.firstName +
          " " +
          this.props.contactPerson.lastName,
        value: this.props.contactPerson.id,
      };
    }

    /*============================================================================================= */
    //                                AUDIT COMMITTEE INITIALVALUES                                 //
    /*============================================================================================= */
    var initialAuditCommitteeValues = {};
    if (
      this.props.company &&
      paramList &&
      parameterOptions &&
      parameterOptions.auditCommitteeEstablishedByTypes
    ) {
      initialAuditCommitteeValues["establishedBy"] =
        parameterOptions.auditCommitteeEstablishedByTypes.filter(
          (p) => p.value == this.props.company.establishedBy
        )[0] || "";
      initialAuditCommitteeValues["electedBy"] =
        parameterOptions.auditCommitteeElectedByTypes.filter(
          (p) => p.value == this.props.company.electedBy
        )[0] || "";
      initialAuditCommitteeValues["collectively"] =
        parameterOptions.auditCommitteeWorkingCollectivelyTypes.filter(
          (p) => p.value == this.props.company.collectively
        )[0] || "";
    }


    return (
      <FlexContainer>
        <CompanyForm
          companyList={this.props.companyList.map((elm) => {
            return {
              label: elm.longName,
              value: elm.cid,
              helpText: elm.symbol,
            };
          })}
          isCompanySelected={this.state.isCompanySelected}
          onCloseNewCompanyModal={() => {
            this.setState({showNewCompanyModal: false});
            this.props.dispatch(destroy("newCompanyForm"));
          }}
          onCompanySelect={getCompany}
          initalCompanyValue={
            this.props.company.cid != null
              ? {
                label: this.props.company.longName,
                value: this.props.company.cid,
              }
              : undefined
          }
          companyRoleTypes={parameterOptions.companyRoleTypes || []}
          issuerStatusTypes={parameterOptions.issuerStatusTypes || []}
          t={this.props.t}
          onSubmit={() => this.props.history.push("/")}
          onCancel={() => this.props.history.push("/")}
          onOpenNewCompanyModal={onOpenNewCompanyModal}
          showNewCompanyModal={this.state.showNewCompanyModal}
          saveNewCompany={saveNewCompany}
        />
        {this.state.isCompanySelected && !this.state.loading ? (
          <FlexContainer>
            <CompanyDetailsForm
              ref={(x) => {
                if (x) this.companyDetails = x;
              }}
              onSubmit={onSubmit(CompanyDetailsValidator)}
              onToggleCompanyDetails={onToggleCompanyDetails}
              isCompanyDetailsOpen={this.state.isCompanyDetailsOpen}
              enableReinitialize={true}
              isNewCompanySelected={this.state.isNewCompanySelected}
              companyDetails={this.props.company}
              initialValues={{
                ...this.props.company,
                ...initCompDetails,
              }}
              newCompany={newCompany}
              t={this.props.t}
              onGroup={() => {
                this.setState({currentTime: true});
              }}
              onNoGroup={() => {
                this.setState({currentTime: false});
              }}
              {...parameterOptions}
              paramOptions={parameterOptions}
              isEditable={this.isFieldEditable}
              isRequired={this.isFieldRequired}
              isVisible={this.isFieldVisible}
              logoSize={
                this.props.parameterList != null
                  ? this.props.parameterList.maxLogoSize / 1000
                  : 0
              }
            />

            <Address
              ref={(x) => {
                this.address = x;
              }}
              onSubmit={onSubmit(AddressValidator)}
              t={this.props.t}
              onToggleAddress={onToggleAddress}
              isAddressOpen={this.state.isAddressOpen}
              {...parameterOptions}
              initialValues={{
                ...initAddresses,
              }}
              isEditable={this.isFieldEditable}
              isRequired={this.isFieldRequired}
              isVisible={this.isFieldVisible}
            />

            <Contact
              ref={(x) => (this.contact = x)}
              onSubmit={onSubmit(ContactPersonValidator)}
              onToggleContactPersons={onToggleContactPersons}
              isContactPersonOpen={this.state.isContactPersonOpen}
              isContactSelected={this.state.isContactSelected}
              selectedContact={selectedContact}
              t={this.props.t}
              roleList={contactRoleIds}
              contactOptions={this.props.contactPersonList
                .filter((elm) => elm.contactPersonType === 1)
                .sort((a, b) =>
                  a.lastName
                    .toLowerCase()
                    .localeCompare(b.lastName.toLowerCase())
                )
                .map((elm) => {
                  return {
                    label: elm.firstName + " " + elm.lastName,
                    value: elm.id,
                  };
                })}
              contactPersonList={this.props.contactPersonList}
              getContact={getContact}
              initialValues={{...contactPersonInitVal}}
              {...parameterOptions}
              /*New ContactPerson*/

              showNewContactBox={this.state.showNewContactBox}
              newContactPerson={newContactPerson}
              onSubmitNewContact={() => {
                return newContactPerson();
              }}
              onCloseNewContactModal={(formName) => {
                destroyContactForms();
                this.setState({showNewContactBox: false});
              }}
              onNewContact={newContact}
              /*Edit Contactperson */
              showEditContactBox={this.state.showEditContactBox}
              initialContactPerson={{...editContactPersonInitVal}}
              editContactPerson={editContactPerson}
              onCloseEditContactModal={(formName) => {
                destroyContactForms();
                this.setState({
                  showEditContactBox: false,
                  showDeleteButton: false,
                });
              }}
              onEditContact={editContact}
              onSubmitEditContact={() => {
                return editContactPerson();
              }}
              deleteContact={deleteContact}
              onCloseConfirmModal={(formName) => {
                this.setState({openConfirmModalContact: false});
              }}
              openConfirmModalContact={this.state.openConfirmModalContact}
              onConfirmDeleteContact={onConfirmDeleteContact}
              contactInfo={getContactPersonName()}
              showDeleteButton={this.state.showDeleteButton}
              company={this.props.company}
              isEditable={this.isFieldEditable}
              isRequired={this.isFieldRequired}
              isVisible={this.isFieldVisible}
            />

            {showAuditCommittee() ? (
              <AuditCommitteeForm
                ref={(x) => (this.auditCommittee = x)}
                onSubmit={onSubmit(AuditCommitteeValidator)}
                onToggleAuditCommittee={onToggleAuditCommittee}
                isAuditCommitteeOpen={this.state.isAuditCommitteeOpen}
                roleList={auditMemberRoles}
                t={this.props.t}
                getContact={getContact}
                contactPersonList={this.props.contactPersonList}
                contactOptions={this.props.contactPersonList
                  .filter((elm) => elm.contactPersonType === 3)
                  .map((elm) => {
                    return {
                      label: elm.firstName + " " + elm.lastName,
                      value: elm.id,
                    };
                  })}
                editAuditMember={editAuditMember}
                initialAuditCommitteeValues={initialAuditCommitteeValues}
                initVal={auditFormInitVal}
                initialAuditMember={editContactPersonInitVal}
                parameterOptions={parameterOptions}
                onOpenNewAuditCommitteeModal={onOpenNewAuditCommitteeModal}
                openNewAuditCommitteeModal={
                  this.state.openNewAuditCommitteeModal
                }
                onCloseNewAuditCommitteeModal={(formName) => {
                  destroyContactForms();
                  this.setState({openNewAuditCommitteeModal: false});
                }}
                openEditAuditMemberModal={this.state.openEditAuditMemberModal}
                onEditAuditMemberModal={onEditAuditMemberModal}
                onCloseEditAuditCommitteeModal={() => {
                  destroyContactForms();
                  this.setState({
                    openEditAuditMemberModal: false,
                    showDeleteButton: false,
                  });
                }}
                newAuditMember={newAuditMember}
                onSubmitNew={() => {
                  return newAuditMember();
                }}
                onSubmitEdit={() => {
                  return editAuditMember();
                }}
                cid={this.props.company.cid}
                deleteContact={deleteContact}
                onCloseConfirmModal={() => {
                  this.setState({openConfirmModalMember: false});
                }}
                openConfirmModalMember={this.state.openConfirmModalMember}
                onConfirmDeleteMember={onConfirmDeleteMember}
                contactInfo={getContactPersonName()}
                showDeleteButton={this.state.showDeleteButton}
                isEditable={this.isFieldEditable}
                isRequired={this.isFieldRequired}
                isVisible={this.isFieldVisible}
              />
            ) : (
              ""
            )}

            <Comment
              t={this.props.t}
              onToggleComment={onToggleComment}
              isCommentOpen={this.state.isCommentOpen}
              initialValues={{
                comments: this.props.company ? this.props.company.comments : "",
              }}
              enableReinitialize={true}
            />

            <FlexContainer row vAlignCenter flexEnd margin={[24, 0, 24, 0]}>
              <Button.Standard
                margin={[0, 16, 0, 0]}
                inactive={this.state.loading}
                onClick={() =>
                  this.state.loading
                    ? undefined
                    : this.setState({overlayLoading: true}, submitCompanyData)
                }
              >
                {t("save")}
              </Button.Standard>
              <Button.Text onClick={() => this.props.history.push(`${base}`)}>
                {" "}
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        ) : (
          ""
        )}
        {this.state.loading ? <Loader/> : ""}
        {this.state.overlayLoading ? <OverlayLoader/> : ""}
      </FlexContainer>
    );
  }
}

CompanyContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    form,
    companyInformationReducer,
    companyPermissionReducer,
    permissionReducer,
  } = state;

  return {
    /*COMMON*/
    parameterList: companyInformationReducer.parameters.list,

    /*Permission*/
    globalCompanyPermissions:
      companyPermissionReducer.globalCompanyPermissions || [],
    companyPermissions: companyPermissionReducer.companyPermissions || [],
    globalPermissions: permissionReducer.permissions || [],

    /*CONTACTPERSONS*/
    contactPersonList: companyInformationReducer.contactPersonList.list,
    contactPerson: companyInformationReducer.getContactPerson.contact,

    /*COMPANYDETAILS*/

    companyList: companyInformationReducer.companyList.list,
    company: companyInformationReducer.getCompany.list,

    /*FORMS */

    editContactForm:
      form.contactEditModalForm != null
        ? form.contactEditModalForm.values
        : undefined,
    newContactForm:
      form.newContactModalForm != null
        ? form.newContactModalForm.values
        : undefined,
    contactTableForm:
      form.contactTableForm != null ? form.contactTableForm.values : undefined,

    newCompanyForm:
      form.newCompanyForm != null ? form.newCompanyForm.values : undefined,
    companyDetailsForm:
      form.companyDetailsForm != null
        ? form.companyDetailsForm.values
        : undefined,

    addressForm: form.addressForm != null ? form.addressForm.values : undefined,
    commentForm: form.commentForm != null ? form.commentForm.values : undefined,

    editAuditModalForm:
      form.editAuditModalForm != null
        ? form.editAuditModalForm.values
        : undefined,
    newAuditCommitteeMemberForm:
      form.newAuditCommitteeMemberForm != null
        ? form.newAuditCommitteeMemberForm.values
        : undefined,
    auditMainForm:
      form.auditMainForm != null ? form.auditMainForm.values : undefined,

    //IS FETCHED:
    isCompanyFetched: companyInformationReducer.getCompany.isFetched,
    isParamsFetched: companyInformationReducer.parameters.isFetched,
    isContactPersonFetched: companyInformationReducer.getContactPerson.isFetched,
    isContactPersonListFetched: companyInformationReducer.contactPersonList.isFetched,

    //IS CREATED,
    isContactPersonCreated: companyInformationReducer.contactPerson.isCreated,
    isCompanyCreated: companyInformationReducer.company.isCreated,

    //IS UPDATED
    isContactPersonUpdated: companyInformationReducer.contactPerson.isUpdated,
    isCompanyUpdated: companyInformationReducer.company.isUpdated,
  };
}

export default connect(
  mapStateToProps,
  null
)(withTranslation("translations")(CompanyContainer));
