import React from "react";
import styled from "styled-components";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../../components/FlexContainer";
import {
  DateBox,
  InputBox,
  TimePickerBox,
  TextAreaBox,
  NoLabelInputBox,
  DropDownBox,
  TextBox
} from "../components";
import Button from "../../components/Button";
import CheckBox from "../../components/CheckBox";
import { InsiderType } from "../InsiderToolActions";
import Label from "../../components/Label";

import { getUtcDateTimeLabelFromFormValues } from "../UTCUtils";

const Header = styled.span`
  font-size: 18px;
  font-weight: 600;

  margin: 40px 0 0 0;
`;

const Text = styled.span`
  white-space: pre-line;
`;

class InsiderApproveFormPhone extends React.Component {
  render() {
    const {
      t,
      handleSubmit,
      emailDate,
      projectName,
      submitting,
      invalid,
      listType,
      countryOptions
    } = this.props;
    // width={(window.innerWidth - 40 + "px")}
    return (
      <FlexContainer column margin={[0, 10, 20, 10]}>
        <h1>
          {t(
            listType === InsiderType.LIST
              ? "it_approve_header"
              : "it_approve_header_conf"
          )}
        </h1>
        <Text>
          {t(
            listType === InsiderType.LIST
              ? "it_approve_text"
              : "it_approve_text_conf",
            { project: projectName, date: emailDate }
          )}
        </Text>

        <form onSubmit={handleSubmit} autoComplete="off">
          <FlexContainer column>
            <Header>{t("it_personalia")}</Header>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="first_name"
                label={t("first_name")}
              />
              <Field component={InputBox} name="surname" label={t("surname")} />
            </FlexContainer>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="birth_surname"
                label={t("it_birth_surname")}
              />
              <Field component={InputBox} name="email" label={t("email")} />
            </FlexContainer>
            <FlexContainer row margin={[0, 0, 5, 0]}>
              <FlexContainer column margin={[0, 8, 0, 0]}>
                <Field
                  component={InputBox}
                  name="phone_private"
                  label={t("it_phone_private")}
                />
                <Field
                  component={NoLabelInputBox}
                  name="phone_private_2"
                  margin={[5, 0, 0, 0]}
                />
              </FlexContainer>
              <FlexContainer column margin={[0, 8, 0, 0]}>
                <Field
                  component={InputBox}
                  name="phone_work"
                  label={t("it_phone_work")}
                />
                <Field
                  component={NoLabelInputBox}
                  name={"phone_work_2"}
                  margin={[5, 0, 0, 0]}
                />
              </FlexContainer>
            </FlexContainer>
            <FlexContainer row>
              <Field
                component={DateBox}
                name="date_of_birth"
                border
                label={t("it_date_of_birth")}
                numPreviousYears={100}
              />
            </FlexContainer>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="idnumber"
                label={t("it_idnumber")}
              />
            </FlexContainer>
            <FlexContainer row>
              <Field component={InputBox} name="address" label={t("address")} />
              <Field
                component={InputBox}
                name="post_code"
                label={t("it_post_code")}
              />
            </FlexContainer>
            <FlexContainer row>
              <Field component={InputBox} name="city" label={t("it_city")} />
              <Field
                component={DropDownBox}
                name="country"
                label={t("country")}
                t={t}
                options={countryOptions}
                searchable={true}
                placeholder={this.props.t("select")}
              />
            </FlexContainer>

            <Label margin={[24, 0, 0, 0]} bold>
              {t("information_regarding_issuer")}
            </Label>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="company_name"
                label={t("it_company_name")}
              />
            </FlexContainer>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="insider_function_and_reason"
                label={t(
                  listType === InsiderType.LIST
                    ? "it_insider_function_and_reason"
                    : "it_insider_function_and_reason_conf"
                )}
              />
            </FlexContainer>
            <FlexContainer margin={[12, 0, 0, 0]}>
              <Label maxWidth>{t("it_company_address")}</Label>
              <FlexContainer row>
                <Field
                  component={InputBox}
                  name="company_address"
                  label={t("it_company_street_name_and_number")}
                />
                <Field
                  component={InputBox}
                  name="company_post_code"
                  label={t("it_post_code")}
                />
              </FlexContainer>
              <FlexContainer row>
                <Field
                  component={InputBox}
                  name="company_city"
                  label={t("it_city")}
                />
                <Field
                  component={DropDownBox}
                  name="company_country"
                  label={t("country")}
                  t={t}
                  options={countryOptions}
                  searchable={true}
                  placeholder={this.props.t("select")}
                />
              </FlexContainer>
            </FlexContainer>

            {/*<FlexContainer row margin={[16, 0, 20, 0]}>*/}
            <FlexContainer column margin={[16, 0, 0, 0]}>
              <Label margin={[24, 0, 0, 0]} bold>
                {listType === InsiderType.LIST
                  ? t("it_obtained_insider_info")
                  : t("it_obtained_conf_info")}
              </Label>
              <FlexContainer row>
                <Field
                  component={
                    this.props.insider.data.obtained ? TextBox : DateBox
                  }
                  border
                  name="obtained_date"
                  label={
                    t("date") +
                    " " +
                    getUtcDateTimeLabelFromFormValues(
                      this.props.insiderFormValues,
                      "obtained_date",
                      "obtained_time"
                    )
                  }
                />
              </FlexContainer>
              <FlexContainer row>
                <Field
                  component={
                    this.props.insider.data.obtained ? TextBox : TimePickerBox
                  }
                  name="obtained_time"
                  label={t("time")}
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer column padding={[0, 8, 0, 0]}>
              <Label margin={[16, 0, 0, 0]} bold>
                {t("comment")}
              </Label>
              <Field
                component={TextAreaBox}
                border
                name="comment"
                label={t("it_write_comment")}
                noResize
              />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer row margin={[20, 0, 0, 0]}>
            <FlexContainer>
              <Text>{t("it_approve_accept_header")}</Text>
              {listType === InsiderType.LIST ? (
                <FlexContainer row margin={[20, 0, 0, 0]}>
                  <Field
                    component={CheckBox}
                    name="regulatory_compliance"
                    label={t("it_regulatory_compliance")}
                  />
                </FlexContainer>
              ) : null}
              <FlexContainer row margin={[20, 0, 20, 0]}>
                <Field
                  component={CheckBox}
                  name="correct_information"
                  label={t("it_correct_information")}
                />
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
          <Button.Form type="submit" inactive={invalid || submitting}>
            {t("it_approve_and_return")}
          </Button.Form>
        </form>
      </FlexContainer>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  if (!values.regulatory_compliance && props.listType === InsiderType.LIST) {
    errors.regulatory_compliance = "Required";
  }
  if (!values.correct_information) {
    errors.correct_information = "Required";
  }
  return errors;
};

export default reduxForm({
  validate,
  form: "insiderApproveForm",
  enableReinitialize: true
})(InsiderApproveFormPhone);
