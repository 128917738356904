import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import SimpleFileUploader from "../../../components/SimpleFileUploader";

export class ImportExcelInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openHelp: false
    };
  }

  render() {
    const { t, handleSubmit, onSubmit } = this.props;

    const toggleHelp = () => {
      this.setState({ openHelp: !this.state.openHelp });
    };

    return (
      <FlexContainer>
        <FlexContainer margin={[0, 0, 16, 0]}>
          {this.props.listType === this.props.insiderType.LIST
            ? t("it_import_from_excel_info_list_1")
            : t("it_import_from_excel_info_conf_list_1")}
        </FlexContainer>
        <FlexContainer vAlignCenter>
          <table style={{ width: "100%" }} border="1">
            <thead>
              <tr>
                <th />
                <th>A</th>
                <th>B</th>
                <th>C</th>
                <th>D</th>
                <th>E</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold" }}>1</td>
                <td>Example name</td>
                <td>Example lastname</td>
                <td>Example email</td>
                <td>Example phone</td>
                <td>Example obtained time</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>2</td>
                <td>Example name</td>
                <td>Example lastname</td>
                <td>Example email</td>
                <td>Example phone</td>
                <td>Example obtained time</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold" }}>3</td>
                <td>John</td>
                <td>Doe</td>
                <td>john@doe.com</td>
                <td>'+47 12345678</td>
                <td>'2019-01-01 08:00</td>
              </tr>
            </tbody>
          </table>
        </FlexContainer>
        <FlexContainer margin={[16, 0, 0, 0]}>
          {this.props.listType === this.props.insiderType.LIST
            ? t("it_import_from_excel_info_list_2")
            : ""}
        </FlexContainer>
        <FlexContainer margin={[36, 0, 36, 0]} vAlignCenter>
          <SimpleFileUploader
            uploadFile={file => this.props.uploadExcelFile(file)}
            fileValidator={file => undefined}
          >
            {t("upload_file")}
          </SimpleFileUploader>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default ImportExcelInfo;
