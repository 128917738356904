import {AuditlogDetails, AuditlogEvents, AuditlogList} from "./AuditlogActions";
import { combineReducers } from "redux";

const initialAuditlogListState = {
  auditlog: [],
  error: undefined,
  isFetched: false,
};

export const auditlogListReducer = (
  state = {
    ...initialAuditlogListState,
  },
  action
) => {
  switch (action.type) {
    case AuditlogList.postSuccess:
      return Object.assign({}, state, {
        auditlog: action.data.data.entity,
        isFetched: true,
      });
    case AuditlogList.postFail:
      return Object.assign({}, state, {
        auditlog: [],
        error: action.error,
      });
    default:
      return state;
  }
};

const initialAuditlogDetailsState = {
  details: [],
  error: undefined,
};

export const auditlogDetailsReducer = (
    state = {
      ...initialAuditlogDetailsState,
    },
    action
) => {
  switch (action.type) {
    case AuditlogDetails.receive:
      return Object.assign({}, state, {
        details: action.data.data.entity,
      });
    case AuditlogDetails.receiveFail:
      return Object.assign({}, state, {
        details: [],
        error: action.error,
      });
    default:
      return state;
  }
};

const initialAuditlogEventsState = {
  events: [],
};

export const auditlogEventsReducer = (
    state = {
      ...initialAuditlogEventsState,
    },
    action
) => {
  switch (action.type) {
    case AuditlogEvents.receive:
      return Object.assign({}, state, {
        events: action.data.data.entity,
      });
    default:
      return state;
  }
};

const auditlogReducer = combineReducers({
  auditlogListReducer,
  auditlogDetailsReducer,
  auditlogEventsReducer
})

export default auditlogReducer;
