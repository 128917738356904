import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import Input from "../components/Input";
import Label from "../components/Label";
import { Field, reduxForm } from "redux-form";
import SearchableSelect from "../components/SearchableSelect";
import { WhiteDropDown } from "../components/WhiteDropDown";
import { ErrorText } from "../components/ErrorText";
import { browserIsIE } from "../util/common";

const InputField = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 19, 0, 0]} maxWidth={props.maxWidth}>
      <Label htmlFor={props.input.name}>{props.label}</Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <FlexContainer id={props.input.name} row marginTop={10}>
        <Input {...props} greyPlaceholder />
      </FlexContainer>
    </FlexContainer>
  );
};

export const DropDownField = props => {
  const { error } = props.meta;

  return (
    <FlexContainer
      column
      margin={[0, 8, 0, 0]}
      maxWidth={browserIsIE() ? "700" : "150"}
    >
      <Label
        htmlFor={props.name}
        maxWidth={185}
        margin={[0, 0, 0, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};

class AddressForm extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <WhiteDropDown
        onClick={() => {
          this.props.onToggleAddress();
        }}
        isOpen={this.props.isAddressOpen}
        header={t("address")}
        greenBackground
      >
        <FlexContainer>
          <form>
            <Label margin={[0, 0, 10, 0]} bold>
              {t("postal_address") +
                (this.props.isRequired("address") ? "*" : "")}
            </Label>
            <Field
              name={"error_placeholder"}
              component={props => {
                const { error } = props.meta;

                return error ? <ErrorText>{error}</ErrorText> : "";
              }}
            />
            <FlexContainer margin={[0, 16, 0, 0]} row spaceBetween>
              <Field
                name={"1_address1"}
                label={
                  t("address_line_1") +
                  (this.props.isRequired("address") ? "*" : "")
                }
                border
                component={InputField}
              />

              <Field
                name={"1_address2"}
                label={t("address_line_2")}
                border
                component={InputField}
              />

              <Field
                name={"1_pcode"}
                label={t("post_code")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"1_city"}
                label={t("postal_place")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"1_foreign_postal"}
                label={t("foreign_postal")}
                border
                component={InputField}
              />

              <Field
                name={"1_ctry"}
                label={t("country")}
                placeholder={t("select")}
                searchable={true}
                options={this.props.countries}
                component={DropDownField}
                t={t}
              />
            </FlexContainer>

            <Label margin={[0, 0, 10, 0]} bold>
              {t("visiting_address")}
            </Label>
            <FlexContainer margin={[0, 16, 0, 0]} row spaceBetween>
              <Field
                name={"3_address1"}
                label={t("address_line_1")}
                border
                component={InputField}
              />

              <Field
                name={"3_address2"}
                label={t("address_line_2")}
                border
                component={InputField}
              />

              <Field
                name={"3_pcode"}
                label={t("post_code")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"3_city"}
                label={t("postal_place")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"3_foreign_postal"}
                label={t("foreign_postal")}
                border
                component={InputField}
              />

              <Field
                name={"3_ctry"}
                label={t("country")}
                placeholder={t("select")}
                searchable={true}
                component={DropDownField}
                options={this.props.countries}
                t={t}
              />
            </FlexContainer>

            <Label margin={[0, 0, 16, 0]} bold>
              {t("company_office_address")}
            </Label>
            <FlexContainer margin={[0, 10, 0, 0]} row spaceBetween>
              <Field
                name={"4_address1"}
                label={t("address_line_1")}
                border
                component={InputField}
              />

              <Field
                name={"4_address2"}
                label={t("address_line_2")}
                border
                component={InputField}
              />

              <Field
                name={"4_pcode"}
                label={t("post_code")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"4_city"}
                label={t("postal_place")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />
              <Field
                name={"4_foreign_postal"}
                label={t("foreign_postal")}
                border
                component={InputField}
              />

              <Field
                name={"4_ctry"}
                label={t("country")}
                placeholder={t("select")}
                searchable={true}
                component={DropDownField}
                options={this.props.countries}
                t={t}
              />
            </FlexContainer>
            <Label margin={[0, 0, 10, 0]} bold>
              {t("invoice_address")}
            </Label>
            <FlexContainer margin={[0, 16, 0, 0]} row spaceBetween>
              <Field
                name={"2_address1"}
                label={t("address_line_1")}
                border
                component={InputField}
              />

              <Field
                name={"2_address2"}
                label={t("address_line_2")}
                border
                component={InputField}
              />

              <Field
                name={"2_pcode"}
                label={t("post_code")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"2_city"}
                label={t("postal_place")}
                maxWidth={browserIsIE() ? "450" : "100"}
                border
                component={InputField}
              />

              <Field
                name={"2_foreign_postal"}
                label={t("foreign_postal")}
                border
                component={InputField}
              />

              <Field
                name={"2_ctry"}
                label={t("country")}
                placeholder={t("select")}
                searchable={true}
                component={DropDownField}
                options={this.props.countries}
                t={t}
              />
            </FlexContainer>
          </form>
        </FlexContainer>
      </WhiteDropDown>
    );
  }
}

export default (AddressForm = reduxForm({
  form: "addressForm",
  enableReinitialize: true
})(AddressForm));
