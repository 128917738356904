import {
    GetIssuanceDetails,
    GetIssuanceInstruments, GetIssuanceIssuers,
    GetIssuanceList,
    GetIssuanceParameters
} from "./ShareIssuanceActions";
import {combineReducers} from "redux";

const initialIssuanceListState = {
    list: [],
    pageNumber: 0,
    totalElements: 0
}
export const issuanceList = (
    state = {
        ...initialIssuanceListState,
    },
    action
) => {
    switch (action.type) {
        case GetIssuanceList.receive:
            return Object.assign({}, state, {
                list: action.data.items || [],
                pageNumber: action.data.pageNumber,
                totalElements: action.data.totalElements
            });
        default:
            return state;
    }
};

const initialIssuanceDetailsState = {
    details: {}
}
export const issuanceDetails = (
    state = {
        ...initialIssuanceDetailsState,
    },
    action
) => {
    switch (action.type) {
        case GetIssuanceDetails.receive:
            return Object.assign({}, state, {
                details: action.data|| {},
            });
        case GetIssuanceDetails.clear:
            return Object.assign({}, state, {
                ...initialIssuanceDetailsState,
            });
        default:
            return state;
    }
};

const initialIssuanceParametersState = {
    list: []
}
export const issuanceParameters = (
    state = {
        ...initialIssuanceParametersState,
    },
    action
) => {
    switch (action.type) {
        case GetIssuanceParameters.receive:
            return Object.assign({}, state, {
                list: action.data || [],
            });
        default:
            return state;
    }
};

const initialIssuanceInstrumentsState = {
    list: []
}
export const issuanceInstruments = (
    state = {
        ...initialIssuanceInstrumentsState,
    },
    action
) => {
    switch (action.type) {
        case GetIssuanceInstruments.receive:
            return Object.assign({}, state, {
                list: action.data || [],
            });
        case GetIssuanceInstruments.clear:
            return Object.assign({}, state, {
                ...initialIssuanceInstrumentsState,
            });
        default:
            return state;
    }
};

const initialIssuanceIssuersState = {
    list: []
}
export const issuanceIssuers = (
    state = {
        ...initialIssuanceIssuersState,
    },
    action
) => {
    switch (action.type) {
        case GetIssuanceIssuers.receive:
            return Object.assign({}, state, {
                list: action.data || [],
            });
        case GetIssuanceIssuers.clear:
            return Object.assign({}, state, {
                ...initialIssuanceInstrumentsState,
            });
        default:
            return state;
    }
};

const issuanceReducer = combineReducers({
    issuanceList,
    issuanceDetails,
    issuanceParameters,
    issuanceInstruments,
    issuanceIssuers
});

export default issuanceReducer;