import React from "react";
import { withTranslation } from "react-i18next";
import Table, { TD, TR } from "../../components/Table";
import WithPagination from "../../components/PaginationContainer";
import MenuBoxContainer from "../MenuBoxContainer";
import styled from "styled-components";
import { LIST_ACTIVE, LIST_CLOSED } from "./InsiderListHome";
import { FlexContainer } from "../../components/FlexContainer";
import { mapAccessLevel, OBNTInsiderToolDateFormat } from "../../util/common";
import moment from "moment";
import FilterBox from "../../components/FilterBox";
import { getLanguage } from "../../util/common";
import { Icon, TooltipWrapper } from "../components";
import { ErrorText } from "../../components/ErrorText";
import { Tooltip } from "../../components/Tooltip";

const Link = styled.a`
  text-align: left;
  color: #336699;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-weight: 600;
`;

const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1
};

const SortOnType = {
  DATE: 0,
  LIST: 1,
  CREATEDBY: 2,
  ACCESS: 3,
  CREATED: 4,
  UPDATED: 5,
  TYPE: 6,
  STATUS: 7
};

const PaginatedTable = WithPagination(Table);

class InsiderListList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SortOnType: SortOnType.CREATED,
      sortOrder: sortOrder.DESCENDING
    };
  }

  typeList = [];
  createdBylist = [];
  accessList = [];
  statusList = [];

  lng = getLanguage();

  TableHeaderWithFilter = ({ id, list }) => (
    <FilterBox
      enableToggleAll
      onChange={this.updateFilterMap}
      isChecked={this.isFilterOptionChecked}
      data={list}
      id={id}
      isAllChecked={this.isAllChecked(list)}
      checkAllText={this.props.t("select_all")}
      onChangeAll={state => this.updateFilterMapWithList(list, state)}
    />
  );

  TableHeaderSort = ({ SortOnType }) => (
    <Icon
      height={20}
      width={20}
      margin={[3, 0, 0, 3]}
      src="/icons/sort-white-24px.svg"
      onClick={() =>
        this.state.sortOrder === sortOrder.DESCENDING
          ? this.setState({ sortOrder: sortOrder.ASCENDING, SortOnType })
          : this.setState({ sortOrder: sortOrder.DESCENDING, SortOnType })
      }
      alt={"sort"}
    />
  );

  sortFunction = (aData, bData) => {
    if (aData > bData) return 1 * this.state.sortOrder;
    if (aData < bData) return -1 * this.state.sortOrder;
  };

  sortTimeFunction = (aTime, bTime) => {
    if (aTime.isBefore(bTime)) return 1 * this.state.sortOrder;
    if (aTime.isAfter(bTime)) return -1 * this.state.sortOrder;
  };

  listSort = (a, b) => {
    const aCreatedTime = moment(a.data.created);
    const bCreatedTime = moment(b.data.created);

    const aUpdatedTime = moment(a.data.updated);
    const bUpdatedTime = moment(b.data.updated);

    const sortCreated = (descending = undefined) => {
      if (aCreatedTime.isBefore(bCreatedTime))
        return 1 * (descending || this.state.sortOrder);
      if (aCreatedTime.isAfter(bCreatedTime))
        return -1 * (descending || this.state.sortOrder);
    };

    switch (this.state.SortOnType) {
      case SortOnType.LIST:
        return (
          this.sortFunction(a.data.code_name, b.data.code_name) ||
          sortCreated(true)
        );

      case SortOnType.CREATEDBY:
        return (
          this.sortFunction(
            this.props.userMap.get(a.data.createdBy)
              ? this.props.userMap.get(a.data.createdBy).label
              : this.props.t("it_deactivated_user"),
            this.props.userMap.get(b.data.createdBy)
              ? this.props.userMap.get(b.data.createdBy).label
              : this.props.t("it_deactivated_user")
          ) || sortCreated(true)
        );

      case SortOnType.ACCESS:
        return (
          this.sortFunction(
            mapAccessLevel(a.accessLevel, this.props.t),
            mapAccessLevel(b.accessLevel, this.props.t)
          ) || sortCreated(true)
        );

      case SortOnType.TYPE:
        return this.sortFunction(a.data.type, b.data.type) || sortCreated(true);

      case SortOnType.STATUS:
        return (
          this.sortFunction(this.getListState(a), this.getListState(b)) ||
          sortCreated(true)
        );

      case SortOnType.UPDATED:
        return (
          this.sortTimeFunction(aUpdatedTime, bUpdatedTime) || sortCreated(true)
        );

      case SortOnType.CREATED:
        return this.sortTimeFunction(aCreatedTime, bCreatedTime);
    }
  };

  filter = row => {
    if (
      this.props.filterMap.get(
        `${
          this.props.userMap.get(row.data.createdBy)
            ? this.props.userMap.get(row.data.createdBy).label
            : this.props.t("it_deactivated_user")
        }`
      ) ||
      this.props.filterMap.get(mapAccessLevel(row.accessLevel, this.props.t)) ||
      this.props.filterMap.get(this.props.t("it_" + row.data.type + "_list")) ||
      this.props.filterMap.get(this.props.t("it_" + row.data.listState))
    ) {
      return true;
    }
    return false;
  };

  updateFilterMapWithList = (list, state) => {
    let newMap = new Map(this.props.filterMap.entries());
    list.forEach(e => {
      if (state) {
        newMap.delete(e);
      } else {
        newMap.set(e, true);
      }
    });
    this.props.updateFilterMap(newMap);
  };

  updateFilterMap = (value, state) => {
    let newMap = new Map(this.props.filterMap.entries());
    if (state === true) {
      newMap.delete(value);
    } else {
      newMap.set(value, true);
    }
    this.props.updateFilterMap(newMap);
  };

  popultateFilterBoxes = _ => {
    if (this.props.data != null) {
      this.props.data.map(row => {
        this.typeList.push(this.props.t("it_" + row.data.type + "_list"));
        this.createdBylist.push(
          row.data.createdBy && this.props.userMap.get(row.data.createdBy)
            ? this.props.userMap.get(row.data.createdBy).label
            : this.props.t("it_deactivated_user")
        );
        this.accessList.push(mapAccessLevel(row.accessLevel, this.props.t));
        this.statusList.push(this.props.t("it_" + row.data.listState));
      });
      this.typeList = this.typeList.distinctValues();
      this.createdBylist = this.createdBylist.distinctValues();
      this.accessList = this.accessList.distinctValues();
      this.statusList = this.statusList.distinctValues();
    }
  };

  isFilterOptionChecked = value => {
    return !this.props.filterMap.has(value);
  };

  isAllChecked = list => {
    let isChecked = true;
    list.forEach(e => (isChecked &= this.isFilterOptionChecked(e)));
    return isChecked === 1;
  };

  componentWillMount() {
    this.popultateFilterBoxes();
  }

  componentWillUpdate() {
    this.createdBylist = [];
    this.statusList = [];
    this.accessList = [];
    this.typeList = [];
    this.popultateFilterBoxes();
  }

  getListState = row => {
    const { t } = this.props;

    if (row.data.listState === LIST_ACTIVE) {
      return t("it_" + row.data.listState);
    } else if (row.data.listState === LIST_CLOSED) {
      return (
        <React.Fragment>
          <FlexContainer row>
            {t("it_" + row.data.listState)}
            {this.listIsSoonDeleted(row)}
          </FlexContainer>
          <FlexContainer>{row.data.closedTime}</FlexContainer>
        </React.Fragment>
      );
    } else {
      return row.data.listState;
    }
  };

  listIsSoonDeleted = row => {
    const deletionDate = row.data.deletion_date
      ? moment(row.data.deletion_date, OBNTInsiderToolDateFormat)
      : undefined;
    if (deletionDate && deletionDate.isBefore(moment().add(1, "month"))) {
      return (
        <React.Fragment>
          <ErrorText>*</ErrorText>
          <Tooltip>
            {this.props.t("it_list_will_be_deleted", {
              deletionDate: deletionDate.format(OBNTInsiderToolDateFormat)
            })}
          </Tooltip>
        </React.Fragment>
      );
    }
    return undefined;
  };

  render() {
    const { onInsiderListClick, t } = this.props;

    const thLabels = [
      <FlexContainer row>
        {t("list")}
        <this.TableHeaderSort SortOnType={SortOnType.LIST} />
      </FlexContainer>,
      <FlexContainer row>
        {t("list_created_by")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATEDBY} />
        <this.TableHeaderWithFilter
          id={"createdBy"}
          list={this.createdBylist}
        />
      </FlexContainer>,
      <FlexContainer row>
        {t("access")}
        <this.TableHeaderSort SortOnType={SortOnType.ACCESS} />
        <this.TableHeaderWithFilter id={"access"} list={this.accessList} />
      </FlexContainer>,
      <FlexContainer row>
        {t("created")}
        <this.TableHeaderSort SortOnType={SortOnType.CREATED} />
      </FlexContainer>,
      <FlexContainer row>
        {t("updated")}
        <this.TableHeaderSort SortOnType={SortOnType.UPDATED} />
      </FlexContainer>,
      <FlexContainer row>
        {t("type")}
        <this.TableHeaderSort SortOnType={SortOnType.TYPE} />
        <this.TableHeaderWithFilter id={"type"} list={this.typeList} />
      </FlexContainer>,
      <FlexContainer row>
        {t("status")}
        <this.TableHeaderSort SortOnType={SortOnType.STATUS} />
        <this.TableHeaderWithFilter id={"status"} list={this.statusList} />
      </FlexContainer>,
      t("options")
    ];
    const rows =
      this.props.data != null
        ? this.props.data
            .filter(row => !this.filter(row))
            .sort(this.listSort)
            .map(row => {
              const listNeedsAttention =
                row.numResponded > 0 ? <ErrorText>*</ErrorText> : undefined;
              return (
                <TR key={row.listId}>
                  <TD
                    pointer
                    onClick={() =>
                      onInsiderListClick(row.listId, row.data.type)
                    }
                  >
                    <Link>{row.data.code_name}</Link>
                  </TD>
                  <TD width={170}>
                    {row.data.createdBy &&
                    this.props.userMap.get(row.data.createdBy)
                      ? this.props.userMap.get(row.data.createdBy).label
                      : t("it_deactivated_user")}
                  </TD>
                  <TD>{mapAccessLevel(row.accessLevel, t)}</TD>
                  <TD boldFirstLine width={120} minWidth={120}>
                    {row.data.createdTime}
                  </TD>
                  <TD boldFirstLine width={120} minWidth={120}>
                    {row.data.updatedTime}
                  </TD>
                  <TD width={220} minWidth={220}>
                    <TooltipWrapper
                      helpCursor={listNeedsAttention !== undefined}
                    >
                      {t("it_" + row.data.type + "_list") +
                        " (" +
                        row.numInsiders +
                        ")"}
                      {listNeedsAttention ? listNeedsAttention : ""}
                      {listNeedsAttention ? (
                        <Tooltip>{t("it_list_needs_attention")}</Tooltip>
                      ) : (
                        ""
                      )}
                    </TooltipWrapper>
                  </TD>
                  <TD width={150} minWidth={150}>
                    <TooltipWrapper
                      helpCursor={this.listIsSoonDeleted(row) !== undefined}
                    >
                      {this.getListState(row)}
                    </TooltipWrapper>
                  </TD>
                  <TD center>
                    <MenuBoxContainer
                      insiderListSubType={row.data.type} //TODO: Change name to something more readable
                      listId={row.listId}
                      icon={"/icons/more-horiz-black-24px.svg"}
                      selectedIcon={"/icons/more-horiz-selected-black-24px.svg"}
                      listName={row.data.code_name}
                      row={row}
                      history={this.props.history}
                      base={this.props.base}
                    />
                  </TD>
                </TR>
              );
            })
        : [];

    // If everything has been filetered out then we have to add something the let the table header be visibile
    if (this.props.filterMap.size > 0 && rows.length === 0)
      rows.push(undefined);

    return <PaginatedTable data={rows} tableHeaderLabels={thLabels} />;
  }
}

export default withTranslation("translations")(InsiderListList);
