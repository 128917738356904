import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { reset, SubmissionError } from "redux-form";
import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import {
  fetchDistributionContactsByListId,
  fetchDistributionListById,
  fetchDistributionLists,
  createDistributionContact,
  updateDistributionContact,
  updateDistributionList,
  deleteDistributionListAction,
  deleteDistributionContact
} from "./EmailDistributionActions";
import { PageHeading } from "../components/PageHeading";
import EmailDistributionListDetails from "./EmailDistributionListDetails";
import { NotificationManager } from "react-notifications";
import {
  emailDistributionListFormValidator,
  emailDistributionContactFormValidator
} from "./validator";

import Loader, { OverlayLoader } from "../components/Loader";

import { isSuperUser } from "../util/common";

//const PaginatedEmailDistributionList = WithPagination(EmailDistributionList);

class EmailDistributionListDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isContactModalOpen: false,
      isDeleteWarningOpen: false,
      isEditListOpen: false,
      selectedContactId: undefined,
      loading: false
    };
  }

  findListId = () => {
    let n = this.props.location.pathname.lastIndexOf("/");
    let length = this.props.location.pathname.length;
    let listid = this.props.location.pathname.substring(n + 1, length);

    return listid;
  };

  updateList = () => {
    const errors = emailDistributionListFormValidator(
      this.props.newListValues,
      this.props
    );

    return new Promise((resolve, reject) => {
      if (errors) {
        return reject(new SubmissionError(errors));
      }
      var updateDistibutionListObject = {
        name: this.props.newListValues.name
          ? this.props.newListValues.name
          : undefined,
        description: this.props.newListValues.description
          ? this.props.newListValues.description
          : undefined
      };

      this.setState({ loading: true }, () =>
        this.props
          .dispatch(
            updateDistributionList(
              updateDistibutionListObject,
              this.findListId()
            )
          )

          .then(() =>
            this.props.dispatch(
              fetchDistributionLists(
                isSuperUser(this.props.userLoggedIn)
                  ? 0
                  : this.props.userLoggedIn.organisationId
              )
            )
          )

          .then(() => this.setState({ isEditListOpen: false }))
          .then(() => this.props.dispatch(reset("editDistributionListForm")))
          .then(() =>
            NotificationManager.success(this.props.t("edist_list_updated"))
          )
          .then(() => this.setState({ isEditListOpen: false, loading: false }))
          .then(resolve)
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          })
      );
    });
  };

  deleteDistributionList = () => {
    this.setState({ loading: true }, () =>
      this.props
        .dispatch(deleteDistributionListAction(this.findListId()))
        .then(() => this.props.history.push("/emailDist"))
        .then(() =>
          this.setState({ isDeleteWarningOpen: false, loading: false })
        )
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        })
    );
  };

  //Contact person

  addNewContactToList = () => {
    const errors = emailDistributionContactFormValidator(
      this.props.newContactValues,
      this.props
    );

    return new Promise((resolve, reject) => {
      if (errors) {
        return reject(new SubmissionError(errors));
      }
      var newDistibutionContact = {
        email: this.props.newContactValues.email
          ? this.props.newContactValues.email
          : undefined,
        description: this.props.newContactValues.description
          ? this.props.newContactValues.description
          : undefined
      };
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(
            createDistributionContact(newDistibutionContact, this.findListId())
          )
          .then(() =>
            this.props.dispatch(
              fetchDistributionContactsByListId(this.findListId())
            )
          )

          .then(() => this.props.dispatch(reset("distributionContactForm")))
          .then(() =>
            NotificationManager.success(this.props.t("edist_contact_created"))
          )
          .then(() =>
            this.setState({ isContactModalOpen: false, loading: false })
          )
          .then(resolve)
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          })
      );
    });
  };

  updateContactPerson = () => {
    const errors = emailDistributionContactFormValidator(
      this.props.newContactValues,
      this.props
    );

    return new Promise((resolve, reject) => {
      if (errors) {
        return reject(new SubmissionError(errors));
      }
      var updateDistibutionContact = {
        email: this.props.newContactValues.email
          ? this.props.newContactValues.email
          : undefined,
        description: this.props.newContactValues.description
          ? this.props.newContactValues.description
          : undefined
      };
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(
            updateDistributionContact(
              updateDistibutionContact,
              this.state.selectedContactId
            )
          )
          .then(() =>
            this.props.dispatch(
              fetchDistributionContactsByListId(this.findListId())
            )
          )

          .then(() => this.props.dispatch(reset("distributionContactForm")))
          .then(() =>
            NotificationManager.success(this.props.t("edist_contact_updated"))
          )
          .then(() =>
            this.setState({ isContactModalOpen: false, loading: false })
          )
          .then(resolve)
          .catch(err => {
            console.log(err);
            this.setState({ loading: false });
          })
      );
    });
  };

  deleteContactFromList = () => {
    this.setState({ loading: true }, () =>
      this.props
        .dispatch(deleteDistributionContact(this.state.selectedContactId))
        .then(() =>
          this.props.dispatch(
            fetchDistributionContactsByListId(this.findListId())
          )
        )
        .then(() =>
          this.setState({ isDeleteWarningOpen: false, loading: false })
        )
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        })
    );
  };

  componentDidMount() {
    this.setState({ loading: true }, () =>
      this.props
        .dispatch(fetchDistributionListById(this.findListId()))
        .then(() =>
          this.props.dispatch(
            fetchDistributionContactsByListId(this.findListId())
          )
        )
        .then(() =>
          this.props
            .dispatch(
              fetchDistributionLists(
                isSuperUser(this.props.userLoggedIn)
                  ? 0
                  : this.props.userLoggedIn.organisationId
              )
            )
            .then(() => this.setState({ loading: false }))
            .catch(err => {
              console.log(err);
              this.setState({ loading: false });
            })
        )
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        })
    );
  }

  render() {
    const { t, distributionContactList, distributionList } = this.props;

    const getContactDetails = () => {
      return distributionContactList.find(
        contact => contact.id === this.state.selectedContactId
      );
    };

    const getListInfo = () => {
      return distributionList.find(
        //contact id is number and findlist is string, might be a better way to do this check.
        list => list.id + "" === this.findListId()
      );
    };

    return (
      <FlexContainer>
        <PageHeading>{t("edist_list_details")}</PageHeading>

        {this.state.loading ? <OverlayLoader /> : ""}

        <EmailDistributionListDetails
          {...this.props}
          addNewContactToList={this.addNewContactToList}
          isContactModalOpen={this.state.isContactModalOpen}
          onCloseContactModal={() => {
            this.setState({ isContactModalOpen: false });
            this.props.dispatch(reset("distributionContactForm"));
          }}
          onOpenContactModal={() => this.setState({ isContactModalOpen: true })}
          onOpenUpdateContact={id =>
            this.setState({ isContactModalOpen: true, selectedContactId: id })
          }
          isDeleteWarningOpen={this.state.isDeleteWarningOpen}
          onCloseWarningModal={() => {
            this.setState({ isDeleteWarningOpen: false });
          }}
          onOpenDeleteContact={id =>
            this.setState({
              isDeleteWarningOpen: true,
              selectedContactId: id
            })
          }
          deleteContactFromList={this.deleteContactFromList}
          getContactDetails={getContactDetails}
          data={distributionContactList}
          updateContactPerson={this.updateContactPerson}
          deleteDistributionList={this.deleteDistributionList}
          getListInfo={getListInfo}
          isEditListOpen={this.state.isEditListOpen}
          onOpenEditList={() => this.setState({ isEditListOpen: true })}
          loading={this.state.loading}
          onCloseEditList={() => {
            this.setState({ isEditListOpen: false });
          }}
          updateList={this.updateList}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { loginUser, emailDistributionReducer, form } = state;

  return {
    userLoggedIn: loginUser.user,
    distributionContactList:
      emailDistributionReducer.distributionContactList.list,
    newContactValues:
      form.distributionContactForm && form.distributionContactForm.values
        ? form.distributionContactForm.values
        : {},
    distributionList: emailDistributionReducer.distributionList.list,
    newListValues:
      form.editDistributionListForm && form.editDistributionListForm.values
        ? form.editDistributionListForm.values
        : {}
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(EmailDistributionListDetailsContainer)
);
