import moment from "moment";
import {
  OBNTInsiderToolDateFormat,
  OBNTInsiderToolDateTimeFormat,
  OBNTTimeFormat
} from "../util/common";
import { showUTCTime } from "./InsiderToolReducer";

export const getDateTime = (timestamp, showUTCTime) => {
  if (showUTCTime) {
    return moment.utc(timestamp);
  } else {
    return moment(timestamp);
  }
};

export const getDateTimeInOBNTFormat = (timestamp, showUTCTime) => {
  if (timestamp) {
    return getDateTime(timestamp, showUTCTime).format(
      OBNTInsiderToolDateTimeFormat
    );
  } else return "";
};

export const getDateInOBNTFormat = (timestamp, showUTCTime) => {
  if (timestamp) {
    return getDateTime(timestamp, showUTCTime).format(
      OBNTInsiderToolDateFormat
    );
  } else return "";
};

export const getTimeInOBNTFormat = (timestamp, showUTCTime) => {
  if (timestamp) {
    return getDateTime(timestamp, showUTCTime).format(OBNTTimeFormat);
  } else return "";
};

export const getISOTimeFromDateAndTime = (date, time) => {
  const dateTimeString = date + " " + time;
  if (showUTCTime) {
    return moment
      .utc(dateTimeString, OBNTInsiderToolDateTimeFormat)
      .toISOString();
  } else {
    return moment(dateTimeString, OBNTInsiderToolDateTimeFormat).toISOString();
  }
};
export const getISOTimeFromDateTimeAndFormat = (dateTime, format) => {
  if (showUTCTime) {
    return moment.utc(dateTime, format).toISOString();
  } else {
    return moment(dateTime, format).toISOString();
  }
};

export const getUtcDateTimeLabelFromFormValues = (
  formValues,
  dateKey,
  timeKey
) => {
  let utcLabel = "";
  if (
    formValues &&
    dateKey &&
    timeKey &&
    formValues[dateKey] &&
    formValues[timeKey]
  ) {
    let utcString = formValues[dateKey] + " " + formValues[timeKey];
    if (!showUTCTime) {
      utcString = moment
        .utc(moment(utcString, OBNTInsiderToolDateTimeFormat))
        .format(OBNTInsiderToolDateTimeFormat);
    }
    utcLabel = " (UTC: " + utcString + ")";
  }
  return utcLabel;
};
