import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import Label from "../components/Label";
import Textarea from "../components/Textarea";
import { WhiteDropDown } from "../components/WhiteDropDown";
import React, { Component } from "react";

const TextAreaBox = props => {
  const { height } = props;
  return (
    <FlexContainer column margin={props.margin} height={height}>
      {props.noLabel ? (
        ""
      ) : (
        <Label margin={[0, 0, 3, 0]} htmlFor={props.name}>
          {props.label}
        </Label>
      )}

      <Textarea {...props} />
    </FlexContainer>
  );
};

class CommentForm extends Component {
  state = {};
  render() {
    return (
      <WhiteDropDown
        greenBackground
        header={this.props.t("comment")}
        onClick={() => {
          this.props.onToggleComment();
        }}
        isOpen={this.props.isCommentOpen}
      >
        <form>
          <FlexContainer>
            <Field
              height="125px"
              border
              label={this.props.t("comment") + ":"}
              name="comments"
              component={TextAreaBox}
              noResize
            />
          </FlexContainer>
        </form>
      </WhiteDropDown>
    );
  }
}

CommentForm.propTypes = {
  t: PropTypes.func.isRequired
};

export default (CommentForm = reduxForm({
  form: "commentForm"
})(CommentForm));
