import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import { Modal } from "../components/Modal";
import React from "react";
import { Field, reduxForm } from "redux-form";
import { InputBox } from "../insiderTool/components";
import Label from "../components/Label";
import RadioButton from "../components/RadioButton";

class UpdateSystemProplModal extends React.Component {
  render() {
    const { t, selectedKey, selectedValue } = this.props;

    return (
      <Modal
        width={"50%"}
        key={"showEditURLModal"}
        header={t("system_edit_default_values")}
        isOpen={true}
        onClose={this.props.onClose}
      >
        <FlexContainer column>
          <FlexContainer row minHeight={"50"}>
            <FlexContainer margin={[0, 15, 0, 0]} center>
              <Label>
                {t(
                  "system_" +
                    selectedKey.substring(selectedKey.lastIndexOf(".") + 1)
                )}
              </Label>
            </FlexContainer>

            {selectedValue !== "true" && selectedValue !== "false" ? (
              <FlexContainer>
                <Field component={InputBox} name={"propValue"} border />
              </FlexContainer>
            ) : (
              <FlexContainer row vAlignCenter center>
                <Field
                  name="propValue"
                  component={RadioButton}
                  radioValue={"true"}
                  label={t("yes")}
                />

                <Field
                  name="propValue"
                  margin={[0, 0, 0, 20]}
                  component={RadioButton}
                  radioValue={"false"}
                  label={t("no")}
                />
              </FlexContainer>
            )}
          </FlexContainer>
          <FlexContainer
            row
            center
            flexEnd
            vAlignCenter
            margin={[35, 0, 16, 0]}
          >
            <Button.Standard
              margin={[0, 15, 0, 0]}
              onClick={this.props.handleSubmit}
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={this.props.onClose}>{t("close")}</Button.Text>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
}

UpdateSystemProplModal = reduxForm({
  form: "updateSystemProplModal",
  enableReinitialize: true
})(UpdateSystemProplModal);

export default UpdateSystemProplModal;
