import React from "react";

import { Modal } from "../../../components/Modal";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { mapAccessLevel, browserIsIE } from "../../../util/common";
import Table, { TD, TR } from "../../../components/Table";
import { InsiderType } from "../../InsiderToolActions";
import { HelpIcon } from "../../../components/HelpIcon";
import i18n from "../../../i18n";
import { FlexContainer } from "../../../components/FlexContainer";
import { connect } from "react-redux";
import Label from "../../../components/Label";
import Button from "../../../components/Button";
import { Icon } from "../../components";
import { getDateInOBNTFormat, getTimeInOBNTFormat } from "../../UTCUtils";

Array.prototype.groupBy = function(prop) {
  const grouped = this.reduce(function(groups, item) {
    const val = item[prop];
    groups[val] = groups[val] || [];
    groups[val].push(item);
    return groups;
  }, {});

  return Object.keys(grouped).map(key => ({ type: key, data: grouped[key] }));
};

Array.prototype.flatten = function() {
  const flatten = list =>
    list.reduce((a, b) => a.concat(Array.isArray(b) ? flatten(b) : b), []);
  const b = flatten(this);

  return b;
};
const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1
};
const sortType = {
  DATE: 0,
  AREA: 1
};

export class Tracelog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortType: sortType.DATE,
      sortOrder: sortOrder.DESCENDING,
      mailModalIsOpen: false
    };
  }

  recordToArea = rec => {
    switch (rec.type) {
      case InsiderType.MANDATORY_KEY_INFO:
      case InsiderType.MANDATORY_NCA_INFO:
      case InsiderType.ATTACHMENT:
      case InsiderType.LIST:
        return this.props.t("it_" + rec.type);
      case InsiderType.STATE:
        return this.props.t("state");
      case InsiderType.USER:
        return rec.id;
      case InsiderType.PERSON:
        const firstName = rec.data.first_name ? rec.data.first_name : "";
        const lastName = rec.data.last_name ? rec.data.last_name : "";

        return rec.data
          ? `${this.props.t("it_tracelog_insider")}: ${firstName} ${lastName}`
          : "";
    }
  };

  getTranslatedKey = key => {
    const match = key.match(/(.*)_[0-9]+|(.*)/); // Remove trailing numbers if existing
    var modifiedKey = match[1] ? match[1] : match[2];

    var translatedKey = this.props.t(modifiedKey);

    if (translatedKey === modifiedKey) {
      translatedKey = this.props.t("it_" + modifiedKey);
    }
    if (translatedKey === `it_${modifiedKey}`) {
      translatedKey = this.props.t(
        "it_" + modifiedKey + "_" + this.props.listType
      );
    }

    if (translatedKey === `it_${modifiedKey}_${this.props.listType}`) {
      translatedKey = this.props.t("it_tracelog_" + modifiedKey);
    }
    if (translatedKey === `it_tracelog_${modifiedKey}`) {
      translatedKey = this.props.t(
        "it_tracelog_" + modifiedKey + "_" + this.props.listType
      );
    }

    return translatedKey;
  };

  added = (prevRecord, record) => {
    const prevKeys = Object.keys(prevRecord.data || {});
    switch (record.type) {
      case InsiderType.ATTACHMENT:
        return prevRecord.data
          ? [
              {
                field: record.short_data,
                oldVal: "",
                newVal: "",
                time: record.fm_time,
                area: this.recordToArea(record),
                who: record.who
              }
            ]
          : [];
      default:
        return record.data
          ? Object.keys(record.data)
              .filter(key => !prevKeys.includes(key))
              .map(key => {
                return {
                  field: this.getTranslatedKey(key),
                  oldVal: "",
                  newVal: record.data[key],
                  area: this.recordToArea(record.data ? record : prevRecord),
                  time: record.fm_time,
                  who: record.who
                };
              })
          : [];
    }
  };

  modified = (prevRecord, record) =>
    record.data
      ? Object.keys(prevRecord.data || {})
          .filter(key => record.data[key] != prevRecord.data[key])
          .map(key => ({
            field: this.getTranslatedKey(key),
            oldVal: prevRecord.data[key],
            newVal: record.data[key],
            area: this.recordToArea(record.data ? record : prevRecord),
            time: record.fm_time,
            who: record.who
          }))
      : [];

  deleted = (prevRecord, record) => {
    const newKeys = Object.keys(record.data || {});
    if (record.data === null) {
      return {
        field:
          record.type === InsiderType.ATTACHMENT ? prevRecord.short_data : "",
        oldVal: "",
        newVal: this.props.t("DELETED"),
        area: this.recordToArea(record.data ? record : prevRecord),
        time: record.fm_time,
        who: record.who
      };
    }
    return Object.keys(prevRecord.data || {})
      .filter(key => !newKeys.includes(key))
      .map(key => ({
        field: this.getTranslatedKey(key),
        oldVal: prevRecord.data[key],
        newVal: "",
        area: this.recordToArea(record.data ? record : prevRecord),
        time: record.fm_time
      }));
  };

  addFieldRecord = (record, index, array) =>
    index === 0
      ? this.added({ data: {} }, record)
      : this.added(array[index - 1], record)
          .concat(this.modified(array[index - 1], record))
          .concat(this.deleted(array[index - 1], record));

  handleTypeSTATE = entries =>
    entries.map(e => {
      const insider = this.props.insiders.find(i => i.id === e.id);
      if (e.data.mail) {
        return {
          field: insider
            ? `${insider.data.first_name ? insider.data.first_name : ""} ${
                insider.data.surname ? insider.data.surname : ""
              }`
            : "",
          oldVal: "",
          newVal: (
            <Button.Standard
              onClick={() =>
                this.setState({
                  mailModalIsOpen: true,
                  mailSubject: e.data.mail.subject,
                  mailBody: e.data.mail.body,
                  mailRecipent: e.data.mail.to
                })
              }
            >
              {this.props.t("view")}
            </Button.Standard>
          ),
          time: e.fm_time,
          area: this.props.t("it_tracelog_email_sent_to_insider"),
          who: e.who
        };
      }
      return {
        field: insider
          ? `${insider.data.first_name ? insider.data.first_name : ""} ${
              insider.data.surname ? insider.data.surname : ""
            }`
          : "",
        oldVal: "",
        newVal: this.props.t(e.short_data),
        time: e.fm_time,
        area: this.props.t("insider_state"),
        who: e.who
      };
    });

  handleTypeUSER = entries =>
    entries.map(e => ({
      field: e.data.name,
      oldVal: "",
      newVal: mapAccessLevel(e.accessLevel, this.props.t),
      time: e.fm_time,
      area: this.props.t("user"),
      who: e.who
    }));

  toTypeSpecificFields = entry => {
    const entries = entry.data;

    switch (entry.type) {
      case InsiderType.LIST:
      case InsiderType.PERSON:
      case InsiderType.MANDATORY_KEY_INFO:
      case InsiderType.MANDATORY_NCA_INFO:
      case InsiderType.ATTACHMENT:
        return entries.groupBy("id").map(this.toTypeSpecificFields);
      case InsiderType.STATE:
        return this.handleTypeSTATE(entries);
      case InsiderType.USER:
        return this.handleTypeUSER(entries);
      default:
        // All entries that is grouped by ID is handled here.
        return entries.map(this.addFieldRecord);
    }
  };

  notNull = e => e != null;

  objectsToArray = (acc, val) => [...acc, ...val];

  toTableRows = (field, index) => {
    return (
      <TR key={index}>
        <TD printBorder>
          <div style={{ whiteSpace: "nowrap" }}>
            {getDateInOBNTFormat(field.time, this.props.showUTCTime)}
          </div>
          <div style={{ whiteSpace: "nowrap" }}>
            {getTimeInOBNTFormat(field.time, this.props.showUTCTime)}
          </div>
        </TD>
        <TD printBorder>
          {this.props.userMap.get(field.who)
            ? this.props.userMap.get(field.who).label
            : field.who}
        </TD>
        <TD printBorder>{field.area}</TD>
        <TD printBorder>{field.field}</TD>
        <TD printBorder>{field.oldVal}</TD>
        <TD printBorder>{field.newVal}</TD>
      </TR>
    );
  };

  traceLogSort = (a, b) => {
    const aTime = moment(a.time);
    const bTime = moment(b.time);

    if (this.state.sortType === sortType.AREA) {
      if (a.area > b.area) return 1 * this.state.sortOrder;
      if (a.area < b.area) return -1 * this.state.sortOrder;
    }

    if (aTime.isBefore(bTime)) return 1 * this.state.sortOrder;
    if (aTime.isAfter(bTime)) return -1 * this.state.sortOrder;
    return 0;
  };

  render() {
    const { t, onClose, data, helpTextFileName } = this.props;

    const timeZoneString = this.props.showUTCTime
      ? t("it_utc_time")
      : t("it_local_time");
    const arrTableHeaders = [
      <FlexContainer row>
        {t("date") + " " + timeZoneString}
        <Icon src="/icons/sort-white-24px.svg" />
      </FlexContainer>,
      t("user"),
      <FlexContainer row>
        {t("area")}
        <Icon src="/icons/sort-white-24px.svg" />
      </FlexContainer>,
      t("field"),
      t("old_value") + " " + t("it_utc_time"),
      t("new_value") + " " + t("it_utc_time")
    ];

    const tableRows = data
      ? data
          .groupBy("type")
          .map(this.toTypeSpecificFields)
          .flatten()
          .sort(this.traceLogSort)
          .map(this.toTableRows)
      : [];

    const header = (
      <FlexContainer row center>
        {t("historic_entries")}
        {helpTextFileName ? (
          <HelpIcon
            margin={[8, 0, -3, 5]}
            fileName={helpTextFileName}
            language={i18n.language}
            insiderToolHelp
            height={"18"}
            width={"18"}
          />
        ) : (
          undefined
        )}
      </FlexContainer>
    );

    const onSortClick = sortType =>
      this.state.sortOrder === sortOrder.DESCENDING
        ? this.setState({ sortOrder: sortOrder.ASCENDING, sortType })
        : this.setState({ sortOrder: sortOrder.DESCENDING, sortType });

    const tableHeaderProps = [
      {
        onClick: () => onSortClick(sortType.DATE),
        pointer: true
      },

      ,
      {
        onClick: () => onSortClick(sortType.AREA),
        pointer: true
      }
    ];

    return (
      <React.Fragment>
        <Modal isOpen onClose={onClose} header={header} width={"68%"} xScroll>
          <Table
            tableHeaderProps={tableHeaderProps}
            tableHeaderLabels={arrTableHeaders}
            stickyOption={false}
            forceUpdate={true}
            tableRows={tableRows}
          />
        </Modal>
        <Modal
          isOpen={this.state.mailModalIsOpen}
          onClose={() => this.setState({ mailModalIsOpen: false })}
          xScroll
          height="70%"
          width="58%"
          header={this.props.t("email")}
        >
          <FlexContainer column>
            <FlexContainer
              margin={[0, 0, 20, 0]}
              greyBackground
              padding={[5, 5, 5, 5]}
            >
              <Label bold>{this.props.t("address")}:</Label>
              {this.state.mailRecipent}
            </FlexContainer>
            <FlexContainer
              margin={[0, 0, 20, 0]}
              greyBackground
              padding={[5, 5, 5, 5]}
            >
              <Label bold>{this.props.t("subject")}:</Label>
              {this.state.mailSubject}
            </FlexContainer>
            <FlexContainer
              greyBackground
              padding={[5, 5, 5, 5]}
              style={{ wordBreak: browserIsIE() ? "break-all" : "break-word" }}
            >
              <Label bold breakWordsWithHyphen>
                {this.props.t("it_tracelog_email_body")}:
              </Label>
              {this.state.mailBody}
            </FlexContainer>
          </FlexContainer>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;

  return {
    userMap: insiderToolReducer.userList.map,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    insiders: insiderToolReducer.insiderList.insiders,
    showUTCTime: insiderToolReducer.UTC.showUTC
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(Tracelog)
);
