import React from "react";
import { Icon } from "../../components";
import moment from "moment";
import FilterBox from "../../../components/FilterBox";
import { FlexContainer } from "../../../components/FlexContainer";

const sortOrder = {
  ASCENDING: -1,
  DESCENDING: 1
};

const SortOnType = {
  STATUS: 0,
  NAME: 1,
  EMAIL: 2,
  PHONEWORK: 3,
  ADDRESSWORK: 4,
  FUNCTIONANDREASON: 5,
  ACCESSGIVEN: 6,
  CEASED: 7,
  BIRTHDATE: 8,
  ID: 9,
  PHONEPERSONAL: 10,
  ADDRESSPERSONAL: 11,
  COMMENTS: 12
};

function WithFilterAndSort(WrappedComponent) {
  return class Filter extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        filterMap: new Map(),
        sortOrder: sortOrder.DESCENDING,
        statusList: [],
        filterUpdated: false
      };
      this.maxResultOnPage = 10;
    }

    /**********************************************************
     TABLE FILTER FUNCTIONS
  **********************************************************/
    TableHeaderWithFilter = ({ id, list }) => (
      <FilterBox
        enableToggleAll
        onChange={this.updateFilterMap}
        isChecked={this.isFilterOptionChecked}
        data={list}
        id={id}
        isAllChecked={this.isAllChecked(list)}
        checkAllText={this.props.t("select_all")}
        onChangeAll={state => this.updateFilterMapWithList(list, state)}
      />
    );

    TableHeaderSort = ({ SortOnType }) => (
      <Icon
        height={20}
        width={20}
        margin={[3, 0, 0, 3]}
        src="/icons/sort-white-24px.svg"
        onClick={() =>
          this.state.sortOrder === sortOrder.DESCENDING
            ? this.setState({ sortOrder: sortOrder.ASCENDING, SortOnType })
            : this.setState({ sortOrder: sortOrder.DESCENDING, SortOnType })
        }
        alt={"sort"}
      />
    );

    updateFilterMapWithList = (list, state) => {
      var newMap = new Map(this.state.filterMap.entries());
      list.forEach(e => {
        if (state) {
          newMap.delete(e);
        } else {
          newMap.set(e, true);
        }
      });
      this.setState({ filterMap: newMap });
    };

    updateFilterMap = (value, state) => {
      var newMap = new Map(this.state.filterMap.entries());
      if (state === true) {
        newMap.delete(value);
      } else {
        newMap.set(value, true);
      }
      this.setState({ filterMap: newMap });
    };

    filter = row => {
      if (
        this.state.filterMap.get(
          this.props.t("it_" + this.props.getState(row.id))
        )
      ) {
        return true;
      }
      return false;
    };

    popultateFilterBoxes = _ => {
      if (this.props.insiderStateData != null) {
        const tempList = this.props.insiderStateData
          .map(row => this.props.t("it_" + row.short_data))
          .distinctValues();

        if (!tempList.equal(this.state.statusList))
          this.setState({ statusList: tempList.distinctValues() });
      }
    };

    listSort = (a, b) => {
      const sortFunction = (aData, bData) => {
        if (aData > bData) return 1 * this.state.sortOrder;
        if (aData < bData) return -1 * this.state.sortOrder;
      };

      const sortTimeFunction = (aTime, bTime) => {
        if (aTime.isBefore(bTime)) return 1 * this.state.sortOrder;
        if (aTime.isAfter(bTime)) return -1 * this.state.sortOrder;
      };

      const aAccessGivenTime = moment(a.data.obtained);
      const bAccessGivenTime = moment(b.data.obtained);

      const aBirthDate = moment(a.data.date_of_birth);
      const bBirthDate = moment(b.data.date_of_birth);

      switch (this.state.SortOnType) {
        case SortOnType.STATUS:
          return sortFunction(
            this.props.t("it_" + this.props.getState(a.id)),
            this.props.t("it_" + this.props.getState(b.id))
          );

        case SortOnType.NAME:
          return sortFunction(
            a.data.surname ? a.data.surname.toLowerCase() : "",
            b.data.surname ? b.data.surname.toLowerCase() : ""
          );

        case SortOnType.EMAIL:
          return sortFunction(
            a.data.email ? a.data.email : "",
            b.data.email ? b.data.email : ""
          );

        case SortOnType.ADDRESSWORK:
          return sortFunction(
            a.data.company_address ? a.data.company_address : "",
            b.data.company_address ? b.data.company_address : ""
          );
        case SortOnType.FUNCTIONANDREASON:
          return sortFunction(
            a.data.insider_function_and_reason
              ? a.data.insider_function_and_reason
              : "",
            b.data.insider_function_and_reason
              ? b.data.insider_function_and_reason
              : ""
          );
        case SortOnType.ACCESSGIVEN:
          return sortTimeFunction(aAccessGivenTime, bAccessGivenTime);

        case SortOnType.CEASED:
          return sortFunction(
            a.data.ceased ? a.data.ceased : "",
            b.data.ceased ? b.data.ceased : ""
          );

        case SortOnType.BIRTHDATE:
          return sortTimeFunction(aBirthDate, bBirthDate);

        case SortOnType.ID:
          return sortFunction(
            a.data.idnumber ? a.data.idnumber : "",
            b.data.idnumber ? b.data.idnumber : ""
          );

        case SortOnType.PHONEPERSONAL:
          return sortFunction(
            a.data.phone_private ? a.data.phone_private : "",
            b.data.phone_private ? b.data.phone_private : ""
          );

        case SortOnType.PHONEWORK:
          return sortFunction(
            a.data.phone_work ? a.data.phone_work : "",
            b.data.phone_work ? b.data.phone_work : ""
          );

        case SortOnType.ADDRESSPERSONAL:
          return sortFunction(
            a.data.address ? a.data.address : "",
            b.data.address ? b.data.address : ""
          );

        case SortOnType.COMMENTS:
          return sortFunction(
            a.data.comment ? a.data.comment : "",
            b.data.comment ? b.data.comment : ""
          );
      }
    };

    isFilterOptionChecked = value => {
      return !this.state.filterMap.has(value);
    };

    isAllChecked = list => {
      var isChecked = true;
      list.forEach(e => (isChecked &= this.isFilterOptionChecked(e)));
      return isChecked === 1;
    };
    /**********************************************************
     END - TABLE FILTER FUNCTIONS
  **********************************************************/

    componentWillMount() {
      this.popultateFilterBoxes();
    }

    componentDidUpdate() {
      this.popultateFilterBoxes();
    }

    render() {
      const { data, t } = this.props;

      const thLabels = [
        this.props.hasEditAccess ? (
          <Icon
            height={30}
            width={30}
            src={
              this.state.showMenu
                ? "/icons/more-horiz-selected-white-24px.svg"
                : "/icons/more-horiz-white-24px.svg"
            }
            alt="menu"
            id={"selectOptionsMenu"}
          />
        ) : (
          ""
        ),
        <FlexContainer noWrap row>
          {t("status")}
          <this.TableHeaderSort SortOnType={SortOnType.STATUS} />
          <this.TableHeaderWithFilter
            id={"status"}
            list={this.state.statusList}
          />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("name")}
          <this.TableHeaderSort SortOnType={SortOnType.NAME} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("email")}
          <this.TableHeaderSort SortOnType={SortOnType.EMAIL} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_phone_work")}
          <this.TableHeaderSort SortOnType={SortOnType.PHONEWORK} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_company_name_and_address")}
          <this.TableHeaderSort SortOnType={SortOnType.ADDRESSWORK} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_function_and_reason")}
          <this.TableHeaderSort SortOnType={SortOnType.FUNCTIONANDREASON} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_obtained")}
          <this.TableHeaderSort SortOnType={SortOnType.ACCESSGIVEN} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_ceased")}
          <this.TableHeaderSort SortOnType={SortOnType.CEASED} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_date_of_birth")}
          <this.TableHeaderSort SortOnType={SortOnType.BIRTHDATE} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_idnumber")}
          <this.TableHeaderSort SortOnType={SortOnType.ID} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_phone_private")}
          <this.TableHeaderSort SortOnType={SortOnType.PHONEPERSONAL} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_personal_home_address")}
          <this.TableHeaderSort SortOnType={SortOnType.ADDRESSPERSONAL} />
        </FlexContainer>,
        <FlexContainer noWrap row>
          {t("it_comments")}
          <this.TableHeaderSort SortOnType={SortOnType.COMMENTS} />
        </FlexContainer>,
        t("it_historical_entries")
      ];

      const sliceData = () => {
        return data != null
          ? data
              .sort(this.listSort)
              .filter(insider => !this.filter(insider))

              .slice(
                0 + this.props.maxResultOnPage * (this.props.activePage - 1),
                this.props.maxResultOnPage * this.props.activePage
              )
          : data;
      };

      var rows = sliceData();

      return (
        <WrappedComponent
          {...this.props}
          data={rows}
          thLabels={thLabels}
          onlyDisplayTableHeader={
            this.state.filterMap.size > 0 && rows.length === 0
          }
        />
      );
    }
  };
}

export default WithFilterAndSort;
