import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "../css/react-select.css";
import styled from "styled-components";
import { sortFunction } from "./SearchableSelect";

const StyledSelect = styled(Select.Creatable)`
  width: 100%;
  font-size: 14px;

  .Select-control {
    ${(props) => (props.noBorder === true ? "border: 0px solid;" : "")};
  }

  ${(props) =>
    props.maxWidth
      ? "max-width: " + props.maxWidth + "px; width: 100%;"
      : ""} ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

class CreatableSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options:
        this.props.options != null
          ? !this.props.isSorted
            ? this.props.options.sort(sortFunction)
            : this.props.options
          : [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.options.length > 0) {
      if (nextProps.options.length !== this.state.options.length) {
        this.setState({
          options: nextProps.options.sort(
            this.props.sortFunction || sortFunction
          ),
        });
      }
    }
  }

  render() {
    const { name, value, onChange } = this.props.input;

    const onSelectChange = (value) => {
      onChange(value);
      if (this.props.onSelect != null) {
        this.props.onSelect(value);
      }
    };

    return (
      <StyledSelect
        clearable={true}
        searchable={this.props.searchable}
        name={name}
        placeholder={this.props.placeholder}
        value={value}
        onChange={onSelectChange}
        options={this.props.options}
        showNewOptionAtTop={true}
        matchPos="start"
        noResultsText={this.props.t("not_found")}
        {...this.props}
        promptTextCreator={() => this.props.t("create_option")}
      />
    );
  }
}

CreatableSelect.propTypes = {
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  searchable: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default CreatableSelect;
