import React from "react";
import {withTranslation} from "react-i18next";
import Label from "../components/Label";
import {FlexContainer} from "../components/FlexContainer";
import styled from "styled-components";

const ListItem = styled.li`
  color: white;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  font-size: 14px;
`
const OrderedList = styled.ol`
  padding: 0 0 0 12px;
  margin: 0 0 0 0;
  font-size: 14px;
`

const UnorderedList = styled.ul`
`

class PasswordRules extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const props = this.props;

        return (<FlexContainer>
            <OrderedList>
                <ListItem>{props.t("password_rule_length")}</ListItem>
                <UnorderedList>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_length_sub")}
                    </Label></ListItem>
                </UnorderedList>
                <ListItem><Label color={"white"} fullWidth>
                    {props.t("password_rule_composition")}
                </Label></ListItem>
                <UnorderedList>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_composition_sub_1")}
                    </Label></ListItem>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_composition_sub_2")}
                    </Label></ListItem>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_composition_sub_3")}
                    </Label></ListItem>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_composition_sub_4")}
                    </Label></ListItem>
                </UnorderedList>
                <ListItem><Label color={"white"} fullWidth>
                    {props.t("password_rule_repetition")}
                </Label></ListItem>
                <UnorderedList>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_repetition_sub")}
                    </Label></ListItem>
                </UnorderedList>
                <ListItem><Label color={"white"} fullWidth>
                    {props.t("password_rule_username")}
                </Label></ListItem>
                <UnorderedList>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_username_sub")}
                    </Label></ListItem>
                </UnorderedList>
                <ListItem><Label color={"white"} fullWidth>
                    {props.t("password_rule_reuse")}
                </Label></ListItem>
                <UnorderedList>
                    <ListItem><Label color={"white"} fullWidth>
                        {props.t("password_rule_reuse_sub")}
                    </Label></ListItem>
                </UnorderedList>
            </OrderedList>
        </FlexContainer>)
    }
}


export default withTranslation("translations")(PasswordRules);