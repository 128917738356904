import { withTranslation } from "react-i18next";
import React from "react";
import { MenuBox } from "./modals/MenuBox";
import { MenuItem } from "./components";
import { reset, SubmissionError } from "redux-form";
import { Modal } from "../components/Modal";
import PrivelegesModalForm from "./modals/PrivelegesModalForm";
import CloseAndNotifyList from "./modals/insiderList/CloseAndNotifyList";
import CloseAndNotifyAuthority from "./modals/insiderList/CloseAndNotifyAuthority";
import CloseList from "./modals/insiderList/CloseList";
import { connect } from "react-redux";
import {
  addUser,
  createListBasedOnOtherList,
  exportCSVList,
  exportList,
  fetchInsiderList,
  fetchInsiderListList,
  fetchTemplate,
  InsiderType,
  reopenList,
  sendMailToInsiders,
  updateInsiderListData,
  updateInsiderListExtraData
} from "./InsiderToolActions";
import { OverlayLoader } from "../components/Loader";
import moment from "moment/moment";
import {
  ACCESS_LEVEL,
  getLanguage,
  OBNTInsiderToolDateFormat,
  showFileFromServer
} from "../util/common";
import { NotificationManager } from "react-notifications";
import { CreateNewListModal } from "./modals/insiderListList/CreateNewListModal";
import { FlexContainer } from "../components/FlexContainer";
import i18n from "../i18n";
import { HelpIcon } from "../components/HelpIcon";
import AdministerStorageTimeModal from "./modals/insiderList/AdministerStorageTimeModal";
import { ConfirmModal } from "../components/ConfirmModal";

export class MenuBoxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showEditPrivilegesModal: false,
      showPrivilegesModal: false,
      showCloseAndNotify: false,
      showCloseList: false,
      showCloseAndNotifyAuthority: false,
      showLoader: false,
      pageOne: false,
      pageTwo: false,
      showNewInsiderListModal: false,
      showNewConfListModal: false,
      showAdministerStorageTime: false
    };
  }

  exportList = timestamp => {
    const lang = getLanguage() === "No" ? "no" : "en";
    this.props
      .dispatch(exportList(this.props.listId, timestamp, lang))
      .then(data =>
        showFileFromServer(data, this.props.insiderListInfo.code_name + ".pdf")
      );
  };
  exportCSVList = timestamp => {
    this.props
      .dispatch(exportCSVList(this.props.listId, timestamp))
      .then(data =>
        showFileFromServer(data, this.props.insiderListInfo.code_name + ".csv")
      );
  };

  getIdFromList = response => {
    return response.entries.find(entry => entry.type === "list").id;
  };

  updateNCAWithReferenceToAnnouncement = () => {
    const {
      mandatoryNCAInfo,
      closeAndNotifyListFormValues,
      listId
    } = this.props;
    const englishReference = closeAndNotifyListFormValues.reference_to_announcement_en
      ? closeAndNotifyListFormValues.reference_to_announcement_en
      : undefined;
    const norwegianReference = closeAndNotifyListFormValues.reference_to_announcement_no
      ? closeAndNotifyListFormValues.reference_to_announcement_no
      : undefined;

    mandatoryNCAInfo.reference_to_announcement_en = englishReference
      ? englishReference
      : norwegianReference;
    mandatoryNCAInfo.reference_to_announcement_no = norwegianReference
      ? norwegianReference
      : englishReference;
    return this.props
      .dispatch(
        updateInsiderListExtraData(
          InsiderType.MANDATORY_NCA_INFO,
          mandatoryNCAInfo,
          listId
        )
      )
      .then(() => this.props.dispatch(fetchInsiderList(listId)));
  };

  referenceHasValue = () => {
    const { closeAndNotifyListFormValues } = this.props;
    return (
      closeAndNotifyListFormValues &&
      (closeAndNotifyListFormValues.reference_to_announcement_en ||
        closeAndNotifyListFormValues.reference_to_announcement_no)
    );
  };

  render() {
    const {
      t,
      usersWithAccessToIT,
      dispatch,
      listOpened,
      listId,
      listType,
      icon,
      selectedIcon,
      usersWithAccessToList,
      insiderListInfo,
      emailTemplate,
      listName,
      insiderListSubType,
      row,
      hasEditAccess,
      loginUserId,
      isAdmin,
      isOwnerOrHigher
    } = this.props;

    const getUsersWithName = () => {
      return usersWithAccessToList && usersWithAccessToIT
        ? usersWithAccessToIT
            .filter(
              user =>
                user.isAdmin ||
                (this.props.usersWithAccessToListMap.get(user.id) &&
                  this.props.usersWithAccessToListMap.get(user.id)
                    .accessLevel !== ACCESS_LEVEL.NO_ACCESS)
            )
            .map(user => ({
              label: user.label,
              accessLevel: ACCESS_LEVEL.ADMIN, // Default value. If user is a non-admin user, then this value will be overridden by the linebelow.
              ...this.props.usersWithAccessToListMap.get(user.id)
            }))
        : [];
    };

    const getValuesAsArray = values => {
      let array = [];
      Object.keys(values).forEach(key => {
        if (key.startsWith("body_")) {
          array.push(values[key]);
        }
      });
      return array;
    };

    const sendMail = (listId, values, templateName) => {
      return this.props
        .dispatch(
          sendMailToInsiders(listId, getValuesAsArray(values), templateName)
        )
        .then(() =>
          NotificationManager.success(
            this.props.t("it_email_sent_to_persons_with_mail_registered")
          )
        )
        .catch(e => console.log(e));
    };

    const replaceKeysInString = (string, language) => {
      const { t } = this.props;
      if (string) {
        let replacedString = string.replace(
          /_name_ _surname_/g,
          t("it_name_replaced_here", { lng: language })
        );
        replacedString = replacedString.replace(
          /_projectName_/g,
          this.props.insiderListInfo.code_name
        );
        if (
          listType === InsiderType.LIST &&
          this.props.mandatoryNCAInfo &&
          this.props.mandatoryNCAInfo.reference_to_announcement_en
        ) {
          replacedString = replacedString.replace(
            /_reference_to_announcement_en_/g,
            this.props.mandatoryNCAInfo.reference_to_announcement_en
          );
        } else {
          replacedString = replacedString.replace(
            /_reference_to_announcement_en_/g,
            t("it_reference_to_announcement_replacement", {
              lng: language
            })
          );
        }
        if (
          listType === InsiderType.LIST &&
          this.props.mandatoryNCAInfo &&
          this.props.mandatoryNCAInfo.reference_to_announcement_no
        ) {
          replacedString = replacedString.replace(
            /_reference_to_announcement_no_/g,
            this.props.mandatoryNCAInfo.reference_to_announcement_no
          );
        } else {
          replacedString = replacedString.replace(
            /_reference_to_announcement_no_/g,
            t("it_reference_to_announcement_replacement", {
              lng: language
            })
          );
        }

        return replacedString;
      }
      return string;
    };

    const storageAdministrationHeader = (
      <FlexContainer row center>
        {t(
          insiderListSubType === InsiderType.LIST
            ? "administer_storage_time"
            : "administer_storage_time_conf"
        )}
        <HelpIcon
          margin={[8, 0, -3, 5]}
          fileName={
            insiderListSubType === InsiderType.LIST
              ? "administer_storage_time"
              : "administer_storage_time_conf"
          }
          language={i18n.language}
          insiderToolHelp
          height={"18"}
          width={"18"}
        />
      </FlexContainer>
    );

    const getDisableDaysBefore = () => {
      if (
        insiderListSubType === InsiderType.LIST &&
        row.data.listState === "closed" &&
        row.data.deletion_date
      ) {
        return moment(row.data.closed_date)
          .add(5, "years")
          .toDate();
      }
      return moment()
        .add(1, "day")
        .toDate();
    };

    return [
      <MenuBox
        id={listId}
        key={"menuBox"}
        icon={icon}
        selectedIcon={selectedIcon}
      >
        {!hasEditAccess(listId) ? (
          undefined
        ) : (
          <MenuItem
            onClick={() => {
              if (listOpened) {
                this.setState({ showEditPrivilegesModal: true });
              } else {
                this.setState({ showLoader: true });
                dispatch(fetchInsiderList(listId))
                  .then(() => {
                    this.setState({
                      showEditPrivilegesModal: true,
                      showLoader: false
                    });
                  })
                  .catch(() => this.setState({ showLoader: false }));
              }
            }}
          >
            {listOpened
              ? t("access_administration")
              : t("list_access_administration")}
          </MenuItem>
        )}
        {listOpened ? (
          <React.Fragment>
            {listType === InsiderType.CONFIDENTIALITY_LIST ||
            !hasEditAccess(listId) ||
            insiderListInfo.listState === "closed" ? (
              undefined
            ) : (
              <MenuItem
                onClick={() => {
                  if (isOwnerOrHigher) {
                    Promise.resolve(this.setState({ showLoader: true }))
                      .then(() =>
                        this.props.dispatch(fetchTemplate("", "closeAndNotify"))
                      )
                      .then(
                        this.setState({
                          showCloseAndNotify: true,
                          showLoader: false
                        })
                      )
                      .catch(e => {
                        console.log(e);
                        this.setState({ showLoader: false });
                      });
                  } else {
                    NotificationManager.error(
                      this.props.t("it_owner_or_higher_to_close_list"),
                      "",
                      10000
                    );
                  }
                }}
              >
                {t("it_close_list_after_stock_exchange_notice")}
              </MenuItem>
            )}
            {!hasEditAccess(listId) ||
            insiderListInfo.listState === "closed" ? (
              undefined
            ) : (
              <MenuItem
                onClick={() => {
                  if (isOwnerOrHigher) {
                    Promise.resolve(this.setState({ showLoader: true }))
                      .then(() => this.props.dispatch(reset("closeListForm")))
                      .then(() => {
                        listType === InsiderType.CONFIDENTIALITY_LIST
                          ? this.props.dispatch(
                              fetchTemplate("", "closeConfList")
                            )
                          : this.props.dispatch(fetchTemplate("", "closeList"));
                      })
                      .then(
                        this.setState({
                          showCloseList: true,
                          showLoader: false
                        })
                      )
                      .catch(e => {
                        console.log(e);
                        this.setState({ showLoader: false });
                      });
                  } else {
                    NotificationManager.error(
                      this.props.t("it_owner_or_higher_to_close_list"),
                      "",
                      10000
                    );
                  }
                }}
              >
                {listType === InsiderType.CONFIDENTIALITY_LIST
                  ? t("close_conf_list")
                  : t("close_list_without_sending")}
              </MenuItem>
            )}

            {listOpened && insiderListInfo.listState === "closed" ? (
              <MenuItem
                onClick={() => {
                  if (isAdmin) {
                    this.setState({ showReopenModal: true });
                  } else {
                    NotificationManager.error(
                      this.props.t("it_talk_to_admin_to_re_open_list"),
                      "",
                      10000
                    );
                  }
                }}
              >
                {t("it_open_already_closed_list")}
              </MenuItem>
            ) : (
              undefined
            )}

            {/*TODO: Re-apply when we have a way to give superusers access to lists*/}
            {/*<MenuItem*/}
            {/*onClick={() =>*/}
            {/*this.setState({ showCloseAndNotifyAuthority: true })*/}
            {/*}*/}
            {/*>*/}
            {/*{listType === InsiderType.CONFIDENTIALITY_LIST*/}
            {/*? ""*/}
            {/*: t("send_to_authority")}*/}
            {/*</MenuItem>*/}
            <MenuItem
              onClick={() => this.exportList(this.props.historicTimestamp)}
            >
              {listType !== InsiderType.CONFIDENTIALITY_LIST
                ? this.props.inHistoricMode
                  ? t("export_current_list_to_pdf")
                  : t("export_list_to_pdf")
                : this.props.inHistoricMode
                  ? t("export_current_conf_list_to_pdf")
                  : t("export_conf_list_to_pdf")}
            </MenuItem>

            <MenuItem
              onClick={() => this.exportCSVList(this.props.historicTimestamp)}
            >
              {listType !== InsiderType.CONFIDENTIALITY_LIST
                ? this.props.inHistoricMode
                  ? t("export_current_list_to_csv")
                  : t("export_list_to_csv")
                : this.props.inHistoricMode
                  ? t("export_current_conf_list_to_csv")
                  : t("export_conf_list_to_csv")}
            </MenuItem>

            {listType === InsiderType.CONFIDENTIALITY_LIST ||
            !hasEditAccess(listId) ||
            insiderListInfo.listState === "closed" ? (
              ""
            ) : (
              <MenuItem
                id={"sendNotificationToAuthority"}
                onClick={() => {
                  NotificationManager.warning(
                    this.props.t("it_will_be_activated_when_mar_is_applicable"),
                    "",
                    10000
                  );
                  // Promise.resolve(this.setState({ showLoader: true }))
                  //   .then(() =>
                  //     this.props.dispatch(fetchTemplate("", "closeAndNotify"))
                  //   )
                  //   .then(this.setState({ showCloseAndNotify: true }))
                  //   .catch(e => console.log(e));
                }}
              >
                {t("close_list_and_send_to_authority")}
              </MenuItem>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <MenuItem
              onClick={() => {
                this.setState({ showLoader: true });
                dispatch(fetchInsiderList(listId))
                  .then(() => {
                    this.setState({
                      showPrivilegesModal: true,
                      showLoader: false
                    });
                  })
                  .catch(() => this.setState({ showLoader: false }));
              }}
            >
              {t("list_access")}
            </MenuItem>

            <MenuItem
              onClick={() => {
                this.setState({ showNewInsiderListModal: true });
              }}
            >
              {t("use_as_template_for_insider_list")}
            </MenuItem>
            <MenuItem
              onClick={() => {
                this.setState({ showNewConfListModal: true });
              }}
            >
              {t("use_as_template_for_confidentiality_list")}
            </MenuItem>

            {!hasEditAccess(listId) ? (
              undefined
            ) : (
              <MenuItem
                onClick={() => {
                  if (this.props.row.data.listState !== "closed") {
                    NotificationManager.warning(
                      this.props.t("it_list_is_not_closed_yet"),
                      "",
                      5000
                    );
                  } else {
                    this.setState({ showAdministerStorageTime: true });
                  }
                }}
              >
                {t(
                  insiderListSubType === InsiderType.LIST
                    ? "administer_storage_time"
                    : "administer_storage_time_conf"
                )}
              </MenuItem>
            )}
          </React.Fragment>
        )}
      </MenuBox>,

      this.state.showEditPrivilegesModal ? (
        <PrivelegesModalForm
          key={"editPrivelegeModalForm"}
          t={t}
          listId={listId}
          edit={true}
          toggleShowEditPrivilegesModal={() =>
            this.setState({
              showEditPrivilegesModal: !this.state.showEditPrivilegesModal
            })
          }
          listType={listType}
          listOpened={listOpened}
          InsiderType={InsiderType}
        />
      ) : (
        undefined
      ),

      this.state.showCloseAndNotify && emailTemplate.body !== undefined ? (
        <CloseAndNotifyList
          toggleShowCloseAndNotify={() =>
            this.setState({
              showCloseAndNotify: !this.state.showCloseAndNotify
            })
          }
          listType={listType}
          insiderListInfo={insiderListInfo}
          replaceKeysInString={replaceKeysInString}
          updateMandatoryNCA={this.updateNCAWithReferenceToAnnouncement}
          listId={listId}
          enableReinitialize
          referenceHasValue={this.referenceHasValue}
          sendMail={sendMail}
        />
      ) : (
        undefined
      ),

      this.state.showCloseList && emailTemplate.body !== undefined ? (
        <CloseList
          toggleShowCloseList={() =>
            this.setState({
              showCloseList: !this.state.showCloseList
            })
          }
          listType={listType}
          insiderListInfo={insiderListInfo}
          replaceKeysInString={replaceKeysInString}
          listId={listId}
          sendMail={sendMail}
          userList={usersWithAccessToIT}
        />
      ) : (
        undefined
      ),
      this.state.showCloseAndNotifyAuthority ? (
        <Modal
          key={"showCloseAndNotifyAuthorityModal"}
          header={t("important_information")}
          isOpen={this.state.showCloseAndNotifyAuthority}
          onClose={() => this.setState({ showCloseAndNotifyAuthority: false })}
        >
          <CloseAndNotifyAuthority
            pageOne={this.state.pageOne}
            pageTwo={this.state.pageTwo}
            onPageTwo={() =>
              this.setState({
                pageTwo: !this.state.pageOne,
                pageOne: !this.state.pageTwo
              })
            }
            t={t}
            userList={usersWithAccessToIT}
            onSubmit={values => {
              console.log(values);
              console.log(listId);
            }}
            onCancel={() => {
              this.props.dispatch(reset("closeAndNotifyAuthorityForm"));
              this.setState({
                showCloseAndNotifyAuthority: false,
                pageOne: false,
                pageTwo: false
              });
            }}
            listId={listId}
          />
        </Modal>
      ) : (
        undefined
      ),
      this.state.showPrivilegesModal ? (
        <Modal
          key={"privilegesModal"}
          header={t("list_access")}
          isOpen={this.state.showPrivilegesModal}
          onClose={() => this.setState({ showPrivilegesModal: false })}
        >
          <PrivelegesModalForm
            key={"privelegeModalForm"}
            t={t}
            userList={getUsersWithName()}
            listId={listId}
            edit={false}
            toggleShowEditPrivilegesModal={() =>
              this.setState({
                showPrivilegesModal: !this.state.showPrivilegesModal
              })
            }
          />
        </Modal>
      ) : (
        undefined
      ),
      this.state.showNewInsiderListModal ? (
        <Modal
          key={"newConfListModal"}
          header={t("it_insider_list_based_on_old_list", {
            listName: listName
          })}
          isOpen={this.state.showNewInsiderListModal}
          onClose={() => this.setState({ showNewInsiderListModal: false })}
        >
          <CreateNewListModal
            t={t}
            onSubmit={() => {
              this.setState({ showLoader: true });
              this.props
                .dispatch(createListBasedOnOtherList(listId, InsiderType.LIST))
                .then(response => {
                  const id = this.getIdFromList(response);
                  return this.props
                    .dispatch(fetchInsiderList(id))
                    .then(() =>
                      this.props.history.push(
                        `${this.props.base}/${InsiderType.LIST}/${id}`
                      )
                    );
                })
                .catch(e => {
                  console.log(e);
                  this.setState({ showLoader: false });
                });
            }}
            onSubmitLabel={"create_insider_list"}
            onCancel={() => {
              this.setState({
                showNewInsiderListModal: false
              });
            }}
            listType={InsiderType.LIST}
            isBasedOnOtherList
          />
        </Modal>
      ) : (
        undefined
      ),
      this.state.showNewConfListModal ? (
        <Modal
          key={"newConfListModal"}
          header={t("it_conf_list_based_on_old_list", { listName: listName })}
          isOpen={this.state.showNewConfListModal}
          onClose={() => this.setState({ showNewConfListModal: false })}
        >
          <CreateNewListModal
            t={t}
            onSubmit={() => {
              this.setState({ showLoader: true });
              this.props
                .dispatch(
                  createListBasedOnOtherList(
                    listId,
                    InsiderType.CONFIDENTIALITY_LIST
                  )
                )
                .then(response => {
                  const id = this.getIdFromList(response);
                  return this.props
                    .dispatch(fetchInsiderList(id))
                    .then(() =>
                      this.props.history.push(
                        `${this.props.base}/${
                          InsiderType.CONFIDENTIALITY_LIST
                        }/${id}`
                      )
                    );
                })
                .catch(e => {
                  console.log(e);
                  this.setState({ showLoader: false });
                });
            }}
            onSubmitLabel={"new_insider_project_list"}
            onCancel={() => {
              this.setState({
                showNewConfListModal: false
              });
            }}
            listType={InsiderType.CONFIDENTIALITY_LIST}
            isBasedOnOtherList
          />
        </Modal>
      ) : (
        undefined
      ),
      this.state.showAdministerStorageTime ? (
        <Modal
          key={"showAdministerStorageTime"}
          header={storageAdministrationHeader}
          isOpen={this.state.showAdministerStorageTime}
          onClose={() => this.setState({ showAdministerStorageTime: false })}
        >
          <AdministerStorageTimeModal
            t={t}
            onSubmit={values => {
              return new Promise((resolve, reject) => {
                const errors = {};
                if (!values.deletion_date) {
                  errors.deletion_date = t("required_field");
                }
                if (
                  insiderListSubType === InsiderType.LIST &&
                  values.deletion_date &&
                  moment(values.deletion_date).isBefore(
                    moment(values.closed_date, OBNTInsiderToolDateFormat)
                      .startOf("day")
                      .add(5, "years")
                  )
                ) {
                  errors.deletion_date = t(
                    "it_date_has_to_be_five_years_after_closing_of_the_list"
                  );
                }

                if (Object.keys(errors).length > 0) {
                  reject(new SubmissionError(errors));
                } else {
                  this.props
                    .dispatch(
                      updateInsiderListData(insiderListSubType, values, listId)
                    )
                    .then(() => {
                      this.setState({ showAdministerStorageTime: false });
                      return this.props.dispatch(fetchInsiderListList());
                    })
                    .catch(e => console.log(e));
                }
              });
            }}
            onCancel={() => this.setState({ showAdministerStorageTime: false })}
            disableDaysBefore={getDisableDaysBefore()}
            initialValues={{
              ...row.data
            }}
          />
        </Modal>
      ) : (
        undefined
      ),
      this.state.showReopenModal ? (
        <ConfirmModal
          key={"reopenModal"}
          isOpen={this.state.showReopenModal}
          onClose={() => this.setState({ showReopenModal: false })}
          header={t("it_open_already_closed_list")}
          onConfirm={() => {
            Promise.resolve(this.setState({ showLoader: true })).then(() => {
              this.props
                .dispatch(reopenList(listId))
                .then(() => this.props.dispatch(fetchInsiderList(listId)))
                .then(
                  this.setState({ showLoader: false, showReopenModal: false })
                )
                .catch(e => {
                  console.log(e);
                  this.setState({ showLoader: false });
                });
            });
          }}
          confirmText={t("it_open_already_closed_list")}
          cancelText={t("cancel")}
        >
          <FlexContainer center vAlignCenter margin={[10, 0, 30, 0]}>
            {t("it_are_you_sure_you_want_to_reopen_list")}
          </FlexContainer>
        </ConfirmModal>
      ) : (
        undefined
      ),
      this.state.showLoader ? <OverlayLoader key={"loader"} /> : undefined
    ];
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer, permissionReducer, form, loginUser } = state;

  const inHistoricMode = insiderToolReducer.insiderList.historicMode;

  var hasEditAccess = undefined;

  if (insiderToolReducer.insiderListList.mapHasEditAccess != null)
    hasEditAccess = listId =>
      insiderToolReducer.insiderListList.mapHasEditAccess.get(listId) &&
      !inHistoricMode;
  else
    hasEditAccess = _ =>
      insiderToolReducer.insiderList.insiderListInfo.hasEditAccess &&
      !inHistoricMode;

  return {
    loginUserId: loginUser.user.globalSubject,
    usersWithAccessToIT: insiderToolReducer.userList.list,
    usersWithAccessToList: insiderToolReducer.insiderList.users,
    usersWithAccessToListMap: insiderToolReducer.insiderList.usersMap,
    insiderListInfo: insiderToolReducer.insiderList.insiderListInfo.data,
    mandatoryNCAInfo:
      insiderToolReducer.insiderList.mandatoryNCAData.data || {},

    hasEditAccess,

    historicTimestamp: insiderToolReducer.insiderList.historicTimestamp,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    emailTemplate: insiderToolReducer.template.template,
    permissionList: permissionReducer.permissions || [],
    closeAndNotifyListFormValues:
      form.closeAndNotifyListForm && form.closeAndNotifyListForm.values
        ? form.closeAndNotifyListForm.values
        : undefined,
    isAdmin: insiderToolReducer.insiderList.insiderListInfo.isAdmin,
    isOwnerOrHigher:
      insiderToolReducer.insiderList.insiderListInfo.isOwnerOrHigher,
    inHistoricMode: insiderToolReducer.insiderList.historicMode
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(MenuBoxContainer)
);
