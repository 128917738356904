import { withTranslation } from "react-i18next";
import React from "react";
import { FlexContainer } from "../../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import Button from "../../components/Button";
import {
  Icon,
  InputBox,
  TextAreaBox,
  TextBox,
  Attachment
} from "../components";
import Label from "../../components/Label";
import SimpleFileUploader from "../../components/SimpleFileUploader";
import CreatableSelect from "../../components/CreatableSelect";

const AttachmentBox = ({
  t,
  attachments,
  openAttachment,
  removeAttachment,
  inEditMode
}) => (
  <FlexContainer column>
    <Label margin={[0, 0, 8, 0]}>{t("attachments")}:</Label>
    <FlexContainer
      pre
      scrollbarsOnOverflow
      whiteBackground
      padding={[0, 10, 0, 10]}
    >
      {attachments.map((file, index) => (
        <Attachment
          key={index}
          noIcon
          onClick={() => openAttachment(file.id, file.name)}
          onClickDelete={() =>
            removeAttachment ? removeAttachment(file.id) : undefined
          }
          showDelete={inEditMode}
        >
          {file.name}
        </Attachment>
      ))}
    </FlexContainer>
  </FlexContainer>
);

class Document extends React.Component {
  componentWillMount() {
    this.props.fetchInsiderListDocument();
  }

  render() {
    const { t, inEditMode, typeList } = this.props;

    return (
      <FlexContainer>
        <FlexContainer margin={[0, 10, 0, 0]} column width={"60%"}>
          <Field
            component={!inEditMode ? TextBox : InputBox}
            name="title"
            bold
            label={t("title")}
            width={"40%"}
          />

          <FlexContainer margin={[20, 0, 0, 0]}>
            {inEditMode ? <Label bold>{t("Type") + ":"}</Label> : ""}
            <Field
              border
              name={"type"}
              label={t("type")}
              component={!inEditMode ? TextBox : CreatableSelect}
              width={"40%"}
              isSorted={true}
              searchable={true}
              options={typeList}
              onNewOptionClick={this.props.onNewContact}
              placeholder={t("it_select_or_create_new_type")}
              t={this.props.t}
            />
          </FlexContainer>

          <Field
            border
            bold
            margin={[20, 0, 0, 0]}
            name="message"
            label={t("it_information")}
            component={!inEditMode ? TextBox : TextAreaBox}
            noResize
          />

          <FlexContainer row margin={[20, 0, 0, 0]}>
            <AttachmentBox {...this.props} />
            {inEditMode ? (
              <FlexContainer margin={[10, 0, 0, 0]} vAlignEnd>
                <SimpleFileUploader
                  uploadFile={file => this.props.onUploadAttachment(file)}
                  fileValidator={file => undefined}
                >
                  {t("upload_files")}
                </SimpleFileUploader>
              </FlexContainer>
            ) : (
              ""
            )}
          </FlexContainer>
        </FlexContainer>

        <FlexContainer row vAlignCenter margin={[60, 0, 25, 0]}>
          {inEditMode ? (
            <Button.Standard
              margin={[0, 24, 0, 0]}
              onClick={this.props.handleSubmit}
            >
              {t("save")}
            </Button.Standard>
          ) : (
            undefined
          )}

          {inEditMode ? (
            <Button.Text onClick={this.props.onCancel}>
              {t("cancel")}
            </Button.Text>
          ) : (
            <FlexContainer row vAlignCenter onClick={this.props.onCancel}>
              <Icon margin={[0, 8, 0, 0]} src="/icons/back-arrow-24-px.svg" />
              <Button.Text>{t("back")}</Button.Text>
            </FlexContainer>
          )}
        </FlexContainer>
      </FlexContainer>
    );
  }
}

export default (Document = reduxForm({
  form: "documentForm",
  enableReinitialize: true
})(withTranslation("translations")(Document)));
