import React from "react";
import { Field, reduxForm } from "redux-form";

import { FlexContainer } from "../../components/FlexContainer";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { PageHeading } from "../../components/PageHeading";
import { ErrorText } from "../../components/ErrorText";

const MFAInputBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer center vAlignCenter>
      {error ? <ErrorText>{props.t(error)}</ErrorText> : undefined}
      <Input margin={[8, 0, 0, 0]} {...props} />
    </FlexContainer>
  );
};

class InsiderApproveTwoFactor extends React.Component {
  render() {
    const { t, handleSubmit, submitDisabled } = this.props;
    return (
      <FlexContainer maxWidth={767}>
        <form onSubmit={handleSubmit}>
          <FlexContainer
            margin={[75, 0, 0, 0]}
            padding={[40, 40, 40, 40]}
            blueBackground
            vAlignCenter
            column
          >
            <PageHeading margin={[0, 0, 20, 0]} uppercase color="white">
              {t("login")}
            </PageHeading>

            <FlexContainer vAlignCenter>
              <Field
                name="mfacode"
                fontSize="16px"
                component={MFAInputBox}
                greyPlaceholder
                maxWidth={460}
                placeholder={t("mfa_code")}
                type="text"
                t={t}
              />

              <FlexContainer row center margin={[40, 0, 0, 0]}>
                <Button.Form
                  type="submit"
                  inactive={submitDisabled}
                  disabled={submitDisabled}
                >
                  {t("login")}
                </Button.Form>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

export default reduxForm({
  form: "insiderTwoFactor"
})(InsiderApproveTwoFactor);
