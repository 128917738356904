import { parsePhoneNumberFromString } from "libphonenumber-js";

export const exportKnownPersons = (knownPersonList, countryOptions) => {
  let csvString = "";

  // If we are to not include the 'headers' the next line should be removed
  csvString =
    "email;firstname;lastname;birthLastname;workPhoneNumber;workMobilePhoneNumber;companyname;companyaddress;companypostalCode;companycity;companycountry;jobDescription;birthday;ssn;personalPhoneNumber;personalMobilePhoneNumber;address;postalCode;city;country\r\n";
  // sets distinct email of knownperson to a filtred array
  const filteredKnownPersonList = [];
  const map = new Map();
  for (const item of knownPersonList) {
    if (!map.has(item.data.email)) {
      map.set(item.data.email);
      filteredKnownPersonList.push({
        ...item.data
      });
    }
  }

  filteredKnownPersonList.forEach(person => {
    const data = person;
    if (data.email && data.email !== "") {
      csvString += getValue(data.email) + ";";
      csvString += getValue(data.first_name) + ";";
      csvString += getValue(data.surname) + ";";
      csvString += getValue(data.birth_surname) + ";";
      csvString += getPhoneNumber(data.phone_work) + ";";
      csvString += getPhoneNumber(data.phone_work_2) + ";";

      // Add company-information if company_name is present - else add empty-fields
      if (data.company_name && data.company_name !== "") {
        csvString += getValue(data.company_name) + ";";
        csvString += getValue(data.company_address) + ";";
        csvString += getValue(data.company_post_code) + ";";
        csvString += getValue(data.company_city) + ";";
        csvString +=
          getValue(getCountryName(countryOptions, data.company_country)) + ";";
      } else {
        csvString += ";;;;;";
      }
      csvString += getValue(data.insider_function_and_reason) + ";";
      csvString += getValue(data.date_of_birth) + ";";
      csvString += getValue(data.idnumber) + ";";
      csvString += getPhoneNumber(data.phone_private) + ";";
      csvString += getPhoneNumber(data.phone_private_2) + ";";
      csvString += getValue(data.address) + ";";
      csvString += getValue(data.post_code) + ";";
      csvString += getValue(data.city) + ";";
      csvString +=
        getValue(getCountryName(countryOptions, data.country)) + "\r\n";
    }
  });

  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const fileName = "exportedKnownPersons.csv";
  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(blob, fileName);
  } else {
    let link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const getPhoneNumber = number => {
  if (number) {
    const parsedNumber = parsePhoneNumberFromString(number);
    if (parsedNumber && parsedNumber.isValid()) {
      return number;
    }
  }
  return "";
};

const getValue = field => {
  return field ? field : "";
};

const getCountryName = (countryOptions, countryCode) => {
  const country = countryOptions.find(country => country.id === countryCode);
  if (country) {
    return country.nameEn ? country.nameEn : country.nameNo;
  }
  return countryCode;
};
