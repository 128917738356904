import { combineReducers } from "redux";

import {
  REQUEST_LOGIN_FAILURE,
  RECEIVE_LOGIN_PHASE_ONE,
  RECEIVE_LOGIN_PHASE_TWO,
  LOG_OUT,
  LOGIN_WITH_TOKEN,
  REQUEST_LOGIN_PHASE_TWO,
  REQUEST_LOGIN_PHASE_ONE,
  MISSING_TWO_FACTOR_DATA_ERROR_CODE
} from "./LoginActions";
import {
  PASSWORD_CHANGE_FAIL,
  RENEW_TOKEN,
  RENEW_TOKEN_SUCCESS,
  RENEW_TOKEN_FAIL
} from "../actions/CommonActions";

const initalState = {
  loginSuccessPhase1: false,
  loginSuccessPhase2: false,
  additionalSteps: false,
  changePasswordRequired: false,
  loginError: undefined,
  tokenRenewalInProgress: false,
  tokenRenewalFailed: false,
  userLoginWithToken: false
};

export const login = (
  state = {
    ...initalState
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_LOGIN_PHASE_ONE:
      return Object.assign({}, state, {
        loginSuccessPhase1: true,
        additionalSteps: action.data.additionalSteps,
        changePasswordRequired: action.data.changePasswordRequired,
        loginError: undefined
      });
    case RECEIVE_LOGIN_PHASE_TWO:
      return Object.assign({}, state, {
        loginSuccessPhase2: true,
        additionalSteps: false,
        changePasswordRequired: action.data.changePasswordRequired
      });

    case PASSWORD_CHANGE_FAIL:
      return Object.assign({}, state, initalState);

    case LOG_OUT: // Reset login variables when we log out the user
      return Object.assign({}, state, initalState);

    case REQUEST_LOGIN_FAILURE:
      let error = action.error;
      if (error && error.data && error.data.details) {
        const details = error.data.details;
        const error1240 = details.find(
          detail => detail.code === MISSING_TWO_FACTOR_DATA_ERROR_CODE
        );
        if (error1240) {
          error = MISSING_TWO_FACTOR_DATA_ERROR_CODE;
        }
      }
      return Object.assign({}, state, {
        ...initalState,
        loginError: error
      });

    case RENEW_TOKEN:
      return Object.assign({}, state, { tokenRenewalInProgress: true });

    case RENEW_TOKEN_FAIL:
      return Object.assign({}, state, {
        tokenRenewalInProgress: false,
        tokenRenewalFailed: true
      });

    case RENEW_TOKEN_SUCCESS:
      return Object.assign({}, state, { tokenRenewalInProgress: false });

    case LOGIN_WITH_TOKEN:
      return Object.assign({}, state, { userLoginWithToken: true });

    default:
      return state;
  }
};

const loginReducer = combineReducers({
  login
});

export default loginReducer;
