import React from "react";
import styled from "styled-components";
import {FlexContainer} from "./FlexContainer";
import Label from "./Label";

export const StyledInput = styled.input`
  box-sizing: border-box;
  border: 0;
  height: 35px;
  text-overflow: ellipsis;
  width: 100%;
  border-radius: 0px;
  padding-left: 16px;
  font-size: 14px;
  font-family: inherit;

  &::placeholder {
    color: black;
    ${props => (props.greyPlaceholder ? "color: rgba(0, 0, 0, 0.6);" : "")}
  }
  &:-ms-input-placeholder {
    color: black;
    ${props => (props.greyPlaceholder ? "color: rgba(0, 0, 0, 0.6);" : "")}
  }

  ${props => (props.transparent ? "background: rgba(1,1,1,0);" : "")}
  ${props => (props.border ? "border: solid 1px #d0d0d0;" : "")}
    ${props =>
      props.maxWidth ? "max-width: " + props.maxWidth + "px;width:100%;" : ""}
      ${props => (props.width ? "width: " + props.width : "")};

  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
  ${props => (props.isHidden ? "display:none;" : "")}
    
    ${props =>
      props.hasError
        ? "border: 1px solid #cc0033; outline: none; background-color: #fce4e4;"
        : ""}
    ${props => (props.fontSize ? "font-size: " + props.fontSize : "")}
    ${props =>
      props.background ? "background-color: " + props.background : ""}
`;

const Input = props => {
  return (
      <StyledInput autoComplete="off" {...props.input} {...props} />)
};

export const InputLabel = props => {
  return (
      <FlexContainer>
        <Label bold={props.bold} margin={[5, 0, 2, 0]}>
          {props.label}
        </Label>
        <StyledInput autoComplete="off" {...props.input} {...props} />
      </FlexContainer>
  )
};

export default Input;
