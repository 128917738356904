import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {Field, reduxForm, reset} from "redux-form";
import {Icon, InputBox, TextAreaBox} from "../insiderTool/components";
import Button from "../components/Button";
import SimpleFileUploader from "../components/SimpleFileUploader";
import Label from "../components/Label";
import {NotificationManager} from "react-notifications";
import Table, {TD, TR} from "../components/Table";
import {getFormattedTime, getOBNTDateTime} from "../util/common";
import CheckBox from "../components/CheckBox";
import {TextTip, TextTipContainer} from "../emission/ShareIssuanceForm";
import {getEmailStatus, sendEmail} from "./StatusActions";

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

async function getBase64(file) {
    const result = await toBase64(file).catch(e => e);
    if (result instanceof Error) {
        console.log("Error: ", result.message);
        return;
    }
    return result;
}

const Attachment = ({onClickDelete, children}) => (
    <FlexContainer row onClick={onClickDelete}>
        <Icon
            margin={[0, 10, 0, 0]}
            pointer
            src="/icons/red-cross-24.svg"
            alt={"DELETE"}
            onClick={onClickDelete}
        />
        <Label>{children}</Label>
    </FlexContainer>
);

const AttachmentBox = (props) => {
    return (
        <FlexContainer column>
            <FlexContainer pre scrollbarsOnOverflow>
                {props.attachments.map((file, index) => (
                    <Attachment
                        key={index}
                        onClickDelete={() => props.onDelete(file)}
                    >
                        {file.name}
                    </Attachment>
                ))}
            </FlexContainer>
        </FlexContainer>
    );
};

class EmailStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            attachmentList: [],
            status: [],
            time: undefined,
            direct: false
        };
    }

    componentDidMount() {
        this.fetchStatus()
    }

    fetchStatus() {
        this.props.dispatch(getEmailStatus())
            .then(() => {
                this.setState({time: getFormattedTime()})
            })
    }

    onUploadAttachment = file => {
        getBase64(file).then(res => {
            let obj = {
                name: file.name,
                data: res.split(",")[1]
            };

            let tempAttachmentList = this.state.attachmentList;
            tempAttachmentList.push(obj);
            this.setState({
                attachmentList: tempAttachmentList,
            });
        });
    };

    onDeleteAttachment = (attachmentToDelete) => {
        const updatedAttachments = this.state.attachmentList
            .filter((att) =>
                att !== attachmentToDelete
            )
        this.setState({attachmentList: updatedAttachments})
    }

    onSend = (vals) => {
        if (this.state.attachmentList.length > 0) {
            vals["attachments"] = this.state.attachmentList;
        }
        this.props.dispatch(sendEmail(vals))
            .then(() => {
                NotificationManager.success(this.props.t("success"));
                this.props.dispatch(reset("emailStatus"));
                this.setState({attachmentList: []})
                this.fetchStatus()
        })
            .catch(() => {
                NotificationManager.error(this.props.t("an_error_occured"), "", 0);
            })
    }

    render() {
        const { t } = this.props;
        const tableHeaderLabels = [t("to"), t("subject"), t("time"), t("status"), t("reason")];
        const tableHeaderProps = [undefined, undefined, undefined, undefined, undefined];
        const tableRows = this.props.emailStatus?.map((row, index) => {
            return  (
                <TR key={index}>
                    <TD ellipsis>{row.to}</TD>
                    <TD>{row.subject}</TD>
                    <TD ellipsis>{getOBNTDateTime(row.createdTime)}</TD>
                    <TD>{row.status}</TD>
                    <TD >
                        <FlexContainer>
                            <TextTipContainer compressed>
                                {row.reason?.length > 80 ?
                                    <FlexContainer>
                                        <TextTip rounded compressed>{row.reason}</TextTip>
                                        {row.reason.substring(0, 80) + '...'}
                                    </FlexContainer>
                                     : row.reason}
                            </TextTipContainer>
                        </FlexContainer>
                    </TD>
                </TR>
            )
        })

        return (
            <FlexContainer trueCenter>
                <FlexContainer width={"80%"} greyBackground padding={[5,15,15,15]}>
                    <form>
                        <FlexContainer row>
                            <Field
                                name={"to"}
                                label={t("to")}
                                component={InputBox}
                            />
                            <FlexContainer width={"10%"}/>
                            <Field
                                name={"from"}
                                label={t("from")}
                                component={InputBox}
                            />
                        </FlexContainer>
                        <FlexContainer>
                            <Field
                                margin={[10,0,0,0]}
                                name={"subject"}
                                label={t("subject")}
                                component={InputBox}
                            />
                            <Field
                                margin={[10,0,0,0]}
                                name={"body"}
                                label={t("message")}
                                component={TextAreaBox}
                                border
                            />
                        </FlexContainer>


                        <FlexContainer margin={[20, 0, 0, 0]}>
                            <AttachmentBox
                                attachments={this.state.attachmentList}
                                onDelete={this.onDeleteAttachment}
                                {...this.props}
                            />
                            <FlexContainer row vAlignCenter margin={[20, 0, 0, 0]}>
                                <FlexContainer row vAlignCenter>
                                    <SimpleFileUploader
                                        fileValidator={() => undefined}
                                        uploadFile={file => this.onUploadAttachment(file)}
                                    >
                                        {t("upload_file")}
                                    </SimpleFileUploader>
                                </FlexContainer>

                                <FlexContainer row vAlignCenter flexEnd>

                                    <Label>{t("email_direct")}</Label>
                                    <Field
                                        checked={this.state.direct}
                                        name={"direct"}
                                        margin={[0,15,0,5]}
                                        onChange={() =>
                                            this.setState({
                                                direct: !this.state.direct
                                            })
                                        }
                                        component={CheckBox}
                                    />
                                    <Button.Standard
                                        onClick={this.props.handleSubmit(this.onSend)}
                                    >
                                        {t("submit")}
                                    </Button.Standard>
                                </FlexContainer>

                            </FlexContainer>


                        </FlexContainer>
                    </form>
                </FlexContainer>


                <FlexContainer margin={[20,0,0,0]} >
                    <FlexContainer row vAlignCenter spaceBetween>
                            <Label fontSize={18} bold>Status ({this.state.time}):</Label>
                            <Button.Standard margin={[2,2,2,2]}
                                onClick={() => this.fetchStatus()}>{t("refresh")}
                            </Button.Standard>
                    </FlexContainer>
                    {this.props.statusFetched &&
                        <FlexContainer>
                            {this.props.emailStatus.length > 0 ?
                                <Table
                                    tableRows={tableRows}
                                    tableHeaderLabels={tableHeaderLabels}
                                    tableHeaderProps={tableHeaderProps}
                                    t={t}
                                    forceUpdate={true}
                                /> :
                                <Label>{t("email_status_no_failed")}</Label>}
                        </FlexContainer>
                    }
                </FlexContainer>
            </FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const { statusReducer } = state;

    return {
        emailStatus: statusReducer.emailStatus.list,
        statusFetched: statusReducer.emailStatus.isFetched
    };
}

export default reduxForm({form: "emailStatus"})(connect(mapStateToProps)(withTranslation("translations")(EmailStatus)));
