import {
  deleteData,
  fetchData,
  postData,
  putData,
} from "../actions/CommonActionsNoObsvc";

export const EMAIL_DIST_ROOT = "v1/emaildist/";

export const EmailDistributionActions = {
  request: "REQUEST_DISTRIBUTION_LISTS",
  receive: "RECEIVE_DISTRIBUTION_LISTS",
  receiveFail: "RECEIVE_DISTRIBUTION_LISTS_FAILED",
};

export const fetchDistributionLists = (orgId, showServerError = true) => {
  return fetchData(
    EmailDistributionActions,
    {
      orgId,
    },
    EMAIL_DIST_ROOT + "lists",
    showServerError
  );
};

export const EmailDistributionListByIdActions = {
  request: "REQUEST_DISTRIBUTION_LIST_BY_ID",
  receive: "RECEIVE_DISTRIBUTION_LIST_BY_ID",
  receiveFail: "RECEIVE_DISTRIBUTION_LIST_BY_ID_FAILED",
};

export const fetchDistributionListById = (listId) => {
  return fetchData(
    EmailDistributionListByIdActions,
    {},
    EMAIL_DIST_ROOT + "lists/" + listId
  );
};

export const EmailDistributionStatusActions = {
  request: "REQUEST_DISTRIBUTION_STATUS_BY_ISSUER_ID",
  receive: "RECEIVE_DISTRIBUTION_STATUS_BY_ISSUER_ID",
  receiveFail: "RECEIVE_DISTRIBUTION_STATUS_BY_ISSUER_ID",
};
export const fetchDistributionStatus = (
  issuerId,
  messageId,
  email,
  fromDate,
  toDate
) => {
  return fetchData(
    EmailDistributionStatusActions,
    {
      issuerId,
      messageId,
      email,
      fromDate,
      toDate,
    },
    EMAIL_DIST_ROOT + "status"
  );
};

export const EmailDistributionContactsByListIdActions = {
  request: "REQUEST_DISTRIBUTION_CONTACTS_BY_ID",
  receive: "RECEIVE_DISTRIBUTION_CONTACTS_BY_ID",
  receiveFail: "RECEIVE_DISTRIBUTION_CONTACTS_BY_ID",
};

export const fetchDistributionContactsByListId = (listId) => {
  return fetchData(
    EmailDistributionContactsByListIdActions,
    undefined,
    EMAIL_DIST_ROOT + "contacts/" + listId
  );
};

export const CreateDistributionList = {
  request: "CREATE_DISTRIBUTION_LIST",
  postFail: "CREATE_DISTRIBUTION_LIST_FAILED",
  postSuccess: "CREATE_DISTRIBUTION_LIST_SUCCESS",
};

export const createDistributionList = (distributionList) =>
  postData(
    CreateDistributionList,
    undefined,
    distributionList,
    EMAIL_DIST_ROOT + "lists"
  );

export const CreateDistributionContact = {
  request: "CREATE_DISTRIBUTION_CONTACT",
  postFail: "CREATE_DISTRIBUTION_CONTACT_FAILED",
  postSuccess: "CREATE_DISTRIBUTION_CONTACT_SUCCESS",
};

export const createDistributionContact = (distributionContact, listId) =>
  postData(
    CreateDistributionContact,
    undefined,
    distributionContact,
    EMAIL_DIST_ROOT + "contacts/" + listId
  );

export const UpdateDistributionContact = {
  request: "UPDATE_DISTRIBUTION_CONTACT",
  putFail: "UPDATE_DISTRIBUTION_CONTACT_FAILED",
  putSuccess: "UPDATE_DISTRIBUTION_CONTACT_SUCCESS",
};

export const updateDistributionContact = (distributionContact, id) =>
  putData(
    UpdateDistributionContact,
    undefined,
    distributionContact,
    EMAIL_DIST_ROOT + "contacts/" + id
  );

export const UpdateDistributionList = {
  request: "UPDATE_DISTRIBUTION_LIST",
  putFail: "UPDATE_DISTRIBUTION_LIST_FAILED",
  putSuccess: "UPDATE_DISTRIBUTION_LIST_SUCCESS",
};

export const updateDistributionList = (distributionListInfo, id) =>
  putData(
    UpdateDistributionList,
    undefined,
    distributionListInfo,
    EMAIL_DIST_ROOT + "lists/" + id
  );

export const DeleteDistributionContact = {
  request: "DELETE_DISTRIBUTION_CONTACT",
  deleteFail: "DELETE_DISTRIBUTION_CONTACT_FAILED",
  deleteSuccess: "DELETE_DISTRIBUTION_CONTACT_SUCCESS",
};

export const deleteDistributionContact = (id) =>
  deleteData(DeleteDistributionContact, EMAIL_DIST_ROOT + "contacts/" + id);

export const DeleteDistributionList = {
  request: "DELETE_DISTRIBUTION_LIST",
  deleteFail: "DELETE_DISTRIBUTION_LIST_FAILED",
  deleteSuccess: "DELETE_DISTRIBUTION_LIST_SUCCESS",
};

export const deleteDistributionListAction = (listId) =>
  deleteData(DeleteDistributionList, EMAIL_DIST_ROOT + "lists/" + listId);
