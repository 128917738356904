import React from "react";
import { FlexContainer } from "../../../components/FlexContainer";
import { Field, reduxForm } from "redux-form";
import Label from "../../../components/Label";
import Button from "../../../components/Button";
import Textarea from "../../../components/Textarea";
import RadioButton from "../../../components/RadioButton";
import { TextBox } from "../../components";
import { InsiderType } from "../../InsiderToolActions";
import styled from "styled-components";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment/moment";
import { getOBNTDate, getOBNTTime } from "../../../util/common";
import { LIST_CLOSED } from "../../home/InsiderListHome";
import { fetchInsiderList, closeList } from "../../InsiderToolActions";
import { reset } from "redux-form";
import { Modal } from "../../../components/Modal";
import { OverlayLoader } from "../../../components/Loader";

const SendMailRadioButton = props => {
  return (
    <FlexContainer row margin={[24, 0, 10, 0]} vAlignCenter>
      <RadioButton
        {...props}
        checked
        margin={[0, 0, 8, 0]}
        radioValue={"sendMail"}
        label={props.t("send_mail")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onSendMail();
            props.input.onChange(value);
          }
        }}
      />
      <RadioButton
        {...props}
        component={RadioButton}
        margin={[0, 0, 8, 10]}
        radioValue={"do_not_send_mail"}
        label={props.t("do_not_send_mail")}
        input={{
          value: props.input.value,
          onChange: value => {
            props.onDoNotSendMail();
            props.input.onChange(value);
          }
        }}
      />
    </FlexContainer>
  );
};

const TextAreaBox = props => {
  const { height } = props;
  return (
    <FlexContainer column margin={props.margin} height={height}>
      {props.noLabel ? (
        ""
      ) : (
        <Label margin={[0, 0, 3, 0]} htmlFor={props.name}>
          {props.label}
        </Label>
      )}

      <Textarea {...props} />
    </FlexContainer>
  );
};

const Text = styled.span`
  ${props => (props.bold ? "font-weight: 600;" : "")};
  ${props => (props.fixedMargin ? "margin: 20px 0 20px 0;" : "")};
`;

export class CloseListForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailSelected: false
    };
    this.isSubmitting = false;
  }

  render() {
    const { t, listType } = this.props;
    const onSendMail = () => {
      this.setState({ sendMailSelected: true });
    };
    const onDoNotSendMail = () => {
      this.setState({ sendMailSelected: false });
    };

    return (
      <FlexContainer>
        {this.props.loading ? <OverlayLoader /> : undefined}
        {!this.props.pageTwo ? (
          <FlexContainer>
            {listType === InsiderType.CONFIDENTIALITY_LIST ? (
              <FlexContainer>
                {t("it_close_confidentiality_list")}
              </FlexContainer>
            ) : (
              <FlexContainer>
                {t("it_close_list_info")}
                <Text fixedMargin>
                  <Text bold>{t("it_close_list_info_2")}</Text>
                  {t("it_close_list_info_3")}
                </Text>
              </FlexContainer>
            )}
            <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
              <Button.Standard
                onClick={this.props.onPageTwo}
                margin={[0, 24, 0, 0]}
              >
                {t("it_confirm")}
              </Button.Standard>

              <Button.Text onClick={this.props.onCancel}>
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        ) : (
          <FlexContainer>
            <FlexContainer margin={[0, 0, 12, 0]}>
              {listType === InsiderType.CONFIDENTIALITY_LIST
                ? t("it_close_confidentiality_list_email_info")
                : t("it_close_list_email_info")}
            </FlexContainer>

            <Field
              name={"sendMail"}
              t={this.props.t}
              component={SendMailRadioButton}
              onSendMail={onSendMail}
              onDoNotSendMail={onDoNotSendMail}
            />

            <FlexContainer>
              <FlexContainer>
                <FlexContainer>
                  {this.state.sendMailSelected ? (
                    <Field
                      overflowHidden
                      readOnly
                      height="auto"
                      name="subject"
                      component={TextBox}
                      noResize
                      noLabel={!this.state.sendMailSelected}
                      label={
                        t("subject", { lng: "en" }) +
                        " / " +
                        t("subject", { lng: "no" })
                      }
                      bold
                      margin={[0, 0, 16, 0]}
                    />
                  ) : (
                    undefined
                  )}
                  <FlexContainer row>
                    {this.state.sendMailSelected ? (
                      <Field
                        overflowHidden
                        readOnly
                        height="auto"
                        name="body_0"
                        component={TextBox}
                        noResize
                        noLabel={!this.state.sendMailSelected}
                        label={t("it_message_in_english")}
                        bold
                        textMargin={[26, 0, 10, 0]}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.sendMailSelected ? (
                      <Field
                        overflowHidden
                        readOnly
                        height="auto"
                        name="body_3"
                        component={TextBox}
                        noResize
                        noLabel={!this.state.sendMailSelected}
                        label={t("it_message_in_norwegian")}
                        margin={[0, 0, 10, 10]}
                        bold
                        textMargin={[8, 0, 10, 0]}
                      />
                    ) : (
                      ""
                    )}
                  </FlexContainer>

                  <FlexContainer row>
                    <FlexContainer margin={[0, 16, 0, 0]}>
                      {this.state.sendMailSelected ? (
                        <Field
                          height="195px"
                          border
                          name="body_1"
                          component={TextAreaBox}
                          noResize
                        />
                      ) : (
                        t(
                          listType === InsiderType.LIST
                            ? "it_do_not_send_mail_info"
                            : "it_do_not_send_mail_info_conf"
                        )
                      )}
                    </FlexContainer>
                    {this.state.sendMailSelected ? (
                      <Field
                        height="195px"
                        border
                        name="body_4"
                        component={TextAreaBox}
                        noResize
                      />
                    ) : (
                      ""
                    )}
                  </FlexContainer>
                  <FlexContainer row>
                    {this.state.sendMailSelected ? (
                      <Field
                        overflowHidden
                        readOnly
                        height="auto"
                        name="body_2"
                        component={TextBox}
                        noResize
                        noLabel
                      />
                    ) : (
                      ""
                    )}

                    {this.state.sendMailSelected ? (
                      <Field
                        overflowHidden
                        readOnly
                        height="auto"
                        name="body_5"
                        component={TextBox}
                        noResize
                        noLabel
                      />
                    ) : (
                      ""
                    )}
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer margin={[24, 0, 0, 0]} row vAlignCenter flexEnd>
                <Button.Standard
                  inactive={this.submitting}
                  onClick={() => {
                    if (this.isSubmitting) {
                      return undefined;
                    }
                    this.isSubmitting = true;
                    this.state.sendMailSelected
                      ? this.props.handleSubmit()
                      : this.props.onCancel();
                  }}
                  margin={[0, 24, 0, 0]}
                >
                  {this.state.sendMailSelected ? t("send_mail") : t("OK")}
                </Button.Standard>
                <Button.Text onClick={this.props.onCancel}>
                  {t("it_exit")}
                </Button.Text>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        )}
      </FlexContainer>
    );
  }
}

CloseListForm = reduxForm({
  form: "closeListForm"
})(CloseListForm);

class CloseList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageOne: false,
      pageTwo: false,
      showCloseList: true,
      loading: false
    };
  }

  getCloseListMailTemplateName = () => {
    return this.props.listType === InsiderType.CONFIDENTIALITY_LIST
      ? "closeConfList"
      : "closeList";
  };

  showPageTwo = () => {
    this.props.hasInsiders
      ? this.setState({
          pageTwo: true,
          pageOne: false
        })
      : this.props.toggleShowCloseList();
  };

  render() {
    const {
      emailTemplate,
      replaceKeysInString,
      insiderListInfo,
      listId,
      sendMail,
      t
    } = this.props;

    return (
      <Modal
        key={"showCloseListModal"}
        header={t("important_information")}
        isOpen={true}
        onClose={() => {
          this.props.toggleShowCloseList();
          this.props.dispatch(reset("closeListForm"));
        }}
      >
        <FlexContainer>
          <form>
            <CloseListForm
              {...this.props}
              pageOne={this.state.pageOne}
              pageTwo={this.state.pageTwo}
              loading={this.state.loading}
              initialValues={{
                subject: emailTemplate.subject,
                body_0: replaceKeysInString(emailTemplate.body[0], "en"),
                body_1: emailTemplate.body[1],
                body_2: emailTemplate.body[2],
                body_3: replaceKeysInString(emailTemplate.body[3], "nb"),
                body_4: emailTemplate.body[4],
                body_5: emailTemplate.body[5]
              }}
              onPageTwo={() => {
                const now = moment();
                insiderListInfo.update_date = getOBNTDate(now);
                insiderListInfo.update_time = getOBNTTime(now);
                insiderListInfo.closed_date = now;
                insiderListInfo.listState = LIST_CLOSED;
                this.props
                  .dispatch(closeList(listId))
                  .then(() => Promise.resolve(this.showPageTwo()))
                  .then(() => this.props.dispatch(fetchInsiderList(listId)));
              }}
              onSubmit={values => {
                sendMail(listId, values, this.getCloseListMailTemplateName())
                  .then(() => this.setState({ loading: true }))
                  .then(() => this.props.dispatch(fetchInsiderList(listId)))
                  .then(() => {
                    this.props.dispatch(reset("closeListForm"));
                  })
                  .then(() => this.setState({ loading: false }));
                this.props.toggleShowCloseList();
              }}
              onCancel={() => {
                this.props.dispatch(reset("closeListForm"));
                this.props.toggleShowCloseList();
              }}
            />
          </form>
        </FlexContainer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;
  return {
    emailTemplate: insiderToolReducer.template.template,
    hasInsiders:
      insiderToolReducer.insiderList.insiders.length != 0 ? true : false
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(CloseList)
);
