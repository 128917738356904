import React from "react";
import styled from "styled-components";
import { FlexContainer } from "./FlexContainer";
import { Icon } from "../insiderTool/components";
import Label from "./Label";
import CheckBox from "./CheckBox";
import { browserIsIE } from "../util/common";

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  text-align: left;
`;

const FilterOptionsContainer = styled.div`
  position: fixed;
  color: black;
  background-color: white;
  min-width: 5%;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: block;
  margin: 30px 0 0 -2em;
  padding-bottom: 7px;
  font-weight: 300;
  top: ${props => (props.top ? props.top + "px" : "0px")};
  left: ${props => (props.left ? props.left + "px" : "0px")};

  ${props =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

export default class FilterBox extends React.Component {
  constructor() {
    super();
    this.state = {
      showFilterOptions: false,
      menuTop: undefined,
      menuLeft: undefined,
      selected: false
    };
  }

  clickIsInsideMenu = target =>
    target == null
      ? false
      : ["filter" + this.props.id, "filterBox" + this.props.id].includes(
          target.id
        )
        ? true
        : this.clickIsInsideMenu(target.parentNode);

  componentDidMount() {
    document.addEventListener("click", this.toggleMenu);
    document.addEventListener("scroll", this.toggleMenuOnScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.toggleMenu);
    document.removeEventListener("scroll", this.toggleMenuOnScroll);
  }

  toggleMenu = event => {
    if (
      !this.state.showFilterOptions &&
      event.target.id === "filter" + this.props.id
    ) {
      const top = browserIsIE()
        ? event.clientY - event.offsetY
        : event.target.y + event.target.height / 2;
      const left = browserIsIE()
        ? event.clientX - event.offsetX
        : event.target.x;
      this.setState({
        showFilterOptions: true,
        menuTop: top,
        menuLeft: left
      });
    } else if (
      this.state.showFilterOptions &&
      !this.clickIsInsideMenu(event.target)
    ) {
      this.setState({ showFilterOptions: false });
    }
  };
  toggleMenuOnScroll = _ => {
    if (this.state.showFilterOptions)
      this.setState({ showFilterOptions: false });
  };

  render() {
    const { t } = this.props;
    const checkBoxList = this.props.data.map((data, index) => {
      return (
        <CheckBox
          key={index}
          useReduxCheckState
          label={data}
          input={{
            value: this.props.isChecked(data),
            onChange: state => this.props.onChange(data, state)
          }}
        />
      );
    });

    return (
      <FlexContainer width={"auto"} id={"filterBox" + this.props.id} row>
        <Icon
          margin={[3, 0, 0, 4]}
          height={18}
          width={18}
          src={
            this.props.isAllChecked
              ? "/icons/filter_list-24px.svg"
              : "/icons/filter_lists_selected-24px.svg"
          }
          alt="sort"
          id={"filter" + this.props.id}
        />

        {this.state.showFilterOptions ? (
          <FilterOptionsContainer
            padding={[10, 20, 20, 20]}
            top={this.state.menuTop}
            left={this.state.menuLeft}
          >
            {this.props.enableToggleAll ? (
              <FlexContainer margin={[8, 0, 16, 0]}>
                <CheckBox
                  key={"toggle"}
                  useReduxCheckState
                  label={this.props.checkAllText}
                  input={{
                    value: this.props.isAllChecked,
                    onChange: state => this.props.onChangeAll(state)
                  }}
                />
              </FlexContainer>
            ) : (
              undefined
            )}
            <Label bold>Filter:</Label>
            <FlexContainer margin={[8, 0, 0, 0]}>{checkBoxList}</FlexContainer>
          </FilterOptionsContainer>
        ) : (
          undefined
        )}
      </FlexContainer>
    );
  }
}
