import React from "react";
import styled from "styled-components";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import { ActionText } from "../components/ActionText";
import { Modal } from "../components/Modal";
import { StyledLink } from "../components/StyledLink";

const StyledRouterLink = styled(Link)`
  text-align: left;
  color: #336699;
  text-decoration: none;
  margin-left: 20px;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled.p`
  ${props => (props.bold ? "font-weight: 600;" : "")};
  font-size: 16px;
`;

class EmailSent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpModal: false
    };
  }

  render() {
    const t = this.props;
    const onHelp = () => {
      this.setState({ showHelpModal: true });
    };
    const onCloseHelpModal = () => {
      this.setState({ showHelpModal: false });
    };
    return (
      <FlexContainer margin={[40, 0, 0, 0]} vAlignCenter>
        <PageHeading>{this.props.t("email_confirmation")}</PageHeading>
        <FlexContainer center row>
          <StyledRouterLink to="/login">
            {this.props.t("back_to_login")}{" "}
          </StyledRouterLink>

          <ActionText onClick={() => onHelp()} margin={[0, 0, 0, 40]}>
            {this.props.t("need_help")}
          </ActionText>
        </FlexContainer>

        <Modal
          isOpen={this.state.showHelpModal}
          onClose={onCloseHelpModal}
          header={this.props.t("need_help")}
        >
          <FlexContainer>
            <Text>{this.props.t("login_problems")}</Text>
            <Text>
              {this.props.t("contact_before_close")} <br />
              {this.props.t("phone")}: + 47 22 34 19 45
              <br />
              {this.props.t("email")}:{" "}
              <StyledLink href={"mailto:ma@oslobors.no"}>
                ma@oslobors.no
              </StyledLink>
            </Text>
            <Text>
              {this.props.t("publication_service_questions")}
              <br />
              {this.props.t("phone")}: +47 22 34 18 16
              <br />
              {this.props.t("email")}:{" "}
              <StyledLink href={"mailto:corporate.services@oslobors.no"}>
                corporate.services@oslobors.no
              </StyledLink>
            </Text>
          </FlexContainer>
        </Modal>
      </FlexContainer>
    );
  }
}

export default withTranslation("translations")(EmailSent);
