import { postData } from "../actions/CommonActionsNoObsvc";

export const Notification = {
  request: "REQUEST_NOTIFICATION",
  postSuccess: "RECEIVE_NOTIFICATION",
  postFail: "RECEIVE_NOTIFICATION_FAILED",
};

export const fetchNotification = (id, organisationId) =>
  postData(
    Notification,
    undefined,
    { id, organisationId },
    "v1/notification/get"
  );

export const NotificationList = {
  request: "REQUEST_NOTIFICATION_LIST",
  postSuccess: "RECEIVE_NOTIFICATION_LIST",
  postFail: "RECEIVE_NOTIFICATION_LIST_FAILED",
};

export const fetchNotificationList = (organisationId) =>
  postData(
    NotificationList,
    undefined,
    { organisationId },
    "v1/notification/listShort"
  );

export const FetchOrganisationList = {
  request: "REQUEST_ORGANIZATION_LIST",
  postSuccess: "RECEIVE_ORGANIZATION_LIST",
  postFail: "RECEIVE_ORGANIZATION_LIST_FAILED",
};

export const fetchOrganisationList = () =>
  postData(
    FetchOrganisationList,
    undefined,
    undefined,
    "v1/sysadmin/organisation/list"
  );

export const Organisation = {
  request: "REQUEST_NOTIFICATION_ORGANIZATION",
  postSuccess: "RECEIVE_NOTIFICATION_ORGANIZATION",
  postFail: "RECEIVE_NOTIFICATION_ORGANIZATION_FAILED",
};

export const fetchOrganisation = (id) =>
  postData(Organisation, undefined, { id }, "v1/sysadmin/organisation/get");

export const IssuerList = {
  request: "REQUEST_ISSUER_LIST",
  postSuccess: "RECEIVE_ISSUER_LIST",
  postFail: "RECEIVE_ISSUER_LIST_FAILED",
};

export const fetchIssuerList = () =>
  postData(IssuerList, undefined, undefined, "v1/company/list");

export const CreateNotification = {
  request: "CREATE_NOTIFICATION",
  postSuccess: "CREATE_NOTIFICATION_SUCCESS",
  postFail: "CREATE_NOTIFICATION_FAILED",
};

export const createNotification = (notification) =>
  postData(
    CreateNotification,
    undefined,
    { ...notification },
    "v1/notification/create"
  );

export const SetStatusNotification = {
  request: "SET_STATUS",
  postFail: "SET_STATUS_FAILED",
  postSuccess: "SET_STATUS_SUCCESS",
};

export const setStatusNotification = (params) =>
  postData(
    SetStatusNotification,
    undefined,
    params,
    "v1/notification/setStatus"
  );
