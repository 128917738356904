import React from "react";
import styled, { css } from "styled-components";

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 530
};

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
const tiltedPhone = (...args) => css`
  @media (max-height: 580px) {
    ${css(...args)};
  }
`;

const tiltedTablet = (...args) => css`
  @media (max-height: 768px) and (max-width: 1024px) {
    ${css(...args)};
  }
`;

const ModalWrapper = styled.div`
  ${props =>
    props.open
      ? "display:flex;"
      : "display: none;"} background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;

  @media print {
    position: absolute;
    height: auto;
    width: 100%;
  }
`;

const ModalBox = styled.div`
display:grid;
display: -ms-grid;
-ms-grid-rows: 100px auto 40px;
-ms-grid-columns: 78px 1fr 78px;

grid-template-columns: 78px auto 78px;
grid-template-rows: 100px 1fr 40px;



width: ${props => (props.width ? props.width : "51%")};
background:white;
color:black;
overflow-y: auto;
overflow-x: hidden;
overflow-x: ${props => (props.xScroll ? "auto" : "")};

max-height:${props => (props.height ? props.height : "80%")};

${props => (props.fixedHeightEightyPercent ? "height:80%" : "")};
${props => (props.minHeight ? "min-height:" + props.minHeight + ";" : "min-height:10%;")};
${props => (props.noMinHeight ? "min-height:0%" : "")};

${props => (props.setHeight ? "height:" + props.setHeight + ";" : "")};


max-width:90%;

${media.phone`

-ms-grid-columns: 14px 1fr 14px;
max-width:90%;

grid-template-columns: 28px auto 28px;

`}

${media.tablet`

-ms-grid-columns: 14px 1fr 14px;
max-width:90%;

grid-template-columns: 28px auto 28px;

`}

${tiltedPhone`
max-height:90%;
max-width:80%;
`}

${tiltedTablet`
height:90%;
width:80%;
`}

@media print {
  width: 100%;
  max-width: 100%;
  @page {
    size: landscape;
    
  }
  zoom: 75%;
}

`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-top: 24px;
  float: right;
  padding-right: 24px;

  ${media.phone`
padding-right: 10px;
`} ${media.tablet`

padding-right: 10px;

`};
`;

const HeaderModal = styled.h1`
  letter-spacing: normal;
  text-align: center;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  grid-column: 2;
  grid-row: 1 / 2;

  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  text-transform: uppercase;
`;

const IconContainer = styled.div`
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  grid-column: 3;
  grid-row: 1 / 2;
`;

const ContentModal = styled.div`
  color: black;
  white-space: pre-line;
  overflow-wrap: break-word;
  ${props => (props.fillContent ? "display: inline-block;" : "display: flex")}
  margin-bottom: 10px;

  ${props => (props.center ? "justify-content: center;" : "")}
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;

  grid-column: 2;
  grid-row: 2 / 3;
`;

export class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.handleKeyDownEvent = this.handleKeyDownEvent.bind(this);
  }
  componentWillMount() {
    window.addEventListener("keydown", this.handleKeyDownEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDownEvent);
  }

  handleKeyDownEvent(event) {
    if (this.props.isOpen && event.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const {
      header,
      onClose,
      children,
      isOpen,
      center,
      fixedHeightEightyPercent,
      minHeight,
      noMinHeight,
      width,
      height,
      xScroll,
      setHeight,
      fillContent
    } = this.props;

    return (
      <ModalWrapper open={isOpen}>
        <ModalBox
          fixedHeightEightyPercent={fixedHeightEightyPercent}
          height={height}
          minHeight={minHeight}
          noMinHeight={noMinHeight}
          setHeight={setHeight}
          width={width}
          xScroll={xScroll}
        >
          <IconContainer>
            <CloseIcon onClick={onClose} src="/icons/red-cross-24.svg" />
          </IconContainer>

          <HeaderModal>{header}</HeaderModal>

          <ContentModal fillContent={fillContent} center={center}>{children}</ContentModal>
        </ModalBox>
      </ModalWrapper>
    );
  }
}
