export const isModuleVisible = (moduleName, visibleModules) => {
  if (visibleModules !== undefined) {
    return visibleModules.some(
        (value) => value.toLowerCase() === moduleName.toLowerCase()
    );
  }
  return false;
};

export const isSsoEnabled = (option, adminOptions) => {
  if (adminOptions !== undefined) {
    return adminOptions.some(
        (value) => value.toLowerCase() === option.toLowerCase()
    );
  }
  return true;
};
