import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import {withRouter} from "react-router";

import {FlexContainer} from "./components/FlexContainer";
import Button from "./components/Button";
import styled from "styled-components";

import moment from "moment";
import Loader from "./components/Loader";
import i18n from "./i18n";

import Shiitake from "shiitake";
import {isModuleVisible} from "./util/accessUtil";
import {getInsiderLogURL} from "./util/common";
import {fetchInfoboardMessages} from "./infoboard/InfoboardActions";
import {fetchInsiderLogUrlByUser} from "./systemAdmin/InsiderSyncApi/InsiderSyncDetialsActions";

const InfoHeader = styled.h4`
  font-weight: 600;
  font-size: 16px;
  margin: 0 0 2px 10px;
`;
const InfoBody = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
  margin: 0 0 0 10px;
`;

const GridContainer = styled.div`
  display: -ms-grid;
  display: grid;

  /*IE 11*/
  -ms-grid-columns: 294px 294px 294px 278px;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  /*******/

  grid-template-columns: 294px 294px 294px 278px;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`;

const GridButtonContainer = props => {
  const { onClick } = props;
  return (
    <FlexContainer
      {...props}
      onClick={undefined}
      column
      width={"278px"}
      greyBackground
      padding={[8, 8, 20, 8]}
      margin={[0, 0, 60, 0]}
      flexStart
    >
      <Button.Menu margin={[0, 0, 10, 0]} breakLine onClick={onClick}>
        {props.children}
      </Button.Menu>
      {props.infoText}
    </FlexContainer>
  );
};

const Link = styled.span`
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc + val + "px "), "") +
        ";"
      : ""};
`;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInfoboardMessage: new Map(),
      propsFetched: false
    };
  }

  componentWillMount() {
    if (!this.props.insiderLogUrlIsFetched && isModuleVisible("insiderlog", this.props.visibleModules)) {
      this.props.dispatch(fetchInsiderLogUrlByUser())
    }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.publicProps && !this.state.propsFetched) {
      const infoBoardAvailable = nextProps.publicProps[
          "global.module.infoboard"
          ];
      if (infoBoardAvailable === "true") {
        this.props.dispatch(fetchInfoboardMessages());
        this.setState({propsFetched: true});
      }
    }
  }


  render() {
    const { t, publicProps } = this.props;

    const gridProperies = [
      { "-ms-grid-row": 1, "-ms-grid-column": 1 },
      { "-ms-grid-row": 1, "-ms-grid-column": 2 },
      { "-ms-grid-row": 1, "-ms-grid-column": 3 },
      { "-ms-grid-row": 1, "-ms-grid-column": 4 },
      { "-ms-grid-row": 2, "-ms-grid-column": 1 },
      { "-ms-grid-row": 2, "-ms-grid-column": 2 },
      { "-ms-grid-row": 2, "-ms-grid-column": 3 },
      { "-ms-grid-row": 2, "-ms-grid-column": 4 },
      { "-ms-grid-row": 3, "-ms-grid-column": 1 },
      { "-ms-grid-row": 3, "-ms-grid-column": 2 },
      { "-ms-grid-row": 3, "-ms-grid-column": 3 },
      { "-ms-grid-row": 3, "-ms-grid-column": 4 },
      { "-ms-grid-row": 4, "-ms-grid-column": 1 }
    ].reverse();

    const infoboardMessages = this.props.infoboardMessages.map(
      (message, index) => {
        const title =
          i18n.language === "nb" ? message.titleNo : message.titleEn + "";
        const body =
          i18n.language === "nb" ? message.bodyNo : message.bodyEn + "";

        const displayMessage =
          moment().isAfter(message.publishTime) && title.length > 0;
        return displayMessage ? (
          <FlexContainer
            greyBackground
            padding={[8, 8, 8, 8]}
            margin={[0, 0, 20, 0]}
            key={message.identity}
          >
            <InfoHeader>{title}</InfoHeader>
            <InfoBody>
              {!this.state.showInfoboardMessage.has(index) ? (
                <FlexContainer>
                  <Shiitake
                    lines={5}
                    overflowNode={
                      <span>
                        ...
                        <Link
                          margin={[0, 0, 0, 5]}
                          onClick={() => {
                            var map = new Map(this.state.showInfoboardMessage);
                            map.set(index, index);
                            this.setState({ showInfoboardMessage: map });
                          }}
                        >
                          {t("show_more")}
                        </Link>
                      </span>
                    }
                  >
                    {body}
                  </Shiitake>
                </FlexContainer>
              ) : (
                <FlexContainer column>
                  <div dangerouslySetInnerHTML={{ __html: body }} />
                  <Link
                    margin={[5, 0, 0, 0]}
                    onClick={() => {
                      var map = new Map(this.state.showInfoboardMessage);
                      map.delete(index);
                      this.setState({ showInfoboardMessage: map });
                    }}
                  >
                    {t("show_less")}
                  </Link>
                </FlexContainer>
              )}
            </InfoBody>
          </FlexContainer>
        ) : (
          ""
        );
      }
    );

    return this.props.visibleModulesIsFetched ? (
      <FlexContainer column>
        <FlexContainer padding={[0, 0, 15, 0]}>
          {infoboardMessages}
        </FlexContainer>

        <GridContainer>
          {isModuleVisible("news", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("newsmessage_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/newsmessage")}
            >
              {t("messages")}
            </GridButtonContainer>
          ) : undefined}

          {/*TODO: Use proper module/access - check!!*/}
          {isModuleVisible("emaildist", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("email_management_descr")}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/emailDist")}
            >
              {t("email_management")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("financialcalendar", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("financialcalendar_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/finanskalender")}
            >
              {t("finance_calendar")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("company", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("company_descr") + "."}
              {...gridProperies.pop()}
              onClick={() =>
                this.props.history.push("/companyInformation/form")
              }
            >
              {t("company_information")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("insider", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("insider_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/innsider")}
            >
              {t("insiderregister")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("notification", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("delayed_publication_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/notification")}
            >
              {t("delayed_publication")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("insidertool", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("insidertool_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/insidertool")}
            >
              {t("insidertool")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("insiderlog", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("insider_log_descr") + "."}
              {...gridProperies.pop()}
              onClick={() =>
                window.open(
                  getInsiderLogURL(publicProps, this.props.insiderLogUrl),
                  "_blank"
                )
              }
            >
              {t("insider_log")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("issuance", this.props.visibleModules) ? (
              <GridButtonContainer
                  infoText={t("share_module_desc")}
                  {...gridProperies.pop()}
                  onClick={() => this.props.history.push("/shareissuance")}
              >
                {t("share_module_name")}
              </GridButtonContainer>
          ) : undefined}


          {isModuleVisible("myProfile", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("myprofile_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => {
                if (
                  publicProps.connectRoot &&
                  publicProps.connectRoot.length > 0
                ) {
                  window.location = publicProps.connectRoot + "/user/edit";
                } else {
                  this.props.history.push("/profil");
                }
              }}
            >
              {t("my_profile")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("auditlog", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("auditlog_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/auditlog")}
            >
              {t("auditlog")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("infoboard", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("infoboard_descr") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/ma")}
            >
              {t("info_from_ma_short")}
            </GridButtonContainer>
          ) : undefined}

          {isModuleVisible("systemadministrator", this.props.visibleModules) ? (
            <GridButtonContainer
              infoText={t("system_administration") + "."}
              {...gridProperies.pop()}
              onClick={() => this.props.history.push("/system")}
            >
              {t("system_administration")}
            </GridButtonContainer>
          ) : undefined}
        </GridContainer>
      </FlexContainer>
    ) : (
      <Loader />
    );
  }
}

function mapStateToProps(state) {
  const {
    infoboardReducer,
    loginUser,
    systemAdministratorReducer,
    visibleModulesReducer,
    insiderSyncDetailsReducer
  } = state;

  return {
    infoboardMessages: infoboardReducer.messages,
    infoboardMessagesIsFetched: infoboardReducer.messagesIsFetched,
    visibleModules: visibleModulesReducer.visibleModules || [],
    visibleModulesIsFetched: visibleModulesReducer.visibleModulesIsFetched,
    currentUserInfo: loginUser.user,
    publicProps: systemAdministratorReducer.publicProps.object || {},
    insiderLogUrl:
      insiderSyncDetailsReducer.insiderSyncDetialsByUser.insiderLogUrl || {},
    insiderLogUrlIsFetched:
        insiderSyncDetailsReducer.insiderSyncDetialsByUser.insiderLogUrlIsFetched
  };
}

export default withRouter(
  connect(mapStateToProps)(withTranslation("translations")(Home))
);
