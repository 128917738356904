import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {ErrorText} from "../components/ErrorText";

class BatchjobsStatus extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    render() {
        const { t, base } = this.props;

        return (
            <FlexContainer center vAlignCenter margin={[0, 0, 0, 0]}>

                <ErrorText>Coming soon, stay tuned.</ErrorText>

            </FlexContainer>
        );
    }
}

function mapStateToProps(state) {
    const {

    } = state;

    return {
    };
}

export default connect(mapStateToProps)(withTranslation("translations")(BatchjobsStatus));
