import moment from "moment";
import { postData } from "../actions/CommonActionsNoObsvc";

export const CountriesList = {
  request: "REQUEST_COUNTRIES",
  postSuccess: "RECEIVE_COUNTRIES_LIST",
  postFail: "RECEIVE_CONTRIES_LIST_FAILED",
};

export const fetchCountriesList = () =>
  postData(CountriesList, undefined, undefined, "v1/insider/countries");

export const InsiderList = {
  request: "REQUEST_INSIDER_LIST",
  postSuccess: "RECEIVE_INSIDER_LIST",
  postFail: "RECEIVE_INSIDER_LIST_FAILED",
  store: "STORE_INSIDER_LIST",
};
export const fetchInsiderList = (id) =>
  postData(InsiderList, undefined, { id }, "v1/insider/list");

export const PrimaryInsiderList = {
  request: "REQUEST_PRIMARY_INSIDER_LIST",
  postSuccess: "RECEIVE_PRIMARY_INSIDER_LIST",
  postFail: "RECEIVE_PRIMARY_INSIDER_LIST_FAILED",
  store: "STORE_PRIMARY_INSIDER_LIST",
};
export const fetchPrimaryInsiderList = (id) =>
  postData(PrimaryInsiderList, undefined, { id }, "v1/insider/primary");

export const ValidFrom = {
  request: "REQUEST_INSIDER_START_DATE",
  postSuccess: "RECEIVE_INSIDER_START_DATE",
  postFail: "RECEIVE_INSIDER_START_DATE_FAILED",
};

export const getValidFrom = (id) =>
  postData(ValidFrom, undefined, { id }, "v1/insider/getValidFrom");

export const addInsiderList = {
  request: "ADD_INSIDER",
  postSuccess: "ADD_INSIDER_SUCCESS",
  postFail: "ADD_INSIDER_FAILED",
};

export const addInsider = (insiderData) =>
  postData(addInsiderList, undefined, { ...insiderData }, "v1/insider/add");

export const updateInsiderList = {
  request: "UPDATE_INSIDER",
  postSuccess: "UPDATE_INSIDER_SUCCESS",
  postFail: "UPDATE_INSIDER_FAILED",
};
export const updateInsider = (insiderData) =>
  postData(
    updateInsiderList,
    undefined,
    { ...insiderData },
    "v1/insider/update"
  );

export const deactivateInsiderList = {
  request: "DEACTIVATE_INSIDER",
  postSuccess: "DEACTIVATE_SUCCESS",
  postFail: "DEACTIVATE_FAILED",
};

export const deactivateInsider = (id) => {
  const date = moment().format("YYYY-MM-DD");
  return postData(
    deactivateInsiderList,
    undefined,
    {
      id,
      params: { date },
    },
    "v1/insider/deactivate"
  );
};

export const InsiderClearData = {
  clear: "CLEAR_INSIDER_DATA",
};

export const resetInsiderData = () => {
  return { type: InsiderClearData.clear };
};
