import React from "react";

import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import { PageHeading } from "../components/PageHeading";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import Input from "../components/Input";
import RadioButton from "../components/RadioButton";
import Label from "../components/Label";
import SearchableSelect from "../components/SearchableSelect";
import PasswordRules from "../resetPassword/PasswordRules";

const InputBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 8, 0, 0]}>
      <Label color={"white"} htmlFor={props.input.name} margin={[0, 0, 0, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

export const SearchDropDownWlabel = props => {
  const { error } = props.meta;
  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label
        color={"white"}
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};

class NewUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorMessageProtocol: undefined,
      twoFactorEnabled: undefined,
      twoFactorCountryCode: undefined,
      twoFactorPhone: undefined,
      twoFactorCodeRequested: false
    };
  }

  render() {
    const props = this.props;
    const { t } = this.props;

    const loginBoxMargin = props.underMaintenance
      ? [40, 0, 0, 0]
      : [75, 0, 0, 0];

    return (
      <FlexContainer maxWidth={767} width={"760px"}>
        <form>
          <FlexContainer
            margin={loginBoxMargin}
            padding={[40, 40, 40, 40]}
            blueBackground
            vAlignCenter
            column
          >
            <PageHeading margin={[0, 0, 20, 0]} uppercase color="white">
              {t("new_password")}
            </PageHeading>
            <FlexContainer vAlignCenter>
              <FlexContainer vAlignCenter hidden={!props.twoFactorEnabled}>
                <Label margin={[0, 0, 20, 0]} color={"white"}>
                  {props.t("reset_password_info")}
                </Label>

                <FlexContainer maxWidth={295} margin={[0, 0, 16, 0]}>
                  <Label margin={[0, 0, 8, 0]} color={"white"}>
                    {props.t("receive_one_time_code") + ":"}
                  </Label>

                  <Field
                    component={RadioButton}
                    checked
                    name="protocol"
                    color={"white"}
                    margin={[0, 0, 8, 0]}
                    radioValue={"SMS"}
                    label={props.t("sms")}
                  />
                  <Field
                    component={RadioButton}
                    name="protocol"
                    color={"white"}
                    margin={[0, 0, 8, 0]}
                    radioValue={"VOICE"}
                    label={props.t("speech_message")}
                  />
                </FlexContainer>

                {/*<Label color={"white"}>{props.t("phonenumber_for_receival_mfa")}:</Label>*/}

                <FlexContainer margin={[0, 0, 0, 0]} maxWidth={295} column>
                  <FlexContainer margin={[0, 0, 16, 0]}>
                    <Field
                      component={SearchDropDownWlabel}
                      label={t("choose_area_code")}
                      searchable={true}
                      name="areaCode"
                      //maxWidth={128}
                      {...props}
                      margin={[0, 0, 0, 0]}
                      placeholder={props.t("choose_area_code")}
                      options={this.props.countries || []}
                    />
                  </FlexContainer>
                  <Field
                    component={InputBox}
                    label={t("mobile_number_mfa") + ":"}
                    name={"twoFactorPhone"}
                    border
                    maxWidth={295}
                    margin={[0, 0, 40, 0]}
                    placeholder={props.t("mobile_number_mfa")}
                    greyPlaceholder
                  />

                  <Button.Standard
                    loading={this.props.loading}
                    onClick={props.handleSubmit(this.props.onVerifyMfa)}
                    margin={[0, 0, 16, 0]}
                  >
                    {t("verify")}
                  </Button.Standard>
                </FlexContainer>
              </FlexContainer>

              <FlexContainer
                hidden={
                  !this.props.twoFactorCodeRequested && props.twoFactorEnabled
                }
                maxWidth={295}
              >
                <FlexContainer hidden={!props.twoFactorEnabled}>
                  <Field
                    name={"receivedCode"}
                    component={InputBox}
                    label={t("register_receival_of_mfacode") + ":"}
                    maxWidth={295}
                    placeholder={t("register_receival_of_mfacode")}
                    greyPlaceholder
                    border
                    margin={[0, 0, 16, 0]}
                    disabled={!this.props.twoFactorCodeRequested}
                  />
                </FlexContainer>

                <Field
                  name="password"
                  label={t("new_password")}
                  component={InputBox}
                  greyPlaceholder
                  maxWidth={460}
                  margin={[0, 0, 16, 0]}
                  placeholder={t("new_password")}
                  type="password"
                />

                <Field
                  name="repeat_password"
                  label={t("repeat_password") + ":"}
                  component={InputBox}
                  greyPlaceholder
                  maxWidth={460}
                  margin={[0, 0, 16, 0]}
                  placeholder={t("repeat_password")}
                  onKeyDown={e => {
                    switch (e.keyCode) {
                      case 13: //Enter
                        e.preventDefault();
                        const submitFunction = this.props.handleSubmit(
                          this.props.onSave
                        );
                        submitFunction();
                        break
                      default:
                    }
                  }}
                  type="password"
                />

                <FlexContainer maxWidth={295} margin={[40, 0, 0, 0]}>
                  <Button.Standard
                    onClick={props.handleSubmit(this.props.onSave)}
                  >
                    {t("save_password")}
                  </Button.Standard>
                </FlexContainer>

                <FlexContainer maxWidth={295} margin={[20, 0, 0, 0]}>
                  <PasswordRules />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

export default reduxForm({
  form: "newUserForm"
})(NewUserForm);
