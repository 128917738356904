import {
  deleteCASLogin,
  deleteToken,
  doRequest,
  getGlobalSubject,
  getToken,
  setCASLogin,
  setToken,
} from "../obsvcClient";
import {logOut} from "../login/LoginActions";

import jwt_decode from "jwt-decode";
import moment from "moment";
import i18n from "../i18n";
import {fetchPublicProps} from "../systemAdmin/SystemAdministratorActions";
import fetch from "cross-fetch";
import {doPostRequest} from "../noObsvcClient";

import {postData} from "./CommonActionsNoObsvc";

const LOCAL_LANG_KEY = "obnt-local-lang";
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const DISPATCH_CONFIG = "DISPATCH_CONFIG";
export const DISPATCH_URLS = "DISPATCH_URLS";
export const DISPATCH_SSO_ENABLED = "DISPATCH_SSO_ENABLED";

export const fetchData = (
  fetchValues,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError,
  returnFullResponse
) => {
  const request = () => {
    return { type: fetchValues.request };
  };
  const receive = (data) => {
    return { type: fetchValues.receive, data };
  };
  const receiveFailed = (error) => {
    return { type: fetchValues.receiveFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(request());

    return doRequest(
      fetchValues.obsvcFilename,
      fetchValues.params,
      true,
      showServerError,
      returnFullResponse
    ).then(
      (data) => {
        dispatch(receive(data));
        onSuccess(data);
        return data;
      },
      (error) => {
        dispatch(receiveFailed(error));
        onFailure(error);
        throw error;
      }
    );
  };

  return executer;
};

export const updateData = (
  updateValues,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError
) => {
  const update = () => {
    return { type: updateValues.update };
  };
  const updateSuccess = (data) => {
    return { type: updateValues.updateSuccess, data };
  };
  const updateFailed = (error) => {
    return { type: updateValues.updateFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }
    dispatch(update());

    return doRequest(
      updateValues.obsvcFilename,
      updateValues.params,
      true,
      showServerError
    ).then(
      (data) => {
        dispatch(updateSuccess(data));
        onSuccess(data);
        return data;
      },
      (error) => {
        dispatch(updateFailed(error));
        onFailure(error);

        throw error;
      }
    );
  };

  return executer;
};

export const createData = (
  createValues,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError
) => {
  const create = () => {
    return { type: createValues.create };
  };
  const createSuccess = (data) => {
    return { type: createValues.createSuccess, data };
  };
  const createFailed = (error) => {
    return { type: createValues.createFail, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }
    dispatch(create());

    return doRequest(
      createValues.obsvcFilename,
      createValues.params,
      true,
      showServerError
    ).then(
      (data) => {
        dispatch(createSuccess(data));
        onSuccess(data);
        return data;
      },
      (error) => {
        dispatch(createFailed(error));
        onFailure(error);
        throw error;
      }
    );
  };
  return executer;
};

export const deleteData = (
  deleteValues,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError
) => {
  const remove = () => {
    return { type: deleteValues.delete };
  };
  const removeSuccess = () => {
    return { type: deleteValues.deleteSuccess };
  };
  const removeFailed = (error) => {
    return { type: deleteValues.deleteFailed, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(remove());

    return doRequest(
      deleteValues.obsvcFilename,
      deleteValues.params,
      true,
      showServerError
    ).then(
      (data) => {
        dispatch(removeSuccess());
        onSuccess(data);
        return data;
      },
      (error) => {
        dispatch(removeFailed(error));
        onFailure(error);
        throw error;
      }
    );
  };

  return executer;
};

export const uploadData = (
  uploadValues,
  onSuccess = () => undefined,
  onFailure = () => undefined,
  showServerError
) => {
  const upload = () => {
    return { type: uploadValues.upload };
  };
  const uploadSuccess = (data) => {
    return { type: uploadValues.uploadSuccess, data };
  };
  const uploadFailed = (error) => {
    return { type: uploadValues.uploadFailed, error };
  };

  const executer = async (dispatch, getState) => {
    while (getState().loginReducer.login.tokenRenewalInProgress) {
      await sleep(200);
    }

    dispatch(upload());

    return doRequest(
      uploadValues.obsvcFilename,
      uploadValues.params,
      false,
      showServerError
    ).then(
      (data) => {
        dispatch(uploadSuccess(data));
        onSuccess();
        return data;
      },
      (error) => {
        dispatch(uploadFailed(error));
        onFailure();
        throw error;
      }
    );
  };

  return executer;
};

export const CompanyListActions = {
  request: "REQUEST_ISSUERS",
  postSuccess: "RECEIVE_ISSUERS",
  postFail: "RECEIVE_ISSUERS_FAILED",
};
export const fetchCompanyList = () =>
  postData(CompanyListActions, undefined, undefined, "v1/company/list");

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PASSWORD_CHANGE_OK = "PASSWORD_CHANGED";
export const PASSWORD_CHANGE_FAIL = "PASSWORD_NOT_CHANGED";
export const PASSWORDS_DO_NOT_MATCH = "PASSWORDS_DO_NOT_MATCH";
export function changePasswordOnLogin(password1, password2) {
  const requestPasswordChange = () => {
    return {
      type: CHANGE_PASSWORD,
    };
  };
  const passwordChangeSuccessful = () => {
    return {
      type: PASSWORD_CHANGE_OK,
    };
  };
  const passwordChangeFail = (error) => {
    return {
      type: PASSWORD_CHANGE_FAIL,
      error,
    };
  };
  const passwordsDoNotMatch = () => {
    return {
      type: PASSWORDS_DO_NOT_MATCH,
    };
  };

  return (dispatch) => {
    if (password1 === password2) {
      dispatch(requestPasswordChange());

      return doRequest("common/changePassword.obsvc", {
        password1: password1,
        password2: password2,
      }).then(
        (data) => dispatch(passwordChangeSuccessful()),
        (error) => dispatch(passwordChangeFail(error))
      );
    }

    dispatch(passwordsDoNotMatch());
  };
}
export const RENEW_TOKEN = "RENEW_TOKEN";
export const RENEW_TOKEN_SUCCESS = "RENEW_TOKEN_SUCCESS";
export const RENEW_TOKEN_FAIL = "RENEW_TOKEN_FAIL";

export const renewLoginToken = (loginWithSSO) => {
  const signalStartRenewal = () => {
    return { type: RENEW_TOKEN };
  };
  const signalRenewalSuccess = () => {
    return { type: RENEW_TOKEN_SUCCESS };
  };
  const signalRenewalFail = () => {
    return { type: RENEW_TOKEN_FAIL };
  };
  const decodeToken = (token) =>
    token != null ? jwt_decode(token) : undefined;
  const tokenHasExpired = (decodedToken) => {
    if (decodedToken == null) return true;

    const expiryDate = moment.unix(decodedToken.exp);
    const now = moment();
    return expiryDate < now;
  };
  const timeUntilRenewal = (decodedToken) => {
    const expiryDate = moment.unix(decodedToken.exp);
    const now = moment();
    return Math.max(0, expiryDate - now - 2000);
  };

  const executer = (dispatch) => {
    dispatch(signalStartRenewal());

    if (!loginWithSSO) {
      return doPostRequest(
        "v1/authn/twoFactor/renew",
        undefined,
        undefined,
        true,
        true
      ).then(
        (data) => {
          const { token } = data.data.entity;
          if (token) {
            deleteToken();
            setToken(token);
            deleteCASLogin();
            dispatch(signalRenewalSuccess());
            setTimeout(
              () => dispatch(renewLoginToken(false)),
              timeUntilRenewal(decodeToken(token))
            );
            return data.data.entity;
          }
        },
        (error) => {
          dispatch(signalRenewalFail());
          dispatch(logOut());
          throw error;
        }
      );
    } else {
      return doPostRequest("v1/authn/cas/renew").then(
        (data) => {
          const { token } = data.data.entity;
          if (token) {
            deleteToken();
            setToken(token);
            setCASLogin();
            dispatch(signalRenewalSuccess());
            setTimeout(
              () => dispatch(renewLoginToken(true)),
              timeUntilRenewal(decodeToken(token))
            );
            return data.data.entity;
          }
        },
        (error) => {
          dispatch(signalRenewalFail());
          dispatch(logOut());
          throw error;
        }
      );
    }
  };

  if (!getToken()) return (dispatch) => dispatch(logOut());
  if (tokenHasExpired(decodeToken(getToken())))
    return (dispatch) => dispatch(logOut());

  return executer;
};

export const PermissionsActions = {
  request: "REQUEST_PERMISSONS",
  postSuccess: "RECEIVE_PERMISSIONS",
  postFail: "RECEIVE_PERMISSIONS_FAILED",
};
export const fetchPermissions = () =>
  postData(
    PermissionsActions,
    undefined,
    undefined,
    "v1/sysadmin/loginPerson/permissions"
  );

export const UserOrganisationActions = {
  request: "REQUEST_USER_ORGANIZATION",
  postSuccess: "RECEIVE_USER_ORGANIZATION",
  postFail: "RECEIVE_USER_ORGANIZATION_FAILED",
};
export const fetchUserOrganisation = () =>
  postData(
    UserOrganisationActions,
    undefined,
    undefined,
    "v1/sysadmin/organisation/get"
  );

export const LoginUserActions = {
  request: "REQUEST_LOGIN_USER",
  postSuccess: "RECEIVE_LOGIN_USER",
  postFail: "RECEIVE_LOGIN_USER_FAILED",

  loginComplete: "LOGIN_COMPLETE",
  incompatibleUser: "INCOMPATIBLE_USER",
};
export const fetchLoginUser = () =>
  postData(
    LoginUserActions,
    undefined,
    {
      params: { globalSubject: getGlobalSubject() },
    },
    "v1/sysadmin/loginPerson/get"
  );

export const CompanyPermission = {
  request: "REQUEST_COMPANY_PERMISSION_LIST",
  postSuccess: "RECEIVE_COMPANY_PERMISSION_LIST",
  postFail: "RECEIVE_COMPANY_PERMISSION_LIST_FAILED",
};

export const fetchCompanyPermission = (cid) =>
  postData(
    CompanyPermission,
    undefined,
    { cid: cid },
    "v1/sysadmin/loginPerson/permissions"
  );

export const setupLoginData = (loginWithSSO) => {
  const setLanguage = (language) => {
    let lng = language || i18n.language;

    const localLang = getLocalLanguage();
    if (localLang) {
      lng = localLang;
    }

    if (lng != null)
      return new Promise((resolve) =>
        resolve(
          lng.toLowerCase() === "en"
            ? i18n.changeLanguage("en")
            : i18n.changeLanguage("nb")
        )
      );
    else i18n.changeLanguage("nb");
  };

  const loginComplete = () => {
    return { type: LoginUserActions.loginComplete };
  };

  const userNotCompatible = () => {
    return { type: LoginUserActions.incompatibleUser };
  };

  return (dispatch) => {
    return new Promise((resolve) =>
      resolve(dispatch(() => dispatch(renewLoginToken(loginWithSSO))))
    )
      .then(() => dispatch(fetchPermissions()))
      .then((res) => {
        if (res && res.data && res.data.entity.permissions.userPermissions && res.data.entity.permissions.userPermissions.includes("gui")) {
          // EVERYTHING IS OKAY
          return Promise.resolve(res);
        } else {
          // Should route user to "non-compatible-user"
          return Promise.reject(dispatch(userNotCompatible()));
        }
      })
      .then(() => dispatch(fetchLoginUser()))
      .then((data) => {
        dispatch(fetchPublicProps(data.data.entity.organisationId));
        return Promise.resolve(data);
      })
      .then(({ entity }) => setLanguage(entity.preferedLang))
      .then(() => dispatch(fetchUserOrganisation()))
      .then(() => dispatch(loginComplete()))
      .catch(() => dispatch(loginComplete()));
  };
};

export const TWO_FACTOR_PROP_KEY = "int.system.global.enableTwoFactor";
export const SSO_ENABLED_PROP_KEY = "int.system.global.ssoEnabled";

const TwoFactorLogoutActions = {
  request: "REQUEST_TWO_FACTOR_LOGOUT",
  postSuccess: "RECEIVE_TWO_FACTOR_LOGOUT",
  postFail: "RECEIVE_TWO_FACTOR_LOGOUT_FAILED",
};

export const logout = () =>
  postData(
    TwoFactorLogoutActions,
    undefined,
    undefined,
    "v1/authn/twoFactor/logout"
  );

export const setLocalLanguage = (language) => {
  if (sessionStorage) {
    sessionStorage.setItem(LOCAL_LANG_KEY, language);
  }
};

export const getLocalLanguage = () => {
  if (sessionStorage) {
    return sessionStorage.getItem(LOCAL_LANG_KEY);
  }
};
export const deleteLocalLanguage = () => {
  if (sessionStorage) {
    return sessionStorage.removeItem(LOCAL_LANG_KEY);
  }
};

export const fetchConfig = () => {
  return fetch("/env.json", {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      throw err;
    });
};
