import React from "react";
import Table, { TD, TR } from "../components/Table";
import styled from "styled-components";
import {
  browserIsIE,
  getLanguage,
  getOBNTDateTime,
} from "../util/common";
import CheckBox from "../components/CheckBox";
import { FlexContainer } from "../components/FlexContainer";
import Input from "../components/Input";
import DayPicker from "../components/DayPicker";
import Label from "../components/Label";
import { Field, reduxForm } from "redux-form";
import { SelectDropDown } from "./FormOne";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import { ActionText } from "../components/ActionText";
import { MAX_RESULT_ON_PAGE } from "../components/PaginationContainer";
import { PageHeading } from "../components/PageHeading";
import { connect } from "react-redux";

const Icon = styled.img`
  cursor: pointer;
  ${props => (props.inactive ? "opacity: 0.5;" : "")};
`;

const InfoIcon = props => (
  <img
    src="/icons/info-pliktig-opplysninger.svg"
    alt={props.altText}
    title={props.altText}
  />
);

const LagringIcon = props => (
  <img
    src="/icons/lagringspliktig.svg"
    alt={props.altText}
    title={props.altText}
  />
);

const EditIcon = props => (
  <Icon
    {...props}
    src="/icons/ic-edit-black-24-px.svg"
    alt={props.altText}
    title={props.altText}
  />
);

const MessageTitle = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

const SearchBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer
      column
      margin={[0, 8, 0, 0]}
      maxWidth={browserIsIE() ? 450 : 170}
    >
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>
      <Input
        {...props}
        {...props.input}
        border
        onKeyDown={e => {
          switch (e.keyCode) {
            case 13: //Enter
              props.handleSubmit();
          }
        }}
      />
    </FlexContainer>
  );
};

const DateBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, props.noRightMarign ? 0 : 8, 0, 0]}>
      <Label
        maxWidth
        bold
        htmlFor={props.name}
        margin={[8, props.noRightMarign ? 0 : 8, 3, 0]}
      >
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : undefined}
      </Label>

      <DayPicker {...props} border />
    </FlexContainer>
  );
};
class SearchComponent extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <form autoComplete="off">
        <FlexContainer margin={[40, 0, 10, 0]}>
          {t("messages_from_newspoint")}
        </FlexContainer>

        <FlexContainer row margin={[10, 0, 20, 0]} vAlignEnd spaceBetween>
          <FlexContainer minWidth={900} row>
            <Field
              component={SelectDropDown}
              t={this.props.t}
              hiddenToolTip={true}
              placeholder={t("select")}
              options={this.props.companyOptionList}
              name="issuer"
              label={t("issuer")}
            />
            <Field name="fromDate" component={DateBox} label={t("fromDate")} />
            <Field name="toDate" component={DateBox} label={t("toDate")} />
            <Field
              name="pub"
              noRightMargin
              component={SearchBox}
              handleSubmit={this.props.handleSubmit(this.props.onSubmit)}
              label={t("user")}
            />
          </FlexContainer>
          <FlexContainer vAlignCenter row width="auto" spaceBetween>
            <Button.Standard
              margin={[0, 0, 0, 30]}
              onClick={this.props.handleSubmit(this.props.onSubmit)}
            >
              {t("search")}
            </Button.Standard>
            <ActionText
              margin={[0, 0, 0, 30]}
              onClick={() => {
                this.props.reset();
              }}
            >
              {t("reset_search")}
            </ActionText>
          </FlexContainer>
        </FlexContainer>
      </form>
    );
  }
}
SearchComponent = reduxForm({ form: "messageSearch" })(SearchComponent);

class MessageList extends React.Component {
  render() {
    const { t } = this.props;

    const thLabels = [
      t("messageId"),
      t("issuerIdShort"),
      t("message"),
      t("category"),
      t("status"),
      this.props.publishedLabel,
      this.props.publishedByLabel
    ];

    if (
      this.props.globalPermissionList.find(value => value === "news.correct") ||
      this.props.showAll
    )
      thLabels.push(this.props.correctLabel);

    if (
      this.props.globalPermissionList.some(value => value === "news.newswatch")
    ) {
      thLabels.push(t("type"));
      thLabels.push(t("OBNT"));
      thLabels.push(t("system"));
    }

    const arrWidth = [98, 384, 252, 160, 120, 138, 85, 85, 85, 85];
    const headerProps = [
      ,
      ,
      ,
      ,
      ,
      ,
      ,
      { center: true },
      { center: true },
      { center: true },
      { center: true }
    ];

    const tableRows =
      this.props.data != null
        ? this.props.data.map((message, index) => (
            <TR
              key={index}
              redBackgroundImportant={message["test"]}
              title={message["test"] ? t("this_is_a_test_message") : ""}
            >
              <TD>{message.messageId}</TD>

              <TD ellipsis title={message.issuerSign} width={arrWidth[0]}>
                {message.issuerSign}
              </TD>
              <TD
                deleted={message.messageStatus === "DELETED"}
                pointer
                width={arrWidth[1]}
                onClick={() => this.props.onNewsClick(message.id)}
              >
                <MessageTitle>{message.title}</MessageTitle>
              </TD>
              <TD width={arrWidth[2]} ellipsis>
                {message.category["name" + getLanguage()]}
              </TD>
              <TD width={arrWidth[3]}>{t(message.messageStatus)}</TD>
              <TD boldFirstLine width={arrWidth[4]} minWidth={arrWidth[4]}>
                {message.messageStatus === "PENDING_PUBLISHING" ||
                message.messageStatus === "PUBLISHED"
                  ? getOBNTDateTime(
                      message.publishedTime,
                      !this.props.localDateTime
                    )
                  : ""}
              </TD>
              <TD ellipsis width={arrWidth[5]}>
                {message.publishedBy}
              </TD>

              {this.props.globalPermissionList.some(
                value => value === "news.correct"
              ) || this.props.showAll ? (
                <TD width={arrWidth[7]} center>
                  <EditIcon
                    inactive={!this.props.correctionAllowed(message)}
                    altText={this.props.correctionText(message)}
                    onClick={() => {
                      if (this.props.correctionAllowed(message))
                        this.props.onEditMessage(message);
                    }}
                  />
                </TD>
              ) : undefined}

              {this.props.globalPermissionList.some(
                value => value === "news.newswatch"
              )
                ? [
                    <TD center key="type">
                      <FlexContainer row>
                        {message.infoRequired ? (
                          <LagringIcon altText={t("infoRequired_tooltip")} />
                        ) : null}
                        {message.oamMandatory ? (
                          <InfoIcon altText={t("oamMandatory_tooltip")} />
                        ) : null}
                      </FlexContainer>
                    </TD>,
                    <TD center key="obdn">
                      <CheckBox
                        disabled
                        checked={message.distributionAllowed}
                      />
                    </TD>,
                    <TD capitalize center key="system">
                      {message.source ? message.source.toLowerCase() : ""}
                    </TD>
                  ]
                : undefined}
            </TR>
          ))
        : [];

    return (
      <FlexContainer
        column
        padding={
          tableRows && tableRows.length < MAX_RESULT_ON_PAGE
            ? [0, 0, 30, 0]
            : []
        }
      >
        <SearchComponent
          onSubmit={(val => {
            this.props.onMessageSearch(val, !!this.props.isDraftList);
          })}
          companyOptionList={this.props.companyOptionList}
          t={t}
        />

        <Table
          tableRows={tableRows}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
          forceUpdate={true}
          t={t}
        />
        {!this.props.data || this.props.data.length < 1 ? (
          <FlexContainer center vAlignCenter>
            {this.props.noIssuerAccess ? (
              <PageHeading>{t("no_issuer_access")}</PageHeading>
            ) : (
              <PageHeading>{t("not_found")}</PageHeading>
            )}
          </FlexContainer>
        ) : undefined}
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { config } = state;

  return {
    localDateTime: config.config.localDateTime
  };
}

export default connect(mapStateToProps)(MessageList);
