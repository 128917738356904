import {fetchServerFile, getLanguage, showFileFromServer} from "../util/common";
import {FlexContainer} from "./FlexContainer";
import FocusElement from "./FocusElement";
import React from "react";
import styled from "styled-components";

const HelpText = styled.p`
  color: black;
  font-size: 16px;
`;

const DownloadLink = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;
`;

const HelpDocumentsContainer = (props) => {
    const { t } = props;

    const fetchFile = (filename) => {
        props.dispatch(fetchServerFile("v1/sysadmin/help/" + filename))
            .then((data) => {
                return showFileFromServer(data, filename + ".pdf");
            }).catch((err) => {
                console.log(err);
            });
    };

    return (
        <FlexContainer
            column
            height={props.isOpen ? "230px" : "0px"}
            withTransition
            backgroundColor="#c7d3e3"
            padding={props.isOpen ? [0, 0, 24, 64] : [0, 0, 0, 64]}
            margin={props.isOpen ? [0, 0, 24, 0] : [0, 0, 0, 0]}
        >
            <FlexContainer
                column
                hidden={!props.isOpen}
                fontSize="16px"
                margin={[0, 0, 25, 0]}
            >
                <HelpText>
                    <DownloadLink
                        onClick={() => fetchFile("guidance_categories")}
                    >
                        {t("guidance_categories")}
                    </DownloadLink>
                </HelpText>
                <HelpText>
                    <DownloadLink
                        onClick={() => fetchFile("Retningslinjer_for_oslo_bors_newspoint_" + getLanguage().toLowerCase())}
                    >
                        {t("user_documentation")}
                    </DownloadLink>
                </HelpText>
                <HelpText>
                    <DownloadLink
                        onClick={() => fetchFile("form_of_proxy_" + getLanguage().toLowerCase())}
                    >
                        {t("form_of_proxy")}
                    </DownloadLink>
                </HelpText>
                <HelpText>
                    <FocusElement color={"black"} onClick={props.showContactMaHelpModal}>
                        {t("contact_ma")}
                    </FocusElement>
                </HelpText>
            </FlexContainer>
        </FlexContainer>
    );
};

export default HelpDocumentsContainer;