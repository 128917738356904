import { FlexContainer } from "../components/FlexContainer";
import styled from "styled-components";
import React from "react";
import OtherEventBlock from "./OtherEventBlock";

export const ContentHeading = styled.h4`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
`;

const Period = props => {
  const { t } = props;
  return (
    <FlexContainer row spaceBetween>
      <FlexContainer
        greyBackground
        column
        padding={[8, 8, 8, 8]}
        margin={[0, 9, 0, 0]}
      >
        <ContentHeading>{t("periodic_reporting")}</ContentHeading>
        {props.requiredEvents}
      </FlexContainer>
      <OtherEventBlock
        right
        t={t}
        forceUpdate={true}
        existingEvents={props.existingEvents}
        period={props.period}
        onRemoveUserRow={props.onRemoveUserRow}
        onAddUserRow={props.onAddUserRow}
        updateEvent={props.updateEvent}
        updateDateForOptionalEvent={props.updateDateForOptionalEvent}
        optionalEvents={props.optionalEvents}
        isSuperUser={props.isSuperUser}
      />
    </FlexContainer>
  );
};

export default Period;
