import React from "react";
import { FlexContainer } from "../components/FlexContainer";

import styled from "styled-components";
import Label from "./Label";
import { ErrorText } from "./ErrorText";
import { mapAccessLevel } from "../util/common";

const Select = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px 8px 0 8px
    width: 100%;
    height: 239px;
    background-color: white;
    color: black
    border: solid 1px #d0d0d0;
    overflow: auto;

    &:focus {
        outline: none;
    }
`;
const StyledOption = styled.a`
  padding: 4px 8px 4px 8px;
  margin: 1px 0 1px 0;
  cursor: ${props => (props.edit ? "pointer;" : "none:")};
  ${props => (props.selected ? "background: rgba(178, 207, 174, 0.2);" : "")};
  &: hover {
    outline: none;
    background: rgba(178, 207, 174, 0.2);
  }
  &: focus {
    outline: none;
    ${props => (props.hasFocus ? "background: rgba(178, 207, 174, 0.2);" : "")};
  }
`;

const PrivilegePreview = styled.span`
  float: right;
`;

class Option extends React.Component {
  componentDidUpdate() {
    if (this.props.hasFocus) {
      this.option.focus({ preventScroll: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.selected !== this.props.selected ||
      nextProps.tabIndex !== this.props.tabIndex ||
      nextProps.hasFocus !== this.props.hasFocus
    );
  }

  render() {
    const { t } = this.props;

    return (
      <StyledOption
        {...this.props}
        ref={x => (this.option = x)}
        onClick={e => {
          if (this.props.hasFocus) {
            this.option.blur();
          }
          this.props.onClick(e);
          e.preventDefault();
        }}
        onFocus={this.props.onTabFocus}
      >
        {this.props.option.label
          ? this.props.option.label
          : this.props.option.id}
        <PrivilegePreview>
          {mapAccessLevel(this.props.option.accessLevel, t)}
        </PrivilegePreview>
      </StyledOption>
    );
  }
}
class SelectionBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState() {
    return {
      selectedList: []
    };
  }

  populateOptions = (options, selectedList, onClick) => {
    let optionsArray = [];

    if (options) {
      options.forEach(option => {
        optionsArray.push(
          <Option
            key={option.id}
            onTabFocus={() => {
              this.setState({ selectedId: option.id });
            }}
            hasFocus={selectedList.includes(option.id)}
            onClick={() => onClick(option.id)}
            selected={selectedList.includes(option.id)}
            option={option}
            edit={this.props.edit}
            t={this.props.t}
          />
        );
      });
    }
    return optionsArray;
  };
  componentWillUnmount() {
    this.setState(this.getInitialState());
  }

  render() {
    const onClick = id => {
      if (this.props.edit) {
        let selected = this.state.selectedList;
        const index = selected.indexOf(id);
        if (index > -1) {
          selected.splice(index, 1);
        } else {
          selected.push(id);
        }
        this.setState({ selectedList: selected });
        this.props.input.onChange(selected);
      }
    };
    const options = this.populateOptions(
      this.props.options,
      this.state.selectedList,
      onClick
    );

    return (
      <FlexContainer spaceBetween id={this.props.input.name}>
        <Label>{this.props.label}</Label>
        {this.props.meta.error ? (
          <ErrorText>{this.props.meta.error}</ErrorText>
        ) : (
          undefined
        )}
        <Select tabIndex="0">{options}</Select>
      </FlexContainer>
    );
  }
}

export default SelectionBox;
