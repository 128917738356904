import React from "react";
import {addInsiderLogDetails, fetchInsiderSyncClients, fetchInsiderSyncDetails,} from "./InsiderSyncDetialsActions";
import WithPagination from "../../components/PaginationContainer";
import {FlexContainer} from "../../components/FlexContainer";
import InsiderSyncList from "./InsiderSyncList";
import {validateNewInsiderLogDetails} from "../validator";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {StyledLink} from "../../components/StyledLink";
import Table, {TD, TR} from "../../components/Table";
import {WhiteDropDown} from "../../components/WhiteDropDown";
import {SelectDropDown} from "../../messages/FormOne";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Icon} from "../../insiderTool/components";
import AddInsiderLogUrlModal from "./AddInsiderLogUrlModal";
import UpdateSystemProplModal from "../UpdateSystemProplModal";
import {fetchSystemProps, updateSystemProps} from "../SystemAdministratorActions";
import Button from "../../components/Button";
import {hasValue} from "../../util/common";
import {NotificationManager} from "react-notifications";


const PaginatedInsiderSyncList = WithPagination(InsiderSyncList);
const INSIDER_LOG = 'Insider Log';

export const EditDeleteIcon = props => (
    <Icon
        {...props}
        src={props.iconSource}
        alt={props.altText}
        title={props.altText}
    />
);

class InsiderSyncContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            showAddURLModal: false,
            showEditPropModal: false,
            selectedKey: undefined,
            selectedValue: undefined,
            selectedItem: {},
            defaultValuesExpanded: false,
            searchForCompany: undefined,
            selectedClient: undefined
        };
    }

    componentWillMount() {
        this.props.dispatch(fetchInsiderSyncClients())
    }

    getCompanyList = () => {
        const isCompanyAlreadyAdded = (comp) => {
            const foundIt = this.props.insiderSyncDetails.some((item => item.cid === comp.cid));
            return !foundIt
        }
        return this.props.companyList && this.props.companyList.length > 0
            ? this.props.companyList
                .filter(comp => isCompanyAlreadyAdded(comp))
                .map(company => {
                return {
                    label: company.longName,
                    value: company.cid
                };
            })
            : [];
    };

    addInsiderSyncDetails = () => {
        const errors = validateNewInsiderLogDetails(
            this.props.addInsiderLogDetailsForm,
            this.props
        );

        return new Promise((resolve, reject) => {
            if (errors) {
                return reject(new SubmissionError(errors));
            }

            let insiderLogDetails = {};
            insiderLogDetails = {
                cid: this.props.addInsiderLogDetailsForm.company
                    ? this.props.addInsiderLogDetailsForm.company.value
                    : {},
                approvalStatus: this.props.addInsiderLogDetailsForm.insiderLogSync ? 1 : 0,
                insiderLogUrl: this.props.addInsiderLogDetailsForm.insiderLogURL
                    ? this.props.addInsiderLogDetailsForm.insiderLogURL
                    : null,
                clientId: this.props.addInsiderLogDetailsForm.client.value
            };

            this.setState({ loading: true }, () =>
                this.props
                    .dispatch(addInsiderLogDetails(insiderLogDetails))
                    .then(() => {
                        if (this.state.selectedClient !== undefined) {
                            this.props.dispatch(fetchInsiderSyncDetails(this.state.selectedClient.value))
                        }
                    })
                    .then(() => {
                        this.setState({ showAddURLModal: false, loading: false })
                    })
                    .then(() =>
                        NotificationManager.success(this.props.t("success"))
                    )
                    .catch((err) => {
                        this.setState({ loading: false });
                        console.log(err);
                    })
            );
        });
    };


    onSelectClient = (client) => {
        console.log(client)
        this.setState({loading: true, selectedClient: client})
        if (hasValue(client)) {
            this.props.dispatch(fetchInsiderSyncDetails(client.value)).then(() => {
                this.setState({loading: false})
            });
        }
    }

    onCompanySearch = (company) => {
        this.setState({searchForCompany: company !== null ? company.value : undefined})
    }

    getInsiderSyncDetails = () => {
        if (this.state.searchForCompany !== undefined) {
            return this.props.insiderSyncDetails
                .filter(comp => comp.id === this.state.searchForCompany)
                .sort((a, b) => a.issuerName.localeCompare(b.issuerName)
            )
        } else {
            return this.props.insiderSyncDetails.sort((a, b) =>
                a.issuerName.localeCompare(b.issuerName)
            )
        }
    }

    mapClients = () => {
        return (
            this.props.insiderSyncClients.map((key) => {
                return {
                    label: key.name,
                    value: key.id,
                };
            })
        )
    }

    render() {
        const {
            t,
            systemProps,
        } = this.props;
        const featureHeaderProps = [
            {},
            { width: "600px" },
            { center: true, width: "100px;" }
        ];

        const featureThLabels = [t("key"), t("value"), t("edit")];

        const getValueForNonOrganisationProp = key => {
            if (systemProps[key] === "true") {
                return t("yes");
            } else if (systemProps[key] === "false") {
                return t("no");
            } else {
                return (
                    <StyledLink href={systemProps[key]} target={"_blank"}>
                        {systemProps[key]}
                    </StyledLink>
                );
            }
        };

        const featureTableRows = systemProps
            ? Object.keys(systemProps)
                .filter(
                    key =>
                        key ===
                        "public.app.insidertool.global.createInsiderListDisabled" ||
                        key.startsWith(
                            "public.app.insiderlog.global.defaultInsiderLogURL_"
                        )
                )
                .sort().map((key, index) => {
                    return (
                        <TR key={index}>
                            <TD>
                                {t("system_" + key.substring(key.lastIndexOf(".") + 1))}
                            </TD>
                            <TD>{getValueForNonOrganisationProp(key)}</TD>
                            <TD center>
                                <EditDeleteIcon
                                    inactive={false}
                                    altText={t("edit")}
                                    iconSource={"/icons/ic-edit-black-24-px.svg"}
                                    onClick={() => {
                                        this.setState({
                                            showEditPropModal: true,
                                            selectedKey: key,
                                            selectedValue: systemProps[key]
                                        });
                                    }}
                                />
                            </TD>
                        </TR>
                    );
                })
            : [];

        return (
            <FlexContainer>
                <FlexContainer width={"40%"}>
                    <Field
                        component={SelectDropDown}
                        t={this.props.t}
                        hiddenToolTip={true}
                        placeholder={t("choose_client")}
                        options={this.mapClients()}
                        onSelect={this.onSelectClient}
                        name="clients"
                        label={t("choose_customer_of_sync_api")}
                    />
                </FlexContainer>


                {hasValue(this.state.selectedClient) &&
                    <FlexContainer>
                        {this.state.selectedClient.label === INSIDER_LOG &&
                            <WhiteDropDown
                                onClick={() => {
                                    this.setState({defaultValuesExpanded: !this.state.defaultValuesExpanded});
                                }}
                                isOpen={this.state.defaultValuesExpanded}
                                key="defaultValues"
                                whiteBackground
                                header={t("default_values")}
                                containerNoMarginTop
                                noBodyPadding
                            >
                                <Table
                                    tableRows={featureTableRows || []}
                                    tableHeaderLabels={featureThLabels}
                                    tableHeaderProps={featureHeaderProps}
                                    forceUpdate={true}
                                    t={t}
                                    noHeader
                                    key={"features"}
                                    margin={[0, 0, 30, 0]}
                                />
                            </WhiteDropDown>
                        }
                    <PaginatedInsiderSyncList
                        key="insiderSync"
                        t={t}
                        {...this.props}
                        data={this.getInsiderSyncDetails() || []}
                        systemProps={this.props.systemProps}
                        loading={this.state.loading}
                        addInsiderSyncDetails={this.addInsiderSyncDetails}
                        onCompanySearch={this.onCompanySearch}
                        selectedClient={this.state.selectedClient}
                    />

                    </FlexContainer>
                }

                {this.state.showAddURLModal ? (
                    <AddInsiderLogUrlModal
                        t={t}
                        companyList={this.getCompanyList() || []}
                        onClose={() =>
                            this.setState({
                                showAddURLModal: false
                            })
                        }
                        clients={this.mapClients()}
                        addInsiderSyncDetails={this.addInsiderSyncDetails}
                        initialValues={{
                            client: this.state.selectedClient,
                        }}
                        onSelectClient={this.onSelectClient}
                    />
                ) : undefined}



                {this.state.showEditPropModal ? (
                    <UpdateSystemProplModal
                        t={t}
                        selectedKey={this.state.selectedKey}
                        selectedValue={this.state.selectedValue}
                        onClose={() =>
                            this.setState({
                                showEditPropModal: false
                            })
                        }
                        initialValues={{ propValue: this.state.selectedValue }}
                        onSubmit={values => {
                            let systemProps = {};
                            systemProps[this.state.selectedKey] = values.propValue;

                            this.props
                                .dispatch(updateSystemProps(systemProps))
                                .then(() => this.props.dispatch(fetchSystemProps()))
                                .then(() =>
                                    this.setState({
                                        showEditPropModal: false,
                                        selectedValue: undefined,
                                        selectedKey: undefined
                                    })
                                )
                                .catch(err => {
                                    this.setState({ loading: false });
                                    console.log(err);
                                });
                        }}
                    />
                ) : undefined}

                <FlexContainer row center flexEnd vAlignCenter margin={[35, 0, 16, 0]}>
                    <Button.Standard
                        margin={[0, 15, 0, 0]}
                        onClick={() => this.setState({ showAddURLModal: true })}
                    >
                        {t("add_new_event")}
                    </Button.Standard>
                </FlexContainer>

            </FlexContainer>

            )

    }
}

function mapStateToProps(state) {
    const { form, insiderSyncDetailsReducer } = state;

    return {
        insiderSyncDetails: insiderSyncDetailsReducer.insiderSyncDetialsList.list,
        insiderSyncClients: insiderSyncDetailsReducer.insiderSyncClients.list,
        addInsiderLogDetailsForm:
            form &&
            form.addInsiderLogUrlModalForm &&
            form.addInsiderLogUrlModalForm.values
                ? form.addInsiderLogUrlModalForm.values
                : {},
    };
}

InsiderSyncContainer = reduxForm({
    form: "insiderSyncContainer"
})(InsiderSyncContainer);

export default connect(mapStateToProps)(
    withTranslation("translations")(InsiderSyncContainer)
);
