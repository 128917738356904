import {postData, fetchData, deleteData} from "../actions/CommonActionsNoObsvc";

export const FetchOrganisationActions = {
  request: "REQUEST_ORGANISATION",
  postSuccess: "RECEIVE_ORGANISATION",
  postFail: "RECEIVE_ORGANISATION_FAILED",
  clear: "CLEAR_ORGANISATION",

};

export const fetchOrganisation = (id) =>
  postData(
    FetchOrganisationActions,
    undefined,
    { id },
    "v1/sysadmin/organisation/get"
  );

export const clearOrganisation = () => {
    return { type: FetchOrganisationActions.clear };
};

export const UpdateOrganisationActions = {
  request: "UPDATE_ORGANISATION",
  postSuccess: "UPDATE_ORGANISATION_SUCCESS",
  postFail: "UPDATE_ORGANISATION_FAILED",
};

export const updateOrganisation = (organisation, onSuccess, onFailure) =>
  postData(
    UpdateOrganisationActions,
    undefined,
    { ...organisation },
    "v1/sysadmin/organisation/update",
    undefined,
    onSuccess,
    onFailure
  );

export const CreateOrganisationActions = {
  request: "CREATE_ORGANISATION",
  postSuccess: "CREATE_ORGANISATION_SUCCESS",
  postFail: "CREATE_ORGANISATION_FAILED",
};

export const createOrganisation = (organisation, onSuccess, onFailure) =>
  postData(
    CreateOrganisationActions,
    undefined,
    { ...organisation },
    "v1/sysadmin/organisation/add",
    undefined,
    onSuccess,
    onFailure
  );

export const FetchOrganisationList = {
  request: "REQUEST_ORGANIZATION_LIST",
  postSuccess: "RECEIVE_ORGANIZATION_LIST",
  postFail: "RECEIVE_ORGANIZATION_LIST_FAILED",
};

export const fetchOrganisationList = () =>
  postData(
    FetchOrganisationList,
    undefined,
    undefined,
    "v1/sysadmin/organisation/list"
  );

export const FetchUser = {
  request: "REQUEST_USER",
  postSuccess: "RECEIVE_USER",
  postFail: "RECEIVE_USER_FAILED",
};

export const fetchUser = (id) =>
  postData(FetchUser, undefined, { id }, "v1/sysadmin/loginPerson/get");

export const UpdateUser = {
  request: "UPDATE_USER",
  postSuccess: "UPDATE_USER_SUCCESS",
  postFail: "UPDATE_USER_FAILED",
};

export const updateUser = (person) =>
  postData(
    UpdateUser,
    undefined,
    { ...person },
    "v1/sysadmin/loginPerson/update"
  );

export const CreateUser = {
  request: "CREATE_USER",
  postSuccess: "CREATE_USER_FAILED",
  postFail: "CREATE_USER_SUCCESS",
};

export const createUser = (person) =>
  postData(CreateUser, undefined, { ...person }, "v1/sysadmin/loginPerson/add");

export const UserList = {
  request: "REQUEST_USERLIST",
  postSuccess: "RECEIVE_USERLIST",
  postFail: "RECEIVE_USERLIST_FAILED",

  clear: "CLEAR_USERLIST",
};
export const fetchUserList = (organisationId, filter) =>
  postData(
    UserList,
    undefined,
    { organisationId, filter },
    "v1/sysadmin/loginPerson/list"
  );

export const clearUserList = () => ({ type: UserList.clear });

export const UserParameterActions = {
  request: "REQUEST_USER_PARAMS",
  postSuccess: "RECEIVE_USER_PARAMS",
  postFail: "RECEIVE_USER_PARAMS_FAILED",
};

export const fetchUserParams = () =>
  postData(
    UserParameterActions,
    undefined,
    undefined,
    "v1/sysadmin/loginPerson/parameters"
  );

export const UserActivationActions = {
  request: "REQUEST_USER_ACTIVATION",
  postSuccess: "RECEIVE_USER_ACTIVATION",
  postFail: "USER_ACTIVATION_FAILED",
};

export const activateUser = (id) =>
  postData(
    UserActivationActions,
    undefined,
    { id },
    "v1/sysadmin/loginPerson/activate"
  );

export const UserDeactivationActions = {
  request: "REQUEST_USER_DEACTIVATION",
  postSuccess: "RECEIVE_USER_DEACTIVATION",
  postFail: "USER_DEACTIVATION_FAILED",
};

export const deactivateUser = (id) =>
  postData(
    UserDeactivationActions,
    undefined,
    { id },
    "v1/sysadmin/loginPerson/deactivate"
  );

export const UserPasswordResetActions = {
  request: "REQUEST_USER_PASSWORD_RESET",
  postSuccess: "PASSWORD_RESET_SUCCESS",
  postFail: "PASSWORD_RESET_FAILED",
};

export const resetUserPassword = (globalSubject) =>
  postData(
    UserPasswordResetActions,
    undefined,
    { globalSubject: globalSubject },
    "v1/sysadmin/loginPerson/forcePasswordChange"
  );

export const OrganisationStatusToggleActions = {
  request: "REQUEST_SET_ORGANISATION_STATUS",
  postSuccess: "REQUEST_SET_ORGANISATION_STATUS_SUCCESS",
  postFail: "REQUEST_SET_ORGANISATION_STATUS_FAILED",
};

export const toggleOrganisationActivity = (orgId, isActive) =>
  postData(
    OrganisationStatusToggleActions,
    undefined,
    {
      organisationId: orgId,
      params: {
        active: isActive,
      },
    },
    "v1/sysadmin/organisation/setActive"
  );

export const Credentials = {
  request: "UPDATE_CREDENTIALS",
  postSuccess: "UPDATE_CREDENTIALS",
  postFail: "UPDATE_CREDENTIALS_FAILED",
};

export const setCredentials = (
  globalSubject,
  code,
  country,
  phone,
  protocol,
  resetSecret,
  changeType,
  oldPassword,
  newPassword
) =>
  postData(
    Credentials,
    undefined,
    {
      globalSubject,
      resetSecret,
      changeType,
      newCredentials: {
        phone,
        country,
        protocol,
        code,
        password: newPassword,
      },
      oldCredentials: {
        password: oldPassword,
      },
    },
    "v1/sysadmin/loginPerson/updateCredentials"
  );

export const PasswordPolicy = {
    request: "UPDATE_PASSWORD_POLICY",
    postSuccess: "UPDATE_PASSWORD_POLICY",
    postFail: "UPDATE_PASSWORD_POLICY_FAILED",
};

export const setPasswordPolicy = (
    globalSubject,
    changeType,
    policy
) =>
    postData(
        PasswordPolicy,
        undefined,
        {
            globalSubject,
            policy
        },
        "v1/sysadmin/loginPerson/updatePasswordPolicy"
    );

export const PublicKey = {
  request: "REQUEST_SET_PUBLIC_KEY",
  postSuccess: "PUBLIC_KEY_SUCCESS",
  postFail: "PUBLIC_KEY_FAILED",
};

export const setPublicKey = (
    globalSubject,
    values
) =>
    postData(
        PublicKey,
        undefined,
        {
          globalSubject,
          credentials: Object.assign(values, { type: "authorizedKey" })
        },
        "v1/sysadmin/loginPerson/addAuthorizedKey"
    );

export const UpdatePublicKey = {
  request: "UPDATE_PUBLIC_KEY",
  postSuccess: "UPDATE_PUBLIC_KEY_SUCCESS",
  postFail: "UPDATE_PUBLIC_KEY_FAILED",
};

export const updatePublicKey = (
    globalSubject,
    values
) =>
    postData(
        UpdatePublicKey,
        undefined,
        {
          globalSubject,
          credentials: Object.assign(values, { type: "authorizedKey" })
        },
        "v1/sysadmin/loginPerson/updateAuthorizedKey"
    );

export const GenerateApiKey = {
    request: "REQUEST_GENERATE_KEY",
    postSuccess: "GENERATE_KEY_SUCCESS",
    postFail: "GENERATE_KEY_FAILED",
};

export const generateApiKey = (
    globalSubject, values
) =>
    // fetchData(
    //     GenerateApiKey,
    //     undefined,
    //     "v1/sysadmin/loginPerson/generateKey/" + globalSubject,
    //     false
    // );
postData(
    GenerateApiKey,
    undefined,
    {
        globalSubject,
        credentials: Object.assign(values, { type: "apiKey" })
    },
    "v1/sysadmin/loginPerson/generateKey"
);

export const ApiKeys = {
    request: "REQUEST_API_KEYS",
    receive: "RECEIVE_API_KEYS",
    receiveFail: "RECEIVE_API_KEYS_FAILED",
    clear: "CLEAR_API_KEYS"
};

export const getApiKeys = (
    globalSubject,
) =>
    fetchData(
        ApiKeys,
        undefined,
        "v1/sysadmin/loginPerson/getApiKeys/" + globalSubject,
        false
    );

export const clearApiKeys = () => ({ type: ApiKeys.clear });

export const ValidKeys = {
  request: "REQUEST_VALID_KEYS",
  receive: "RECEIVE_VALID_KEYS",
  receiveFail: "RECEIVE_VALID_KEYS_FAILED",
  clear: "CLEAR_VALID_KEYS"
};

export const getPublicKeys = (
    globalSubject,
) =>
    fetchData(
        ValidKeys,
        undefined,
        "v1/sysadmin/loginPerson/getValidKeys/" + globalSubject,
        false
    );

export const clearValidKeys = () => ({ type: ValidKeys.clear });

export const DeleteApiKey = {
    request: "DELETE_API_KEY",
    deleteSuccess: "DELETE_API_KEY_SUCCESS",
    deleteFail: "DELETE_API_KEY_FAILED",
};

export const deleteApiKey = (globalSubject, id) =>
    deleteData(
        DeleteApiKey,
        "v1/sysadmin/loginPerson/deleteApiKey/" + globalSubject + "/" + id,
        undefined
    );

export const UserprofileData = {
    request: "REQUEST_USERPROFILE_DATA",
    receive: "RECEIVE_USERPROFILE_DATA",
    receiveFail: "RECEIVE_USERPROFILE_DATA_FAILED",
};

export const getUserprofileData = (
    globalSubject,
) =>
    fetchData(
        UserprofileData,
        undefined,
        "v1/sysadmin/loginPerson/getUserprofileData/" + globalSubject,
        false
    );

export const UpdateUserprofileData = {
    request: "UPDATE_USERPROFILE_DATA",
    postSuccess: "UPDATE_USERPROFILE_DATA_SUCCESS",
    postFail: "UPDATE_USERPROFILE_DATA_FAILED",
};

export const setUserprofileData = (
    globalSubject,
    values
) =>
    postData(
        UpdateUserprofileData,
        undefined,
        values,
        "v1/sysadmin/loginPerson/setUserprofileData/" + globalSubject
    );