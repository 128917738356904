import { PageHeading } from "../components/PageHeading";
import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { INTERNAL_DOCUMENTATION } from "./InsiderToolContainer";
import { InsiderType, updateUTCTime } from "./InsiderToolActions";
import Label from "../components/Label";
import moment from "moment";
import CheckBox from "../components/CheckBox";
import { exportKnownPersons } from "./knownPersonsUtil";
import Button from "../components/Button";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class InsiderListHeaderRouting extends React.Component {
  getTimeZoneAsString = () => {
    const timeZone = moment.tz.guess();
    const now = moment();
    const UTC = moment.parseZone(now.format()).format("z");
    const offset = moment.parseZone(now.format()).format("Z");
    return timeZone ? timeZone + " (" + UTC + offset + ")" : UTC + offset;
  };

  render() {
    const { t, base, location, history } = this.props;
    const selectedPageIs = string => {
      return location.pathname.includes(string);
    };
    const listPage = location.pathname === base;

    return (
      <FlexContainer column>
        <FlexContainer row spaceBetween>
          <PageHeading margin={[0, 0, 0, 0]}>{t("insidertool")}</PageHeading>
        </FlexContainer>
        <FlexContainer row margin={[10, 0, 10, 0]} center>
          <PageHeading
            isFocusable
            opacity={
              selectedPageIs(InsiderType.LIST) ||
              selectedPageIs(InsiderType.CONFIDENTIALITY_LIST) ||
              listPage
                ? "1"
                : "0.5"
            }
            underline={
              selectedPageIs(InsiderType.LIST) ||
              selectedPageIs(InsiderType.CONFIDENTIALITY_LIST) ||
              listPage
            }
            margin={[0, 20, 0, 0]}
            color={"#1c315b"}
            onClick={() => {
              history.push(`${base}`);
            }}
          >
            {t("my_insider_lists")}
          </PageHeading>

          <PageHeading
            isFocusable
            opacity={
              selectedPageIs(InsiderType.PERMANENT_INSIDERS) ? "1" : "0.5"
            }
            underline={selectedPageIs(InsiderType.PERMANENT_INSIDERS)}
            margin={[0, 20, 0, 0]}
            color={"#1c315b"}
            onClick={() => {
              history.push(`${base}/${InsiderType.PERMANENT_INSIDERS}`);
            }}
          >
            {t("permanent_insiders")}
          </PageHeading>
          <PageHeading
            isFocusable
            opacity={selectedPageIs(INTERNAL_DOCUMENTATION) ? "1" : "0.5"}
            underline={selectedPageIs(INTERNAL_DOCUMENTATION)}
            margin={[0, 0, 0, 0]}
            color={"#1c315b"}
            onClick={() => {
              history.push(`${base}/${INTERNAL_DOCUMENTATION}`);
            }}
          >
            {t("internal_documentation")}
          </PageHeading>
        </FlexContainer>
        <FlexContainer row margin={[10, 0, 10, 0]}>
          <FlexContainer row vAlignCenter>
            <CheckBox
              onChange={value => {
                this.props.dispatch(updateUTCTime(value));
              }}
              label={t("it_show_utc_time_dates")}
              name={"showUTCTime"}
              fontSize={14}
            />
            <Label italic margin={[0, 0, 0, 8]}>
              {t("it_your_time_zone", {
                timezone: this.getTimeZoneAsString()
              })}
            </Label>
          </FlexContainer>
          {listPage ? (
            <FlexContainer row vAlignCenter flexEnd>
              <Button.Standard
                onClick={() => {
                  exportKnownPersons(
                    this.props.knownPersonList,
                    this.props.countryOptions
                  );
                }}
              >
                {t("it_export_all_known_persons")}
              </Button.Standard>
            </FlexContainer>
          ) : (
            undefined
          )}
        </FlexContainer>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;

  return {
    knownPersonList: insiderToolReducer.knownPersons.persons,
    countryOptions: insiderToolReducer.countries.countries || []
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderListHeaderRouting)
);
