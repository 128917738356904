import React from "react";
import {FlexContainer} from "../components/FlexContainer";
import {ConfirmModal} from "../components/ConfirmModal";
import {StyledInput} from "../components/Input";
import {ErrorText} from "../components/ErrorText";
import Button from "../components/Button";
import {hasValue} from "../util/common";

class RenameAttachmentsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            attachments: JSON.parse(JSON.stringify(props.list)),
            warningText: undefined,
            canUpdate: false
        };
    }

    onEditName = (att) => {
        const atts = [...this.state.attachments]
        const attachmentToEdit = atts.find(item => item.attId === att.attId);
        attachmentToEdit.edit = true;
        attachmentToEdit.oldAttName = attachmentToEdit.attName;
        this.setState({attachments: atts})
    }

    onChange = (newName, id) => {
        const atts = [...this.state.attachments]
        const attachmentToEdit = atts.find(item => item.attId === id);
        attachmentToEdit.attName = newName;
        this.setState({attachments: atts, warningText: undefined})
    }

    confirmEdit = () => {
        const atts = [...this.state.attachments]
        const attsToUpdate = atts.filter(att => !this.props.list.some(att2 => att.attName === att2.attName));
        if (attsToUpdate.length < 1) {
            this.setState({warningText: "no_change"})
        }
        else if (!hasValue(this.state.warningText)) {
            this.setState({warningText: "confirm_edit_filename", canUpdate: true})
        } else if (this.state.canUpdate) {
            this.props.onConfirm(attsToUpdate)
        }
    }


    render() {
        const { t } = this.props;

        return (
            <ConfirmModal
                center
                header={t("edit_filename")}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                confirmText={t("confirm")}
                cancelText={t("cancel")}
                onConfirm={this.confirmEdit}
            >
                <FlexContainer trueCenter>
                    {(this.state.attachments || []).map((file, index) => {
                        return (
                        <FlexContainer key={index} width={"60%"} margin={[5,5,5,5]} row>
                            <StyledInput fontSize={17} border defaultValue={file.attName}
                                       disabled={file.edit !== true}
                                       onChange={(e) => this.onChange(e.target.value, file.attId)}
                            >
                            </StyledInput>
                            {file.edit !== true &&
                                <Button.Standard width={"20"}
                                                 notRounded
                                                 onClick={() => this.onEditName(file)}
                                >
                                    <img style={{width:"20px"}} alt={""} src={"/icons/ic-edit-white-24-px.svg"}/>
                                </Button.Standard>
                            }
                        </FlexContainer>
                    )})}
                    {hasValue(this.state.warningText) && <ErrorText margin={[20,0,0,0]}>{t(this.state.warningText)}</ErrorText>}
                </FlexContainer>
            </ConfirmModal>
        );
    }
}

export default RenameAttachmentsModal;