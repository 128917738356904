import { connect } from "react-redux";
import PropTypes from "prop-types";
import MFABoxForm from "./MFABoxForm";
import React from "react";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import { getGlobalSubject } from "../obsvcClient";
import { reset, SubmissionError } from "redux-form";
import styled from "styled-components";
import { fetchUser, updateCredentials } from "../actions/MFAActions";
import Loader, { OverlayLoader } from "../components/Loader";

const MFAErrorText = styled.span`
  white-space: ${props => (props.err500 ? "normal" : "pre-wrap")};
  word-wrap: break-word;
`;

class MFABoxContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    const props = this.props;

    const showError = error => {
      const errorString =
        error.data.details != null &&
        error.data.details.reduce(
          (acc, val) => (acc += `${val.message + "\n\n"}`),
          ""
        );
      NotificationManager.error(
        <MFAErrorText>{errorString}</MFAErrorText>,
        i18next.t("server_error"),
        5000
      );
    };

    const invalidPhone = () => {
      return !/^\+[0-9]+|[0-9]+/i.test(props.mfaValues.twoFactorPhone);
    };

    const validate = () => {
      let errors = {};

      if (invalidPhone()) {
        errors.twoFactorPhone = props.t("invalid_phonenumber");
      }
      return Object.keys(errors).length > 0 ? errors : undefined;
    };

    const validatePhoneNumber = () => {
      // const sleep = () =>
      // new Promise(resolve =>
      //setTimeout(() => resolve(console.log(this.state.loading)), 2000)
      // );
      return new Promise((resolve, reject) => {
        const errors = validate();
        if (errors) {
          reject(new SubmissionError(errors));
        } else {
          Promise.resolve(this.setState({ loading: true })).then(() =>
            this.props
              .dispatch(
                updateCredentials(
                  getGlobalSubject(),
                  null,
                  props.mfaValues.areaCode.value,
                  props.mfaValues.twoFactorPhone,
                  props.mfaValues.twoFactorProtocol,
                  null,
                  10
                )
              )
              .then(props.setTwoFactorCodeRequested)
              .catch(error => {
                showError(error);
              })
              .then(() => this.setState({ loading: false }))
              .then(resolve)
          );
        }
      });
    };

    const handleServerErrors = (error, reject) => {
      if (error.data.details != null) {
        if (error.data.details.some(e => e.code === 1201)) {
          return reject(
            new SubmissionError({
              receivedCode: this.props.t("invalid_one_time_code")
            })
          );
        } else {
          showError(error);
        }
      }
    };

    const updateTwoFactorNumber = () => {
      return new Promise((resolve, reject) => {
        const globalSubject = getGlobalSubject();

        this.props
          .dispatch(
            updateCredentials(
              getGlobalSubject(),
              props.mfaValues.receivedCode,
              null,
              null,
              null,
              null,
              11,
              null
            )
          )
          .then(() => props.closeModal())
          .then(() =>
            NotificationManager.success(
              props.t("Two_factor_number_updated"),
              props.t("success"),
              5000
            )
          )
          .then(() => this.props.dispatch(fetchUser(globalSubject)))
          .then(() => this.props.dispatch(reset("MFABoxForm")))
          .then(resolve)
          .catch(error => {
            handleServerErrors(error, reject);
          });
      });
    };

    return (
      <div>
        <MFABoxForm
          loading={this.state.loading}
          {...props}
          validatePhoneNumber={validatePhoneNumber}
          updateTwoFactorNumber={updateTwoFactorNumber}
          twoFactorCodeRequested={props.twoFactorCodeRequested}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { form } = state;

  return {
    mfaValues: form.MFABoxForm ? form.MFABoxForm.values : undefined
  };
}

MFABoxContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(MFABoxContainer);
