import React from "react";
import styled, { css } from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  ${(props) => (props.noWrap ? "white-space: nowrap;" : "")}
  flex-direction: ${(props) => (props.row ? "row;" : "column;")};
  flex-direction: ${(props) => (props.column ? "column;" : "")};
  width: 100%;

  ${(props) => (props.fontSize ? "font-size: " + props.fontSize + ";" : "")};

  ${(props) => (props.relativeContainer ? "position: relative;" : "")};
  ${(props) => (props.scrollbarsOnOverflow ? "overflow: auto;" : "")};
  ${(props) => (props.scrollbarsOnVerticalOverflow ? "overflow-y: auto;" : "")}

  ${(props) => (props.spaceBetween ? "justify-content: space-between;" : "")};
  ${(props) => (props.center ? "justify-content: center; " : "")};
  ${(props) => (props.flexEnd ? "justify-content: flex-end;" : "")};
  ${(props) => (props.flexStart ? "justify-content: flex-start;" : "")};
  ${(props) => (props.relative ? "position: relative;" : "")};

  ${(props) =>
    props.gridStart ? "justify-self: start; -ms-grid-column-align: start;" : ""}
  ${(props) =>
    props.gridEnd ? "justify-self: end; -ms-grid-column-align: end;" : ""}
    ${(props) =>
    props.center ? "justify-self: center; -ms-grid-column-align: center;" : ""}
    ${(props) =>
    props["-ms-grid-row"] ? "-ms-grid-row: " + props["-ms-grid-row"] + ";" : ""}
    ${(props) =>
    props["-ms-grid-column"]
      ? "-ms-grid-column: " + props["-ms-grid-column"] + ";"
      : ""}

    
    ${(props) =>
    props.maxWidth ? "max-width: " + props.maxWidth + "px;" : ""};
  ${(props) => (props.minWidth ? "min-width: " + props.minWidth + "px;" : "")};
  ${(props) =>
    props.minHeight ? "min-height: " + props.minHeight + "px;" : ""};
  ${(props) =>
    props.maxHeight ? "max-height: " + props.maxHeight + "px;" : ""};
  ${(props) => (props.width ? "width: " + props.width + ";" : "")};

  ${(props) =>
    props.marginBottom ? "margin-bottom: " + props.marginBottom + "px;" : ""}
  ${(props) =>
    props.marginRight ? "margin-right: " + props.marginRight + "px;" : ""}
    ${(props) =>
    props.marginLeft ? "margin-left: " + props.marginLeft + "px;" : ""}
  ${(props) =>
      props.marginTop ? "margin-top: " + props.marginTop + "px;" : ""}
    
    ${(props) =>
    props.boxShadow ? "box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.3);" : ""}
    ${(props) => (props.vAlignCenter ? "align-items: center;" : "")}
    ${(props) => (props.vAlignEnd ? "align-items: flex-end;" : "")}
    ${(props) => (props.vAlignStart ? "align-items: flex-start;" : "")}

    ${(props) =>
    props.greyBackground ? "background: rgba(199, 211, 227, 0.3);" : ""}
    ${(props) => (props.whiteBackground ? "background: white;" : "")}
    ${(props) => (props.blueBackground ? "background: #1c315b;" : "")}
    ${(props) =>
    props.greenBackground ? "background: rgba(178, 207, 174, 0.2);" : ""}
    ${(props) =>
    props.backgroundColor ? "background: " + props.backgroundColor + ";" : ""}

    ${(props) =>
    props.padding
      ? "padding: " +
        props.padding.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}
    ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""}

    
    //This is IE11 specific. This attribute won't have any effect on new browsers.
    ${(props) =>
    props.fitToContent
      ? css`
          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            /* IE10+ specific styles go here */
            width: auto;
          }
        `
      : ""}

    ${(props) => (props.height ? "height: " + props.height + ";" : "")}

    ${(props) => (props.hidden === true ? "display: none;" : "display: flex;")}
    ${(props) => (props.notVisible ? "visibility: hidden;" : "")}

    ${(props) => (props.border ? "border: solid 1px #d0d0d0;" : "")}
  ${(props) => (props.rounded ? "border: solid 1px #eaeaea; border-radius: 20px;" : "")}
    ${props => (props.borderTop ? "border-top: #eaeaea 1px solid;" : "")};
    ${(props) => (props.pre ? "white-space: pre;" : "")}
    ${(props) => (props.preWrap ? "white-space: pre-wrap;" : "")}
    ${(props) => (props.breakWord ? "word-break: break-all;" : "")};

  ${(props) => (props.withTransition ? "transition: 200ms;" : "")}

  ${(props) => (props.pointer ? "cursor: pointer;" : "")}
    
    ${(props) =>
    props.overlay
      ? "background: #e9e9e9; position: fixed; top: 0; right: 0; bottom: 0; left: 0; opacity: 0.5; z-index: 999; pointer-events: none;"
      : ""}
    ${(props) => (props.grey ? "opacity: 0.5; " : "")};
  ${(props) => (props.textAlign ? "text-align: " + props.textAlign + ";" : "")};
    ${(props) =>
    props.bottom ? "bottom: 0; position: absolute;" : ""}
    ${(props) =>
        props.right ? "margin-left: auto;" : ""}
    
    

    ${(props) => (props.trueCenter ? "justify-content: center; align-items: center; " : "")};
  ${(props) => (props.fit ? "width: fit-content;" : "")}

`;
