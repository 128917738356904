import { postData } from "../actions/CommonActionsNoObsvc";
import { EMAIL_DIST_ROOT } from "../emailDistribution/EmailDistributionActions";

export const UnsubscribeEmailListActions = {
  post: "POST_UNSUBSCRIBE_DISTRIBUTION_LISTS",
  postSuccess: "POST_UNSUBSCRIBE_DISTRIBUTION_LISTS_SUCCESS",
  postFail: "POST_UNSUBSCRIBE_DISTRIBUTION_LISTS_FAILED"
};
export const postUnsubscribeEmailList = parameterHash => {
  return postData(
    UnsubscribeEmailListActions,
    undefined,
    undefined,
    EMAIL_DIST_ROOT + "contacts/unsubscribe/" + parameterHash
  );
};
