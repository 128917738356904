import { combineReducers } from "redux";
import {
  DocumentAction,
  DocumentListAction,
  ENTER_HISTORIC_MODE,
  ExternalInsiderActions,
  FetchCountriesActions,
  InsiderToolActions,
  InsiderListListActions,
  InsiderType,
  JsonExcelActions,
  KnownPersonsAction,
  LEAVE_HISTORIC_MODE,
  PermanentListActions,
  TemplateActions,
  TraceLogActions,
  UserList
} from "./InsiderToolActions";
import { UserTypes } from "../admin/AdminContainer";
import {
  ACCESS_LEVEL,
  filterUnique,
  lexicographicalSort
} from "../util/common";
import {
  getDateInOBNTFormat,
  getDateTimeInOBNTFormat,
  getTimeInOBNTFormat
} from "./UTCUtils";
import { LIST_CLOSED } from "./home/InsiderListHome";
import moment from "moment";
import { compareKnownPersonFields } from "./insiderList/insiderTable/handleInsiders/utils/CompareKnownPersonFields";

export let showUTCTime = false;
export const EXCEL_INPUT_FORMAT = "YYYY-MM-DD HH:mm";

const initialExcelList = {
  list: [],
  isFetched: false
};

const getAreaCode = directionalNumber => {
  if (directionalNumber === "+47") return "NO";
  else if (directionalNumber === "+46") return "SE";
  else if (directionalNumber === "+45") return "DK";
  else if (directionalNumber === "+33") return "FR";
  else if (directionalNumber === "+44") return "GB";
  else if (directionalNumber === "+49") return "DE";
  else if (directionalNumber === "+65") return "SG";
  else if (directionalNumber === "+298") return "FO";
  else if (directionalNumber === "+1") return "US";
};

const importList = (
  state = {
    ...initialExcelList
  },
  action
) => {
  switch (action.type) {
    case JsonExcelActions.upload:
      let items = action.data
        ? action.data.items.map(item => {
            if (item.phone) {
              const spaceIndex = item.phone.indexOf(" ");
              if (spaceIndex > -1 && item.phone.indexOf("+") === 0) {
                const areaCode = item.phone.substring(0, spaceIndex);
                const phoneNumber = item.phone.substring(spaceIndex + 1);
                const twoFactorAreaCode = getAreaCode(areaCode);
                if (twoFactorAreaCode) {
                  item.twoFactorCountryCode = twoFactorAreaCode;
                  item.twoFactorPhone = phoneNumber;
                } else {
                  item.twoFactorPhone = item.phone;
                }
              } else {
                item.twoFactorPhone = item.phone;
              }
            }
            delete item.phone;
            if (item.obtained) {
              const obtained = moment(item.obtained, EXCEL_INPUT_FORMAT, true);
              if (obtained.isValid()) {
                item.obtained = getDateTimeInOBNTFormat(obtained, showUTCTime);
              } else {
                delete item.obtained;
              }
            }
            return item;
          })
        : [];
      return Object.assign({}, state, {
        isFetched: true,
        list: items
      });
    default:
      return state;
  }
};

const initialTemplate = {
  template: {}
};
const template = (
  state = {
    ...initialTemplate
  },
  action
) => {
  switch (action.type) {
    case TemplateActions.request:
      return Object.assign({}, state, {
        ...initialTemplate
      });
    case TemplateActions.receive:
      return Object.assign({}, state, {
        template: action.data ? action.data : {}
      });
    default:
      return state;
  }
};

const initalInsiderListListState = {
  list: [],
  isFetched: false,
  mapHasEditAccess: undefined
};
const insiderListList = (
  state = {
    ...initalInsiderListListState
  },
  action
) => {
  switch (action.type) {
    case InsiderListListActions.receive:
      let listList = action.data ? action.data.entries : [];

      listList.forEach(list => {
        list.data.createdTime = getDateTimeInOBNTFormat(
          list.data.created,
          showUTCTime
        );
        list.data.updatedTime = getDateTimeInOBNTFormat(
          list.data.updated,
          showUTCTime
        );
        if (list.data.listState === LIST_CLOSED) {
          list.data.closedTime = getDateTimeInOBNTFormat(
            list.data.closed_date,
            showUTCTime
          );
        }
      });

      return Object.assign({}, state, {
        isFetched: true,
        list: listList,
        mapHasEditAccess: new Map(
          listList.map(list => [
            list.listId,
            list.accessLevel !== ACCESS_LEVEL.READ &&
              list.accessLevel !== ACCESS_LEVEL.NO_ACCESS
          ])
        )
      });

    case InsiderToolActions.updateUTC:
      showUTCTime = action.value;

      let updatedListList = JSON.parse(JSON.stringify(state.list));
      updatedListList.forEach(list => {
        list.data.createdTime = getDateTimeInOBNTFormat(
          list.data.created,
          showUTCTime
        );
        list.data.updatedTime = getDateTimeInOBNTFormat(
          list.data.updated,
          showUTCTime
        );
        if (list.data.listState === LIST_CLOSED) {
          list.data.closedTime = getDateTimeInOBNTFormat(
            list.data.closed_date,
            showUTCTime
          );
        }
      });

      return Object.assign({}, state, {
        isFetched: true,
        list: updatedListList
      });

    default:
      return state;
  }
};

const initalInsiderListState = {
  insiderListInfo: {},
  mandatoryNCAData: {},
  mandatoryKeyData: {},
  insiderStateData: [],
  insiders: [],
  insidersMap: new Map(),
  users: [],
  usersMap: new Map(),
  isFetched: false,
  attachments: [],
  historicTimestamp: undefined,
  historicMode: false
};
export const insiderList = (
  state = {
    ...initalInsiderListState
  },
  action
) => {
  switch (action.type) {
    case InsiderToolActions.receiveFail:
      return Object.assign({}, state, {
        isFetched: false
      });
    case ENTER_HISTORIC_MODE:
      return Object.assign({}, state, {
        historicTimestamp: action.data,
        historicMode: true
      });
    case LEAVE_HISTORIC_MODE:
      return Object.assign({}, state, {
        historicTimestamp: undefined,
        historicMode: false
      });

    case InsiderToolActions.receive:
    case PermanentListActions.receive:
      let listInfo = action.data.entries
        ? action.data.entries.find(
            entry =>
              entry.type === InsiderType.LIST ||
              entry.type === InsiderType.PERMANENT_INSIDERS
          ) || { data: {} }
        : { data: {} };

      if (
        listInfo.type === InsiderType.LIST &&
        listInfo.data.type === InsiderType.CONFIDENTIALITY_LIST
      )
        listInfo.type = InsiderType.CONFIDENTIALITY_LIST;

      const listInfoUpdated = listInfo.data.updated
        ? listInfo.data.updated
        : listInfo.data.created;

      listInfo.data.updatedTime = getDateTimeInOBNTFormat(
        listInfoUpdated,
        showUTCTime
      );
      listInfo.data.update_date = getDateInOBNTFormat(
        listInfoUpdated,
        showUTCTime
      );
      listInfo.data.update_time = getTimeInOBNTFormat(
        listInfoUpdated,
        showUTCTime
      );

      listInfo.hasEditAccess = action.data.accessLevel > ACCESS_LEVEL.READ;

      listInfo.isAdmin = action.data.accessLevel === ACCESS_LEVEL.ADMIN;
      listInfo.isOwnerOrHigher =
        action.data.accessLevel > ACCESS_LEVEL.READ_WRITE;

      let mandatoryKeyData = action.data.entries
        ? action.data.entries.find(
            entry => entry.type === InsiderType.MANDATORY_KEY_INFO
          ) || { data: {} }
        : { data: {} };

      if (mandatoryKeyData.data.date_time_insider_existence) {
        mandatoryKeyData.data.existence_date = getDateInOBNTFormat(
          mandatoryKeyData.data.date_time_insider_existence,
          showUTCTime
        );
        mandatoryKeyData.data.existence_time = getTimeInOBNTFormat(
          mandatoryKeyData.data.date_time_insider_existence,
          showUTCTime
        );
      }
      if (mandatoryKeyData.data.date_time_responsible) {
        mandatoryKeyData.data.responsible_date = getDateInOBNTFormat(
          mandatoryKeyData.data.date_time_responsible,
          showUTCTime
        );
        mandatoryKeyData.data.responsible_time = getTimeInOBNTFormat(
          mandatoryKeyData.data.date_time_responsible,
          showUTCTime
        );
      }
      if (mandatoryKeyData.data.date_time_insider_creation) {
        mandatoryKeyData.data.created_date = getDateInOBNTFormat(
          mandatoryKeyData.data.date_time_insider_creation,
          showUTCTime
        );
        mandatoryKeyData.data.created_time = getTimeInOBNTFormat(
          mandatoryKeyData.data.date_time_insider_creation,
          showUTCTime
        );
      }
      if (mandatoryKeyData.data.likely_disclosure) {
        mandatoryKeyData.data.likely_disclosure_date = getDateInOBNTFormat(
          mandatoryKeyData.data.likely_disclosure,
          showUTCTime
        );
        mandatoryKeyData.data.likely_disclosure_time = getTimeInOBNTFormat(
          mandatoryKeyData.data.likely_disclosure,
          showUTCTime
        );
      }

      if (listInfo.data.listState === LIST_CLOSED) {
        listInfo.data.closedTime = getDateTimeInOBNTFormat(
          listInfo.data.closed_date,
          showUTCTime
        );
      }

      let insiders = action.data.entries
        ? action.data.entries
            .filter(ent => ent.type === InsiderType.PERSON)
            .map(entry => {
              if (entry.data.obtained) {
                entry.data.obtainedTime = getDateTimeInOBNTFormat(
                  entry.data.obtained,
                  showUTCTime
                );
                entry.data.obtained_date = getDateInOBNTFormat(
                  entry.data.obtained,
                  showUTCTime
                );
                entry.data.obtained_time = getTimeInOBNTFormat(
                  entry.data.obtained,
                  showUTCTime
                );
              }
              if (entry.data.ceased) {
                entry.data.ceased_date = getDateInOBNTFormat(
                  entry.data.ceased,
                  showUTCTime
                );
                entry.data.ceased_time = getTimeInOBNTFormat(
                  entry.data.ceased,
                  showUTCTime
                );
                entry.data.ceasedTime = getDateTimeInOBNTFormat(
                  entry.data.ceased,
                  showUTCTime
                );
              }
              return entry;
            })
        : [];

      let insidersMap = new Map(insiders.map(e => [e.id, e]));

      return Object.assign({}, state, {
        insiderListInfo: listInfo,

        mandatoryNCAData: action.data.entries
          ? action.data.entries.find(
              entry => entry.type === InsiderType.MANDATORY_NCA_INFO
            ) || {}
          : {},
        mandatoryKeyData: mandatoryKeyData,
        insiderStateData: action.data.entries
          ? action.data.entries.filter(ent => ent.type === InsiderType.STATE)
          : [],
        insiders: insiders,
        insidersMap: insidersMap,
        users: action.data.entries
          ? action.data.entries.filter(ent => ent.type === UserTypes.USER)
          : [],
        usersMap: action.data.entries
          ? new Map(
              action.data.entries
                .filter(ent => ent.type === UserTypes.USER)
                .map(user => [user.id, user])
            )
          : new Map(),

        attachments: action.data.entries
          ? action.data.entries
              .filter(ent => ent.type === InsiderType.ATTACHMENT)
              .map(ent => ({
                id: ent.id,
                name: ent.short_data,
                listId: ent.listId,
                parentId: ent.parentId
              }))
          : [],
        isFetched: true
      });
    case InsiderToolActions.updateUTC:
      showUTCTime = action.value;
      const insiderListInfoData = state.insiderListInfo.data;
      const updatedTimeStamp = insiderListInfoData
        ? insiderListInfoData.updated
          ? insiderListInfoData.updated
          : insiderListInfoData.created
        : "";
      mandatoryKeyData = state.mandatoryKeyData.data;
      const { ...mandatoryKeyRest } = state.mandatoryKeyData;
      insiders = state.insiders
        ? state.insiders.map(insider => {
            if (insider.data.obtained) {
              insider.data.obtainedTime = getDateTimeInOBNTFormat(
                insider.data.obtained,
                showUTCTime
              );
              insider.data.obtained_date = getDateInOBNTFormat(
                insider.data.obtained,
                showUTCTime
              );
              insider.data.obtained_time = getTimeInOBNTFormat(
                insider.data.obtained,
                showUTCTime
              );
            }
            if (insider.data.ceased) {
              insider.data.ceased_date = getDateInOBNTFormat(
                insider.data.ceased,
                showUTCTime
              );
              insider.data.ceased_time = getTimeInOBNTFormat(
                insider.data.ceased,
                showUTCTime
              );
              insider.data.ceasedTime = getDateTimeInOBNTFormat(
                insider.data.ceased,
                showUTCTime
              );
            }
            return insider;
          })
        : [];

      return Object.assign({}, state, {
        insiderListInfo: insiderListInfoData
          ? {
              ...state.insiderListInfo,
              data: {
                ...insiderListInfoData,
                updatedTime: getDateTimeInOBNTFormat(
                  updatedTimeStamp,
                  showUTCTime
                ),
                update_date: getDateInOBNTFormat(updatedTimeStamp, showUTCTime),
                update_time: getTimeInOBNTFormat(updatedTimeStamp, showUTCTime),
                closedTime: insiderListInfoData.closed_date
                  ? getDateTimeInOBNTFormat(
                      insiderListInfoData.closed_date,
                      showUTCTime
                    )
                  : undefined
              }
            }
          : state.insiderListInfo,
        mandatoryKeyData: mandatoryKeyData
          ? {
              ...mandatoryKeyRest,
              data: {
                ...mandatoryKeyData,
                existence_date: mandatoryKeyData.date_time_insider_existence
                  ? getDateInOBNTFormat(
                      mandatoryKeyData.date_time_insider_existence,
                      showUTCTime
                    )
                  : undefined,
                existence_time: mandatoryKeyData.date_time_insider_existence
                  ? getTimeInOBNTFormat(
                      mandatoryKeyData.date_time_insider_existence,
                      showUTCTime
                    )
                  : undefined,
                responsible_date: mandatoryKeyData.date_time_responsible
                  ? getDateInOBNTFormat(
                      mandatoryKeyData.date_time_responsible,
                      showUTCTime
                    )
                  : undefined,
                responsible_time: mandatoryKeyData.date_time_responsible
                  ? getTimeInOBNTFormat(
                      mandatoryKeyData.date_time_responsible,
                      showUTCTime
                    )
                  : undefined,
                created_date: mandatoryKeyData.date_time_insider_creation
                  ? getDateInOBNTFormat(
                      mandatoryKeyData.date_time_insider_creation,
                      showUTCTime
                    )
                  : undefined,
                created_time: mandatoryKeyData.date_time_insider_creation
                  ? getTimeInOBNTFormat(
                      mandatoryKeyData.date_time_insider_creation,
                      showUTCTime
                    )
                  : undefined,
                likely_disclosure_date: mandatoryKeyData.likely_disclosure
                  ? getDateInOBNTFormat(
                      mandatoryKeyData.likely_disclosure,
                      showUTCTime
                    )
                  : undefined,
                likely_disclosure_time: mandatoryKeyData.likely_disclosure
                  ? getTimeInOBNTFormat(
                      mandatoryKeyData.likely_disclosure,
                      showUTCTime
                    )
                  : undefined
              }
            }
          : state.mandatoryKeyData,
        insiders: insiders
      });

    default:
      return state;
  }
};

const initalUserListState = {
  list: [],
  map: new Map()
};

export const userList = (
  state = {
    ...initalUserListState
  },
  action
) => {
  switch (action.type) {
    case UserList.receive:
      const userList = action.data.users.map(user => {
        return {
          label: user.firstName + " " + user.lastName,
          id: user.globalSubject,
          isAdmin: user.isAdministrator
        };
      });
      const userMap = new Map(
        userList.map(user => [
          user.id,
          {
            label: user.label,
            isAdmin: user.isAdmin
          }
        ])
      );

      return Object.assign({}, state, {
        list: userList,
        map: userMap
      });
    case UserList.clear:
      return Object.assign({}, state, {
        ...initalUserListState
      });
    default:
      return state;
  }
};

const initialTraceLogState = {
  mandatoryNCAData: [],
  mandatoryKeyData: [],
  insiderData: [],
  allData: []
};
const tracelog = (
  state = {
    ...initialTraceLogState
  },
  action
) => {
  switch (action.type) {
    case TraceLogActions.receive:
      const entries = action.data.entries ? action.data.entries : [];
      return Object.assign({}, state, {
        mandatoryKeyData: entries.filter(
          x =>
            x.type === InsiderType.MANDATORY_KEY_INFO ||
            x.type === InsiderType.ATTACHMENT
        ),
        mandatoryNCAData: entries.filter(
          x => x.type === InsiderType.MANDATORY_NCA_INFO
        ),
        insiderData: entries.filter(x =>
          [InsiderType.PERSON, InsiderType.STATE].includes(x.type)
        ),
        allData: action.data.entries.sort((x, y) =>
          lexicographicalSort(x.type, y.type)
        )
      });

    default:
      return state;
  }
};

const initialExternalInsider = {
  insider: {},
  header: {}
};
const externalInsider = (
  state = {
    ...initialExternalInsider
  },
  action
) => {
  switch (action.type) {
    case ExternalInsiderActions.receive:
      let insider = action.data.data;
      const header = action.data.header;
      if (insider.data) {
        if (insider.data.obtained) {
          insider.data.obtainedTime = getDateTimeInOBNTFormat(
            insider.data.obtained,
            showUTCTime
          );
          insider.data.obtained_date = getDateInOBNTFormat(
            insider.data.obtained,
            showUTCTime
          );
          insider.data.obtained_time = getTimeInOBNTFormat(
            insider.data.obtained,
            showUTCTime
          );
        }
        if (insider.data.ceased) {
          insider.data.ceased_date = getDateInOBNTFormat(
            insider.data.ceased,
            showUTCTime
          );
          insider.data.ceased_time = getTimeInOBNTFormat(
            insider.data.ceased,
            showUTCTime
          );
          insider.data.ceasedTime = getDateTimeInOBNTFormat(
            insider.data.ceased,
            showUTCTime
          );
        }
      }
      return Object.assign({}, state, {
        insider: insider,
        header: header
      });
    default:
      return state;
  }
};

const initialKnownPersons = {
  persons: [],
  uniqueKnownPersonOptionList: []
};

const knownPersons = (
  state = {
    ...initialKnownPersons
  },
  action
) => {
  switch (action.type) {
    case KnownPersonsAction.receive:
      return Object.assign({}, state, {
        persons: action.data.entries ? action.data.entries : [],
        uniqueKnownPersonOptionlist: action.data.entries
          ? action.data.entries
              .filter(
                person =>
                  person.data && (person.data.first_name || person.data.surname)
              )
              .filter(
                (person, index, arr) =>
                  arr.findIndex(person2 =>
                    Object.compare(
                      compareKnownPersonFields(person),
                      compareKnownPersonFields(person2)
                    )
                  ) === index
              )
              .sort(knownPersonsSort)
              .map(person => {
                return {
                  label:
                    (person.data.first_name || "") +
                    " " +
                    (person.data.surname || ""),
                  value: person.id
                };
              })
          : []
      });
    default:
      return state;
  }
};

const knownPersonsSort = (personA, personB) => {
  const personAFirstName = personA.data.first_name || "";
  const personASurname = personA.data.surname || "";
  const personAFMTime = moment(personA.fm_time);

  const personBFirstName = personB.data.first_name || "";
  const personBSurname = personB.data.surname || "";
  const personBFMTime = moment(personB.fm_time);

  if (
    personAFirstName
      .toLocaleLowerCase()
      .localeCompare(personBFirstName.toLocaleLowerCase(), "nb-NO") !== 0
  ) {
    return personAFirstName
      .toLocaleLowerCase()
      .localeCompare(personBFirstName.toLocaleLowerCase(), "nb-NO");
  }
  if (
    personASurname
      .toLocaleLowerCase()
      .localeCompare(personBSurname.toLocaleLowerCase(), "nb-NO") !== 0
  ) {
    return personASurname
      .toLocaleLowerCase()
      .localeCompare(personBSurname.toLocaleLowerCase(), "nb-NO");
  }
  if (personAFMTime.isBefore(personBFMTime)) return 1;
  if (personAFMTime.isAfter(personBFMTime)) return -1;

  return 0;
};

const UTC = (
  state = {
    showUTC: false
  },
  action
) => {
  switch (action.type) {
    case InsiderToolActions.updateUTC:
      return Object.assign({}, state, {
        showUTC: action.value
      });
    default:
      return state;
  }
};

const initialDocument = {
  document: undefined,
  list: [],
  map: new Map(),
  types: [],
  attachments: [],
  fullAttachmentList: []
};

const document = (
  state = {
    ...initialDocument
  },
  action
) => {
  switch (action.type) {
    case DocumentAction.createSuccess:
    case DocumentAction.receive:
      return Object.assign({}, state, {
        document: action.data,
        attachments: action.attachments || []
      });

    case DocumentListAction.receive:
      const docEntries = (action.data.entries || []).filter(
        e => e.type === InsiderType.DOCUMENT
      );
      const attachmentEntries = (action.data.entries || []).filter(
        e => e.type === InsiderType.ATTACHMENT
      );

      const typeEntries = docEntries.filter(e => e.data.type !== undefined);

      return Object.assign({}, state, {
        fullAttachmentList: attachmentEntries,
        list: docEntries,
        map: new Map(docEntries.map(d => [d.id, d])),
        types:
          typeEntries.length === 0
            ? undefined
            : typeEntries
                .map(e => e.data.type)
                .filter(filterUnique)
                .map(type => (type ? { label: type, value: type } : {}))
      });

    default:
      return state;
  }
};

const initialCountries = {
  countries: []
};

const countries = (
  state = {
    ...initialCountries
  },
  action
) => {
  switch (action.type) {
    case FetchCountriesActions.receive:
      return Object.assign({}, state, {
        countries: action.data.countries
      });
    default:
      return state;
  }
};

const insiderToolReducer = combineReducers({
  insiderListList,
  insiderList,
  userList,
  tracelog,
  importList,
  template,
  externalInsider,
  knownPersons,
  document,
  UTC,
  countries
});

export default insiderToolReducer;
