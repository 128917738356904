import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import WithPagination from "../components/PaginationContainer";
import { reset } from "redux-form";
import { isSuperUser } from "../util/common";
import EmailDistributionList from "./EmailDistributionList";
import {
  fetchDistributionLists,
  createDistributionList
} from "./EmailDistributionActions";
import emailDistributionReducer from "./EmailDistributionReducer";
import { PageHeading } from "../components/PageHeading";
import { emailDistributionListFormValidator } from "./validator";
import { SubmissionError } from "redux-form";
import { NotificationManager } from "react-notifications";
import { OverlayLoader } from "../components/Loader";
import { fetchOrganisationList } from "../admin/AdminActions";

//const PaginatedEmailDistributionList = WithPagination(EmailDistributionList);

class EmailDistributionListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentWillMount() {
    this.props
      .dispatch(
        fetchDistributionLists(
          isSuperUser(this.props.userLoggedIn)
            ? 0
            : this.props.userLoggedIn.organisationId
        )
      )
      .then(() => {
        if (isSuperUser(this.props.userLoggedIn)) {
          return this.props.dispatch(fetchOrganisationList());
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { t } = this.props;

    const addNewList = () => {
      const errors = emailDistributionListFormValidator(
        this.props.newListValues,
        this.props
      );
      return new Promise((resolve, reject) => {
        if (errors) {
          return reject(new SubmissionError(errors));
        }

        var newDistibutionList = {
          orgId: this.props.userLoggedIn.organisationId,
          name: this.props.newListValues.name,
          description: this.props.newListValues.description
        };

        this.setState({ loading: true }, () =>
          this.props
            .dispatch(createDistributionList(newDistibutionList))
            .then(() =>
              this.props
                .dispatch(
                  fetchDistributionLists(
                    isSuperUser(this.props.userLoggedIn)
                      ? 0
                      : this.props.userLoggedIn.organisationId
                  )
                )

                .then(() =>
                  NotificationManager.success(
                    this.props.t("edist_list_created")
                  )
                )
                .then(() => this.props.dispatch(reset("addToListComponent")))
                .then(() => this.setState({ loading: false }))
                .then(resolve)
                .catch(err => {
                  console.log(err);
                  this.setState({ loading: false });
                })
            )
            .catch(err => {
              console.log(err);
              this.setState({ loading: false });
            })
        );
      });
    };

    const fetchAllListsSuperUser = () => {
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(fetchDistributionLists(0))
          .finally(() => this.setState({ loading: false }))
      );
    };

    return (
      <FlexContainer>
        <PageHeading>{t("email_management")}</PageHeading>
        {this.state.loading ? <OverlayLoader /> : ""}
        <EmailDistributionList
          key="recent"
          t={t}
          data={this.props.distributionList}
          {...this.props}
          addNewList={addNewList}
          isSuperUser={isSuperUser(this.props.userLoggedIn)}
          fetchAllListsSuperUser={fetchAllListsSuperUser}
          organisationList={this.props.organisationList}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { emailDistributionReducer, form, loginUser, adminReducer } = state;
  return {
    newListValues:
      form.addToListComponent && form.addToListComponent.values
        ? form.addToListComponent.values
        : {},
    distributionList: emailDistributionReducer.distributionList.list,
    userLoggedIn: loginUser.user,
    organisationList:
      adminReducer.organisationList && adminReducer.organisationList.list
        ? adminReducer.organisationList.list
        : []
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(EmailDistributionListContainer)
);
