import React from "react";
import styled from "styled-components";
import { FlexContainer } from "./FlexContainer";
import { HelpModal } from "./HelpModal";
import { HelpIcon } from "./HelpIcon";
import i18n from "../i18n";

const DropDownContainer = styled.div`
  display: block;
  ${props => (props.containerNoMarginTop ? "" : "margin-top: 10px")};
  width: 100%;
  ${props => (props.withLeftPadding ? "padding: 16px;" : "")};
`;

const DropDownHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #336699;
  //justify-self:left; //x-aksen;
  //align-self:start; //y-aksen;
  margin-top: 10px;

  //just for test
  height: 54px;
`;

const DropDownIcon = styled.img`
  margin-left: 16px;
  cursor: pointer;
  align: center;
  //padding-top: 20px;
  padding-right: 25px;
  float: right;
`;

const DropDownHeader = styled.h2`
  text-align: left;
  padding: 0;
  color: #ffffff;
  margin-left: 16px;

  font-size: 16px;

  ${props => (props.boldHeader ? "font-weight: 600;" : " font-weight: 500;")};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const DropDownBody = styled.div`
  background-color: rgb(238, 242, 247);
  white-space: pre-wrap;
  ${props => (props.noBodyPadding ? "" : "padding: 16px")};
  //just for test
  min-height: 30px;
`;

export class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);

    this.state = {
      isOpen: this.props.defaultOpen ? true : false
    };
  }

  onClick(e) {
    if (e.target.id === this.props.id + "edit") return;
    if (e.target.id === this.props.helpFileName + "help") return;
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { header, children, helpFileName, ...rest } = this.props;

    return (
      <DropDownContainer
        withLeftPadding={this.props.withLeftPadding}
        containerNoMarginTop={this.props.containerNoMarginTop}
      >
        <DropDownHeaderContainer onClick={this.onClick}>
          <DropDownHeader boldHeader={this.props.boldHeader}>
            {header}
            {helpFileName ? (
              <HelpIcon
                language={i18n.language}
                fileName={helpFileName}
                whiteIcon
                insiderToolHelp
                margin={[0, 0, -2, 5]}
                id={helpFileName + "help"}
              />
            ) : (
              undefined
            )}
          </DropDownHeader>
          <FlexContainer vAlignCenter row width="auto">
            <DropDownIcon
              key={"icon"}
              src={
                this.state.isOpen
                  ? "/icons/ic-keyboard-arrow-up-white-24-px.svg"
                  : "/icons/ic-keyboard-arrow-down-white-24-px.svg"
              }
            />
            {this.props.editIcon ? (
              <DropDownIcon
                onClick={this.props.onEdit}
                id={this.props.id + "edit"}
                key={"iconEdit"}
                src={"/icons/ic-edit-white-24-px.svg"}
              />
            ) : (
              ""
            )}
          </FlexContainer>
        </DropDownHeaderContainer>
        {this.state.isOpen ? (
          <DropDownBody
            id={"dropDownBody"}
            {...rest}
            isOpen={this.state.isOpen}
          >
            {children}
          </DropDownBody>
        ) : (
          ""
        )}
      </DropDownContainer>
    );
  }
}
