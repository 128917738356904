import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {FlexContainer} from "../components/FlexContainer";
import {Route} from "react-router-dom";
import {fetchCompanyList, fetchSystemProps,} from "./SystemAdministratorActions";
import {PageHeading} from "../components/PageHeading";
import ModuleForm from "./systemSettings/ModuleForm";
import LoggedInUsers from "./LoggedInUsers";
import UsersTwoFactorDisabledContainer from "./usersWithTwoFactorDisabled/UsersTwoFactorDisabledContainer";
import AdminContainer from "../admin/AdminContainer";
import InsiderSyncContainer from "./InsiderSyncApi/InsiderSyncContainer";
import StatusContainer from "../status/StatusContainer";
import SystemSettingsContainer from "./systemSettings/SystemSettingsContainer";
import {isSuperUser} from "../util/common";

const USERS = "/system";
const SETTINGS = "/system/settings";
const ACTIVEUSERS = "/system/activeusers";
const INSIDER_LOG_LINKS_NEW = "/system/insidersync";
const USER_ADMIN = "/system/useradmin";
const STATUS = "/system/status";


class SystemAdministratorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.findValueOfSystemProp = this.findValueOfSystemProp.bind(this);
  }
  componentWillMount() {
    this.props.dispatch(fetchSystemProps())
    this.props.dispatch(fetchCompanyList())
    if (
      !this.moduleShouldBeShown("twoFactorDisabledUsers") &&
      this.props.location.pathname !== ACTIVEUSERS
    ) {
      this.props.history.push(`${this.props.base}/activeusers`);
    }
  }

  findValueOfSystemProp(sysPropKey) {
    return this.props.systemPropsList.find(
      (element) => element.key === sysPropKey
    );
  }

  moduleShouldBeShown(moduleName) {
    const { config } = this.props;
    return (
      !config ||
      !config.adminOptions ||
      (config &&
        config.adminOptions &&
        config.adminOptions.includes(moduleName))
    );
  }

  render() {
    const { t, base, systemPropsObject } =
      this.props;

    return (
      <FlexContainer>
        <FlexContainer>
          <FlexContainer center row>
            {this.moduleShouldBeShown("twoFactorDisabledUsers") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                onClick={() => {
                  this.props.history.push(`${base}`);
                }}
                opacity={this.props.location.pathname === USERS ? "1.0" : "0.5"}
                underline={this.props.location.pathname === USERS}
                pointer={this.props.location.pathname !== USERS}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("users_two_factor_disabled")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("activeUsers") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === ACTIVEUSERS ? "1.0" : "0.5"
                }
                underline={this.props.location.pathname === ACTIVEUSERS}
                pointer={this.props.location.pathname !== ACTIVEUSERS}
                onClick={() => this.props.history.push(`${base}/activeusers`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("active_users")}
              </PageHeading>
            ) : undefined}

            {this.moduleShouldBeShown("insiderLog") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname === INSIDER_LOG_LINKS_NEW
                    ? "1.0"
                    : "0.5"
                }
                underline={
                  this.props.location.pathname === INSIDER_LOG_LINKS_NEW
                }
                pointer={this.props.location.pathname !== INSIDER_LOG_LINKS_NEW}
                onClick={() =>
                  this.props.history.push(`${base}/insidersync`)
                }
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_insider_sync")}
              </PageHeading>
            ) : undefined}
            {this.moduleShouldBeShown("useradmin") ? (
              <PageHeading
                fontSize={20}
                isFocusable
                opacity={
                  this.props.location.pathname.includes(USER_ADMIN)
                    ? "1.0"
                    : "0.5"
                }
                underline={this.props.location.pathname.includes(USER_ADMIN)}
                pointer={!this.props.location.pathname.includes(USER_ADMIN)}
                onClick={() => this.props.history.push(`${base}/useradmin`)}
                margin={[0, 25, 25, 0]}
                color={"#1c315b"}
              >
                {t("system_user_admin_link")}
              </PageHeading>
            ) : undefined}
              {this.moduleShouldBeShown("status") ? (
                  <PageHeading
                      fontSize={20}
                      isFocusable
                      opacity={
                          this.props.location.pathname.includes(STATUS)
                              ? "1.0"
                              : "0.5"
                      }
                      underline={this.props.location.pathname.includes(STATUS)}
                      pointer={!this.props.location.pathname.includes(STATUS)}
                      onClick={() => this.props.history.push(`${base}/status`)}
                      margin={[0, 25, 25, 0]}
                      color={"#1c315b"}
                  >
                      {t("Status")}
                  </PageHeading>
              ) : undefined}
                  <PageHeading
                      fontSize={20}
                      isFocusable
                      opacity={
                          this.props.location.pathname === SETTINGS ? "1.0" : "0.5"
                      }
                      underline={this.props.location.pathname === SETTINGS}
                      pointer={this.props.location.pathname !== SETTINGS}
                      onClick={() => this.props.history.push(`${base}/settings`)}
                      margin={[0, 25, 25, 0]}
                      color={"#1c315b"}
                  >
                      {t("system_settings")}
                  </PageHeading>
          </FlexContainer>
        </FlexContainer>
        <FlexContainer>
          <Route
            exact
            path={`${base}`}
            render={() => {
                return this.moduleShouldBeShown("twoFactorDisabledUsers") ? (
                <UsersTwoFactorDisabledContainer {...this.props} />
              ) : "";
            }}
          />
        </FlexContainer>
        <FlexContainer column>
          <Route
            exact
            path={`${base}/settings`}
            render={() => {
              return  (
                  <SystemSettingsContainer
                      moduleShouldBeShown={(moduleName) => this.moduleShouldBeShown(moduleName)}
                      isSuperUser={this.props.isSuperUser}
                  />
              );
            }}
          />
          <Route
            exact
            path={`${base}/modules`}
            render={() => {
              return this.moduleShouldBeShown("modules") ? (
                <ModuleForm forceUpdate />
              ) : (
                ""
              );
            }}
          />
          <Route
            exact
            path={`${base}/activeusers`}
            render={() => {
              return this.moduleShouldBeShown("activeUsers") ? (
                <LoggedInUsers {...this.props} />
              ) : (
                ""
              );
            }}
          />

          <Route
            exact
            path={`${base}/insidersync`}
            render={() => {
              return this.moduleShouldBeShown("insiderLog") ? (
                <InsiderSyncContainer
                  key="insiderSync"
                  t={t}
                  {...this.props}
                  systemProps={systemPropsObject}
                />
              ) : (
                ""
              );
            }}
          />

          <Route
            path={`${base}/useradmin/:page?`}
            render={() => {
              return this.moduleShouldBeShown("useradmin") ? (
                <AdminContainer forceUpdate base={`${base}/useradmin`} />
              ) : (
                ""
              );
            }}
          />

            <Route
                exact
                path={`${base}/status`}
                render={() => {
                    return this.moduleShouldBeShown("status") ? (
                        <StatusContainer base={`${base}/status`} {...this.props} />
                    ) : "";
                }}
            />
        </FlexContainer>
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { systemAdministratorReducer, insiderSyncDetailsReducer, config, loginUser } = state;

  return {
    systemPropsList: systemAdministratorReducer.systemProps.list,
    systemPropsObject: systemAdministratorReducer.systemProps.object,
    companyList: systemAdministratorReducer.companyList.list,
    config: config.config,
    insiderSyncDetails: insiderSyncDetailsReducer.insiderSyncDetialsList.list,
    isSuperUser: isSuperUser(loginUser.user),
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(SystemAdministratorContainer)
);
