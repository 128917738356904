import { combineReducers } from "redux";
import {
  LoggedInUsers,
  OrganisationList,
  CompanyListActions,
  PublicProps,
  FetchSystemProps,
  UsersWithTwoFactorDisabledList,
} from "./SystemAdministratorActions";

const initalUsersWithTwoFactorDisabledListState = {
  list: [],
  isFetched: false,
};
export const usersTwoFactorDisabledList = (
  state = {
    ...initalUsersWithTwoFactorDisabledListState,
  },
  action
) => {
  switch (action.type) {
    case UsersWithTwoFactorDisabledList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const initalSystemProps = {
  object: {},
  list: [],
  isFetched: false,
};
export const systemProps = (
  state = {
    ...initalSystemProps,
  },
  action
) => {
  switch (action.type) {
    case FetchSystemProps.postSuccess:
      return Object.assign({}, state, {
        object:
          action.data && action.data.data.entity.sysProps
            ? action.data.data.entity.sysProps
            : {},
        list:
          action.data && action.data.data.entity.sysProps
            ? Object.keys(action.data.data.entity.sysProps).map((key) => {
                return {
                  key: key,
                  value: action.data.data.entity.sysProps[key],
                };
              })
            : [],
        isFetched: true,
      });
    case FetchSystemProps.postFail:
    default:
      return state;
  }
};

const initalPublicProps = {
  object: {},
  list: [],
  isFetched: false,
};
export const publicProps = (
  state = {
    ...initalPublicProps,
  },
  action
) => {
  switch (action.type) {
    case PublicProps.postSuccess:
      return Object.assign({}, state, {
        object:
          action.data && action.data.data.entity.publicProps
            ? action.data.data.entity.publicProps
            : {},
        list:
          action.data && action.data.data.entity.publicProps
            ? Object.keys(action.data.data.entity.publicProps).map((key) => {
                return {
                  key: key,
                  value: action.data.data.entity.publicProps[key],
                };
              })
            : [],
        isFetched: true,
      });
    case PublicProps.postFail:
    default:
      return state;
  }
};

const initalLoggedInUsers = {
  object: {},
  isFetched: false,
};
export const loggedInUsers = (
  state = {
    ...initalLoggedInUsers,
  },
  action
) => {
  switch (action.type) {
    case LoggedInUsers.postSuccess:
      return Object.assign({}, state, {
        object: action.data.data.entity || {},
        isFetched: true,
      });
    case LoggedInUsers.postFail:
    default:
      return state;
  }
};

const initalOrgListState = {
  list: [],
  isFetched: false,
};
export const organisationList = (
  state = {
    ...initalOrgListState,
  },
  action
) => {
  switch (action.type) {
    case OrganisationList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const initalCompListState = {
  list: [],
  isFetched: false,
};
export const companyList = (
  state = {
    ...initalCompListState,
  },
  action
) => {
  switch (action.type) {
    case CompanyListActions.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const systemAdministratorReducer = combineReducers({
  usersTwoFactorDisabledList,
  systemProps,
  loggedInUsers,
  organisationList,
  companyList,
  publicProps,
});

export default systemAdministratorReducer;
