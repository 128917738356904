import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Login from "./Login";
import {withTranslation} from "react-i18next";
import {
  LOGIN_CREDENTIALS_INCORRECT_ERROR_CODE,
  loginPhaseOne,
  loginPhaseTwo,
  MISSING_TWO_FACTOR_DATA_ERROR_CODE,
  PASSWORDS_DO_NOT_MATCH_ERROR_CODE
} from "./LoginActions";
import {changePasswordOnLogin, setupLoginData} from "../actions/CommonActions";
import Loader from "../components/Loader";
import {browserIsIE} from "../util/common";
import LoginSSO from "./LoginSSO";

class LogInContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showHelpModal: false,
      username: undefined
    };
  }

  render() {
    const { t } = this.props;

    const onHelp = () => {
      this.setState({ showHelpModal: true });
    };

    const mapLoginError = errorCode => {
      switch (errorCode) {
        case LOGIN_CREDENTIALS_INCORRECT_ERROR_CODE:
          return t("username_password_error");
        case PASSWORDS_DO_NOT_MATCH_ERROR_CODE:
          return t("passwords_do_not_match");
        case MISSING_TWO_FACTOR_DATA_ERROR_CODE:
          return t("missing_two_factor_data");
        case undefined:
          return "";
        default:
          return t("unable_to_login");
      }
    };

    const onClickCreator = () => {
      const onLoginSuccess = data => {
        if (data.entity.changePasswordRequired) {
          return window.location.assign(`/resetPassword/${data.entity.globalSubject}/${data.entity.resetSecret}/${data.entity.language || 'en'}`);
        }

        if (data.entity.additionalSteps === false && !data.entity.changePasswordRequired) {
          if (browserIsIE()) window.location.assign("/");

          return this.props
            .dispatch(setupLoginData(false))
            .then(() => this.props.setTimer());
        }
        return true;
      };

      if (this.props.loginSuccessPhase1) {
        if (this.props.additionalSteps) {
          return inputObj =>
            this.props
              .dispatch(
                loginPhaseTwo({
                  username: this.username,
                  code: inputObj.mfacode
                })
              )
              .then(onLoginSuccess)
              .then(additionalSteps => {
                if (additionalSteps) this.setState({ loading: false });
              })
              .catch(error => {
                console.log(error);
                this.setState({ loading: false });
              });
        }
      } else {
        return inputObj =>
          this.props
            .dispatch(
              loginPhaseOne({
                username: inputObj.username,
                password: inputObj.password
              })
            )
            .then(onLoginSuccess)
            .then(additionalSteps => {
              if (additionalSteps) this.setState({ loading: false });
            })
            .then(() => (this.username = inputObj.username))
            .catch(error => {
              console.log(error.data.statusMessage);
              this.setState({ loading: false });
            });
      }

      if (this.props.changePasswordRequired) {
        return inputObj =>
          this.props.dispatch(
            changePasswordOnLogin({
              password1: inputObj.password1,
              password2: inputObj.password2
            })
          );
      }
    };

    const onLogin = onClickCreator();

    if (this.state.loading || !this.props.ssoEnabledIsFetched)
      return <Loader />;

    if (
      !this.props.ssoEnabled ||
      (window.location.pathname &&
        window.location.pathname.includes("loginPWD"))
    ) {
      return (
        <Login
          key="login"
          t={this.props.t}
          changePasswordRequired={this.props.changePasswordRequired}
          onClickAction={values =>
            this.setState({ loading: true }, () => onLogin(values))
          }
          loginError={mapLoginError(this.props.loginError)}
          additionalSteps={this.props.additionalSteps}
          loginSuccessPhase1={this.props.loginSuccessPhase1}
          showHelpModal={this.state.showHelpModal}
          onHelp={onHelp}
          onCloseHelpModal={() => this.setState({ showHelpModal: false })}
          sessionLogOut={this.props.sessionLogOut}
        />
      );
    } else if (
      this.props.ssoEnabled &&
      window.location.pathname &&
      !window.location.pathname.includes("loginPWD")
    ) {
      return <LoginSSO t={this.props.t} setTimer={this.props.setTimer} />;
    }
    return "";
  }
}

LogInContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  const { loginReducer, passwordChangeReducer, ssoEnabled } = state;

  return {
    loginSuccessPhase1: loginReducer.login.loginSuccessPhase1,
    loginSuccessPhase2: loginReducer.login.loginSuccessPhase2,

    loginError: loginReducer.login.loginError,
    additionalSteps: loginReducer.login.additionalSteps,
    changePasswordRequired: loginReducer.login.changePasswordRequired,
    passwordChangeSuccess: passwordChangeReducer.passwordChangeSuccess,
    ssoEnabledIsFetched: ssoEnabled.isFetched,
    ssoEnabled: ssoEnabled.ssoEnabled
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(LogInContainer)
);
