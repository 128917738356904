import React from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {FlexContainer} from "../components/FlexContainer";
import {ErrorText} from "../components/ErrorText";
import i18n from "../i18n";
import {setLocalLanguage} from "../actions/CommonActions";

class MaintenanceView extends React.Component {

  render() {
    const { t, maintenance } = this.props;

    const changeLanguage = () => {
      const language = i18n.language === "nb" ? "en" : "nb";
      setLocalLanguage(language);
      i18n.changeLanguage(language);
    }

    return (
      <FlexContainer center vAlignCenter margin={[100, 0, 0, 0]}>
          <ErrorText fontSize={"26px"} onClick={changeLanguage} pointer>{t("maintenance_header")}</ErrorText>
          {maintenance.englishText && i18n.language !== 'nb' &&
              <ErrorText fontSize={"20px"} margin={[20,0,0,0]}>
                {maintenance.englishText}
              </ErrorText>
          }
          {maintenance.norwegianText && i18n.language === 'nb' &&
              <ErrorText fontSize={"20px"} margin={[20,0,0,0]}>
                {maintenance.norwegianText}
              </ErrorText>
          }
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    maintenance,
  } = state;

  return {
    maintenance: maintenance
  };
}

export default connect(mapStateToProps)(withTranslation("translations")(MaintenanceView));
