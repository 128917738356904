import {FlexContainer} from "../../components/FlexContainer";
import Label from "../../components/Label";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import TwoFactorContainer from "./TwoFactorContainer";
import React from "react";
import {PageHeading} from "../../components/PageHeading";
import SSOContainer from "./SSOContainer";
import MaintenanceContainer from "./MaintenanceContainer";
import ModuleForm from "./ModuleForm";
import {TWO_FACTOR_PROP_KEY} from "../../actions/CommonActions";
import {
    MAINTENANCE_ENABLED_PROP_KEY,
    MAINTENANCE_ENGLISH_TEXT_PROP_KEY,
    MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY,
    SSO_ENABLED_PROP_KEY
} from "../../actions/CommonActionsNoObsvc";


class SystemSettingsContainer extends React.Component {

    render() {
        const {
            t,
            moduleShouldBeShown,
            twoFactorStatus,
            ssoEnabled,
            maintenanceEnabled,
            maintenanceNorwegianText,
            maintenanceEnglishText
        } = this.props;
        console.log(this.props.isSuperUser)
        return (
            <FlexContainer>
                <FlexContainer row center>
                    {moduleShouldBeShown("twoFactorSettings") && (
                        <FlexContainer marginRight="5">
                            <PageHeading>{t("two_factor_settings")}</PageHeading>
                            <FlexContainer border greyBackground>
                                <FlexContainer center row margin={[16, 0, 12, 16]}>
                                    {t("system_two_factor_status")}
                                    <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                                        {twoFactorStatus === "true"
                                            ? t("system_two_factor_on")
                                            : t("system_two_factor_off")}
                                    </Label>
                                </FlexContainer>
                                <TwoFactorContainer
                                    enableReinitialize={true}
                                    t={t}
                                    isActive={twoFactorStatus}
                                    initialValues={{
                                        twoFactorStatus: twoFactorStatus,
                                    }}
                                    key="twoFactorMenuBody"
                                    isSuperUser={this.props.isSuperUser}
                                />
                            </FlexContainer>
                        </FlexContainer>
                    )}

                    {moduleShouldBeShown("ssoEnabled") && (
                        <FlexContainer marginLeft="5">
                            <PageHeading>{t("system_sso_admin_link")}</PageHeading>
                            <FlexContainer border greyBackground>
                                <FlexContainer center row margin={[16, 0, 12, 16]}>
                                    {t("system_sso_status")}
                                    <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                                        {ssoEnabled === "true"
                                            ? t("system_sso_on")
                                            : t("system_sso_off")}
                                    </Label>
                                </FlexContainer>
                                <SSOContainer
                                    enableReinitialize={true}
                                    t={t}
                                    isActive={ssoEnabled}
                                    initialValues={{
                                        ssoEnabled: ssoEnabled,
                                    }}
                                    key="ssoEnabledContainer"
                                    isSuperUser={this.props.isSuperUser}
                                />
                            </FlexContainer>
                        </FlexContainer>
                    )}
                </FlexContainer>
                <FlexContainer>
                    {moduleShouldBeShown("maintenance") && (
                        <FlexContainer>
                            <PageHeading>{t("system_maintenance_link")}</PageHeading>
                            <FlexContainer border greyBackground>
                                <FlexContainer>
                                    <FlexContainer center row margin={[16, 0, 12, 16]}>
                                        {t("system_maintenance_status")}
                                        <Label margin={[0, 0, 0, 10]} fontSize={16} bold>
                                            {maintenanceEnabled === "true"
                                                ? t("system_maintenance_on")
                                                : t("system_maintenance_off")}
                                        </Label>
                                    </FlexContainer>
                                    <FlexContainer trueCenter margin={[16, 0, 12, 16]}>
                                        {t("system_maintenance_text_norwegian")}
                                        <Label fontSize={16} bold>
                                            {maintenanceNorwegianText}
                                        </Label>
                                    </FlexContainer>
                                    <FlexContainer trueCenter margin={[16, 0, 12, 16]}>
                                        {t("system_maintenance_text_english")}
                                        <Label fontSize={16} bold>
                                            {maintenanceEnglishText}
                                        </Label>
                                    </FlexContainer>
                                </FlexContainer>
                                <MaintenanceContainer
                                    enableReinitialize={true}
                                    t={t}
                                    isActive={maintenanceEnabled}
                                    initialValues={{
                                        maintenanceEnabled: maintenanceEnabled,
                                        norwegianText: maintenanceNorwegianText,
                                        englishText: maintenanceEnglishText,
                                    }}
                                    key="maintenanceContainer"
                                    isSuperUser={this.props.isSuperUser}
                                />
                            </FlexContainer>
                        </FlexContainer>
                    )}
                </FlexContainer>
                <FlexContainer>
                    {moduleShouldBeShown("modules") && (
                        <FlexContainer>
                            <PageHeading>{t("modules_settings")}</PageHeading>
                            <ModuleForm forceUpdate isSuperUser={this.props.isSuperUser}/>
                        </FlexContainer>
                    )}
                </FlexContainer>
            </FlexContainer>
        )
    }
}

function mapStateToProps(state) {
    const {systemAdministratorReducer} = state;

    return {
        twoFactorStatus:
            systemAdministratorReducer.systemProps.object[TWO_FACTOR_PROP_KEY],
        ssoEnabled:
            systemAdministratorReducer.systemProps.object[SSO_ENABLED_PROP_KEY],
        maintenanceEnabled:
            systemAdministratorReducer.systemProps.object[MAINTENANCE_ENABLED_PROP_KEY],
        maintenanceNorwegianText:
            systemAdministratorReducer.systemProps.object[MAINTENANCE_NORWEGIAN_TEXT_PROP_KEY],
        maintenanceEnglishText:
            systemAdministratorReducer.systemProps.object[MAINTENANCE_ENGLISH_TEXT_PROP_KEY],
    };
}

export default connect(mapStateToProps)(
    withTranslation("translations")(SystemSettingsContainer)
);