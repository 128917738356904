import {
  InfoboardActionsGetAllMessages,
  InfoboardActionsGetMessage,
  InfoboardActionsGetMessages,
} from "./InfoboardActions";

const initialInfoboardStatus = {
  messages: [],
  messagesIsFetched: false,
  messagesAll: [],
  messagesAllIsFetched: false,
  message: "",
  messageIsFetched: false,
};

const infoboardReducer = (
  state = {
    ...initialInfoboardStatus,
  },
  action
) => {
  switch (action.type) {
    case InfoboardActionsGetMessages.receive:
      return Object.assign({}, state, {
        messages: action.data.entity || [],
        messagesIsFetched: true,
      });

    case InfoboardActionsGetAllMessages.receive:
      return Object.assign({}, state, {
        messagesAll: action.data.entity || [],
        messagesAllIsFetched: true,
      });

    case InfoboardActionsGetMessage.receive:
      return Object.assign({}, state, {
        message: action.data.entity || [],
        messageIsFetched: true,
      });

    default:
      return state;
  }
};

export default infoboardReducer;
