import {
  postData,
  fetchData,
  putData,
  deleteData,
} from "../actions/CommonActionsNoObsvc";

const prefix = "v1/infoboard/messages";

export const InfoboardActionsGetMessages = {
  request: "GET_INFOBOARD_MESSAGES",
  receive: "GET_INFOBOARD_MESSAGES_RECEIVE",
  receiveFail: "GET_INFOBOARD_MESSAGES_FAIL",
};

export const fetchInfoboardMessages = () =>
  fetchData(InfoboardActionsGetMessages, undefined, prefix);

export const InfoboardActionsGetAllMessages = {
  request: "GET_INFOBOARD_ALLMESSAGES",
  receive: "GET_INFOBOARD_ALLMESSAGES_RECEIVE",
  receiveFail: "GET_INFOBOARD_ALLMESSAGES_FAIL",
};

export const fetchAllInfoboardMessages = () =>
    fetchData(InfoboardActionsGetAllMessages, undefined, prefix + "/all");

export const InfoboardActionsGetMessage = {
  request: "GET_INFOBOARD_MESSAGE",
  receive: "GET_INFOBOARD_MESSAGE_RECEIVE",
  receiveFail: "GET_INFOBOARD_MESSAGE_FAIL",
};

export const fetchInfoboardMessageById = (messageId) =>
  fetchData(InfoboardActionsGetMessage, undefined, `${prefix}/${messageId}`);

export const InfoboardActionsUpdateMessage = {
  request: "UPDATE_INFOBOARD_MESSAGE",
  putSuccess: "UPDATE_INFOBOARD_MESSAGE_SUCCESS",
  putFail: "UPDATE_INFOBOARD_MESSAGE_FAIL",
};

export const updateInfoboardMessageById = (messageData) =>
  putData(InfoboardActionsUpdateMessage, undefined, { ...messageData }, prefix);

export const InfoboardActionsCreateMessage = {
  request: "CREATE_INFOBOARD_MESSAGE",
  postFail: "CREATE_INFOBOARD_MESSAGE_FAIL",
  postSuccess: "CREATE_INFOBOARD_MESSAGE_SUCCESS",
};

export const createInfoboardMessage = (messageData) =>
  postData(
    InfoboardActionsCreateMessage,
    undefined,
    { ...messageData },
    prefix
  );

export const InfoboardActionsDeleteMessage = {
  request: "DELETE_INFOBOARD_MESSAGE",
  deleteSuccess: "DELETE_INFOBOARD_MESSAGE_SUCCESS",
  deleteFail: "DELETE_INFOBOARD_MESSAGE_FAIL",
};

export const deleteInfoboardMessageById = (messageId) =>
  deleteData(InfoboardActionsDeleteMessage, `${prefix}/${messageId}`);
