import React from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Redirect} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import ResetPasswordForm from "./ResetPasswordForm";
import {updateCredentials} from "../actions/MFAActions";
import {SubmissionError} from "redux-form";
import {validatePassword} from "./Validators";
import i18n from "../i18n";

class ResetPasswordFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.globalSubject = props.match ? props.match.params.globalSubject : undefined;
    this.resetSecret = props.match ? props.match.params.resetSecret : undefined;
    this.lang = props.match ? props.match.params.lang : undefined;
    this.state = {
      twoFactorCodeRequested: false,
      redirect: false
    };
  }

  componentWillMount() {
    if (this.lang !== null) {
      if (this.lang === "en") {
        i18n.changeLanguage("en")
      } else {
        i18n.changeLanguage("nb")
      }
    }
    else i18n.changeLanguage("nb");
  }

  render() {
    if (this.state.redirect) {
      NotificationManager.info(this.props.t("new_password_information"));
      return <Redirect to="/login" />;
    }

    return (
      <ResetPasswordForm
        t={this.props.t}
        onSave={(value, dispatch, props) => {
          const sendPasswordValues = () => {
            return this.props.dispatch(
                updateCredentials(
                    this.globalSubject,
                    value.receivedCode,
                    null,
                    null,
                    null,
                    this.resetSecret,
                    3,
                    null,
                    value.repeat_password
                )
            );
          };

          const handleServerErrors = (error, reject) => {
            if (error.data.details != null) {
              if (error.data.details.some(e => e.code === 1222)) {
                return reject(
                  new SubmissionError({
                    password: this.props.t("min_signs")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1223)) {
                return reject(
                  new SubmissionError({
                    password: this.props.t("password_reused")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1224)) {
                return reject(
                  new SubmissionError({
                    password: this.props.t("password_username")
                  })
                );
              }
              if (error.data.details.some(e => e.code === 1235)) {
                return reject(
                  new SubmissionError({
                    password: this.props.t("link_expired")
                  })
                );
              } else {
                error.data.details.forEach(e =>
                  NotificationManager.error(e.message, "", 0)
                );
              }
            } else
              NotificationManager.error(
                this.props.t("an_error_occured"),
                "",
                0
              );
          };

          const errors = validatePassword(value, props);

          return new Promise((resolve, reject) => {
            if (errors) {
              return reject(new SubmissionError(errors));
            }
            sendPasswordValues()
              .then(() =>
                NotificationManager.success(this.props.t("password_updated"))
              )
              .then(resolve)
              .then(() => this.setState({ redirect: true }))
              .catch(error => handleServerErrors(error, reject));
          });
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const { form } = state;

  return {
    passwordForm:
      form.resetPassword != null ? form.resetPassword.values : undefined
  };
}

ResetPasswordFormContainer.propTypes = {
  t: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect(mapStateToProps)(
  withTranslation("translations")(ResetPasswordFormContainer)
);
