import React from "react";
import { FlexContainer } from "../../components/FlexContainer";
import { GreyDropDown } from "../../components/GreyDropDown";
import { getLanguage } from "../../util/common";

function readTextFile(file) {
  return new Promise(resolve => {
    const rawFile = new XMLHttpRequest();
    rawFile.open("GET", file, false);

    rawFile.onreadystatechange = function() {
      if (rawFile.readyState === 4) {
        if (rawFile.status === 200 || rawFile.status === 0) {
          resolve(rawFile.responseText);
        }
      }
    };
    rawFile.send(null);
  });
}

class InsiderDocumentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      documentationContentEn: undefined,
      documentationContentNo: undefined
    };
  }

  createDocumentationHierarchy = filePath => {
    return readTextFile(filePath)
      .then(str => {
        var parser = new DOMParser();
        const htmlDocument = parser.parseFromString(str, "text/html");

        const insiderDocumentation = (
          <GreyDropDown
            header={htmlDocument.querySelector("h1").innerText}
            boldHeader
            greyContainer
            noBodyPadding
            withLeftPadding
            parentPadding
            name="documentationHeader"
          >
            {Array.from(htmlDocument.querySelectorAll(".sect1")).map(
              (section, index) => (
                <GreyDropDown
                  key={`insiderDoc${index}`}
                  header={section.querySelector("h2").innerText}
                  boldHeader
                  name="documentationSection"
                  greyContainer
                  noBodyPadding
                  containerNoMarginTop
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: section.querySelector(".sectionbody").innerHTML
                    }}
                  />
                </GreyDropDown>
              )
            )}
          </GreyDropDown>
        );

        return insiderDocumentation;
      })
      .catch(e => console.log(e));
  };

  componentDidMount() {
    this.createDocumentationHierarchy(`/help/it_frontpage_en.html`).then(doc =>
      this.setState({ documentationContentEn: doc })
    );
    this.createDocumentationHierarchy(`/help/it_frontpage_no.html`).then(doc =>
      this.setState({ documentationContentNo: doc })
    );
  }

  render() {
    return (
      <FlexContainer>
        {getLanguage() === "No"
          ? this.state.documentationContentNo
          : this.state.documentationContentEn}
      </FlexContainer>
    );
  }
}

export default InsiderDocumentation;
