import { combineReducers } from "redux";
import {
  IssuerList,
  Notification,
  NotificationList,
  FetchOrganisationList,
} from "./NotificationActions";

const initalNotificationState = {
  data: {},
  isFetched: false,
};
export const notification = (
  state = {
    ...initalNotificationState,
  },
  action
) => {
  switch (action.type) {
    case Notification.postSuccess:
      return Object.assign({}, state, {
        data: action.data.data.entity || {},
        isFetched: true,
      });
    default:
      return state;
  }
};

const initalOrgListState = {
  list: [],
  isFetched: false,
};
export const organisationList = (
  state = {
    ...initalOrgListState,
  },
  action
) => {
  switch (action.type) {
    case FetchOrganisationList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const initalNotificationListState = {
  list: [],
  isFetched: false,
};
export const notificationList = (
  state = {
    ...initalNotificationListState,
  },
  action
) => {
  switch (action.type) {
    case NotificationList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const initalIssuerListState = {
  list: [],
  isFetched: false,
};
export const issuerList = (
  state = {
    ...initalIssuerListState,
  },
  action
) => {
  switch (action.type) {
    case IssuerList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    case IssuerList.postFail:
      return Object.assign({}, state, {
        list: [],
        isFetched: false,
      });
    default:
      return state;
  }
};

const notificationReducer = combineReducers({
  organisationList,
  issuerList,
  notificationList,
  notification,
});

export default notificationReducer;
