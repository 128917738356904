import React from "react";
import { NotificationManager } from "react-notifications";
import {
  uploadExcelAttachment,
  createMultipleInsiders,
  fetchInsiderList,
  InsiderType
} from "../../../InsiderToolActions";
import { validateInsiderFromExcel } from "../Validator";
import { SubmissionError, reset } from "redux-form";
import { Modal } from "../../../../components/Modal";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ImportExcel } from "../../../modals/insiderList/ImportExcel";
import { ImportExcelInfo } from "../../../modals/insiderList/ImportExcelInfo";
import Button from "../../../../components/Button";
import { OverlayLoader } from "../../../../components/Loader";
import {
  getISOTimeFromDateAndTime,
  getISOTimeFromDateTimeAndFormat
} from "../../../UTCUtils";
import { EXCEL_INPUT_FORMAT } from "../../../InsiderToolReducer";

const helperFunctions = {
  toRowIndex: key => key.match(/([0-9]+)-(.*)/i)[1],
  populateMapByRowIndex: (rowIndex, keys, values) => {
    const rowIndexKeys = keys.filter(val => val.startsWith(rowIndex));
    return rowIndexKeys.reduce(
      (acc, val) => ({
        ...acc,
        [val.match(/[0-9]+-(.*)/i)[1]]:
          val.includes("twoFactorCountryCode") &&
          values[val] &&
          values[val].value
            ? values[val].value
            : val.includes("obtained") && values[val]
              ? getISOTimeFromDateTimeAndFormat(values[val], EXCEL_INPUT_FORMAT)
              : values[val]
      }),
      {}
    );
  }
};

class ImportFromExcel extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImportExcel: false,
      showImportExcelInfo: false,
      loading: false
    };
  }

  uploadExcelFile = file => {
    const { t, dispatch } = this.props;

    let formData = new FormData();

    formData.append("attachment", file);
    formData.append("listId", this.props.listId);

    Promise.resolve(this.setState({ loading: true }))
      .then(() => dispatch(uploadExcelAttachment(formData)))
      .then(() =>
        NotificationManager.success(t("it_attachment_uploaded_success"))
      )
      .then(() => this.setState({ showImportExcel: true }))
      .then(() => this.setState({ showImportExcelInfo: false }))
      .then(() => this.setState({ loading: false }));
  };

  addInsiderFromExcel = values => {
    const { dispatch } = this.props;
    const keys = Object.keys(values);

    return new Promise((resolve, reject) => {
      const errors = validateInsiderFromExcel(values, this.props);
      if (errors) {
        reject(new SubmissionError(errors));
      } else {
        Promise.resolve(this.setState({ loading: true }))
          .then(() =>
            keys
              .map(helperFunctions.toRowIndex)
              .distinctValues()
              .map(v => helperFunctions.populateMapByRowIndex(v, keys, values))
          )
          .then(excelList =>
            this.props.dispatch(
              createMultipleInsiders(this.props.listId, excelList)
            )
          )
          .then(() => dispatch(fetchInsiderList(this.props.listId)))
          .then(() => dispatch(reset("insiderTableForm")))
          .then(() => this.setState({ loading: false, showImportExcel: false }))
          .then(resolve)

          .catch(_ => this.setState({ loading: false }));
      }
    });
  };

  createInitialValues = importList => {
    const initImport = {};
    if (importList != null) {
      importList.map((il, index) => {
        initImport[index + "-" + "first_name"] = il.firstName;
        initImport[index + "-" + "surname"] = il.lastName;
        initImport[index + "-twoFactorCountryCode"] = il.twoFactorCountryCode;
        initImport[index + "-twoFactorPhone"] = il.twoFactorPhone;
        initImport[index + "-" + "email"] = il.email;
        initImport[index + "-obtained"] = il.obtained;
      });
      return initImport;
    }
  };

  render() {
    const { t, dispatch, listType } = this.props;

    const initImport = this.createInitialValues(this.props.importList);

    return (
      <React.Fragment>
        {this.state.loading ? <OverlayLoader /> : ""}
        <Button.Standard
          margin={[0, 0, 0, 5]}
          onClick={() =>
            this.props.hasEditAccess
              ? this.setState({ showImportExcelInfo: true })
              : undefined
          }
          inactive={!this.props.hasEditAccess}
        >
          {this.props.listType === InsiderType.LIST
            ? t("it_import_insiders_excel_list")
            : t("it_import_insiders_excel_conf_list")}
        </Button.Standard>

        <Modal
          width={"70%"}
          key={"showImportExcelModal"}
          xScroll
          header={t(
            listType === InsiderType.LIST
              ? "it_import_insiders_excel_list"
              : "it_import_insiders_excel_conf_list"
          )}
          isOpen={this.state.showImportExcel}
          onClose={() =>
            this.setState({
              showImportExcel: false
            })
          }
        >
          <ImportExcel
            enableReinitialize={true}
            onSubmit={data => this.addInsiderFromExcel(data)}
            t={t}
            importList={this.props.importList}
            initialValues={{
              ...initImport
            }}
            onCancel={() => {
              dispatch(reset("closeAndNotifyListForm"));
              this.setState({
                showImportExcel: false
              });
            }}
            countryOptions={this.props.countryOptions}
          />
        </Modal>

        <Modal
          width={"61%"}
          key={"showImportExcelInfoModal"}
          xScroll
          header={t(
            listType === InsiderType.LIST
              ? "it_import_insiders_excel_list"
              : "it_import_insiders_excel_conf_list"
          )}
          isOpen={this.state.showImportExcelInfo}
          onClose={() =>
            this.setState({
              showImportExcelInfo: false
            })
          }
        >
          <ImportExcelInfo
            insiderType={InsiderType}
            listType={listType}
            uploadExcelFile={this.uploadExcelFile}
            enableReinitialize={true}
            onSubmit={data => this.uploadExcelFile(data)}
            t={t}
            importList={this.props.importList}
            initialValues={{
              ...initImport
            }}
            onCancel={() => {
              dispatch(reset("closeAndNotifyListForm"));
              this.setState({
                showImportExcelInfo: false
              });
            }}
          />
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;

  return {
    importList: insiderToolReducer.importList.list,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(ImportFromExcel)
);
