import React from "react";
import { validateInsiderPerson } from "../Validator";
import { reset, SubmissionError } from "redux-form";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import {
  fetchInsiderList,
  fetchTemplate,
  InsiderType,
  updateInsider,
  inactivatePerson,
  deleteInsider
} from "../../../InsiderToolActions";
import InactivateModal from "../../../modals/insiderList/InactivateModal";
import InsiderPerson from "../../../modals/insiderList/InsiderPerson";
import { withTranslation } from "react-i18next";
import { OverlayLoader } from "../../../../components/Loader";
import { ConfirmModal } from "../../../../components/ConfirmModal";
import { FlexContainer } from "../../../../components/FlexContainer";

class UpdateInsider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showInactivateModal: false,
      showDeleteModal: false
    };
  }

  getTemplateName = () => {
    return this.props.listType === InsiderType.LIST
      ? "inactivateInsider"
      : "inactivatePerson";
  };

  isApproved = _ => {
    if (this.props.listType === InsiderType.CONFIDENTIALITY_LIST) {
      return false;
    }

    return this.props.getState(this.props.selectedInsider.id) === "confirmed";
  };

  hasMissingValues = values => {
    return !(
      values.address &&
      values.address.length > 0 &&
      values.company_name &&
      values.company_name.length > 0 &&
      values.company_address &&
      values.company_address.length > 0 &&
      values.company_country &&
      values.company_country.length > 0 &&
      values.country &&
      values.country.length > 0 &&
      values.date_of_birth &&
      values.date_of_birth.length > 0 &&
      values.email &&
      values.email.length > 0 &&
      values.idnumber &&
      values.idnumber.length > 0 &&
      values.insider_function_and_reason &&
      values.insider_function_and_reason.length > 0 &&
      values.first_name &&
      values.first_name.length > 0 &&
      values.obtained &&
      values.obtained.length > 0 &&
      values.phone_private &&
      values.phone_private.length > 0 &&
      values.phone_work &&
      values.phone_work.length > 0 &&
      values.surname &&
      values.surname.length > 0 &&
      values.birth_surname &&
      values.birth_surname.length > 0
    );
  };

  isInsiderCeased = values => {
    if (
      values.ceased_date &&
      values.ceased_time &&
      this.props.getState(this.props.selectedInsider.id) !== "inactive"
    ) {
      return true;
    }
    return false;
  };

  onSubmitInsider = (values, listId) => {
    return new Promise((resolve, reject) => {
      const errors = validateInsiderPerson(values, this.props);

      if (errors) {
        reject(new SubmissionError(errors));
      } else if (this.hasMissingValues(values) && this.isApproved()) {
        NotificationManager.error(
          this.props.t(
            "it_cant_save_an_approved_insider_without_all_values_filled_in"
          ),
          "",
          0
        );
        reject();
      } else {
        if (this.isInsiderCeased(values)) {
          Promise.resolve(this.setState({ loading: true }))
            .then(() =>
              this.props.dispatch(fetchTemplate("", this.getTemplateName()))
            )
            .then(response => {
              this.setState({
                emailTemplate: response,
                showInactivateModal: true,
                inactivatePersonId: this.props.selectedInsider.id
              });
            })
            .then(resolve)
            .catch(e => console.log(e))
            .then(() => this.setState({ loading: false }));
        } else {
          if (!values.dontUpdateTimeOnSave) {
            this.props.updateUpdateTime();
          }
          delete values.dontUpdateTimeOnSave;
          const data = this.props.getUpdatedDatesForInsider(values);
          this.props.updateCountriesForPerson(data);
          return Promise.resolve(this.setState({ loading: true }))
            .then(() =>
              this.props.dispatch(
                updateInsider(
                  this.props.selectedInsider.id,
                  listId,
                  data,
                  InsiderType.PERSON
                )
              )
            )
            .then(() => this.props.dispatch(reset("insiderPerson")))
            .then(() => {
              this.props.hideEditInsiderForm();
            })
            .then(() => this.props.dispatch(fetchInsiderList(listId)))
            .catch(e => console.log(e))
            .then(() => Promise.resolve(this.setState({ loading: false })));
        }
      }
    });
  };

  notifyInactivePerson = values => {
    const { selectedInsider, listId, templateName, onClose } = this.props;

    let bodyObject = {
      body: [values.body_0, values.body_1, values.body_2, values.body_3]
    };
    Promise.resolve(this.setState({ showLoader: true }))
      .then(() =>
        this.props.dispatch(
          inactivatePerson(
            selectedInsider.id,
            listId,
            bodyObject,
            "",
            this.getTemplateName(),
            this.state.sendMailSelected
          )
        )
      )
      .then(() => this.props.dispatch(fetchInsiderList(listId)))
      .then(() => {
        this.props.hideEditInsiderForm();
        this.setState({ showLoader: false, showInactivateModal: false });
      })
      .catch(e => {
        console.log(e);
        this.setState({ showLoader: false });
      });
  };

  getInsiderDeletionText = selected => {
    const insider = {
      insider: selected.data.first_name + " " + selected.data.surname
    };
    return this.props.t(
      this.props.listType === InsiderType.LIST
        ? "it_delete_insider_text"
        : "it_delete_text",
      insider
    );
  };

  render() {
    const { listId, listType, t } = this.props;
    return (
      <React.Fragment>
        {this.state.showLoader ? <OverlayLoader /> : ""}
        {this.props.isEditInsiderFormVisible ? (
          <InsiderPerson
            key={"editInsiderModal"}
            isOpen={this.props.isEditInsiderFormVisible}
            onClose={() => this.props.hideEditInsiderForm()}
            onSubmit={data => this.onSubmitInsider(data, listId)}
            initialValues={
              this.props.selectedInsider
                ? {
                    ...this.props.selectedInsider.data
                  }
                : undefined
            }
            enableReinitialize={true}
            forceUpdate={true}
            onSubmitText={
              this.props.listType === InsiderType.LIST
                ? "it_update_insider"
                : "it_update_person"
            }
            onDeleteText={
              this.props.listType === InsiderType.LIST
                ? "it_delete_insider"
                : "it_delete_person"
            }
            onDelete={() => this.setState({ showDeleteModal: true })}
            header={
              this.props.listType === InsiderType.LIST
                ? "it_update_insider"
                : "it_update_person"
            }
            listType={listType}
            countryOptions={this.props.countryOptions}
          />
        ) : (
          undefined
        )}

        {this.state.showInactivateModal ? (
          <InactivateModal
            key={"inactivateModal"}
            t={t}
            onClose={() => this.setState({ showInactivateModal: false })}
            initialValues={{
              subject: this.state.emailTemplate.subject,
              body_0: this.props.replaceKeysInString(
                this.state.emailTemplate.body[0],
                "en"
              ),
              body_1: this.props.replaceKeysInString(
                this.state.emailTemplate.body[1],
                "en"
              ),
              body_2: this.props.replaceKeysInString(
                this.state.emailTemplate.body[2],
                "nb"
              ),
              body_3: this.props.replaceKeysInString(
                this.state.emailTemplate.body[3],
                "nb"
              )
            }}
            onConfirm={values => this.onSubmitInsider(values, listId)}
            notifyInactivePerson={this.notifyInactivePerson}
          />
        ) : (
          undefined
        )}

        <ConfirmModal
          key={"deleteModal"}
          isOpen={this.state.showDeleteModal}
          onClose={() => this.setState({ showDeleteModal: false })}
          width={"25%"}
          header={t("delete")}
          confirmText={t("delete")}
          cancelText={t("cancel")}
          onConfirm={() => {
            this.props
              .dispatch(
                deleteInsider(this.props.selectedInsider.id, this.props.listId)
              )
              .then(() => {
                this.props.hideEditInsiderForm();
                this.setState({
                  showDeleteModal: false
                });
              })
              .then(() =>
                this.props.dispatch(fetchInsiderList(this.props.listId))
              );
          }}
        >
          <FlexContainer row vAlignCenter margin={[16, 0, 16, 0]}>
            {this.props.selectedInsider
              ? this.getInsiderDeletionText(this.props.selectedInsider)
              : undefined}
          </FlexContainer>
        </ConfirmModal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;
  return {
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(UpdateInsider)
);
