import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import styled from "styled-components";

import { ContentHeading, InputBox, InputHeading, InputRow } from "./Edit";
import SearchableSelect from "../components/SearchableSelect";

import Button from "../components/Button";
import { FlexContainer } from "../components/FlexContainer";
import DayPicker from "../components/DayPicker";
import i18n from "../i18n";
import Input from "../components/Input";
import moment from "moment/moment";
import { OBNTDateFormat } from "../util/common";
import { isSameOrBefore } from "./FinanskalenderContainer";

const Icon = styled.img`
  cursor: pointer;
  padding: 0 5px 0 5px;
  margin-left: 5px;
  box-sizing: border-box;
`;

function OtherEventBlock(props) {
  const { t } = props;

  const setDate = (index, period, date) => {
    props.updateDateForOptionalEvent(index, period, date);
  };

  const updateEvent = (eventId, period, index) => {
    props.updateEvent(eventId, period, index);
  };

  const inputRow = (event, index, period) => {
    const date = event.eventDate;
    const formattedDate = moment(date, "YYYY-MM-DD", true).isValid()
      ? moment(date, "YYYY-MM-DD").format(OBNTDateFormat)
      : date;
    return (
      <InputRow key={index}>
        <InputBox left>
          <InputHeading>{t("event")}</InputHeading>
          {event.eventType && event.eventType.eventType === 2 ? (
            <Field
              name={event.eventType.name_NO + "_text"}
              component={Input}
              readOnly
              props={{
                value:
                  i18n.language === "nb"
                    ? event.eventType.name_NO
                    : event.eventType.name_EN
              }}
            />
          ) : (
            <Field
              name={"event"}
              component={SearchableSelect}
              noBorder={true}
              clearable={false}
              placeholder={props.t("select")}
              searchable={false}
              options={props.optionalEvents}
              t={props.t}
              input={{
                value: {
                  label: event.eventType
                    ? i18n.language === "nb"
                      ? event.eventType.name_NO
                      : event.eventType.name_EN
                    : undefined,
                  value: event.eventType ? event.eventType.id : undefined
                },
                onChange: event => {
                  updateEvent(event.value, period, index);
                }
              }}
            />
          )}
        </InputBox>
        <InputBox
          right
          test={event.eventType && event.eventType.eventType !== 2}
        >
          <InputHeading>{t("event_publication")}</InputHeading>
          {!date ||
          (!moment(date, "YYYY-MM-DD", true).isValid() ||
            isSameOrBefore(date)) ||
          props.isSuperUser ? (
            <FlexContainer row>
              <Field
                name={"date"}
                placeholder={""}
                component={DayPicker}
                dateFormat={OBNTDateFormat}
                input={{
                  value: formattedDate ? formattedDate : undefined,
                  onChange: date => {
                    if (formattedDate || date) {
                      setDate(index, period, date);
                    }
                  }
                }}
                disableDaysBefore={new Date()}
                numFutureYears={2}
                numPreviousYears={0}
              />
              {!event.eventType || event.eventType.eventType !== 2 ? (
                <InputBox maxWidth={24}>
                  <Icon
                    onClick={() => props.onRemoveUserRow(period, index)}
                    src="/icons/red-cross-24.svg"
                    width="24px"
                  />
                </InputBox>
              ) : undefined}
            </FlexContainer>
          ) : (
            <Field
              name={"date"}
              component={Input}
              readOnly
              props={{ value: formattedDate }}
              background={"#F2F2F2"}
            />
          )}
        </InputBox>
      </InputRow>
    );
  };

  let rows = [];
  let index = 0;

  if (props.existingEvents) {
    props.existingEvents.forEach(event => {
      rows.push(inputRow(event, index++, props.period));
    });
  }

  const hasEmptyOptionalEvent = () => {
    let hasEmptyEvent = false;
    if (props.existingEvents) {
      props.existingEvents.forEach(event => {
        if (!event.hasOwnProperty("eventType")) {
          hasEmptyEvent = true;
        }
      });
    }
    return hasEmptyEvent;
  };

  return (
    <FlexContainer height="100%" greyBackground padding={[8, 8, 8, 8]}>
      <ContentHeading>{t("other_events")}</ContentHeading>
      {rows}
      <FlexContainer width={"25%"}>
        <Button.Standard
          onClick={() => {
            if (!hasEmptyOptionalEvent()) {
              props.onAddUserRow(props.period);
            }
          }}
          margin={[16, 0, 0, 0]}
          inactive={hasEmptyOptionalEvent()}
        >
          {t("add_new_event")}
        </Button.Standard>
      </FlexContainer>
    </FlexContainer>
  );
}

OtherEventBlock.propTypes = {
  t: PropTypes.func.isRequired,
  onRemoveUserRow: PropTypes.func.isRequired,
  onAddUserRow: PropTypes.func.isRequired
};

export default OtherEventBlock;
