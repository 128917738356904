import styled from "styled-components";

const Label = styled.label`
  font-size: ${props => (props.fontSize ? props.fontSize + "px;" : "14px;")};

  ${props =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
  ${props =>
    props.maxWidth ? "max-width: " + props.maxWidth + "px; width: 100%;" : ""};
  ${props => (props.bold ? "font-weight: 600;" : "")};
  ${props =>
    props.fontWeight ? "font-weight: " + props.fontWeight + ";" : ""};
  ${props => (props.italic ? "font-style: italic;" : "")};
  ${props => (props.fullWidth ? "width: 100%;" : "")};
  ${props => (props.color ? "color: " + props.color : "")};
  ${props => (props.noWrap ? "white-space: nowrap" : "")};
  ${props => (props.grey ? "opacity: 0.5; " : "")};
  ${props => (props.tooltip ? "cursor: help;" : "")};
  ${props => (props.underline ? "text-decoration: underline;" : "")};
  ${(props) => props.marginTop ? "margin-top: " + props.marginTop + "px;" : ""}
`;

export default Label;
