import fetch from "cross-fetch";
import React from "react";
import styled from "styled-components";
import {NotificationManager} from "react-notifications";
import moment from "moment";
import {hasValue, OBNTDateTimeFormat} from "./util/common";
import i18next from "i18next";
import {getToken, requestLogger} from "./obsvcClient";

export const TOKEN_KEY = "ob-jwt-token";

const P = styled.p`
  white-space: pre;
`;
const LogEntry = (props) => <P>{props.children}</P>;
let logEntry = "";


const API_URL = () => {
  return fetch("/urls.json", {
    headers : {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then(res => {
    return res.json().then(data => ({
      data: data
    }))
  }).then((res) => {
    sessionStorage.setItem('api', res.data.api_url)
    sessionStorage.setItem('api_large', res.data.api_large)
    return res.data.api_url;
  })
}

export const getMaintenance = () => {
  return fetch("/maintenance.json", {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then((res) => {
    if (res.status !== 404) {
      return res.json().then(content => {
        return content;
      });
    }
  }).catch((err) => console.log(err));
}

const getRequestUrl = (endPoint, params) => {
  let url = "/" + endPoint;
  if (params) {
    url += "?";
    const queryParams = Object.keys(params)
      .filter((key) => params[key] !== undefined)
      .map((key) => {
        return key + "=" + params[key];
      })
      .join("&");
    url += queryParams;
  }
  return url;
};

const doRequest = (url, method, headers, body, showServerError, returnFullResponse, timeoutInSeconds) => {
  return fetch(url, {
    method: method,
    headers: headers,
    body: body,
    signal: timeoutInSeconds ? AbortSignal.timeout(timeoutInSeconds * 1000) : undefined
  }).then((response) => {
        return onResponseResolved(response, showServerError, returnFullResponse)
      }
  )
};

export const doGetRequest = (
  endPoint,
  params,
  showServerError = true,
  returnFullResponse = true,
  timeoutInSeconds
) => {
  const headers = new fetch.Headers();

  headers.append("Content-Type", "application/json");

  const token = getToken();
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }
  logEntry = "/service/" + endPoint + "\n";

  if (endPoint === "v1/sysadmin/env" || !hasValue(sessionStorage.getItem("api"))) {
    return API_URL().then((api) => {
      const url = api + getRequestUrl(endPoint, params);
      return doRequest(url, "GET", headers, null, showServerError, returnFullResponse, timeoutInSeconds)
    })
  } else {
    const url = (endPoint.startsWith("v1/news/") ? sessionStorage.getItem("api_large") : sessionStorage.getItem("api")) + getRequestUrl(endPoint, params);
    return doRequest(url, "GET", headers, null, showServerError, returnFullResponse)
  }
};

export const doPostRequest = (
  endPoint,
  params,
  body = {},
  showServerError,
  returnFullResponse = true,
  convertToJSON = true
) => {
  const headers = new fetch.Headers();

  if (convertToJSON) {
    headers.append("Content-Type", "application/json");
  }

  const token = getToken();
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  logEntry = "/service/" + endPoint + "\n";

  if (!hasValue(sessionStorage.getItem("api"))) {
    return API_URL().then((api) => {
      const url = api + getRequestUrl(endPoint, params);
      return doRequest(url, "POST", headers, convertToJSON ? JSON.stringify(body) : body, showServerError, returnFullResponse)
    })
  } else {
    const url = (endPoint.startsWith("v1/news/") ? sessionStorage.getItem("api_large") : sessionStorage.getItem("api")) + getRequestUrl(endPoint, params);
    return doRequest(url, "POST", headers, convertToJSON ? JSON.stringify(body) : body, showServerError, returnFullResponse)
  }
};

export const doDeleteRequest = (
    endPoint,
    params,
    showServerError = true,
    returnFullResponse = true
) => {
  const headers = new fetch.Headers();

  headers.append("Content-Type", "application/json");

  const token = getToken();
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  logEntry = "/service/" + endPoint + "\n";

  if (!hasValue(sessionStorage.getItem("api"))) {
    return API_URL().then((api) => {
      const url = api + getRequestUrl(endPoint, params);
      return doRequest(url, "DELETE", headers, null, showServerError, returnFullResponse)
    })
  } else {
    const url = (endPoint.startsWith("v1/news/") ? sessionStorage.getItem("api_large") : sessionStorage.getItem("api")) + getRequestUrl(endPoint, params);
    return doRequest(url, "DELETE", headers, null, showServerError, returnFullResponse)
  }
};

export const doPutRequest = (
  endPoint,
  params,
  body = {},
  showServerError = true,
  returnFullResponse = true
) => {
  const headers = new fetch.Headers();

  headers.append("Content-Type", "application/json");

  const token = getToken();
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  logEntry = "/service/" + endPoint + "\n";

  if (!hasValue(sessionStorage.getItem("api"))) {
    return API_URL().then((api) => {
      const url = api + getRequestUrl(endPoint, params);
      return doRequest(url, "PUT", headers, JSON.stringify(body), showServerError, returnFullResponse)
    })
  } else {
    const url = (endPoint.startsWith("v1/news/") ? sessionStorage.getItem("api_large") : sessionStorage.getItem("api")) + getRequestUrl(endPoint, params);
    return doRequest(url, "PUT", headers, JSON.stringify(body), showServerError, returnFullResponse)
  }
};

var serviceCounter = 0;
const logResponse = (response, responsJSON) => {
  const newEntry = (
    <LogEntry key={"sc_" + serviceCounter++}>
      {moment().format(OBNTDateTimeFormat + ":ss") +
        "\n" +
        logEntry +
        "response status: " +
        response.status +
        "\n" +
        "RequestID: " +
        responsJSON?.header?.OB_REQUEST_ID}
    </LogEntry>
  );

  if (requestLogger.length >= 25) {
    requestLogger.slice(1);
  }
  requestLogger.push(newEntry);
};
const logResponse500 = (response, error) => {
  const newEntry = (
    <LogEntry key={"sc_" + serviceCounter++}>
      {moment().format(OBNTDateTimeFormat + ":ss") +
        "\n" +
        logEntry +
        "response status: " +
        response.status +
        "\n" +
        "ERROR: " +
        error}
    </LogEntry>
  );

  if (requestLogger.length >= 25) {
    requestLogger.slice(1);
  }
  requestLogger.push(newEntry);
};

const ErrorText = styled.span`
  white-space: ${(props) => (props.err500 ? "normal;" : "pre-wrap;")};
  word-wrap: break-word;
`;
const onResponseResolved = (response, showServerError, returnFullResponse) => {
  if (response.status === 500) {
      if (showServerError)
        NotificationManager.error(
          <ErrorText err500/>,
            i18next.t("server_error"),
          0
        );
    throw response.status;
  } else if (
    response.status !== 200 &&
    response.status !== 202 &&
    response.status !== 303
  ) {
    return response.json().then((rJson) => {
      logResponse(response, rJson);

      if (showServerError) {
        let errorString;
        if (rJson.reason) {
          errorString = rJson.reason;
        } else {
          errorString = rJson.data &&
              rJson.data.details &&
              rJson.data.details.reduce(
                  (acc, val) => (acc += `${val.message + "\n\n"}`),
                  ""
              );
        }
        NotificationManager.error(
          <ErrorText>{errorString}</ErrorText>,
          i18next.t("server_error"),
          0
        );
      }

      throw rJson;
    });
  } else {
    if (!response._bodyBlob.type.includes("application/json")) {
      return response._bodyBlob;
    }

    return response.json().then((responseAsString) => {
      logResponse(response, responseAsString);
      if (returnFullResponse) {
        return responseAsString;
      }
      return responseAsString.data;
    });
  }
};
