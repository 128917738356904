import React from "react";
import { FlexContainer } from "../../components/FlexContainer";
import {Field} from "redux-form";
import Loader from "../../components/Loader";
import Table, { TD, TR } from "../../components/Table";
import CheckBox from "../../components/CheckBox";
import { connect } from "react-redux";
import {SelectDropDown} from "../../messages/FormOne";
import {EditDeleteIcon} from "./InsiderSyncContainer";
import EditInsiderLogUrlModal from "./EditInsiderLogUrlModal";
import {
    deleteInsiderLogDetails,
    fetchInsiderSyncDetails,
    updateInsiderLogSyncDetails
} from "./InsiderSyncDetialsActions";
import {ConfirmModal} from "../../components/ConfirmModal";
import {withTranslation} from "react-i18next";
import {NotificationManager} from "react-notifications";

class InsiderSyncList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showEditURLModal: false,
      showDeleteURLModal: false,
    };
  }

    render() {
    const {
      t,
    } = this.props;

    const thLabels = [
      t("company"),
      t("cid"),
      t("lei"),
      t("system_insider_log_link"),
      "Sync",
      t("edit"),
      t("delete")
    ];

    const headerProps = [
      {},
      {},
      {},
      { center: true, width: "100px;" },
      { center: true, width: "100px;" },
      { center: true, width: "100px;" }
    ];

    const tableRows =
      this.props.data.length > 0 &&
      this.props.data.map((entity, index) => {
              return (
                  <TR key={index}>
                      <TD deleted={!entity.active} ellipsis>
                          {entity.issuerName}
                      </TD>
                      <TD deleted={!entity.active} ellipsis>
                          {entity.cid}
                      </TD>
                      <TD deleted={!entity.active} ellipsis>
                          {entity.lei}
                      </TD>
                      <TD ellipsis>{entity.insiderLogUrl}</TD>
                      <TD>
                          {
                              <CheckBox
                                  margin={[0, 0, 0, 30]}
                                  disabled={true}
                                  checked={entity.approvalStatus > 0}
                              />
                          }
                      </TD>
                      <TD center>
                          <EditDeleteIcon
                              inactive={false}
                              altText={t("edit")}
                              iconSource={"/icons/ic-edit-black-24-px.svg"}
                              onClick={() => {
                                  this.setState({
                                      showEditURLModal: true,
                                      selectedCompany: {
                                          id: entity.id,
                                          issuerName: entity.issuerName,
                                          insiderLogURL: entity.insiderLogUrl,
                                          isSync: entity.approvalStatus,
                                          cid: entity.cid
                                      }
                                  });
                              }}
                          />
                      </TD>
                      <TD center>
                          <EditDeleteIcon
                              inactive={false}
                              altText={t("delete")}
                              iconSource={"/icons/red-cross-24.svg"}
                              onClick={() => {
                                  this.setState({
                                      showDeleteURLModal: true,
                                      selectedCompany: {
                                          issuerName: entity.issuerName,
                                          id: entity.id,
                                          cid: entity.cid
                                      }
                                  });
                              }}
                          />
                      </TD>
                  </TR>
              );
      });

    return (
      <FlexContainer margin={[0, 0, 20, 0]}>


          <FlexContainer width={"40%"} margin={[10, 0, 10, 0]}>
              <Field
                  component={SelectDropDown}
                  t={this.props.t}
                  hiddenToolTip={true}
                  placeholder={t("select")}
                  options={this.props.insiderSyncDetails.map((key) => {
                      return {
                          label: key.issuerName,
                          value: key.id,
                      };
                  })
                  }
                  onSelect={this.props.onCompanySearch}
                  onChange={null}
                  name="company"
                  label={t("system_company_filter")}
              />
          </FlexContainer>


          <FlexContainer margin={[10, 0, 10, 0]}>
          </FlexContainer>
          {this.props.loading ? <Loader /> :
              <Table
              tableRows={tableRows || []}
              tableHeaderLabels={thLabels}
              tableHeaderProps={headerProps}
              forceUpdate={true}
              t={t}
          />}

          {this.state.showEditURLModal ? (
              <EditInsiderLogUrlModal
                  t={t}
                  initialValues={{
                      insiderLogURL: this.state.selectedCompany.insiderLogURL,
                      insiderLogSync: this.state.selectedCompany.isSync > 0,
                      issuerName: this.state.selectedCompany.issuerName,
                      client: this.props.selectedClient.label
                  }}
                  onClose={() =>
                      this.setState({
                          showEditURLModal: false
                      })
                  }
                  onSubmit={values => {
                      this.setState({ loading: true });
                      let insiderLogDetails;
                      insiderLogDetails = {
                          cid: this.state.selectedCompany.cid,
                          approvalStatus: values.insiderLogSync ? 1 : 0,
                          insiderLogUrl: values.insiderLogURL,
                          clientId: this.props.selectedClient ? this.props.selectedClient.value : null
                      };

                      this.props
                          .dispatch(
                              updateInsiderLogSyncDetails(
                                  insiderLogDetails,
                                  this.state.selectedCompany.id
                              )
                          )
                          .then(() => {
                              if (this.props.selectedClient !== undefined) {
                                  this.props.dispatch(fetchInsiderSyncDetails(this.props.selectedClient.value))
                              }
                          })
                          .then(() =>
                              NotificationManager.success(this.props.t("success"))
                          )
                          .then(() =>
                              this.setState({ showEditURLModal: false, loading: false })
                          )
                          .catch(err => {
                              this.setState({ loading: false });
                              console.log(err);
                          });
                  }}
              />
          ) : undefined}

          {this.state.showDeleteURLModal ? (
              <ConfirmModal
                  t={t}
                  isOpen={true}
                  header={t("system_delete_insider_log_url")}
                  confirmText={t("it_confirm")}
                  cancelText={t("cancel")}
                  onClose={() =>
                      this.setState({
                          showDeleteURLModal: false
                      })
                  }
                  onConfirm={() => {
                      this.setState({ loading: true });
                      this.props
                          .dispatch(
                              deleteInsiderLogDetails(this.state.selectedCompany.id) //TODO må spesifisere client?
                          )
                          .then(() => {
                              if (this.props.selectedClient !== undefined) {
                                  this.props.dispatch(fetchInsiderSyncDetails(this.props.selectedClient.value))
                              }
                          })
                          .then(() =>
                              NotificationManager.success(this.props.t("success"))
                          )
                          .then(() =>
                              this.setState({ showDeleteURLModal: false, loading: false })
                          )
                          .catch(err => {
                              this.setState({ loading: false });
                              console.log(err);
                          });
                  }}
              >
                  <FlexContainer center vAlignCenter margin={[10, 0, 30, 0]}>
                      {t(
                          "system_you_really_want_to_delete_insider_log_url_for_organisation",
                          {
                              company: this.state.selectedCompany.issuerName
                          }
                      )}
                  </FlexContainer>
              </ConfirmModal>
          ) : undefined}

      </FlexContainer>
    );
  }
}


export default connect()(
    withTranslation("translations")(InsiderSyncList));
