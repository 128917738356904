import React from "react";
import styled, { css } from "styled-components";
import { sizes } from "../util/common";
import { FlexContainer } from "./FlexContainer";
import Button from "./Button";

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
const tiltedPhone = (...args) => css`
  @media (max-height: 580px) {
    ${css(...args)};
  }
`;

const tiltedTablet = (...args) => css`
  @media (max-height: 768px) and (max-width: 1024px) {
    ${css(...args)};
  }
`;

const ModalWrapper = styled.div`
  ${(props) =>
    props.isOpen
      ? "display:flex;"
      : "display: none;"} background-color: rgba(0, 0, 0, 0.3);

  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
`;

const ModalBox = styled.div`
  display: inline-block;
  display: -ms-grid;
  -ms-grid-rows: 100px 1fr;
  -ms-grid-columns: 16px 1fr 16px;

  grid-template-columns: 16px auto 16px;
  grid-template-rows: 100px 1fr;

  ${(props) => (props.width ? "width: " + props.width + ";" : "width: 50%;")}
  background:white;
  color: black;
  overflow-y: auto;
  overflow-x: auto;
  min-height: 10%;
  max-height: 80%;

  margin-bottom: 30px;

  ${media.phone`

-ms-grid-columns: 14px 1fr 14px;
max width: 90%;

grid-template-columns: 28px auto 28px;
`}

  ${media.tablet`

-ms-grid-columns: 14px 1fr 14px;
max width: 90%;

grid-template-columns: 28px auto 28px;

`}

${tiltedPhone`
max-height:90%;
max-width:80%;
`}

${tiltedTablet`
height:90%;
width:80%;
`}
`;

const CloseIcon = styled.img`
  cursor: pointer;
  margin-top: 16px;
  float: right;
  padding-right: 16px;

  ${media.phone`
padding-right: 10px;
`} ${media.tablet`

padding-right: 10px;

`};
`;

const HeaderModal = styled.h1`
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;
  grid-column: 2;
  grid-row: 1 / 2;
`;

const IconContainer = styled.div`
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 3;
  grid-column: 3;
  grid-row: 1 / 2;
`;

const ContentModal = styled.div`
  color: black;
  white-space: pre-line;
  overflow-wrap: break-word;
  display: block;
  margin-bottom: 10px;

  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 2;

  grid-column: 2;
  grid-row: 2 / 3;
  margin: 20px;
`;

export class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDownEvent = this.handleKeyDownEvent.bind(this);
  }
  componentWillMount() {
    window.addEventListener("keydown", this.handleKeyDownEvent);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDownEvent);
  }

  handleKeyDownEvent(event) {
    if (this.props.isOpen && event.keyCode === 27) {
      this.props.onClose();
    }
  }

  render() {
    const {
      isOpen,
      header,
      onConfirm,
      onClose,
      confirmText,
      cancelText,
      children,
      width,
      customButtons,
      isJSON,
    } = this.props;

    return (
      <ModalWrapper isOpen={isOpen}>
        <ModalBox width={width}>
          <IconContainer>
            <CloseIcon onClick={onClose} src="/icons/red-cross-24.svg" />
          </IconContainer>

          <HeaderModal>{header}</HeaderModal>

          <ContentModal>
            {isJSON ? <pre>{children}</pre> : children}

            {customButtons ? (
              customButtons
            ) : (
              <FlexContainer
                row
                center
                flexEnd
                vAlignCenter
                margin={[16, 0, 16, 0]}
              >
                <Button.Standard
                  inactive={onConfirm == null}
                  onClick={onConfirm}
                  margin={[0, 16, 0, 0]}
                >
                  {confirmText}
                </Button.Standard>
                <Button.Text onClick={onClose}>{cancelText}</Button.Text>
              </FlexContainer>
            )}
          </ContentModal>
        </ModalBox>
      </ModalWrapper>
    );
  }
}
