import React from "react";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import { Field, Fields, reduxForm } from "redux-form";
import DayPicker from "../components/DayPicker";

import Button from "../components/Button";
import Label from "../components/Label";
import { MessageBox } from "../components/MessageBox";
import { ErrorText } from "../components/ErrorText";

const DateBox = (props) => (
  <FlexContainer column maxWidth={275} margin={[0, 0, 24, 0]}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
    <DayPicker {...props} border={true} disableDaysBefore={new Date()} />
  </FlexContainer>
);

/*
const TimeBox = (props) => (
  <FlexContainer column noMargin maxWidth={178} margin={[0, 0, 0, 16]}>
    <Label htmlFor={props.input.name}>{props.label}</Label>
    <TimePicker {...props} />
    {props.meta.error ? <ErrorText>{props.meta.error}</ErrorText> : undefined}
  </FlexContainer>
);
*/

const ErrorMessage = (props) => {
  const { error } = props.meta;

  return error ? (
    <FlexContainer margin={[20, 0, 2, 0]}>
      <ErrorText>{error}</ErrorText>
    </FlexContainer>
  ) : (
    ""
  );
};

class InfoMessage extends React.Component {
  render() {
    const props = this.props;
    const { t } = props;

    return (
      <FlexContainer column>
        <form autoComplete="off">
          <PageHeading>
            {props.messageId ? t("message_from_ma") : t("new_message_from_ma")}
          </PageHeading>
          <FlexContainer row>
            <Field
              component={DateBox}
              label={t("publishdate")}
              name={"publishDate"}
            />
            {/*<Field
                component={TimeBox}
                label={t("time")}
                name={"publishTime"}
                t={t}
              />*/}
          </FlexContainer>

          <Field component={ErrorMessage} name="errorMessage" />

          <FlexContainer row margin={[0, 0, 40, 0]}>
            <FlexContainer margin={[0, 19, 0, 0]}>
              <Fields
                names={["titleNo", "bodyNo"]}
                component={MessageBox}
                withFileUpload={false}
                header={t("norwegian_message")}
                titlePlaceholder={t("messageTitle")}
                messagePlaceholder={t("message")}
              />
            </FlexContainer>
            <FlexContainer>
              <Fields
                names={["titleEn", "bodyEn"]}
                component={MessageBox}
                withFileUpload={false}
                header={t("english_message")}
                titlePlaceholder={t("messageTitle")}
                messagePlaceholder={t("message")}
              />
            </FlexContainer>
          </FlexContainer>
          <FlexContainer flexEnd row vAlignCenter margin={[0, 0, 25, 0]}>
            <Button.Standard
              margin={[0, 24, 0, 0]}
              onClick={props.handleSubmit}
            >
              {t("save")}
            </Button.Standard>
            <Button.Text onClick={props.onCancel}>{t("cancel")}</Button.Text>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

InfoMessage = reduxForm({
  form: "ma_message",
})(InfoMessage);

export default InfoMessage;
