import {css} from "styled-components";
import moment from "moment-timezone";
import i18n from "../i18n";
import {fetchData} from "../actions/CommonActionsNoObsvc";

export const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 530,
};

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});

const FetchServerFileActions = {
  request: "REQUEST_SERVER_FILE",
  receive: "RECEIVE_SERVER_FILE",
  receiveFail: "RECEIVE_SERVER_FILE_FAILED",
};

export const fetchServerFile = (path) =>
    fetchData(
        FetchServerFileActions,
        undefined,
        path
    );

export const showFileFromServer = (blob, fileName) => {
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
    return;
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(blob);
  var link = document.createElement("a");
  link.href = data;
  link.download = fileName;
  link.dispatchEvent(
    new MouseEvent(`click`, { bubbles: true, cancelable: true, view: window })
  );
};

export const OBNTPrettyDateFormat = "DD.MM.YYYY";
export const OBNTDateFormat = "DD-MM-YYYY";
export const OBNTTimeFormat = "HH:mm";
export const OBNTTimeFormatWithSecAndMillis = "HH:mm:ss.SSS";
export const OBNTDateTimeFormat = OBNTDateFormat + " " + OBNTTimeFormat;
export const OBNTInsiderToolDateFormat = "YYYY-MM-DD";
export const OBNTInsiderToolDateTimeFormat = "YYYY-MM-DD " + OBNTTimeFormat;

export const getOBNTDate = (timestamp) => {
  return timestamp ? moment(timestamp).format(OBNTDateFormat) : undefined;
};

export const getOBNTTime = (timestamp) => {
  return timestamp ? moment(timestamp).format(OBNTTimeFormat) : undefined;
};

export const getOBNTTimeWithSecAndMillis = (timestamp) => {
  return timestamp
    ? moment(timestamp).format(OBNTTimeFormatWithSecAndMillis)
    : undefined;
};

export const getOBNTDateTime = (timestamp, tzOslo = false) => {
  if (timestamp) {
    return tzOslo
      ? moment.tz(timestamp, "Europe/Oslo").format(OBNTDateTimeFormat)
      : moment(timestamp).format(OBNTDateTimeFormat);
  }
};

export const getPrettyOBNTDate = (timestamp) => {
  return timestamp ? moment(timestamp).format(OBNTPrettyDateFormat) : undefined;
};

export const lexicographicalSort = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;

  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase(), "nb-NO");
};

export const lightWeightLexicographicalSort = (a, b) => {
  return lexicographicalSort(a.substring(0, 2), b.substring(0, 2));
};

export const getLanguage = () => (i18n.language === "nb" ? "No" : "En");
export const getLanguages = () => ["no", "en"];

export const browserIsIE = () =>
  window.navigator && window.navigator.msSaveOrOpenBlob;

export const filterUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

// CONTACT CONSTANTS
export const CEO = 5;
export const CFO = 6;
export const BOARD_DIRECTOR = 4;
export const IR = 9;
export const LAW_DIRECTOR = 10;
export const PRIMARY_EQUITY = 1;
export const SECONDARY_EQUITY = 3;
export const PRIMARY_BOND = 2;
export const SECONDARY_BOND = 11;
export const AUDIT_COMMITEE_MEMBER_1 = 12;
export const AUDIT_COMMITEE_MEMBER_2 = 13;

// Limit for number of messages to display
export const MAX_NUM_MESSAGES = 500;

export const ACCESS_LEVEL = {
  NO_ACCESS: 0,
  READ: 1,
  READ_WRITE: 2,
  OWNER: 3,
  ADMIN: 4,
};
export const mapAccessLevel = (accessLevel, t) => {
  switch (accessLevel) {
    case ACCESS_LEVEL.NO_ACCESS:
    case ACCESS_LEVEL.READ:
    case ACCESS_LEVEL.READ_WRITE:
    case ACCESS_LEVEL.OWNER:
    case ACCESS_LEVEL.ADMIN:
      return t("it_insider_permission_" + accessLevel);
    default:
      return accessLevel;
  }
};

export const APP_WIDTH = 1160;

export const isSuperUser = (user) => {
  return user && user.roles && user.roles.includes("superuser");
};

const PUBLIC_INSIDER_LOG_DEFAULT_LINK_PREFIX =
  "insiderlog.global.defaultInsiderLogURL_";
export const INSIDER_LOG_LINK_PREFIX =
  "public.app.insiderlog.org.insiderLogURL-";
export const PUBLIC_INSIDER_LOG_LINK_PREFIX = "insiderlog.org.insiderLogURL";

export const getInsiderLogURL = (
  publicPropsObject,
  insiderLogSyncDetailsUrl
) => {
  return insiderLogSyncDetailsUrl[Object.keys(insiderLogSyncDetailsUrl)[0]] !==
    undefined && insiderLogSyncDetailsUrl !== ""
    ? insiderLogSyncDetailsUrl[Object.keys(insiderLogSyncDetailsUrl)[0]].url
    : publicPropsObject[
        PUBLIC_INSIDER_LOG_DEFAULT_LINK_PREFIX + getLanguage().toLowerCase()
      ];
};

export const hasValue = (val) => {
  return !(val === undefined || val === null);

}

export const stringHasValue = (val) => {
  return val !== null && val !== undefined && val.trim() !== "";
}

export const getFormattedTime = () => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}