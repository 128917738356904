import React from "react";
import { NotificationManager } from "react-notifications";
import InsiderPerson from "../../../modals/insiderList/InsiderPerson";
import {
  createInsider,
  fetchInsiderList,
  fetchTemplate,
  InsiderType
} from "../../../InsiderToolActions";

import SendReminderModal from "../../../modals/insiderList/SendReminderModal";

import { validateInsiderPerson } from "../Validator";
import { reset, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { OverlayLoader } from "../../../../components/Loader";
import { compareKnownPersonFields } from "./utils/CompareKnownPersonFields";

export const valuesNotAllowedWithoutMFANumber = [
  "address",
  "ceased_date",
  "ceased_time",
  "city",
  "comment",
  "company_address",
  "company_city",
  "company_country",
  "company_name",
  "company_post_code",
  "country",
  "date_of_birth",
  "idnumber",
  "insider_function_and_reason",
  "phone_private",
  "phone_private_2",
  "phone_work",
  "phone_work_2",
  "post_code"
];

class AddNewInsider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifyModal: false,
      selectedKnownPerson: undefined
    };
  }

  fetchMailTemplates = () => {
    const listType = this.props.listType;
    const templateFileName =
      listType === InsiderType.LIST
        ? "inside_notification"
        : "conf_notification";
    this.setState({ templateFileName: templateFileName });
    return this.props
      .dispatch(fetchTemplate("no", templateFileName))
      .then(response => this.setState({ emailTemplateNo: response }))
      .then(() => this.props.dispatch(fetchTemplate("en", templateFileName)))
      .then(response => this.setState({ emailTemplateEn: response }));
  };

  someValuesNotAllowed = values => {
    let missingTwoFactorAndHasMoreValues = false;
    const twoFactorPhone = values.twoFactorPhone;
    const twoFactorCountryCode = values.twoFactorCountryCode;

    if (!twoFactorPhone || !twoFactorCountryCode) {
      for (let k = 0; k < valuesNotAllowedWithoutMFANumber.length; k++) {
        if (
          values[valuesNotAllowedWithoutMFANumber[k]] &&
          values[valuesNotAllowedWithoutMFANumber[k]].length > 0
        ) {
          missingTwoFactorAndHasMoreValues = true;
          break;
        }
      }
    }
    return missingTwoFactorAndHasMoreValues;
  };

  onSelectKnownPerson = values => {
    const { knownPersonList, dispatch } = this.props;

    const getSelectedKnownPerson = id => {
      return knownPersonList
        ? knownPersonList.find(person => person.id === id)
        : undefined;
    };

    dispatch(reset("insiderPerson"));
    this.setState({
      selectedKnownPerson: getSelectedKnownPerson(values.value)
    });
  };

  onAddNewInsider = values => {
    const { dispatch, listId, t } = this.props;

    return new Promise((resolve, reject) => {
      const errors = validateInsiderPerson(values, this.props);
      if (errors) {
        reject(new SubmissionError(errors));
      } else {
        if (!values.dontUpdateTimeOnSave) {
          this.props.updateUpdateTime();
        }
        delete values.dontUpdateTimeOnSave;
        delete values.regulatory_compliance;
        delete values.correct_information;
        delete values.selectedKnownPersonId;

        const data = this.props.getUpdatedDatesForInsider(values);
        Promise.resolve(this.setState({ loading: true }))
          .then(() => this.props.updateCountriesForPerson(data))
          .then(() => dispatch(createInsider(null, listId, data)))
          .then(() => dispatch(reset("insiderPerson")))
          .then(() => dispatch(fetchInsiderList(listId)))
          .then(() =>
            this.setState({
              showAddInsiderForm: false,
              selectedKnownPerson: undefined
            })
          )
          .catch(e => console.log(e))
          .then(() => this.setState({ loading: false }));
      }
    });
  };

  addNewNotifyInsider = (values, listId) => {
    return new Promise((resolve, reject) => {
      const errors = validateInsiderPerson(values, this.props);

      if (errors) {
        reject(new SubmissionError(errors));
      } else if (this.someValuesNotAllowed(values)) {
        NotificationManager.error(
          this.props.t(
            "it_some_of_the_selected_has_more_value_than_allowed_and_no_two_factor_info"
          ),
          "",
          0
        );
        reject();
      } else {
        if (!values.dontUpdateTimeOnSave) {
          this.props.updateUpdateTime();
        }
        delete values.dontUpdateTimeOnSave;
        delete values.regulatory_compliance;
        delete values.correct_information;

        const data = this.props.getUpdatedDatesForInsider(values);
        this.props.updateCountriesForPerson(data);

        Promise.resolve(this.setState({ loading: true }))
          .then(() => this.props.dispatch(createInsider(null, listId, data)))
          .then(response =>
            Promise.resolve(this.setState({ newInsiderId: response.result.id }))
          )
          .then(() => this.props.dispatch(fetchInsiderList(listId)))

          .then(this.fetchMailTemplates)
          .then(() =>
            this.setState({ showAddInsiderForm: false, showNotifyModal: true })
          )
          .then(() => this.props.dispatch(reset("insiderPerson")))
          .then(resolve)
          .catch(e => reject(console.log(e)))
          .then(() => this.setState({ loading: false }));
      }
    });
  };

  render() {
    const { dispatch, t, listId, listType } = this.props;
    return (
      <React.Fragment>
        {this.state.showLoader ? <OverlayLoader /> : ""}
        {this.state.showAddInsiderForm ? (
          <InsiderPerson
            key="newInsiderModal"
            isOpen={this.state.showAddInsiderForm}
            onClose={() => {
              dispatch(reset("insiderPerson"));
              this.setState({ showAddInsiderForm: false });
            }}
            onSubmit={data => this.onAddNewInsider(data)}
            onSubmitText={
              this.props.listType === InsiderType.LIST
                ? "it_add_insider_to_list"
                : "it_add_person_to_list"
            }
            onSubmitNotify={data => this.addNewNotifyInsider(data, listId)}
            onSubmitNotifyText={
              this.props.listType === InsiderType.LIST
                ? "it_add_insider_to_list_notify"
                : "it_add_person_to_list_notify"
            }
            header={
              this.props.listType === InsiderType.LIST
                ? "it_add_new_insider_to_list"
                : "it_add_new_person_to_list"
            }
            listType={listType}
            knownPersons={this.props.uniqueKnownPersonOptionList}
            onSelectKnownPerson={this.onSelectKnownPerson}
            initialValues={
              this.state.selectedKnownPerson
                ? {
                    ...compareKnownPersonFields(this.state.selectedKnownPerson),
                    selectedKnownPersonId: this.state.selectedKnownPerson.id
                  }
                : undefined
            }
            enableReinitialize
            resetForm={() => {
              this.setState({ selectedKnownPerson: undefined });
              dispatch(reset("insiderPerson"));
            }}
            isNewInsider
            countryOptions={this.props.countryOptions}
          />
        ) : (
          undefined
        )}

        {this.state.showNotifyModal ? (
          <SendReminderModal
            notifyReminder={"notify"}
            templateName={this.state.templateFileName}
            listType={listType}
            key={"sendReminderModal"}
            onClose={() =>
              this.setState({
                showNotifyModal: false,
                selectedRow: undefined,
                newInsiderId: undefined
              })
            }
            onSend={sendFnc => {
              Promise.resolve(this.setState({ loading: true }))
                .then(sendFnc)
                .then(() => this.setState({ loading: false }));
            }}
            initialValues={{
              body_0: this.props.replaceKeysInString(
                this.state.emailTemplateNo.body[0],
                "nb"
              ),
              body_en_0: this.props.replaceKeysInString(
                this.state.emailTemplateEn.body[0],
                "en"
              ),
              body_1: "",
              body_en_1: "",
              body_2: this.props.replaceKeysInString(
                this.state.emailTemplateNo.body[2],
                "nb"
              ),
              body_en_2: this.props.replaceKeysInString(
                this.state.emailTemplateEn.body[2],
                "en"
              )
            }}
            selectNorwegian={() => {
              this.setState({ norwegianSelected: true });
            }}
            selectEnglish={() => this.setState({ norwegianSelected: false })}
            norwegianSelected={this.state.norwegianSelected}
            emailTemplateNo={this.state.emailTemplateNo}
            emailTemplateEn={this.state.emailTemplateEn}
            insidersToApprove={[this.state.newInsiderId]}
          />
        ) : (
          undefined
        )}
        <Button.Standard
          margin={[0, 0, 0, 5]}
          onClick={() =>
            this.props.hasEditAccess
              ? this.setState({ showAddInsiderForm: true })
              : undefined
          }
          inactive={!this.props.hasEditAccess}
        >
          {this.props.listType === InsiderType.LIST
            ? t("it_add_insider")
            : t("it_add_person")}
        </Button.Standard>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { insiderToolReducer } = state;

  return {
    importList: insiderToolReducer.importList.list,
    listType: insiderToolReducer.insiderList.insiderListInfo.type,
    listId: insiderToolReducer.insiderList.insiderListInfo.listId,
    uniqueKnownPersonOptionList:
      insiderToolReducer.knownPersons.uniqueKnownPersonOptionlist,
    knownPersonList: insiderToolReducer.knownPersons.persons
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(AddNewInsider)
);
