import { combineReducers } from "redux";
import {
  FetchOrganisationList,
  UserList,
  UpdateUser,
  UpdateOrganisationActions,
  UserParameterActions,
  CreateUser,
  FetchUser,
  CreateOrganisationActions,
  FetchOrganisationActions, ValidKeys, GenerateApiKey, ApiKeys, UserprofileData
} from "./AdminActions";

const initalOrgListState = {
  list: [],
  isFetched: false,
};
export const organisationList = (
  state = {
    ...initalOrgListState,
  },
  action
) => {
  switch (action.type) {
    case FetchOrganisationList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity || [],
        isFetched: true,
      });
    default:
      return state;
  }
};

const initialOrgState = {
  orgData: undefined,
  organisationIsFetched: false,
  updateFailed: false,
  createFailed: false,
};
export const organisation = (
  state = {
    ...initialOrgState,
  },
  action
) => {
  switch (action.type) {
    case FetchOrganisationActions.postSuccess:
      return Object.assign({}, state, {
        orgData: action.data.data.entity,
        organisationIsFetched: true,
      });
    case FetchOrganisationActions.request:
      return Object.assign({}, state, {
        orgData: undefined,
        organisationIsFetched: false,
      });
    case FetchOrganisationActions.clear:
      return Object.assign({}, state, {
        orgData: undefined,
        organisationIsFetched: false,
      });

    case UpdateOrganisationActions.postFail:
      return Object.assign({}, state, {
        updateFailed: true,
      });
    case UpdateOrganisationActions.request:
      return Object.assign({}, state, {
        updateFailed: false,
      });
    case CreateOrganisationActions.request:
      return Object.assign({}, state, {
        createFailed: false,
      });
    case CreateOrganisationActions.postFail:
      return Object.assign({}, state, {
        createFailed: true,
      });

    default:
      return state;
  }
};

const initalUserListState = {
  list: [],
};
export const userList = (
  state = {
    ...initalUserListState,
  },
  action
) => {
  switch (action.type) {
    case UserList.postSuccess:
      return Object.assign({}, state, {
        list: action.data.data.entity,
      });
    case UserList.clear:
      return Object.assign({}, state, {
        ...initalUserListState,
      });
    default:
      return state;
  }
};

const initalUserState = {
  userData: undefined,
  userIsFetched: false,
  updateFailed: false,
  createFailed: false,
  userNameExists: undefined,
};
export const user = (
  state = {
    ...initalUserState,
  },
  action
) => {
  switch (action.type) {
    case FetchUser.postSuccess:
      return Object.assign({}, state, {
        userData: action.data.data.entity,
        userIsFetched: true,
      });
    case CreateUser.postFail:
    case UpdateUser.postFail:
      return Object.assign({}, state, {
        updateFailed: true,
      });
    case UpdateUser.request:
    case CreateUser.postSuccess:
      return Object.assign({}, state, {
        createFailed: false,
      });

    default:
      return state;
  }
};

const initalUserParamsState = {
  params: undefined,
  paramsAreFetched: false,
};
export const userParams = (
  state = {
    ...initalUserParamsState,
  },
  action
) => {
  switch (action.type) {
    case UserParameterActions.postSuccess:
      return Object.assign({}, state, {
        params: action.data.data.entity,
        paramsAreFetched: true,
      });

    default:
      return state;
  }
};

const initialValidKeysState = {
  list: [],
};
export const validKeys = (
    state = {
      ...initialValidKeysState,
    },
    action
) => {
  switch (action.type) {
    case ValidKeys.receive:
      return Object.assign({}, state, {
        list: action.data.data.entity,
      });
    case ValidKeys.clear:
      return Object.assign({}, state, {
        ...initialValidKeysState,
      });
    default:
      return state;
  }
};

const initialApiKeysState = {
  list: [],
};
export const apiKeys = (
    state = {
      ...initialApiKeysState,
    },
    action
) => {
  switch (action.type) {
    case ApiKeys.receive:
      return Object.assign({}, state, {
        list: action.data.data.entity,
      });
    case ApiKeys.clear:
      return Object.assign({}, state, {
        ...initialApiKeysState,
      });
    default:
      return state;
  }
};

const initialGeneratedKeyState = {
  generatedKey: {},
};
export const generatedKey = (
    state = {
      ...initialGeneratedKeyState,
    },
    action
) => {
  switch (action.type) {
    case GenerateApiKey.receive:
      return Object.assign({}, state, {
        generatedKey: action.data.data.entity,
      });
    default:
      return state;
  }
};

const initialUserprofileDataState = {
  data: {},
};
export const userprofileData = (
    state = {
      ...initialUserprofileDataState,
    },
    action
) => {
  switch (action.type) {
    case UserprofileData.receive:
      return Object.assign({}, state, {
        data: action.data.data.entity,
      });
    default:
      return state;
  }
};


const adminReducer = combineReducers({
  organisationList,
  organisation,
  userList,
  user,
  userParams,
  validKeys,
  generatedKey,
  apiKeys,
  userprofileData
});

export default adminReducer;
