import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import "moment/locale/nb";

const Input = styled.input`
  position: relative;
  box-sizing: border-box;
  border: solid 1px #d0d0d0;
  padding: 8px 16px 8px 16px;
  font-size: inherit;
  font-family: inherit;
  height: 35px;
  width: 100%;
  &:focus {
    outline: none;
  }
`;
const Icon = styled.img`
  ${props =>
    props.displayIcon
      ? "display: inline;"
      : "display:none;"} position: absolute;
  top: 15px;
  right: 8px;
  cursor: pointer;
`;
const TimePanel = styled.div`
  ${props =>
    props.displayPanel
      ? "display: grid; -ms-grid;"
      : "display:none;"} position: absolute;
  top: 35px;

  height: 128px;
  width: 100%;

  background: white;
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  border: solid 1px #d0d0d0;
  border-top: 0px;
  padding: 0px 40px 25px 40px;

  /*IE 11*/
  -ms-grid-columns: 1fr;
  -ms-grid-rows: 1fr 1fr 1fr 1fr;
  /*******/

  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  z-index: 1;
`;
const Header = styled.span`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  padding: 8px;

  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  padding: 4px;
`;
const TopButtons = styled(Buttons)`
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
`;
const BottomButtons = styled(Buttons)`
  -ms-grid-row: 4;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
`;

const Button = styled.img`
  padding: 0 2px 0 2px;
`;

const Numbers = styled(Buttons)`
  font-size: 14px;
  consttopbuttons=styled(Buttons)-ms-grid-row: 3;
  -ms-grid-row-span: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
`;

export class TimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hour: "00",
      minute: "00",
      displayTimePanel: false
    };

    this.handleClickEvent = this.handleClickEvent.bind(this);
    this.inputValidator = this.inputValidator.bind(this);
  }

  handleClickEvent(e) {
    const timeTags = [
      this.props.input.name,
      this.props.input.name + "_time_panel",
      "openIcon"
    ];
    const clickIsInsideTimePanel = target =>
      target == null
        ? false
        : timeTags.includes(target.id || "")
          ? true
          : clickIsInsideTimePanel(target.parentNode);

    if (
      !clickIsInsideTimePanel(e.target) &&
      this.state.displayTimePanel === true
    ) {
      this.setState({ displayTimePanel: false });
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClickEvent);
    this.updateState(this.props);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClickEvent);
  }

  componentWillReceiveProps(nextProps) {
    this.updateState(nextProps);
  }

  updateState(props) {
    if (props.input.value != null) {
      const match = props.input.value.match(/(\d\d):(\d\d)/);

      if (match && match.length === 3) {
        const hour = match[1];
        const minute = match[2];
        this.setState({ hour, minute });
      }
    }
  }

  formatHour = hour =>
    hour < 0 ? "23" : hour < 10 ? "0" + hour : String(hour);
  formatMinute = minute =>
    minute < 0 ? "55" : minute < 10 ? "0" + minute : String(minute);

  inputValidator(e) {
    if (
      (e.keyCode < 48 || e.keyCode > 57) &&
      e.keyCode !== 8 &&
      e.keyCode !== 13 &&
      e.keyCode != 9 &&
      (e.keyCode < 95 || e.keyCode > 106)
    ) {
      e.preventDefault();
    } else if (e.keyCode === 13) {
      //Enter
      this.props.input.onChange(this.state.hour + ":" + this.state.minute);
      this.setState({ displayTimePanel: false });
      e.preventDefault();
    } else if (e.keyCode === 9) {
      //tab
      return;
    } else if (e.keyCode === 8) {
      //Backspace

      if (this.props.input.value.length === 5) {
        this.setState({ minute: this.state.minute[0] + "0" });
      } else if (this.props.input.value.length === 4) {
        this.setState({ minute: "00" });
      } else if (this.props.input.value.length === 3) {
        this.setState({ hour: this.state.hour[0] + "0" });
        // Remove colon when users deletes third character
        this.props.input.onChange(this.props.input.value.slice(0, 2));
      } else if (this.props.input.value.length === 1) {
        this.setState({ hour: "00" });
      }
    } // 0-9
    else {
      if (this.props.input.value.length === 0) {
        //first digit validation
        if (!["0", "1", "2"].includes(e.key)) {
          e.preventDefault();
          return;
        }
        this.setState({ hour: e.key + "0" });
      } else if (this.props.input.value.length === 1) {
        //second digit validation
        if (this.props.input.value === "2") {
          if (!["0", "1", "2", "3"].includes(e.key)) {
            e.preventDefault();
            return;
          }
        }
        this.setState({ hour: this.state.hour[0] + e.key });
        //add colon when user enters second digit
        this.props.input.onChange(this.props.input.value + e.key + ":");
        e.preventDefault();
      } else if (this.props.input.value.length === 3) {
        //third digit validation
        if (!["0", "1", "2", "3", "4", "5"].includes(e.key)) {
          e.preventDefault();
          return;
        }
        this.setState({ minute: e.key + "0" });
      } else if (this.props.input.value.length === 4) {
        this.setState({ minute: this.state.minute[0] + e.key });
      } else if (this.props.input.value.length >= 5) {
        //Include colon in the count and prvent more digits
        e.preventDefault();
      }
    }
  }

  render() {
    const { t } = this.props;
    const decrementHour = () => {
      var hour = this.formatHour((Number(this.state.hour) - 1) % 24);
      this.props.input.onChange(hour + ":" + this.state.minute);
      this.setState({ hour: hour });
    };
    const decrementMinute = () => {
      var decrementUnit = (Number(this.state.minute) % 5) - 5;
      var minute = this.formatMinute(
        (Number(this.state.minute) + decrementUnit) % 60
      );

      this.props.input.onChange(this.state.hour + ":" + minute);
      this.setState({ minute: minute });
    };
    const incrementHour = () => {
      var hour = this.formatHour((Number(this.state.hour) + 1) % 24);
      this.props.input.onChange(hour + ":" + this.state.minute);
      this.setState({ hour: hour });
    };
    const incrementMinute = () => {
      var incrementUnit = (Number(this.state.minute) % 5) - 5;
      var minute = this.formatMinute(
        (Number(this.state.minute) - incrementUnit) % 60
      );

      this.props.input.onChange(this.state.hour + ":" + minute);
      this.setState({ minute: minute });
    };

    return (
      <FlexContainer
        relative
        column
        noMargin
        boxShadow={this.state.displayTimePanel}
      >
        <Input
          {...this.props.input}
          onKeyDown={this.inputValidator}
          id={this.props.input.name}
          onClick={() => {
            this.setState({ displayTimePanel: !this.state.displayTimePanel });
          }}
        />
        <Icon
          id="openIcon"
          onClick={() =>
            this.setState({ displayTimePanel: !this.state.displayTimePanel })
          }
          displayIcon={!this.state.displayTimePanel}
          src="/icons/ic-arrow-drop-down-down.svg"
        />

        <Icon
          displayIcon={this.state.displayTimePanel}
          src="/icons/ic-arrow-drop-down-up.svg"
        />

        <TimePanel
          id={this.props.input.name + "_time_panel"}
          displayPanel={this.state.displayTimePanel}
        >
          <Header>{t("choose_time")}</Header>
          <TopButtons>
            <Button
              src="/icons/ic-keyboard-arrow-up-black-24-px.svg"
              onClick={incrementHour}
            />
            <Button
              src="/icons/ic-keyboard-arrow-up-black-24-px.svg"
              onClick={incrementMinute}
            />
          </TopButtons>
          <Numbers>
            <span name={this.props.input.name + "_hour"}>
              {this.state.hour}
            </span>
            <span>:</span>
            <span name={this.props.input.name + "_minute"}>
              {this.state.minute}
            </span>
          </Numbers>
          <BottomButtons>
            <Button
              src="/icons/ic-keyboard-arrow-down-black-24-px.svg"
              onClick={decrementHour}
            />
            <Button
              src="/icons/ic-keyboard-arrow-down-black-24-px.svg"
              onClick={decrementMinute}
            />
          </BottomButtons>
        </TimePanel>
      </FlexContainer>
    );
  }
}

TimePicker.defaultProps = {
  input: {
    value: "00:00"
  }
};
export default withTranslation("translations")(TimePicker);
