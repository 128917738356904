import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { PageHeading } from "../components/PageHeading";
import { OverlayLoader } from "../components/Loader";
import { fetchCompanyList } from "../actions/CommonActions";
import EmailDistributionStatus from "./EmailDistributionStatus";
import { fetchDistributionStatus } from "./EmailDistributionActions";
import { reset } from "redux-form";
import { emailDistributionSearchFormValidator } from "./validator";
import { SubmissionError } from "redux-form";

class EmailDistributionStatusContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      issuerId: undefined,
      messageId: undefined,
      email: undefined,
      fromDate: undefined,
      toDate: undefined
    };
  }

  componentWillMount() {
    this.setState({ loading: true }, () =>
      this.props
        .dispatch(fetchCompanyList())
        .then(() => this.props.dispatch(fetchDistributionStatus()))
        .then(() => this.setState({ loading: false }))
        .catch(err => {
          console.log(err);
          this.setState({ loading: false });
        })
    );
  }

  render() {
    const { t, searchStatusForm } = this.props;

    const onStatusSearch = () => {
      const errors = emailDistributionSearchFormValidator(
        searchStatusForm,
        this.props
      );
      return new Promise((resolve, reject) => {
        if (errors) {
          return reject(new SubmissionError(errors));
        }

        this.setState(
          {
            issuerId: searchStatusForm.companySelect
              ? searchStatusForm.companySelect.value
              : undefined,
            messageId: searchStatusForm.messageId
              ? searchStatusForm.messageId
              : undefined,
            email: searchStatusForm.email ? searchStatusForm.email : undefined,
            fromDate: searchStatusForm.fromDate
              ? searchStatusForm.fromDate
              : undefined,
            toDate: searchStatusForm.toDate
              ? searchStatusForm.toDate
              : undefined,
            loading: true
          },
          () =>
            this.props
              .dispatch(
                fetchDistributionStatus(
                  this.state.issuerId,
                  this.state.messageId,
                  this.state.email,
                  this.state.fromDate,
                  this.state.toDate
                )
              )
              .then(resolve)
              .finally(() => this.setState({ loading: false }))
        );
      });
    };

    const resetSearch = () => {
      this.props
        .dispatch(fetchDistributionStatus())
        .then(() => this.props.dispatch(reset("distributionStatusForm")));
    };

    return (
      <FlexContainer>
        <PageHeading>{t("edist_distribution_status_header")}</PageHeading>
        {this.state.loading ? <OverlayLoader /> : ""}
        <EmailDistributionStatus
          {...this.props}
          onStatusSearch={onStatusSearch}
          resetSearch={resetSearch}
          companyList={this.props.companyList.map(elm => {
            return {
              label: elm.longName,
              value: elm.cid,
              helpText: elm.symbol
            };
          })}
          distributionStatusList={this.props.distributionStatusList}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { emailDistributionReducer, form } = state;
  return {
    companyList: emailDistributionReducer.companyList.list,
    distributionStatusList:
      emailDistributionReducer.distributionStatusList.list,
    searchStatusForm:
      form.distributionStatusForm && form.distributionStatusForm.values
        ? form.distributionStatusForm.values
        : {}
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(EmailDistributionStatusContainer)
);
