import React from "react";
import PropTypes from "prop-types";
import "../css/react-select.css";
import { lexicographicalSort, getLanguage } from "../util/common";
import { Tooltip } from "./Tooltip";
import {StyledSelect, Option} from "./SearchableSelect";

export const sortFunction = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return lexicographicalSort(a.label, b.label);
};

class SearchableSelectNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: getLanguage(),
      selectedValue: this.props.input ? this.props.input.value : undefined,
      options:
        this.props.options != null
          ? !this.props.isSorted
            ? this.props.options.sort(sortFunction)
            : this.props.options
          : [],
    };
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  componentDidMount() {
    if (
      this.props.options &&
      this.props.selectIfOnlyOneOption &&
      this.props.options.length === 1
    ) {
      this.onSelectChange(this.props.options[0]);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input && nextProps.input.value && !this.state.selectedValue) {
      this.setState({ selectedValue: nextProps.input.value });
    } else if (
      this.state.selectedValue &&
      !(nextProps.input && nextProps.input.value)
    ) {
      this.setState({ selectedValue: null, lng: getLanguage() });
    }
    if (
      nextProps.options.length !== this.state.options.length ||
      this.props.forceUpdate ||
      this.state.lng !== getLanguage()
    ) {
      this.setState(
        {
          options: nextProps.options.sort(sortFunction),
          lng: getLanguage(),
        },
        () => {
          if (this.state.selectedValue) {
            this.setState({
              selectedValue: this.state.options.find((option) =>
                nextProps.input &&
                nextProps.input.value &&
                nextProps.input.value.value
                  ? option.value === nextProps.input.value.value
                  : option.value === this.state.selectedValue.value
              ),
            });
          }
        }
      );
    }

    if (
      this.props.selectIfOnlyOneOption &&
      nextProps.options.length === 1 &&
      (!this.state.selectedValue || this.state.selectedValue.value < 0)
    ) {
      this.onSelectChange(nextProps.options[0]);
    }
  }

  onSelectChange(value) {
    const { onChange } = this.props.input || this.props;
    if (onChange != null) onChange(value);

    this.setState({ selectedValue: value });

    if (this.props.onSelect != null) {
      this.props.onSelect(value);
    }
  }

  render() {
    const { name } = this.props.input || this.props;

    const placeholder = this.props.placeholder ? this.props.placeholder : this.props.t("select");

    const clearable =
      this.props.clearable !== undefined ? this.props.clearable : true;

    return (
      <StyledSelect
        capitalize={this.props.capitalize}
        searchable={this.props.searchable}
        hiddenToolTip={this.props.hiddenToolTip}
        name={name}
        placeholder={placeholder}
        value={this.state.selectedValue}
        optionRenderer={
          !this.props.hiddenToolTip
            ? (option) => (
                <Option
                  capitalize={this.props.capitalize}
                  hiddenToolTip={this.props.hiddenToolTip}
                >
                  {option.label}
                  {option.helpText != null && option.helpText.length > 0 ? (
                    <Tooltip left>
                      {option.helpText.replace(/<br>/gi, "\n")}
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Option>
              )
            : undefined
        }
        clearable={clearable}
        onChange={this.onSelectChange}
        filterOptions={(options, filterString) =>
          this.state.options.filter(
            (option) =>
              (option.label &&
                option.label
                  .toLowerCase()
                  .startsWith(filterString.toLowerCase())) ||
              (option.helpText != null &&
                option.helpText
                  .toLowerCase()
                  .startsWith(filterString.toLowerCase()))
          )
        }
        noResultsText={this.props.t("not_found")}
        {...this.props}
      />
    );
  }
}

SearchableSelectNew.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  searchable: PropTypes.bool,
  onSelect: PropTypes.func,
  t: PropTypes.func.isRequired,
};

SearchableSelectNew.defaultProps = {
  capitalize: false,
  hiddenToolTip: true,
  searchable: true,
  selectIfOnlyOneOption: false,
};

export default SearchableSelectNew;
