import React from "react";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import {
  DateBox,
  DropDownBox,
  InputBox,
  NoLabelInputBox,
  TextAreaBox,
  TimePickerBox,
  TooltipWrapper
} from "../../components";
import Button from "../../../components/Button";
import { FlexContainer } from "../../../components/FlexContainer";
import styled from "styled-components";
import { Modal } from "../../../components/Modal";
import Label from "../../../components/Label";
import CheckBox from "../../../components/CheckBox";
import { InsiderType } from "../../InsiderToolActions";
import { connect } from "react-redux";
import { getUtcDateTimeLabelFromFormValues } from "../../UTCUtils";
import { Tooltip } from "../../../components/Tooltip";
import { HelpIcon } from "../../../components/HelpIcon";
import i18n from "../../../i18n";
import SearchableSelect from "../../../components/SearchableSelect";
import { ErrorText } from "../../../components/ErrorText";

const Header = styled.span`
  font-size: 18px;
  font-weight: 600;
`;

const ButtonRow = ({
  onCancel,
  onSubmit,
  onSubmitText,
  onSubmitNotify,
  onSubmitNotifyText,
  onDelete,
  onDeleteText,
  t,
  isNewInsider
}) => {
  return (
    <FlexContainer vAlignCenter row>
      {onDelete ? (
        <Button.Standard margin={[0, 20, 0, 0]} onClick={onDelete} inverted>
          {t(onDeleteText)}
        </Button.Standard>
      ) : (
        undefined
      )}

      <Field
        component={CheckBox}
        label={t("it_do_not_update_time_on_save")}
        name={"dontUpdateTimeOnSave"}
        fontSize={14}
      />

      <Button.Standard margin={[0, 20, 0, 20]} onClick={onSubmit} inverted>
        {t(onSubmitText)}
      </Button.Standard>
      {isNewInsider ? (
        <Button.Standard onClick={onSubmitNotify}>
          {t(onSubmitNotifyText)}
        </Button.Standard>
      ) : (
        undefined
      )}
    </FlexContainer>
  );
};

const ErrorMessage = props => {
  const { error } = props.meta;

  return error ? (
    <FlexContainer>
      <ErrorText>{error}</ErrorText>
    </FlexContainer>
  ) : (
    ""
  );
};

class InsiderPerson extends React.Component {
  render() {
    const {
      t,
      isOpen,
      handleSubmit,
      onSubmit,
      onClose,
      onSubmitText,
      onSubmitNotify,
      onSubmitNotifyText,
      onDelete,
      onDeleteText,
      header,
      listType,
      knownPersons,
      onSelectKnownPerson,
      resetForm,
      isNewInsider,
      countryOptions
    } = this.props;

    const headerElement = (
      <FlexContainer row center>
        {t(header)}
        <HelpIcon
          margin={[8, 0, -3, 5]}
          fileName={
            listType === InsiderType.LIST
              ? "personalia_insider"
              : "personalia_person"
          }
          language={i18n.language}
          insiderToolHelp
          height={"18"}
          width={"18"}
        />
      </FlexContainer>
    );

    return (
      <Modal
        xScroll
        width={"65%"}
        isOpen={isOpen}
        onClose={onClose}
        header={headerElement}
      >
        <form style={{ width: "100%" }} autoComplete="off">
          <FlexContainer column>
            <FlexContainer column margin={[0, 0, 12, 0]}>
              {isNewInsider && knownPersons ? (
                <FlexContainer row>
                  <FlexContainer>
                    <FlexContainer width={"50%"}>
                      <Field
                        options={knownPersons}
                        t={t}
                        onChange={onSelectKnownPerson}
                        name={"selectedKnownPersonId"}
                        component={SearchableSelect}
                        placeholder={t("it_select_insider_if_exists")}
                      />
                    </FlexContainer>
                  </FlexContainer>
                  <FlexContainer row vAlignCenter flexEnd>
                    <Button.Standard
                      margin={[0, 8, 0, 0]}
                      onClick={resetForm}
                      inverted
                    >
                      {t("it_reset_form")}
                    </Button.Standard>
                  </FlexContainer>
                </FlexContainer>
              ) : (
                undefined
              )}

              <FlexContainer width={"50%"}>
                <Label bold>{t("phonenumber_for_receival_mfa")}:</Label>
                <FlexContainer row margin={[5, 0, 0, 0]}>
                  <Field
                    component={DropDownBox}
                    label={t("choose_area_code")}
                    t={t}
                    options={countryOptions}
                    searchable={true}
                    placeholder={t("select")}
                    name={"twoFactorCountryCode"}
                  />
                  <Field
                    component={InputBox}
                    label={t("telephonenumber")}
                    name={"twoFactorPhone"}
                  />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
            <Header>{t("it_personalia")}</Header>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="first_name"
                label={t("first_name")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_first_name_insider_tooltip")
                    : undefined
                }
              />
              <Field
                component={InputBox}
                name="surname"
                label={t("surname")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_surname_insider_tooltip")
                    : undefined
                }
              />
              <Field
                component={InputBox}
                name="birth_surname"
                label={t("it_birth_surname")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_birth_surname_insider_tooltip")
                    : t("it_birth_surname_conf_tooltip")
                }
              />
              <Field
                component={InputBox}
                name="email"
                label={t("email")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_email_insider_tooltip")
                    : t("it_email_conf_tooltip")
                }
              />
            </FlexContainer>
            <FlexContainer row margin={[0, 0, 5, 0]}>
              <FlexContainer column margin={[0, 8, 0, 0]}>
                <Field
                  component={InputBox}
                  name="phone_private"
                  label={t("it_phone_private")}
                  tooltip={t("it_personal_telephone_number_tooltip")}
                />
                <Field
                  component={NoLabelInputBox}
                  name="phone_private_2"
                  margin={[5, 0, 0, 0]}
                />
              </FlexContainer>
              <FlexContainer column margin={[0, 8, 0, 0]}>
                <Field
                  component={InputBox}
                  name="phone_work"
                  label={t("it_phone_work")}
                  tooltip={t("it_professional_phone_tooltip")}
                />
                <Field
                  component={NoLabelInputBox}
                  name={"phone_work_2"}
                  margin={[5, 0, 0, 0]}
                />
              </FlexContainer>
              <Field
                component={DateBox}
                name="date_of_birth"
                border
                label={t("it_date_of_birth")}
                numPreviousYears={100}
                tooltip={t("it_date_of_birth_tooltip")}
              />
              <Field
                component={InputBox}
                name="idnumber"
                label={t("it_idnumber")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_national_id_number_insider_tooltip")
                    : t("it_national_id_number_tooltip")
                }
              />
            </FlexContainer>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="address"
                label={t("address")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_full_home_address_insider_tooltip")
                    : t("it_full_home_address_conf_tooltip")
                }
              />
              <Field
                component={InputBox}
                name="post_code"
                label={t("it_post_code")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_full_home_address_insider_tooltip")
                    : t("it_full_home_address_conf_tooltip")
                }
              />
              <Field
                component={InputBox}
                name="city"
                label={t("it_city")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_full_home_address_insider_tooltip")
                    : t("it_full_home_address_conf_tooltip")
                }
              />
              <Field
                component={DropDownBox}
                name="country"
                label={t("country")}
                t={t}
                options={countryOptions}
                searchable={true}
                placeholder={this.props.t("select")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_full_home_address_insider_tooltip")
                    : t("it_full_home_address_conf_tooltip")
                }
              />
            </FlexContainer>

            <Label margin={[24, 0, 0, 0]} bold>
              {t(
                listType === InsiderType.LIST
                  ? "information_regarding_issuer"
                  : "information_regarding_issuer_conf"
              )}
            </Label>
            <FlexContainer row>
              <Field
                component={InputBox}
                name="company_name"
                label={t("it_company_name")}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_company_name_insider_tooltip")
                    : t("it_company_name_conf_tooltip")
                }
              />
              <Field
                component={InputBox}
                name="insider_function_and_reason"
                label={t(
                  listType === InsiderType.LIST
                    ? "it_insider_function_and_reason"
                    : "it_insider_function_and_reason_conf"
                )}
                tooltip={
                  listType === InsiderType.LIST
                    ? t("it_function_and_reason_insider_tooltip")
                    : t("it_function_and_reason_conf_tooltip")
                }
              />
            </FlexContainer>
            <FlexContainer>
              <TooltipWrapper helpCursor>
                <Label maxWidth margin={[12, 0, -4, 0]} tooltip>
                  {t("it_company_address")}
                </Label>
                <Tooltip>
                  {listType === InsiderType.LIST
                    ? t("it_company_address_insider_tooltip")
                    : t("it_company_address_conf_tooltip")}
                </Tooltip>
              </TooltipWrapper>
              <FlexContainer row>
                <Field
                  component={InputBox}
                  name="company_address"
                  label={t("it_company_street_name_and_number")}
                />
                <Field
                  component={InputBox}
                  name="company_post_code"
                  label={t("it_post_code")}
                />
                <Field
                  component={InputBox}
                  name="company_city"
                  label={t("it_city")}
                />
                <Field
                  component={DropDownBox}
                  name="company_country"
                  label={t("country")}
                  t={t}
                  options={countryOptions}
                  searchable={true}
                  placeholder={this.props.t("select")}
                />
              </FlexContainer>
            </FlexContainer>

            <FlexContainer row margin={[0, 0, 20, 0]}>
              <FlexContainer column>
                <TooltipWrapper helpCursor>
                  <Label margin={[24, 0, 0, 0]} bold tooltip>
                    {listType === InsiderType.LIST
                      ? t("it_obtained_insider_info")
                      : t("it_obtained_conf_info")}
                  </Label>
                  <Field
                    component={ErrorMessage}
                    name="obtainedTimeDateError"
                  />
                  <Tooltip>
                    {listType === InsiderType.LIST
                      ? t("it_obtained_insider_tooltip")
                      : t("it_obtained_conf_tooltip")}
                  </Tooltip>
                </TooltipWrapper>
                <FlexContainer row>
                  <Field
                    component={DateBox}
                    border
                    name="obtained_date"
                    label={
                      t("date") +
                      " " +
                      getUtcDateTimeLabelFromFormValues(
                        this.props.insiderPersonFormValues,
                        "obtained_date",
                        "obtained_time"
                      )
                    }
                  />
                  <Field
                    component={TimePickerBox}
                    name="obtained_time"
                    label={t("time")}
                  />
                </FlexContainer>
                {!isNewInsider ? (
                  <FlexContainer row margin={[0, 0, 20, 0]}>
                    <FlexContainer column>
                      <TooltipWrapper helpCursor>
                        <Label margin={[24, 0, 0, 0]} bold tooltip>
                          {listType === InsiderType.LIST
                            ? t("it_ceased_insider_info")
                            : t("it_ceased_conf_info")}
                        </Label>
                        <Field
                          component={ErrorMessage}
                          name="ceasedTimeDateError"
                        />
                        <Tooltip>
                          {listType === InsiderType.LIST
                            ? t("it_ceased_insider_tooltip")
                            : t("it_ceased_conf_tooltip")}
                        </Tooltip>
                      </TooltipWrapper>
                      <FlexContainer row>
                        <Field
                          component={DateBox}
                          border
                          name="ceased_date"
                          label={
                            t("date") +
                            " " +
                            getUtcDateTimeLabelFromFormValues(
                              this.props.insiderPersonFormValues,
                              "ceased_date",
                              "ceased_time"
                            )
                          }
                          disableDaysAfter={new Date()}
                        />
                        <Field
                          component={TimePickerBox}
                          name="ceased_time"
                          label={t("time")}
                        />
                      </FlexContainer>
                    </FlexContainer>
                  </FlexContainer>
                ) : (
                  undefined
                )}
              </FlexContainer>

              <FlexContainer margin={[8, 0, 8, 0]} column width={"90%"}>
                <Label margin={[24, 0, 0, 0]} bold>
                  {t("comment")}
                </Label>
                <Field
                  component={TextAreaBox}
                  border
                  name="comment"
                  label={t("it_write_comment")}
                  noResize
                  tooltip={
                    listType === InsiderType.LIST
                      ? t("it_comments_insider_tooltip")
                      : t("it_comments_conf_tooltip")
                  }
                />
              </FlexContainer>
            </FlexContainer>
            <FlexContainer margin={[8, 0, 16, 0]}>
              <ButtonRow
                t={t}
                onSubmit={handleSubmit(onSubmit)}
                onSubmitNotify={
                  onSubmitNotify ? handleSubmit(onSubmitNotify) : undefined
                }
                onCancel={onClose}
                onSubmitText={onSubmitText}
                onSubmitNotifyText={onSubmitNotifyText}
                onDelete={onDelete}
                onDeleteText={onDeleteText}
                isNewInsider={isNewInsider}
              />
            </FlexContainer>
          </FlexContainer>
        </form>
      </Modal>
    );
  }
}
InsiderPerson = reduxForm({
  form: "insiderPerson"
})(InsiderPerson);

function mapStateToProps(state) {
  const { form } = state;

  return {
    insiderPersonFormValues: form.insiderPerson
      ? form.insiderPerson.values
      : undefined
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderPerson)
);
