import fetch from "cross-fetch";
import jwt_decode from "jwt-decode";

import React from "react";
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { OBNTDateTimeFormat } from "./util/common";
import i18next from "i18next";

export const TOKEN_KEY = "ob-jwt-token";
export const CAS_LOGIN_KEY = "cas-login";
export var requestLogger = [];

const P = styled.p`
  white-space: pre;
`;
const LogEntry = (props) => <P>{props.children}</P>;
var logEntry = "";

export const doRequest = (
  command,
  data,
  convertToJSON = true,
  showServerError = true,
  returnFullResponse = false
) => {
  const headers = new fetch.Headers();

  if (convertToJSON) {
    headers.append("Content-Type", "application/json");
  }

  const token = getToken();
  if (token) {
    headers.append("Authorization", "Bearer " + token);
  }

  logEntry = "/obsvc/" + command + "\n";

  return fetch("/obsvc/" + command, {
    method: "POST",
    body: convertToJSON ? JSON.stringify(data || {}) : data,
    headers: headers,
  }).then((response) =>
    onResponseResolved(response, showServerError, returnFullResponse)
  );
};

var globalCounter = 0;
const logResponse = (response, responsJSON) => {
  const newEntry = (
    <LogEntry key={globalCounter++}>
      {moment().format(OBNTDateTimeFormat + ":ss") +
        "\n" +
        logEntry +
        "response status: " +
        response.status +
        "\n" +
        "RequestID: " +
        responsJSON.header.OB_REQUEST_ID}
    </LogEntry>
  );

  requestLogger =
    requestLogger.length >= 25 ? requestLogger.slice(1) : requestLogger;
  requestLogger.push(newEntry);
};
const logResponse500 = (response, error) => {
  const newEntry = (
    <LogEntry key={globalCounter++}>
      {moment().format(OBNTDateTimeFormat + ":ss") +
        "\n" +
        logEntry +
        "response status: " +
        response.status +
        "\n" +
        "ERROR: " +
        error}
    </LogEntry>
  );

  requestLogger =
    requestLogger.length >= 25 ? requestLogger.slice(1) : requestLogger;
  requestLogger.push(newEntry);
};

const ErrorText = styled.span`
  white-space: ${(props) => (props.err500 ? "normal;" : "pre-wrap;")};
  word-wrap: break-word;
`;
const onResponseResolved = (response, showServerError, returnFullResponse) => {
  if (response.status === 500) {
    response.text().then((err) => {
      var stripedErr = err.replace(/<[^>]+>/g, "\n");
      logResponse500(response, stripedErr);

      if (showServerError)
        NotificationManager.error(
          <ErrorText err500>{stripedErr}</ErrorText>,
          "500 Server Error",
          0
        );
    });

    throw response.status;
  } else if (response.status !== 200 && response.status !== 202) {
    return response.json().then((rJson) => {
      logResponse(response, rJson);

      if (showServerError) {
        const errorString =
          rJson.data.details != null &&
          rJson.data.details.reduce(
            (acc, val) => (acc += `${val.message + "\n\n"}`),
            ""
          );
        NotificationManager.error(
          <ErrorText>{errorString}</ErrorText>,
          i18next.t("server_error"),
          0
        );
      }

      throw rJson;
    });
  } else {
    if (!response._bodyBlob.type.includes("application/json"))
      return response._bodyBlob;

    return response.json().then((obsvcResponse) => {
      logResponse(response, obsvcResponse);
      if (returnFullResponse) {
        return obsvcResponse;
      }
      return obsvcResponse.data;
    });
  }
};

export const setToken = (token) => {
  if (sessionStorage) {
    sessionStorage.setItem(TOKEN_KEY, token);
  }
};
export const deleteToken = () => {
  if (sessionStorage) {
    return sessionStorage.removeItem(TOKEN_KEY);
  }
};
export const getToken = () => {
  if (sessionStorage) {
    return sessionStorage.getItem(TOKEN_KEY);
  }
};

export const setCASLogin = () => {
  if (sessionStorage) {
    sessionStorage.setItem(CAS_LOGIN_KEY, "true");
  }
};
export const deleteCASLogin = () => {
  if (sessionStorage) {
    return sessionStorage.removeItem(CAS_LOGIN_KEY);
  }
};
export const getCASLogin = () => {
  if (sessionStorage) {
    return sessionStorage.getItem(CAS_LOGIN_KEY);
  }
};
export const getUserName = () => {
  const username = (token) => (token != null ? jwt_decode(token).login : "");

  return username(getToken());
};

export const getGlobalSubject = () => {
  const globalSubject = (token) => (token != null ? jwt_decode(token).sub : "");
  return globalSubject(getToken());
};
