import React from "react";

import { StyledNewsMessage } from "./StyledNewsMessage";
import { HeaderPanel } from "../components/HeaderPanel";
import { FlexContainer } from "../components/FlexContainer";
import Button from "../components/Button";
import styled from "styled-components";
import {ConfirmModal} from "../components/ConfirmModal";
import RadioButton from "../components/RadioButton";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
  fetchAttachmentList,
  fetchChannels,
  republishMessage,
  updateAttachments
} from "./MessageActions";
import {NotificationManager} from "react-notifications";
import Link from "../components/Link";
import RenameAttachmentsModal from "./RenameAttachmentsModal";
import {OverlayLoader} from "../components/Loader";

const DeletePanel = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.spaceBetween ? "space-between;" : "flex-end;"};
  flex-direction: row;

  grid-row: 2/3;
  grid-column: 2/3;

  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;

  padding: 10px 0 0 0;
`;

const Icon = styled.img`
  cursor: pointer;
  padding: 0px 5px 0 5px;
  margin-right: 8px;
  box-sizing: border-box;
`;

const NavigationPanel = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  grid-row: 2/3;
  grid-column: 1/2;

  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;

  padding: 10px 0 0 0;
`;

const WarningText = styled.div`
  white-space: pre-wrap;
`;

const RadioButtonChannelGroup = props => {
  return (
      <FlexContainer row margin={[12, 0, 20, 0]} vAlignCenter>
        {props.channels.map((channel, index) => (
            <RadioButton
            {...props}
            key={index}
            margin={[0, 0, 8, 10]}
            radioValue={channel}
            checked={props.selected === channel}
            label={props.t("republish_" + channel)}
        />
        ))}
      </FlexContainer>
  );
};

/**
 * Displays details about a news message inline in the result table.
 * @param {*} props
 */
class NewsMessage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showRepublishModal: false,
      showEditAttachmentsNameModal: false,
      channel: "",
      loading: false
    };
  }

  confirmRepublish = () => {
    const msgObj = { id: this.props.messageId, channel: this.state.channel }
    this.setState({ loading: true });
    this.props.dispatch(republishMessage(msgObj))
        .then(() => {
          this.setState({ showRepublishModal: false, channel: "", loading: false });
          NotificationManager.success(
              this.props.t("success")
          );
        })
        .catch((e) => {
          console.log(e)
        })
  }

  confirmUpdateFileName = (atts) => {
    if (atts.length > 0) {
      this.props.dispatch(updateAttachments(atts))
          .then(() => {
            this.setState({ showEditAttachmentsNameModal: false });
          })
          .then(() => {
            this.props.dispatch(fetchAttachmentList(atts[0].newsId))
          })
          .catch((e) => {
            console.log(e)
          })
    }
  }

  render() {
    const props = this.props;
    const { t } = props;

    return (
      <FlexContainer>
        {this.state.loading ? <OverlayLoader /> : undefined}
        <HeaderPanel>{props.messageURL}</HeaderPanel>
        <StyledNewsMessage.ContentPanel>
          <StyledNewsMessage.AttributesPanel
            expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
            attachmentboxIsVisible={props.attachmentList.length > 0}
          >
            <StyledNewsMessage.InfoBox>
              <StyledNewsMessage.Header>{t("status")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.status}</StyledNewsMessage.Text>
            </StyledNewsMessage.InfoBox>
            <StyledNewsMessage.TimeBox>
              <StyledNewsMessage.Header>{t("time")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.publishedTime}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.TimeBox>

            <StyledNewsMessage.MessageIdBox>
              <StyledNewsMessage.Header>{t("issuer")}</StyledNewsMessage.Header>

              <StyledNewsMessage.Text>
                {props.companyName}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.MessageIdBox>

            <StyledNewsMessage.IssuerBox role="contentinfo">
              <StyledNewsMessage.Header>
                {t("issuerId")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.companySymbol}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.IssuerBox>

            {props.instrumentName ? (
              <StyledNewsMessage.IntrumentBox
                visible={props.instrumentName != null}
                role="contentinfo"
              >
                <StyledNewsMessage.Header>
                  {t("instrument")}
                </StyledNewsMessage.Header>
                <StyledNewsMessage.Text>
                  {props.instrumentName}
                </StyledNewsMessage.Text>
              </StyledNewsMessage.IntrumentBox>
            ) : undefined}

            <StyledNewsMessage.MarketBox role="contentinfo">
              <StyledNewsMessage.Header>{t("market")}</StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.exchanges}</StyledNewsMessage.Text>
            </StyledNewsMessage.MarketBox>

            <StyledNewsMessage.CorrectedVersions
              visible={props.hasCorrections}
              role="contentinfo"
            >
              <StyledNewsMessage.Header>
                {t("corrected_versions")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>
                {props.correctedVersionsLinks}
              </StyledNewsMessage.Text>
            </StyledNewsMessage.CorrectedVersions>

            <StyledNewsMessage.CategoryBox
              role="contentinfo"
              instrumentIsVisible={props.instrumentName != null}
              attachmentboxIsVisible={props.attachmentList.length > 0}
              expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
            >
              <StyledNewsMessage.Header>
                {t("category")}
              </StyledNewsMessage.Header>
              <StyledNewsMessage.Text>{props.category}</StyledNewsMessage.Text>
              <StyledNewsMessage.List>
                {props.messageInfoList}
              </StyledNewsMessage.List>
            </StyledNewsMessage.CategoryBox>


            {props.attachmentList.length > 0 &&
            <FlexContainer>
              <StyledNewsMessage.AttatchmentBox
                  expandedGrid={props.hasMultipleLanguages || props.hasCorrections}
              >
                <StyledNewsMessage.List>
                  {(props.attachmentList || []).map((file, index) => (
                      <StyledNewsMessage.FileLi
                          key={index}
                          onClick={() => props.getAttachment(file.attId)}
                          onClickDelete={() => props.deleteAttachment(file)}
                          // showDeleteButton={props.showAttachmentButton}
                          onlyPDFIcon={this.props.onlyPDFIcon}
                      >
                        {file.attName}
                      </StyledNewsMessage.FileLi>
                  ))}
                </StyledNewsMessage.List>
              </StyledNewsMessage.AttatchmentBox>

              {(this.props.showRenameAttachments &&
                  this.props.messageStatus === "PUBLISHED") && (
                  <FlexContainer textAlign={"right"}>
                    <Link
                        fontSize={"14"}
                        onClick={() => this.setState({showEditAttachmentsNameModal: true})}>
                      {t("edit_filename")}
                    </Link>
                  </FlexContainer>
              )}
            </FlexContainer>
            }

          </StyledNewsMessage.AttributesPanel>
          <StyledNewsMessage.Message>
            <StyledNewsMessage.MessageBodyHeadline>
              {props.title}
            </StyledNewsMessage.MessageBodyHeadline>
            <StyledNewsMessage.MessageBodyText
              textFormat={props.textFormat}
              role="document"
            >
              {props.body}
            </StyledNewsMessage.MessageBodyText>
          </StyledNewsMessage.Message>
          <NavigationPanel>
            <FlexContainer row vAlignCenter onClick={props.onCancel}>
              <Icon src="/icons/back-arrow-24-px.svg" />
              <Button.Text>{t("back")}</Button.Text>
            </FlexContainer>
          </NavigationPanel>
          <DeletePanel
            spaceBetween={
              this.props.showEditPublished &&
              this.props.messageStatus === "PUBLISHED" &&
              this.props.showDeleteButton
            }
          >
            <FlexContainer row>
              {(this.props.showEditPublished &&
                  this.props.messageStatus === "PUBLISHED") && (
                  <Button.Standard
                      onClick={() => {
                        this.props.onSuperEdit(this.props.id, this.props.cid);
                      }}
                  >
                    {t("edit")}
                  </Button.Standard>
              )}
              {(this.props.showRepublish &&
                  this.props.messageStatus === "PUBLISHED") && (
                  <Button.Standard
                      margin={[0,0,0,10]}
                      onClick={() => {
                        this.props.dispatch(fetchChannels()).then(() => {
                          if (this.props.channels.length > 0) {
                            this.setState({ showRepublishModal: true, channel: this.props.channels[0] });
                          } else {
                            NotificationManager.error(t("republish_channels_error"), "", 0);
                          }
                        })
                      }}
                  >
                    {t("republish")}
                  </Button.Standard>
              )}
            </FlexContainer>

            {this.props.showDeleteButton && (
              <Button.Standard onClick={props.onDeleteMessage}>
                {t("delete_message")}
              </Button.Standard>
            )}
          </DeletePanel>
        </StyledNewsMessage.ContentPanel>
        {this.state.showRepublishModal &&
            <ConfirmModal
                center
                header={t("republish")}
                isOpen={this.state.showRepublishModal}
                onClose={() => this.setState({ showRepublishModal: false })}
                confirmText={t("confirm")}
                cancelText={t("cancel")}
                onConfirm={() => {this.confirmRepublish()}}
            >
              <FlexContainer>
                <p>
                  {t("republish_confirm", {
                    title: this.props.title,
                  })}
                </p>
                <Field
                    name={"channel"}
                    component={RadioButtonChannelGroup}
                    t={props.t}
                    channels={this.props.channels}
                    selected={this.state.channel}
                    onChange={(e) => this.setState({ channel: e })}
                />
              </FlexContainer>
            </ConfirmModal>
        }

        {this.state.showEditAttachmentsNameModal &&
            <RenameAttachmentsModal
              list={props.attachmentList}
              t={props.t}
              onConfirm={(e) => { this.confirmUpdateFileName(e)} }
              onClose={() => this.setState({ showEditAttachmentsNameModal: false })}
              isOpen={this.state.showEditAttachmentsNameModal}
            />
        }

      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const {
    messageReducer
  } = state;

  return {
    channels: messageReducer.channels.list
  }
}
export default reduxForm({
  form: "newsMessage",
  destroyOnUnmount: false,
})(connect(mapStateToProps)(NewsMessage));