import moment from "moment";
import { isSameOrBefore } from "./FinanskalenderContainer";

export const noCalendarEventDates = calendarObj => {
  return (
    periodHasNoEvents(calendarObj.pastPeriod) &&
    periodHasNoEvents(calendarObj.currentPeriod) &&
    periodHasNoEvents(calendarObj.futurePeriod)
  );
};

export const noFutureEvents = calendarObj => {
  return (
    periodHasNoFutureEvents(calendarObj.pastPeriod) &&
    periodHasNoFutureEvents(calendarObj.currentPeriod) &&
    periodHasNoFutureEvents(calendarObj.futurePeriod)
  );
};

export const someDatesAreUndefined = calendarObj => {
  let hasInvalidDates = listHasInvalidDate(
    calendarObj.pastPeriod.requiredEvents,
    false
  );
  hasInvalidDates = listHasInvalidDate(
    calendarObj.currentPeriod.requiredEvents,
    hasInvalidDates
  );
  hasInvalidDates = listHasInvalidDate(
    calendarObj.futurePeriod.requiredEvents,
    hasInvalidDates
  );
  hasInvalidDates = listHasInvalidDate(
    calendarObj.pastPeriod.optionalEvents,
    hasInvalidDates
  );
  hasInvalidDates = listHasInvalidDate(
    calendarObj.currentPeriod.optionalEvents,
    hasInvalidDates
  );
  hasInvalidDates = listHasInvalidDate(
    calendarObj.futurePeriod.optionalEvents,
    hasInvalidDates
  );

  return hasInvalidDates;
};

const periodHasNoEvents = period => {
  let hasNoEvents = true;
  if (period.requiredEvents) {
    period.requiredEvents.forEach(event => {
      if (event.eventDate) {
        hasNoEvents = false;
      }
    });
  }
  if (period.optionalEvents) {
    period.optionalEvents.forEach(event => {
      if (event.eventDate) {
        hasNoEvents = false;
      }
    });
  }
  return hasNoEvents;
};

const periodHasNoFutureEvents = period => {
  let hasNoFutureEvents = true;

  if (period.requiredEvents) {
    period.requiredEvents.forEach(event => {
      if (event.eventDate) {
        const formattedDate = getFormattedDate(event.eventDate);
        if (isSameOrBefore(formattedDate)) {
          hasNoFutureEvents = false;
        }
      }
    });
  }

  if (period.optionalEvents) {
    period.optionalEvents.forEach(event => {
      if (event.eventDate) {
        const formattedDate = getFormattedDate(event.eventDate);
        if (isSameOrBefore(formattedDate)) {
          hasNoFutureEvents = false;
        }
      }
    });
  }
  return hasNoFutureEvents;
};

const getFormattedDate = date => {
  if (moment(date, "DD.MM.YYYY", true).isValid()) {
    return moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
  } else {
    return date;
  }
};

const listHasInvalidDate = (eventList, hasInvalidDates = false) => {
  if (!hasInvalidDates && eventList && eventList.length > 0) {
    eventList.forEach(event => {
      if (
        event.eventDate &&
        !moment(event.eventDate, "YYYY-MM-DD", true).isValid()
      ) {
        hasInvalidDates = true;
      }
    });
  }
  return hasInvalidDates;
};
