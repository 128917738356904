import React from "react";
import { Field, reduxForm } from "redux-form";
import { FlexContainer } from "../components/FlexContainer";
import { PageHeading } from "../components/PageHeading";
import { ErrorText } from "../components/ErrorText";
import Button from "../components/Button";
import Input from "../components/Input";
import Label from "../components/Label";
import SearchableSelect from "../components/SearchableSelect";
import PasswordRules from "./PasswordRules";

const InputBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column maxWidth={295} margin={[0, 8, 0, 0]}>
      <Label color={"white"} htmlFor={props.input.name} margin={[0, 0, 0, 0]}>
        {props.label}
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <Input
        margin={[0, 4, 0, 0]}
        id={props.input.name}
        {...props.input}
        {...props}
      />
    </FlexContainer>
  );
};

export const SearchDropDownWlabel = props => {
  const { error } = props.meta;
  return (
    <FlexContainer column margin={[0, 8, 0, 0]}>
      <Label
        color={"white"}
        htmlFor={props.name}
        maxWidth={185}
        margin={[8, 8, 3, 0]}
        {...props}
      >
        {props.label}:
      </Label>
      {error ? <ErrorText>{error}</ErrorText> : undefined}
      <SearchableSelect
        {...props}
        name={props.name}
        placeholder={props.placeholder}
        searchable={true}
        options={props.options}
        t={props.t}
      />
    </FlexContainer>
  );
};

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      twoFactorMessageProtocol: undefined,
      twoFactorEnabled: undefined,
      twoFactorCountryCode: undefined,
      twoFactorPhone: undefined,
      twoFactorCodeRequested: true
    };
  }

  render() {
    const props = this.props;
    const { t } = this.props;

    const loginBoxMargin = props.underMaintenance
      ? [40, 0, 0, 0]
      : [75, 0, 0, 0];

    return (
      <FlexContainer maxWidth={767}>
        <form>
          <FlexContainer
            margin={loginBoxMargin}
            padding={[40, 40, 40, 40]}
            blueBackground
            vAlignCenter
            column
          >
            <FlexContainer maxWidth={295} center>
              <PageHeading margin={[0, 0, 40, 0]} uppercase color="white">
                {t("resetPasswordHeader")}
              </PageHeading>
            </FlexContainer>
            <FlexContainer maxWidth={295}>
              <FlexContainer vAlignCenter>
                <Field
                  name="password"
                  label={t("new_password")}
                  component={InputBox}
                  greyPlaceholder
                  maxWidth={460}
                  margin={[0, 0, 16, 0]}
                  placeholder={t("new_password")}
                  type="password"
                />

                <Field
                  name="repeat_password"
                  label={t("repeat_password") + ":"}
                  component={InputBox}
                  greyPlaceholder
                  maxWidth={460}
                  margin={[0, 0, 16, 0]}
                  placeholder={t("repeat_password")}
                  onKeyDown={e => {
                    switch (e.keyCode) {
                      case 13: //Enter
                        e.preventDefault();
                        const submitFunction = this.props.handleSubmit(
                          this.props.onSave
                        );
                        submitFunction();
                        break
                      default:
                    }
                  }}
                  type="password"
                />
                <FlexContainer maxWidth={295} margin={[10, 0, 0, 0]}>
                  <Button.Standard
                    onClick={props.handleSubmit(this.props.onSave)}
                  >
                    {t("save_password")}
                  </Button.Standard>
                </FlexContainer>

                <FlexContainer maxWidth={295} margin={[20, 0, 0, 0]}>
                  <PasswordRules />
                </FlexContainer>
              </FlexContainer>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}

export default reduxForm({
  form: "resetPassword"
})(ResetPasswordForm);
