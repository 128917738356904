import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import "../css/react-select.css";
import styled from "styled-components";
import { lexicographicalSort, getLanguage } from "../util/common";
import { Tooltip } from "./Tooltip";

const StyledSelect = styled(Select)`
  position: relative;
  width: 100%;
  font-size: 14px;

  .Select-control {
    ${(props) => (props.noBorder === true ? "border: 0px solid;" : "")};
  }

  .Select-value-label {
    ${(props) => (props.capitalize ? "text-transform: capitalize;" : "")};
  }

  ${(props) =>
    props.maxWidth
      ? "max-width: " + props.maxWidth + "px; width:100%;"
      : ""} ${(props) =>
    props.margin
      ? "margin: " +
        props.margin.reduce((acc, val) => (acc += val + "px "), "") +
        ";"
      : ""};
`;

const Option = styled.span`
  ${(props) => (props.capitalize ? "text-transform: capitalize;" : "")};

  &:hover ${Tooltip} {
    ${(props) =>
      props.hiddenToolTip === true
        ? "visibility: hidden;"
        : "visibility: visible;"};
  }
`;

export const sortFunction = (a, b) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return lexicographicalSort(a.label, b.label);
};

class SearchableSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lng: getLanguage(),
      selectedValue: this.props.input ? this.props.input.value : undefined,
      options:
        this.props.options != null
          ? !this.props.isSorted
            ? this.props.options.sort(sortFunction)
            : this.props.options
          : [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input && prevProps.input.value && !this.state.selectedValue) {
      this.setState({ selectedValue: prevProps.input.value });
    } else if (
      this.state.selectedValue &&
      !(prevProps.input && prevProps.input.value)
    ) {
      this.setState({ selectedValue: undefined, lng: getLanguage() });
    }
    if (
      prevProps.options.length !== this.state.options.length ||
      this.props.forceUpdate ||
      this.state.lng !== getLanguage()
    ) {
      this.setState(
        {
          options: prevProps.options.sort(sortFunction),
          lng: getLanguage(),
        },
        () => {
          if (this.state.selectedValue != null) {
            this.setState({
              selectedValue: this.state.options.find((option) =>
                prevProps.input != null &&
                prevProps.input.value != null &&
                prevProps.input.value.value != null
                  ? option.value === prevProps.input.value.value
                  : option.value === this.state.selectedValue
              ),
            });
          }
        }
      );
    }
  }

  render() {
    const { name, onChange } = this.props.input || this.props;

    const onSelectChange = (value) => {
      if (onChange != null) onChange(value);

      this.setState({ selectedValue: value });

      if (this.props.onSelect != null) {
        this.props.onSelect(value);
      }
    };

    return (
      <StyledSelect
        capitalize={this.props.capitalize}
        searchable={this.props.searchable}
        hiddenToolTip={this.props.hiddenToolTip}
        name={name}
        placeholder={this.props.placeholder}
        value={this.state.selectedValue}
        optionRenderer={
          !this.props.hiddenToolTip
            ? (option) => (
                <Option
                  capitalize={this.props.capitalize}
                  hiddenToolTip={this.props.hiddenToolTip}
                >
                  {option.label}
                  {option.helpText != null && option.helpText.length > 0 ? (
                    <Tooltip left>
                      {option.helpText.replace(/<br>/gi, "\n")}
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </Option>
              )
            : undefined
        }
        clearable={true}
        onChange={onSelectChange}
        filterOptions={(options, filterString) =>
          this.state.options.filter(
            (option) =>
              (option.label &&
                option.label
                  .toLowerCase()
                  .startsWith(filterString.toLowerCase())) ||
              (option.helpText != null &&
                option.helpText
                  .toLowerCase()
                  .startsWith(filterString.toLowerCase()))
          )
        }
        noResultsText={this.props.t("not_found")}
        {...this.props}
      />
    );
  }
}

SearchableSelect.propTypes = {
  options: PropTypes.arrayOf(Object).isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  searchable: PropTypes.bool,
  onSelect: PropTypes.func,
  t: PropTypes.func.isRequired,
};

SearchableSelect.defaultProps = {
  capitalize: false,
  hiddenToolTip: true,
  searchable: true,
};

export default SearchableSelect;
