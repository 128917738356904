import React from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
import "../css/pagination.css";
import { MAX_NUM_MESSAGES } from "../util/common";

const CenterText = styled.div`
  display: flex;
  padding: 0 0 20px 0;
  justify-content: center;
`;

export const MAX_RESULT_ON_PAGE = 25;

function WithPagination(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.pageRangeDisplayed = 4;

      this.state = {
        activePage: 1
      };

      this.keyHandler = this.keyHandler.bind(this);
      this.getLastPage = this.getLastPage.bind(this);
    }

    componentWillReceiveProps(nextProps) {
      // Reset pagination when we receive new data.
      this.setState({ activePage: 1 });
    }

    getLastPage() {
      return this.props.data != null
        ? this.props.data.length / MAX_RESULT_ON_PAGE
        : 1;
    }

    keyHandler(e) {
      if (
        e.keyCode === 39 &&
        e.shiftKey &&
        this.state.activePage < this.getLastPage()
      ) {
        this.setState({ activePage: this.state.activePage + 1 });
      } else if (e.keyCode === 37 && e.shiftKey && this.state.activePage > 1)
        this.setState({ activePage: this.state.activePage - 1 });
    }
    componentDidMount() {
      window.addEventListener("keydown", this.keyHandler);
    }
    componentWillUnmount() {
      window.removeEventListener("keydown", this.keyHandler);
    }
    render() {
      const sliceData = data =>
        data != null
          ? data.slice(
              MAX_RESULT_ON_PAGE * (this.state.activePage - 1),
              MAX_RESULT_ON_PAGE * this.state.activePage
            )
          : data;

      const { data } = this.props;
      const total = this.props.total !== undefined ? (this.props.total > 500 ? 500 : this.props.total) : (data.length > 0 ? data.length : 0);
      return [
        <WrappedComponent
          key="paginatedData"
          {...this.props}
          data={sliceData(data)}
        />,
        total > MAX_RESULT_ON_PAGE ? (
          <Pagination
            key="paginationModule"
            itemsCountPerPage={MAX_RESULT_ON_PAGE}
            activePage={this.props.page
                || this.state.activePage
              }
            totalItemsCount={total}
            onChange={(pageNumber) => {
             this.setState({ activePage: pageNumber }, () =>
                 this.props.onPageClick ? this.props.onPageClick(pageNumber, this.props.isDraftList) : undefined
             );
            }}
            pageRangeDisplayed={this.pageRangeDisplayed}
          />
        ) : (
          ""
        ),
        data != null &&
        this.state.activePage >= data.length / MAX_RESULT_ON_PAGE &&
        this.props.showOverLimitMessage &&
        data.length >= MAX_NUM_MESSAGES ? (
          <CenterText key="infoText">
            {this.props.t("maxResultReached")}
          </CenterText>
        ) : (
          ""
        )
      ];
    }
  };
}

export default WithPagination;
