import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {Redirect, Route, Switch} from "react-router-dom";
import {PageHeading} from "../components/PageHeading";
import {FlexContainer} from "../components/FlexContainer";
import Button from "../components/Button";
import MessageList from "../messages/MessageList";

import {
    fetchAttachment,
    fetchAttachmentList,
    fetchCategories,
    fetchCorrectionMessage,
    fetchDraftMessageCount,
    fetchDraftMessageList,
    fetchGlobalMessagePermission,
    fetchMessage,
    fetchMessageCount,
    fetchMessageList,
    fetchMessageParams,
    fetchMessagePermission,
    fetchSecurities,
    removeAttachment,
    removeNewsMessage,
    resetMessageData,
    updateNewsMessage,
    uploadAttachment,
} from "./MessageActions";
import NewsMessage from "../messages/Message";
import {ConfirmModal} from "../components/ConfirmModal";
import EditContainer from "./EditContainer";
import {fetchCompanyList} from "../actions/CommonActions";
import moment from "moment";
import Loader, {OverlayLoader} from "../components/Loader";
import WithPagination from "../components/PaginationContainer";
import {
    browserIsIE,
    getLanguage,
    getLanguages,
    getOBNTDateTime,
    OBNTDateTimeFormat,
    showFileFromServer,
} from "../util/common";
import {NotificationManager} from "react-notifications";
import {StyledNewsMessage} from "./StyledNewsMessage";
import styled from "styled-components";
import {destroy} from "redux-form";
import i18next from "i18next";
import {fetchDistributionLists} from "../emailDistribution/EmailDistributionActions";

const ErrorText = styled.span`
  white-space: ${(props) => (props.err500 ? "normal" : "pre-wrap")};
  word-wrap: break-word;
`;

export const NewsMessageAction = {
  LIST_MESSAGES: "list",
  LIST_DRAFTS: "drafts",
  EDIT: "edit",
  NEW: "new",
  VIEW: "view",
  CORRECTION: "correction",
};

const { LIST_MESSAGES, LIST_DRAFTS, VIEW, EDIT, CORRECTION, NEW } =
  NewsMessageAction;

const Link = styled.a`
  color: white;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  font-weight: 600;`;
/**
 * Higher order components must be defined outside render method, so
 * we define that message and draft list should be paginated outside the class here.
 */
const PaginatedMessageList = WithPagination(MessageList);
const PaginatedDraftList = WithPagination(MessageList);

export const uploadFile = (fileObj, newsId, dispatch) => {
  var formData = new FormData();

  formData.append("attachment", fileObj.file);
  formData.append("newsId", newsId);
  formData.append("filename", fileObj.fileName);

  return dispatch(uploadAttachment(formData)).then(({ data }) =>
    Promise.resolve(data.entity[0].attId)
  );
};

const subtractWeekdays = (date, days, holidays) => {
  const isholiday = (holidayDate) => date.isSame(holidayDate, "days");

  while (days > 0) {
    date.subtract(1, "days");
    // decrease "days" only if it's a weekday.
    if (holidays == null) break;
    else if (!holidays.find(isholiday)) days -= 1;
  }
  return date;
};

class NewsMessageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCorrectModal: false,
      messageId: undefined,
      showDeleteModal: false,
      draftPublished: "",
      messageToDelete: undefined,
      showDeleteAttachmentModal: false,
      attachmentToDelete: undefined,
      addedAttachment: false, // Flag to trigger re-render attachmentlist when user adds a new attachments,
      loading: false,
      overlayLoading: false,
      noIssuerAccess: false,
      page: 1,
      searchProps: {
        page: 1,
        cids: undefined,
        fromDate: undefined,
        toDate: undefined,
        usernamePublishedBy: undefined,
      }
    };
  }


  componentWillMount() {
    const re = new RegExp(`${this.props.base}/(${VIEW}|${EDIT})/(\\d+)`, "g");
    const newsId = re.exec(this.props.location.pathname);
    const { dispatch } = this.props;
    if (
      this.props.publicProps &&
      this.props.publicProps["global.module.emaildist"] &&
      this.props.publicProps["global.module.emaildist"] === "true"
    ) {
      dispatch(
        fetchDistributionLists(this.props.userLoggedIn.organisationId, false)
      ).catch((err) => console.log(err));
    }

    Promise.resolve(this.setState({ loading: true }))
      .then(() =>
        Promise.all([
          dispatch(fetchGlobalMessagePermission()),
          dispatch(fetchMessageParams()),
          dispatch(fetchCompanyList()),
        ])
      )
      .then(() => {
        if (newsId != null) {
          return dispatch(fetchMessage(newsId[2])).then((message) =>
            Promise.all([
              dispatch(
                fetchMessagePermission(newsId[2], message.data.entity.cid)
              ),
              dispatch(fetchAttachmentList(newsId[2])),
              dispatch(fetchSecurities(message.data.entity.cid)),
              dispatch(fetchCategories(message.data.entity.cid)),
            ])
          );
        }
        return this.props.dispatch(resetMessageData());
      })
      .then(() =>
        Promise.all([
          this.props.dispatch(fetchMessageCount()),
          this.props.dispatch(fetchDraftMessageCount()),
          this.props.dispatch(fetchMessageList()),
        ])
      )
      .catch((err) => {
        console.log(err);
        if (err.data && err.data.details) {
          const noIssuerAccess = err.data.details.find(
            (err) => err.code === 1143
          );
          if (noIssuerAccess) {
            this.setState({ noIssuerAccess: true });
          } else {
            const errorString =
              err.data != null &&
              err.data.details != null &&
              err.data.details.reduce(
                (acc, val) => (acc += `${val.message + "\n\n"}`),
                ""
              );
            NotificationManager.error(
              <ErrorText>{errorString}</ErrorText>,
              i18next.t("server_error"),
              0
            );
          }
        }
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { t, match, base } = this.props;
    const { params } = match;

    const setInitialSearchProps = () => {
      this.setState({ page: 1, searchProps: {
          page: 1,
          cids: undefined,
          fromDate: undefined,
          toDate: undefined,
          usernamePublishedBy: undefined,
        } })
    }

    const getAttachment = (attId) => {
      const attachmentNameArr = this.props.attachmentList.filter(
        (att) => att.attId === attId
      );
      this.setState({overlayLoading: true})

      this.props
        .dispatch(fetchAttachment(attId))
        .then((data) =>
          showFileFromServer(
            data,
            attachmentNameArr != null
              ? attachmentNameArr[0].attName
              : "attchment.pdf"
          )
        ).then(() => {
          this.setState({overlayLoading: false})
      })
    };

    const onPageClick = (page, draft) => {
      this.setState({ overlayLoading: true, page: page, searchProps: { ...this.state.searchProps, page: page} }, () =>
          new Promise((resolve) => {
            if (draft) {
              resolve(this.props.dispatch(fetchDraftMessageList(this.state.searchProps)));
            } else {
              resolve(this.props.dispatch(fetchMessageList(this.state.searchProps)));
            }
          })
              .then(() => this.setState({ overlayLoading: false })))

    }

    const onNewsClick = (id) => {
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(fetchMessage(id))
          .then((message) =>
            this.props.dispatch(
              fetchMessagePermission(id, message.data.entity.cid)
            )
          )
          .then(() => this.props.dispatch(fetchAttachmentList(id)))
          .then(() => this.setState({ loading: false }))
          .then(() => this.props.history.push(`${base}/${VIEW}/${id}`))
      );
    };

    const onDraftClick = (id) => {
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(fetchMessage(id))
          .then((message) =>
            this.props.dispatch(
              fetchMessagePermission(id, message.data.entity.cid)
            )
          )
          .then(() => this.props.dispatch(fetchAttachmentList(id)))
          .then(() => this.setState({ loading: false }))
          .then(() => this.props.history.push(`${base}/${VIEW}/${id}`))
      );
    };

    const onSuperEdit = (messageId, cid, newsMessageAction) => {
      //Destroy forms to enable reinitialize when correcting two or more different messages and using back-button in the browser
      this.props.dispatch(destroy("newNewsMessage"));
      this.props.dispatch(destroy("newMessageFormTwo"));
      this.setState({ loading: true }, () =>
        this.props
          .dispatch(fetchMessage(messageId))
          .then(() => this.setState({ loading: false }))
          .then(() => {
            this.props.dispatch(fetchSecurities(cid));
          })
          .then(() => {
            this.props.dispatch(fetchAttachmentList(messageId));
          })
          .then(() => this.props.dispatch(fetchCategories(cid)))
      );
      this.props.history.push(`${base}/${newsMessageAction}/${messageId}`);
    };

    const onEditMessage = (messageId, newsMessageAction) => {
      //Destroy forms to enable reinitialize when correcting two or more different messages and using back-button in the browser
      this.props.dispatch(destroy("newNewsMessage"));
      this.props.dispatch(destroy("newMessageFormTwo"));

      this.setState({ loading: true }, () =>
        new Promise((resolve) => {
          if (newsMessageAction === NewsMessageAction.CORRECTION) {
            resolve(this.props.dispatch(fetchCorrectionMessage(messageId)));
          }
          else {
            resolve(this.props.dispatch(fetchMessage(messageId)));
          }
        })
          .then(({ data }) => {
            this.props.dispatch(fetchSecurities(data.entity.cid));
            return data.entity;
          })
          .then((entity) => {
            this.props.dispatch(fetchAttachmentList(entity.id));
            return entity.cid;
          })
          .then((cid) => this.props.dispatch(fetchCategories(cid)))
          .then(() =>
            !this.props.companyListIsFetched
              ? this.props.dispatch(fetchCompanyList())
              : undefined
          )
          .then(() => this.setState({ loading: false }))
          .then(() =>
            this.props.history.push(`${base}/${newsMessageAction}/${messageId}`)
          )
            .catch((err) => {
              console.log(err.data.statusMessage)
              this.setState({ loading: false })
            })
      );
    };

    const onMessageDeleteSuccess = (draftPublished) => {
      if (draftPublished === "DRAFT") {
        this.props.dispatch(fetchDraftMessageCount()),
        this.props.dispatch(fetchDraftMessageList());
      } else {
        this.props.dispatch(fetchMessageList());
      }
    };

    const onMessageDeleteFailed = (error) => {
      console.log(error);
      NotificationManager.error(t("failed_to_delete_message"));
    };

    const deleteMessageConfirm = (message) => {
      this.setState({
        showDeleteModal: true,
        messageToDelete: message,
        draftPublished: message.messageStatus,
      });
    };

    const deleteMessage = (messageId) => {
      this.props
        .dispatch(removeNewsMessage(messageId))
        .then(() => onMessageDeleteSuccess(this.state.draftPublished))
        .then(() => {
          if (this.state.draftPublished === "DRAFT")
            this.props.history.push(`${base}/${LIST_DRAFTS}`);
          else this.props.history.push(`${base}/${LIST_MESSAGES}`);
        })
        .catch(onMessageDeleteFailed);
    };

    const deleteAttachment = (file) => {
      this.setState({
        showDeleteAttachmentModal: true,
        attachmentToDelete: file,
      });
    };

    const deleteAttachmentSuccess = (messageId) => {
      this.props.dispatch(fetchAttachmentList(messageId));
    };

    const deleteAttachmentFailed = (error) => {
      console.log(error);
      NotificationManager.error(t("failed_to_delete_attachment"));
    };

    const doRemoveAttachment = (attId, newsId) => {
      this.props.dispatch(
        removeAttachment(
          attId,
          () => deleteAttachmentSuccess(newsId),
          deleteAttachmentFailed
        )
      );
    };
    const conatinerWidth = () => {
      if (browserIsIE()) return "100%";

      if (
        params &&
        params.page === LIST_DRAFTS &&
        this.props.draftMessageList != null
      )
        return "auto";
      if (
        params &&
        params.page === LIST_MESSAGES &&
        this.props.messageList != null
      )
        return "auto";

      return "100%;";
    };

    const onMessageSearch = (values, isDraft) => {
      if (values === null) return Promise.reject;

      const cid = values.issuer != null ? values.issuer.value : undefined;
      const fromDate =
        values.fromDate != null
          ? moment
              .utc(values.fromDate, OBNTDateTimeFormat)
              .startOf("day")
              .toISOString()
          : undefined;
      const toDate =
        values.toDate != null
          ? moment
              .utc(values.toDate, OBNTDateTimeFormat)
              .endOf("day")
              .toISOString()
          : undefined;

      if (toDate && fromDate) {
        if (toDate < fromDate) {
          NotificationManager.info(t("message_not_valid_date_range"));
        }
      }
      const usernamePublishedBy = values.pub;


//update state
      this.setState({ overlayLoading: true, page: 1, searchProps: { ...this.state.searchProps,
          page: 1, cids: cid, fromDate: fromDate, toDate: toDate, usernamePublishedBy: usernamePublishedBy} }, () =>
          new Promise((resolve) => {
            if (isDraft) {
                resolve(this.props.dispatch(fetchDraftMessageCount({
                    cids: cid,
                    fromDate,
                    toDate,
                    usernamePublishedBy,
                  }))
                .then(() =>
                    resolve(this.props.dispatch(
                        fetchDraftMessageList({
                          cids: cid,
                          fromDate,
                          toDate,
                          usernamePublishedBy,
                        })
                    ))
                ))
            } else {
              resolve(this.props.dispatch(fetchMessageList({
                        cids: cid,
                        fromDate,
                        toDate,
                        usernamePublishedBy,
                      }))
                .then(() => resolve(this.props.dispatch(fetchMessageCount({
                      cids: cid,
                      fromDate,
                      toDate,
                      usernamePublishedBy,
                    }))
                )))
            }
          }).then(() => this.setState({ overlayLoading: false })))
    };

    const companyOptionList =
      this.props.companyList != null
        ? this.props.companyList.map((elm) => {
            return {
              label: elm.longName,
              helpText: elm.symbol,
              value: elm.cid,
              symbol: elm.symbol,
            };
          })
        : [];

    return (
      <FlexContainer column width={conatinerWidth()}>
        {this.state.loading ? (
          <Loader />
        ) : (
          <div>
            {this.state.overlayLoading ? (
              <OverlayLoader />
            ) : (
              <div>
                <FlexContainer
                  column
                  hidden={
                    params != null
                      ? [NEW, EDIT, CORRECTION, VIEW].includes(params.page)
                      : false
                  }
                >
                  <FlexContainer row spaceBetween>
                    <Button.Standard
                      onClick={() => {
                        //Destroy forms to enable reinitialize when correcting two or more different messages and using back-button in the browser
                        this.props.dispatch(destroy("newNewsMessage"));
                        this.props.dispatch(destroy("newMessageFormTwo"));
                        this.props.history.push(`${base}/${NEW}`);
                      }}
                    >
                      {t("new_message_announcement")}
                    </Button.Standard>
                    <FlexContainer width="auto" row>
                      <PageHeading
                        isFocusable
                        onClick={() => {
                          if (params.page !== LIST_MESSAGES) {
                            setInitialSearchProps()
                            this.props.dispatch(fetchMessageList())
                            this.props.history.push(`${base}/${LIST_MESSAGES}`);
                          }
                        }}
                        opacity={params.page === LIST_MESSAGES ? "1.0" : "0.5"}
                        underline={params.page === LIST_MESSAGES}
                        pointer={params.page !== LIST_MESSAGES}
                        margin={[0, 25, 25, 0]}
                        color={"#1c315b"}
                      >
                        {t("recent_messages")}
                      </PageHeading>

                      <PageHeading
                        isFocusable
                        opacity={params.page === LIST_DRAFTS ? "1.0" : "0.5"}
                        underline={params.page === LIST_DRAFTS}
                        pointer={params.page !== LIST_DRAFTS}
                        onClick={() => {
                          if (params.page !== LIST_DRAFTS) {
                            setInitialSearchProps()
                            this.props.dispatch(fetchDraftMessageList());
                            this.props.history.push(`${base}/${LIST_DRAFTS}`);
                          }
                        }}
                        margin={[0, 0, 0, 0]}
                        color={"#1c315b"}
                      >
                        {t("draft_and_prepared_messages") +
                          (this.props.messageDraftCount ? "(" + this.props.messageDraftCount + ")" : "(0)")}
                      </PageHeading>
                    </FlexContainer>
                  </FlexContainer>
                </FlexContainer>
              </div>
            )}

            <Switch>
              <Route
                path={`${base}/${LIST_MESSAGES}`}
                render={(props) => {
                  const messageIsOlderThanTwoDays = (message) => {
                    if (
                      this.props.globalPermissionList != null &&
                      this.props.globalPermissionList.some(
                        (perm) => perm === "news.allwaysCorrect"
                      )
                    )
                      return false;

                    const date = moment(message.publishedTime);
                    return date.isBefore(
                      subtractWeekdays(
                        moment(new Date()).endOf("day"),
                        2,
                        this.props.messageParams.holidays
                      )
                    );
                  };
                  return this.props.messageListIsFetched ||
                    this.props.messageListReceiveFailed ? (
                    <PaginatedMessageList
                      key="recent"
                      companyOptionList={companyOptionList}
                      data={this.props.messageList}
                      total={this.props.messageCount}
                      showOverLimitMessage={true}
                      onNewsClick={onNewsClick}
                      userLoggedIn={this.props.userLoggedIn}
                      onMessageSearch={onMessageSearch}
                      globalPermissionList={this.props.globalPermissionList}
                      publishedByLabel={t("publishedBy")}
                      publishedLabel={t("published")}
                      correctLabel={t("correct")}
                      onPageClick={onPageClick}
                      page={this.state.page}
                      correctionAllowed={(message) => {
                        return (
                          !message.correctedByMessage > 0 &&
                          message.financialCalendar === false &&
                          messageIsOlderThanTwoDays(message) === false &&
                          message.messageStatus !== "DELETED"
                        );
                      }}
                      correctionText={(message) => {
                        if (message.messageStatus === "DELETED") {
                          return t("edit_correction_deleted_message");
                        } else if (message.correctedByMessage > 0) {
                          return t("edit_correction_message");
                        } else if (messageIsOlderThanTwoDays(message)) {
                          return t("edit_old_message");
                        } else {
                          return t("edit_message");
                        }
                      }}
                      onEditMessage={({ id, publishedTime }) => {
                        if (moment().isAfter(publishedTime)) {
                          this.setState({
                            messageId: id,
                            showCorrectModal: true,
                          });
                        } else onEditMessage(id, NewsMessageAction.EDIT);
                      }}
                      onSuperEditMessage={({ id }) =>
                        onEditMessage(id, NewsMessageAction.EDIT)
                      }
                      t={t}
                      {...props}
                      noIssuerAccess={this.state.noIssuerAccess}
                    />
                  ) : (
                    <Loader />
                  );
                }}
              />

              <Route
                path={`${base}/${LIST_DRAFTS}`}
                render={(props) => {
                  return this.props.draftMessageListIsFetched ||
                    this.props.draftMessageListReceiveFailed ? (
                        <PaginatedDraftList
                      key="drafts"
                      companyOptionList={companyOptionList}
                      onMessageSearch={onMessageSearch}
                      publishedByLabel={t("created_by")}
                      publishedLabel={t("to_be_published")}
                      correctLabel={t("edit")}
                      data={this.props.draftMessageList}
                      total={this.props.messageDraftCount}
                      onPageClick={onPageClick}
                      page={this.state.page}
                      permissionList={this.props.permissionList}
                      correctionAllowed={(message) =>
                        !message.correctedByMessage > 0 &&
                        message.financialCalendar === false
                      }
                      correctionText={(message) =>
                        message.correctedByMessage > 0
                          ? t("edit_correction_message")
                          : message.financialCalendar
                          ? t("edit_financial_calendar_message")
                          : t("edit_message")
                      }
                      onNewsClick={onDraftClick}
                      showAll={true}
                      globalPermissionList={this.props.globalPermissionList}
                      onEditMessage={({ id }) =>
                        onEditMessage(id, NewsMessageAction.EDIT)
                      }
                      t={t}
                      {...props}
                      isDraftList={true}
                      noIssuerAccess={this.state.noIssuerAccess}
                    />
                  ) : (
                    <Loader />
                  );
                }}
              />

              <Route
                path={`${base}/${NEW}`}
                render={(props) => {
                  return (
                    <EditContainer
                      superEditPermission={this.props.messagePermissionList.some(
                        (p) => p === "news.editPublished"
                      )}
                      t={t}
                      securities={this.props.securities}
                      newsMessageAction={NewsMessageAction.NEW}
                      companyList={this.props.companyList}
                      categoryList={this.props.categoryList}
                      messageListURL={`${base}/${LIST_MESSAGES}`}
                      messageParams={this.props.messageParams}
                      userLoggedIn={this.props.userLoggedIn}
                      language={getLanguage()}
                      getAttachment={getAttachment}
                      deleteAttachment={deleteAttachment}
                      languages={getLanguages()}
                      {...props}
                      base={`${base}/${NEW}`}
                    />
                  );
                }}
              />
              <Route
                path={`${base}/:newsMessageAction/:messageId`}
                render={(props) => {
                  const { messageId, newsMessageAction } = props.match.params;

                  switch (newsMessageAction) {
                    case EDIT:
                    case CORRECTION:
                      return (
                        <EditContainer
                          superEditPermission={this.props.messagePermissionList.some(
                            (p) => p === "news.editPublished"
                          )}
                          t={t}
                          securities={this.props.securities}
                          messageParams={this.props.messageParams}
                          newsMessageAction={newsMessageAction}
                          companyList={this.props.companyList}
                          categoryList={this.props.categoryList}
                          attachmentList={this.props.attachmentList}
                          attachmentListIsFetched={
                            this.props.attachmentListIsFetched
                          }
                          getAttachment={getAttachment}
                          deleteAttachment={deleteAttachment}
                          newsMessage={this.props.newsMessage}
                          messageListURL={`${base}/${LIST_MESSAGES}`}
                          languages={getLanguages()}
                          {...props}
                          base={`${base}/${newsMessageAction}/${messageId}`}
                          userLoggedIn={this.props.userLoggedIn}
                        />
                      );
                    case VIEW:
                      const correctedVersions = () => {
                        var arrCorrections = [];
                        if (this.props.newsMessage.correctedByMessage > 0)
                          arrCorrections.push(
                            <StyledNewsMessage.DecoratedText
                              key="1"
                              onClick={() =>
                                onNewsClick(
                                  this.props.newsMessage.correctedByMessage
                                )
                              }
                            >
                              {t("correctedBy_link_text")}
                            </StyledNewsMessage.DecoratedText>
                          );
                        if (this.props.newsMessage.correctionForMessage > 0)
                          arrCorrections.push(
                            <StyledNewsMessage.DecoratedText
                              key="2"
                              onClick={() =>
                                onNewsClick(
                                  this.props.newsMessage.correctionForMessage
                                )
                              }
                            >
                              {t("correctionFor_link_text")}
                            </StyledNewsMessage.DecoratedText>
                          );
                        return arrCorrections;
                      };
                      var selectedCompany = null;
                      if (this.props.newsMessage != null) {
                        const comp = this.props.companyMap.get(
                          this.props.newsMessage.cid
                        );
                        if (this.props.newsMessage.cid === 0)
                          selectedCompany = {
                            label: comp ? comp.longName : "unknown",
                            helpText: comp ? comp.symbol : "unknown",
                            value: comp ? comp.cid : "unknown",
                            symbol: comp ? comp.symbol : "unknown",
                          };
                      }

                      return this.props.newsMessage != null &&
                        this.props.messagePermissionList != null ? (
                        <NewsMessage
                          onSuperEdit={(id, cid) =>
                            onSuperEdit(id, cid, NewsMessageAction.EDIT)
                          }
                          onCancel={() =>
                            this.props.history.length > 0
                              ? this.props.history.goBack()
                              : this.props.history.push(`${this.props.base}`)
                          }
                          {...this.props.newsMessage}
                          exchanges={this.props.newsMessage.exchanges
                            .split(",")
                            .map((market) => {
                              const translatedMarket = t("market_" + market);
                              return translatedMarket !== "market_" + market
                                ? translatedMarket
                                : market;
                            })
                            .join(", ")}
                          category={
                            this.props.newsMessage.category[
                              "name" + getLanguage()
                            ]
                          }
                          companySymbol={
                            this.props.companyMap.get(
                              this.props.newsMessage.cid
                            ) != null
                              ? this.props.companyMap.get(
                                  this.props.newsMessage.cid
                                ).symbol
                              : ""
                          }
                          messageURL={
                            <Link
                              target="_blank"
                              href={
                                this.props.messageParams.newswebPrefix +
                                "/message/" +
                                this.props.newsMessage.messageId +
                                "?languageId=" +
                                  (getLanguage().toLocaleLowerCase() === "no" ? "nb" : getLanguage().toLocaleLowerCase())
                              }
                            >
                              {this.props.newsMessage.messageId}
                            </Link>
                          }
                          companyName={this.props.newsMessage.issuerName}
                          companyOptions={companyOptionList}
                          selectedCompany={selectedCompany}
                          showDeleteButton={this.props.messagePermissionList.some(
                            (p) => p === "news.delete"
                          )}
                          showEditPublished={this.props.messagePermissionList.some(
                            (p) => p === "news.editPublished"
                          )}
                          showRepublish={this.props.messagePermissionList.some(
                              (p) => p === "news.republish"
                          )}
                          showRenameAttachments={this.props.messagePermissionList.some(
                              (p) => p === "news.renameAttachments"
                          )}
                          instrumentName={this.props.newsMessage.instrumentName}
                          messageId={this.props.newsMessage.messageId}
                          attachmentList={this.props.attachmentList || []}
                          getAttachment={getAttachment}
                          deleteAttachment={deleteAttachment}
                          status={t(this.props.newsMessage.messageStatus)}
                          hasCorrections={correctedVersions().length > 0}
                          correctedVersionsLinks={correctedVersions()}
                          publishedTime={getOBNTDateTime(
                            this.props.newsMessage.publishedTime,
                            !this.props.localDateTime
                          )}
                          onDeleteMessage={() => {
                            deleteMessageConfirm(this.props.newsMessage);
                          }}
                          updateMessage={(cid) =>
                            this.props
                              .dispatch(
                                updateNewsMessage(
                                  {
                                    partialUpdate: true,
                                    updatedFields: ["cid"],
                                    cid,
                                    id: this.props.newsMessage.id,
                                  },
                                  false
                                )
                              )
                              .then(() => this.setState({ loading: true }))
                              .then(() =>
                                Promise.all([
                                  this.props.dispatch(fetchDraftMessageCount()),
                                  this.props.dispatch(fetchMessageList()),
                                  this.props.dispatch(fetchDraftMessageList()),
                                ])
                              )
                              .then(() =>
                                this.props.history.push(
                                  this.props.newsMessage.messageStatus ===
                                    "PUBLISHED"
                                    ? `${base}/${LIST_MESSAGES}`
                                    : `${base}/${LIST_DRAFTS}`
                                )
                              )
                              .then(() => this.setState({ loading: false }))
                              .then(() =>
                                NotificationManager.success(
                                  t("message_updated")
                                )
                              )
                          }
                          uploadFile={(file) =>
                            uploadFile(
                              file,
                              this.props.newsMessage.id,
                              this.props.dispatch
                            )
                              .then(() =>
                                this.props.dispatch(
                                  fetchAttachmentList(this.props.newsMessage.id)
                                )
                              )
                              .then(() =>
                                NotificationManager.success(
                                  t("attachment_uploaded")
                                )
                              )
                          }
                          maxFileSize={this.props.messageParams.attachMaxSize}
                          maxNumFiles={this.props.messageParams.maxNumAttach}
                          fileType={"application/pdf"}
                          t={this.props.t}
                          onlyPDFIcon={this.props.onlyPDFIcon}
                        />
                      ) : (
                        ""
                      );

                    default:
                      return "";
                  }
                }}
              />
              <Route
                path={base}
                render={() => <Redirect to={`${base}/${LIST_MESSAGES}`} />}
              />
            </Switch>

            <ConfirmModal
              center
              header={t("correct_message")}
              isOpen={this.state.showCorrectModal}
              onClose={() => this.setState({ showCorrectModal: false })}
              confirmText={"OK"}
              cancelText={t("cancel")}
              onConfirm={() => {
                this.setState({ showCorrectModal: false });
                onEditMessage(
                  this.state.messageId,
                  NewsMessageAction.CORRECTION
                );
              }}
            >
              <p>{t("correct_message_info")}</p>
              <p>{t("correct_message_confirm")}</p>
            </ConfirmModal>

            <ConfirmModal
              center
              header={t("delete_message")}
              isOpen={this.state.showDeleteModal}
              onClose={() => this.setState({ showDeleteModal: false })}
              confirmText={t("delete")}
              cancelText={t("cancel")}
              onConfirm={() => {
                this.setState({ showDeleteModal: false });
                deleteMessage(this.state.messageToDelete.id);
              }}
            >
              <p>
                {this.state.messageToDelete
                  ? t("delete_message_info", {
                      title: this.state.messageToDelete.title,
                    })
                  : undefined}
              </p>
            </ConfirmModal>

            <ConfirmModal
              center
              header={t("delete_attachment")}
              isOpen={this.state.showDeleteAttachmentModal}
              onClose={() =>
                this.setState({ showDeleteAttachmentModal: false })
              }
              confirmText={t("delete")}
              cancelText={t("cancel")}
              onConfirm={() => {
                this.setState({ showDeleteAttachmentModal: false });
                doRemoveAttachment(
                  this.state.attachmentToDelete.attId,
                  this.state.attachmentToDelete.newsId
                );
              }}
            >
              <p>
                {this.state.attachmentToDelete
                  ? t("delete_message_info", {
                      title: this.state.attachmentToDelete.attName,
                    })
                  : undefined}
              </p>
            </ConfirmModal>
          </div>
        )}
      </FlexContainer>
    );
  }
}
NewsMessageContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  const {
    messageReducer,
    companyReducer,
    categoryReducer,
    loginUser,
    config,
    systemAdministratorReducer,
  } = state;

  const messageList = (messageReducer.messageList.list || []).sort((a, b) => {
    const aPub = moment(a.publishedTime);
    const bPub = moment(b.publishedTime);

    if (aPub.isAfter(bPub)) return -1;
    else if (aPub.isBefore(bPub)) return 1;
    return 0;
  });

  return {
    companyList: companyReducer.list,
    companyMap: companyReducer.map,
    companyListIsFetched: companyReducer.companyListIsFetched,
    categoryList: categoryReducer.categories,
    categoryListIsFetched: categoryReducer.categoryListIsFetched,

    messageList: messageList,
    messageListIsFetched: messageReducer.messageList.isFetched,
    messageListReceiveFailed: messageReducer.messageList.receiveFailed,
    messageCount: messageReducer.messageCount.count,
    messageDraftCount: messageReducer.messageDraftCount.count,

    draftMessageList: messageReducer.draftMessageList.list,
    draftMessageListIsFetched: messageReducer.draftMessageList.isFetched,
    draftMessageListReceiveFailed:
      messageReducer.draftMessageList.receiveFailed,

    newsMessage: messageReducer.message.data,
    newsMessageIsFetched: messageReducer.message.isFetched,

    messageParams: messageReducer.messageParams || {},
    securities: messageReducer.securities.list,
    securityListIsFetched: messageReducer.securities.isFetched,

    attachmentList: messageReducer.attachment.list,
    attachmentListIsFetched: messageReducer.attachment.listIsFetched,

    globalPermissionList: messageReducer.globalMessagePermissionList.list,
    messagePermissionList: messageReducer.messagePermissionList.list,

    userLoggedIn: loginUser.user,
    localDateTime: config.config.localDateTime,
    onlyPDFIcon: config.config.onlyPDFIcon,
    publicProps: systemAdministratorReducer.publicProps.object || {},
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(NewsMessageContainer)
);
