import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Table, { TD, TR } from "../components/Table";
import { getOBNTDateTime } from "../util/common";
import {fetchLoggedInUsers} from "./SystemAdministratorActions";
import Loader from "../components/Loader";

class LoggedInUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentWillMount() {
        this.setState({loading: true})
        this.props.dispatch(fetchLoggedInUsers()).then(() => {
            this.setState({loading: false})
        })
    }
  render() {
    const { loggedInUsers, t } = this.props;

    const thLabels = [
      t("username"),
      t("system_login_time"),
      t("system_last_refresh_time"),
      t("system_refresh_count"),
      t("system_expire_time")
    ];

    const headerProps = [{}, {}, {}, { center: true }, {}];

    const tableRows = loggedInUsers
      ? Object.values(loggedInUsers).map((entity, index) => (
          <TR key={index}>
            <TD>{entity.login}</TD>
            <TD>{getOBNTDateTime(entity.loginTime)}</TD>
            <TD>{getOBNTDateTime(entity.lastRefreshTime)}</TD>
            <TD center>{entity.refreshCount}</TD>
            <TD>{getOBNTDateTime(entity.expires)}</TD>
          </TR>
        ))
      : [];

    return (
      <FlexContainer>
          {this.props.loading ? <Loader /> : undefined}
        <Table
          tableRows={tableRows}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
          forceUpdate={true}
          t={t}
        />
      </FlexContainer>
    );
  }
}

function mapStateToProps(state) {
  const { systemAdministratorReducer } = state;
  return {
    loggedInUsers: systemAdministratorReducer.loggedInUsers.object
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(LoggedInUsers)
);
