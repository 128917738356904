import { postData } from "../actions/CommonActionsNoObsvc";

export const ContactPersonList = {
  request: "REQUEST_CONTACTPERSON_LIST",
  postSuccess: "RECEIVE_CONTACTPERSON_LIST",
  postFail: "RECEIVE_CONTACTPERSON_LIST_FAILED",
};

export const fetchContactPersonList = (cid) =>
  postData(ContactPersonList,  undefined, { cid: cid }, "v1/company/contactPerson/list");

export const CompanyList = {
  request: "REQUEST_COMPANY",
  postSuccess: "RECEIVE_COMPANY_LIST",
  postFail: "RECEIVE_COMPANY_LIST_FAILED",
};

export const fetchCompanyList = () =>
  postData(CompanyList, undefined, undefined, "v1/company/list");

export const GetContactPerson = {
  request: "REQUEST_CONTACTPERSON_GET",
  postSuccess: "RECEIVE_CONTACTPERSON_GET",
  postFail: "RECEIVE_CONTACTPERSON_GET_FAILED",
  clear: "CLEAR_CONTACTPERSON_GET",
};
export const clearContactPerson = () => {
  return { type: GetContactPerson.clear };
};
export const fetchContactPerson = (id) =>
  postData(GetContactPerson, undefined, { id: id }, "v1/company/contactPerson/get");

export const GetCompany = {
  request: "REQUEST_COMPANY_GET",
  postSuccess: "RECEIVE_COMPANY_GET",
  postFail: "RECEIVE_COMPANY_GET_FAILED",
};

export const fetchCompany = (cid) =>
  postData(GetCompany, undefined, { id: cid }, "v1/company/get");

export const Parameters = {
  request: "REQUEST_PARAMETERS_GET",
  postSuccess: "RECEIVE_PARAMETERS_GET",
  postFail: "RECEIVE_PARAMETERS_GET_FAILED",
};

export const fetchParameters = (cid = "") =>
  postData(Parameters, undefined, { cid: cid }, "v1/company/parameters");

export const NewContact = {
  request: "CREATE_NEW_CONTACT",
  postSuccess: "CREATE_SUCCESS_NEW_CONTACT",
  postFail: "CREATE_FAILED_NEW_CONTACT",
};

export const UpdateContact = {
  request: "UPDATE_CONTACT",
  postSuccess: "UPDATE_CONTACT_SUCCESS",
  postFail: "UPDATE_CONTACT_FAIL",
};

export const createNewContactPerson = (contact) =>
  postData(NewContact,  undefined, { ...contact }, "v1/company/contactPerson/add");

export const updateContactPerson = (contact) =>
  postData(UpdateContact, undefined, { ...contact }, "v1/company/contactPerson/update");

export const NewCompany = {
  request: "CREATE_NEW_COMPANY",
  postSuccess: "CREATE_SUCCESS_NEW_COMPANY",
  postFail: "CREATE_FAILED_NEW_COMPANY",
};

export const UpdateCompany = {
  request: "UPDATE_COMPANY",
  postSuccess: "UPDATE_COMPANY_SUCCESS",
  postFail: "UPDATE_COMPANY_FAIL",
};

export const createCompany = (company) =>
  postData(NewCompany, undefined, { ...company }, "v1/company/add");

export const updateCompany = (company) =>
  postData(UpdateCompany, undefined, { ...company }, "v1/company/update");

export const DeleteContact = {
  request: "DELETE_CONTACT",
  postSuccess: "DELETE_CONTACT_SUCCESS",
  postFail: "DELETE_CONTACT_FAILED",
};

export const deleteContactPerson = (contact, cid) =>
  postData(
    DeleteContact,
    undefined,
    { id: contact, cid: cid },
    "v1/company/contactPerson/delete"
  );
