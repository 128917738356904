import {combineReducers} from "redux";
import {Healthchecks, EmailStatus} from "./StatusActions";

const initialEmailStatusState = {
    list: [],
    isFetched: false,
};
export const emailStatus = (
    state = {
        ...initialEmailStatusState,
    },
    action
) => {
    switch (action.type) {
        case EmailStatus.receive:
            return Object.assign({}, state, {
                list: action.data.data.entity,
                isFetched: true,
            });

        default:
            return state;
    }
};

const initialHealthcheckState = {
    list: [],
    isFetched: false,
};
export const healthchecks = (
    state = {
        ...initialHealthcheckState,
    },
    action
) => {
    switch (action.type) {
        case Healthchecks.receive:
            return Object.assign({}, state, {
                list: action.data.data.entity,
                isFetched: true,
            });

        default:
            return state;
    }
};

const statusReducer = combineReducers({
    emailStatus,
    healthchecks
});

export default statusReducer;