import React from "react";
import Table, { TD, TR } from "../../components/Table";
import { connect } from "react-redux";
import styled from "styled-components";
import { FlexContainer } from "../../components/FlexContainer";
import Label from "../../components/Label";
import { Field, reduxForm } from "redux-form";
import { ErrorText } from "../../components/ErrorText";
import { ActionText } from "../../components/ActionText";
import { MAX_RESULT_ON_PAGE } from "../../components/PaginationContainer";
import { Icon } from "../../insiderTool/components";
import Input from "../../components/Input";
import Loader from "../../components/Loader";

const UserTitle = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

export const SortOrder = {
  ASCENDING: 1,
  DESCENDING: -1
};

export const SortOnType = {
  USERNAME: 0,
  LASTNAME: 1,
  FIRSTNAME: 2,
  ORGANISATION: 3,
  PROTOCOL: 4
};

const SearchBox = props => {
  const { error } = props.meta;

  return (
    <FlexContainer column margin={[0, 8, 0, 0]} maxWidth>
      <Label maxWidth bold htmlFor={props.name} margin={[8, 8, 3, 0]}>
        {props.label}:{error ? (
          <ErrorText padding={[0, 0, 0, 5]}>{error}</ErrorText>
        ) : (
          undefined
        )}
      </Label>
      <Input {...props} {...props.input} border />
    </FlexContainer>
  );
};

class SearchComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <FlexContainer>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <FlexContainer margin={[10, 0, 10, 0]}>
            {t("overview_users_disabled_two_factor")}
          </FlexContainer>

          <FlexContainer row margin={[10, 0, 10, 0]} vAlignEnd spaceBetween>
            <FlexContainer minWidth={900} row>
              <Field
                name="username"
                noRightMargin
                component={SearchBox}
                label={t("username")}
              />
              <Field
                name="organisation"
                noRightMargin
                component={SearchBox}
                label={t("organisation")}
              />
            </FlexContainer>
            <FlexContainer vAlignCenter row width="auto" spaceBetween>
              <ActionText
                margin={[0, 0, 7, 20]}
                onClick={() => {
                  this.props.reset();
                }}
              >
                {t("reset_search")}
              </ActionText>
            </FlexContainer>
          </FlexContainer>
        </form>
      </FlexContainer>
    );
  }
}
SearchComponent = reduxForm({ form: "userSearch" })(SearchComponent);

class UsersTwoFactorDisabledList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  TableHeaderSort = ({ SortOnType }) => (
    <Icon
      height={20}
      width={20}
      margin={[3, 0, 0, 3]}
      src="/icons/sort-white-24px.svg"
      onClick={() => {
        this.props.setSortOnType(SortOnType);
      }}
      alt={"sort"}
    />
  );

  render() {
    const { t, base } = this.props;
    const thLabels = [
      <FlexContainer row>
        {t("username")}
        <this.TableHeaderSort SortOnType={SortOnType.USERNAME} />
      </FlexContainer>,
      <FlexContainer row>
        {t("first_name")}
        <this.TableHeaderSort SortOnType={SortOnType.FIRSTNAME} />
      </FlexContainer>,
      <FlexContainer row>
        {t("last_name")}
        <this.TableHeaderSort SortOnType={SortOnType.LASTNAME} />
      </FlexContainer>,
      <FlexContainer row>
        {t("organisation")}
        <this.TableHeaderSort SortOnType={SortOnType.ORGANISATION} />
      </FlexContainer>,
      <FlexContainer row center>
        {t("two_factor_message_protocol")}
        <this.TableHeaderSort SortOnType={SortOnType.PROTOCOL} />
      </FlexContainer>
    ];

    const getOrganisationNameFromFirstInGroups = entity => {
      return entity && entity.groups && entity.groups[0]
        ? entity.groups[0].name
        : undefined;
    };

    const tableRows =
      this.props.data != null
        ? this.props.data.map((entity, index) => (
            <TR key={index} lineThrough={entity.status === 128}>
              <TD pointer onClick={() => this.props.history.push(`${base}/useradmin/user/${entity.id}`)}>
                <UserTitle>{entity.username}</UserTitle>
              </TD>
              <TD>{entity.firstName}</TD>
              <TD>{entity.lastName}</TD>
              <TD>{getOrganisationNameFromFirstInGroups(entity)}</TD>
              <TD center>{entity.twoFactorMessageProtocol}</TD>
            </TR>
          ))
        : [];

    return (
      <React.Fragment>
        <FlexContainer
          column
          padding={
            tableRows && tableRows.length < MAX_RESULT_ON_PAGE
              ? [0, 0, 0, 0]
              : []
          }
          margin={[0, 0, 15, 0]}
        >
          <FlexContainer row>
            <SearchComponent t={t} {...this.props} />
          </FlexContainer>
          {this.props.loading ? <Loader /> : undefined}
          <Table
            tableRows={tableRows}
            tableHeaderLabels={thLabels}
            forceUpdate={true}
            t={t}
          />
        </FlexContainer>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const {} = state;
  return {};
}

export default connect(mapStateToProps)(UsersTwoFactorDisabledList);
