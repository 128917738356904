import React from "react";

import { FlexContainer } from "../../components/FlexContainer";
import { withTranslation } from "react-i18next";

import MandatoryNCAInfo from "./MandatoryNCAInfo";
import MandatoryKeyInformationContainer from "./MandatoryKeyInformation";
import { connect } from "react-redux";
import Loader, { OverlayLoader } from "../../components/Loader";
import { InsiderListHeaderActions } from "./InsiderListHeaderActions";
import Tracelog from "../modals/insiderList/Tracelog";
import InsiderListInformation from "./InsiderListInformation";
import {
  fetchInsiderList,
  fetchTraceLog,
  InsiderType,
  enterHistoricMode,
  leaveHistoricMode
} from "../InsiderToolActions";
import { ErrorText } from "../../components/ErrorText";
import Button from "../../components/Button";
import { getDateTimeInOBNTFormat } from "../UTCUtils";
import { Header } from "../components";
import { LIST_CLOSED } from "../home/InsiderListHome";
import InsiderTableContainer from "../insiderList/insiderTable/InsiderTableContainer";

export class InsiderList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTraceLogModal: false,
      showOverlayLoader: false,
      showLoader: false
    };
  }

  _fetchInsiderList = () => {
    const { listId, dispatch } = this.props;

    Promise.resolve(this.setState({ showLoader: true }))
      .then(() => dispatch(fetchInsiderList(listId)))
      .catch(e => console.log(e))
      .then(() => this.setState({ showLoader: false }));
  };

  componentWillMount() {
    this._fetchInsiderList();
  }

  componentWillUnmount() {
    this.props.dispatch(leaveHistoricMode());
  }

  componentDidUpdate(prevProps) {
    const { listId } = this.props;
    if (prevProps.listId !== listId) {
      this._fetchInsiderList();
    }
  }

  getInsiderListAt = timestamp => {
    const { listId, dispatch } = this.props;
    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() => dispatch(fetchInsiderList(listId, timestamp)))
      .then(
        () =>
          timestamp === null
            ? dispatch(leaveHistoricMode())
            : dispatch(enterHistoricMode(timestamp))
      )
      .then(() =>
        this.setState({
          showOverlayLoader: false
        })
      );
  };

  toggleTraceLogModal = () =>
    this.setState({ showTraceLogModal: !this.state.showTraceLogModal });

  openTraceLog = _ => {
    const { listId, dispatch } = this.props;

    Promise.resolve(this.setState({ showOverlayLoader: true }))
      .then(() => dispatch(fetchTraceLog(listId)))
      .then(() => this.toggleTraceLogModal())
      .then(() => this.setState({ showOverlayLoader: false }));
  };

  render() {
    const {
      t,
      insiderListInfo,
      tracelog,
      listId,
      type,
      insiderListIsFetched,
      inHistoricMode,
      historicTimestamp
    } = this.props;

    if (this.state.showLoader) return <Loader />;
    if (!insiderListIsFetched) return "";

    if (type === InsiderType.PERMANENT_INSIDERS) {
      return (
        <FlexContainer fontSize="20px" margin={[50, 0, 0, 0]} vAlignCenter>
          <p>{this.props.t("delayed_until_mar")}</p>
        </FlexContainer>
      );
    }
    return [
      <FlexContainer column key="insiderListForm" margin={[30, 0, 0, 0]}>
        {insiderListInfo &&
        insiderListInfo.data &&
        insiderListInfo.data.listState &&
        insiderListInfo.data.listState === LIST_CLOSED ? (
          <FlexContainer vAlignCenter center>
            <Header color={"red"} margin={[0]} fontSize={"18"}>
              {t(
                type === InsiderType.LIST
                  ? "it_the_list_is_closed"
                  : "it_the_conf_list_is_closed",
                { closedDateTime: insiderListInfo.data.closedTime }
              )}
            </Header>
          </FlexContainer>
        ) : (
          undefined
        )}
        <FlexContainer row flexEnd>
          {type !== InsiderType.PERMANENT_INSIDERS ? (
            <InsiderListInformation />
          ) : (
            ""
          )}

          <InsiderListHeaderActions
            getInsiderListAt={this.getInsiderListAt}
            listId={listId}
            viewTracelog={this.openTraceLog}
            insiderListData={insiderListInfo}
          />
        </FlexContainer>

        {inHistoricMode ? (
          <FlexContainer column fitToContent margin={[20, 0, 0, 0]}>
            <ErrorText>{`${t(
              "it_viewing_earlier_version"
            )} ${getDateTimeInOBNTFormat(
              historicTimestamp,
              this.props.showUTCTime
            )}`}</ErrorText>
            <Button.Text onClick={() => this.getInsiderListAt(null)}>
              {t("it_revert_to_original")}
            </Button.Text>
          </FlexContainer>
        ) : (
          ""
        )}

        <MandatoryKeyInformationContainer insiderListInfo={insiderListInfo} />

        {type === InsiderType.LIST ? (
          <MandatoryNCAInfo insiderListInfo={insiderListInfo} />
        ) : (
          ""
        )}

        <InsiderTableContainer />

        {this.state.showTraceLogModal ? (
          <Tracelog
            onClose={this.toggleTraceLogModal}
            data={tracelog.allData}
            helpTextFileName={"main_tracelog"}
          />
        ) : (
          undefined
        )}

        {this.state.showOverlayLoader ? <OverlayLoader /> : ""}
      </FlexContainer>
    ];
  }
}

function mapStateToProps(state) {
  const { loginUser, insiderToolReducer } = state;

  return {
    tracelog: insiderToolReducer.tracelog,
    insiderListInfo: insiderToolReducer.insiderList.insiderListInfo,
    insiderListIsFetched: insiderToolReducer.insiderList.isFetched,
    organsationName: loginUser.userOrganisation.name,
    historicTimestamp: insiderToolReducer.insiderList.historicTimestamp,
    inHistoricMode: insiderToolReducer.insiderList.historicMode,
    showUTCTime: insiderToolReducer.UTC.showUTC
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderList)
);
