import React from "react";
import { PageHeading } from "../components/PageHeading";
import { FlexContainer } from "../components/FlexContainer";
import styled from "styled-components";
import Table, { TR, TD } from "../components/Table";
import Button from "../components/Button";
import { getOBNTDate } from "../util/common";

const ClickAbleTitle = styled.div`
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgb(51, 102, 153);
  font-weight: 600;
  &:hover {
    text-decoration: underline;
  }
`;

function InfoList(props) {
  const { t } = props;

  const thLabels = [
    t("date"),
    t("title"),
    t("registered_by"),
    t("modify"),
    t("delete"),
  ];
  const arrWidth = [88, 539, 362, 85, 85];
  const headerProps = [{}, {}, {}, { center: true }, { center: true }];

  const tableRows = props.messageList.map((message, index) => (
    <TR key={index}>
      <TD boldFirstLine width={arrWidth[0]}>
        {getOBNTDate(message.publishTime)}
      </TD>
      <TD
        pointer
        width={arrWidth[1]}
        onClick={() => props.onMessageClick(message.identity)}
      >
        <ClickAbleTitle>{props.title(message)}</ClickAbleTitle>
      </TD>
      <TD width={arrWidth[2]}>{message.displayUser}</TD>
      <TD width={arrWidth[3]} center pointer>
        <img
          src="/icons/ic-edit-black-24-px.svg"
          onClick={() => props.onMessageClick(message.identity)}
          alt={"Edit"}
        />
      </TD>
      <TD width={arrWidth[4]} center pointer>
        <img
          src="/icons/red-cross-24.svg"
          onClick={() => props.onDeleteMessage(message)}
          alt={"Delete"}
        />
      </TD>
    </TR>
  ));

  return (
    <FlexContainer column>
      <FlexContainer row spaceBetween margin={[0, 0, 26, 0]}>
        <PageHeading margin={[0, 0, 0, 0]}>{t("info_from_ma")}</PageHeading>
        <Button.Standard onClick={props.onNewMessage}>
          {t("new_message")}
        </Button.Standard>
      </FlexContainer>

      <Table
        tableRows={tableRows}
        tableHeaderLabels={thLabels}
        tableHeaderProps={headerProps}
        forceUpdate={true}
      />
    </FlexContainer>
  );
}

export default InfoList;
