import { SubmissionError } from "redux-form";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import React from "react";
import styled from "styled-components";

const ErrorText = styled.span`
  white-space: ${(props) => (props.err500 ? "normal;" : "pre-wrap;")};
  word-wrap: break-word;
`;

export const handleServerErrors = (error, reject, t) => {
  const errors = {};
  if (null != error.data && error.data.details != null) {
    error.data.details.forEach((e) => {
      if (e.code === 1200) {
        errors.idNumber = e.message;
      } else if (e.code === 1201) {
        errors.idNumber = e.message;
      } else if (e.code === 1202) {
        errors.idNumber = e.message;
      } else if (e.code === 1203) {
        errors.idNumber = e.message;
      } else if (e.code === 1204) {
        errors.postalCode = e.message;
      } else if (e.code === 1205) {
        errors.postalPlace = e.message;
      } else if (e.code === 1206) {
        errors.countryOption = e.message;
      } else if (e.code === 1207) {
        errors.email = e.message;
      } else if (e.code === 1208) {
        errors.languageOption = e.message;
      } else if (e.code === 1209) {
        errors.firstName = e.message;
      } else if (e.code === 1210) {
        errors.surname = e.message;
      } else if (e.code === 1211) {
        errors.companyName = e.message;
      } else if (e.code === 1212) {
        errors.surname = e.message;
      } else if (e.code === 1213) {
        errors.companyName = e.message;
      } else if (e.code === 1214) {
        errors.position = e.message;
        errors.commission = e.message;
      } else if (e.code === 1215) {
        errors.firstname = e.message;
      } else if (e.code === 1216) {
        errors.middlename = e.message;
      } else if (e.code === 1217) {
        errors.postalCode = e.message;
      } else if (e.code === 1218) {
        errors.postalPlace = e.message;
      } else if (e.code === 1219) {
        errors.email = e.message;
      } else if (e.code === 1220) {
        errors.phone1 = e.message;
      } else if (e.code === 1221) {
        errors.phone2 = e.message;
      } else if (e.code === 1222) {
        errors.position = e.message;
      } else if (e.code === 1223) {
        errors.commission = e.message;
      } else if (e.code === 1224) {
        errors.comment = e.message;
      } else if (e.code === 1225) {
        errors.validFrom = e.message;
      } else if (e.code === 1226) {
        errors.validFrom = e.message;
      } else if (e.code === 1227) {
        errors.email = e.message;
      } else if (e.code === 1228) {
        errors.primaryInsider = e.message;
      } else {
        errors.default = "Something went wrong";
        NotificationManager.error(
          <ErrorText>{e.message}</ErrorText>,
          i18next.t("server_error"),
          10000
        );
      }
    });
  } else {
    errors.default = "Something went wrong";
    NotificationManager.error(
      <ErrorText>
        {error.data && error.data.statusMessage
          ? error.data.statusMessage
          : "Something went wrong"}
      </ErrorText>,
      i18next.t("server_error"),
      10000
    );
  }

  return Object.keys(errors).length > 0
    ? reject(new SubmissionError(errors))
    : undefined;
};
