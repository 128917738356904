import React from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import i18n from "../../i18n";

import InsiderApproveForm from "./InsiderApproveForm";
import InsiderApproveTwoFactor from "./InsiderApproveTwoFactor";
import {
  fetchInsiderExternal,
  fetchInsiderExternalWithMfaCode,
  getCountries,
  InsiderType,
  updateInsiderExternal
} from "../InsiderToolActions";
import Loader, { OverlayLoader } from "../../components/Loader";
import {
  getDateTimeInOBNTFormat,
  getISOTimeFromDateAndTime
} from "../UTCUtils";
import { getLanguage, sizes } from "../../util/common";
import { FlexContainer } from "../../components/FlexContainer";
import { SubmissionError } from "redux-form";
import { NotificationManager } from "react-notifications";
import InsiderApproveFormPhone from "./InsiderApproveFormPhone";

class InsiderApproveFormContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMfa: true,
      loading: true,
      twoFactorCode: undefined,
      showOverlayLoader: false,
      submissionSuccess: false,
      invalidUrl: false,
      mfaDisabled: false
    };
  }

  getInsiderObject = values => {
    let copiedValues = JSON.parse(JSON.stringify(values));
    if (copiedValues.obtained_date && copiedValues.obtained_time) {
      copiedValues.obtained = getISOTimeFromDateAndTime(
        copiedValues.obtained_date,
        copiedValues.obtained_time
      );
    }

    delete copiedValues.obtained_date;
    delete copiedValues.obtained_time;
    delete copiedValues.obtainedTime;

    if (copiedValues.company_country && copiedValues.company_country.value) {
      copiedValues.company_country = copiedValues.company_country.value;
    }
    if (copiedValues.country && copiedValues.country.value) {
      copiedValues.country = copiedValues.country.value;
    }

    return copiedValues;
  };

  submit = values => {
    // print the form values to the console
    this.setState({ showOverlayLoader: true });
    const { insider } = this.props;
    const insiderObject = this.getInsiderObject(values);

    this.props
      .dispatch(
        updateInsiderExternal(
          insider.listId,
          insider.id,
          insiderObject,
          InsiderType.PERSON,
          this.props.token,
          this.state.twoFactorCode
        )
      )
      .then(() => {
        this.setState({ showOverlayLoader: false, submissionSuccess: true });
      })
      .catch(e => {
        this.setState({ showOverlayLoader: false });
        console.log(e);
      });
  };

  handleServerErrors = (error, reject) => {
    if (error.header["http.code"] && error.header["http.code"] === 401) {
      return reject(
        new SubmissionError({
          mfacode: "invalid_one_time_code"
        })
      );
    } else {
      return reject(
        NotificationManager.error("", this.props.t("server_error"), 0)
      );
    }
  };

  mfaSubmit = values => {
    return new Promise((resolve, reject) => {
      this.setState({ showOverlayLoader: true, twoFactorCode: values.mfacode });
      return this.props
        .dispatch(
          fetchInsiderExternalWithMfaCode(this.props.token, values.mfacode)
        )
        .then(resolve)
        .then(() => {
          this.setState({ showOverlayLoader: false, showMfa: false });
        })
        .catch(e => {
          this.handleServerErrors(e, reject);
          this.setState({ showOverlayLoader: false });
          console.log(e);
        });
    });
  };

  componentWillMount() {
    this.props.dispatch(getCountries()).catch(e => console.log(e));
    if (this.lang != null) {
      this.lang === "en"
        ? i18n.changeLanguage("en")
        : i18n.changeLanguage("nb");
    } else if (navigator.language) {
      navigator.language.includes("-NO")
        ? i18n.changeLanguage("nb")
        : i18n.changeLanguage("en");
    } else i18n.changeLanguage("nb");
    if (this.props.token) {
      Promise.resolve(this.setState({ loading: true }))
        .then(() => this.props.dispatch(fetchInsiderExternal(this.props.token)))
        .then(response => {
          const httpCode = this.props.insiderHeader["http.code"];

          if (httpCode && httpCode === 202 && !response.data.twoFactorEnabled)
            return Promise.resolve(
              this.setState({ showMfa: false, mfaDisabled: true })
            );
          if (!httpCode || httpCode !== 202) {
            return Promise.resolve(this.setState({ showMfa: false }));
          }
        })
        .then(() => this.setState({ loading: false }))
        .catch(e => {
          this.setState({ loading: false });
          if (e === 500) this.setState({ invalidUrl: true });
          console.log(e);
        });
    }
  }

  getMailDate = () => {
    let mailDate = undefined;
    if (this.props.insider.insiderStateChangedDate) {
      mailDate = getDateTimeInOBNTFormat(
        this.props.insider.insiderStateChangedDate
      );
    }
    return mailDate;
  };

  render() {
    const countryOptions = this.props.countries.map(country => {
      return {
        label: getLanguage() === "No" ? country.nameNo : country.nameEn,
        value: country.id
      };
    });

    if (this.state.loading) {
      return <Loader />;
    }
    if (this.state.submissionSuccess) {
      return (
        <FlexContainer fontSize="20px" margin={[50, 0, 0, 0]} vAlignCenter>
          <p>{this.props.t("it_successful_registration")}</p>
        </FlexContainer>
      );
    } else if (this.state.invalidUrl) {
      return (
        <FlexContainer fontSize="20px" margin={[50, 0, 0, 0]} vAlignCenter>
          <p>{this.props.t("it_invalid_url")}</p>
        </FlexContainer>
      );
    } else if (this.state.showMfa) {
      return (
        <React.Fragment>
          {this.state.showOverlayLoader ? <OverlayLoader /> : undefined}
          <InsiderApproveTwoFactor
            {...this.props}
            onSubmit={this.mfaSubmit}
            submitDisabled={!this.props.insiderTwoFactorValues}
          />
        </React.Fragment>
      );
    } else if (this.state.mfaDisabled) {
      return (
        <FlexContainer fontSize="20px" margin={[50, 0, 0, 0]} vAlignCenter>
          <p>{this.props.t("it_twofactor_disabled")}</p>
        </FlexContainer>
      );
    } else {
      const {
        correct_information,
        regulatory_compliance,
        ...rest
      } = this.props.insider.data;
      return (
        <React.Fragment>
          {this.state.showOverlayLoader ? <OverlayLoader /> : undefined}
          {window.innerWidth >= sizes.phone ? (
            <InsiderApproveForm
              {...this.props}
              projectName={this.props.insider.projectName}
              emailDate={this.getMailDate()}
              onSubmit={this.submit}
              initialValues={{
                ...rest
              }}
              listType={
                this.props.insider.isInsiderList
                  ? InsiderType.LIST
                  : InsiderType.CONFIDENTIALITY_LIST
              }
              countryOptions={countryOptions}
              insiderFormValues={this.props.insiderFormValues}
            />
          ) : (
            <InsiderApproveFormPhone
              {...this.props}
              projectName={this.props.insider.projectName}
              emailDate={this.getMailDate()}
              onSubmit={this.submit}
              initialValues={{
                ...rest
              }}
              listType={
                this.props.insider.isInsiderList
                  ? InsiderType.LIST
                  : InsiderType.CONFIDENTIALITY_LIST
              }
              countryOptions={countryOptions}
              insiderFormValues={this.props.insiderFormValues}
            />
          )}
        </React.Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  const { form, insiderToolReducer } = state;

  return {
    insiderFormValues: form.insiderApproveForm
      ? form.insiderApproveForm.values
      : undefined,
    insiderTwoFactorValues: form.insiderTwoFactor
      ? form.insiderTwoFactor.values
      : undefined,
    insider: insiderToolReducer.externalInsider.insider,
    insiderHeader: insiderToolReducer.externalInsider.header,
    countries: insiderToolReducer.countries.countries
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(InsiderApproveFormContainer)
);
