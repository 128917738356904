import React from "react";
import { FlexContainer } from "../components/FlexContainer";
import { withTranslation } from "react-i18next";
import { ErrorText } from "../components/ErrorText";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Loader from "../components/Loader";
import { postUnsubscribeEmailList } from "./UnsubscribeEmailListActions";
import i18n from "../i18n";

export const UNSUBSCRIBE_ROOT_PATH = "/unsubscribe";

class UnsubscribeEmailListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      unsubscribeToken: undefined,
      unsubscribed: false
    };
  }

  componentDidMount() {
    const pathWithoutRoot = this.props.location.pathname.substring(
      UNSUBSCRIBE_ROOT_PATH.length + 1
    );
    const lastSeparatorChar = pathWithoutRoot.indexOf("/");
    const language = pathWithoutRoot.substring(lastSeparatorChar + 1);
    const parameterForServer = pathWithoutRoot.substring(0, lastSeparatorChar);

    language === "en" ? i18n.changeLanguage("en") : i18n.changeLanguage("nb");

    this.props
      .dispatch(postUnsubscribeEmailList(parameterForServer))
      .then(res => {
        console.log(res);
        this.setState({ unsubscribed: true, loading: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ unsubscribed: false, loading: false });
      });
  }

  render() {
    const { t } = this.props;

    if (this.state.loading) {
      return <Loader />;
    }

    return (
      <FlexContainer center vAlignCenter margin={[100, 0, 0, 0]}>
        {this.state.unsubscribed ? (
          <ErrorText fontSize={"26px"}>{t("unsubscribe_did_work")}</ErrorText>
        ) : (
          <ErrorText fontSize={"26px"}>
            {t("unsubscribe_did_not_work")}
          </ErrorText>
        )}
      </FlexContainer>
    );
  }
}

export default withRouter(
  connect()(withTranslation("translations")(UnsubscribeEmailListContainer))
);
