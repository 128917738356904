import React from "react";
import { connect } from "react-redux";
import { FlexContainer } from "../components/FlexContainer";
import SearchableSelect from "../components/SearchableSelect";
import Button from "../components/Button";
import Label from "../components/Label";
import { ErrorText } from "../components/ErrorText";
import { Field, reduxForm } from "redux-form";
import { withTranslation } from "react-i18next";
import { Modal } from "../components/Modal";

class InputField extends React.Component {
  render() {
    const props = this.props;
    const { error } = props.meta;
    const { PropsComponent } = props;
    const { height } = props;

    return (
      <FlexContainer column margin={[0, 0, 16, 0]} height={height}>
        {props.noLabel ? (
          ""
        ) : (
          <Label margin={[0, 0, 3, 0]} htmlFor={props.name}>
            {props.label}
          </Label>
        )}
        {error ? <ErrorText>{error}</ErrorText> : undefined}
        <PropsComponent {...props} />
      </FlexContainer>
    );
  }
}

export const InputBox = props => {
  return (
    <Field {...props} component={InputField} PropsComponent={props.component} />
  );
};

class NewInsiderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPersonFirm: false
    };
  }

  render() {
    const { t } = this.props;

    const headerText = newInsiderModalForm => {
      if (
        newInsiderModalForm == null ||
        newInsiderModalForm.primaryRelated == null
      ) {
        return "new_insider";
      } else if (newInsiderModalForm.primaryRelated.value == 0) {
        return "new_insider_primary";
      } else {
        return "new_insider_relation";
      }
    };
    const isPrimaryInsiderSelected = newInsiderModalForm => {
      if (
        newInsiderModalForm == null ||
        newInsiderModalForm.primaryRelated == null
      ) {
        return false;
      } else if (newInsiderModalForm.primaryRelated.value == 0) {
        return true;
      } else {
        return false;
      }
    };

    return (
      <Modal
        header={t(headerText(this.props.newInsiderModalForm))}
        isOpen={this.props.showNewEntry}
        onClose={() => this.props.onClose()}
      >
        <FlexContainer row>
          <FlexContainer column margin={[0, 40, 0, 0]}>
            <InputBox
              label={t("type") + ":*"}
              name="primaryRelated"
              t={t}
              component={SearchableSelect}
              searchable={true}
              placeholder={this.props.t("select")}
              options={[
                { label: t("primary_insider"), value: 0 },
                { label: t("related_party"), value: 1 }
              ]}
            />
            {!isPrimaryInsiderSelected(this.props.newInsiderModalForm) ? (
              <InputBox
                label={t("person_firm") + ":*"}
                name="personFirm"
                t={t}
                component={SearchableSelect}
                searchable={true}
                placeholder={this.props.t("select")}
                options={[
                  { label: t("person"), value: 0 },
                  { label: t("firm"), value: 1 }
                ]}
              />
            ) : (
              ""
            )}
            <FlexContainer row vAlignCenter flexEnd>
              <Button.Standard
                onClick={this.props.handleSubmit}
                margin={[0, 24, 0, 0]}
              >
                {t("okay")}
              </Button.Standard>
              <Button.Text onClick={this.props.onCancel}>
                {t("cancel")}
              </Button.Text>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  const { form } = state;

  return {
    newInsiderModalForm:
      form.insiderModule && form.insiderModule.values
        ? form.insiderModule.values
        : undefined
  };
}

NewInsiderModal = reduxForm({
  form: "insiderModule"
})(NewInsiderModal);

export default connect(mapStateToProps)(
  withTranslation("translations")(NewInsiderModal)
);
