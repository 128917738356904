export const compareKnownPersonFields = person => {
  var {
    selectedKnownPersonId,
    insider_function_and_reason,
    comment,
    obtained,
    ceased,
    ceasedTime,
    obtained_time,
    obtained_date,
    ceased_time,
    ceased_date,
    regulatory_compliance,
    correct_information,
    ...rest
  } = person.data;
  return rest;
};
