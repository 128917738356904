import {FlexContainer} from "../../components/FlexContainer";
import {Field, reduxForm, reset} from "redux-form";
import {connect} from "react-redux";
import React from "react";
import CheckBox from "../../components/CheckBox";
import {withTranslation} from "react-i18next";
import Table, {TD, TR} from "../../components/Table";
import Button from "../../components/Button";
import {fetchSystemProps, updateSystemProps} from "../SystemAdministratorActions";
import {OverlayLoader} from "../../components/Loader";

export const MODULE_SETTING_PREFIX = "public.system.global.module.";

class ModuleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    const { systemProps, t, moduleFormValues } = this.props;

    const saveChangedSystemProps = () => {
      let changedSystemProps = {};
      if (moduleFormValues) {
        Object.keys(moduleFormValues).forEach(key => {
          const keyFromSystemProps = Object.keys(systemProps).find(
            systemPropsKey => systemPropsKey === MODULE_SETTING_PREFIX + key
          );
          if (
            keyFromSystemProps &&
            systemProps[keyFromSystemProps] !== moduleFormValues[key] + ""
          ) {
            changedSystemProps[keyFromSystemProps] = moduleFormValues[key] + "";
          }
        });

        this.setState({ loading: true }, () =>
          this.props
            .dispatch(updateSystemProps(changedSystemProps))
            .then(() => this.props.dispatch(reset("moduleForm")))
            .then(() => this.props.dispatch(fetchSystemProps()))
            .then(() => this.setState({ loading: false }))
            .catch(err => {
              console.log(err);
              this.setState({ loading: false });
            })
        );
      }

      return changedSystemProps;
    };

    const headerProps = [{}, { center: true }];

    const thLabels = [t("system_module"), t("active")];

    const tableRows = systemProps
      ? Object.keys(systemProps)
          .filter(key => key.startsWith(MODULE_SETTING_PREFIX))
          .map((key, index) => (
            <TR key={index}>
              <TD>{t("system_" + key.substring(key.lastIndexOf(".") + 1))}</TD>
              <TD>
                <FlexContainer center vAlignCenter>
                  <Field
                    name={key.substring(key.lastIndexOf(".") + 1)}
                    fitToContent
                    component={CheckBox}
                    checked={systemProps[key] === "true"}
                  />
                </FlexContainer>
              </TD>
            </TR>
          ))
      : [];

    return (
      <FlexContainer margin={[0, 0, 20, 0]}>
        {this.state.loading ? <OverlayLoader /> : undefined}
        <Table
          tableRows={tableRows}
          tableHeaderLabels={thLabels}
          tableHeaderProps={headerProps}
          forceUpdate={true}
          t={t}
          key={"modules"}
        />
        <FlexContainer row flexEnd vAlingEnd margin={[20, 0, 0, 0]}>
          <Button.Standard
            margin={[0, 24, 0, 0]}
            onClick={this.props.isSuperUser ? saveChangedSystemProps : undefined}
            inactive={!this.props.isSuperUser}
          >
            {t("save")}
          </Button.Standard>
        </FlexContainer>
      </FlexContainer>
    );
  }
}

ModuleForm = reduxForm({
  form: "moduleForm",
  enableReinitialize: true
})(ModuleForm);

function mapStateToProps(state) {
  const { systemAdministratorReducer, form } = state;

  return {
    systemProps: systemAdministratorReducer.systemProps.object || {},
    moduleFormValues:
      form.moduleForm && form.moduleForm.values ? form.moduleForm.values : {}
  };
}

export default connect(mapStateToProps)(
  withTranslation("translations")(ModuleForm)
);
